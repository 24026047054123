import React, { Component } from "react";
import {
  TemplateContainer,
  Header,
  Title,
  WrapInput,
  Input,
  Label,
  WrapLabel,
  Description,
  PreviewWrap,
  Preview,
  WrapButtons,
  Icon,
  Wrap,
  WrapEmpty,
  ConsoleCard,
  ConsoleList,
  ConsoleImage,
  WrapError,
  Example,
  SettingFields,
  SettingField
} from "../styled";
import IconCirclePlus from "../../../components/kit/Icon/IconCirclePlus";
import FormFieldKit from "../../../components/kit/Fields/FormField/FormField";
import Button from "../../../components/kit/Button/ButtonKit";
import { api } from "../../..";
import TypographyKit from "../../../components/kit/Typography/TypographyKit";
import { COLOR } from "../../../constants/theme";
import { propValueOr } from "../../../helpers/common";
import imageCompression from "browser-image-compression";
import DialogKit from "../../../components/kit/Dialog/DialogKit";
import ButtonKit from "../../../components/kit/Button/ButtonKit";
import moment from "moment";
import { imageFields } from "../imageFields";
import iconX from "../../../static/icons/remove.svg";
import CrossPlatformThumbnail from "../../../static/images/cross-platform.svg";
import Panel from "../../../components/kit/Panel/Panel";
import { WrapPanel } from "../../assets/styled";

class GameCreate extends Component {
  constructor(props) {
    super();

    this.state = {
      name: "",
      settings: [{ name: "", value: "" }],
      settingGroups: [],
      visible: true,
      creating: false,
      created: false,
      generalError: "",
      success: "",
      selectedConsoles: [],
      consoleSelected: false,
      consoleError: false,
      isAddSettingDialogOpen: false,
      seriesId: null,
      settingsId: 0
    };
  }

  handleChangeImage = async (propName, val) => {
    if (val) {
      const options = {
        maxSizeMB: 0.4,
        useWebWorker: true
      };
      this.setState({
        processingImage: true,
        [propName]: null
      });
      const originalFile = val;
      this.setState({ [propName]: originalFile });
      let file;
      if (originalFile.type && originalFile.type.includes("gif")) {
        file = originalFile;
        const fileNameArr = file.name.split(".");
        if (fileNameArr.length > 1) {
          Object.defineProperty(file, "name", {
            writable: true,
            value: `${fileNameArr[0]}_${moment().format("Hmm-DMMYY")}${
              fileNameArr[1]
            }`
          });
        }
      } else {
        file = await imageCompression(val, options);
        const fileNameArr = file.name.split(".");
        if (fileNameArr.length > 1) {
          file.name = `${fileNameArr[0]}_${moment().format("Hmm-DMMYY")}${
            fileNameArr[1]
          }`;
        }
      }
      this.setState({ [propName]: file });

      this.createImgConstraints(propName);

      let reader = new FileReader();
      reader.onload = readerEvent => {
        const img = new Image();
        img.src = readerEvent.target.result;
        img.onload = e => {
          const error = this.validateImage(img.width, img.height);
          if (!!error) {
            this.setState({
              image: null,
              imagePreview: null,
              processingImage: false,
              imageError: true,
              [propName + "Uploaded"]: false,
              ["error" + propName]: this.state.imageConstraints.text
            });
            return;
          }
          this.setState({
            [propName + "Preview"]: img.src,
            processingImage: false,
            imageError: false,
            [propName + "Uploaded"]: true,
            ["error" + propName]: "",
            [propName + "Error"]: false
          });
        };
      };

      reader.readAsDataURL(originalFile);
    }
  };

  handleBlurImage = image => {
    if (!this.isImageValid(image))
      this.setState({
        ["error" + image]: "You must upload a game " + image,
        [image + "Error"]: true
      });
  };

  isImageValid = image => {
    if (!this.state[image + "Uploaded"]) return false;

    return true;
  };

  createImgConstraints = imgType => {
    let widthLimits, heightLimits, aspectRatioLimits, text;
    if (imgType === "thumbnail") {
      widthLimits = 296;
      heightLimits = 444;
      aspectRatioLimits = widthLimits / heightLimits;
      text = "Image must be min 296px by 444px and have an aspect ratio of 2:3";
    } else if (imgType === "background") {
      widthLimits = 1222;
      heightLimits = 185;
      aspectRatioLimits = [6.59, 6.61];
      text =
        "Image must be minimium 1222px by 185px with aspect ratio between 6.59 - 6.61";
    } else if (imgType === "icon") {
      widthLimits = 220;
      heightLimits = 220;
      aspectRatioLimits = 1;
      text = "Image must be 220px by 220px";
    } else if (imgType === "event") {
      widthLimits = null;
      heightLimits = null;
      aspectRatioLimits = 16 / 9;
      text = "Image must have an aspect ratio of 16:9";
    } else if (imgType === "match") {
      //no limits
      widthLimits = 1440;
      heightLimits = 750;
      aspectRatioLimits = null;
      text = "Image must be min 1440px by 855px";
    } else if (imgType === "mobile") {
      widthLimits = null;
      heightLimits = null;
      aspectRatioLimits = 2.07;
      text = "Image must have aspect ratio of 2.07";
    }

    this.setState({
      imageConstraints: {
        width: widthLimits,
        height: heightLimits,
        aspectRatio: aspectRatioLimits,
        text: text
      }
    });

    return true;
  };

  validateImage = (width, height) => {
    const aspectRatio = width / height;
    let { imageConstraints } = this.state;

    if (!!imageConstraints.aspectRatio) {
      if (imageConstraints.aspectRatio?.length > 1) {
        if (
          aspectRatio < imageConstraints.aspectRatio[0] ||
          aspectRatio > imageConstraints.aspectRatio[1]
        ) {
          return true;
        }
      } else if (
        Math.floor(imageConstraints.aspectRatio) !== Math.floor(aspectRatio)
      ) {
        return true;
      }
    }
    if (!!imageConstraints.width) {
      if (width < imageConstraints.width) return true;
    }
    if (!!imageConstraints.height) {
      if (height < imageConstraints.height) return true;
    }

    return false;
  };

  createGame = () => {
    const {
      name,
      rules,
      selectedConsoles,
      thumbnail,
      icon,
      background,
      event,
      match,
      title,
      mobile,
      visible,
      seriesId,
      settingGroups
    } = this.state;

    let data = new FormData();

    if (thumbnail) {
      data.append("thumbnail", thumbnail);
      data.append("original", thumbnail);
    }
    if (icon) data.append("icon", icon);
    if (background) data.append("background", background);
    if (event) data.append("event", event);
    if (match) data.append("match", match);
    if (title) data.append("title", title);
    if (title) data.append("mobile", mobile);
    if (name) data.append("name", name);
    if (rules) data.append("rules", rules);
    if (seriesId) data.append("seriesId", seriesId);
    if (settingGroups) {
      let newSettingGroups = [];
      settingGroups.forEach(settingGroup => {
        let arr = {};
        settingGroup.settings.forEach(setting => {
          arr[setting.name] = setting.value;
        });
        let newSettingGroup = {
          name: settingGroup.name,
          settings: arr
        };
        newSettingGroups.push(newSettingGroup);
      });

      data.append("settingGroups", JSON.stringify(newSettingGroups));
    }

    if (visible) data.append("visible", visible);
    if (selectedConsoles) data.append("selectedConsoles", selectedConsoles);
    this.setState({ creating: true });
    api.games
      .create(data)
      .then(() => {
        this.setState({
          creating: false,
          success: "Game created!",
          error: "",
          created: false
        });
      })
      .catch(err => {
        const errData = (err.response && err.response.data) || {};

        this.setState({
          generalError: errData.error ? errData.error : "Error",
          loading: false,
          creating: false
        });
      });
    this.setState({ loading: false });
  };

  isNameValid = () => {
    if (!this.state.name) return false;

    return true;
  };

  isConsolesValid = () => {
    if (!this.state.consoleSelected) return false;

    return true;
  };

  isSubmitDisabled = () => {
    for (let i = 0; i < imageFields.length; i++) {
      if (!this.isImageValid(imageFields[i].key)) return true;
    }
    if (!this.isConsolesValid() || !this.isNameValid() || this.state.created) {
      return true;
    }
  };

  handleChangeName = name =>
    this.setState({ name, errorName: "", generalError: "", success: "" });

  handleBlurName = () => {
    if (!this.isNameValid()) {
      this.setState({
        errorName: "You must input a game name"
      });
    }
  };

  addSettingField = () => {
    this.setState({
      settings: [...this.state.settings, { name: "", value: "" }]
    });
  };

  addSettingGroupField = id => {
    const newState = this.state.settingGroups.map((group, i) => {
      if (id === i) {
        if (group) {
          group.settings = [...group.settings, { name: "", value: "" }];
        } else {
          group = { name: "", settings: [{ name: "", value: "" }] };
        }
      }
      return group;
    });
    this.setState({ settingGroups: newState });
  };

  handleCloseSettingDialog = () => {
    this.setState({
      isAddSettingDialogOpen: false,
      addSetting: "",
      settings: [{ name: "", value: "" }]
    });
  };

  deleteSettingGroup = index => {
    const { settingGroups } = this.state;

    let newSettingGroups = [...settingGroups];

    newSettingGroups.splice(index, 1);

    this.setState({
      settingGroups: newSettingGroups
    });
  };

  renderAddSettingDialog = () => {
    const { isAddSettingDialogOpen, addSetting, settings } = this.state;

    return (
      <DialogKit
        isOpen={isAddSettingDialogOpen}
        onClose={this.handleCloseSettingDialog}
        title={"Create Settings"}
        renderBody={() => (
          <>
            <FormFieldKit
              fullWidth
              label={"Settings Name"}
              placeholder={"Enter settings name"}
              onChange={val => this.setState({ addSetting: val })}
              value={addSetting}
            />
            {settings?.map((setting, id) => {
              return (
                <SettingFields id={id}>
                  <SettingField>
                    <FormFieldKit
                      fullWidth
                      id={id}
                      label={
                        <Wrap>
                          Setting {id + 1} Name
                          <img
                            style={{ opacity: 0 }}
                            src={iconX}
                            alt=""
                            width={22}
                            height={22}
                          />
                        </Wrap>
                      }
                      placeholder={`Enter Setting ${id + 1} Name`}
                      onChange={val =>
                        this.setState({
                          settings: [
                            ...settings.slice(0, id),
                            { ...setting, name: val },
                            ...settings.slice(id + 1)
                          ]
                        })
                      }
                      value={setting.name}
                    />
                  </SettingField>
                  <SettingField>
                    <FormFieldKit
                      fullWidth
                      id={id}
                      label={
                        <Wrap>
                          Setting {id + 1} Value
                          <img
                            onClick={() => {
                              let newSettings = [
                                ...settings.slice(0, id),
                                ...settings.slice(id + 1)
                              ];
                              this.setState({
                                settings: newSettings
                              });
                            }}
                            style={{ cursor: "pointer" }}
                            src={iconX}
                            alt=""
                            width={22}
                            height={22}
                          />
                        </Wrap>
                      }
                      placeholder={`Enter Setting ${id + 1} Value`}
                      onChange={val =>
                        this.setState({
                          settings: [
                            ...settings.slice(0, id),
                            { ...setting, value: val },
                            ...settings.slice(id + 1)
                          ]
                        })
                      }
                      value={setting.value}
                    />
                  </SettingField>
                </SettingFields>
              );
            })}
            <Icon onClick={this.addSettingField}>
              <IconCirclePlus width={25} />
            </Icon>
          </>
        )}
        renderFooter={() => (
          <WrapButtons>
            <ButtonKit
              fullWidth
              small
              disabled={
                Object.keys(settings).includes(addSetting) || !addSetting
              }
              onClick={() => {
                const { settingGroups, settings, addSetting } = this.state;
                if (Object.keys(settings).includes(addSetting)) {
                  return;
                }

                this.setState({
                  settingGroups: [
                    ...settingGroups,
                    { name: addSetting, settings: settings }
                  ],
                  // settings: [{ name: "", value: "" }],
                  isAddSettingDialogOpen: false,
                  addSetting: ""
                });
                this.handleCloseSettingDialog();
              }}
            >
              Add
            </ButtonKit>
            <ButtonKit
              fullWidth
              small
              appearance={"secondary"}
              color={"red"}
              onClick={this.handleCloseSettingDialog}
            >
              Cancel
            </ButtonKit>
          </WrapButtons>
        )}
      />
    );
  };

  render() {
    const {
      name,
      errorName,
      rules,
      creating,
      settingGroups,
      selectedConsoles,
      errorConsole,
      consoleError,
      seriesId
    } = this.state;

    const { consoles, allSeries } = this.props;

    return (
      <TemplateContainer>
        {this.renderAddSettingDialog()}
        <Header>
          <Title>CREATE NEW GAME</Title>
        </Header>
        <FormFieldKit
          label={"Name"}
          fullWidth
          placeholder={"Enter name..."}
          required
          value={name}
          errorText={errorName}
          onChange={this.handleChangeName}
          onBlur={this.handleBlurName}
        />
        <FormFieldKit
          label={"Game Rules (Markdown Compatible)"}
          description={
            "These are game title specific Rival Rules (users must accept to play in Arenas & Leagues games)"
          }
          fullWidth
          placeholder={"Enter rules..."}
          multiline
          type={"textarea"}
          value={rules}
          inputProps={{ maxLength: 10000 }}
          onChange={val =>
            this.setState({ rules: val, generalError: "", success: "" })
          }
        />
        <Wrap>
          <FormFieldKit
            label={"Add To Game Series"}
            description={"Add game to existing series"}
            fullWidth
            multiline
            select
            selectOptions={allSeries.map(gameSeries => ({
              label: gameSeries.name,
              value: gameSeries.id
            }))}
            value={seriesId}
            onChange={val => {
              this.setState({ seriesId: val, updated: false });
            }}
            placeholder={"Select Existing Series"}
          />
        </Wrap>
        <WrapError error={consoleError}>
          <WrapLabel>
            <Label>
              {"Select Consoles"}{" "}
              {
                <span style={{ color: COLOR.RIVAL_RED, fontWeight: "bold" }}>
                  *
                </span>
              }
            </Label>
          </WrapLabel>
          <ConsoleList>
            {consoles.map(
              gameConsole =>
                gameConsole.id && (
                  <ConsoleCard
                    key={gameConsole.id}
                    active={selectedConsoles.indexOf(gameConsole.id) > -1}
                    onClick={() => {
                      if (selectedConsoles.indexOf(gameConsole.id) > -1) {
                        //if already selected, unselect
                        let temp = selectedConsoles;
                        const i = selectedConsoles.indexOf(gameConsole.id);
                        temp.splice(i, 1);
                        if (temp.length > 0)
                          this.setState({
                            selectedConsoles: temp,
                            consoleSelected: true,
                            consoleError: false,
                            errorConsole: ""
                          });
                        else
                          this.setState({
                            selectedConsoles: temp,
                            consoleSelected: false,
                            errorConsole: "You must select a console",
                            consoleError: true
                          });
                      } else
                        this.setState({
                          //select
                          selectedConsoles: selectedConsoles.concat(
                            gameConsole.id
                          ),
                          consoleSelected: true,
                          consoleError: false,
                          errorConsole: ""
                        });
                    }}
                  >
                    <ConsoleImage
                      src={propValueOr(
                        gameConsole,
                        "imageInfo.thumbnail",
                        CrossPlatformThumbnail
                      )}
                      onError={e => (e.target.src = "")}
                      alt=""
                    />
                  </ConsoleCard>
                )
            )}
            <ConsoleCard
              key={0}
              active={selectedConsoles.indexOf(0) > -1}
              onClick={() => {
                if (selectedConsoles.indexOf(0) > -1) {
                  //if already selected, unselect
                  let temp = selectedConsoles;
                  const i = selectedConsoles.indexOf(0);
                  temp.splice(i, 1);
                  if (temp.length > 0)
                    this.setState({
                      selectedConsoles: temp,
                      consoleSelected: true,
                      consoleError: false,
                      errorConsole: ""
                    });
                  else
                    this.setState({
                      selectedConsoles: temp,
                      consoleSelected: false,
                      errorConsole: "You must select a console",
                      consoleError: true
                    });
                } else
                  this.setState({
                    //select
                    selectedConsoles: selectedConsoles.concat(0),
                    consoleSelected: true,
                    consoleError: false,
                    errorConsole: ""
                  });
              }}
            >
              <ConsoleImage
                src={CrossPlatformThumbnail}
                onError={e => (e.target.src = "")}
                alt=""
              ></ConsoleImage>
            </ConsoleCard>
          </ConsoleList>
          {errorConsole}
        </WrapError>
        {settingGroups && (
          <>
            <Wrap>
              <Title>GAME SETTINGS</Title>
              <Icon
                onClick={() => this.setState({ isAddSettingDialogOpen: true })}
              >
                <IconCirclePlus width={25} />
              </Icon>
            </Wrap>
            {propValueOr(Object.entries(settingGroups), "length") > 0 ? (
              <WrapPanel>
                {settingGroups.map((settingGroup, index) => (
                  <>
                    <img
                      onClick={() => this.deleteSettingGroup(index)}
                      style={{ cursor: "pointer", float: "right" }}
                      src={iconX}
                      alt=""
                      width={22}
                      height={22}
                    />
                    <Panel trigger={settingGroup.name} open={false}>
                      {settingGroup.settings.map((setting, id) => (
                        <SettingFields id={id}>
                          <SettingField>
                            <FormFieldKit
                              fullWidth
                              id={id}
                              label={
                                <Wrap>
                                  Setting {id + 1} Name
                                  <img
                                    style={{ opacity: 0 }}
                                    src={iconX}
                                    alt=""
                                    width={22}
                                    height={22}
                                  />
                                </Wrap>
                              }
                              placeholder={`Enter Setting ${id + 1} Name`}
                              onChange={val =>
                                this.setState({
                                  settingGroups: [
                                    ...this.state.settingGroups.slice(0, index),
                                    {
                                      ...settingGroup,
                                      settings: [
                                        ...settingGroup.settings.slice(0, id),
                                        { ...setting, name: val },
                                        ...settingGroup.settings.slice(id + 1)
                                      ]
                                    },
                                    ...this.state.settingGroups.slice(index + 1)
                                  ]
                                })
                              }
                              value={setting.name}
                            />
                          </SettingField>
                          <SettingField>
                            <FormFieldKit
                              fullWidth
                              id={id}
                              label={
                                <Wrap>
                                  <Label>Setting {id + 1} Value</Label>
                                  <img
                                    onClick={() => {
                                      let newSettings = [
                                        ...this.state.settingGroups.slice(
                                          0,
                                          index
                                        ),
                                        {
                                          ...settingGroup
                                        },
                                        ...this.state.settingGroups.slice(
                                          index + 1
                                        )
                                      ];
                                      newSettings[index].settings.splice(id, 1);
                                      this.setState({
                                        settingGroups: newSettings
                                      });
                                    }}
                                    style={{ cursor: "pointer" }}
                                    src={iconX}
                                    alt=""
                                    width={22}
                                    height={22}
                                  />
                                </Wrap>
                              }
                              placeholder={`Enter Setting ${id + 1} Value`}
                              onChange={val =>
                                this.setState({
                                  settingGroups: [
                                    ...this.state.settingGroups.slice(0, index),
                                    {
                                      ...settingGroup,
                                      settings: [
                                        ...settingGroup.settings.slice(0, id),
                                        { ...setting, value: val },
                                        ...settingGroup.settings.slice(id + 1)
                                      ]
                                    },
                                    ...this.state.settingGroups.slice(index + 1)
                                  ]
                                })
                              }
                              value={setting.value}
                            />
                          </SettingField>
                        </SettingFields>
                      ))}
                      <Icon onClick={() => this.addSettingGroupField(index)}>
                        <IconCirclePlus width={25} />
                      </Icon>
                    </Panel>
                  </>
                ))}
              </WrapPanel>
            ) : (
              <WrapEmpty>
                No Game Settings. You can add settings by clicking the '+' icon
                above.
              </WrapEmpty>
            )}
          </>
        )}
        <Title>GAME IMAGES</Title>
        {imageFields.map(field => (
          <WrapInput key={field.key}>
            <WrapError error={this.state[field.key + "Error"]}>
              <WrapLabel>
                <Label>
                  {"Game " + field.key}{" "}
                  {
                    <span
                      style={{ color: COLOR.RIVAL_RED, fontWeight: "bold" }}
                    >
                      *
                    </span>
                  }
                </Label>
              </WrapLabel>
            </WrapError>
            <Description>{field.description}</Description>
            {field?.example && (
              <Example>
                EXAMPLE of a Game {field.key} image:
                <img src={field.example} alt="" height={30} />
              </Example>
            )}
            <Input
              type="file"
              name={field.key}
              onChange={e =>
                this.handleChangeImage(field.key, e.target.files[0])
              }
              onBlur={() => this.handleBlurImage(field.key)}
            />
            {this.state[field.key + "Preview"] && (
              <PreviewWrap>
                <Preview src={this.state[field.key + "Preview"]} />
              </PreviewWrap>
            )}
            <WrapError error={this.state[field.key + "Error"]}>
              {this.state["error" + field.key]}
            </WrapError>
          </WrapInput>
        ))}
        {this.state.generalError && (
          <TypographyKit color={COLOR.CHERRY_RED}>
            {this.state.generalError}
          </TypographyKit>
        )}
        {this.state.success && (
          <TypographyKit color={COLOR.GREEN}>
            {this.state.success}
          </TypographyKit>
        )}
        <Button
          onClick={() => this.createGame()}
          fullWidth
          small
          disabled={this.isSubmitDisabled()}
          preloader={creating}
          appearance={"secondary"}
        >
          CREATE GAME
        </Button>
      </TemplateContainer>
    );
  }
}

export default GameCreate;
