import styled from "styled-components";
import { COLOR, FONT_WEIGHT, FONT_FAMILY } from "../../../constants/theme";
import breakpoints from "../../../helpers/breakpoints";

export const Container = styled.div`
  min-height: 100%;
  width: 100%;
  margin-top: 10px;
  color: ${COLOR.TEXT};
`;

export const Content = styled.div`
  display: flex;
`;

export const WalletTypeColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
`;

export const WalletTypeHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 80px;
  border-bottom: 1px solid ${COLOR.BG_PRIMARY};
`;

export const WalletTypeTable = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${COLOR.BG_SECONDARY};
  width: 100%;
  margin: 20px 0px;
  border-top: 6px solid ${COLOR.BG_PRIMARY};
  border-bottom: 6px solid ${COLOR.BG_PRIMARY};

  > :not(:last-child) {
    border-bottom: 2px solid ${COLOR.BG_SECONDARY};
  }
`;

export const WalletTypeItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${COLOR.BG_PRIMARY};
  ${({ active }) =>
    active
      ? `background-color: ${COLOR.BG_SECONDARY};
    border-left: 6px solid ${COLOR.RIVAL_RED};`
      : `cursor: pointer;
    &:hover {
      opacity: 0.4;
    }`}
  height: 100px;
  padding: 0 20px;
`;

export const ItemText = styled.div`
  font: ${FONT_WEIGHT.BOLD} 16px ${FONT_FAMILY.THIN};
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
`;

export const WrapCoinsTotal = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 10px;
  width: 100%;
  max-width: 350px;

  border-radius: 50px;
  background-color: ${COLOR.BG_ACCENT};
  color: ${COLOR.TEXT};

  ${breakpoints.down("sm")} {
    margin: 0px 0px;
  }
`;

export const CoinsTotal = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  padding: 2px 5px;

  ${breakpoints.down("sm")} {
    padding: 10px 25px;
  }
`;

export const CoinsText = styled.div`
  font: ${FONT_WEIGHT.NORMAL} 15px ${FONT_FAMILY.GOTHIC_BOLD};
`;

export const WrapCoinsCount = styled.div`
  display: flex;
  align-items: center;
`;

export const CoinsIcon = styled.img`
  width: 15px;
  height: 15px;
  margin-left: 3px;
`;

export const CoinsCount = styled.div`
  font: ${FONT_WEIGHT.NORMAL} 15px ${FONT_FAMILY.GOTHIC_BOLD};
  margin-top: 5px;
`;

export const ListSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ListHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 80px;
  border-bottom: 1px solid ${COLOR.BG_PRIMARY};
  > :not(:last-child) {
    margin-right: 10px;
  }
`;

export const WrapSearchField = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 200px;
`;

export const WrapList = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 512px;

  margin: 20px 0px 10px 0px;
  padding: 0px 20px;
  border-top: 6px solid ${COLOR.BG_PRIMARY};
  border-right: 6px solid ${COLOR.BG_PRIMARY};
  border-bottom: 6px solid ${COLOR.BG_PRIMARY};
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;

  > * {
    border-bottom: 1px solid ${COLOR.BORDER};
  }
`;

export const EmptyList = styled.div`
  font: ${FONT_WEIGHT.BOLD} 20px ${FONT_FAMILY.THIN};
`;
