import Organization from "./Organization/Organization";
import OrganizationGroups from "./OrganizationGroups/OrganizationGroups";

const routes = [
  {
    path: "/organizations",
    component: Organization
  },
  {
    path: "/organizations/groups",
    component: OrganizationGroups
  },
  {
    path: "/organizations/groups/:slug",
    component: OrganizationGroups
  }
];

export default routes;
