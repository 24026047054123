import React, { Component } from "react";
import { connect } from "react-redux";
import HeaderNotifications from "./HeaderNotifications";
import {
  fetchNotificationsAction,
  readNotificationAction,
  readAllNotificationsAction
} from "../../../../store/notifications/actions";
import InfoToast from "../../../smart/ToastNotifications/InfoToast/InfoToast";
import { toast } from "react-toastify";

class HeaderNotificationsContainer extends Component {
  componentDidUpdate(prevProps) {
    if (
      prevProps.isRefreshingNotifications &&
      !this.props.isRefreshingNotifications
    ) {
      const oldNotificationIds = prevProps.notifications.list.map(n => n.id);
      for (let notif of this.props.notifications.list) {
        if (!!!notif.read) {
          if (oldNotificationIds.every(oldNotifId => notif.id !== oldNotifId)) {
            toast(
              <InfoToast
                {...notif}
                onClose={id => this.updateNotification(id)}
              />
            );
          }
        } else {
          break;
        }
      }
    }
  }

  updateNotification(id) {
    this.props.readNotificationAction(id);
  }

  render() {
    return <HeaderNotifications {...this.props} />;
  }
}

const mapStateToProps = state => ({
  notifications: state.notifications,
  hasFetchingNotifications: state.notifications.wasLoaded,
  isRefreshingNotifications: state.notifications.isLoading
});

const mapDispatchToProps = {
  fetchNotificationsAction,
  readNotificationAction,
  readAllNotificationsAction
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderNotificationsContainer);
