import React, { useState } from "react";

import {
  LayoutLoggedWrapHeaderSD,
  LayoutLoggedSD,
  LayoutLoggedMainSD,
  WrapContent,
  Content
} from "./styled";
import HeaderLogged from "../../components/smart/HeaderLogged/HeaderLogged";
import SideNav from "../../components/smart/SideNav/SideNav";
import LevelUp from "../../components/presentation/LevelUp/LevelUp";
import ChatBar from "../../components/smart/ChatWindow/ChatBar";
import { withRouter } from "react-router-dom";
import withWidth from "../../hoc/withWidth";

function LayoutGeneral(props) {
  const { children, user, width } = props;
  const [isOpen, setMobileMenu] = useState(false);
  const isMobile = width < 600;

  return (
    <LayoutLoggedSD>
      <SideNav isOpen={isOpen} key={window.location.pathname} user={user} />
      <WrapContent>
        <LevelUp type={"topBar"}>
          <LayoutLoggedWrapHeaderSD>
            <HeaderLogged
              setMobileMenu={v => setMobileMenu(v)}
              user={user}
              isMobile={isMobile}
            />
          </LayoutLoggedWrapHeaderSD>
        </LevelUp>
        <Content>
          <LayoutLoggedMainSD>{children}</LayoutLoggedMainSD>
        </Content>
        <ChatBar />
      </WrapContent>
    </LayoutLoggedSD>
  );
}

export default withRouter(withWidth(LayoutGeneral));
