import { propValueOr } from "../../helpers/common";
import { api } from "../../index";
import ORG_GAMES_ACTIONS from "./constants";

export const fetchOrganizationGames = id => dispatch => {
  dispatch({
    type: ORG_GAMES_ACTIONS.list.request
  });
  api.organization.games
    .list(id)
    .then(resp => {
      dispatch({
        type: ORG_GAMES_ACTIONS.list.success,
        payload: propValueOr(resp, "data", [])
      });
    })
    .catch(err => {
      dispatch({
        type: ORG_GAMES_ACTIONS.list.error,
        payload: err
      });
    });
};

export const resetGames = () => dispatch => {
  dispatch({
    type: ORG_GAMES_ACTIONS.reset
  });
};
