import CommunicationCreate from "./CommunicationCreate/CommunicationCreate";
import CommunicationScheduled from "./CommunicationScheduled/CommunicationScheduled";
import CommunicationDrafts from "./CommunictationDrafts/CommunicationDrafts";
import CommunicationSent from "./CommunicationSent/CommunicationSent";
import CommunicationTemplates from "./CommunicationTemplates/CommunicationTemplates";
import CommunicationScheduledEdit from "./CommunicationScheduled/CommunicationScheduledEdit";
import CommunicationTemplatesEdit from "./CommunicationTemplates/CommunicationTemplatesEdit";
import CommunicationDraftsEdit from "./CommunictationDrafts/CommunicationDraftsEdit";
import CommunicationTemplatesCreate from "./CommunicationTemplates/CommunicationTemplatesCreate";

const routes = [
  {
    path: "/communication/create",
    contentComponent: CommunicationCreate,
    exact: true
  },
  {
    path: "/communication/scheduled",
    contentComponent: CommunicationScheduled,
    exact: true
  },
  {
    path: "/communication/scheduled/:id",
    contentComponent: CommunicationScheduledEdit,
    exact: true
  },
  {
    path: "/communication/drafts",
    contentComponent: CommunicationDrafts,
    exact: true
  },
  {
    path: "/communication/drafts/:id",
    contentComponent: CommunicationDraftsEdit,
    exact: true
  },
  {
    path: "/communication/sent",
    contentComponent: CommunicationSent,
    exact: true
  },
  {
    path: "/communication/global_templates",
    contentComponent: CommunicationTemplates,
    exact: true,
    props: {
      isGlobalTemplates: true
    }
  },
  {
    path: "/communication/global_templates/create",
    contentComponent: CommunicationTemplatesCreate,
    exact: true,
    props: {
      isGlobalTemplates: true
    }
  },
  {
    path: "/communication/global_templates/:id",
    contentComponent: CommunicationTemplatesEdit,
    exact: true,
    props: {
      isGlobalTemplates: true
    }
  },
  {
    path: "/communication/templates",
    contentComponent: CommunicationTemplates,
    exact: true
  },
  {
    path: "/communication/templates/create",
    contentComponent: CommunicationTemplatesCreate,
    exact: true
  },
  {
    path: "/communication/templates/:id",
    contentComponent: CommunicationTemplatesEdit,
    exact: true
  }
];

export default routes;
