import styled, { css } from "styled-components";
import {
  COLOR,
  FONT_FAMILY_DEFAULT as FONT_FAMILY,
  FONT_SIZE,
  FONT_WEIGHT
} from "../../constants/theme";
import { TypographyKitSD } from "../../components/kit/Typography/styled";
import { ButtonKitSD } from "../../components/kit/Button/styled";
import { InputKitSD, LabelKitSD } from "../../components/kit/Fields/styled";
import { DateTimePickerFieldKitSD } from "../../components/kit/Fields/DateTimePicker/styled";
import {
  FormFieldErrorKitSD,
  FormFieldKitSD
} from "../../components/kit/Fields/FormField/styled";
import { ChipInputSD } from "../../components/smart/ChipInput/styled";
import {
  FieldTypeAndSelectInputKitSD,
  FieldTypeAndSelectPopupOptionInnerSD,
  FieldTypeAndSelectPopupOptionSD,
  FieldTypeAndSelectPopupOptionTextSD,
  FieldTypeAndSelectPopupSD,
  FieldTypeAndSelectSD
} from "../../components/smart/FieldTypeAndSelect/styled";
import {
  ChipSD,
  ChipWrapIconSD
} from "../../components/presentation/Chip/styled";
import { BlankSD } from "../../components/presentation/Blank/styled";
import { Item as BreadcrumbsItem } from "../../components/presentation/Breadcrumbs/styled";
import breakpoints from "../../helpers/breakpoints";
import { SVGIconSD } from "../../components/kit/Icon/styled";

export const Content = styled.div`
  min-height: 100%;
  width: 100%;
  color: ${COLOR.WHITE};
  background-color: ${COLOR.BG_PRIMARY};
  z-index: 3;
  padding: 0 20px;
`;

export const Container = styled.div`
  min-height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const ContentInner = styled.div`
  margin: 0 auto 20px;
  width: 100%;
  color: ${COLOR.WHITE};
  background-color: ${COLOR.BG_SECONDARY};
  border-radius: 16px;
  z-index: 3;

  ${breakpoints.down("sm")} {
    max-width: 600px;
  }

  ${breakpoints.down("xs")} {
    max-width: 100%;
    width: auto;
    margin: 0 auto 0;
  }

  ${BlankSD} {
    min-height: 480px;
  }

  ${BreadcrumbsItem} {
    text-transform: uppercase;
  }
`;

export const FormFieldWithSearch = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  flex-direction: column-reverse;

  ${ChipInputSD} {
    min-height: unset;
    max-height: unset;
    padding: 0;
    background-color: transparent;
    border: none;
    border-radius: 0;
    box-shadow: none;
  }

  ${ChipSD} {
    padding: 8px 8px 8px 12px;
    border-radius: 4px;

    &:nth-last-child(2) {
      margin-right: 12px;
    }
  }

  ${ChipWrapIconSD} {
    color: ${COLOR.WHITE};
    font-size: 14px;
  }

  ${FieldTypeAndSelectInputKitSD} {
    &:focus {
      background-color: transparent;
      border-color: transparent !important;
      outline: none;
    }
  }

  ${FieldTypeAndSelectSD} {
    position: static;
    flex: 1;
    min-width: 200px;
    z-index: initial;

    ${breakpoints.down("sm")} {
      min-width: 180px;
    }
  }

  ${FieldTypeAndSelectPopupSD} {
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    max-height: 502px;
  }

  ${FieldTypeAndSelectPopupOptionSD} {
    padding: 0 21px;
    position: relative;

    &:not(:first-child) {
      margin-top: -1px;
    }

    &:not(:last-child) {
      ${FieldTypeAndSelectPopupOptionInnerSD} {
        border-bottom: 1px solid ${COLOR.BG_ACCENT};
      }
    }
  }

  ${FieldTypeAndSelectPopupOptionTextSD} {
    padding: 17px 0;
  }
`;

export const FormFieldImageWrap = styled.div`
  width: 32px;
  flex-shrink: 0;
  align-self: center;
  overflow: hidden;
  margin-right: 12px;

  ${breakpoints.down("xs")} {
    width: 24px;
    margin-right: 10px;
  }
`;

export const FormFieldImage = styled.img`
  display: block;
  max-width: 100%;
`;

export const FormField = styled.div`
  position: relative;
  padding: 14px 0;
  border-top: 1px solid ${COLOR.BORDER_3};

  ${breakpoints.down("sm")} {
    padding: 10px 0;
  }

  &:first-child {
    border-top: none;
  }

  ${FormFieldErrorKitSD} {
    margin-left: ${({ variant }) => (variant !== "primary" ? "13px" : "0")};
  }

  ${LabelKitSD} {
    color: ${COLOR.GREY};
    font-weight: ${FONT_WEIGHT.MEDIUM};
  }

  ${InputKitSD} {
    ${({ variant }) =>
      variant !== "primary" &&
      css`
        background-color: transparent;
        border-color: transparent;
        color: ${COLOR.WHITE};
        padding-left: 0;
        padding-right: 0;

        &::placeholder {
          color: ${COLOR.GREY};
        }

        &:focus {
          border-color: transparent;
          box-shadow: none;
          color: ${COLOR.WHITE} !important;
        }

        &:disabled {
          opacity: 0.5;
          cursor: not-allowed;
          background-color: transparent;
        }
      `}

  textarea${InputKitSD} {
    height: 352px;
    padding-top: 0;
    padding-bottom: 0;
    font: ${FONT_WEIGHT.MEDIUM} 14px / 1.5 ${FONT_FAMILY.THIN};
  }

`;

export const FormFiledError = styled.div``;

export const Form = styled.div`
  padding: 45px 55px;

  ${breakpoints.down("sm")} {
    padding: 15px 15px 30px;
  }

  ${DateTimePickerFieldKitSD} {
    background-color: transparent;
    border-color: transparent;
    cursor: pointer;

    &:focus {
      box-shadow: none;
    }

    &::placeholder {
      color: ${COLOR.WHITE};
      font-weight: ${FONT_WEIGHT.BOLD};
    }
  }
`;

export const ContentHead = styled.div`
  padding: 30px 26px;

  ${breakpoints.down("sm")} {
    padding: 15px 15px;
  }

  & > ${TypographyKitSD} {
    font-family: ${FONT_FAMILY.THIN};
    font-weight: ${FONT_WEIGHT.BOLD};

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
  ${FormFieldKitSD} {
    ${SVGIconSD} {
      stroke: ${COLOR.WHITE};
    }
  }
`;

export const Panel = styled.div`
  padding: 12px 26px;
  margin: 0 0 28px;
  background-color: ${COLOR.BG_ACCENT};
`;

export const ContentBody = styled.div`
  padding: 0 0 30px;

  & > ${Form} {
    padding: 20px 26px 0;
  }
`;

export const Items = styled.div`
  padding: 0 26px;

  ${breakpoints.down("sm")} {
    padding: 0 15px;
  }
`;

export const FormActions = styled.div`
  display: flex;
  align-items: center;
  padding: 23px 0 0;
  border-top: 1px solid ${COLOR.BORDER_3};

  ${breakpoints.down("sm")} {
    flex-wrap: wrap;
    margin: 0 -5px;
  }

  ${breakpoints.down("xs")} {
    margin: 0;
  }

  & > ${ButtonKitSD} {
    width: auto;
    flex-basis: 25%;
    white-space: nowrap;

    &:not(:first-child) {
      margin-left: 10px;
      white-space: nowrap;

      ${breakpoints.down("sm")} {
        margin: 5px 5px;
      }

      ${breakpoints.down("xs")} {
        margin: 5px 0;
      }
    }

    ${breakpoints.down("sm")} {
      flex-basis: calc(50% - 10px);
      margin: 5px 5px;
    }

    ${breakpoints.down("xs")} {
      flex-basis: 100%;
      margin: 5px 0;
    }
  }
`;

export const FormPanel = styled.div`
  display: flex;
  align-items: center;
  border-top: 1px solid ${COLOR.BORDER_3};
  padding: 8px 0;
  position: relative;
  z-index: 1;

  ${breakpoints.down("xs")} {
    flex-wrap: wrap;
  }

  ${FormFieldKitSD} {
    margin-bottom: 0;
  }
`;

export const FormPanelItem = styled.div`
  position: relative;

  ${breakpoints.down("xs")} {
    &:nth-child(3) {
      width: 100%;
    }
  }
`;

export const FormPanelFields = styled.div`
  padding: 15px 15px;
  width: 300px;
  background: ${COLOR.BORDER_2};

  ${FormFieldKitSD} {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
`;

export const ImageIcon = styled.span`
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
  transition: opacity 0.3s;
  opacity: 0;
  z-index: 2;
  padding: 4px;
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.36);
  font-size: 0;
`;

export const AttachmentIcon = styled.span`
  position: absolute;
  top: 2px;
  right: 2px;
  cursor: pointer;
  transition: opacity 0.3s;
  opacity: 0;
  z-index: 2;
  padding: 4px;
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.36);
  font-size: 0;
`;

export const Image = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: filter 0.3s;
`;

export const ImageWrap = styled.div`
  width: calc(20% - 24px);
  height: 88px;
  margin: 12px 12px;
  border-radius: 4px;
  box-shadow: 0 4px 20px 0 rgba(4, 11, 35, 0.16);
  overflow: hidden;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.36);
    opacity: 0;
    transition: all 0.3s;
    pointer-events: none;
  }

  &:hover {
    &::before {
      opacity: 1;
    }

    ${ImageIcon} {
      opacity: 1;
    }

    ${Image} {
      filter: blur(4px);
    }
  }
`;

export const FormAttachmentImages = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 -12px;
  padding: 0 0 12px;

  & > svg {
    margin: 20px auto;
    align-self: center;
    fill: ${COLOR.WHITE};
  }
`;

export const FormAttachmentWrap = styled.div`
  width: 400px;
  height: 22px;
  margin-top: 2px;
  margin-bottom: 2px;
  background-color: gray;

  box-shadow: 0 4px 20px 0 rgba(4, 11, 35, 0.16);
  overflow: hidden;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.36);
    opacity: 0;
    transition: all 0.3s;
    pointer-events: none;
  }

  &:hover {
    &::before {
      opacity: 1;
    }

    ${AttachmentIcon} {
      opacity: 1;
    }

    ${Image} {
      filter: blur(4px);
    }
  }
`;

export const FormAttachments = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
`;

export const FormHeader = styled.div`
  display: block;
  max-width: 100%;
  overflow: hidden;
  margin: 0 0 14px;

  ${ImageWrap} {
    width: 100%;
    height: auto;
    margin: 0;
    border-radius: 0;
    box-shadow: none;
  }
`;

export const PopupWrapper = styled.div`
  position: absolute;
  bottom: 100%;
  transform: translate(-50%, -10px);
  left: 50%;
  width: auto;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.5);
  border-radius: 2px;
  overflow: auto;
  max-height: 183px;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
    border-radius: 1px;
    background: ${COLOR.BG_ACCENT};
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 1px;
    background: ${COLOR.BG_PRIMARY};
    -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.5);
  }

  &::-webkit-scrollbar-thumb:window-inactive {
    background: ${COLOR.BG_PRIMARY};
  }
`;

export const FullscreenBg = styled.div`
  background-color: rgba(0, 0, 0, 0.9);
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  top: 0;
  justify-content: center;
  align-items: center;
  cursor: zoom-out;
  color: ${COLOR.WHITE};
  font-size: 24px;
`;
export const FullscreenImage = styled.img`
  max-width: 100%;
  max-height: 80%;

  ${breakpoints.down("725")} {
    max-height: 80%;
  }
`;
export const FullscreenText = styled.div`
  color: ${COLOR.WHITE};
  font-size: 18px;
  position: absolute;
  top: 120px;
  left: 15px;
  text-shadow: 2px 2px #000000;

  ${breakpoints.down("725")} {
    top: 150px;
  }
`;

export const Recipients = styled.div`
  width: 100%;
  max-height: 43vh;
  overflow: auto;
`;

export const RecipientsItem = styled.div`
  display: flex;
  align-items: center;
  padding: 6px 0;

  &:not(:first-child) {
    border-top: 1px solid ${COLOR.BORDER_3};
  }

  ${FormFieldKitSD} {
    flex-shrink: 0;
    margin: 0 10px 0 0;
    font-size: 0;
  }
`;

export const RecipientsItemContent = styled.div`
  color: ${COLOR.WHITE};
  font-family: ${FONT_FAMILY.THIN};
  font-size: ${FONT_SIZE.PARAGRAPH}px;
`;
