import imageCompression from 'browser-image-compression';
import { cloneDeep, isEqual, xorWith } from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import ButtonKit from '../../../../../components/kit/Button/ButtonKit';
import ButtonLinkKit from '../../../../../components/kit/Button/ButtonLinkKit';
import DialogKit from '../../../../../components/kit/Dialog/DialogKit';
import CheckboxKit from '../../../../../components/kit/Fields/Checkbox/CheckboxKit';
import CountryFieldKit from '../../../../../components/kit/Fields/CountryField/CountryField';
import FormFieldKit from '../../../../../components/kit/Fields/FormField/FormField';
import Panel from '../../../../../components/kit/Panel/Panel';
import Chat from '../../../../../components/smart/Chat/Chat';
import InputChips from '../../../../../components/smart/ChipInput/InputChips';
import {
  propValueOr,
  safePropValueOr,
  snakeToText,
} from '../../../../../helpers/common';
import { api } from '../../../../../index';
import PinIcon from '../../../../../static/icons/icon-external.svg';
import DeleteIcon from '../../../../../static/icons/remove.svg';
import UndoIcon from '../../../../../static/icons/undo.svg';
import { pinChatChannel } from '../../../../../store/chat/actions';
import { fetchTournamentAction } from '../../../../../store/tournament/actions';
import { BRACKET_PREVIEW_OPTIONS } from '../../../constants';
import Communications from '../../../TournamentCreate/Communications/Communications';
import { compressionOptions } from '../../../TournamentCreate/constants';
import Uploader from '../../../TournamentCreate/Images/Uploader/Uploader';
import UploaderFile from '../../../TournamentCreate/Images/Uploader/UploaderFile';
import Prizes from '../../../TournamentCreate/Prizes/Prizes';
import { TOURNAMENT_MODES } from '../../../TournamentCreate/Type/Type';
import ModeratorDialog from '../ModeratorDialog/ModeratorDialog';
import Announcements from './Announcements/Announcements';
import { adFields, getCardValues, imageFields, pdfFields } from './constants';
import Moderators from './Moderators/Moderators';
import OrganizationDialog from './OrganizationDialog/OrganizationDialog';
import StageEditor from './StageEditor/StageEditor';
import {
  ButtonWrap,
  Card,
  Content,
  Delete,
  Description,
  Details,
  DialogText,
  Dot,
  Header,
  Icon,
  IconWrap,
  Image,
  Info,
  Label,
  PanelContent,
  PinChatButton,
  Row,
  Stages,
  Status,
  Subtitle,
  Text,
  Time,
  TournamentPanels,
  WrapChat,
  WrapInput,
  WrapLabel,
  WrapPinButton,
} from './styled';

class Update extends Component {
  constructor(props) {
    super();

    this.state = this.initialState(props);
  }

  initialState = props => {
    const {
      name,
      description,
      startDate,
      registrationStart,
      registrationEnd,
      preview,
      checkinStart,
      checkinEnd,
      checkinRequired,
      verifiedRequired,
      minPlayers,
      maxPlayers,
      restrictions,
      entryFee,
      walletFee,
      survey,
      inviteToken,
      prizes,
      imageInfo,
      stages,
      events,
      metadata,
      featured,
      organization,
      mode,
    } = props.tournament;

    return {
      name: name,
      description: description,
      startTime: startDate,
      registrationStart: registrationStart,
      registrationEnd: registrationEnd,
      preview: preview || 'none',
      checkinStart: checkinStart,
      checkinEnd: checkinEnd,
      checkinRequired,
      verifiedRequired,
      minPlayers: minPlayers,
      maxPlayers: maxPlayers,
      countries: (restrictions && restrictions.countries) || [],
      ageRestrictionFrom:
        restrictions && restrictions.age && (restrictions.age.from || null),
      ageRestrictionTo:
        restrictions && restrictions.age && (restrictions.age.to || null),
      thumbnail: null,
      background: null,
      event: null,
      mobile: null,
      ad1: (imageInfo && imageInfo.ad1) || {},
      ad2: (imageInfo && imageInfo.ad2) || {},
      ad3: (imageInfo && imageInfo.ad3) || {},
      pdf1: (imageInfo && imageInfo.pdf1) || {},
      pdf2: (imageInfo && imageInfo.pdf2) || {},
      pdf3: (imageInfo && imageInfo.pdf3) || {},
      pdf4: (imageInfo && imageInfo.pdf4) || {},
      firstPlace: prizes && prizes.length ? prizes[0] : '',
      secondPlace: prizes && prizes.length ? prizes[1] : '',
      entryFee: entryFee ? entryFee / 100 : null,
      walletFee: walletFee ? walletFee : null,
      survey: survey ? survey : null,
      inviteToken: inviteToken ? inviteToken : null,
      prize:
        imageInfo && (imageInfo.prize || { title: null, description: null }),
      stages: stages
        ? stages
            .sort((a, b) => a.stage - b.stage)
            .map((stage, i) => {
              return { ...stage, index: i };
            })
        : [],
      updating: false,
      isConfirmDialogOpen: false,
      isOrganizationDialogOpen: false,
      error: null,
      events: (events || []).map(e => ({
        name: e.event,
        templateId: e.communicationMessageId,
      })),
      templates: [],
      showIcon: metadata && metadata.showIcon,
      featured: featured || null,
      affiliationWide: !organization,
      mode,
      hideStageInSchedule: metadata?.hideStageInSchedule,
      ...(metadata?.moderators && { moderators: metadata.moderators }),
      ...(metadata?.matchMessage && { matchMessage: metadata.matchMessage }),
    };
  };

  componentDidMount() {
    Promise.all([
      api.getTemplatesEmails(),
      api.getGlobalTemplatesEmails(),
    ]).then(resp => {
      const [templatesResp, globalTemplatesResp] = resp;

      let templates = [];
      if (templatesResp) {
        templates = templates.concat(
          templatesResp.data.docs.map(t => ({
            label: t.name,
            value: t.id,
            id: t.id,
            type: 'template',
          }))
        );
      }

      if (globalTemplatesResp) {
        templates = templates.concat(
          globalTemplatesResp.data.docs.map(t => ({
            label: `[GLOBAL] ${t.name}`,
            value: t.id,
            id: t.id,
            type: 'global_template',
          }))
        );
      }

      this.setState({ templates });
    });
  }

  renderConfirmDialog = () => {
    const { error } = this.state;
    return (
      <DialogKit
        isOpen={this.state.isConfirmDialogOpen}
        onClose={() =>
          this.setState({ isConfirmDialogOpen: false, error: null })
        }
        displayButtonClose
        title={error ? 'Failed' : 'Update Tournament'}
        renderBody={() => (
          <DialogText>
            {error
              ? 'Failed to update tournament: ' + error
              : 'Are you sure you want to update the tournament? Changes cannot be undone.'}
          </DialogText>
        )}
        renderFooter={() => (
          <>
            {!error && (
              <ButtonKit
                shape={'rounded'}
                fullWidth
                color={'rival_red'}
                onClick={this.updateTournament}
                preloader={this.state.updating}
                style={{ marginBottom: '15px' }}
              >
                Yes, Update!
              </ButtonKit>
            )}
            <ButtonKit
              shape={'rounded'}
              fullWidth
              appearance={'secondary'}
              onClick={() =>
                this.setState({ isConfirmDialogOpen: false, error: null })
              }
            >
              {error ? 'Okay' : 'Cancel'}
            </ButtonKit>
          </>
        )}
      />
    );
  };

  validateFields = () => {
    const {
      updating,
      isConfirmDialogOpen,
      ad1,
      ad2,
      ad3,
      pdf1,
      pdf2,
      pdf3,
      pdf4,
      prize,
      stages,
      ...rest
    } = this.state;
    if (ad1.image || ad1.link || ad2.image || ad2.link || ad3.image || ad3.link)
      return true;
    if (
      pdf1.link ||
      pdf1.title ||
      pdf2.link ||
      pdf2.title ||
      pdf3.link ||
      pdf3.title ||
      pdf4.link ||
      pdf4.title
    )
      return true;
    for (let prop in rest) {
      if (rest[prop]) return true;
    }
    if (stages && stages.length) return true;
    return false;
  };

  addStage = () => {
    let stages = this.state.stages;
    const defaultSettings = stages && stages.length > 0 && stages[0].settings;

    //add stage
    stages.push({
      index: stages.length,
      name: null,
      stage: stages.length + 1,
      type: null,
      startDate: null,
      minPlayers: 4,
      maxPlayers: 4,
      maxRounds: null,
      maxBrackets: 1,
      prizes: null,
      settings: defaultSettings,
      roundInfo: {},
      validated: false,
    });
    this.setState({ stages: stages });
  };

  handleStage = stage => {
    const { stages } = this.state;

    //edit existing stage or add new one
    if (stage.index !== undefined && stage.index !== null) {
      stages[stage.index] = { ...stage };
      this.setState({ stages: stages });
    }
  };

  deleteStage = stage => {
    let stages = this.state.stages;

    if (!stage.stage || stage.stage === 1) {
      window.alert('Failed. Cannot delete the first stage');
      return;
    }
    //edit existing stage or add new one
    if (stage.index !== undefined && stage.index !== null) {
      if (stage.index > -1) {
        stages.splice(stage.index, 1);
      }
      this.setState({ stages: stages });
    }
  };

  handleChangePrize = async (propName, innerPropName, val) => {
    if (innerPropName === 'image' && val != null) {
      const compressedImage = await imageCompression(val, compressionOptions);
      compressedImage.lastModifiedDate = new Date();
      val = new File([compressedImage], compressedImage.name);
    }
    this.setState({
      [propName]: { ...this.state[propName], [innerPropName]: val },
    });
  };

  handleChangeImage = async (propName, val) => {
    let compressedImage = await imageCompression(val, compressionOptions);
    compressedImage.lastModifiedDate = new Date();
    const newFile = new File([compressedImage], compressedImage.name);
    this.setState({
      [propName]: newFile,
    });
  };

  handleChangeAdImage = async (propName, file) => {
    if (file == null) {
      this.setState({
        [propName]: {
          ...this.state[propName],
          image: null,
        },
      });
      return;
    }
    let compressedImage = await imageCompression(file, compressionOptions);
    compressedImage.lastModifiedDate = new Date();
    const newFile = new File([compressedImage], compressedImage.name);
    this.setState({
      [propName]: {
        ...this.state[propName],
        image: newFile,
      },
    });
  };

  handleChangePDF = async (propName, file) => {
    if (file == null) {
      this.setState({
        [propName]: {
          ...this.state[propName],
          link: null,
        },
      });
      return;
    }
    this.setState({
      [propName]: {
        ...this.state[propName],
        link: file,
      },
    });
  };

  handleChangeField = (propName, val) => {
    this.setState({ [propName]: val });
  };

  areMomentsEqual = (m1, m2) => {
    return moment(m1).isSame(moment(m2), 'minute') || m1 === m2;
  };

  updateTournament = () => {
    const { tournament, organization } = this.props;

    this.setState({ updating: true });
    const {
      name,
      startTime,
      description,
      matchMessage,
      thumbnail,
      background,
      event,
      mobile,
      registrationStart,
      registrationEnd,
      preview,
      checkinStart,
      checkinEnd,
      checkinRequired,
      verifiedRequired,
      ad1,
      ad2,
      ad3,
      pdf1,
      pdf2,
      pdf3,
      pdf4,
      stages,
      prize,
      entryFee,
      walletFee,
      survey,
      inviteToken,
      firstPlace,
      secondPlace,
      minPlayers,
      maxPlayers,
      ageRestrictionFrom,
      ageRestrictionTo,
      events,
      featured,
      affiliationWide,
      mode,
      countries,
      moderators,
      hideStageInSchedule,
    } = this.state;

    const stagesData = stages.map(stage => {
      delete stage.index;
      delete stage.validated;
      const roundInfo = stage.roundInfo;
      Object.keys(stage.roundInfo).forEach(r => {
        delete roundInfo[r].val; // remove val key
      });
      return stage;
    });
    const body = {
      ...(name !== tournament.name && { name: name }),
      ...(!this.areMomentsEqual(startTime, tournament.startDate) && {
        startDate: startTime
          ? moment(startTime).format('YYYY-MM-DD HH:mm:ssZZ')
          : null,
      }),
      ...(description !== tournament.description && {
        description: description,
      }),
      ...(prize && prize.delete
        ? { prize: 'delete' }
        : prize && {
            ...(prize.image instanceof File && { prize: prize.image }),
            ...((!prize.title
              ? !!propValueOr(tournament, 'imageInfo.prize.title')
              : prize.title !==
                propValueOr(tournament, 'imageInfo.prize.title')) && {
              prizeTitle: prize.title,
            }),
            ...((!prize.description
              ? !!propValueOr(tournament, 'imageInfo.prize.description')
              : prize.description !==
                propValueOr(tournament, 'imageInfo.prize.description')) && {
              prizeDescription: prize.description,
            }),
          }),
      ...((firstPlace !== propValueOr(tournament, 'prizes', [])[0] ||
        secondPlace !== propValueOr(tournament, 'prizes', [])[1]) && {
        prizes: JSON.stringify([
          firstPlace || propValueOr(tournament, 'prizes', [])[0] || 500,
          secondPlace || propValueOr(tournament, 'prizes', [])[1] || 250,
        ]),
      }),
      ...(entryFee * 100 !== tournament.entryFee && {
        entryFee: entryFee * 100,
      }),
      ...(walletFee !== tournament.walletFee && {
        walletFee: walletFee || null,
      }),
      ...(survey != tournament.survey && { survey: survey }),
      ...(inviteToken != tournament.inviteToken && { inviteToken }),
      ...(thumbnail instanceof File && {
        thumbnail: thumbnail,
      }),
      ...(background instanceof File && {
        background: background,
      }),
      ...(event instanceof File && {
        event: event,
      }),
      ...(mobile instanceof File && {
        mobile: mobile,
      }),
      ...(ad1 && ad1.delete
        ? { ad1: 'delete' }
        : ad1 && {
            ...(ad1.image instanceof File && { ad1: ad1.image }),
            ...((ad1.link || null) !==
              propValueOr(tournament, 'imageInfo.ad1.link') && {
              ad1Link: ad1.link || '',
            }),
          }),
      ...(ad2 && ad2.delete
        ? { ad2: 'delete' }
        : ad2 && {
            ...(ad2.image instanceof File && { ad2: ad2.image }),
            ...((ad2.link || null) !==
              propValueOr(tournament, 'imageInfo.ad2.link') && {
              ad2Link: ad2.link || '',
            }),
          }),
      ...(ad3 && ad3.delete
        ? { ad3: 'delete' }
        : ad3 && {
            ...(ad3.image instanceof File && { ad3: ad3.image }),
            ...((ad3.link || null) !==
              propValueOr(tournament, 'imageInfo.ad3.link') && {
              ad3Link: ad3.link || '',
            }),
          }),
      ...(pdf1 && pdf1.delete
        ? { pdf1: 'delete' }
        : pdf1 && {
            ...(pdf1.link instanceof File && { pdf1: pdf1.link }),
            ...((pdf1.title || null) !==
              propValueOr(tournament, 'imageInfo.pdf1.title') && {
              pdf1Title: pdf1.title || '',
            }),
          }),
      ...(pdf2 && pdf2.delete
        ? { pdf2: 'delete' }
        : pdf2 && {
            ...(pdf2.link instanceof File && { pdf2: pdf2.link }),
            ...((pdf2.title || null) !==
              propValueOr(tournament, 'imageInfo.pdf2.title') && {
              pdf2Title: pdf2.title || '',
            }),
          }),
      ...(pdf3 && pdf3.delete
        ? { pdf3: 'delete' }
        : pdf3 && {
            ...(pdf3.link instanceof File && { pdf3: pdf3.link }),
            ...((pdf3.title || null) !==
              propValueOr(tournament, 'imageInfo.pdf3.title') && {
              pdf3Title: pdf3.title || '',
            }),
          }),
      ...(pdf4 && pdf4.delete
        ? { pdf4: 'delete' }
        : pdf4 && {
            ...(pdf4.link instanceof File && { pdf4: pdf4.link }),
            ...((pdf4.title || null) !==
              propValueOr(tournament, 'imageInfo.pdf4.title') && {
              pdf4Title: pdf4.title || '',
            }),
          }),
      ...(!this.areMomentsEqual(
        registrationStart,
        tournament.registrationStart
      ) && {
        registrationStart: registrationStart
          ? moment(registrationStart).format('YYYY-MM-DD HH:mm:ssZZ')
          : null,
      }),
      ...(!this.areMomentsEqual(
        registrationEnd,
        tournament.registrationEnd
      ) && {
        registrationEnd: registrationEnd
          ? moment(registrationEnd).format('YYYY-MM-DD HH:mm:ssZZ')
          : null,
      }),
      ...(!this.areMomentsEqual(checkinStart, tournament.checkinStart) && {
        checkinStart: checkinStart
          ? moment(checkinStart).format('YYYY-MM-DD HH:mm:ssZZ')
          : null,
      }),
      ...(!this.areMomentsEqual(checkinEnd, tournament.checkinEnd) && {
        checkinEnd: checkinEnd
          ? moment(checkinEnd).format('YYYY-MM-DD HH:mm:ssZZ')
          : null,
      }),
      ...(checkinRequired !== tournament.checkinRequired && {
        checkinRequired,
      }),
      ...(verifiedRequired !== tournament.verifiedRequired && {
        verifiedRequired,
      }),
      ...(preview !== tournament.preview && {
        preview: preview !== 'none' ? preview : null,
      }),
      ...(mode !== tournament.mode && {
        mode,
      }),
      ...(minPlayers !== tournament.minPlayers && { minPlayers }),
      ...(maxPlayers !== tournament.maxPlayers && { maxPlayers }),
      /* eslint eqeqeq: 0 */
      ...((ageRestrictionFrom !=
        propValueOr(tournament, 'restrictions.age.from') ||
        ageRestrictionTo != propValueOr(tournament, 'restrictions.age.to') ||
        JSON.stringify(countries) !==
          JSON.stringify(
            propValueOr(tournament, 'restrictions.countries')
          )) && {
        restrictions: JSON.stringify({
          ...((ageRestrictionFrom || ageRestrictionTo) && {
            age: {
              ...(ageRestrictionFrom && { from: parseInt(ageRestrictionFrom) }),
              ...(ageRestrictionTo && { to: parseInt(ageRestrictionTo) }),
            },
          }),
          ...(JSON.stringify(countries) !==
            JSON.stringify(
              propValueOr(tournament, 'restrictions.countries', [])
            ) && {
            countries: countries,
          }),
        }),
      }),
      ...(!isEqual(stagesData, tournament.stages) && {
        stages: JSON.stringify(stagesData),
      }),
      ...(!isEqual(
        events.map(e => ({
          communicationMessageId: e.templateId,
          event: e.name,
        })),
        tournament.events
      ) && {
        events: JSON.stringify(events),
      }),
      ...((hideStageInSchedule !== tournament?.metadata?.hideStageInSchedule ||
        (moderators &&
          xorWith(moderators, tournament?.metadata?.moderators || [], isEqual)
            ?.length > 0) ||
        matchMessage !== tournament?.metadata?.matchMessage) && {
        metadata: JSON.stringify({
          ...cloneDeep(propValueOr(tournament, 'metadata', {})),
          ...(moderators &&
            xorWith(moderators, tournament?.metadata?.moderators || [], isEqual)
              ?.length > 0 && { moderators }),
          ...(hideStageInSchedule !==
            tournament?.metadata?.hideStageInSchedule && {
            hideStageInSchedule,
          }),
          ...(matchMessage !== tournament?.metadata?.matchMessage && {
            matchMessage,
          }),
        }),
      }),
      ...(featured != tournament.featured && { featured }),
      ...(affiliationWide !== !tournament.organization &&
        !!organization.id && {
          affiliationWide: true,
          organizationId: affiliationWide ? null : organization.id,
        }),
    };

    let data = new FormData();
    for (let key in body) {
      data.append(key, body[key]);
    }

    api.admin.tournament
      .update(tournament.id, data)
      .then(resp => {
        this.setState({ ...this.initialState(this.props) });
      })
      .catch(err => {
        this.setState({
          error: propValueOr(
            err,
            'response.data.message',
            typeof err === 'string' ? err : JSON.stringify(err)
          ),
        });
      })
      .finally(() => this.setState({ updating: false }));
  };

  onShowModeratorDialog = () => {
    this.setState({ isModeratorDialogOpen: true });
  };

  render() {
    const {
      name,
      description,
      matchMessage,
      registrationStart,
      registrationEnd,
      preview,
      checkinStart,
      checkinEnd,
      checkinRequired,
      verifiedRequired,
      startTime,
      stages,
      minPlayers,
      maxPlayers,
      ageRestrictionFrom,
      ageRestrictionTo,
      isOrganizationDialogOpen,
      isModeratorDialogOpen,
      featured,
      affiliationWide,
      mode,
      countries,
      hideStageInSchedule,
      // showIcon
    } = this.state;
    const { tournament, match, organization } = this.props;
    const { bg, game, color, status, time, button } = getCardValues(tournament);

    return (
      <Content>
        <OrganizationDialog
          onClose={() => this.setState({ isOrganizationDialogOpen: false })}
          isOpen={isOrganizationDialogOpen}
        />
        {this.renderConfirmDialog()}
        <Card src={bg}>
          <Info>
            <Image src={game} />
            <Details>
              <Subtitle>
                ID {tournament.id} |{' '}
                {propValueOr(tournament, 'console.name', 'Cross Platform')} |{' '}
                {snakeToText(tournament.type)} |{' '}
                {propValueOr(tournament, 'organization.name', '')}
              </Subtitle>
              <Time>{time}</Time>
              <Status>
                <Dot color={color} />
                <Text>{status}</Text>
              </Status>
            </Details>
          </Info>
          {tournament.wallet && (
            <ButtonLinkKit
              appearance={'secondary'}
              to={`/tournaments/${match.params.token}/transactions`}
            >
              Manage Transactions
            </ButtonLinkKit>
          )}
          {button && (
            <ButtonLinkKit
              appearance={'secondary'}
              to={
                button === 'games'
                  ? `/matches?page=1&q=${tournament.id}`
                  : `/tournaments/${match.params.token}/${button}`
              }
            >
              {'Manage ' + button}
            </ButtonLinkKit>
          )}
        </Card>
        <ButtonWrap>
          <ButtonKit
            color={'rival_red'}
            shape={'rounded'}
            small
            onClick={() => this.setState({ isOrganizationDialogOpen: true })}
          >
            Change Tournament Organization
          </ButtonKit>
          <ButtonLinkKit
            shape={'rounded'}
            appearance={'secondary'}
            small
            width={250}
            target="_blank"
            rel="noopener noreferrer"
            to={`/tournaments/create/type?t=${tournament.token}`}
          >
            Create A Tournament Copy{' '}
            <img
              src={PinIcon}
              width={12}
              height={12}
              alt=""
              style={{ marginLeft: '6px', marginTop: '-3px' }}
            />
          </ButtonLinkKit>
        </ButtonWrap>
        <WrapChat>
          TOURNAMENT CHAT MONITOR
          <WrapChat>
            <WrapPinButton>
              <PinChatButton
                src={PinIcon}
                onClick={() =>
                  this.props.pinChatChannel({
                    channel: `tournament-${match.params.token}`,
                    tournament: tournament,
                  })
                }
              />
            </WrapPinButton>
            <Chat
              channel={`tournament-${match.params.token}`}
              height={'300px'}
              moderatorAt={tournament.moderatorAt}
              showModeratorDialog={
                tournament.moderatorAt ? this.onShowModeratorDialog : null
              }
            />
          </WrapChat>
        </WrapChat>
        {tournament.status !== 'complete' && <Announcements />}
        {tournament.status !== 'complete' && (
          <>
            <Header>Tournament Assets</Header>
            <TournamentPanels>
              <Panel
                trigger={'TOURNAMENT DETAILS'}
                customMargin={'0px'}
                triggerStyle={{ padding: '15px 25px' }}
                open={false}
              >
                <PanelContent>
                  <FormFieldKit
                    label={'Name of the Tournament'}
                    fullWidth
                    placeholder={tournament.name || 'Enter new name...'}
                    value={name}
                    inputProps={{ maxLength: 50 }}
                    onChange={val => this.setState({ name: val })}
                  />
                  <FormFieldKit
                    label={'Description'}
                    fullWidth
                    multiline
                    placeholder={
                      tournament.description || 'Write a new description...'
                    }
                    value={description}
                    onChange={val => this.setState({ description: val })}
                  />
                  {/* <Row>
                    <CheckboxKit
                      label={`Show Icon?`}
                      horizontalLabel
                      onChange={() =>
                        this.setState({
                          showIcon: !showIcon
                        })
                      }
                      checked={showIcon}
                      isBig
                      fullWidth
                      style={{ marginBottom: "10px" }}
                    />
                  </Row> */}
                  {tournament.type === 'scheduled' &&
                    tournament.status === 'initialized' && (
                      <>
                        <Row>
                          <FormFieldKit
                            dateTimePicker
                            fullWidth
                            label={'Registration Start'}
                            onChange={val =>
                              this.setState({ registrationStart: val })
                            }
                            value={registrationStart}
                          />
                          <FormFieldKit
                            dateTimePicker
                            fullWidth
                            label={'Registration End'}
                            onChange={val =>
                              this.setState({ registrationEnd: val })
                            }
                            value={registrationEnd}
                          />
                        </Row>
                        <Row>
                          <FormFieldKit
                            label={'Bracket Preview'}
                            description={
                              <>
                                A preview of the tournament bracket will be
                                displayed on the tournament page.
                              </>
                            }
                            fullWidth
                            multiline
                            select
                            selectOptions={BRACKET_PREVIEW_OPTIONS}
                            value={safePropValueOr(
                              BRACKET_PREVIEW_OPTIONS.find(
                                o => o.value === preview
                              ),
                              'label',
                              undefined
                            )}
                            onChange={val => this.setState({ preview: val })}
                            placeholder={
                              preview === undefined
                                ? 'Select bracket preview type'
                                : undefined
                            }
                          />
                        </Row>
                        <Row style={{ margin: '5px 0' }}>CHECK-IN</Row>
                        <Row>
                          <CheckboxKit
                            label={`Check-in Required? ${
                              !checkinStart || !checkinEnd
                                ? '(must set check-in start and end times first)'
                                : ''
                            }`}
                            horizontalLabel
                            onChange={() =>
                              this.setState({
                                checkinRequired: !checkinRequired,
                              })
                            }
                            checked={checkinRequired}
                            disabled={!checkinStart || !checkinEnd}
                            isBig
                            fullWidth
                            style={{ marginBottom: '10px' }}
                          />
                        </Row>
                        <Row>
                          <FormFieldKit
                            dateTimePicker
                            fullWidth
                            label={'Check-in Start'}
                            onChange={val =>
                              this.setState({
                                checkinStart: val,
                                ...(!val && { checkinRequired: false }),
                              })
                            }
                            value={checkinStart}
                          />
                          <FormFieldKit
                            dateTimePicker
                            fullWidth
                            label={'Check-in End'}
                            onChange={val =>
                              this.setState({
                                checkinEnd: val,
                                ...(!val && { checkinRequired: false }),
                              })
                            }
                            value={checkinEnd}
                          />
                        </Row>
                        <FormFieldKit
                          dateTimePicker
                          fullWidth
                          label={'Tournament Start Date & Time'}
                          onChange={val => this.setState({ startTime: val })}
                          value={startTime}
                        />
                      </>
                    )}
                  {tournament.status === 'initialized' && (
                    <>
                      <Row>
                        <FormFieldKit
                          label={
                            tournament.teamSize
                              ? 'Minimum Teams'
                              : 'Minimum Players'
                          }
                          fullWidth
                          required
                          placeholder={
                            tournament.teamSize
                              ? 'Enter minimum teams'
                              : 'Enter minimum players'
                          }
                          type={'number'}
                          min={'2'}
                          max={maxPlayers && maxPlayers.toString()}
                          value={minPlayers}
                          onChange={val => {
                            const newVal = parseInt(val);
                            this.setState({
                              minPlayers: isNaN(newVal) ? null : newVal,
                            });
                          }}
                          errorText={
                            (!minPlayers ||
                              (minPlayers &&
                                maxPlayers &&
                                minPlayers > maxPlayers)) &&
                            'Invalid minimum players'
                          }
                        />
                        <FormFieldKit
                          label={
                            tournament.teamSize
                              ? 'Maximum Teams'
                              : 'Maximum Players'
                          }
                          fullWidth
                          placeholder={
                            tournament.teamSize
                              ? 'Enter max teams'
                              : 'Enter max players'
                          }
                          type={'number'}
                          min={
                            ((minPlayers || minPlayers === 0) &&
                              minPlayers.toString()) ||
                            '2'
                          }
                          value={maxPlayers}
                          onChange={val => {
                            const newVal = parseInt(val);
                            this.setState({
                              maxPlayers: isNaN(newVal) ? null : newVal,
                            });
                          }}
                        />
                      </Row>
                      <Row style={{ margin: '5px 0 0' }}>
                        <FormFieldKit
                          label={'Tournament mode'}
                          description={
                            <>
                              The mode determines how players advance throught
                              the bracket and when their next match(s) become
                              available.
                              <br />
                              <br />
                              1. Match Driven - Elimination & Swiss - When a
                              player's opponent is available, the match starts
                              regardless of round, allows for playing ahead when
                              possible.
                              <br />
                              2. Round Driven - Caution - Free For All (FFA) -
                              Players must wait to play their next round match
                              until all matches in a round are complete, even if
                              their next opponent is available.
                            </>
                          }
                          fullWidth
                          multiline
                          required
                          select
                          disabled={
                            mode === undefined ||
                            stages?.some(s => s?.type === 'free for all')
                          }
                          selectOptions={TOURNAMENT_MODES}
                          value={safePropValueOr(
                            TOURNAMENT_MODES.find(tm => tm.value === mode),
                            'label',
                            'round-driven'
                          )}
                          onChange={val => {
                            this.setState({ mode: val });
                          }}
                          placeholder={
                            mode === undefined
                              ? 'Select tourament mode'
                              : undefined
                          }
                        />
                      </Row>
                      <Row>
                        <FormFieldKit
                          label={'Minimum Age (Optional)'}
                          fullWidth
                          value={ageRestrictionFrom}
                          type={'number'}
                          inputProps={{ min: 1, max: 100 }}
                          onChange={val => {
                            const newVal = val === '' ? null : parseInt(val);
                            if (!isNaN(newVal)) {
                              this.setState({ ageRestrictionFrom: newVal });
                            }
                          }}
                          placeholder={'Set a min age restriction'}
                        />
                        <FormFieldKit
                          label={'Maximum Age (Optional)'}
                          fullWidth
                          value={ageRestrictionTo}
                          type={'number'}
                          inputProps={{ min: 1, max: 100 }}
                          onChange={val => {
                            const newVal = val === '' ? null : parseInt(val);
                            if (!isNaN(newVal)) {
                              this.setState({ ageRestrictionTo: newVal });
                            }
                          }}
                          placeholder={'Set a max age restriction'}
                        />
                      </Row>
                      <Row
                        style={{
                          justifyContent: 'flex-start',
                          marginBottom: '15px',
                        }}
                      >
                        <CountryFieldKit
                          searchable
                          onChange={c =>
                            this.setState({ countries: [...countries, c] })
                          }
                        />
                        <InputChips
                          onChange={c => this.setState({ countries: c })}
                          readOnly
                          hiddenInput
                          small
                          value={countries}
                        />
                      </Row>
                      <FormFieldKit
                        label={'Featured Tournament Order Number (Optional)'}
                        description={
                          'Enter a number to set the position of this tournament in the featured tournaments slider on the Game Center. Leave blank to make this not a featured tournament.'
                        }
                        fullWidth
                        value={featured}
                        type={'number'}
                        inputProps={{ min: 1, max: 100 }}
                        onChange={val => {
                          const newVal = val === '' ? null : parseInt(val);
                          if (!isNaN(newVal)) {
                            this.setState({ featured: newVal });
                          }
                        }}
                        placeholder={'Set featured order'}
                      />
                      {organization &&
                        organization.type === 'affiliation' &&
                        organization.isPrimary === false && (
                          <FormFieldKit
                            label={`Make affiliation-wide tournament?`}
                            checkbox
                            name={'affiliationWide'}
                            checked={affiliationWide}
                            onChange={val =>
                              this.setState({ affiliationWide: val })
                            }
                            isBig
                            width={'30%'}
                          />
                        )}
                    </>
                  )}
                  {tournament.teamSize > 1 && (
                    <>
                      <Row>
                        Rules Acceptance Required for All Team Members
                        (Optional)
                      </Row>
                      <Row
                        style={{
                          justifyContent: 'flex-start',
                          marginBottom: '15px',
                        }}
                      >
                        <FormFieldKit
                          label={
                            'If checked, every member of a team must accept rules or entire team will be removed at start time'
                          }
                          checkbox
                          name={'verifiedRequired'}
                          checked={verifiedRequired}
                          onChange={val =>
                            this.setState({ verifiedRequired: val })
                          }
                          isBig
                          width={'30%'}
                        />
                      </Row>
                    </>
                  )}
                  <FormFieldKit
                    label={'Match Page Message'}
                    fullWidth
                    multiline
                    description={
                      'This will show up as the first message in the chat box on each match page in the tournament. If blank, the default message based on elimination type will be shown.'
                    }
                    placeholder={'Enter custom match page message'}
                    value={matchMessage}
                    onChange={val => this.setState({ matchMessage: val })}
                  />
                </PanelContent>
              </Panel>
              <Panel
                trigger={'TOURNAMENT PRIZES'}
                customMargin={'0px'}
                triggerStyle={{ padding: '15px 25px' }}
                open={false}
              >
                <PanelContent>
                  <Prizes
                    onChangeField={(propName, val) =>
                      this.handleChangeField(propName, val)
                    }
                    onChangePrize={this.handleChangePrize}
                    formState={this.state}
                    tournament={tournament}
                    isUpdate
                  />
                </PanelContent>
              </Panel>
              <Panel
                trigger={'TOURNAMENT IMAGES'}
                customMargin={'0px'}
                triggerStyle={{ padding: '15px 25px' }}
                overflowWhenOpen="auto"
                open={false}
              >
                <PanelContent>
                  {imageFields.map(field => (
                    <WrapInput key={field.key}>
                      <Uploader
                        key={field.key}
                        label={field.key}
                        description={field.description}
                        onChange={file =>
                          this.handleChangeImage(field.key, file)
                        }
                        constraints={field.constraints}
                        imgLabels={field.imgLabels}
                        imageDimensions={field.imageDimensions}
                        defaultImage={propValueOr(
                          this.props,
                          `tournament.imageInfo.${field.key}`
                        )}
                        onReset={() => this.setState({ [field.key]: null })}
                      />
                    </WrapInput>
                  ))}
                  {adFields.map(field => {
                    const isDeleted = propValueOr(
                      this.state,
                      `${field.key}.delete`
                    );
                    return (
                      <WrapInput key={field.key}>
                        <WrapLabel>
                          <Label>{'Tournament ' + field.key}</Label>
                          <Delete>
                            {propValueOr(
                              tournament,
                              `imageInfo.${field.key}.image`
                            ) && (
                              <IconWrap
                                onClick={() => {
                                  this.setState({
                                    [field.key]: isDeleted
                                      ? {
                                          link: propValueOr(
                                            tournament,
                                            `imageInfo.${field.key}.link`
                                          ),
                                          image: propValueOr(
                                            tournament,
                                            `imageInfo.${field.key}.image`
                                          ),
                                          delete: null,
                                        }
                                      : {
                                          link: '',
                                          image: null,
                                          delete: true,
                                        },
                                  });
                                }}
                              >
                                <Icon src={isDeleted ? UndoIcon : DeleteIcon} />
                                {isDeleted && 'Undo Delete'}
                              </IconWrap>
                            )}
                          </Delete>
                        </WrapLabel>
                        <Description>{field.description}</Description>
                        <Uploader
                          key={field.key}
                          onChange={file =>
                            this.handleChangeAdImage(field.key, file)
                          }
                          constraints={field.constraints}
                          imgLabels={field.imgLabels}
                          imageDimensions={field.imageDimensions}
                          defaultImage={propValueOr(
                            this.props,
                            `tournament.imageInfo.${field.key}.image`
                          )}
                          disabled={isDeleted}
                          onReset={() =>
                            this.handleChangeAdImage(field.key, null)
                          }
                        />
                        <FormFieldKit
                          label={`${field.key} link`}
                          name={field.key}
                          disabled={isDeleted}
                          value={this.state[field.key].link}
                          onChange={val =>
                            this.setState({
                              [field.key]: {
                                ...this.state[field.key],
                                link: val,
                              },
                            })
                          }
                          placeholder={'Enter ad link...'}
                        />
                      </WrapInput>
                    );
                  })}
                  {pdfFields.map((field, i) => {
                    const isDeleted = propValueOr(
                      this.state,
                      `${field.key}.delete`
                    );
                    return (
                      <WrapInput key={field.key}>
                        <WrapLabel>
                          <Label>{`Tournament PDF #${i + 1}`}</Label>
                          <Delete>
                            {propValueOr(
                              tournament,
                              `imageInfo.${field.key}.link`
                            ) && (
                              <IconWrap
                                onClick={() => {
                                  this.setState({
                                    [field.key]: isDeleted
                                      ? {
                                          title: propValueOr(
                                            tournament,
                                            `imageInfo.${field.key}.title`
                                          ),
                                          link: propValueOr(
                                            tournament,
                                            `imageInfo.${field.key}.link`
                                          ),
                                          delete: null,
                                        }
                                      : {
                                          link: '',
                                          image: null,
                                          delete: true,
                                        },
                                  });
                                }}
                              >
                                <Icon src={isDeleted ? UndoIcon : DeleteIcon} />
                                {isDeleted && 'Undo Delete'}
                              </IconWrap>
                            )}
                          </Delete>
                        </WrapLabel>
                        <Description>{field.description}</Description>
                        <UploaderFile
                          key={field.key}
                          onChange={file =>
                            this.handleChangePDF(field.key, file)
                          }
                          defaultFile={propValueOr(
                            this.props,
                            `tournament.imageInfo.${field.key}.link`
                          )}
                          disabled={isDeleted}
                          onReset={() => this.handleChangePDF(field.key, null)}
                        />
                        <FormFieldKit
                          label={`PDF #${i + 1} Title`}
                          name={field.key}
                          disabled={isDeleted}
                          value={this.state[field.key].title}
                          onChange={val =>
                            this.setState({
                              [field.key]: {
                                ...this.state[field.key],
                                title: val,
                              },
                            })
                          }
                          placeholder={'Enter PDF title...'}
                        />
                      </WrapInput>
                    );
                  })}
                </PanelContent>
              </Panel>
              <Panel
                trigger={'TOURNAMENT COMMUNICATIONS'}
                customMargin={'0px'}
                triggerStyle={{ padding: '15px 25px' }}
                overflowWhenOpen="auto"
                open={false}
              >
                <PanelContent>
                  <Communications
                    onChangeField={(propName, val) =>
                      this.handleChangeField(propName, val)
                    }
                    formState={this.state}
                  />
                </PanelContent>
              </Panel>
              <Panel
                trigger={'MODERATOR ASSIGNMENTS'}
                customMargin={'0px'}
                triggerStyle={{ padding: '15px 25px' }}
                overflowWhenOpen="auto"
                open={false}
              >
                <PanelContent style={{ paddingBottom: '30px', height: 'auto' }}>
                  <Moderators
                    moderators={this.state.moderators}
                    onChange={mods => this.setState({ moderators: mods })}
                  />
                </PanelContent>
              </Panel>
            </TournamentPanels>
            <Header>Stage Editor</Header>
            <Stages>
              <Row
                style={{
                  justifyContent: 'flex-start',
                  margin: '8px 0',
                  border: 'none',
                }}
              >
                <FormFieldKit
                  label={'Display Stage 1 Name in Schedule'}
                  checkbox
                  name={'hideStageInSchedule'}
                  checked={!hideStageInSchedule}
                  onChange={() =>
                    this.setState({ hideStageInSchedule: !hideStageInSchedule })
                  }
                  isBig
                  width={'30%'}
                />
              </Row>
              <StageEditor
                deleteStage={this.deleteStage}
                handleStage={this.handleStage}
                addStage={this.addStage}
                stages={stages}
                formState={{ ...tournament, ...this.state }}
              />
            </Stages>
            <ButtonKit
              color={'rival_red'}
              shape={'rounded'}
              fullWidth
              onClick={() => this.setState({ isConfirmDialogOpen: true })}
              disabled={!this.validateFields()}
            >
              Update Tournament
            </ButtonKit>
          </>
        )}
        <ModeratorDialog
          isOpen={isModeratorDialogOpen}
          onClose={() =>
            this.setState({
              isModeratorDialogOpen: false,
            })
          }
        />
      </Content>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  tournament: state.tournamentState.tournament,
  isFetchingTournament: state.tournamentState.isFetching,
  organization: state.organizationsState.organizations.find(
    o => o.id === propValueOr(state, 'user.info.organizationId', null)
  ),
});
const mapDispatchToProps = {
  fetchTournamentAction,
  pinChatChannel,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Update));
