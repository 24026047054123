import styled from "styled-components";
import {
  COLOR,
  FONT_FAMILY,
  FONT_WEIGHT
} from "../../../../../constants/theme";

export const Container = styled.div`
  background-color: ${COLOR.BG_SECONDARY};
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  margin: ${props => (props.customMargin ? props.customMargin : "5px 20px")};
  width: 100%;
  z-index: 1;
  overflow: auto;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${COLOR.TEXT_3};
  font: ${FONT_WEIGHT.MEDIUM} 14px / 1 ${FONT_FAMILY.THIN};
  text-decoration: none;
  padding: 15px 30px;
  user-select: none;
`;

export const Icon = styled.img`
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 8px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  color: ${props => (props.triggerColor ? props.triggerColor : COLOR.TEXT_3)};
  font: ${FONT_WEIGHT.MEDIUM} 14px / 1 ${FONT_FAMILY.THIN};
  text-decoration: none;
`;

export const WrapIcons = styled.div`
  svg {
    transform: rotate(45deg);
    fill: #ffffff;
    height: 19px;
    cursor: pointer;
  }
`;
