import styled from "styled-components";
import { zIndex } from "../../components/presentation/LevelUp/LevelUp";
import { COLOR } from "../../constants/theme";

export const LayoutGeneralSD = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const LayoutGeneralWrapHeaderSD = styled.div`
  position: sticky;
  flex: 0 0 auto;
  top: -1px;
  z-index: ${zIndex};
`;

export const LayoutGeneralWrapMainSD = styled.div`
  display: flex;
  flex: 1 0 auto;
`;

// for child component will be enough set height 100% for cover all height.
export const LayoutGeneralMainSD = styled.div`
  flex: 0 0 100%;
  min-width: 0;
  background-color: ${COLOR.BG_PRIMARY};
`;

export const LayoutGeneralWrapFooterSD = styled.div`
  flex: 0 0 auto;
`;
