import React, { Component } from "react";
import {
  Container,
  Content,
  // Info,
  Title,
  Date,
  Path,
  DarkPath,
  ItemWrap,
  GameImage,
  TournamentName,
  // TournamentImage,
  // TournamentIcon,
  // TournamentBanner,
  // TournamentThumbnail,
  Item,
  Icon,
  Row,
  RowItem,
  Subtitle,
  Label,
  LabelThin,
  VertPath,
  // Header,
  DescriptionWrap,
  // ImageWrap,
  // SmallImageWrap,
  StageItem,
  // Type,
  Description,
  ListWrap
  // DateItem,
  // Stage,
} from "./styled";
import moment from "moment";
import { propValueOr } from "../../../../helpers/common";
import ages from "../../../../static/icons/icon-academic-cap.svg";
import trophy from "../../../../static/icons/icon-trophy-white.svg";
import teams from "../../../../static/icons/icon-teams.svg";
// import { api } from "../../../..";
import momentTz from "moment-timezone";
import { connect } from "react-redux";
const zone_name = momentTz.tz.guess();
const timezone = momentTz.tz(zone_name).zoneAbbr();
class Review extends Component {
  render() {
    //Getting selections
    const gameConsole = propValueOr(
      this.props.consoles.find(c => c.id === this.props.system),
      "imageInfo.icon"
    );

    const { ageRestrictionFrom, ageRestrictionTo } = this.props;
    let ageText = "";
    if (ageRestrictionTo && ageRestrictionFrom) {
      ageText = `Ages ${ageRestrictionFrom} - ${ageRestrictionTo}`;
    } else if (ageRestrictionTo) {
      ageText = `Ages ${ageRestrictionTo} and under`;
    } else if (ageRestrictionFrom) {
      ageText = `Ages ${ageRestrictionFrom} and up`;
    } else {
      ageText = "All Ages ";
    }

    var type = "";
    if (this.props.type === "on-the-fly") {
      type = " On The Fly ";
    } else if (this.props.type === "scheduled") {
      type = " Scheduled ";
    }

    var teamSize = "";
    if (this.props.teamSize) {
      teamSize = this.props.teamSize + " Players";
    }

    var regStart = this.props.registrationStart;
    if (!regStart) {
      regStart = "Not Specified.";
    } else {
      regStart =
        moment(this.props.registrationStart).format("M/D/YY @ hh:mmA") +
        ` ${timezone}`;
    }

    var regEnd = this.props.registrationEnd;
    if (!regEnd) {
      regEnd = "Not Specified.";
    } else {
      regEnd =
        moment(this.props.registrationEnd).format("M/D/YY @ hh:mmA") +
        ` ${timezone}`;
    }

    var checkInStart = this.props.checkinStart;
    if (!checkInStart) {
      checkInStart = "Not Specified.";
    } else {
      checkInStart =
        moment(this.props.checkinStart).format("M/D/YY @ hh:mmA") +
        ` ${timezone}`;
    }

    var checkInEnd = this.props.checkinEnd;
    if (!checkInEnd) {
      checkInEnd = "Not Specified.";
    } else {
      checkInEnd =
        moment(this.props.checkinEnd).format("M/D/YY @ hh:mmA") +
        ` ${timezone}`;
    }

    function toTitleCase(str) {
      if (!str) return str;
      return str.replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    }

    return (
      <Container>
        <Content>
          <Title>Review</Title>
          <Path></Path>

          {/* TOURNAMENT INFO */}
          <ItemWrap>
            <Row>
              <GameImage
                src={propValueOr(this.props, "imageInfo.thumbnail", "")}
              ></GameImage>
              <Item>
                <TournamentName>{this.props.name}</TournamentName>
                <Date>
                  {moment(this.props.startTime).format("M/D/YY @ hh:mmA")}
                  {` ${timezone}`}
                </Date>
                <Row>
                  <Icon src={gameConsole}></Icon>
                  <Icon src={ages}></Icon>
                  <LabelThin>{ageText}</LabelThin>
                  <Icon src={trophy}></Icon>
                  <LabelThin>{type}</LabelThin>
                  <Icon src={teams}></Icon>
                  <LabelThin>{teamSize}</LabelThin>
                </Row>
              </Item>
            </Row>
            <DarkPath></DarkPath>
            <Row>
              <DescriptionWrap>
                <Subtitle>Description (first 200 chars)</Subtitle>
                <Description>
                  {this.props.description
                    ? this.props.description.substring(0, 200) + "..."
                    : "Not Specified."}
                </Description>
              </DescriptionWrap>
              <VertPath></VertPath>
              <ListWrap>
                <RowItem>
                  <Label>Registration Start</Label>
                  <Label>{regStart}</Label>
                </RowItem>
                <DarkPath></DarkPath>
                <RowItem>
                  <Label>Registration End</Label>
                  <Label>{regEnd}</Label>
                </RowItem>
                <DarkPath></DarkPath>
                <RowItem>
                  <Label>Check-in Start</Label>
                  <Label>{checkInStart}</Label>
                </RowItem>
                <DarkPath></DarkPath>
                <RowItem>
                  <Label>Check-in End</Label>
                  <Label>{checkInEnd}</Label>
                </RowItem>
                <DarkPath></DarkPath>
              </ListWrap>
            </Row>
          </ItemWrap>

          {/* IMAGES */}
          {/* <ItemWrap>
            <Header>
              <Title>Images</Title>
            </Header>
            <Row>
              <ImageWrap>
                <Label>Featured Tournament</Label>
                <TournamentImage
                  src={propValueOr(this.props, "imageInfo.event", "")}
                ></TournamentImage>
              </ImageWrap>
              <SmallImageWrap>
                <Label>Tournament Icon</Label>
                <TournamentIcon
                  src={propValueOr(this.props, "imageInfo.icon", "")}
                ></TournamentIcon>
              </SmallImageWrap>
              <SmallImageWrap>
                <Label>Tournament Thumbnail</Label>
                <TournamentThumbnail
                  src={propValueOr(this.props, "imageInfo.thumbnail", "")}
                ></TournamentThumbnail>
              </SmallImageWrap>
            </Row>
            <ImageWrap>
              <Label>Tournament Banner</Label>
              <TournamentBanner
                src={propValueOr(this.props, "imageInfo.background", "")}
              ></TournamentBanner>
            </ImageWrap>
          </ItemWrap> */}

          {/* STAGES */}
          {this.props.stages.map((t, i) => (
            <ItemWrap key={i}>
              <Subtitle>
                {t.name
                  ? "Stage " + (t.index + 1) + ": " + t.name
                  : "Stage " + (t.index + 1)}
              </Subtitle>
              <RowItem>
                <StageItem>
                  <RowItem>
                    <Label>Stage Start Time</Label>
                    <Label>
                      {t.startDate
                        ? moment(t.startDate).format("M/D/YY @ hh:mmA") +
                          ` ${timezone}`
                        : "Not Specified."}
                    </Label>
                  </RowItem>
                  <DarkPath></DarkPath>
                </StageItem>
                <StageItem>
                  <RowItem>
                    <Label>Stage Order</Label>
                    <Label>{t.stage}</Label>
                  </RowItem>
                  <DarkPath></DarkPath>
                </StageItem>
              </RowItem>
              <RowItem>
                <StageItem>
                  <RowItem>
                    <Label>Minimum Players</Label>
                    <Label>
                      {t.minPlayers ? t.minPlayers : "Not Specified."}
                    </Label>
                  </RowItem>
                  <DarkPath></DarkPath>
                </StageItem>
                <StageItem>
                  <RowItem>
                    <Label>Maximum Players</Label>
                    <Label>
                      {t.maxPlayers ? t.maxPlayers : "Not Specified."}
                    </Label>
                  </RowItem>
                  <DarkPath></DarkPath>
                </StageItem>
              </RowItem>
              <RowItem>
                <Label>Number of Brackets</Label>
                <Label>{t.maxBrackets}</Label>
              </RowItem>
              <DarkPath></DarkPath>
              <RowItem>
                <Label>Elimination Style</Label>
                <Label>{toTitleCase(t.type)}</Label>
              </RowItem>
              <DarkPath></DarkPath>
              {Object.entries(t.roundInfo).map(y => (
                <>
                  <RowItem key={y[0]}>
                    <Label>Round {y[0]} Start Time</Label>
                    <Label>
                      {y[1].date
                        ? moment(y[1].date).format("M/D/YY @ hh:mmA") +
                          ` ${timezone}`
                        : "Not Specified."}
                    </Label>
                  </RowItem>
                  <DarkPath></DarkPath>
                </>
              ))}
            </ItemWrap>
          ))}
        </Content>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  consoles: state.consoles.list
});

export default connect(mapStateToProps)(Review);
