import NotificationList from "./NotificationList/ListContainer";

export const routes = [
  {
    path: "/notifications",
    component: NotificationList,
    renderHeader: true,
    exact: true
  }
];
