import styled, { css } from "styled-components";
import { addUnit } from "../../../helpers/common";
import PropTypes from "prop-types";
import { COLOR, FONT_WEIGHT, FONT_FAMILY } from "../../../constants/theme";
import breakpoints from "../../../helpers/breakpoints";

export const Container = styled.div``;

export const Row = styled.div`
  display: flex;
  align-items: center;
  padding: 0 10px;
  background-color: ${({ bgColor }) => bgColor || COLOR.BG_SECONDARY};
  text-decoration: none;
  &:not(:last-child) {
    border-bottom: 1px solid ${COLOR.BORDER};
  }

  ${props =>
    !!props.as &&
    `&:hover {
    opacity: 0.8;
  }`}

  ${breakpoints.down("sm")} {
    padding: 0;

    &:not(:last-child) {
      position: relative;
      border-bottom: none;

      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 15px;
        right: 15px;
        display: flex;
        height: 1px;
        background-color: ${COLOR.BORDER};
      }
    }
  }
`;

export const Column = styled.div`
  flex-grow: ${({ innerWidth }) => (innerWidth === "grow" ? 1 : 0)};
  flex-shrink: 0;
  padding: ${({ padding }) => (!!padding ? padding : "22px 10px")};
  ${({ innerWidth }) => innerWidth === "grow" && "flex-basis: 0;"};
  width: ${({ innerWidth }) => addUnit(innerWidth)};

  ${({ innerWidth }) =>
    innerWidth === "grow" &&
    css`
      min-width: 0;
    `};

  ${({ centered }) =>
    centered &&
    css`
      text-align: center;
    `};

  ${({ hiddenBP }) =>
    hiddenBP &&
    css`
      ${breakpoints.down(hiddenBP)} {
        display: none;
      }
    `}

  ${({ breakpointsWidth }) =>
    breakpointsWidth &&
    css`
      ${breakpointsWidth.map(item => {
        return css`
          ${breakpoints.down(item.breakpoint)} {
            width: ${item.width};
          }
        `;
      })}
    `};

  ${breakpoints.down("sm")} {
    padding: 10px 15px;
  }
`;

Column.propTypes = {
  centered: PropTypes.bool,
  hiddenBP: PropTypes.oneOf(["lg", "md", "sm", "xs"]),
  breakpointsWidth: PropTypes.arrayOf(
    PropTypes.shape({
      breakpoints: PropTypes.string,
      width: PropTypes.string
    })
  ),
  innerWidth: PropTypes.oneOfType([
    PropTypes.oneOf(["grow"]),
    PropTypes.number,
    PropTypes.string
  ])
};

export const Body = styled.div``;

export const Segment = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const SegmentHeader = styled.div`
  display: flex;
  align-items: center;

  background-color: ${COLOR.BG_INPUT};
  width: 100%;
  padding: 20px;
`;

export const SegmentTitle = styled.div`
  font: ${FONT_WEIGHT.MEDIUM} 18px ${FONT_FAMILY.THICK};
`;

export const Header = styled.header`
  border-bottom: 1px solid ${COLOR.BORDER};

  ${Row} {
    height: 40px;
  }

  ${Column} {
    padding: 10px;

    ${breakpoints.down("sm")} {
      padding: 10px 15px;
    }
  }
`;

export const HeaderTitle = styled.span`
  color: ${({ fontColor }) => fontColor || COLOR.TEXT_4};
  font-size: 14px;
  font-weight: ${FONT_WEIGHT.MEDIUM};
  letter-spacing: -0.23px;
  text-transform: uppercase;

  ${breakpoints.down("sm")} {
    font-size: 12px;
  }
`;
