import styled from "styled-components";
import { COLOR, FONT_WEIGHT } from "../../../../constants/theme";

export const Container = styled.div`
  margin-top: 20px;
  color: ${COLOR.TEXT};
  font: ${FONT_WEIGHT.NORMAL} 16px;
`;

export const WrapInput = styled.div`
  width: 100%;
  margin-top: 15px;
`;
export const Input = styled.input`
  width: 100%;
  margin-bottom: 5px;
`;

export const Preview = styled.img`
  max-height: 100px;
`;

export const WrapPreview = styled.div`
  height: 100px;
  margin-top: 10px;
  margin-bottom: 10px;
`;
