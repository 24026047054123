import styled, { css } from "styled-components";
import { COLOR, FONT_FAMILY, FONT_WEIGHT } from "../../../constants/theme";
import breakpoints from "../../../helpers/breakpoints";
import { logoWhite } from "../../../constants/logo";
import { Link, NavLink } from "react-router-dom";
import { propValueOr } from "../../../helpers/common";
import iconBurgerMenu from "../../../static/icons/icon-burger-menu.png";

export const Container = styled.div`
  width: 100%;
  color: #fff;
`;

export const Bar = styled.div`
  width: 100%;
  padding: 0 30px;
  padding-top: 10px;
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;

  background-color: ${COLOR.BG_PRIMARY};

  ${({ isStickyMode }) =>
    isStickyMode &&
    css`
      box-shadow: 0 1px 6px rgba(0, 0, 0, 0.5);
    `};

  ${Bar} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 75px;

    ${breakpoints.down("sm")} {
      flex-grow: 1;
      min-width: 0;
      padding: 0 10px 0 0;
    }

    ${breakpoints.down("xs")} {
    }
  }

  ${breakpoints.down("sm")} {
    display: flex;
  }
`;

export const RivalShield = styled.img`
  width: 27px;
  height: 32px;

  margin-right: 15px;
`;

export const TitleText = styled.div`
  font: ${FONT_WEIGHT.BOLD} 20px ${FONT_FAMILY.GOTHIC_BOLD};
  text-transform: uppercase;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Settings = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

export const WrapNotifications = styled.div`
  padding: 4px 6px;
  margin: 0 8px 0 6px;
  border-radius: 50%;

  background-color: ${COLOR.BG_SECONDARY};
`;

export const WrapAvatar = styled.div`
  flex-shrink: 0;
`;

export const WrapLogo = styled(Link)`
  color: inherit;
  text-decoration: none;
`;

export const Logo = styled.div`
  width: ${props => propValueOr(props, "logo.logoWidth", "127px")};
  height: ${props => propValueOr(props, "logo.logoHeight", "15px")};
  background: url(${({ logo }) => propValueOr(logo, "logo") || logoWhite}) 0 0
    no-repeat;
  background-size: 100%;

  ${props =>
    breakpoints.down(propValueOr(props, "logo.mobile.breakpoint", "sm"))} {
    ${props =>
      propValueOr(props, "logo.mobile.logo") &&
      `background-image: url(${props.logo.mobile.logo})`};
    width: ${props => propValueOr(props, "logo.mobile.logoWidth", undefined)};
    height: ${props => propValueOr(props, "logo.mobile.logoHeight", undefined)};
  }
`;

export const WrapToggle = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 100%;
  cursor: pointer;
  margin-right: 10px;

  ${breakpoints.down("sm")} {
    display: flex;
  }
`;

export const Toggle = styled.div`
  width: 19px;
  height: 17px;
  background: url(${iconBurgerMenu}) 0 0 no-repeat;
  &:hover {
    transition: 0.3s;
    opacity: 0.8;
  }
`;

export const MobileNav = styled.div`
  height: calc(100vh - 50px);
  background-color: ${COLOR.BG_SECONDARY};
  overflow: auto;
`;

export const MobileWrapOrgSwitcher = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 20px 0px;

  border-bottom: 1px solid ${COLOR.BORDER};
`;

export const MobileNavList = styled.div``;

export const MobileNavItem = styled.div`
  margin: 0;
  border-bottom: 1px solid ${COLOR.BORDER};
  &:hover {
    background-color: ${COLOR.BG_PRIMARY};
    transition: 0.3s;
  }
`;

export const MobileNavLink = styled(NavLink)`
  display: block;
  padding: 24px;

  color: ${COLOR.FG_PRIMARY};
  font: ${FONT_WEIGHT.MEDIUM} 12px / 1 ${FONT_FAMILY.THIN};
  text-transform: uppercase;
  text-decoration: none;

  &.active {
    font-weight: ${FONT_WEIGHT.EXTRA_BOLD};
  }
`;

export const MobilePopup = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  display: none;
  overflow: auto;

  background: rgba(0, 0, 0, 0.5);

  box-shadow: 0 1px 15px 0 rgba(0, 0, 0, 0.1);

  ${breakpoints.down("sm")} {
    display: block;
  }
`;
export const MobilePopupContent = styled.div`
  max-width: 300px;
  margin-left: auto;
`;

export const WrapNav = styled.div`
  ${breakpoints.down("sm")} {
    display: none;
  }
`;
