const MATCHES_ACTIONS = {
  list: {
    request: "MATCHES/LIST_REQUEST",
    success: "MATCHES/LIST_SUCCESS",
    error: "MATCHES/LIST_ERROR"
  },
  reset: "MATCHES/RESET_STATE"
};

export default MATCHES_ACTIONS;
