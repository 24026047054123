import React, { Component } from "react";
import Table from "../../components/presentation/Table/Table";
import { connect } from "react-redux";
import {
  Content,
  Container,
  TableEmpty,
  TitleEmpty,
  List,
  Text,
  FilterBar,
  Filter,
  FilterItem,
  WrapIcon,
  WrapButton
} from "./styled";
import { withRouter } from "react-router-dom";
import usersRoutes from "./routes";
import { Route, Switch } from "react-router-dom";
import { verifyPermission, getUsername } from "../../helpers/common";
import { COLOR } from "../../constants/theme";
import Loader from "../../components/presentation/Loader/Loader";
import Pagination from "../../components/presentation/Pagination/Pagination";
import { fetchUsersAction } from "../../store/users/actions";
import withLocationSearch from "../../hoc/withLocationSearch";
import Button from "../../components/kit/Button/ButtonLinkKit";
import { FILTER_OPTIONS } from "./constants";
import * as queryString from "query-string";
import FormFieldKit from "../../components/kit/Fields/FormField/FormField";
import IconSearchGrey from "../../static/icons/icon-search-grey.svg";

class RouteUsers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      role: "user",
      searchWord: ""
    };
  }

  handleChangeFilter = value => {
    this.setState({ role: value });
    const oldFilter = queryString.parse(this.props.location.search);

    const newFilter = {
      ...oldFilter,
      page: 1,
      role: value
    };

    this.props.history.push({
      pathname: "/users",
      search: `?${queryString.stringify(newFilter)}`
    });
  };
  componentDidMount() {
    this.fetchUsers();
    const filter = queryString.parse(this.props.location.search);
    this.setState({ ...filter });
  }

  fetchUsers = () => {
    const { page = 1, role = this.state.role } = this.props.getLocationSearch();
    this.props.fetchUsersAction({ page: page, role: role });
  };

  handleSearchUsers = searchWord => {
    this.setState({ searchWord: searchWord });
    return this.props.fetchUsersAction({
      q: searchWord,
      role: this.state.role
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      this.fetchUsers();
    }
  }

  getTableColumns = () => {
    return [
      {
        title: "ID",
        propName: "id",
        renderItem(rowData, rowIndex) {
          return <Text>{rowData.id}</Text>;
        },
        innerWidth: 50
      },
      {
        title: "Name",
        propName: "fullName",
        renderItem(rowData, rowIndex) {
          return <Text>{rowData.fullName}</Text>;
        },
        innerWidth: "grow"
      },
      {
        title: "Email",
        propName: "email",
        renderItem(rowData, rowIndex) {
          return <Text>{rowData.email}</Text>;
        },
        innerWidth: "grow"
      },
      ...(this.state.role === "user"
        ? [
            {
              title: "Username",
              propName: "username",
              renderItem(rowData, rowIndex) {
                return <Text>{getUsername(rowData)}</Text>;
              },
              innerWidth: "grow"
            }
          ]
        : [])
    ];
  };

  renderDesktopContent = () => (
    <Switch>
      {usersRoutes.map(route => (
        <Route
          exact
          key={route.path}
          path={route.path}
          render={props => {
            const ContentComponent = route.contentComponent;
            return (
              <ContentComponent
                {...props}
                user={this.props.user}
                permission={verifyPermission(
                  route.path,
                  this.props.permissions
                )}
              />
            );
          }}
        />
      ))}
      <Route
        render={() => (
          <Content>
            <FilterBar>
              <Filter>
                {FILTER_OPTIONS.map(option => (
                  <FilterItem
                    key={option.value}
                    isActive={this.state.role === option.value}
                    onClick={() => this.handleChangeFilter(option.value)}
                  >
                    {option.label}
                  </FilterItem>
                ))}
              </Filter>
              {this.state.role === "admin" && (
                <WrapButton>
                  <Button
                    small
                    appearance={"secondary"}
                    to="/users/create"
                    color={"rival_red"}
                  >
                    CREATE USER
                  </Button>
                </WrapButton>
              )}
            </FilterBar>
            <FormFieldKit
              fullWidth
              value={this.state.searchWord}
              placeholder="Search for user by name, email, gamertag, twitch username or phone..."
              onChange={this.handleSearchUsers}
              icon={
                <WrapIcon>
                  <img src={IconSearchGrey} alt="search icon" />
                </WrapIcon>
              }
              iconAlign="left"
            />
            <List>
              <Table
                columns={this.getTableColumns()}
                data={this.props.users.map((u, i) => {
                  u["to"] = `/users/${u.id}`;
                  return u;
                })}
                headerBgColor={COLOR.BG_PRIMARY}
                headerColor={COLOR.GREY}
                renderContentForEmptyTable={() => (
                  <TableEmpty>
                    {this.props.loading ? (
                      <Loader isBlock />
                    ) : (
                      <TitleEmpty>NO USERS</TitleEmpty>
                    )}
                  </TableEmpty>
                )}
              />
              <Pagination withUrl total={this.props.total} />
            </List>
          </Content>
        )}
      />
    </Switch>
  );

  render() {
    return <Container>{this.renderDesktopContent()}</Container>;
  }
}

const mapStateToProps = state => ({
  user: state.user,
  organizations: state.organizationsState.organizations,
  permissions: state.permissionsState.permissions,
  users: state.usersState.users,
  page: state.usersState.page,
  total: state.usersState.total,
  loading: state.usersState.loading
});
const mapDispatchToProps = {
  fetchUsersAction
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withLocationSearch(RouteUsers)));
