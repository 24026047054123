import React from "react";
import styled, { css } from "styled-components";
import breakpoints from "../helpers/breakpoints";
import PropTypes from "prop-types";
import { get, startCase } from "lodash";
import { COLOR, FONT_FAMILY, FONT_WEIGHT } from "../constants/theme";

const Filter = ({ items, filter, onChangeFilter }) => {
  return items?.length > 0 ? (
    <Container>
      {items.map(item => (
        <Item
          key={item}
          isActive={!!get(filter, item)}
          onClick={() => onChangeFilter(item)}
        >
          {startCase(item)}
        </Item>
      ))}
    </Container>
  ) : null;
};

export default Filter;

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;

  color: ${COLOR.TEXT};
  overflow: hidden;

  border: 2px solid ${COLOR.RIVAL_RED};
  border-radius: 8px;
`;

const ACTIVE_SWITCHER_ITEM_STYLES = css`
  background-color: ${COLOR.RIVAL_RED};
  color: ${COLOR.WHITE};
`;

const NOT_ACTIVE_SWITCHER_ITEM_STYLES = css`
  background-color: "transparent";
  color: ${COLOR.WHITE};
`;

export const Item = styled.div`
  width: 180px;
  padding: 12px 10px;

  font: ${FONT_WEIGHT.EXTRA_BOLD} 14px ${FONT_FAMILY.THIN};
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;

  cursor: pointer;
  transition: all 0.3s;

  ${_IS_ACTIVE_SWITCHER_ITEM_};

  &:hover {
    ${HOVER_STYLE};
  }

  &:not(:first-child) {
    border-left: 1px solid ${COLOR.RIVAL_RED};
  }
  &:not(:last-child) {
    border-right: 1px solid ${COLOR.RIVAL_RED};
  }

  ${breakpoints.down("xs")} {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 4px;
    padding-right: 4px;
  }

  ${breakpoints.down("md")} {
    width: 150px;
  }

  ${breakpoints.down("440")} {
    min-width: 0;
    font-size: 12px;
  }
`;

Item.propTypes = {
  isActive: PropTypes.bool
};

function _IS_ACTIVE_SWITCHER_ITEM_({ isActive }) {
  return isActive
    ? ACTIVE_SWITCHER_ITEM_STYLES
    : NOT_ACTIVE_SWITCHER_ITEM_STYLES;
}

function HOVER_STYLE({ isActive }) {
  return isActive
    ? css`
        background-color: ${COLOR.RIVAL_RED};
        color: ${COLOR.WHITE};
        opacity: 0.8;
      `
    : ACTIVE_SWITCHER_ITEM_STYLES;
}
