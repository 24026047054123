import React, { Component } from "react";
import { toDoubleDigits } from "../../../helpers/common";
import moment from "moment";

class Timer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      remainingSeconds: this.getRemainingSeconds()
    };
  }

  componentDidMount() {
    this.timer = setInterval(this.tick, 1000);
  }

  componentDidUpdate(prevProps) {
    if (
      moment(prevProps.endDate).format() !== moment(this.props.endDate).format()
    ) {
      this.timer = setInterval(this.tick, 1000);
    }

    if (
      moment(prevProps.startDate).format() !==
      moment(this.props.startDate).format()
    ) {
      this.timer = setInterval(this.tick, 1000);
    }
  }

  componentWillUnmount() {
    this.stop();
  }

  getRemainingSeconds = () => {
    const now = Date.now();
    const result = this.props.endDate
      ? Math.floor((this.props.endDate - now) / 1000)
      : moment(now).diff(moment(this.props.startDate), "seconds");
    return result <= 0 ? 0 : result;
  };

  tick = () => {
    let remainingSeconds = this.getRemainingSeconds();

    if (remainingSeconds === 0) {
      this.stop();
    }

    this.setState({ remainingSeconds });
  };

  stop = () => {
    const { onEndTime } = this.props;
    clearInterval(this.timer);

    if (typeof onEndTime === "function") {
      onEndTime();
    }
  };

  render() {
    const { render, doubleDigits } = this.props;
    let { remainingSeconds } = this.state;

    let sec = remainingSeconds % 60;
    let min = Math.floor(remainingSeconds / 60) % 60;
    let hours = Math.floor(remainingSeconds / 3600);

    if (doubleDigits) {
      sec = toDoubleDigits(sec);
      min = toDoubleDigits(min);
      hours = toDoubleDigits(hours);
    }

    if (typeof render === "function") {
      return render({ hours, min, sec });
    }

    return (
      <div>
        {hours ? `${hours} : ${min}: ${sec}` : `${min}:${toDoubleDigits(sec)}`}
      </div>
    );
  }
}

export default Timer;
