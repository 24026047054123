import styled from "styled-components";
import {
  COLOR,
  FONT_FAMILY,
  FONT_WEIGHT
} from "../../../../../constants/theme";
import breakpoints from "../../../../../helpers/breakpoints";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 434px;
  margin: auto;
  padding: 15px 25px;

  border-radius: 2px;
  background-color: ${COLOR.BG_SECONDARY};

  ${breakpoints.down("xs")} {
    width: 360px;
  }
`;

export const Header = styled.div`
  letter-spacing: 1px;
  font: ${FONT_WEIGHT.NORMAL} 28px ${FONT_FAMILY.THICK};
  text-align: ${props => (props.centered ? "center" : "left")};
  text-transform: uppercase;
  color: ${COLOR.TEXT};
  margin-bottom: 30px;
  border-bottom: 1px solid ${COLOR.BORDER};
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
`;

export const Body = styled.div``;

export const Text = styled.div`
  color: ${COLOR.TEXT};
  font: ${FONT_WEIGHT.LIGHT} 14px / 1.2 ${FONT_FAMILY.THIN};
  text-align: ${props => (props.centered ? "center" : "left")};
`;

export const ButtonClose = styled.div`
  cursor: pointer;
  transition: all 0.3s;

  svg {
    transform: rotate(45deg);
    fill: ${COLOR.FG_PRIMARY};
    height: 19px;
  }

  &:hover {
    opacity: 0.8;
  }
`;

export const WrapButtons = styled.div`
  display: flex;
  margin-top: 25px;

  > :not(:last-child) {
    margin-right: 8px;
  }
`;

export const ScoreSubmitted = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;
export const CheckMark = styled.img`
  width: 75px;
  height: 75px;
  margin: 10px 0 25px;
`;
export const SubmittedText = styled.div`
  font: ${FONT_WEIGHT.NORMAL} 14px / 1.2 ${FONT_FAMILY.THIN};
  color: ${COLOR.TEXT};
`;
export const WarningCircle = styled.div`
  width: 75px;
  height: 75px;
  border-radius: 50%;
  background-color: #efab07;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 30px;
  text-align: center;
`;
export const WarningSymbol = styled.img`
  width: 36px;
  height: 36px;
  margin-left: -2px;
`;
export const WarningText = styled.div`
  font: ${FONT_WEIGHT.LIGHT} 14px / 16px ${FONT_FAMILY.THIN};
  color: ${COLOR.TEXT};
  text-align: center;
  margin: 0 0 30px;
`;
export const WarningHeader = styled.div`
  letter-spacing: 1px;
  font: ${FONT_WEIGHT.NORMAL} 28px ${FONT_FAMILY.THICK};
  text-align: center;
  text-transform: uppercase;
  color: ${COLOR.TEXT};
  margin-bottom: 30px;
`;
