import React, { Component } from "react";
import { Fragment } from "react";
import ButtonKit from "../../../components/kit/Button/ButtonKit";
import { propValueOr } from "../../../helpers/common";
import {
  Container,
  Label,
  Description,
  Input,
  ImageTitle,
  ConstraintList,
  ConstraintItem,
  ConstraintIcon,
  WrapSpecs,
  PreviewWrap,
  Preview,
  WrapButtons
} from "./styled";
import Checkmark from "../../../static/icons/icon-check-green-type.svg";
import RedX from "../../../static/icons/circle-x-red.svg";
import moment from "moment";
import imageCompression from "browser-image-compression";
import Loader from "../../../components/presentation/Loader/Loader";
import { cloneDeep, isEqual } from "lodash";

class Uploader extends Component {
  constructor(props) {
    super();

    this.inputRef = React.createRef();

    this.state = this.initialState(props);
  }

  initialState = (props = undefined) => ({
    image: null,
    constraints: this.createConstraintsObj(props),
    preview: null,
    processing: false,
    error: false,
    submitting: false,
    deleting: false,
    updated: false,
    uploaded: false,
    reset: false
  });

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.constraints, this.props.constraints)) {
      this.setState({ constraints: this.createConstraintsObj() });
    }
  }

  createConstraintsObj = (props = null) => {
    const makeLabel = (param, limits, units) => {
      if (!!limits && limits.length > 0) {
        if (limits.length === 2) {
          if (limits[0] === null)
            return `Required ${param} less than ${limits[1]}${units}`;
          else if (limits[1] === null)
            return `Required ${param} greater than ${limits[0]}${units}`;
          else
            return `Required ${param} between ${limits[0]}${units} and ${limits[1]}${units}`;
        } else if (limits.length === 1) {
          return `${param} of ${limits[0]}${units}`;
        }
      }
      return null;
    };

    const { width, height, aspectRatio } = props
      ? props.constraints
      : propValueOr(this.props, "constraints", {});
    const widthLimits = !!width
      ? {
          limits: width,
          isValid: null,
          text: makeLabel("width", width, "px")
        }
      : null;
    const heightLimits = !!height
      ? {
          limits: height,
          isValid: null,
          text: makeLabel("height", height, "px")
        }
      : null;

    let aspectRatioLimits = null;
    if (!!aspectRatio) {
      const aspectRatioLabels = aspectRatio.map(limit =>
        !!limit.label ? limit.label : `${limit.value}`
      );
      aspectRatioLimits = {
        limits: aspectRatio.map(limit => limit.value),
        isValid: null,
        text: makeLabel("width : height ratio", aspectRatioLabels, "")
      };
    }

    return {
      ...(widthLimits && { width: widthLimits }),
      ...(heightLimits && { height: heightLimits }),
      ...(aspectRatioLimits && { aspectRatio: aspectRatioLimits })
    };
  };

  validateImage = (width, height) => {
    const aspectRatio = width / height;
    let constraints = cloneDeep(this.state.constraints);

    // Width Range
    if (!!constraints.width) {
      if (constraints.width.limits.length === 2) {
        if (
          constraints.width.limits[0] === null &&
          width > constraints.width.limits[1]
        )
          constraints.width.isValid = false;
        else if (
          constraints.width.limits[1] === null &&
          width < constraints.width.limits[0]
        )
          constraints.width.isValid = false;
        else if (
          (!!constraints.width.limits[0] &&
            width < constraints.width.limits[0]) ||
          (!!constraints.width.limits[1] && width > constraints.width.limits[1])
        )
          constraints.width.isValid = false;
        else constraints.width.isValid = true;
      } else if (
        constraints.width.limits.length === 1 &&
        width !== constraints.width.limits[0]
      ) {
        constraints.width.isValid = false;
      } else {
        constraints.width.isValid = true;
      }
    }

    // Height Range
    if (!!constraints.height) {
      if (constraints.height.limits.length === 2) {
        if (
          constraints.height.limits[0] === null &&
          height > constraints.height.limits[1]
        )
          constraints.height.isValid = false;
        else if (
          constraints.height.limits[1] === null &&
          height < constraints.height.limits[0]
        )
          constraints.height.isValid = false;
        else if (
          (!!constraints.height.limits[0] &&
            height < constraints.height.limits[0]) ||
          (!!constraints.height.limits[1] &&
            height > constraints.height.limits[1])
        )
          constraints.height.isValid = false;
        else constraints.height.isValid = true;
      } else if (
        constraints.height.limits.length === 1 &&
        height !== constraints.height.limits[0]
      ) {
        constraints.height.isValid = false;
      } else {
        constraints.height.isValid = true;
      }
    }

    // Aspect Ratio
    if (!!constraints.aspectRatio) {
      if (constraints.aspectRatio.limits.length === 2) {
        if (
          constraints.aspectRatio.limits[0] === null &&
          aspectRatio > constraints.aspectRatio.limits[1]
        )
          constraints.aspectRatio.isValid = false;
        else if (
          constraints.aspectRatio.limits[1] === null &&
          aspectRatio < constraints.aspectRatio.limits[0]
        )
          constraints.aspectRatio.isValid = false;
        else if (
          (!!constraints.aspectRatio.limits[0] &&
            aspectRatio < constraints.aspectRatio.limits[0]) ||
          (!!constraints.aspectRatio.limits[1] &&
            aspectRatio > constraints.aspectRatio.limits[1])
        )
          constraints.aspectRatio.isValid = false;
        else constraints.aspectRatio.isValid = true;
      } else if (
        constraints.aspectRatio.limits.length === 1 &&
        aspectRatio !== constraints.aspectRatio.limits[0]
      ) {
        constraints.aspectRatio.isValid = false;
      } else {
        constraints.aspectRatio.isValid = true;
      }
    }

    const imageError = Object.entries(constraints).some(
      constraint => !constraint[1].isValid
    );
    this.setState({
      constraints
    });
    return imageError;
  };

  handleImage = async event => {
    if (
      event.target.files &&
      event.target.files[0] &&
      event.target.files[0].type?.includes("image")
    ) {
      const options = {
        maxSizeMB: 0.4,
        useWebWorker: true
      };
      this.setState({ processing: true, image: null });
      const originalFile = event.target.files[0];
      this.setState({ image: originalFile });
      let file;
      if (originalFile.type && originalFile.type.includes("gif")) {
        file = originalFile;
        const fileNameArr = file.name.split(".");
        if (fileNameArr.length > 1) {
          Object.defineProperty(file, "name", {
            writable: true,
            value: `${fileNameArr[0]}_${moment().format("Hmm-DMMYY")}${
              fileNameArr[1]
            }`
          });
        }
      } else {
        file = await imageCompression(event.target.files[0], options);
        const fileNameArr = file.name.split(".");
        if (fileNameArr.length > 1) {
          file.name = `${fileNameArr[0]}_${moment().format("Hmm-DMMYY")}${
            fileNameArr[1]
          }`;
        }
      }
      this.setState({ image: file });

      let reader = new FileReader();
      reader.onload = readerEvent => {
        const img = new Image();
        img.src = readerEvent.target.result;
        img.onload = e => {
          const error = this.validateImage(img.width, img.height);
          if (!!error) {
            this.setState({
              image: null,
              preview: null,
              processing: false,
              error: true
            });
            return;
          }
          this.setState({
            preview: img.src,
            processing: false,
            error: false,
            updated: true
          });
          this.props.onChange(file);
        };
      };

      reader.readAsDataURL(originalFile);
    }
  };

  reset = () => {
    this.setState(this.initialState());
    this.props.onReset();
  };

  renderImageSpecs = () => {
    const labels = this.props.imgLabels;
    const constraints = Object.entries(this.state.constraints || {});
    if (!!constraints.length || !!labels.length) {
      const labelBullets = labels.map((label, index) => {
        return (
          <ConstraintItem key={`label${index}`} isValid={null}>
            {label}
          </ConstraintItem>
        );
      });
      const constraintBullets = constraints.map(constraint => {
        const [key, obj] = constraint;
        return (
          <ConstraintItem key={key} isValid={obj.isValid}>
            {obj.text}
            {obj.isValid !== null && (
              <ConstraintIcon src={obj.isValid ? Checkmark : RedX} />
            )}
          </ConstraintItem>
        );
      });

      return [...labelBullets, ...constraintBullets];
    } else {
      return <ConstraintItem isValid={null}>None</ConstraintItem>;
    }
  };

  render() {
    const {
      label,
      description,
      imageDimensions,
      defaultImage,
      disabled
    } = this.props;
    const { processing, preview } = this.state;

    return (
      <Container disabled={disabled}>
        <Label>{label}</Label>
        <Description>{description}</Description>
        <WrapSpecs>
          <ImageTitle>Image Specs:</ImageTitle>
          <ConstraintList>{this.renderImageSpecs()}</ConstraintList>
        </WrapSpecs>
        <PreviewWrap width={imageDimensions[0]} height={imageDimensions[1]}>
          {processing ? (
            <Fragment>
              <Loader isBlock />
            </Fragment>
          ) : (
            <Fragment>
              <Preview src={disabled ? "" : preview || defaultImage} />
              <Input
                ref={this.inputRef}
                type="file"
                name={label}
                onChange={this.handleImage}
              />
            </Fragment>
          )}
        </PreviewWrap>
        <WrapButtons>
          <ButtonKit
            color={"rival_red"}
            shape={"rounded"}
            small
            onClick={() => this.inputRef.current.click()}
          >
            Select Image
          </ButtonKit>
          <ButtonKit
            appearance={"secondary"}
            shape={"rounded"}
            width={100}
            small
            onClick={this.reset}
          >
            Reset
          </ButtonKit>
        </WrapButtons>
      </Container>
    );
  }
}

export default Uploader;
