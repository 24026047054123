import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Container,
  Content,
  Header,
  NavBar,
  NavItem,
  ArrowIcon,
  WrapArrow,
  Status,
  WrapMarkButton,
  ButtonText,
  StatusBadge,
  ModeratorMessage,
  ModTitle,
  ModeratorScores,
  PlayerScoreStatus,
  PlayerTitle,
  ModText,
  WrapChat,
  StatusItem,
  CheckIcon,
  StatusList,
  PopupMenu,
  PopupMenuItem,
  OptionsMenu
} from "./styled";
import Chat from "../../components/smart/Chat/Chat";
import PopupKit from "../../components/kit/Popup/PopupKit";
import { pinChatChannel } from "../../store/chat/actions";
import Streams from "../../components/smart/Streams/Streams";
import { propValueOr } from "../../helpers/common";
import { api } from "../../index";
import Players from "./Players/Players";
import Info from "./Info/Info";
import ForfeitDialog from "./Dialogs/ForfeitDialog";
import ChangeWinnerDialog from "./Dialogs/ChangeWinnerDialog";
import ReplacePlayersDialog from "./Dialogs/ReplacePlayersDialog";
import EditScoreDialog from "./Dialogs/EditScoreDialog";
import ReadyDialog from "./Dialogs/ReadyDialog";
import VerifyDialog from "./Dialogs/VerifyDialog";
import Sidebar from "./Sidebar/Sidebar";

// Icons
import EllipsisIcon from "../../static/icons/vertical-ellipsis.svg";
import ArrowBack from "../../static/icons/chevron-arrow.svg";
import ArrowDown from "../../static/icons/chevron-arrow-down.svg";
import IconCheck from "../../static/icons/icon-check-reg-type.svg";

class RouteGame extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isStatusMenuOpen: false,
      isMenuPopupOpen: false,
      isReadyDialogOpen: false,
      isReplacePlayerDialogOpen: false,
      isForfeitDialogOpen: false,
      isChangeWinnerDialogOpen: false,
      isStreamsOpen: false,
      isVerifyDialogOpen: null,
      editScorePlayer: null,
      activeStreamIndex: 0
    };
  }

  handleStreamChange(index) {
    this.setState({ activeStreamIndex: index });
  }

  renderStreams(game) {
    const { activeStreamIndex } = this.state;
    if (!!game) {
      return (
        <Streams
          streamTabIndex={activeStreamIndex}
          onChangeStreamTab={index => this.handleStreamChange(index)}
          players={game.users}
        />
      );
    }
  }

  renderReadyDialog(game) {
    if (!!game) {
      return (
        <ReadyDialog
          isOpen={this.state.isReadyDialogOpen}
          onClose={() => this.setState({ isReadyDialogOpen: false })}
          players={game.users}
          game={game}
        />
      );
    }
  }

  renderVerifyDialog(game) {
    if (!!game) {
      return (
        <VerifyDialog
          isOpen={!!this.state.isVerifyDialogOpen}
          onClose={() => this.setState({ isVerifyDialogOpen: null })}
          player={this.state.isVerifyDialogOpen}
          game={game}
        />
      );
    }
  }

  renderEditScoreDialog(game) {
    if (!!game) {
      return (
        <EditScoreDialog
          isOpen={!!this.state.editScorePlayer}
          onClose={() => this.setState({ editScorePlayer: null })}
          player={this.state.editScorePlayer}
          game={game}
        />
      );
    }
  }

  renderForfeitDialog(game) {
    if (!!game) {
      return (
        <ForfeitDialog
          isOpen={this.state.isForfeitDialogOpen}
          onClose={() => this.setState({ isForfeitDialogOpen: false })}
          players={game.users}
          game={game}
        />
      );
    }
  }

  renderReplacePlayersDialog(game) {
    if (!!game) {
      return (
        <ReplacePlayersDialog
          isOpen={this.state.isReplacePlayerDialogOpen}
          onClose={() => this.setState({ isReplacePlayerDialogOpen: false })}
          players={game.users}
          game={game}
        />
      );
    }
  }

  renderChangeWinnerDialog(game) {
    if (!!game) {
      return (
        <ChangeWinnerDialog
          isOpen={this.state.isChangeWinnerDialogOpen}
          onClose={() => this.setState({ isChangeWinnerDialogOpen: false })}
          players={game.users}
          game={game}
        />
      );
    }
  }

  render() {
    const { game, history } = this.props;
    const { isStatusMenuOpen, isStreamsOpen } = this.state;
    const status = !game.moderatorAt
      ? "closed"
      : !!game.moderatorId
      ? "pending"
      : "open";
    const channel = propValueOr(game, "tournament.token", "")
      ? `${propValueOr(game, "tournament.token", "")}.${game.token}`
      : game.token;
    let users = propValueOr(game, "users", [{}]);
    let hasModMessage = users.some(
      u => !!propValueOr(u, "moderatorMessage.message")
    );

    const gameStatus = propValueOr(game, "status");

    return (
      <Container>
        <Content>
          <Header>
            <NavBar>
              <WrapArrow onClick={() => history.goBack()}>
                <ArrowIcon src={ArrowBack} />
              </WrapArrow>
              <NavItem>{`Game Id#: ${this.props.match.params.token}`}</NavItem>
            </NavBar>

            <Status>
              <WrapMarkButton
                onClick={() => this.setState({ isStatusMenuOpen: true })}
              >
                <ButtonText>Mark As</ButtonText>
                <ArrowIcon src={ArrowDown} />
              </WrapMarkButton>
              <StatusBadge
                status={status}
                onClick={() => this.setState({ isStatusMenuOpen: true })}
              >
                {status}
              </StatusBadge>
              <PopupKit
                disabledPortal
                isOpen={isStatusMenuOpen}
                onClose={() => this.setState({ isStatusMenuOpen: false })}
                width={100}
              >
                <StatusList>
                  <StatusItem
                    onClick={() =>
                      api.admin.matches.update(game.token, {
                        moderator: "open"
                      })
                    }
                  >
                    OPEN
                    {status === "open" && <CheckIcon src={IconCheck} />}
                  </StatusItem>
                  <StatusItem
                    onClick={() =>
                      api.admin.matches.update(game.token, {
                        moderator: "pending"
                      })
                    }
                  >
                    PENDING
                    {status === "pending" && <CheckIcon src={IconCheck} />}
                  </StatusItem>
                  <StatusItem
                    onClick={() =>
                      api.admin.matches.update(game.token, {
                        moderator: "closed"
                      })
                    }
                  >
                    CLOSED
                    {status === "closed" && <CheckIcon src={IconCheck} />}
                  </StatusItem>
                </StatusList>
              </PopupKit>
              <OptionsMenu
                src={EllipsisIcon}
                onClick={() => this.setState({ isMenuPopupOpen: true })}
              />
              <PopupKit
                isOpen={this.state.isMenuPopupOpen}
                onClose={() => this.setState({ isMenuPopupOpen: false })}
                width={180}
                disabledPortal
                withoutPadding
              >
                <PopupMenu>
                  {!!game.tournament &&
                    (gameStatus === "initialized" ||
                      gameStatus === "complete") && (
                      <PopupMenuItem
                        onClick={() =>
                          this.setState({ isReplacePlayerDialogOpen: true })
                        }
                      >
                        Replace players
                      </PopupMenuItem>
                    )}
                  {gameStatus === "initialized" && (
                    <PopupMenuItem
                      onClick={() => this.setState({ isReadyDialogOpen: true })}
                    >
                      Change Player Ready
                    </PopupMenuItem>
                  )}
                  {gameStatus !== "complete" && (
                    <PopupMenuItem
                      onClick={() =>
                        this.setState({ isForfeitDialogOpen: true })
                      }
                    >
                      Forfeit {this.isTeamTournament ? "Team" : "Player"}
                    </PopupMenuItem>
                  )}
                  {gameStatus === "complete" &&
                    (propValueOr(game, "users", []).length > 1 ? (
                      <PopupMenuItem
                        onClick={() =>
                          this.setState({ isChangeWinnerDialogOpen: true })
                        }
                      >
                        Change Score
                      </PopupMenuItem>
                    ) : (
                      <PopupMenuItem
                        onClick={() =>
                          this.setState({ isChangeWinnerDialogOpen: true })
                        }
                      >
                        Change Score
                      </PopupMenuItem>
                    ))}
                  <PopupMenuItem
                    onClick={() =>
                      this.props.history.push(`${game.token}/transactions`)
                    }
                  >
                    Manage Transactions
                  </PopupMenuItem>
                </PopupMenu>
              </PopupKit>
            </Status>
          </Header>
          {hasModMessage && (
            <ModeratorMessage>
              <ModTitle>Moderator Changed Score</ModTitle>
              <ModeratorScores>
                {propValueOr(users[0], "moderatorMessage.message") && (
                  <PlayerScoreStatus>
                    <PlayerTitle home>
                      {propValueOr(users[0], "username", "Home Score")}
                    </PlayerTitle>
                    <ModText>{`Original Score: ${propValueOr(
                      users[0],
                      "moderatorMessage.submittedScore",
                      "N/A"
                    )}`}</ModText>
                    <ModText>{`Reason: ${propValueOr(
                      users[0],
                      "moderatorMessage.message",
                      "N/A"
                    )}`}</ModText>
                  </PlayerScoreStatus>
                )}
                {users.length > 1 &&
                  propValueOr(users[1], "moderatorMessage.message") && (
                    <PlayerScoreStatus>
                      <PlayerTitle>
                        {propValueOr(users[1], "username", "Away Score")}
                      </PlayerTitle>
                      <ModText>{`Original Score: ${propValueOr(
                        users[1],
                        "moderatorMessage.submittedScore",
                        "N/A"
                      )}`}</ModText>
                      <ModText>{`Reason: ${propValueOr(
                        users[1],
                        "moderatorMessage.message",
                        "N/A"
                      )}`}</ModText>
                    </PlayerScoreStatus>
                  )}
              </ModeratorScores>
            </ModeratorMessage>
          )}
          <Players
            isStreamsOpen={isStreamsOpen}
            handleShowStreams={() =>
              this.setState({ isStreamsOpen: !isStreamsOpen })
            }
            handleVerifyScore={player =>
              this.setState({ isVerifyDialogOpen: player })
            }
            handleEditScore={player =>
              this.setState({ editScorePlayer: player })
            }
          />
          <Info />
          <WrapChat>
            {isStreamsOpen ? (
              this.renderStreams(game)
            ) : (
              <>
                <Chat
                  key={game.token}
                  channel={
                    propValueOr(game, "tournament.token", "")
                      ? `${propValueOr(game, "tournament.token", "")}.${
                          game.token
                        }`
                      : game.token
                  }
                  onExpand={() =>
                    this.props.pinChatChannel({
                      channel: channel,
                      game: { ...game }
                    })
                  }
                  status={game.status}
                  startedAt={game.createdAt}
                  endedAt={game.endedAt}
                  height={"100%"}
                />
              </>
            )}
          </WrapChat>
        </Content>
        <Sidebar />
        {this.renderReadyDialog(game)}
        {this.renderVerifyDialog(game)}
        {this.renderForfeitDialog(game)}
        {this.renderChangeWinnerDialog(game)}
        {this.renderEditScoreDialog(game)}
        {this.renderReplacePlayersDialog(game)}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  games: state.matchesState.matches,
  game: state.gameState.game,
  isFetchingGame: state.gameState.isFetching
});

const mapDispatchToProps = {
  pinChatChannel
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RouteGame));
