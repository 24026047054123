import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import RouteWallet from "./RouteWallet";
import PermissionDenied from "../denied/PermissionDenied";

class RouteWalletContainer extends Component {
  render() {
    const { permission } = this.props;
    if (!permission) {
      return <PermissionDenied />;
    }
    return <RouteWallet />;
  }
}

const mapStateProps = state => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateProps,
  mapDispatchToProps
)(withRouter(RouteWalletContainer));
