import React, { Component } from "react";
import connect from "react-redux/es/connect/connect";
import FormFieldKit from "../../../../components/kit/Fields/FormField/FormField";
import { propValueOr, safePropValueOr } from "../../../../helpers/common";
import {
  Container,
  WrapInput,
  Label,
  Description,
  Title,
  WrapLabel,
  Delete,
  Icon,
  IconWrap,
  CopiedPrize
} from "./styled";
import DeleteIcon from "../../../../static/icons/remove.svg";
import UndoIcon from "../../../../static/icons/undo.svg";
import rb from "../../../../static/icons/rival_coin.svg";
import rp from "../../../../static/icons/rival_points.svg";
import Uploader from "../Images/Uploader/Uploader";
import CheckboxKit from "../../../../components/kit/Fields/Checkbox/CheckboxKit";

class Prizes extends Component {
  constructor(props) {
    super();

    this.state = {
      preview: null,
      isDeleted: false,
      defaultImage: props.tournament?.imageInfo?.prize?.image
    };
  }

  render() {
    const { isDeleted, defaultImage } = this.state;
    const {
      formState,
      onChangeField,
      onChangePrize,
      tournament,
      isUpdate
    } = this.props;

    const {
      errors,
      clickedNext,
      firstPlace,
      secondPlace,
      firstPlaceCoins,
      secondPlaceCoins,
      entryFee,
      walletFee,
      survey,
      prize,
      prizeImage,
      useCopiedPrizeImage,
      inviteToken
    } = formState;

    return (
      <Container>
        <Title>Invite Code (optional)</Title>
        <FormFieldKit
          label={"Invite Code (will be required for tournament entry)"}
          fullWidth
          placeholder={"Enter code..."}
          inputProps={{ maxLength: 50 }}
          value={inviteToken}
          onChange={val => {
            const newVal = val || null;
            onChangeField("inviteToken", newVal);
          }}
        />
        <Title>ENTRY FEE (optional)</Title>
        <FormFieldKit
          label={"Entry Fee (Leave blank if non paid tournament)"}
          fullWidth
          placeholder={"Enter fee to join tournament..."}
          type={"number"}
          min={"0"}
          max={"1000"}
          value={entryFee}
          disabled={!!walletFee && walletFee > 0}
          onChange={val => {
            const newVal = val;
            onChangeField("entryFee", isNaN(newVal) ? null : newVal);
          }}
          errorText={clickedNext && safePropValueOr(errors, "Info.entryFee")}
        />
        <Title>Rivals FEE (optional)</Title>
        <FormFieldKit
          label={"Rivals Entry Fee (Leave blank if non paid tournament)"}
          fullWidth
          placeholder={"Enter Rivals fee to join tournament..."}
          type={"number"}
          min={"0"}
          max={"1000"}
          value={walletFee}
          disabled={!!entryFee && entryFee > 0}
          onChange={val => {
            const newVal = val;
            onChangeField("walletFee", isNaN(newVal) ? null : newVal);
          }}
          errorText={clickedNext && safePropValueOr(errors, "Info.walletFee")}
        />
        <Title>SURVEY (optional)</Title>
        <FormFieldKit
          label={"Survey (Only for non-paid)"}
          fullWidth
          placeholder={"Enter link to embed..."}
          value={survey}
          onChange={val => {
            const newVal = val || null;
            onChangeField("survey", newVal);
          }}
          errorText={clickedNext && safePropValueOr(errors, "Info.survey")}
        />
        <Title
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start"
          }}
        >
          RIVAL POINTS
          <Icon
            src={rp}
            width={20}
            height={20}
            style={{ marginLeft: "5px" }}
            alt=""
          />
          (optional)
        </Title>
        <FormFieldKit
          label={"1st place (Rival Points awarded)"}
          fullWidth
          required
          placeholder={"Enter number of points..."}
          type={"number"}
          min={(secondPlace && secondPlace.toString()) || "0"}
          max={"10000"}
          value={firstPlace}
          onChange={val => {
            const newVal = parseInt(val);
            onChangeField("firstPlace", isNaN(newVal) ? null : newVal);
          }}
          errorText={
            clickedNext && safePropValueOr(errors, "Prizes.firstPlace")
          }
        />
        <FormFieldKit
          label={"2nd Place (Rival Points awarded)"}
          required
          fullWidth
          placeholder={"Enter number of points..."}
          type={"number"}
          min={"0"}
          max={(firstPlace && firstPlace.toString()) || "10000"}
          value={secondPlace}
          onChange={val => {
            const newVal = parseInt(val);
            onChangeField("secondPlace", isNaN(newVal) ? null : newVal);
          }}
          errorText={
            clickedNext && safePropValueOr(errors, "Prizes.secondPlace")
          }
        />
        {!isUpdate && (
          <>
            <Title
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start"
              }}
            >
              Rivals
              <Icon
                src={rb}
                width={20}
                height={20}
                style={{ marginLeft: "5px" }}
                alt=""
              />
              (optional) - Your Community Wallet must have sufficient Rivals to
              create tournament
            </Title>
            <FormFieldKit
              label={"1st place (Rivals awarded)"}
              fullWidth
              placeholder={"Enter number of Rivals..."}
              type={"number"}
              min={(secondPlaceCoins && secondPlaceCoins.toString()) || "0"}
              max={"10000"}
              value={firstPlaceCoins}
              onChange={val => {
                const newVal = parseInt(val);
                onChangeField("firstPlaceCoins", isNaN(newVal) ? null : newVal);
              }}
              errorText={
                clickedNext && safePropValueOr(errors, "Prizes.firstPlaceCoins")
              }
            />
            <FormFieldKit
              label={"2nd Place (Rivals awarded)"}
              fullWidth
              placeholder={
                !firstPlaceCoins
                  ? "Enter 1st Place Rivals first"
                  : "Enter number of Rivals..."
              }
              type={"number"}
              min={"0"}
              disabled={!firstPlaceCoins}
              max={(firstPlaceCoins && firstPlaceCoins.toString()) || "10000"}
              value={secondPlaceCoins}
              onChange={val => {
                const newVal = parseInt(val);
                onChangeField(
                  "secondPlaceCoins",
                  isNaN(newVal) ? null : newVal
                );
              }}
              errorText={
                clickedNext &&
                safePropValueOr(errors, "Prizes.secondPlaceCoins")
              }
            />
          </>
        )}
        <Title>PRIZE INFORMATION (optional)</Title>
        <WrapLabel>
          <Label>Prize Image</Label>
          {isUpdate && (
            <Delete>
              {propValueOr(tournament, "imageInfo.prize.image") && (
                <IconWrap
                  onClick={() => {
                    onChangeField(
                      "prize",
                      isDeleted
                        ? propValueOr(tournament, "imageInfo.prize")
                        : {
                            image: null,
                            title: null,
                            description: null,
                            delete: true
                          }
                    );
                    this.setState({ preview: null, isDeleted: !isDeleted });
                  }}
                >
                  <Icon src={isDeleted ? UndoIcon : DeleteIcon} />
                  {isDeleted && "Undo Delete"}
                </IconWrap>
              )}
            </Delete>
          )}
        </WrapLabel>
        <Description>
          Upload a prize image to show in the points and prizes section
        </Description>
        <Uploader
          key={"prize"}
          disabled={isUpdate ? isDeleted : false}
          onChange={file => onChangePrize("prize", "image", file)}
          constraints={{
            width: [100, 600],
            height: [56, 340],
            aspectRatio: [{ value: 1.7 }, { value: 1.8 }]
          }}
          imgLabels={["16:9 Aspect Ratio"]}
          imageDimensions={[160, 90]}
          defaultImage={defaultImage || undefined}
          onReset={() => onChangePrize("prize", "image", null)}
        />
        {!isUpdate && prizeImage && (
          <div style={{ position: "relative", width: "100%", height: "1px" }}>
            <span
              style={{
                position: "absolute",
                top: "-148px",
                right: "0",
                fontSize: "16px",
                lineHeight: "1"
              }}
            >
              <CheckboxKit
                label={"Use copied prize image"}
                checked={useCopiedPrizeImage && !prize?.image}
                onChange={() =>
                  onChangeField("useCopiedPrizeImage", !useCopiedPrizeImage)
                }
              />
            </span>
            <CopiedPrize src={prizeImage} alt="" />
          </div>
        )}
        <WrapInput>
          <FormFieldKit
            label={"Prize Title"}
            name={"prizeTitle"}
            onChange={val => onChangePrize("prize", "title", val)}
            value={safePropValueOr(prize, "title", "")}
            placeholder={"Enter prize title..."}
            fullWidth
            disabled={isUpdate ? isDeleted : false}
            errorText={
              formState.clickedNext &&
              safePropValueOr(formState, `errors.Prizes.prize.title`)
            }
          />
          <FormFieldKit
            label={"Prize Description"}
            name={"prizeDescription"}
            onChange={val => onChangePrize("prize", "description", val)}
            value={safePropValueOr(prize, "description", "")}
            placeholder={"Enter prize description..."}
            multiline
            fullWidth
            disabled={isUpdate ? isDeleted : false}
            errorText={
              formState.clickedNext &&
              safePropValueOr(formState, `errors.Prizes.prize.description`)
            }
          />
        </WrapInput>
      </Container>
    );
  }
}

const mapStateToProps = state => ({});

export default connect(mapStateToProps)(Prizes);
