export const GAME_ACTIONS = {
  fetch: {
    request: "GAME/FETCH_REQUEST",
    success: "GAME/FETCH_SUCCESS",
    error: "GAME/FETCH_ERROR"
  },
  pusher: {
    update: "GAME/PUSHER_UPDATE"
  },
  reset: "GAME/RESET_STATE"
};
