import styled from "styled-components";
import breakpoints from "../../helpers/breakpoints";
import { COLOR, FONT_FAMILY, FONT_WEIGHT } from "../../constants/theme";
import { Link } from "react-router-dom";

export const Container = styled.div`
  display: flex;
  background-color: ${COLOR.BG_PRIMARY};
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  padding-bottom: 20px;
`;

export const WrapLoader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: calc(100vh - 75px);
`;

export const SnapshotSD = styled.div`
  margin-bottom: 25px;
`;

export const SnapshotRowSD = styled.div`
  background-color: ${COLOR.BG_INPUT};
  display: flex;
  color: ${COLOR.FG_PRIMARY};
  padding: 5px;
  margin: 1px 0;
`;

export const SnapshotTimeSD = styled.div`
  flex: 1;
`;

export const SnapshotHscoreSD = styled.div`
  flex: 1;
  text-align: center;
`;

export const SnapshotHstateSD = styled.div`
  flex: 1;
  text-align: center;
`;

export const SnapshotAscoreSD = styled.div`
  flex: 1;
  text-align: center;
`;

export const SnapshotAstateSD = styled.div`
  flex: 1;
  text-align: center;
`;

export const SnapshotGameClockSD = styled.div`
  flex: 1;
  text-align: center;
`;

export const EndMatchDialogSD = styled.div`
  width: 434px;
  margin: auto;

  border-radius: 2px;
  background-color: ${COLOR.BG_SECONDARY};

  ${breakpoints.down("xs")} {
    width: 320px;
  }
`;

export const EndMatchDialogStep1SD = styled.div`
  position: relative;
  padding: 22px 34px;
`;

// for step 2
export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0px 30px;
  border-radius: 15px;

  background-color: ${COLOR.BG_SECONDARY};
  color: ${COLOR.TEXT};
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 30px 0px;

  width: 100%;
`;

export const HeaderTitle = styled.div`
  padding: 0px 7px 0;
  color: ${COLOR.TEXT};
  font: ${FONT_WEIGHT.MEDIUM} 28px / 32px ${FONT_FAMILY.GOTHIC};
  text-transform: uppercase;
  text-align: center;
  display: flex;
  justify-content: center;
  flex: 1;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  > :not(:last-child) {
    margin-right: 10px;
  }

  ${breakpoints.down("800")} {
    flex-direction: column;
    > :not(:last-child) {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
`;

export const EndMatchDialogStep2TitleSD = styled.div`
  margin-bottom: 16px;
  text-align: center;
  color: ${COLOR.TEXT_3};
  font: ${FONT_WEIGHT.BOLD} 28px / 1.2 ${FONT_FAMILY.GOTHIC};
  text-transform: uppercase;
`;
export const EndMatchDialogStep2FooterSD = styled.div`
  padding: 0 32px 36px;
`;

export const EndMatchDialogLabelSD = styled.div`
  margin-bottom: 6px;
  text-align: center;
  color: ${COLOR.TEXT_3};
  font: ${FONT_WEIGHT.MEDIUM} 18px / 1.8 ${FONT_FAMILY.GOTHIC};
`;
export const EndMatchDialogLabelGamertagSD = styled.div`
  text-align: center;
  color: ${COLOR.TEXT_3};
  font: ${FONT_WEIGHT.NORMAL} 14px / 1.2 ${FONT_FAMILY.GOTHIC};
`;

export const EndMatchDialogScoreSD = styled.div`
  color: ${COLOR.TEXT_3};
  font: ${FONT_WEIGHT.MEDIUM} 24px / 28px ${FONT_FAMILY.GOTHIC};
  text-align: center;
  letter-spacing: 0.86px;
`;

export const EndMatchDialogLinkSD = styled.div`
  margin-top: 15px;
  text-align: center;
  color: ${COLOR.BLUE};
  font: ${FONT_WEIGHT.BOLD} 14px / 1.2 ${FONT_FAMILY.GOTHIC};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export const EndMatchDialogInputSD = styled.input`
  width: 100%;
  height: 35px;
  border: 0;
  border-bottom: 1px solid ${COLOR.BORDER};
  background-color: ${COLOR.BG_INPUT};
  color: ${COLOR.TEXT_3};
  text-align: center;
  font: ${FONT_WEIGHT.EXTRA_BOLD} 28px / 1.2 ${FONT_FAMILY.GOTHIC};
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin: 15px 0px;
  background-color: ${COLOR.BORDER};
`;

export const Status = styled.div`
  color: ${props =>
    props.status === "playing"
      ? COLOR.GREEN
      : props.status === "complete"
      ? COLOR.ROUGE
      : COLOR.YELLOW};
  font: ${FONT_WEIGHT.MEDIUM} 18px / 1 ${FONT_FAMILY.GOTHIC};
  text-transform: uppercase;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  margin-right: 20px;
`;
export const StatusText = styled.div`
  color: ${COLOR.TEXT};
  font: ${FONT_WEIGHT.NORMAL} 14px / 1.2 ${FONT_FAMILY.GOTHIC};
  text-align: right;
  text-transform: none;
`;

export const StatusBadge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 8px 8px 6px 8px;
  border-radius: 20px;

  font: ${FONT_WEIGHT.NORMAL} 12px ${FONT_FAMILY.GOTHIC};
  text-transform: uppercase;

  border: 1px solid
    ${({ status }) => {
      switch (status) {
        case "playing":
          return COLOR.GREEN;
        case "pending":
          return COLOR.YELLOW;
        case "complete":
          return COLOR.GRAY;
        default:
          return COLOR.GRAY;
      }
    }};
`;

export const RouteChallengeUserUploadImageSD = styled.div`
  width: 100%;
  flex: 0 0 100%;
  color: #ffffff;
`;

export const Message = styled.div`
  margin: 15px 0;
  border-bottom: 1px solid ${COLOR.BORDER};
  border-top: 1px solid ${COLOR.BORDER};
  padding: 0 0 23px 0;
`;

export const Title = styled.div`
  text-align: center;
  padding: 5px;
  font: ${FONT_WEIGHT.NORMAL} 16px ${FONT_FAMILY.GOTHIC};
  color: ${COLOR.TEXT};
  text-transform: uppercase;
`;
export const Text = styled.div`
  padding: 7px 0;
  font: ${FONT_WEIGHT.NORMAL} 14px ${FONT_FAMILY.GOTHIC};
  color: ${COLOR.GREY};
`;

/* Left Div */
export const Left = styled.div`
  display: flex;
  align-items: center;
  a {
    text-decoration: none;
    max-height: 32px;
  }
`;

export const Icon = styled.img`
  cursor: pointer;
  width: 32px;
  height: 32px;
`;

export const Label = styled.div`
  font: ${FONT_WEIGHT.MEDIUM} 20px / 28px ${FONT_FAMILY.GOTHIC_BOLD};
  color: ${COLOR.TEXT};
  letter-spacing: -0.52px;
  text-align: left;
  margin-right: 10px;
  ${breakpoints.down("700")} {
    font-size: 18px;
    line-height: 21px;
  }
  ${breakpoints.down("500")} {
    font-size: 14px;
    line-height: 16px;
  }
`;

export const GameLink = styled(Link)`
  display: inline;
  font: ${FONT_WEIGHT.MEDIUM} 20px / 28px ${FONT_FAMILY.GOTHIC_BOLD};
  text-decoration: none;
  color: ${COLOR.TEXT};

  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export const WrapGrid = styled.div`
  background-color: ${COLOR.BG_SECONDARY};
  flex-basis: 50%;
  padding: 26px;

  border: 1px solid ${COLOR.BORDER};
  border-radius: 4px;
`;

export const SnapshotTitle = styled.div`
  font: ${FONT_WEIGHT.MEDIUM} 18px ${FONT_FAMILY.GOTHIC};
  color: ${COLOR.TEXT};
  margin-bottom: 20px;
`;

export const Subtitle = styled.div`
  font: ${FONT_WEIGHT.MEDIUM} 16px ${FONT_FAMILY.GOTHIC_BOLD};
  letter-spacing: 0.28px;
  color: ${COLOR.TEXT};

  margin-bottom: 15px;
`;

export const Description = styled.div`
  font: ${FONT_WEIGHT.LIGHT} 16px ${FONT_FAMILY.GOTHIC};
  text-align: center;
  color: ${COLOR.TEXT};
  margin: 7px 0 16px;
`;

export const WrapReasonField = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  max-width: 500px;
`;

export const Reason = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${COLOR.BG_SECONDARY};
  padding: 26px;
`;

export const VerificationEntry = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  background-color: ${COLOR.BG_PRIMARY};
  height: 450px;
`;

export const Image = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font: ${FONT_WEIGHT.LIGHT} 18px ${FONT_FAMILY.GOTHIC};
  color: ${COLOR.TEXT};
  height: 400px;
  margin-bottom: 15px;
`;
export const WrapPhoto = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
export const Photo = styled.img`
  max-height: 300px;
  max-width: 100%;
  cursor: zoom-in;
`;

export const WrapTitle = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
`;

export const ImageTitle = styled.div`
  font: ${FONT_WEIGHT.LIGHT} 16px / 18px ${FONT_FAMILY.GOTHIC};
  font-weight: ${FONT_WEIGHT.LIGHT};
  color: ${COLOR.TEXT};
`;
export const ImageSubtitle = styled.div`
  font: ${FONT_WEIGHT.NORMAL} 14px / 16px ${FONT_FAMILY.GOTHIC};
  margin-top: 7px;
`;

export const WrapImageList = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  overflow: scroll;
  margin-top: 10px;
  padding: 15px 0px;
  border-top: 2px solid ${COLOR.BORDER};
  border-bottom: 2px solid ${COLOR.BORDER};
  margin-bottom: 25px;
`;

export const ImageList = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;

  > :not(:last-child) {
    margin-right: 15px;
  }
`;

export const WrapImageItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 52px;
  overflow: hidden;
  ${({ active }) =>
    active
      ? `border: 2px solid ${COLOR.RIVAL_RED};`
      : `border: 1px solid ${COLOR.WHITE};
  cursor: pointer;
  opacity: 0.4;
  &:hover {
    opacity: 1;
  }`}
`;

export const ImageItem = styled.img`
  max-height: 100%;
  max-width: 100%;
  display: inline-block;
`;

export const WrapRadioButton = styled.div`
  margin-top: 10px;
`;

export const Avatar = styled.img`
  width: 32px;
  height: 32px;
  object-fit: contain;
  border-radius: 2px;
  border: 2px solid #fff;
  margin-right: 15px;
`;

export const Scores = styled.div`
  display: flex;

  width: 100%;

  margin-bottom: 20px;

  > :not(:last-child) {
    margin-right: 15px;
  }
`;

export const Score = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: 100%;
  padding-bottom: 20px;
`;

export const ScoreSubtitle = styled.div`
  font: ${FONT_WEIGHT.LIGHT} 14px / 16px ${FONT_FAMILY.GOTHIC};
  color: ${COLOR.TEXT};
  display: flex;
  align-items: center;
`;
export const ScoreLabel = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  font: ${FONT_WEIGHT.LIGHT} 14px / 16px ${FONT_FAMILY.GOTHIC};
  color: ${COLOR.TEXT};
`;
export const Side = styled.div`
  background-color: ${props => (props.isHome ? COLOR.NAVY_BLUE : COLOR.ROUGE)};
  font: ${FONT_WEIGHT.NORMAL} 12px / 14px ${FONT_FAMILY.GOTHIC};
  text-transform: uppercase;
  color: #ffffff;
  padding: 8px 15px;
  border-radius: 15px;
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;
export const ItemTitle = styled.div`
  color: ${COLOR.TEXT};
  font: ${FONT_WEIGHT.LIGHT} 14px / 16px ${FONT_FAMILY.GOTHIC};
  margin: ${({ margin }) => (margin ? margin : "0")};
`;
export const ItemDescription = styled.div`
  color: ${COLOR.TEXT};
  font: ${FONT_WEIGHT.NORMAL} 12px / 14px ${FONT_FAMILY.GOTHIC};
  margin: 5px 0 10px;
`;
export const ItemScore = styled.div`
  display: flex;
  margin: 15px 0 0 0;
  width: 100%;
  > :not(:last-child) {
    margin-right: 20px;
  }
`;
export const ItemScoreField = styled.div`
  display: flex;
  flex-direction: ${props => (props.border ? "row" : "column")};
  ${props => props.border && `border-bottom: 2px solid ${props.border}`};
  justify-content: space-between;
  flex: 1 1 50%;
`;

export const LabelGamertag = styled.div`
  text-align: left;
  color: ${COLOR.TEXT_3};
  font: ${FONT_WEIGHT.NORMAL} 16px / 1.2 ${FONT_FAMILY.GOTHIC};
  margin-bottom: 5px;
  ${breakpoints.down("600")} {
    font-size: 14px;
  }
`;

export const LabelScore = styled.div`
  text-align: right;
  color: ${COLOR.TEXT_3};
  font: ${FONT_WEIGHT.NORMAL} 16px / 1.2 ${FONT_FAMILY.GOTHIC};
  margin-bottom: 5px;
  ${breakpoints.down("600")} {
    font-size: 14px;
  }
`;

export const EnterNew = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${COLOR.BG_SECONDARY};
  padding: 25px;
`;
export const IncorrectText = styled.div`
  font: ${FONT_WEIGHT.LIGHT} 14px / 16px ${FONT_FAMILY.GOTHIC};
  color: ${COLOR.TEXT};
  text-align: center;
  margin-bottom: 10px;
`;

export const WrapInputs = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  > :not(:last-child) {
    margin-right: 10px;
  }
`;

export const FullscreenBg = styled.div`
  background-color: rgba(0, 0, 0, 0.9);
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  top: 0px;
  justify-content: center;
  align-items: center;
  cursor: zoom-out;
  color: #ffffff;
  font-size: 24px;
`;
export const FullscreenImage = styled.img`
  max-width: 100%;
  max-height: 80%;
  ${breakpoints.down("725")} {
    max-height: 80%;
  }
`;
export const FullscreenText = styled.div`
  color: #ffffff;
  font-size: 18px;
  position: absolute;
  top: 120px;
  left: 15px;
  text-shadow: 2px 2px #000000;
  ${breakpoints.down("725")} {
    top: 150px;
  }
`;

export const ScoreTitle = styled.div`
  font: ${FONT_WEIGHT.MEDIUM} 14px / 16px ${FONT_FAMILY.GOTHIC};
  color: ${COLOR.TEXT};
  letter-spacing: 0.28px;
  text-transform: uppercase;
`;
export const ScoreTime = styled.div`
  font: ${FONT_WEIGHT.MEDIUM} 12px / 14px ${FONT_FAMILY.GOTHIC};
  color: ${COLOR.GREY_2};
  text-align: right;
`;
export const ScoreHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 8px;
`;
export const Gamertag = styled.div`
  font: ${FONT_WEIGHT.LIGHT} 14px / 16px ${FONT_FAMILY.GOTHIC};
  color: ${COLOR.TEXT};
`;
export const UserScore = styled.div`
  font: ${FONT_WEIGHT.NORMAL} 18px ${FONT_FAMILY.GOTHIC};
  color: ${COLOR.TEXT};
`;
export const TableUser = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 11px 11px 11px 22px;
  border-width: 1px 4px 1px 1px;
  border-color: ${COLOR.BORDER};
  border-style: solid;
  border-right-color: ${({ result }) =>
    result === "W"
      ? COLOR.GREEN
      : result === "L"
      ? COLOR.CHERRY_RED
      : result === "T"
      ? COLOR.YELLOW
      : COLOR.WHITE};
`;
export const ScoreTable = styled.div`
  width: 100%;

  > :not(:last-child) {
    border-bottom-width: 0px;
  }
`;
export const CompletedScore = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  max-width: 800px;

  background-color: ${COLOR.BG_SECONDARY};
  flex-basis: 50%;
  padding: 26px;
`;
export const ReasonTitle = styled.div`
  font: ${FONT_WEIGHT.MEDIUM} 16px ${FONT_FAMILY.GOTHIC_BOLD};
  color: ${COLOR.TEXT};
  letter-spacing: 0.28px;
  text-transform: uppercase;
`;
export const ReasonSubtitle = styled.div`
  font: ${FONT_WEIGHT.MEDIUM} 14px / 16px ${FONT_FAMILY.GOTHIC};
  color: ${COLOR.GREY_2};
  letter-spacing: 0.24px;
  margin-top: 11px;
`;
export const ReasonDescription = styled.div`
  font: ${FONT_WEIGHT.LIGHT} 14px / 16px ${FONT_FAMILY.GOTHIC};
  color: ${COLOR.TEXT};
  margin-top: 11px;
`;
export const CompletedReason = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: ${COLOR.BG_SECONDARY};
  flex-basis: 50%;
  padding: 26px;
`;

export const WrapUser = styled.div`
  display: flex;
  align-items: center;
`;

export const TimerContainer = styled.div`
  display: flex;
  font-size: 18px;
  line-height: 22px;
  white-space: nowrap;
  font-family: ${FONT_FAMILY.GOTHIC};
  color: ${COLOR.TEXT};
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  margin-left: 10px;
  ${breakpoints.down("700")} {
    font-size: 16px;
    line-height: 19px;
  }
  ${breakpoints.down("440")} {
    font-size: 14px;
    line-height: 16px;
  }
`;

export const WrapTimer = styled.div`
  display: flex;
  margin-left: ${props => (props.margin ? "5px" : undefined)};
  color: ${COLOR.CHERRY_RED};
`;

export const Submit = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const WrapSubmitButton = styled.div`
  width: 100%;
  max-width: 350px;
`;
