import isEmail from "validator/lib/isEmail";
import { DAYS_OF_WEEK } from "../constants/common";
import moment from "moment";
import { orderBy } from "lodash";

export const getClientFromPath = path => {
  if (path === "/") {
    return "Rival Games";
  } else if (path && typeof path === "string" && path.split("/").length > 1) {
    return path.split("/")[1];
  }
  return "";
};

export const getSubdomainFromHost = host => {
  let hostname = host;
  if (!hostname || hostname === "") {
    hostname = window.location.hostname;
  }
  const [subDomain] = hostname.split(".");
  if (!subDomain) {
    return "";
  }
  return subDomain.toLowerCase();
};
export const getAffiliationFromHost = host => {
  let hostname = host;
  if (!hostname || hostname === "") {
    hostname = window.location.hostname;
  }
  if (hostname.includes("mls")) {
    return "mls";
  }
  if (hostname.includes("lynn")) {
    return "lynn";
  }
  if (hostname.includes("aau")) {
    return "aau";
  }
  if (hostname.includes("bgca")) {
    return "bgca";
  }
  if (hostname.includes("demo")) {
    return "demo";
  }
  if (hostname.includes("rcc")) {
    return "rcc";
  }
  if (hostname.includes("magicgaming")) {
    return "magic gaming";
  }

  return "";
};

export const getAgesFromTournament = tournament => {
  const ages = propValueOr(tournament, "restrictions.age", null);
  if (ages && ages.to && ages.from) {
    return `Ages ${ages.from} - ${ages.to}`;
  } else if (ages && ages.to) {
    return `Ages ${ages.to} and under`;
  } else if (ages && ages.from) {
    return `Ages ${ages.from} and up`;
  } else {
    return "All Ages";
  }
};

export const prepareWeeksForRendering = weeks => {
  // console.error("prepareWeeksForRendering helper error. Weeks isn't array");
  if (typeof weeks !== "object") {
    return weeks;
  }

  return Object.keys(weeks).map(week => ({
    days: Object.keys(weeks[week].days).map(day => ({
      title: DAYS_OF_WEEK[day] || day,
      value: day,
      times: weeks[week].days[day].map(time => {
        const formattedTo12HoursTime = moment(time, "HH:mm").format("h:mm A");

        return { title: formattedTo12HoursTime, value: time };
      })
    }))
  }));
};

export const toDoubleDigits = num => {
  if (num === 0 || num === "0") {
    return "00";
  }

  return num < 10 ? "0" + num : num;
};

export function arrToCollectionById(arr) {
  return arr.reduce((acc, item) => {
    acc[item.id] = item;
    return acc;
  }, {});
}

export function debounce(fn, delay) {
  let timer;
  return (...args) => {
    if (timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(() => {
      timer = null;
      fn(...args);
    }, delay);
  };
}

export function snakeToText(str) {
  if (str) {
    return str.replace(/([-_][a-z])/g, group => group.replace("-", " "));
  }
}

export function formatGamertag(str) {
  if (str) {
    return str.replace(/ /g, "_");
  }
}

export function parseGamertag(str) {
  if (str) {
    return str.replace(/^psn_/, "");
  }
}

export function parseTournamentId(str) {
  if (str && typeof str === "string") {
    return str.replace(/-CL/, "");
  }
  return str;
}

export function getUsername(user, accountVar = null) {
  if (!user) {
    return "";
  }
  return !!user[accountVar]
    ? user[accountVar]
    : parseGamertag(propValueOr(user, "username", ""));
}

export function numberToWords(n) {
  var special = [
    "zeroth",
    "first",
    "second",
    "third",
    "fourth",
    "fifth",
    "sixth",
    "seventh",
    "eighth",
    "ninth",
    "tenth",
    "eleventh",
    "twelfth",
    "thirteenth",
    "fourteenth",
    "fifteenth",
    "sixteenth",
    "seventeenth",
    "eighteenth",
    "nineteenth"
  ];
  var deca = [
    "twent",
    "thirt",
    "fort",
    "fift",
    "sixt",
    "sevent",
    "eight",
    "ninet"
  ];

  if (n < 20) return special[n];
  if (n % 10 === 0) return deca[Math.floor(n / 10) - 2] + "ieth";
  return deca[Math.floor(n / 10) - 2] + "y-" + special[n % 10];
}

export function getBodyScrollWidth() {
  return window.innerWidth - document.documentElement.clientWidth;
}

export function canUserEdit(curUserInfo) {
  if (!curUserInfo) return false;

  if (!curUserInfo.authorities) return false;

  return (
    curUserInfo.authorities.indexOf("ADMIN") !== -1 ||
    curUserInfo.authorities.indexOf("SYSTEM_ADMIN") !== -1 ||
    curUserInfo.authorities.indexOf("SCHOOL_ADMIN") !== -1 ||
    curUserInfo.authorities.indexOf("TOURNAMENT_MANAGER") !== -1
  );
}

export const isValidEmail = email => isEmail(email);

export const handleEventStopPropagation = e => {
  if (typeof e.stopPropagation === "function") {
    e.stopPropagation();
  }
};

export const addUnit = value => {
  if (!value && value !== 0) return "0";

  if (typeof value === "number") {
    return value === 0 ? "0" : `${value}px`;
  }

  const lastSymbol = value[value.length - 1];

  if (!isNaN(lastSymbol)) {
    return value === "0" ? "0" : `${value}px`;
  }

  return value;
};

export const parseJwt = token => {
  if (token) {
    if (token.split(".").length > 1) {
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function(c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );

      return JSON.parse(jsonPayload);
    } else {
      return token;
    }
  } else {
    return token;
  }
};

export const numberToThousandsString = x => {
  if (!x) return x;
  const parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
};

/**
 * Get property value by path or defaultValue
 *
 * @param obj {object} - Object for finding
 * @param path {string} - path to a property
 * @param defaultValue {* = null} - default value. It will return if path will wrong. It has null by default value.
 * @return {*} Return value of property by specified path or passed default value or null if it do not pass
 */
export const propValueOr = (obj, path, defaultValue = null) => {
  if (!path) return defaultValue;

  return (
    path.split(".").reduce((acc, item) => {
      if (!acc) return acc;

      return acc[item];
    }, obj) || defaultValue
  );
};

/**
 * Safely attempt to get the property value of an object
 *
 * Note: This function works even if the end value is an empty string (''), 'false' boolean or '0' number value
 *
 * @param obj {object} - Object for finding
 * @param path {string} - (e.g. "a.b.c") path to a property in the object (separate property selectors with dots)
 * @param defaultValue {* = null} - default value. Returns this if path does not exist or resulting value is null/undefined. Default is null.
 * @return {*} Return value of property by specified path if not null/undefined, otherwise returns defaultValue
 */
export const safePropValueOr = (obj, path, defaultValue = null) => {
  if (!path) return defaultValue;
  let val = path.split(".").reduce((acc, item) => {
    if (!acc) return acc;

    return acc[item];
  }, obj);

  if (val === undefined || val === null || val === "") {
    val = defaultValue;
  }

  return val;
};

export const getBrowserInfo = () => {
  let ua = navigator.userAgent,
    tem,
    M =
      ua.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
      ) || [];

  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return { name: "IE", version: tem[1] || "" };
  }

  if (M[1] === "Chrome") {
    tem = ua.match(/\bOPR|Edge\/(\d+)/);
    if (tem != null) {
      return { name: "Opera", version: tem[1] };
    }
  }

  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];

  if ((tem = ua.match(/version\/(\d+)/i)) != null) {
    M.splice(1, 1, tem[1]);
  }

  return {
    name: M[0],
    version: M[1]
  };
};

export class FixIOSBodyOverflow {
  browserInfo = getBrowserInfo();

  addOverflow() {
    if (
      this.browserInfo.name === "Safari" &&
      this.browserInfo.version === "12"
    ) {
      document.querySelector("html").classList.add("global-modal-is-open");
    }
  }

  removeOverflow() {
    if (
      this.browserInfo.name === "Safari" &&
      this.browserInfo.version === "12"
    ) {
      document.querySelector("html").classList.remove("global-modal-is-open");
    }
  }
}

/*
 * Prepared object for bb-tags
 */

export const getSearchPreparedOrganization = organization => ({
  title: `[link to="/organization/${organization.slug}"]${organization.name}[/link]`
});

export const getSearchPreparedTournament = tournament => ({
  title: `[link to="/tournaments/${tournament.token}"]${tournament.name}[/link]`
});

export const getSearchPreparedUser = (user, consoleVar = null) => ({
  image: propValueOr(user, "imageInfo.thumbnail"),
  userProfileUrl: getUsername(user, consoleVar),
  title: `[link to="/profiles/${user.gamertag}"]${user.gamertag}[/link]`,
  ...(process.env.REACT_APP_HIDE_SCHOOLS === "false"
    ? {
        subtitle: `[link to="/organization/${propValueOr(
          user,
          "organization.slug"
        )}"]${propValueOr(user, "organization.name")}[/link]`
      }
    : {})
});

// it is used for FreePlay dialog for convert the user info object to the player object
export const getPlayerObjFromUserInfo = userInfo => ({
  image: propValueOr(userInfo, "imageInfo.thumbnail") || undefined,
  gamertag: propValueOr(userInfo, "gamertag"),
  schoolName: (
    propValueOr(userInfo, "organizations", []).find(o => o.isPrimary) || {
      displayName: ""
    }
  ).displayName,
  win: propValueOr(userInfo, "stats.win"),
  tie: propValueOr(userInfo, "stats.tie"),
  loss: propValueOr(userInfo, "stats.loss"),
  rank: propValueOr(userInfo, "stats.rank")
});

export const hasOnlyDigitsInString = str => /^\d*$/.test(str);

export const isHttpUrl = str => {
  str &&
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/gi.test(
      str
    );
};

export const isObjectEmpty = obj =>
  Object.keys(obj).length === 0 && obj.constructor === Object;

export const truncateString = (str, n, wordBoundary) => {
  if (!str) return null;
  if (str.length <= n) return str;

  const subStr = str.substr(0, n - 1);
  return wordBoundary
    ? subStr.substr(0, subStr.lastIndexOf(" "))
    : subStr + "...";
};

export const getFilteredEmails = (emails, query) => {
  return emails.filter(e => {
    if (!query || query === "") return true;
    if (!e.sender) return false;
    return e.sender.email.indexOf(query) !== -1;
  });
};

export const getSortedTemplates = (items, { type, direction }) => {
  if (!type || !direction) {
    return items;
  }

  return orderBy(
    items,
    [
      o => {
        if (isNaN(o[type])) {
          return o[type];
        } else {
          return Number(o[type]);
        }
      }
    ],
    direction
  );
};

export const getPlainTextFromRaw = content => {
  if (typeof content === "object") {
    const str = content.blocks
      .map(block => (!block.text.trim() && "\n") || block.text)
      .join("\n");
    return str.replace(/(\r\n|\n|\r)/gm, "").length > 0 ? str : null;
  }

  return null;
};

export const isJSON = str => {
  if (typeof str !== "string") {
    return false;
  } else {
    try {
      const value = JSON.parse(str);
      if (Array.isArray(value) && !value.length) {
        return false;
      }
    } catch (e) {
      return false;
    }
    return true;
  }
};

export const verifyPermission = (route, permissions) => {
  const permission = permissions.find(p => `/${p.name}` === route);
  if (permission) {
    return permission.role;
  }
  return null;
};

export const getAllRecipients = array => {
  let newArray;

  if (isJSON(array)) {
    newArray = JSON.parse(array);
  } else {
    newArray = Array.isArray(array) ? array : [];
  }

  return newArray
    .map(r => {
      const options = safePropValueOr(r, "options", null);
      return !options ? r.name : r.options;
    })
    .flat(Infinity);
};
