import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { addUnit } from "../../../helpers/common";
import { zIndex } from "../../presentation/LevelUp/LevelUp";
import { COLOR } from "../../../constants/theme";

export const PopupKitSD = styled.div`
  position: ${({ position }) => position};
  top: ${({ top }) => addUnit(top)};
  left: ${({ left }) => addUnit(left)};
  width: ${({ width }) => addUnit(width)};
  max-height: ${({ maxHeight }) => addUnit(maxHeight)};
  ${({ withoutPadding }) =>
    !withoutPadding &&
    css`
      padding: 14px 0;
    `};
  overflow: auto;

  color: ${COLOR.TEXT};
  background-color: ${({ bgColor }) => bgColor};
  box-shadow: 0 ${props => (props.moveShadowToDown ? 15 : 1)}px 15px 0
    rgba(0, 0, 0, 0.1);
  z-index: ${zIndex};
`;
PopupKitSD.propTypes = {
  position: PropTypes.oneOf(["absolute", "fixed"]).isRequired,
  top: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  left: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  maxHeight: PropTypes.number.isRequired,
  availableHeight: PropTypes.number,
  moveShadowToDown: PropTypes.bool,
  withoutPadding: PropTypes.bool,
  bgColor: PropTypes.string,
  zIndex: PropTypes.number
};

PopupKitSD.defaultProps = {
  zIndex: 3230,
  bgColor: COLOR.BG_INPUT
};

export const PopupBodyKitSD = styled.div`
  ${_ifBodyHasMaxHeightProp};
  padding: 0 15px;
`;
PopupBodyKitSD.propTypes = {
  maxHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};
function _ifBodyHasMaxHeightProp(props) {
  if (typeof props.maxWidth === "undefined") {
    return;
  }

  return css`
    overflow: auto;
    max-height: ${addUnit(props.maxWidth)};
  `;
}

export const PopupKitWithoutPortalSD = styled.div`
  z-index: ${zIndex};
`;

PopupKitWithoutPortalSD.propTypes = {
  zIndex: PropTypes.number
};
