import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import {
  Container,
  Wrap,
  GameList,
  SearchBox,
  List,
  StageListItem,
  StageHeader,
  HeaderText,
  HeaderIcon,
  Icon,
  WrapGameItem,
  GameListItem,
  ListTitle,
  ListDescription,
  ListText,
  Placeholder,
  PlaceholderCard,
  GameDetails,
  GameHeader,
  GameTitle,
  OptionsMenu,
  PopupMenu,
  PopupMenuItem,
  Stream,
  GameInfo,
  InfoPlaceholder,
  PlayerInfo,
  PlayerProfile,
  Status,
  StatusText,
  Dot,
  PlayerName,
  PlayerProfilePic,
  PlayerScore,
  HomeBadge,
  AwayBadge,
  WrapTimer,
  Refresh
} from "./styled";
import ForfeitDialog from "./Dialogs/ForfeitDialog";
import ChangeWinnerDialog from "./Dialogs/ChangeWinnerDialog";
import FormFieldKit from "../../../../../components/kit/Fields/FormField/FormField";
import PopupKit from "../../../../../components/kit/Popup/PopupKit";
import { refreshTournamentAction } from "../../../../../store/tournament/actions";
import {
  propValueOr,
  getUsername,
  debounce,
  isObjectEmpty
} from "../../../../../helpers/common";
import { getStatus } from "./constants";
import Pusher from "pusher-js";
import Chat from "../../../../../components/smart/Chat/Chat";
import Streams from "../../../../../components/smart/Streams/Streams";
import EllipsisIcon from "../../../../../static/icons/vertical-ellipsis.svg";
import placeholderUserImg from "../../../../../static/images/default-user.jpg";
import {
  fetchGameAction,
  pusherGameAction
} from "../../../../../store/game/actions";
import ReadyDialog from "./Dialogs/ReadyDialog";
import Timer from "../../../../../components/smart/Timer/Timer";
import moment from "moment";
import ButtonKit from "../../../../../components/kit/Button/ButtonKit";
import refreshIcon from "../../../../../static/icons/refresh.svg";
import downArrow from "../../../../../static/icons/outline-arrow-down.svg";
import upArrow from "../../../../../static/icons/outline-arrow-up.svg";

class Games extends Component {
  menuButtonRef = React.createRef();
  isTeamTournament = false;

  constructor(props) {
    super(props);

    this.pusher = new Pusher(process.env.REACT_APP_PUSHER_ID || "", {
      cluster: "us3",
      forceTLS: true
    });

    this.refreshTournament = debounce(this.refreshTournament.bind(this), 1000);

    this.state = {
      gameSearchTerm: "",
      searchTimerId: null,
      refreshTimerId: null,
      stageList: [],
      displayList: [],
      isFetchingTournament: true,
      tournament: null,
      activeGameToken: null,
      activeStreamIndex: 0,
      isMenuPopupOpen: false,
      isReadyDialogOpen: false,
      isForfeitDialogOpen: false,
      isChangeWinnerDialogOpen: false,
      isFocused: false
    };
  }

  componentDidMount() {
    this.isTeamTournament = !!propValueOr(this.props.tournament, "teamSize");
    const stageList = this.createStageList(this.props.tournament);
    const sortedList = stageList.sort((a, b) => this.sortGameList(a, b));
    // Get token of top game in list as first active game
    let token = null;
    const topActiveStage = sortedList.find(stage => stage.gameList.length > 0);
    if (!!topActiveStage) {
      token = topActiveStage.gameList[0].token;
    }

    window.addEventListener("focus", this.handleFocus);
    window.addEventListener("blur", this.handleBlur);

    this.setState({
      activeGameToken: token,
      stageList: sortedList,
      displayList: sortedList,
      isFetchingTournament: false
    });
    this.props.fetchGameAction(token);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.isRefreshingTournament === true &&
      prevProps.isRefreshingTournament !== this.props.isRefreshingTournament
    ) {
      const stageList = this.createStageList(this.props.tournament);
      this.setState({
        stageList: stageList.sort((a, b) => this.sortGameList(a, b)),
        displayList: stageList.map(stage => {
          return {
            ...stage,
            gameList: this.filterOnSearch(stage, this.state.gameSearchTerm)
          };
        })
      });
    }
  }

  componentWillUnmount() {
    if (!!this.state.activeGameToken) {
      this.pusher.unsubscribe(`match-${this.state.activeGameToken}`);
    }
    window.removeEventListener("focus", this.handleFocus);
    window.removeEventListener("blur", this.handleBlur);
  }

  handleFocus = () => {
    if (this.state.isFocused === false) {
      this.setState({ isFocused: true });
      const token = this.state.activeGameToken;
      if (token) {
        this.props.fetchGameAction(token);
        this.subscribeGame(token);
      }
    }
  };

  handleBlur = () => {
    if (this.state.isFocused === true) {
      this.setState({ isFocused: false });
    }
  };

  createStageList(tournament) {
    let stageList = [];
    if (!isObjectEmpty(tournament)) {
      stageList = tournament.stages.map(stage => {
        let gameList = [];
        stage.brackets.forEach(bracket => {
          bracket.matches.forEach(match => {
            gameList.push(match);
          });
        });
        return {
          ...stage,
          gameList: gameList.sort((a, b) => this.sortGameList(a, b)),
          collapsed: false
        };
      });
    }
    let sortedStageList = stageList.sort((a, b) => this.sortGameList(a, b));
    return sortedStageList;
  }

  refreshTournament() {
    if (!this.props.isRefreshingTournament) {
      this.props.refreshTournamentAction(this.props.match.params.token);
    }
  }

  subscribeGame(token) {
    const channel = this.pusher.subscribe(`match-${token}`);

    channel.unbind();

    channel.bind("reload", () => {
      this.props.fetchGameAction(token);
    });

    channel.bind("status", () => {
      this.refreshTournament();
      this.props.fetchGameAction(token);
    });

    channel.bind("match-info", data => {
      // Update users scores in redux from pusher
      this.props.pusherGameAction(data.message);
    });
  }

  handleCollapse(id) {
    let { displayList } = this.state;
    const index = displayList.findIndex(stage => stage.id === id);
    displayList[index].collapsed = !displayList[index].collapsed;
    this.setState({ displayList });
  }

  handleSearchChange(searchTerm) {
    const { stageList } = this.state;
    clearTimeout(this.state.searchTimerId);
    const timerId = setTimeout(() => {
      const newDisplayList = stageList.map(stage => {
        const newGameList = !!searchTerm
          ? this.filterOnSearch(stage, searchTerm)
          : stage.gameList;
        return { ...stage, gameList: newGameList };
      });
      this.setState({ displayList: newDisplayList });
    }, 500);
    this.setState({ searchTimerId: timerId, gameSearchTerm: searchTerm });
  }

  sortGameList(a, b) {
    const sortOrder = [
      "complete",
      "initialized",
      "pending",
      "challenged",
      "in progress",
      "playing"
    ];
    let aValue = sortOrder.findIndex(status => status === a.status);
    let bValue = sortOrder.findIndex(status => status === b.status);

    return bValue - aValue;
  }

  handleChangeActiveGame(token, id) {
    if (token === this.state.activeGameToken) {
      return;
    }

    this.pusher.unsubscribe(`match-${this.state.activeGameToken}`);

    this.subscribeGame(token);

    this.setState({ activeGameToken: token });

    this.refreshTournament();
    this.props.fetchGameAction(token);
  }

  handleStreamChange(index) {
    this.setState({ activeStreamIndex: index });
  }

  filterOnSearch(stage, gameSearchTerm) {
    return !!gameSearchTerm
      ? stage.gameList.filter(game => {
          if (game.token.toLowerCase().includes(gameSearchTerm.toLowerCase()))
            return true;
          const userIndex = game.users.findIndex(user => {
            return this.isTeamTournament
              ? user.name.toLowerCase().includes(gameSearchTerm.toLowerCase())
              : getUsername(user)
                  .toLowerCase()
                  .includes(gameSearchTerm.toLowerCase());
          });
          if (userIndex >= 0) return true;
          return false;
        })
      : stage.gameList;
  }

  renderReadyDialog(activeGame) {
    if (!!activeGame) {
      return (
        <ReadyDialog
          isOpen={this.state.isReadyDialogOpen}
          onClose={() => this.setState({ isReadyDialogOpen: false })}
          players={activeGame.users}
          game={activeGame}
          refreshTournament={this.refreshTournament}
        />
      );
    }
  }

  renderForfeitDialog(activeGame) {
    if (!!activeGame) {
      return (
        <ForfeitDialog
          isOpen={this.state.isForfeitDialogOpen}
          onClose={() => this.setState({ isForfeitDialogOpen: false })}
          players={activeGame.users}
          game={activeGame}
          teams={this.isTeamTournament}
        />
      );
    }
  }

  renderChangeWinnerDialog(activeGame) {
    if (!!activeGame) {
      return (
        <ChangeWinnerDialog
          isOpen={this.state.isChangeWinnerDialogOpen}
          onClose={() => this.setState({ isChangeWinnerDialogOpen: false })}
          players={activeGame.users}
          game={activeGame}
        />
      );
    }
  }

  render() {
    const { displayList, gameSearchTerm, activeStreamIndex } = this.state;
    const { activeGame } = this.props;

    let homePlayer = !isObjectEmpty(activeGame)
      ? activeGame.users.find(player => player.isHome)
      : null;
    let awayPlayer = !isObjectEmpty(activeGame)
      ? activeGame.users.find(player => !player.isHome)
      : null;
    return (
      <Wrap>
        <Container>
          <GameList>
            <SearchBox>
              <FormFieldKit
                value={gameSearchTerm}
                onChange={value => this.handleSearchChange(value)}
                placeholder={"Search by ID# or Names"}
                gutterBottom={false}
              />
            </SearchBox>
            <List>
              {displayList.length ? (
                displayList.map(stage => {
                  return (
                    <StageListItem key={stage.id}>
                      <StageHeader status={stage.status}>
                        <HeaderText>
                          {!!stage.name
                            ? `STAGE: ${stage.name}`
                            : `STAGE ${stage.stage}`}
                        </HeaderText>
                        <HeaderIcon
                          onClick={() => this.handleCollapse(stage.id)}
                        >
                          {stage.collapsed ? (
                            <Icon src={downArrow} />
                          ) : (
                            <Icon src={upArrow} />
                          )}
                        </HeaderIcon>
                      </StageHeader>
                      {!stage.collapsed
                        ? stage.gameList.map(game => {
                            let homePlayer = game.users.find(
                              user => user.isHome
                            );
                            let awayPlayer = game.users.find(
                              user => !user.isHome
                            );
                            return (
                              <WrapGameItem key={game.token}>
                                <GameListItem
                                  status={game.status}
                                  active={
                                    game.token === this.state.activeGameToken
                                  }
                                  onClick={() =>
                                    this.handleChangeActiveGame(game.token)
                                  }
                                >
                                  <ListDescription>
                                    Round {game.round}
                                  </ListDescription>
                                  <ListTitle>{`GAME ID#: ${game.token}`}</ListTitle>
                                  <ListText>
                                    {this.isTeamTournament
                                      ? homePlayer.team.name
                                      : getUsername(homePlayer)}
                                    {game.status === "initialized" && (
                                      <Dot
                                        status={getStatus(homePlayer, game)}
                                        style={{ margin: "0 5px" }}
                                      />
                                    )}{" "}
                                    vs{" "}
                                    {game.status === "initialized" && (
                                      <Dot
                                        status={getStatus(awayPlayer, game)}
                                        style={{ margin: "0 5px" }}
                                      />
                                    )}
                                    {this.isTeamTournament
                                      ? awayPlayer.team.name
                                      : getUsername(awayPlayer)}
                                  </ListText>
                                </GameListItem>
                              </WrapGameItem>
                            );
                          })
                        : null}
                    </StageListItem>
                  );
                })
              ) : (
                <Placeholder>No Games Found</Placeholder>
              )}
            </List>
          </GameList>
          <GameDetails>
            <GameHeader>
              <GameTitle>
                Game ID#:{" "}
                {!isObjectEmpty(activeGame) ? activeGame.token : "N/A"} --{" "}
                {activeGame.status || ""}
              </GameTitle>
              <Refresh>
                <ButtonKit
                  appearance={"secondary"}
                  onClick={this.refreshTournament}
                  small
                >
                  <img width={20} height={20} src={refreshIcon} alt="" />
                </ButtonKit>
              </Refresh>
              <OptionsMenu
                src={EllipsisIcon}
                onClick={() => this.setState({ isMenuPopupOpen: true })}
                ref={this.menuButtonRef}
              />
              <PopupKit
                isOpen={this.state.isMenuPopupOpen}
                onClose={() => this.setState({ isMenuPopupOpen: false })}
                anchorEl={this.menuButtonRef}
                minWidth={180}
                withoutPadding
              >
                <PopupMenu>
                  {propValueOr(activeGame, "status") === "initialized" && (
                    <PopupMenuItem
                      onClick={() => this.setState({ isReadyDialogOpen: true })}
                    >
                      Change Player Ready
                    </PopupMenuItem>
                  )}
                  {propValueOr(activeGame, "status") !== "complete" && (
                    <PopupMenuItem
                      onClick={() =>
                        this.setState({ isForfeitDialogOpen: true })
                      }
                    >
                      Forfeit {this.isTeamTournament ? "Team" : "Player"}
                    </PopupMenuItem>
                  )}
                  {propValueOr(activeGame, "status") === "complete" && (
                    <PopupMenuItem
                      onClick={() =>
                        this.setState({ isChangeWinnerDialogOpen: true })
                      }
                    >
                      Change Score
                    </PopupMenuItem>
                  )}
                </PopupMenu>
              </PopupKit>
            </GameHeader>
            {activeGame &&
              !isObjectEmpty(activeGame) &&
              (["initialized", "pending", "challenged"].includes(
                activeGame.status
              ) ||
                (activeGame.status === "playing" &&
                  activeGame.readyRequired)) && (
                <WrapTimer>
                  {activeGame.status === "pending"
                    ? "Score Entry: "
                    : activeGame.status === "challenged"
                    ? "Challenge Clock: "
                    : activeGame.status === "initialized"
                    ? "Forfeit Clock: "
                    : "Play Window: "}
                  <span>
                    <Timer
                      endDate={
                        activeGame.status === "pending"
                          ? moment(activeGame.updatedAt).add(3, "minutes")
                          : activeGame.status === "challenged"
                          ? moment(activeGame.updatedAt).add(15, "minutes")
                          : moment(activeGame.forfeitAt)
                      }
                    />
                  </span>
                </WrapTimer>
              )}
            {!!activeGame ? (
              <Stream>
                <Streams
                  streamTabIndex={activeStreamIndex}
                  onChangeStreamTab={index => this.handleStreamChange(index)}
                  players={
                    !isObjectEmpty(activeGame) ? [homePlayer, awayPlayer] : []
                  }
                />
              </Stream>
            ) : (
              <PlaceholderCard height={"200px"}>
                No Stream Available
              </PlaceholderCard>
            )}
            <GameInfo>
              <HomeBadge>Home</HomeBadge>
              {!isObjectEmpty(activeGame) ? (
                <Fragment>
                  <PlayerInfo>
                    <PlayerProfile player={"home"}>
                      <PlayerName>
                        {this.isTeamTournament
                          ? propValueOr(homePlayer, "team.name")
                          : getUsername(homePlayer)}
                      </PlayerName>
                      <Status>
                        <StatusText>
                          {getStatus(homePlayer, activeGame)}
                        </StatusText>
                        <Dot status={getStatus(homePlayer, activeGame)} />
                      </Status>
                    </PlayerProfile>
                    <PlayerProfilePic
                      src={propValueOr(
                        this.isTeamTournament ? homePlayer.team : homePlayer,
                        "imageInfo.thumbnail",
                        {}
                      )}
                      onError={e => (e.target.src = placeholderUserImg)}
                    />
                  </PlayerInfo>
                  <PlayerScore>
                    {propValueOr(homePlayer, "outcome") &&
                      "(" + homePlayer.outcome + ") "}
                    {["initialized", "playing"].includes(
                      propValueOr(activeGame, "status")
                    )
                      ? " - "
                      : `${homePlayer.score} - ${awayPlayer.score}`}
                    {propValueOr(awayPlayer, "outcome") &&
                      " (" + awayPlayer.outcome + ")"}
                  </PlayerScore>
                  <PlayerInfo>
                    <PlayerProfilePic
                      src={propValueOr(
                        this.isTeamTournament ? awayPlayer.team : awayPlayer,
                        "imageInfo.thumbnail",
                        {}
                      )}
                      onError={e => (e.target.src = placeholderUserImg)}
                    />
                    <PlayerProfile>
                      <PlayerName>
                        {this.isTeamTournament
                          ? propValueOr(awayPlayer, "team.name")
                          : getUsername(awayPlayer)}
                      </PlayerName>
                      <Status>
                        <Dot status={getStatus(awayPlayer, activeGame)} />
                        <StatusText>
                          {getStatus(awayPlayer, activeGame)}
                        </StatusText>
                      </Status>
                    </PlayerProfile>
                  </PlayerInfo>
                </Fragment>
              ) : (
                <InfoPlaceholder>N/A</InfoPlaceholder>
              )}
              <AwayBadge>Away</AwayBadge>
            </GameInfo>
            {!isObjectEmpty(activeGame) ? (
              <Chat
                channel={`${this.props.match.params.token}.${activeGame.token}`}
                status={activeGame.status}
                startedAt={activeGame.createdAt}
                endedAt={activeGame.endedAt}
                height={"300px"}
              />
            ) : (
              <PlaceholderCard height={"200px"}>
                No Chat Available
              </PlaceholderCard>
            )}
          </GameDetails>
          {this.renderReadyDialog(activeGame)}
          {this.renderForfeitDialog(activeGame)}
          {this.renderChangeWinnerDialog(activeGame)}
        </Container>
      </Wrap>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  tournament: state.tournamentState.tournament,
  isRefreshingTournament: state.tournamentState.isRefreshing,
  activeGame: state.gameState.game
});

const mapDispatchToProps = {
  refreshTournamentAction,
  fetchGameAction,
  pusherGameAction
};

export default connect(mapStateToProps, mapDispatchToProps)(Games);
