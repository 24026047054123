import styled from "styled-components";
import { COLOR, FONT_WEIGHT, FONT_FAMILY } from "../../../constants/theme";
import breakpoints from "../../../helpers/breakpoints";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  padding: 25px;
  border-radius: 16px;

  width: 450px;

  background-color: ${COLOR.BG_SECONDARY};
  color: ${COLOR.TEXT};

  ${breakpoints.down("sm")} {
    width: 600px;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
  padding-bottom: 20px;
  border-bottom: 1px solid ${COLOR.BORDER};
`;

export const Title = styled.div`
  font: ${FONT_WEIGHT.NORMAL} 20px ${FONT_FAMILY.GOTHIC_BOLD};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  padding-top: 20px;

  height: 100%;
  max-height: 700px;

  overflow-y: auto;

  > :not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const GameCard = styled.img`
  width: 94px;
  height: 141px;

  border-radius: 8px;
  overflow: hidden;
  box-shadow: -2px 2px 10px black;
`;

export const WrapGameCard = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  border-radius: 10px;

  cursor: pointer;
`;

export const WrapText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0 20px;
  font: ${FONT_WEIGHT.NORMAL} 18px ${FONT_FAMILY.THIN};
  text-align: center;
`;

export const WrapButtons = styled.div`
  display: flex;
  justify-content: center;

  width: 100%;
  padding: 15px 0;

  > :not(:last-child) {
    margin-right: 20px;
  }
`;
