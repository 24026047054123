import React, { Component } from "react";
import {
  Container,
  Content,
  List,
  Item,
  Header,
  Info,
  Icon,
  Status,
  Badge,
  Filter,
  FilterItem,
  SearchField,
  Name,
  ButtonWrap,
  TeamsIcon,
  DateBadge,
  StatusBadge,
  IconWrap,
  LinkBadge,
  ArrowIcon,
  WrapLink,
  TeamBadge
} from "./styled";
import FormField from "../../../../components/kit/Fields/FormField/FormField";
import IconSearch from "../../../../components/kit/Icon/IconSearch";
import ButtonKit from "../../../../components/kit/Button/ButtonKit";
import Pagination from "../../../../components/presentation/Pagination/Pagination";
import { propValueOr } from "../../../../helpers/common";
import moment from "moment";
import crossPlatform from "../../../../static/icons/cross-platform.svg";
import TeamsSVG from "../../../../static/icons/icon-teams.svg";
import DeleteIcon from "../../../../static/icons/remove.svg";
import DeleteDialog from "../DeleteDialog/DeleteDialog";
import ArrowRight from "../../../../static/icons/arrow-right-white.svg";
import { COLOR } from "../../../../constants/theme";
class Tournaments extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editMode: false,
      deleteTournament: null,
      isDeleteTournamentDialogOpen: false
    };
  }

  toggleEditMode() {
    this.setState({ editMode: !this.state.editMode });
  }

  handleChangeStatusFilter = status => this.props.onChangeFilter({ status });

  handleChangeSearchFilter = q => this.props.onChangeFilter({ q });

  renderDeleteDialog() {
    return (
      <DeleteDialog
        isOpen={this.state.isDeleteTournamentDialogOpen}
        onClose={() => {
          this.setState({ isDeleteTournamentDialogOpen: false });
          this.props.getTournaments();
        }}
        tournament={this.state.deleteTournament}
      />
    );
  }

  render() {
    const { user, tournaments, filter, total, searchValue } = this.props;
    const { editMode } = this.state;

    let slug = propValueOr(
      propValueOr(this.props, "organizations", []).find(
        org => org.id === propValueOr(this.props, "user.organizationId")
      ),
      "slug"
    );
    if (window.location.hostname.includes("luigi"))
      slug = !!slug ? `${slug}.luigi.` : `luigi.`;
    else if (slug === null) slug = "www.";

    const noDeleteButton = propValueOr(user, "role") !== "admin";

    return (
      <Container>
        {this.renderDeleteDialog()}
        <Content>
          <Header>
            <Filter>
              <FilterItem
                key={"initialized"}
                isActive={filter.status === "initialized"}
                onClick={() => this.handleChangeStatusFilter("initialized")}
              >
                INITIALIZED
              </FilterItem>
              <FilterItem
                key={"in progress"}
                isActive={filter.status === "in progress"}
                onClick={() => this.handleChangeStatusFilter("in progress")}
              >
                IN PROGRESS
              </FilterItem>
              <FilterItem
                key={"complete"}
                isActive={filter.status === "complete"}
                onClick={() => this.handleChangeStatusFilter("complete")}
              >
                COMPLETE
              </FilterItem>
            </Filter>
          </Header>
          <SearchField>
            <FormField
              iconAlign={"left"}
              fullWidth
              icon={<IconSearch />}
              value={searchValue}
              placeholder={"Search by tournament id, token or name..."}
              gutterBottom={false}
              onChange={value => {
                this.props.onChangeSearch(value);
                this.handleChangeSearchFilter(value);
              }}
            />
            {!noDeleteButton ? (
              <ButtonWrap>
                <ButtonKit
                  active={editMode}
                  appearance={"secondary"}
                  color="red"
                  onClick={() => this.toggleEditMode()}
                  small
                >
                  Delete Mode
                </ButtonKit>
              </ButtonWrap>
            ) : null}
          </SearchField>

          <List>
            {(tournaments || []).map(tournament => {
              const status = tournament.status;
              const mods = (tournament?.metadata?.moderators || [])
                .map(m => m?.value)
                ?.join(", ");
              return (
                <Item key={tournament.id}>
                  <Header>
                    <WrapLink to={`/tournaments/${tournament.token}`}>
                      <Info>
                        <Icon
                          src={propValueOr(
                            tournament,
                            "Game.imageInfo.icon",
                            null
                          )}
                        />
                        <Icon
                          src={propValueOr(
                            tournament,
                            "console.imageInfo.icon",
                            crossPlatform
                          )}
                        />
                        <Name>{tournament.name}</Name>
                      </Info>
                      {!!mods && <Badge>{mods}</Badge>}
                      <Status>
                        {!!tournament.teamSize && tournament.teamSize > 1 && (
                          <TeamBadge minWidth={"60px"}>
                            <TeamsIcon src={TeamsSVG} />
                            {tournament.teamSize}
                          </TeamBadge>
                        )}
                        {!user.organizationId && tournament.organization && (
                          <Badge>
                            {propValueOr(tournament, "organization.name", "")}
                          </Badge>
                        )}
                        {tournament.featured && (
                          <Badge>FEATURED #{tournament.featured}</Badge>
                        )}
                        <Badge>ID {propValueOr(tournament, "id", "")}</Badge>
                        <Badge>
                          {propValueOr(tournament, "playerCount", "0")}{" "}
                          {!!tournament.teamSize ? "Teams" : "Players"}
                        </Badge>
                        <DateBadge>
                          {moment(tournament.startDate).format(
                            "MM/DD/YY @ h:mmA "
                          )}
                        </DateBadge>
                        {status === "complete" ? (
                          <DateBadge style={{ backgroundColor: COLOR.GREY }}>
                            {tournament.endedAt
                              ? moment(tournament.endedAt).format(
                                  "MM/DD/YY @ h:mmA"
                                )
                              : "Cancelled"}
                          </DateBadge>
                        ) : (
                          <StatusBadge status={status}>{status}</StatusBadge>
                        )}
                      </Status>
                    </WrapLink>
                    <LinkBadge
                      href={`https://${slug}rivalgames.com/tournaments/${tournament.token}`}
                      target="_blank"
                    >
                      View
                      <ArrowIcon src={ArrowRight} />
                    </LinkBadge>
                    {this.state.editMode && (
                      <IconWrap
                        onClick={() =>
                          this.setState({
                            isDeleteTournamentDialogOpen: true,
                            deleteTournament: tournament
                          })
                        }
                      >
                        <Icon src={DeleteIcon} />
                      </IconWrap>
                    )}
                  </Header>
                </Item>
              );
            })}
          </List>
          <Pagination withUrl total={total} />
        </Content>
      </Container>
    );
  }
}

export default Tournaments;
