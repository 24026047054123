import { ORGANIZATIONS_ACTIONS } from "./constants";

const initialState = {
  organizations: [],
  affiliations: [],
  loading: false,
  error: null,
  affiliationsError: null
};

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case ORGANIZATIONS_ACTIONS.fetch.request:
      return {
        ...state,
        loading: true,
        error: null
      };
    case ORGANIZATIONS_ACTIONS.fetch.success:
      return {
        ...state,
        loading: false,
        organizations: action.payload
      };
    case ORGANIZATIONS_ACTIONS.fetch.error:
      return {
        ...state,
        organizations: [],
        loading: false,
        error: action.payload
      };
    case ORGANIZATIONS_ACTIONS.fetchAffiliations.request:
      return {
        ...state,
        affiliationsError: null
      };
    case ORGANIZATIONS_ACTIONS.fetchAffiliations.success:
      return {
        ...state,
        affiliations: action.payload
      };
    case ORGANIZATIONS_ACTIONS.fetchAffiliations.error:
      return {
        ...state,
        affiliations: [],
        affiliationsError: action.payload
      };

    case ORGANIZATIONS_ACTIONS.reset:
      return initialState;
    default:
      return state;
  }
};

export default reducers;
