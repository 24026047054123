import styled from "styled-components";
import { COLOR, FONT_FAMILY, FONT_WEIGHT } from "../../../constants/theme";

export const NotificationsGroupSD = styled.div``;
export const NotificationsGroupTitleSD = styled.div`
  padding: 5px 22px;
  background-color: ${COLOR.BG_ACCENT};

  color: ${COLOR.TEXT_4};
  font: ${FONT_WEIGHT.MEDIUM} 12px / 1.2 ${FONT_FAMILY.THIN};
  text-transform: uppercase;
`;
export const NotificationsGroupListSD = styled.div``;
export const NotificationsGroupWrapItemSD = styled.div`
  &:not(:first-child):before {
    content: "";
    display: block;
    margin: 0 22px -1px;
    border-top: 1px solid ${COLOR.BORDER};
  }
`;
