/**
 * Validation function of full name field
 * The full name must contain at least two words, and each of them must contain at least two letters
 * @param fullName {string}
 * @return {boolean}
 */
export const isFullNameValid = fullName => {
  if (!fullName) return false;

  const names = fullName.replace(/\s{2,}/, " ").split(" ");

  if (names.length < 2) return false;

  // noinspection RedundantIfStatementJS
  if (names[0].length < 2 || names[1].length < 2) return false;

  return true;
};

/**
 * Validation function of billing name field
 * The full name must contain at least two words
 * @param fullName {string}
 * @return {boolean}
 */
export const isBillingNameValid = fullName => {
  if (!fullName) return false;

  const names = fullName.replace(/\s{2,}/, " ").split(" ");

  if (names.length < 2) return false;

  // noinspection RedundantIfStatementJS
  if (names[0].length < 1 || names[1].length < 1) return false;

  return true;
};

/**
 * Check email by pattern
 * @param email {string}
 * @return {boolean}
 */
export const isEmailValid = email => {
  const emailPattern = /\b[\w.-]+@[\w.-]+\.\w{2,6}\b/gi;
  return emailPattern.test(email);
};

/**
 * The date of birth.
 * Must be less than the current day by at least three years.
 * @param dateOfBirth
 * @return {boolean}
 */
export const isDateOfBirthValid = dateOfBirth => {
  if (!dateOfBirth) return false;

  if (dateOfBirth.search(/_/) !== -1) return false;

  const [month, day] = dateOfBirth.split("/");

  // noinspection RedundantIfStatementJS
  if (month > 12 || day > 31) return false;

  const ThreeYearsPerMilliseconds = 3 * 365 * 24 * 60 * 60 * 1000;
  const dateNow = Date.now();
  const selectedDate = new Date(dateOfBirth);
  return dateNow - selectedDate >= ThreeYearsPerMilliseconds;
};

/**
 * If the phone is not empty. It must contain 10 digits.
 * @param phone {string}
 * @return {boolean}
 */
export const isPhoneValid = phone => {
  if (!phone.length) return true;

  const pattern = /^\d{10}$/;
  return pattern.test(phone);
};

/**
 * Validation function of PlayStation ID field
 * The id must contain only letters, numbers, hyphens, and underscores
 * @param id {string}
 * @return {boolean}
 */
export const isPlayStationIdValid = id => {
  if (!id) return false;

  if (id.length < 3 || id.length > 16) return false;

  // const invalidChars = [' ','']
  // if (id.includes(' ')) return false;

  return true;
};

/**
 * Validation function of link url
 * @param url {string}
 * @return {boolean}
 */

export const isValidUrl = url => {
  const pattern = new RegExp(
    "^(https?:\\/\\/)" +
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
      "((\\d{1,3}\\.){3}\\d{1,3}))" +
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
      "(\\?[;&a-z\\d%_.~+=-]*)?" +
      "(\\#[-a-z\\d_]*)?$",
    "i"
  );
  return !!pattern.test(url);
};
