import React, { Component } from "react";
import { connect } from "react-redux";
import { InfoContainer, Content, Title, Text, CloseButton } from "./styled";
import parser, { Tag } from "bbcode-to-react";
import { Link } from "react-router-dom";
import { readAllNotificationsAction } from "../../../../store/notifications/actions";
import UIfx from "uifx";
import moderatorRequestAudio from "./sounds/notification_high-intensity.wav";

const moderatorRequest = new UIfx(moderatorRequestAudio, {
  volume: 0.7, // number between 0.0 ~ 1.0
  throttleMs: 100
});

class BoldTag extends Tag {
  toReact() {
    // using this.getComponents() to get children components.
    return <b>{this.getComponents()}</b>;
  }
}

class LinkTag extends Tag {
  toReact() {
    return <Link to={this.params.to}>{this.getComponents()}</Link>;
  }
}

parser.registerTag("b", BoldTag);
parser.registerTag("link", LinkTag);

class InfoToast extends Component {
  readNotificationAction(id) {
    this.props.readAllNotificationsAction(id);
  }

  componentDidMount() {
    if (this.props.title === "Moderator Requested") {
      moderatorRequest.play();
    }
  }

  render() {
    const jsxTitle = parser.toReact(this.props.title);
    const jsxText = parser.toReact(this.props.message);
    const handleCloseToast = () => {
      this.props.onClose(this.props.id);
      this.props.closeToast();
    };

    return (
      <InfoContainer>
        <Content>
          {!!this.props.title && <Title>{jsxTitle}</Title>}
          <Text>{jsxText}</Text>
        </Content>
        <CloseButton onClick={() => handleCloseToast()}>Close</CloseButton>
      </InfoContainer>
    );
  }
}

const mapDispatchToProps = {
  readAllNotificationsAction
};

export default connect(null, mapDispatchToProps)(InfoToast);
