export const TOURNAMENT_EVENTS = [
  {
    name: "registration",
    title: "Tournament Registration",
    description:
      "The user will receive this email after tournament registration"
  },
  {
    name: "24-reminder",
    title: "24-Hour Reminder",
    description:
      "The user will receive this email 24 hours before the tournament starts"
  },
  {
    name: "gameday-reminder",
    title: "Gameday Reminder",
    description:
      "The user will receive this email on the day the tournament starts"
  },
  {
    name: "tournament-winner",
    title: "Tournament winner",
    description: "The user will receive this email after winning the tournament"
  },
  {
    name: "community-winner",
    title: "Community winner notification",
    description: "Community will receive this email after winners are defined",
    defaultValue: "[GLOBAL] GLOBAL - Community Winner Notification"
  }
];
