import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  setAuthorizedUserInfoAction,
  setUserAsNotAuthorizedAction,
  updateUserRequestStatus
} from "../store/user/actions";
import connect from "react-redux/es/connect/connect";
import { withRouter } from "react-router-dom";
import PagePreloader from "../components/presentation/PagePreloader/PagePreloader";
import AppRouting from "./AppRouting/AppRouting";
import { parseJwt } from "../helpers/common";
import { api } from "../index";
import AppScrollToTop from "./AppScrollToTop/AppScrollToTop";
import { ToastNotifications } from "../components/smart/ToastNotifications/ToastNotifications";

class App extends Component {
  static propTypes = {
    user: PropTypes.shape({
      logged: PropTypes.bool,
      verified: PropTypes.bool,
      info: PropTypes.shape({}),
      /** if it equals 500 a user will see the error 500 page*/
      requestStatus: PropTypes.number
    }).isRequired,
    setAuthorizedUserInfoAction: PropTypes.func.isRequired,
    setUserAsNotAuthorizedAction: PropTypes.func.isRequired,

    location: PropTypes.object.isRequired
  };

  componentDidMount() {
    const {
      setAuthorizedUserInfoAction,
      setUserAsNotAuthorizedAction
    } = this.props;

    const token = window.localStorage.getItem("jwt");

    if (token) {
      setAuthorizedUserInfoAction(parseJwt(token));
      api.updateTokens(); //on mount, get most recent jwt
    } else {
      setUserAsNotAuthorizedAction();
    }
  }

  componentDidUpdate(prevProps) {
    const { location, user } = this.props;
    const requestStatus = user.requestStatus;

    // reset request status when user goes to another page
    if (!!requestStatus && location !== prevProps.location) {
      this.props.updateUserRequestStatus(null);
    }
  }

  render() {
    const { user, location, permissions } = this.props;

    if (!user.verified) {
      return <PagePreloader />;
    }

    const curPath = location.pathname;

    return (
      <AppScrollToTop>
        <AppRouting user={user} permissions={permissions} curPath={curPath} />
        <ToastNotifications />
      </AppScrollToTop>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  permissions: state.permissionsState.permissions
});
const mapDispatchToProps = {
  setAuthorizedUserInfoAction,
  setUserAsNotAuthorizedAction,
  updateUserRequestStatus
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
