import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import RouteChallenge from "./RouteChallenge";
class RouteChallengeContainer extends Component {
  render() {
    return <RouteChallenge {...this.props} />;
  }
}

const mapStateProps = state => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateProps,
  mapDispatchToProps
)(withRouter(RouteChallengeContainer));
