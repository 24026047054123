import styled from "styled-components";
import { COLOR, FONT_FAMILY, FONT_WEIGHT } from "../../../../constants/theme";

export const Container = styled.div``;

export const WrapInput = styled.div`
  width: 100%;
  margin-top: 15px;
`;
export const Input = styled.input`
  width: 100%;
  margin-bottom: 5px;
`;
export const Label = styled.div`
  text-transform: capitalize;
  font: ${FONT_WEIGHT.MEDIUM} 14px ${FONT_FAMILY.THIN};
  margin-bottom: 7px;
`;

export const Description = styled.div`
  font: ${FONT_WEIGHT.NORMAL} 12px ${FONT_FAMILY.THIN};
  margin-bottom: 14px;
  color: ${COLOR.GREY};
`;

export const Title = styled.div`
  font: ${FONT_WEIGHT.NORMAL} 16px ${FONT_FAMILY.GOTHIC_BOLD};
  margin: 20px 0 10px;
`;

export const Preview = styled.img`
  max-height: 100px;
`;

export const PreviewWrap = styled.div`
  height: 100px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const IconWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
`;

export const Icon = styled.img`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 3px;
`;

export const Delete = styled.div``;

export const WrapLabel = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const CopiedPrize = styled.img`
  position: absolute;
  top: -128px;
  right: 0;
  width: 160px;
  height: 90px;
  object-fit: contain;
`;
