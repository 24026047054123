import React from "react";
import DialogKit from "../../../../../../components/kit/Dialog/DialogKit";
import ButtonKit from "../../../../../../components/kit/Button/ButtonKit";
import { DialogButtons, Text } from "./styled";

const UnregisterDialog = ({
  isDialogOpen,
  unregisteredUser,
  loading,
  onClose,
  onUnregister,
  isTeam
}) => {
  return (
    <DialogKit
      title={!!isTeam ? "Remove Team?" : "Remove Player?"}
      renderBody={() => (
        <Text>{`Are you sure you want to remove ${unregisteredUser.name} from the tournament?`}</Text>
      )}
      displayButtonClose
      onClose={() => onClose()}
      isOpen={isDialogOpen}
      renderFooter={() => (
        <DialogButtons>
          <ButtonKit
            fullWidth
            color={"red"}
            preloader={loading}
            onClick={() => onUnregister()}
          >
            Yes, Remove
          </ButtonKit>
          <ButtonKit
            fullWidth
            appearance={"secondary"}
            onClick={() => onClose()}
          >
            Cancel
          </ButtonKit>
        </DialogButtons>
      )}
    />
  );
};

export default UnregisterDialog;
