export const imageFields = [
  {
    key: "image",
    description: "This is the image to display and differentiate your season.",
    constraints: {
      width: [608, 2160],
      height: [342, 1212],
      aspectRatio: [{ value: 1.76 }, { value: 1.8 }]
    },
    imageDimensions: [400, 224],
    imgLabels: ["16:9 Aspect Ratio (width / height ≈ 1.78)"]
  }
];

export const compressionOptions = {
  maxSizeMB: 0.3,
  useWebWorker: true
};
