import React, { Component } from "react";
import { connect } from "react-redux";
import { Bar, ChatBarItem } from "./styled";
import ChatWindow from "./ChatWindow";
import {
  updatePinnedChat,
  unpinChatChannel
} from "../../../store/chat/actions";
import { chatBarItemWidth } from "./constants";
import withWidth from "../../../hoc/withWidth";

class ChatBar extends Component {
  toggleCollapse = updatedChat => {
    this.props.updatePinnedChat(updatedChat);
  };

  unpinChannel = channel => {
    this.props.unpinChatChannel(channel);
  };

  render() {
    const { chats, width } = this.props;
    return (
      <Bar isActive={!!chats.length}>
        {chats
          .slice(0, Math.floor(width / chatBarItemWidth))
          .map((chat, index) => {
            return (
              <ChatBarItem key={index}>
                <ChatWindow
                  {...chat}
                  toggleCollapse={() =>
                    this.toggleCollapse({
                      ...chat,
                      openWindow: !chat.openWindow
                    })
                  }
                  unpinChannel={() => this.unpinChannel(chat.channel)}
                />
              </ChatBarItem>
            );
          })}
      </Bar>
    );
  }
}

const mapStateToProps = state => ({
  chats: state.chat.chats
});

const mapDispatchToProps = {
  updatePinnedChat,
  unpinChatChannel
};

export default connect(mapStateToProps, mapDispatchToProps)(withWidth(ChatBar));
