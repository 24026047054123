import React, { Component } from "react";
import FormFieldKit from "../../../components/kit/Fields/FormField/FormField";
import RadioButtonKit from "../../../components/kit/Fields/RadioButton/RadioButtonKit";
import Button from "../../../components/kit/Button/ButtonKit";
import {
  Container,
  Title,
  Header,
  BackIcon,
  Row,
  WrapIconButton,
  RemoveIcon,
  Settings,
  Wrap,
  SettingsTitle,
  Label,
  Icon,
  WrapEmpty,
  WrapButtons,
  Description,
  Subtitle,
  TrashIcon,
  Error,
  Game,
  Console,
  OtherIcon
} from "./styled";
import backArrow from "../../../static/icons/chevron-arrow.svg";
import { isEqual, set } from "lodash";
import { connect } from "react-redux";
import {
  createSeason,
  fetchLeague,
  fetchSeason,
  resetSeasonState,
  setSeasonProperty,
  setSeasonState
} from "../../../store/leagues/actions";
import Schedule from "./Schedule/Schedule";
import moment from "moment";
import { fetchTemplates } from "../../../store/games/actions";
import { propValueOr, safePropValueOr } from "../../../helpers/common";
import IconCirclePlus from "../../../components/kit/Icon/IconCirclePlus";
import iconX from "../../../static/icons/remove.svg";
import ButtonKit from "../../../components/kit/Button/ButtonKit";
import DialogKit from "../../../components/kit/Dialog/DialogKit";
import iconTrash from "../../../static/icons/icon-trash.svg";
import { getSeasonErrors, SEASON_TYPES } from "./constants";
// import Images from "./Images/Images";
import imageCompression from "browser-image-compression";
import { compressionOptions } from "./Images/constants";
import SelectKit from "../../../components/kit/Fields/Select/SelectKit";
import { api } from "../../..";
import { debounce, cloneDeep } from "lodash";
import {
  bestOfOptions,
  readyOptions,
  timeOptions,
  verificationOptions
} from "../../tournaments/TournamentCreate/constants";
import momentTz from "moment-timezone";

// Icons & images
import removeSVG from "../../../static/icons/remove.svg";
import crossPlatform from "../../../static/images/cross-platform.svg";
import { getMaxSeasonIndex } from "../../../store/leagues/selectors";
import Panel from "../../../components/kit/Panel/Panel";
import rb from "../../../static/icons/rival_coin.svg";
import rp from "../../../static/icons/rival_points.svg";
import Uploader from "../Uploader/Uploader";

const zone_name = momentTz.tz.guess();
const timezone = momentTz.tz(zone_name).zoneAbbr();
const timezoneStr = `${zone_name} Time (${timezone})`;

class SeasonCreate extends Component {
  constructor(props) {
    super(props);

    this.getTournaments = debounce(this.getTournaments, 500, {
      leading: true,
      trailing: true
    });

    this.state = {
      isAddSettingDialogOpen: false,
      addSetting: "",
      template: null,
      search: "",
      tournaments: [],
      selected: null,
      errors: {}
    };
  }

  componentDidMount() {
    const {
      params: { slug, token }
    } = this.props.match;
    this.props.fetchLeague(slug);

    if (!this.props.isFetchingTemplates) {
      this.props.fetchTemplates();
    }

    if (this.props.isUpdate && token) {
      this.props.fetchSeason(token);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      !!prevProps.isFetchingTemplates &&
      !this.props.isFetchingTemplates &&
      !this.state.template
    ) {
      const template = this.props.templates?.find(
        t =>
          t?.ConsoleId === this.props.league?.consoleId &&
          t?.GameId === this.props.league?.gameId
      );
      this.setState({ template });

      this.props.setSeasonState({
        settings: template?.metadata?.settings || {}
      });
    }
  }

  componentWillUnmount() {
    this.props.resetSeasonState();
  }

  getTournaments = () => {
    const { organizationId } = this.props;

    api.tournament
      .list({
        organizationId,
        q: this.state.search,
        limit: 25,
        page: 1,
        status: "initialized"
      })
      .then(resp =>
        this.setState({
          tournaments: (resp?.data?.docs || []).map(t => ({
            label: t?.name || t?.token || "",
            value: t.id
          }))
        })
      )
      .catch(err =>
        console.log(
          "error fetching tournaments: ",
          err?.response?.message || err?.message || err
        )
      );
  };

  validateSeason = () => {
    const { season } = this.props;
    const {
      startDate,
      endDate,
      minPlayers,
      maxPlayers,
      registrationStart,
      registrationEnd,
      teamSize
    } = season || {};

    const isTeamSeason = teamSize >= 2;

    let newErrors = {};
    if (!startDate) {
      set(newErrors, "startDate", "Must have a start time for a new season.");
    } else if (moment(startDate).isBefore(moment(), "minute")) {
      set(
        newErrors,
        "startDatePast",
        "Cannot schedule a new season in the past."
      );
    }
    if (!!endDate && moment(endDate).isBefore(moment(startDate), "second")) {
      set(
        newErrors,
        "endDateBefore",
        "Season end date cannot be before season start date."
      );
    }
    if (registrationStart) {
      if (
        moment(registrationEnd).isBefore(moment(registrationStart), "minute")
      ) {
        set(
          newErrors,
          "regEndBefore",
          "Registration end cannot be before registration start."
        );
      }
    }
    if (registrationEnd) {
      if (!registrationStart) {
        set(
          newErrors,
          "regStartNull",
          "Please select a registration start date."
        );
      }
    }
    if (
      registrationEnd &&
      moment(registrationEnd).isAfter(moment(startDate), "minute")
    ) {
      set(
        newErrors,
        "regEndDate",
        "Registration end time must be equal to or before the tournament start time"
      );
    }
    if (!minPlayers) {
      set(newErrors, "minPlayers", "Must set a minimum number of players");
    }
    if (maxPlayers !== null && minPlayers > maxPlayers) {
      set(
        newErrors,
        "maxPlayers",
        "Must be greater than or equal to min players"
      );
    }
    if (isTeamSeason == null) {
      set(newErrors, "isTeamSeason", "Must select an option");
    }
    if (isTeamSeason && (teamSize == null || teamSize < 2)) {
      set(
        newErrors,
        "teamSize",
        "Team size is required and must be greater than or equal to 2"
      );
    }

    if (!isEqual(this.state.errors, newErrors)) {
      this.setState({ errors: newErrors });
    }

    return newErrors;
  };

  createSeason = () => {
    const { league, season, history, newSeasonIndex } = this.props;
    const {
      startDate,
      endDate,
      minPlayers,
      maxPlayers,
      registrationStart,
      registrationEnd,
      teamSize,
      maxTeam,
      schedule,
      settings,
      image,
      tournamentId,
      matchInfo,
      description,
      survey,
      walletFee,
      entryFee,
      type,
      prizeImage,
      prizeTitle,
      prizeDescription,
      // coins,
      // prizes,
      firstPlace,
      secondPlace,
      thirdPlace,
      inviteToken
    } = season || {};

    const newEntryFee = parseInt(entryFee * 100);

    const seasonErrors = this.validateSeason();

    if (!!!Object.keys(seasonErrors).length) {
      this.setState({ loading: true });

      const newSchedule = schedule.map(item => {
        return {
          ...item,
          settings: {
            settings: item?.settings?.settings || {},
            ...matchInfo
          }
        };
      });

      const body = {
        prizes: JSON.stringify([
          firstPlace || propValueOr(season, "prizes", [])[0] || 1000,
          secondPlace || propValueOr(season, "prizes", [])[1] || 500,
          thirdPlace || propValueOr(season, "prizes", [])[2] || 250
        ]),
        ...(entryFee &&
          !isNaN(newEntryFee) && {
            entryFee: newEntryFee
          }),
        ...(walletFee && { walletFee }),
        ...(survey && { survey }),
        ...(inviteToken && { inviteToken }),
        name: `Season ${newSeasonIndex}`,
        description: description,
        index: newSeasonIndex,
        leagueId: league?.id,
        startDate: moment(startDate).format("YYYY-MM-DD HH:mm:ssZZ"),
        endDate: moment(endDate).format("YYYY-MM-DD HH:mm:ssZZ"),
        minPlayers: minPlayers > 1 ? minPlayers : 2, // default must be 2 if not set
        maxPlayers: maxPlayers,
        tournamentId,
        type,
        ...(registrationStart && {
          registrationStart: moment(registrationStart).format(
            "YYYY-MM-DD HH:mm:ssZZ"
          )
        }),
        ...(registrationEnd && {
          registrationEnd: moment(registrationEnd).format(
            "YYYY-MM-DD HH:mm:ssZZ"
          )
        }),
        teamSize: teamSize && teamSize > 1 ? teamSize : null,
        ...(teamSize &&
          teamSize > 1 &&
          maxTeam &&
          maxTeam >= teamSize && { maxTeam }),
        ...(image instanceof File && {
          image: image
        }),
        settings: JSON.stringify(settings || {}),
        schedule: JSON.stringify(newSchedule),
        prize: prizeImage,
        prizeTitle: prizeTitle,
        prizeDescription: prizeDescription
      };
      let data = new FormData();
      for (let key in body) {
        if (body[key] != null) {
          data.append(key, body[key]);
        }
      }

      this.props
        .createSeason(data)
        .then(() => {
          if (!this.props.season?.error) {
            this.props.resetSeasonState();
            this.setState({ loading: false, success: "Season created!" });
            history.push(`/leagues/${league?.slug}`);
          }
        })
        .catch(err => {
          const data = (err.response && err.response.data) || {};
          if (data.error) {
            this.setState({ generalError: data.error, loading: false });
          }
        });
    }
  };

  handleChangeField = (propName, val) => {
    this.props.setSeasonProperty(propName, val);
  };

  handleChangeImage = async (propName, val) => {
    let compressedImage = await imageCompression(val, compressionOptions);
    compressedImage.lastModifiedDate = new Date();
    const newFile = new File([compressedImage], compressedImage.name);
    this.props.setSeasonProperty(propName, newFile);
  };

  addMatch = (initial = null) => {
    let schedule = cloneDeep(this.props.season?.schedule);
    if (!schedule) {
      schedule = [];
    }

    //add match
    schedule.push({
      startDate: initial?.startDate || moment(new Date()),
      settings: {
        settings:
          this.props.season?.settings ||
          initial?.settings ||
          this.state.defaultSettings ||
          {}
      },
      round: schedule.length + 1
    });
    this.handleChangeField("schedule", schedule);
  };

  deleteMatch = (match, index) => {
    let schedule = this.props.season?.schedule;
    if (!schedule) {
      schedule = [];
    }

    //edit existing match or add new one
    if (index !== undefined && index !== null) {
      if (index > -1) {
        schedule.splice(index, 1);
        //reset the round number
        schedule = schedule.map((m, i) => ({ ...m, round: i + 1 }));
      }
      this.handleChangeField("schedule", schedule);
    }
  };

  handleCloseSettingDialog = () => {
    this.setState({ isAddSettingDialogOpen: false, addSetting: "" });
  };

  renderAddSettingDialog = () => {
    const { isAddSettingDialogOpen, addSetting } = this.state;
    const { season } = this.props;
    const settings = season?.settings || {};
    return (
      <DialogKit
        isOpen={isAddSettingDialogOpen}
        onClose={this.handleCloseSettingDialog}
        title={"Add Setting"}
        renderBody={() => (
          <>
            <FormFieldKit
              inputProps={{ autoFocus: true }}
              fullWidth
              label={"Setting Name"}
              placeholder={"Enter new setting name"}
              onChange={val => this.setState({ addSetting: val })}
              value={addSetting}
            />
          </>
        )}
        renderFooter={() => (
          <WrapButtons>
            <ButtonKit
              fullWidth
              small
              disabled={
                Object.keys(settings).includes(addSetting) || !addSetting
              }
              onClick={() => {
                const { addSetting } = this.state;
                if (Object.keys(settings).includes(addSetting)) {
                  return;
                }

                this.props.setSeasonState({
                  settings: { ...settings, [addSetting]: "" }
                });
                this.handleCloseSettingDialog();
              }}
            >
              Add
            </ButtonKit>
            <ButtonKit
              fullWidth
              small
              appearance={"secondary"}
              color={"red"}
              onClick={this.handleCloseSettingDialog}
            >
              Cancel
            </ButtonKit>
          </WrapButtons>
        )}
      />
    );
  };

  render() {
    const {
      errors,
      loading,
      search,
      tournaments,
      selected,
      generalError
    } = this.state;
    const { history, templates, season, newSeasonIndex, league } = this.props;

    const {
      startDate,
      endDate,
      minPlayers,
      maxPlayers,
      registrationStart,
      registrationEnd,
      teamSize,
      maxTeam,
      schedule,
      settings,
      isTeamSeason,
      matchInfo,
      description,
      inviteToken,
      entryFee,
      walletFee,
      survey,
      firstPlace,
      secondPlace,
      thirdPlace,
      type
    } = season || {};

    return (
      <Container>
        <Header>
          <BackIcon src={backArrow} onClick={() => history.goBack()} />
          <Title>
            Create Season {newSeasonIndex} -
            {league?.game !== undefined && (
              <Game src={league?.game?.imageInfo?.title} />
            )}
            {league?.console !== undefined && (
              <Console
                src={league?.console?.imageInfo?.thumbnail || crossPlatform}
              />
            )}
          </Title>
          <TrashIcon
            src={iconTrash}
            onClick={() => {
              if (
                window.confirm(
                  "Are you sure you want to discard changes to this new season? You will lose any data you entered / changed."
                )
              ) {
                this.props.resetSeasonState();
              }
            }}
          />
        </Header>
        <Subtitle>General Information</Subtitle>
        <br />
        <FormFieldKit
          label={"Name of the Season (Limit 30 Characters)"}
          fullWidth
          placeholder={"Enter name... (ex. Season 1)"}
          required
          value={`Season ${newSeasonIndex}`}
          inputProps={{ maxLength: 30, autoFocus: true }}
          onChange={val => this.handleChangeField("name", val)}
          disabled
        />
        <br />
        <Row>
          <FormFieldKit
            label={
              "Description of the Season (*Markdown Compatible) -- Limit 2500 Characters"
            }
            fullWidth
            placeholder={"Enter description..."}
            required
            multiline
            value={description}
            inputProps={{ maxLength: 2500 }}
            onChange={val => this.handleChangeField("description", val)}
          />
        </Row>
        <Row>
          <FormFieldKit
            dateTimePicker
            fullWidth
            required
            label={`Season Start Date & Time`}
            description={timezoneStr}
            onChange={val => {
              this.handleChangeField("startDate", val);
            }}
            value={startDate}
            errorText={errors?.startDate || errors?.startDatePast}
          />
          <FormFieldKit
            dateTimePicker
            fullWidth
            required
            label={`Season End Date & Time`}
            description={timezoneStr}
            onChange={val => {
              this.handleChangeField("endDate", val);
            }}
            value={endDate}
            errorText={errors?.endDateBefore}
          />
        </Row>
        <Row>
          <FormFieldKit
            dateTimePicker
            fullWidth
            label={"Registration Start Time (optional)"}
            description={`${timezoneStr} - Defaults to opening registration immediately`}
            onChange={val => this.handleChangeField("registrationStart", val)}
            value={registrationStart}
            errorText={errors?.regStartNull}
          />
          <FormFieldKit
            dateTimePicker
            fullWidth
            label={"Registration End Time (optional)"}
            description={`${timezoneStr} - Defaults to Season Start Time`}
            onChange={val => this.handleChangeField("registrationEnd", val)}
            value={registrationEnd}
            errorText={errors?.regEndBefore || errors?.regEndDate}
          />
        </Row>
        <Row>Is this a TEAM Season?</Row>
        <Row style={{ marginBottom: "10px" }}>
          <RadioButtonKit
            label={"Yes"}
            horizontalLabel
            onChange={() =>
              this.props.setSeasonState({ teamSize: 2, isTeamSeason: true })
            }
            checked={isTeamSeason}
            fullWidth
          />
          <RadioButtonKit
            label={"No"}
            horizontalLabel
            onChange={() =>
              this.props.setSeasonState({ teamSize: null, isTeamSeason: false })
            }
            checked={isTeamSeason === false}
            fullWidth
          />
        </Row>
        {isTeamSeason && (
          <Row>
            <FormFieldKit
              label={"Team Size"}
              description={"Minimum number of players per team for the season"}
              fullWidth
              required
              placeholder={"Enter team size"}
              type={"number"}
              min={"2"}
              value={teamSize}
              onChange={val => {
                const newVal = parseInt(val);
                this.handleChangeField(
                  "teamSize",
                  isNaN(newVal) ? undefined : newVal
                );
              }}
              errorText={
                !teamSize
                  ? "Please enter a team size"
                  : teamSize < 2
                  ? "Team size must be at least 2"
                  : ""
              }
            />
            <FormFieldKit
              label={"Maximum Players Per Team (optional)"}
              description={
                "i.e. The total allowable roster size. If left blank this will be the 'Team Size'"
              }
              fullWidth
              placeholder={"Enter maximum players per team"}
              type={"number"}
              min={teamSize || "2"}
              max={"15"}
              value={maxTeam}
              onChange={val => {
                let newVal = parseInt(val);
                if (newVal > 15) {
                  newVal = 15;
                }
                this.handleChangeField(
                  "maxTeam",
                  isNaN(newVal) ? null : newVal
                );
              }}
              disabled={!(teamSize > 1)}
              errorText={teamSize && maxTeam ? !(maxTeam >= teamSize) : null}
            />
          </Row>
        )}
        <Row>
          <FormFieldKit
            label={isTeamSeason ? "Minimum Teams" : "Minimum Players"}
            description={`Minimum ${
              isTeamSeason ? "teams" : "players"
            } required for season to start`}
            fullWidth
            required
            placeholder={
              isTeamSeason ? "Enter minimum teams" : "Enter minimum players"
            }
            type={"number"}
            min={"2"}
            max={maxPlayers && maxPlayers.toString()}
            value={minPlayers}
            onChange={val => {
              const newVal = parseInt(val);
              this.handleChangeField("minPlayers", newVal);
            }}
            errorText={errors?.minPlayers}
          />
          <FormFieldKit
            label={
              isTeamSeason
                ? "Maximum Teams"
                : "Maximum Players (blank = no cap)"
            }
            description={`Maximum ${
              isTeamSeason ? "teams" : "players"
            } allowed to register for season`}
            fullWidth
            placeholder={"Leave blank for no limit on registrations"}
            type={"number"}
            min={
              ((minPlayers || minPlayers === 0) && minPlayers.toString()) || "2"
            }
            value={maxPlayers}
            onChange={val => {
              const newVal = parseInt(val);
              this.handleChangeField(
                "maxPlayers",
                isNaN(newVal) ? null : newVal
              );
            }}
            errorText={errors?.maxPlayers}
          />
        </Row>
        <Row>
          <FormFieldKit
            label={""}
            description={
              <>
                The mode determines how matchups are determined for the season
                <br />
                1. Scheduled - All matchups are determined for each round at the
                start of the season, based on order of registration.
                <br />
                2. Ranked - Matchups are determined round-by-round based on
                season leaderboard rankings from previous round results (e.g.
                for the second round, a player who wins their first round
                matchup would play someone else who won their first round
                matchup)
              </>
            }
            fullWidth
            multiline
            required
            select
            selectOptions={SEASON_TYPES}
            value={safePropValueOr(
              SEASON_TYPES.find(tm => tm.value === type),
              "label",
              undefined
            )}
            onChange={val => {
              this.handleChangeField("type", val);
            }}
            placeholder={type === undefined ? "Select season type" : undefined}
            errorText={errors?.type}
          />
        </Row>
        <br />
        <Panel
          trigger={"PRIZES & ENTRIES"}
          customMargin={"0px"}
          // triggerStyle={{ padding: "15px 25px" }}
          open={false}
        >
          <br />
          <Subtitle>Invite Code (optional)</Subtitle>
          <br />
          <FormFieldKit
            label={"Invite Code (will be required for tournament entry)"}
            fullWidth
            placeholder={"Enter code..."}
            inputProps={{ maxLength: 50 }}
            value={inviteToken}
            onChange={val => {
              const newVal = val || null;
              this.handleChangeField("inviteToken", newVal);
            }}
          />
          <br />
          <Subtitle>ENTRY FEE (optional)</Subtitle>
          <br />
          <FormFieldKit
            label={"Entry Fee (Leave blank if non paid tournament)"}
            fullWidth
            placeholder={"Enter fee to join tournament..."}
            type={"number"}
            min={"0"}
            max={"1000"}
            value={entryFee}
            disabled={!!walletFee && walletFee > 0}
            onChange={val => {
              const newVal = val;
              this.handleChangeField("entryFee", isNaN(newVal) ? null : newVal);
            }}
          />
          <br />
          <Subtitle
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start"
            }}
          >
            RIVALS FEE
            <OtherIcon
              src={rb}
              width={20}
              height={20}
              style={{ marginLeft: "5px" }}
              alt=""
            />
            (optional)
          </Subtitle>
          <br />
          <FormFieldKit
            label={"Rivals Entry Fee (Leave blank if non paid tournament)"}
            fullWidth
            placeholder={"Enter Rivals fee to join tournament..."}
            type={"number"}
            min={"0"}
            max={"1000"}
            value={walletFee}
            disabled={!!entryFee && entryFee > 0}
            onChange={val => {
              const newVal = val;
              this.handleChangeField(
                "walletFee",
                isNaN(newVal) ? null : newVal
              );
            }}
          />
          <br />
          <Subtitle>SURVEY (optional)</Subtitle>
          <br />
          <FormFieldKit
            label={"Survey (Only for non-paid)"}
            fullWidth
            placeholder={"Enter link to embed..."}
            value={survey}
            onChange={val => {
              const newVal = val || null;
              this.handleChangeField("survey", newVal);
            }}
          />
          <br />
          <Subtitle
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start"
            }}
          >
            RIVAL POINTS
            <OtherIcon
              src={rp}
              width={20}
              height={20}
              style={{ marginLeft: "5px" }}
              alt=""
            />
            (optional)
          </Subtitle>
          <br />
          <FormFieldKit
            label={"1st place (Rival Points awarded)"}
            fullWidth
            required
            placeholder={"Enter number of points..."}
            type={"number"}
            min={(secondPlace && secondPlace.toString()) || "0"}
            max={"10000"}
            value={firstPlace}
            onChange={val => {
              const newVal = parseInt(val);
              // this.setState({firstPlace: isNaN(newVal) ? null : newVal});
              this.handleChangeField(
                "firstPlace",
                isNaN(newVal) ? null : newVal
              );
            }}
          />
          <FormFieldKit
            label={"2nd Place (Rival Points awarded)"}
            required
            fullWidth
            placeholder={"Enter number of points..."}
            type={"number"}
            min={"0"}
            max={(firstPlace && firstPlace.toString()) || "10000"}
            value={secondPlace}
            onChange={val => {
              const newVal = parseInt(val);
              this.handleChangeField(
                "secondPlace",
                isNaN(newVal) ? null : newVal
              );
            }}
          />
          <FormFieldKit
            label={"3rd Place (Rival Points awarded)"}
            required
            fullWidth
            placeholder={"Enter number of points..."}
            type={"number"}
            min={"0"}
            max={(secondPlace && secondPlace.toString()) || "10000"}
            value={thirdPlace}
            onChange={val => {
              const newVal = parseInt(val);
              this.handleChangeField(
                "thirdPlace",
                isNaN(newVal) ? null : newVal
              );
            }}
          />

          <Subtitle>PRIZE INFORMATION (optional)</Subtitle>
          <Label>Prize Image</Label>
          <Description>
            Upload a prize image to show in the points and prizes section
          </Description>
          <Uploader
            key={"prize"}
            label={"Prize Image"}
            onChange={file => this.handleChangeImage("prizeImage", file)}
            constraints={{
              width: [100, 600],
              height: [56, 340],
              aspectRatio: [{ value: 1.7 }, { value: 1.8 }]
            }}
            imgLabels={["16:9 Aspect Ratio"]}
            imageDimensions={[160, 90]}
            // defaultImage={defaultImage || undefined}
            onReset={() => this.handleChangeImage("prizeImage", null)}
          />
          <FormFieldKit
            label={"Prize Title"}
            name={"prizeTitle"}
            onChange={val => this.handleChangeField("prizeTitle", val)}
            value={season?.prizeTitle}
            placeholder={"Enter prize title..."}
            fullWidth
            disabled={!season?.prizeImage}
          />
          <FormFieldKit
            label={"Prize Description"}
            name={"prizeDescription"}
            onChange={val => this.handleChangeField("prizeDescription", val)}
            value={season?.prizeDescription}
            placeholder={"Enter prize description..."}
            multiline
            fullWidth
            disabled={!season?.prizeImage}
          />
        </Panel>
        <br />
        <br />
        <Label style={{ margin: "10px 0 5px" }}>
          Playoffs / Finals Tournament ID (optional)
        </Label>
        <Description style={{ fontSize: "12px" }}>
          Select a Tournament to use for the playoffs or finals of this season.
          If you haven't made the tournament yet, you can wait to set this and
          connect it in the season updater later if necessary.
        </Description>
        <Row>
          <SelectKit
            fullWidth
            placeholder={"Select a tournament..."}
            value={selected}
            onChange={val => {
              this.handleChangeField("tournamentId", val);
              this.setState({
                selected: tournaments.find(t => t.value === val)?.label
              });
            }}
            select
            options={tournaments}
            searchValue={search}
            onChangeSearchValue={val =>
              this.setState({ search: val }, this.getTournaments)
            }
            searchPlaceholder={"Search by name, id, or token..."}
          />
          <WrapIconButton
            disabled={!selected}
            onClick={() => {
              this.handleChangeField("tournamentId", null);
              this.setState({ selected: null, search: null });
            }}
          >
            <RemoveIcon src={removeSVG} />
          </WrapIconButton>
        </Row>
        {/* <br />
        <Subtitle>Images</Subtitle>
        <br />
        <Images
          onChangeField={(key, val) => this.handleChangeImage(key, val)}
          formState={season}
          onResetField={key => this.props.setSeasonProperty(key, undefined)}
        /> */}
        <br />
        <Subtitle>Season Matches & Scheduling</Subtitle>
        <SettingsTitle>Create A Match Default Template</SettingsTitle>
        <Description style={{ margin: "-10px 0 20px" }}>
          Select the settings to be used for all matches in this season. You can
          update individual match settings later.
        </Description>
        <Settings>
          <FormFieldKit
            label={"Ready Required?"}
            description={
              "Set whether the players have to (1): Go to the game page to start the game (this is standard), or (2): Click a 'ready' button in order to start the game"
            }
            fullWidth
            multiline
            required
            select
            selectOptions={readyOptions}
            value={safePropValueOr(
              readyOptions.find(opt =>
                matchInfo
                  ? matchInfo.ready
                    ? true === opt.value
                    : false === opt.value
                  : false === opt.value
              ),
              "value",
              undefined
            )}
            onChange={val => {
              this.props.setSeasonState({
                matchInfo: { ...matchInfo, ready: val }
              });
            }}
            placeholder={"Select Ready Required"}
          />
          <Row>{matchInfo?.ready ? "Play Window" : "Forfeit Clock"}</Row>
          <Description>
            {matchInfo?.ready
              ? "Players must ready-up and enter scores before this amount of time passes, otherwise the winner will be selected by the system"
              : "Players must ready-up (go to the game page) before this amount of time passes, otherwise they will forfeit the game"}
          </Description>
          <Row>
            <FormFieldKit
              type="number"
              label={matchInfo?.ready ? `Game Ends After` : "Forfeit After"}
              placeholder={"15"}
              fullWidth
              onChange={f => {
                let info = cloneDeep(matchInfo);
                let forfeitArr = matchInfo.forfeit
                  ? matchInfo.forfeit.split(",")
                  : ["15", "minutes"];
                const val = isNaN(parseInt(f)) || parseInt(f) <= 0 ? "" : f;
                forfeitArr[0] = val;
                info.forfeit = forfeitArr.join(",");
                if (!val) {
                  delete info.forfeit;
                }
                this.props.setSeasonState({
                  matchInfo: info
                });
              }}
              onBlur={() => {
                // If user enters null value, reset forfeit to default (15 minutes)
                let info = cloneDeep(matchInfo);
                if (info.forfeit) {
                  let forfeitArr = info.forfeit.split(",");
                  if (!forfeitArr[0] || !parseInt(forfeitArr[0])) {
                    delete info.forfeit;
                    this.props.setSeasonState({ matchInfo: info });
                  }
                }
              }}
              value={propValueOr(matchInfo, "forfeit", "").split(",")[0] || ""}
            />
            <FormFieldKit
              select
              selectOptions={timeOptions}
              label={"Time"}
              placeholder={"min"}
              fullWidth
              onChange={f => {
                let info = cloneDeep(matchInfo);
                let forfeitArr = matchInfo.forfeit
                  ? matchInfo.forfeit.split(",")
                  : ["15", "minutes"];
                forfeitArr[1] = f;
                info.forfeit = forfeitArr.join(",");
                this.props.setSeasonState({ matchInfo: info });
              }}
              value={
                propValueOr(matchInfo, "forfeit", "").split(",")[1] || "minutes"
              }
            />
          </Row>
          <Row>
            <FormFieldKit
              label={`Best Of Series`}
              description={
                "How many games per round? The winner of the round will be determined by who wins the majority of the games in the series."
              }
              select
              selectOptions={bestOfOptions}
              multiline
              fullWidth
              placeholder={"Single Game Series (standard)"}
              onChange={val => {
                let info = cloneDeep(matchInfo);
                info["Best Of"] = val;
                if (!val) {
                  delete info["Best Of"];
                }
                this.props.setSeasonState({ matchInfo: info });
              }}
              value={matchInfo ? matchInfo["Best Of"] : null}
            />
          </Row>
          <Row>
            <FormFieldKit
              label={`Result Verification Method for Matches`}
              description={
                "What kind of result verification? Matches will not complete until verified with the chosen method."
              }
              select
              selectOptions={verificationOptions}
              multiline
              fullWidth
              placeholder={
                "Score Entry w/ No Pic Upload (Same winner entered = advance, standard challenge process)"
              }
              onChange={val => {
                let info = cloneDeep(matchInfo);
                info["Verification"] = val;
                if (!val) {
                  delete info["Verification"];
                }
                this.props.setSeasonState({ matchInfo: info });
              }}
              value={matchInfo ? matchInfo["Verification"] : null}
            />
          </Row>
        </Settings>
        {templates?.length > 0 && (
          <Settings>
            {settings && (
              <>
                <Wrap style={{ margin: "20px 0 0px" }}>
                  <SettingsTitle margin="0">
                    Default Match Template Game Settings
                  </SettingsTitle>
                  <Icon
                    onClick={() =>
                      this.setState({ isAddSettingDialogOpen: true })
                    }
                  >
                    <IconCirclePlus width={25} />
                  </Icon>
                </Wrap>
                <Description>
                  Define the game settings to be used for all of the matches in
                  this season. All matches can be edited after creation as
                  needed.
                </Description>
                <br />
                {propValueOr(Object.entries(settings), "length") > 0 ? (
                  Object.entries(settings).map((item, index) => (
                    <FormFieldKit
                      key={index}
                      fullWidth
                      placeholder={"Enter setting..."}
                      label={
                        <Wrap style={{ marginBottom: "-5px" }}>
                          <Label>{item[0]}</Label>
                          <img
                            onClick={() => {
                              const newSettings = { ...settings };
                              delete newSettings[item[0]];
                              this.props.setSeasonState({
                                settings: newSettings
                              });
                            }}
                            style={{ cursor: "pointer" }}
                            src={iconX}
                            alt=""
                            width={22}
                            height={22}
                          />
                        </Wrap>
                      }
                      inputProps={{ maxLength: 50 }}
                      onChange={val => {
                        this.props.setSeasonState({
                          settings: { ...settings, [item[0]]: val }
                        });
                      }}
                      value={item[1]}
                    />
                  ))
                ) : (
                  <WrapEmpty>
                    No Season Settings. You can add settings by clicking the '+'
                    icon above.
                  </WrapEmpty>
                )}
              </>
            )}
          </Settings>
        )}
        <Row>
          <SettingsTitle margin="0">Create Rounds</SettingsTitle>
        </Row>
        <Row>
          <Description>
            Set the date and time for each round of matches in this Season.
          </Description>
        </Row>
        <Schedule
          season={season}
          schedule={schedule}
          handleMatch={this.handleMatch}
          addMatch={this.addMatch}
          deleteMatch={this.deleteMatch}
          errors={getSeasonErrors(season)}
          isCreate
        />
        {(season?.error || generalError) && (
          <Error>
            Error creating season:{" "}
            {generalError || season?.error?.response?.message}
          </Error>
        )}
        <Button
          onClick={() => this.createSeason()}
          fullWidth
          shape={"rounded"}
          color={"rival_red"}
          width={"200px"}
          disabled={
            !this.props.user.organizationId || !getSeasonErrors(season, true)
          }
          preloader={loading}
        >
          CREATE SEASON
        </Button>
        {this.renderAddSettingDialog()}
      </Container>
    );
  }
}

const mapStateProps = state => ({
  league: state.leaguesState.league,
  season: state.leaguesState.season,
  templates: state.gamesState.templates,
  isFetchingTemplates: state.gamesState.isFetchingTemplates,
  organizationId: state.user?.info?.organizationId,
  newSeasonIndex: getMaxSeasonIndex(state) + 1
});

const mapDispatchToProps = {
  fetchLeague,
  createSeason,
  setSeasonState,
  fetchTemplates,
  setSeasonProperty,
  resetSeasonState,
  fetchSeason
};

export default connect(mapStateProps, mapDispatchToProps)(SeasonCreate);
