import ReactMarkdown from "react-markdown";
import styled, { css } from "styled-components";
import {
  COLOR,
  FONT_FAMILY,
  FONT_WEIGHT
} from "../../../../../../constants/theme";
import pushpin from "../../../../../../static/icons/pushpin.svg";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 5px 20px 0;
  width: 100%;
  max-height: 400px;
  overflow-y: auto;

  > :not(:last-child) {
    margin-bottom: 12px;
  }

  ::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${COLOR.BG_SECONDARY};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${COLOR.BG_PRIMARY};
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${COLOR.RIVAL_RED};
  }
`;

export const Tooltip = styled.div`
  position: absolute;
  z-index: 1;
  display: none;
  top: -6px;
  right: 20px;
  padding: 5px;
  border-radius: 8px;
  background-color: ${COLOR.BG_PRIMARY};
`;

export const Message = styled.div`
  border: 1px solid ${COLOR.RIVAL_RED};
  padding: 15px;
  display: flex;
  align-items: flex-start;
  width: 100%;
  background-color: ${COLOR.BG_PRIMARY};
  border-radius: 8px;
  position: relative;

  &:hover ${Tooltip} {
    display: ${({ isEditing, isAdmin }) =>
      isEditing || !isAdmin ? "none" : "flex"};
    top: ${({ showUser }) => (showUser ? "8px" : "-10px")};
  }

  ${({ deleted }) =>
    deleted &&
    css`
      background-color: ${COLOR.GREY};
      border: 1px solid ${COLOR.GREY};
    `}
`;

export const Avatar = styled.img`
  width: 28px;
  height: 28px;
  margin-right: 11px;
  border-radius: 2px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const WrapInfo = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  color: #aaaaaa;
  font: ${FONT_WEIGHT.BOLD} 12px / 14px ${FONT_FAMILY.THIN};
  margin-top: -3px;
  ${({ deleted }) =>
    deleted &&
    css`
      color: ${COLOR.TEXT};
    `}
`;

export const WrapName = styled.div`
  display: flex;
  align-items: center;
`;

export const Name = styled.div`
  span {
    color: ${COLOR.TEXT};
  }
`;

export const IconPin = styled.img.attrs({ src: pushpin, alt: "" })`
  width: 18px;
  height: 18px;
`;

export const Time = styled.div``;

export const Text = styled.div`
  color: ${COLOR.TEXT};
  font: ${FONT_WEIGHT.NORMAL} 14px / 16px ${FONT_FAMILY.THIN};
  white-space: pre-wrap;
`;

export const WrapIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  padding: 5px;
  margin: 0 5px;

  cursor: pointer;
  border-radius: 4px;
  opacity: 0.8;
  transition: all 0.2s;

  &:hover {
    opacity: 1;
    background-color: ${COLOR.BG_INPUT};
  }
`;
export const Icon = styled.img`
  width: 15px;
  height: 15px;
  ${({ scale }) => scale && `transform: scale(1.5);`}
`;

export const Input = styled.textarea`
  width: 100%;
  height: 75px;
  border: 1px solid rgba(204, 204, 204, 0);
  background-color: ${COLOR.BG_INPUT};
  color: ${COLOR.TEXT};
  font-size: 13px;
  font-weight: ${FONT_WEIGHT.BOLD};
  letter-spacing: -0.22px;
  resize: none;
  word-break: break-word;

  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: ${COLOR.GREY};
    opacity: 0.4;
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: ${COLOR.GREY};
    opacity: 0.4;
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    color: ${COLOR.GREY};
    opacity: 0.4;
  }
  :-moz-placeholder {
    /* Firefox 18- */
    color: ${COLOR.GREY};
    opacity: 0.4;
  }
`;

export const OrderInput = styled.input`
  width: 40px;
  height: 100%;
  border: 1px solid rgba(204, 204, 204, 0);
  background-color: ${COLOR.BG_INPUT};
  color: ${COLOR.TEXT};
  font-size: 12px;
  font-weight: ${FONT_WEIGHT.BOLD};

  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: ${COLOR.GREY};
    opacity: 0.4;
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: ${COLOR.GREY};
    opacity: 0.4;
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    color: ${COLOR.GREY};
    opacity: 0.4;
  }
  :-moz-placeholder {
    /* Firefox 18- */
    color: ${COLOR.GREY};
    opacity: 0.4;
  }
`;

export const WrapAction = styled.div`
  display: flex;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
`;

export const Title = styled.div`
  font-size: 18px;
  text-transform: uppercase;
`;

export const Description = styled.div`
  font-size: 14px;
  color: ${COLOR.GREY};
`;
export const Note = styled.span`
  font-size: 14px;
  color: ${COLOR.GREY};
  margin-top: 7px;
`;

export const NavItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid transparent;
  cursor: pointer;
  transition: all 0.5s ease;

  ${({ active }) =>
    active &&
    css`
      border-bottom: 1px solid ${COLOR.RIVAL_RED};
      color: ${COLOR.RIVAL_RED};
    `};

  &:hover {
    border-bottom: 1px solid ${COLOR.RIVAL_RED};
  }

  ${({ disabled, active }) =>
    disabled &&
    css`
      cursor: not-allowed;
      :hover {
        border-bottom: 1px solid transparent;
      }
    `};
`;

export const Markdown = styled(ReactMarkdown)`
  font-size: 14px;
  a {
    color: #3f85fc;
    display: inline-block;
  }
`;
