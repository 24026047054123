import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PermissionDenied from "../denied/PermissionDenied";
import RouteLeague from "./RouteLeague";
import { ChangeOrg } from "./styled";

class RouteLeagueContainer extends Component {
  render() {
    const { permission, user } = this.props;
    if (!permission) {
      return <PermissionDenied />;
    }

    if (!user?.info?.organizationId) {
      return <ChangeOrg>SELECT AN ORGANIZATION TO VIEW LEAGUES</ChangeOrg>;
    }

    return <RouteLeague />;
  }
}

const mapStateProps = state => ({
  user: state.user
});

const mapDispatchToProps = {};

export default connect(
  mapStateProps,
  mapDispatchToProps
)(withRouter(RouteLeagueContainer));
