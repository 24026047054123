import { propValueOr } from "../../helpers/common";
import { api } from "../../index";
import LEAGUES_ACTIONS from "./constants";

export const fetchLeagues = query => dispatch => {
  dispatch({
    type: LEAGUES_ACTIONS.list.request
  });
  api.league
    .list(query)
    .then(resp => {
      const leagues = propValueOr(resp, "data", []);
      for (let i = 0; i < leagues.length; i++) {
        let initialized = 0,
          inProgress = 0;
        let complete = 0;
        leagues[i].seasons.forEach(s => {
          switch (s.status) {
            case "initialized":
              initialized++;
              break;
            case "in progress":
              inProgress++;
              break;
            case "complete":
              complete++;
              break;
            default:
              break;
          }
        });
        if (inProgress > 0) {
          leagues[i].status = "inProgress";
        } else if (initialized > 0) {
          leagues[i].status = "upcoming";
        } else if (complete > 0) {
          leagues[i].status = "completed";
        } else {
          leagues[i].status = "upcoming";
        }
      }
      dispatch({
        type: LEAGUES_ACTIONS.list.success,
        payload: leagues
      });
    })
    .catch(err => {
      dispatch({
        type: LEAGUES_ACTIONS.list.error,
        payload: err
      });
    });
};

export const fetchLeague = query => dispatch => {
  dispatch({
    type: LEAGUES_ACTIONS.fetch.request
  });
  api.league
    .get(query)
    .then(resp => {
      dispatch({
        type: LEAGUES_ACTIONS.fetch.success,
        payload: propValueOr(resp, "data", {})
      });
    })
    .catch(err => {
      dispatch({
        type: LEAGUES_ACTIONS.fetch.error,
        payload: err
      });
    });
};

export const fetchSeasons = query => dispatch => {
  dispatch({
    type: LEAGUES_ACTIONS.seasons.list.request
  });
  api.season
    .list(query)
    .then(resp => {
      dispatch({
        type: LEAGUES_ACTIONS.seasons.list.success,
        payload: propValueOr(resp, "data", [])
      });
    })
    .catch(err => {
      dispatch({
        type: LEAGUES_ACTIONS.seasons.list.error,
        payload: err
      });
    });
};

export const fetchSeason = query => dispatch => {
  dispatch({
    type: LEAGUES_ACTIONS.seasons.fetch.request
  });
  return api.season
    .get(query)
    .then(resp => {
      const season = propValueOr(resp, "data", {});
      season.isTeamSeason = season?.teamSize && season?.teamSize > 1;
      season.entryFee = isNaN(season?.entryFee / 100)
        ? null
        : season?.entryFee / 100;

      dispatch({
        type: LEAGUES_ACTIONS.seasons.fetch.success,
        payload: season
      });
    })
    .catch(err => {
      dispatch({
        type: LEAGUES_ACTIONS.seasons.fetch.error,
        payload: err
      });
    });
};

export const updateSeason = (token, body) => dispatch => {
  dispatch({
    type: LEAGUES_ACTIONS.seasons.update.request
  });
  return api.season
    .update(token, body)
    .then(resp => {
      dispatch({
        type: LEAGUES_ACTIONS.seasons.update.success,
        payload: propValueOr(resp, "data", {})
      });
    })
    .catch(err => {
      dispatch({
        type: LEAGUES_ACTIONS.seasons.update.error,
        payload: err
      });
    });
};

export const createSeason = body => dispatch => {
  dispatch({
    type: LEAGUES_ACTIONS.seasons.create.request
  });
  return api.season
    .create(body)
    .then(resp => {
      dispatch({
        type: LEAGUES_ACTIONS.seasons.create.success,
        payload: propValueOr(resp, "data", {})
      });
    })
    .catch(err => {
      dispatch({
        type: LEAGUES_ACTIONS.seasons.create.error,
        payload: err
      });
      throw err;
    });
};

// Set single key-value pair for current season state
export const setSeasonProperty = (key, value) => dispatch => {
  dispatch({
    type: LEAGUES_ACTIONS.seasons.setProperty,
    payload: {
      key,
      value
    }
  });
};

// Set entire new state for current season
export const setSeasonState = state => dispatch => {
  dispatch({
    type: LEAGUES_ACTIONS.seasons.setState,
    payload: state
  });
};

// Reset only current season state
export const resetSeasonState = () => dispatch => {
  dispatch({
    type: LEAGUES_ACTIONS.seasons.reset
  });
};

// Reset league state
export const resetLeague = () => dispatch => {
  dispatch({
    type: LEAGUES_ACTIONS.resetLeague
  });
};

// Reset entire leagues state
export const resetLeagues = () => dispatch => {
  dispatch({
    type: LEAGUES_ACTIONS.reset
  });
};
