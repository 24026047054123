import styled from "styled-components";
import { COLOR, FONT_FAMILY, FONT_WEIGHT } from "../../constants/theme";
import { Link } from "react-router-dom";
import withTooltip from "../../hoc/withTooltip";
import breakpoints from "../../helpers/breakpoints";

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  min-height: 100%;
  max-width: 1440px;
  width: 100%;
  color: ${COLOR.WHITE};
  z-index: 3;

  padding: 10px;
  border-radius: 16px;

  background-color: ${COLOR.BG_SECONDARY};
`;

export const Container = styled.div`
  min-height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 10px 10px;
`;

export const Text = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 20px;
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  height: 150px;
  width: 100%;
  background: ${({ image }) => `linear-gradient(
      rgba(0, 0, 0, 0.9), 
      rgba(0, 0, 0, 0.9)
    ), url(${image})`};
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: ${COLOR.BG_PRIMARY};
  margin: 0 0 10px;
  padding: 12.5px 20px;
  transition: all 0.25s ease 0s;
  cursor: pointer;
  border-radius: 8px;
  &:hover {
    background: ${({ image }) => `linear-gradient(
      rgba(0, 0, 0, 0.8), 
      rgba(0, 0, 0, 0.8)
    ), url(${image})`};
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: ${COLOR.BG_PRIMARY};
  }
  position: relative;

  ${breakpoints.down("sm")} {
    padding: 12.5px;
  }
`;

export const LeaguesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 10px;
`;

export const LeaguesHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: ${COLOR.TEXT};
  margin: 0 0 10px;
`;

export const WrapAddButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 3px;
`;

export const AddLeaguesButton = styled(withTooltip(Link)).attrs(props => ({
  tooltip: {
    text: "Create new league",
    position: "left",
    delta: 5
  }
}))`
  cursor: pointer;
  transition: all 0.3s;
`;

export const PlusIcon = styled.div`
  cursor: pointer;
  width: 32px;
  height: 32px;
  margin-right: 10px;
  margin-bottom: 5px;
  color: ${COLOR.TEXT};
  text-decoration: none;
`;

export const WrapLink = styled(Link)`
  color: ${COLOR.TEXT};
  text-decoration: none;
  width: 100%;
  height: 100%;
`;

export const Image = styled.img`
  // 16:9 AR
  width: 222.22px;
  height: 125px;
  min-width: 222.22px;
  min-height: 125px;
  border-radius: 8px;
  margin: 0 20px 0 0;

  ${breakpoints.down("sm")} {
    /* width: 177.78px;
    height: 100px;
    min-width: 177.78px;
    min-height: 100px; */
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

export const Title = styled.div`
  font: ${FONT_WEIGHT.EXTRA_BOLD} 20px / 1 ${FONT_FAMILY.THIN};
  display: flex;
  align-items: center;
`;

export const Players = styled.div`
  font: ${FONT_WEIGHT.NORMAL} 16px / 1 ${FONT_FAMILY.THIN};
  margin-left: auto;
  /* margin-right: 20px; */
  margin-bottom: -3px;
  color: ${COLOR.TEXT};
`;

export const Description = styled.div`
  font: ${FONT_WEIGHT.NORMAL} 14px / 1.2 ${FONT_FAMILY.THIN};
  color: ${COLOR.GREY};

  height: 50px;
  margin: 8px 0 0;
  flex: 1;
  white-space: pre-line;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

export const ChangeOrg = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font: ${FONT_WEIGHT.MEDIUM} 24px / 1 ${FONT_FAMILY.THIN};
  color: ${COLOR.TEXT};
  margin: 50px 0;
`;

export const WrapFilter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const WrapSearch = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0 20px;
  width: 100%;
`;

export const Seasons = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  font-size: 14px;
  color: ${COLOR.GREY};
  margin-top: 10px;
`;
export const Label = styled.div`
  margin-right: 7px;
  font: ${FONT_WEIGHT.MEDIUM} 14px / 1 ${FONT_FAMILY.THIN};
  display: flex;
  align-items: center;
`;

export const Labels = styled.div`
  display: flex;
  color: ${COLOR.TEXT};
  > :not(:last-child) {
    margin-right: 8px;
  }
  > :not(:first-child) {
    margin-left: 8px;
  }

  b {
    color: ${COLOR.GREY};
  }
`;

export const WrapImages = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  > :not(:last-child) {
    margin-right: 10px;
  }

  img {
    max-width: 80px;
    max-height: 25px;
    ${breakpoints.down(1040)} {
      max-width: 60px;
      max-height: 20px;
    }
  }
`;
