import React, { Component } from "react";
import { createPortal } from "react-dom";
import PropTypes from "prop-types";
import {
  getBodyScrollWidth,
  getBrowserInfo,
  handleEventStopPropagation
} from "../../../helpers/common";
import {
  DialogBtnCloseSD,
  DialogBtnCloseCustomSD,
  DialogFooterSD,
  DialogHeaderSD,
  DialogInnerSD,
  DialogTitleSD,
  DialogWrapperSD,
  DialogInnerCustomSD
} from "./styled";
import IconPlus from "../Icon/IconPlus";
import LevelUp from "../../presentation/LevelUp/LevelUp";
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks
} from "body-scroll-lock";
import { COLOR } from "../../../constants/theme";

class DialogKit extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /** render custom header*/
    renderCustom: PropTypes.func,
    renderHeader: PropTypes.func,
    renderBody: PropTypes.func,
    renderFooter: PropTypes.func,
    displayButtonClose: PropTypes.bool,
    bgColor: PropTypes.string,
    isOverflow: PropTypes.bool,
    isMobile: PropTypes.bool
  };

  static defaultProps = {
    displayButtonClose: true,
    bgColor: COLOR.BG_SECONDARY
  };

  state = {
    isOpen: false
  };

  dialogRef = React.createRef();

  componentDidMount() {
    this.browserInfo = getBrowserInfo();
    if (this.props.isOpen) {
      this.openDialog();
    }
  }

  componentDidUpdate(prevProps) {
    const { isOpen } = this.props;

    if (prevProps.isOpen !== isOpen) {
      if (isOpen) {
        this.openDialog();
      } else {
        this.closeDialog();
      }
    }
  }

  componentWillUnmount() {
    this.closeDialog();
    clearAllBodyScrollLocks();
  }

  handlePressEsc = e => {
    if (e.keyCode === 27) {
      this.props.onClose();
    }
  };

  openDialog = () => {
    this.oldBodyStyleAttr = document.body.getAttribute("style") || "";
    document.body.style.paddingRight = `${getBodyScrollWidth()}px`;
    document.documentElement.addEventListener("keydown", this.handlePressEsc);

    this.setState({ isOpen: true }, () => {
      if (
        !(
          this.browserInfo.name === "Safari" &&
          this.browserInfo.version === "13"
        )
      ) {
        disableBodyScroll(this.dialogRef.current);
      }
    });
  };

  closeDialog = () => {
    document.body.setAttribute("style", this.oldBodyStyleAttr || "");
    this.oldBodyStyleAttr = "";
    document.documentElement.removeEventListener(
      "keydown",
      this.handlePressEsc
    );

    if (
      !(this.browserInfo.name === "Safari" && this.browserInfo.version === "13")
    ) {
      enableBodyScroll(this.dialogRef.current);
    }

    this.setState({ isOpen: false });
  };

  renderDialog = () => {
    const { isOpen } = this.state;
    const {
      width,
      onClose,
      displayButtonClose,
      bgColor,
      isMobile
    } = this.props;

    if (!isOpen) {
      return null;
    }

    return (
      <LevelUp type={"modal"}>
        <DialogWrapperSD onClick={onClose} isOverflow ref={this.dialogRef}>
          {this.props.renderCustom ? (
            <DialogInnerCustomSD
              onClick={handleEventStopPropagation}
              width={width}
            >
              {this.props.renderCustom()}
              {displayButtonClose && (
                <DialogBtnCloseCustomSD onClick={onClose}>
                  <IconPlus width={19} />
                </DialogBtnCloseCustomSD>
              )}
            </DialogInnerCustomSD>
          ) : (
            <DialogInnerSD
              onClick={handleEventStopPropagation}
              width={width}
              bgColor={bgColor}
              isOverflow
              isMobile={isMobile}
            >
              {this.renderHeader()}

              {this.renderBody()}

              {this.renderFooter()}

              {displayButtonClose && (
                <DialogBtnCloseSD onClick={onClose}>
                  <IconPlus width={19} />
                </DialogBtnCloseSD>
              )}
            </DialogInnerSD>
          )}
        </DialogWrapperSD>
      </LevelUp>
    );
  };
  renderHeader = () => {
    const { title, renderHeader } = this.props;
    const hasRenderHeader = typeof renderHeader === "function";

    return hasRenderHeader ? (
      renderHeader()
    ) : (
      <DialogHeaderSD>
        <DialogTitleSD>{title}</DialogTitleSD>
      </DialogHeaderSD>
    );
  };
  renderBody = () => {
    const { renderBody } = this.props;
    const hasRenderBody = typeof renderBody === "function";

    return hasRenderBody && renderBody();
  };

  renderFooter = () => {
    const { renderFooter } = this.props;
    const hasRenderFooter = typeof renderFooter === "function";

    return hasRenderFooter && <DialogFooterSD>{renderFooter()}</DialogFooterSD>;
  };
  render() {
    return createPortal(
      this.renderDialog(),
      document.getElementById("root-modal")
    );
  }
}

export default DialogKit;
