import styled from "styled-components";
import { COLOR } from "../../../../constants/theme";

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  color: ${COLOR.TEXT};
  > :not(:last-child) {
    margin-right: 10px;
  }
`;
