import styled from "styled-components";
import {
  COLOR,
  FONT_FAMILY,
  FONT_WEIGHT
} from "../../../../../constants/theme";
import breakpoints from "../../../../../helpers/breakpoints";

export const Container = styled.div`
  width: 100%;
  padding: 26px;
  background-color: ${COLOR.BG_PRIMARY};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  width: 100%;
`;
export const Card = styled.div`
  width: 100%;

  margin: 0 0 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;

  border-top: 1px solid ${COLOR.BORDER};
  border-bottom: 1px solid ${COLOR.BORDER};

  ${breakpoints.down(700)} {
    flex-direction: column;
    align-items: flex-start;
    > :not(:last-child) {
      margin-bottom: 25px;
    }
  }
`;

export const Stages = styled.div`
  border-top: 1px solid ${COLOR.BORDER};
  margin-bottom: 30px;

  > :not(:last-child) {
    border-bottom: 1px solid ${COLOR.BORDER};
  }
`;

export const Header = styled.div`
  font: ${FONT_WEIGHT.BOLD} 18px ${FONT_FAMILY.GOTHIC_BOLD};
  width: 100%;
  padding: 15px 0px 20px 0px;
  text-transform: uppercase;
`;

export const Info = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const Image = styled.img`
  width: 42px;
  height: 63px;
`;
export const Details = styled.div`
  margin-left: 12px;
`;
export const Subtitle = styled.div`
  font: ${FONT_WEIGHT.MEDIUM} 16px ${FONT_FAMILY.GOTHIC};
  letter-spacing: 1px;
  text-transform: capitalize;
`;
export const Time = styled.div`
  font: ${FONT_WEIGHT.MEDIUM} 24px / 30px ${FONT_FAMILY.GOTHIC_BOLD};
  letter-spacing: 1.3px;
`;
export const Status = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
export const Dot = styled.div`
  width: 9px;
  height: 9px;
  margin-right: 7px;
  border-radius: 50%;
  background-color: ${({ color }) => color || COLOR.YELLOW};
`;

export const Text = styled.div`
  font: ${FONT_WEIGHT.MEDIUM} 14px ${FONT_FAMILY.CONDENSED};
  color: ${COLOR.TEXT};
`;
export const DialogText = styled.div`
  font: ${FONT_WEIGHT.NORMAL} 14px ${FONT_FAMILY.THIN};
  color: ${COLOR.TEXT};
`;

export const Fields = styled.div`
  padding: 20px 0;
  margin: 20px 0px;
  border-top: 1px solid ${COLOR.BORDER};
  border-bottom: 1px solid ${COLOR.BORDER};
`;

export const Row = styled.div`
  display: flex;
  > :not(:last-child) {
    margin-right: 20px;
  }
`;

export const WrapInput = styled.div`
  width: 100%;
  margin-top: 20px;

  > :last-child {
    margin-top: 10px;
  }
`;
export const Input = styled.input`
  width: 100%;
  margin-bottom: 5px;
`;
export const Label = styled.div`
  text-transform: capitalize;
  font: ${FONT_WEIGHT.BOLD} 14px ${FONT_FAMILY.GOTHIC};
  margin-bottom: 3px;
`;

export const Description = styled.div`
  font: ${FONT_WEIGHT.NORMAL} 12px ${FONT_FAMILY.GOTHIC};
  margin-bottom: 10px;
  color: ${COLOR.GREY};
`;

export const Separator = styled.div`
  width: 100%;
  height: 20px;
  background-color: ${COLOR.BG_PRIMARY};
`;

export const WrapChat = styled.div`
  position: relative;
  background-color: ${COLOR.BG_SECONDARY};
  padding: 25px 0;
  text-align: center;
  max-height: 500px;
  font: ${FONT_WEIGHT.NORMAL} 20px ${FONT_FAMILY.GOTHIC_BOLD};
  > :first-child {
    text-align: left;
    border: 1px solid ${COLOR.BORDER};
    margin-top: 10px;
  }
`;

export const TournamentPanels = styled.div`
  border-top: 1px solid ${COLOR.BORDER};
  border-bottom: 1px solid ${COLOR.BORDER};
  margin-bottom: 20px;

  > :not(:last-child) {
    border-bottom: 1px solid ${COLOR.BORDER};
  }
`;

export const PanelContent = styled.div`
  width: 100%;

  padding: 0px 30px;
`;

export const ButtonWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;

  > :not(:last-child) {
    margin-right: 12px;
  }
`;

export const IconWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
`;

export const Icon = styled.img`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 3px;
`;

export const Delete = styled.div``;

export const WrapLabel = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const Preview = styled.img`
  max-height: 100px;
`;

export const PreviewWrap = styled.div`
  height: 100px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const WrapPinButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  overflow: hidden;
  padding: 5px;
  padding-right: 15px;
  border-radius: 5px;
  z-index: 100000;
`;

export const PinChatButton = styled.img`
  max-width: 20px;
  height: 20px;

  cursor: pointer;
  &:hover {
    opacity: 0.4;
  }
`;
