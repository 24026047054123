import { PERMISSIONS_ACTIONS } from "./constants";

const initialState = {
  permissions: [],
  loading: false,
  error: null
};

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case PERMISSIONS_ACTIONS.fetch.request:
      return {
        ...state,
        loading: true,
        error: null
      };
    case PERMISSIONS_ACTIONS.fetch.success:
      return {
        ...state,
        loading: false,
        permissions: action.payload
      };
    case PERMISSIONS_ACTIONS.fetch.error:
      return {
        ...state,
        permissions: [],
        loading: false,
        error: action.payload
      };

    case PERMISSIONS_ACTIONS.reset:
      return initialState;
    default:
      return state;
  }
};

export default reducers;
