import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import { COLOR, FONT_FAMILY, FONT_WEIGHT } from "../../../constants/theme";

export const AsideNavSD = styled.div``;

export const AsideNavListSD = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;

  border-radius: 2px;
  overflow: hidden;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
`;

const stylesNavItemLinkHover = css`
  border-bottom: 4px solid ${COLOR.RIVAL_RED};
`;

export const AsideNavItemLinkSD = styled.div`
  display: block;

  color: ${COLOR.FG_PRIMARY};
  font: ${FONT_WEIGHT.NORMAL} 14px / 40px ${FONT_FAMILY.THIN};
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none;

  transition: all 0.3s;

  ${({ notClickable }) =>
    notClickable &&
    `
      pointer-events: none;
  `}
`;
AsideNavItemLinkSD.propTypes = {
  isActive: PropTypes.bool
};

export const Text = styled.div`
  display: flex;
  flex-shrink: 1;
  margin: 0 auto;
  &:hover {
    ${stylesNavItemLinkHover};
  }

  ${({ isActive }) => isActive && stylesNavItemLinkHover};

  transition: all 0.3s;
`;

export const DisabledText = styled.div`
  display: flex;
  flex-shrink: 1;
  margin: 0 auto;
  color: ${COLOR.BUTTON_DISABLED_FONT_COLOR};
`;

export const AsideNavItemBodySD = styled.div`
  padding: 10px;
`;

export const AsideNavItemSD = styled.li`
  & + & {
    ${AsideNavItemLinkSD} {
      border-top: 1px solid ${COLOR.BORDER};
    }
  }
`;
