import React, { Component } from "react";
import { Container, Content } from "./styled";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  listTournamentAction,
  resetTournamentAction
} from "../../../store/tournament/actions";
import { getTournamentCalendarEvents } from "../../../store/tournament/selectors";
import Pusher from "pusher-js";
import * as queryString from "query-string";
import PermissionDenied from "../../denied/PermissionDenied";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/sass/styles.scss";
import "./calendar.scss";

import moment from "moment";

const localizer = momentLocalizer(moment);
class TournamentCalendar extends Component {
  constructor(props) {
    super(props);

    this.pusher = new Pusher(process.env.REACT_APP_PUSHER_ID || "", {
      cluster: "us3",
      forceTLS: true
    });

    this.state = {
      searchValue: ""
    };
  }

  componentDidMount() {
    this.fetchTournaments();

    const channel = this.pusher.subscribe(`tournaments`);

    channel.unbind();

    channel.bind("reload", () => {
      this.fetchTournaments();
    });
  }

  fetchTournaments = () => {
    this.props.fetchTournaments(this.getQueryParams());
  };

  getQueryParams = () => {
    const parsed = queryString.parse(this.props.location.search);

    return {
      status: parsed.status || "initialized",
      page: parsed.page || 1,
      limit: 50,
      q: parsed.q,
      organizationId: this.props.user.organizationId,
      affiliationId: this.props.affiliationId,
      includeStages: true
    };
  };

  render() {
    const { permission, tournamentEvents, history } = this.props;
    if (!permission) {
      return <PermissionDenied />;
    }

    return (
      <Container>
        <Content>
          <Calendar
            localizer={localizer}
            events={tournamentEvents}
            onSelectEvent={event => {
              console.log("event click", event);
              history.push(`/tournaments/${event.token}`);
            }}
            startAccessor="start"
            endAccessor="end"
            style={{ height: 700 }}
          />
        </Content>
      </Container>
    );
  }
}

const mapStateProps = state => ({
  user: state.user.info,
  tournamentEvents: getTournamentCalendarEvents(
    state.tournamentState.tournaments
  ),
  isFetching: state.tournamentState.isFetching,
  total: state.tournamentState.total
});

const mapDispatchToProps = {
  fetchTournaments: listTournamentAction,
  resetTournaments: resetTournamentAction
};

export default connect(
  mapStateProps,
  mapDispatchToProps
)(withRouter(TournamentCalendar));
