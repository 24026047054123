import React, { Component } from "react";
import { WrapPage, Content, SelectOrg } from "./styled";
import { connect } from "react-redux";
import RouteAssets from "./RouteAssets";
import { withRouter } from "react-router-dom";
import { fetchAssetsAction } from "../../store/assets/actions";
import { fetchGames, resetGames } from "../../store/games/actions";
import { fetchConsolesAction } from "../../store/consoles/actions";
import { getGlobalUser } from "../../store/user/selectors";
import { fetchOrganizationGames } from "../../store/orgGames/actions";
import PermissionDenied from "../denied/PermissionDenied";

class RouteAssetsContainer extends Component {
  componentDidMount() {
    this.props.fetchGames();
    this.props.fetchConsolesAction();
  }

  render() {
    const { user, permission } = this.props;
    if (!user.info) {
      return null;
    }
    if (!!user.info.organizationId) {
      this.props.fetchAssetsAction({
        organizationId: user.info.organizationId
      });
      this.props.fetchOrganizationGames(user.info.organizationId);
    } else {
      return (
        <WrapPage>
          <Content>
            <SelectOrg>SELECT AN ORGANIZATION TO VIEW ASSETS</SelectOrg>
          </Content>
        </WrapPage>
      );
    }
    if (!permission) {
      return <PermissionDenied />;
    }
    return <RouteAssets />;
  }
}

const mapStateToProps = state => ({
  user: getGlobalUser(state)
});

const mapDispatchToProps = {
  fetchOrganizationGames,
  fetchAssetsAction,
  fetchGames,
  fetchConsolesAction,
  resetGames
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RouteAssetsContainer));
