import { api } from "../../index";
import { NOTIFICATIONS_ACTIONS } from "./constants";
import { propValueOr } from "../../helpers/common";

export const fetchNotificationsAction = () => dispatch =>
  api.notifications
    .get()
    .then(resp => {
      const notifications = propValueOr(resp, "data.docs", []);
      const sortedNotifications = getSortedNotificationsByCreatedAt(
        notifications
      );

      dispatch({
        type: NOTIFICATIONS_ACTIONS.fetch.success,
        payload: sortedNotifications
      });
    })
    .catch(err => {
      dispatch({ type: NOTIFICATIONS_ACTIONS.fetch.error, payload: { err } });
    });

export const refreshNotificationsAction = () => dispatch => {
  dispatch({
    type: NOTIFICATIONS_ACTIONS.refresh.request
  });

  api.notifications
    .get()
    .then(resp => {
      const notifications = propValueOr(resp, "data.docs", []);
      const sortedNotifications = getSortedNotificationsByCreatedAt(
        notifications
      );

      dispatch({
        type: NOTIFICATIONS_ACTIONS.refresh.success,
        payload: sortedNotifications
      });
    })
    .catch(err => {
      dispatch({ type: NOTIFICATIONS_ACTIONS.refresh.error, payload: { err } });
    });
};

export const readNotificationAction = notificationId => dispatch => {
  api.notifications
    .read(notificationId)
    .then(resp => {
      const notification = propValueOr(resp, "data", []);
      dispatch({
        type: NOTIFICATIONS_ACTIONS.read.success,
        payload: notification
      });
    })
    .catch(err => {
      dispatch({
        type: NOTIFICATIONS_ACTIONS.read.error,
        payload: { err }
      });
    });
};

export const readAllNotificationsAction = () => dispatch =>
  api.notifications
    .readAll()
    .then(resp => {
      const notifications = propValueOr(resp, "data.docs", []);
      const sortedNotifications = getSortedNotificationsByCreatedAt(
        notifications
      );

      dispatch({
        type: NOTIFICATIONS_ACTIONS.readAll.success,
        payload: sortedNotifications
      });
    })
    .catch(err => {
      dispatch({ type: NOTIFICATIONS_ACTIONS.readAll.error });
    });

function getSortedNotificationsByCreatedAt(notifications) {
  const result = [...notifications];

  result.sort((a, b) => {
    const dateA = new Date(a.createdAt);
    const dateB = new Date(b.createdAt);

    if (dateA > dateB) {
      return -1;
    }

    if (dateB > dateA) {
      return 1;
    }

    return 0;
  });

  return result;
}
