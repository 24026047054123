import styled from "styled-components";
import { COLOR, FONT_FAMILY, FONT_WEIGHT } from "../../../../constants/theme";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: ${COLOR.BG_SECONDARY};
  padding: 30px;
  border-radius: 16px;
  max-width: 1440px;
  margin: 0 10px;
  margin-bottom: 20px;
`;

export const ContainerWrap = styled.div`
  display: flex;
  width: 100%;
  max-width: 1440px;
  justify-content: center;
  align-items: flex-start;
  margin-top: 25px;
  padding: 0 11.11%;
  margin: 25px auto 0;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding-bottom: 20px;
`;

export const TournamentInfo = styled.div`
  display: flex;
  align-items: center;

  > {
    &:not(:last-child) {
      position: relative;
      margin-right: 23px;

      &:after {
        content: "";
        position: absolute;
        right: -15px;
        top: 0;
        bottom: 0;

        display: flex;
        width: 6px;
        height: 6px;
        margin: auto;
        background-color: ${COLOR.GREY};
      }
    }
  }
`;

export const WrapPause = styled.div`
  margin-left: auto;
`;

export const Title = styled.div`
  font: ${FONT_WEIGHT.MEDIUM} 28px ${FONT_FAMILY.GOTHIC_BOLD};
`;

export const ConsoleIcon = styled.img`
  max-width: 70px;
  height: 30px;
`;

export const TournamentId = styled.div`
  font: ${FONT_WEIGHT.MEDIUM} 20px ${FONT_FAMILY.GOTHIC};
`;

export const Text = styled.div``;

export const WrapSmallTimer = styled.div`
  padding: 3px 10px;
  background-color: #0c720d;
  border-radius: 13px;
`;

export const WrapChat = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
  flex: 1;
  padding-bottom: 15px;
`;

export const BackIcon = styled.img`
  cursor: pointer;
  width: 32px;
  height: 32px;
  margin-right: 10px;
  margin-bottom: 5px;
`;

export const LinkIcon = styled.img`
  position: absolute;
`;

export const Line = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: -23px 0 15px;
  padding-left: 17px;
  width: 100%;

  a {
    margin-left: 25px;
    color: ${COLOR.TEXT};
    text-decoration: none;
    cursor: pointer;
    margin-right: 16px;
    position: relative;

    &:hover {
      text-decoration: underline;
    }
  }
`;
