import React, { Component } from "react";
import { Container, WrapStages } from "./styled";
import Panel from "./Panel/Panel";
import Stage from "./Stage/Stage";
import Button from "../../../../components/kit/Button/ButtonKit";
import { Row } from "../styled";
import RadioButtonKit from "../../../../components/kit/Fields/RadioButton/RadioButtonKit";

class Stages extends Component {
  constructor(props) {
    super(props);

    this.state = {
      canCreateStages: false
    };
  }

  render() {
    const {
      stages,
      handleStage,
      addStage,
      deleteStage,
      formState
    } = this.props;

    const { canCreateStages } = this.state;

    return (
      <Container>
        {stages.length > 0 ? (
          <WrapStages>
            {stages.map((stage, index) => (
              <Panel
                key={index}
                customMargin={"0"}
                overflowWhenOpen="auto"
                triggerStyle={{ padding: "15px 20px" }}
                trigger={stage.name ? stage.name : `Stage ${stage.stage}`}
                onDelete={() => {
                  if (
                    window.confirm(
                      "Are you sure you want to delete this stage?"
                    )
                  ) {
                    deleteStage(stage);
                  }
                }}
              >
                <Stage
                  deleteStage={deleteStage}
                  handleStage={handleStage}
                  stage={stage}
                  stages={stages}
                  formState={formState}
                />
              </Panel>
            ))}
          </WrapStages>
        ) : (
          <div>
            NO STAGES -- Must have at least one stage to create the tournament.
          </div>
        )}
        {stages.length > 1 && (
          <Row
            style={{
              textAlign: "left",
              marginRight: "auto",
              marginBottom: "12px",
              color: "lightBlue"
            }}
          >
            **NOTE: If you'd like to not have additional stages, please remove
            stages until there is only one left, then select "No" below.
          </Row>
        )}
        <Row
          style={{
            textAlign: "left",
            marginRight: "auto",
            marginBottom: "4px"
          }}
        >
          Create Additional Stage? (optional - caution rarely used)
        </Row>
        <Row
          style={{
            textAlign: "left",
            marginRight: "auto",
            color: "#999",
            fontSize: "14px"
          }}
        >
          Only add a Stage if you are changing game titles or elimination types
          inside the same tournament
        </Row>
        <br />
        <Row
          style={{
            marginBottom: "10px",
            textAlign: "left",
            marginRight: "auto"
          }}
        >
          <RadioButtonKit
            label={"No"}
            horizontalLabel
            onChange={() => {
              this.setState({ canCreateStages: false });
            }}
            checked={!canCreateStages}
            fullWidth
            disabled={stages.length > 1}
          />
          <RadioButtonKit
            label={"Yes"}
            horizontalLabel
            onChange={() => {
              this.setState({ canCreateStages: true });
            }}
            checked={canCreateStages}
            fullWidth
          />
        </Row>
        {(canCreateStages || stages.length > 1) && (
          <Button
            color={"rival_red"}
            onClick={() => addStage()}
            shape={"rounded"}
            appearance={"secondary"}
          >
            CREATE A STAGE
          </Button>
        )}
      </Container>
    );
  }
}

export default Stages;
