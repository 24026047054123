import { WALLET_ACTIONS } from "./constants";

const initialState = {
  isFetching: false,
  wallet: {},
  stats: [],
  wallets: [],
  transactions: [],
  transaction: {},
  page: 1,
  total: 1,
  error: null
};

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case WALLET_ACTIONS.wallet.request:
      return {
        ...state,
        isFetching: true
      };
    case WALLET_ACTIONS.wallet.success:
      return {
        ...state,
        isFetching: false,
        wallet: action.payload
      };
    case WALLET_ACTIONS.wallet.error:
      return {
        ...state,
        isFetching: false
      };
    case WALLET_ACTIONS.stats.request:
      return {
        ...state,
        isFetching: true
      };
    case WALLET_ACTIONS.stats.success:
      return {
        ...state,
        isFetching: false,
        stats: action.payload
      };
    case WALLET_ACTIONS.stats.error:
      return {
        ...state,
        isFetching: false
      };
    case WALLET_ACTIONS.wallets.request:
      return {
        ...state,
        isFetching: true
      };
    case WALLET_ACTIONS.wallets.success:
      return {
        ...state,
        isFetching: false,
        wallets: action.payload.docs,
        page: action.payload.page,
        total: action.payload.pages
      };
    case WALLET_ACTIONS.wallets.error:
      return {
        ...state,
        wallets: [],
        page: 1,
        total: 1,
        isFetching: false
      };
    case WALLET_ACTIONS.transaction.request:
      return {
        ...state,
        isFetching: true
      };
    case WALLET_ACTIONS.transaction.success:
      return {
        ...state,
        isFetching: false,
        transaction: action.payload
      };
    case WALLET_ACTIONS.transaction.error:
      return {
        ...state,
        transaction: {},
        error: action.payload,
        isFetching: false
      };
    case WALLET_ACTIONS.transactions.request:
      return {
        ...state,
        isFetching: true
      };
    case WALLET_ACTIONS.transactions.success:
      return {
        ...state,
        isFetching: false,
        transactions: action.payload.docs,
        page: action.payload.page,
        total: action.payload.pages
      };
    case WALLET_ACTIONS.transactions.error:
      return {
        ...state,
        transactions: [],
        page: 1,
        total: 1,
        isFetching: false
      };
    case WALLET_ACTIONS.resetState:
      return initialState;
    default:
      return state;
  }
};

export default reducers;
