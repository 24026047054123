import React, { Component } from "react";
import PropTypes from "prop-types";

import {
  HeaderNotificationsNoItemsMessageSD,
  HeaderNotificationsSD,
  HeaderNotificationsToggleCounterSD,
  HeaderNotificationsToggleSD,
  HeaderNotificationsWrapGroupsSD,
  HeaderNotificationsWrapPopupSD
} from "./styled";
import PopupKit from "../../../kit/Popup/PopupKit";
import NotificationsPaper from "../../../presentation/NotificationsPaper/NotificationsPaper";
import ButtonKit from "../../../kit/Button/ButtonKit";
import NotificationsGroup from "../../../presentation/NotificationsGroup/NotificationsGroup";
import { getBriefNotifications } from "./notificationData";

import { LinkWithFilteredProps } from "../../../kit/Button/ButtonLinkKit";

import { propValueOr } from "../../../../helpers/common";

class HeaderNotifications extends Component {
  static propTypes = {
    notifications: PropTypes.shape({
      wasLoaded: PropTypes.bool,
      list: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string.isRequired
        })
      )
    }),
    fetchNotificationsAction: PropTypes.func.isRequired
  };

  state = {
    isOpen: false
  };

  componentDidMount() {
    this.props.fetchNotificationsAction();
  }

  open = () => {
    this.setState({ isOpen: true });
  };

  close = () => {
    this.setState({ isOpen: false });
    this.props.readAllNotificationsAction();
  };

  renderNoNotificationsMessage = () => {
    return (
      <HeaderNotificationsNoItemsMessageSD>
        NO NEW NOTIFICATIONS
      </HeaderNotificationsNoItemsMessageSD>
    );
  };

  render() {
    const { notifications } = this.props;
    const notificationGroups = getBriefNotifications(notifications.list);

    const countOfNewNotifications = notifications.list
      ? notifications.list.filter(item => !item.read).length
      : 0;

    const hasNewNotifications =
      propValueOr(notificationGroups, "new", []).length > 0;
    const hasEarlierNotifications =
      propValueOr(notificationGroups, "earlier", []).length > 0;
    const hasAnyNotifications = hasNewNotifications || hasEarlierNotifications;

    return (
      <HeaderNotificationsSD>
        <HeaderNotificationsToggleSD onClick={this.open}>
          {!!countOfNewNotifications && (
            <HeaderNotificationsToggleCounterSD>
              {countOfNewNotifications}
            </HeaderNotificationsToggleCounterSD>
          )}
        </HeaderNotificationsToggleSD>

        <HeaderNotificationsWrapPopupSD>
          <PopupKit
            disabledPortal
            isOpen={this.state.isOpen}
            onClose={this.close}
          >
            <NotificationsPaper
              isFixed
              footer={
                hasAnyNotifications && (
                  <ButtonKit
                    as={LinkWithFilteredProps}
                    fullWidth
                    appearance={"secondary"}
                    to={"/notifications"}
                    onClick={this.close}
                  >
                    See all
                  </ButtonKit>
                )
              }
            >
              {hasAnyNotifications ? (
                <HeaderNotificationsWrapGroupsSD onClick={this.close}>
                  {hasNewNotifications && (
                    <NotificationsGroup
                      notifications={notificationGroups.new}
                      title={"New"}
                    />
                  )}

                  {hasEarlierNotifications && (
                    <NotificationsGroup
                      notifications={notificationGroups.earlier}
                      title={"Earlier"}
                    />
                  )}
                </HeaderNotificationsWrapGroupsSD>
              ) : (
                this.renderNoNotificationsMessage()
              )}
            </NotificationsPaper>
          </PopupKit>
        </HeaderNotificationsWrapPopupSD>
      </HeaderNotificationsSD>
    );
  }
}

export default HeaderNotifications;
