import React from "react";
import PropTypes from "prop-types";
import { DateTimePickerFieldKitSD, DateTimePickerKitSD } from "./styled";
import IconCalendar from "../../Icon/IconCalendar";

function DateTimePickerKit(props) {
  const {
    placeholder,
    onChange,
    value,
    disabled,
    hiddenIcon,
    width,
    fullWidth,
    hasError,
    intervals,
    iconAlign,
    autoFocus
  } = props;
  const valueDate = value && new Date(value);
  const minDate = props.minDate && new Date(props.minDate);
  const maxDate = props.maxDate && new Date(props.maxDate);

  return (
    <DateTimePickerKitSD
      disabled={disabled}
      width={width}
      fullWidth={fullWidth}
      iconAlign={iconAlign}
    >
      <DateTimePickerFieldKitSD
        dropdownMode={"scroll"}
        selected={valueDate}
        placeholderText={placeholder}
        onChange={date => onChange(date ? date.getTime() : null)}
        showTimeSelect
        timeFormat="hh:mm a"
        dateFormat="MMMM d, yyyy hh:mm a"
        disabled={disabled}
        minDate={minDate}
        maxDate={maxDate}
        hasError={hasError}
        timeIntervals={intervals || 15}
        autoFocus={autoFocus}
      />
      {!hiddenIcon && <IconCalendar />}
    </DateTimePickerKitSD>
  );
}

DateTimePickerKit.propTypes = {
  value: PropTypes.number,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  hiddenIcon: PropTypes.bool,
  fullWidth: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  minDate: PropTypes.number,
  maxDate: PropTypes.number,
  hasError: PropTypes.bool,
  iconAlign: PropTypes.oneOf(["left", "right"])
};

DateTimePickerKit.defaultProps = {
  placeholder: "Click to select a date",
  iconAlign: "right"
};

export default DateTimePickerKit;
