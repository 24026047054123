import styled from "styled-components";
import { COLOR, FONT_FAMILY, FONT_WEIGHT } from "../../../../constants/theme";
import breakpoints from "../../../../helpers/breakpoints";

export const Container = styled.div`
  border-radius: 14px;
  display: flex;
  width: calc(100% - 5px);
  min-height: 55px;
  height: 55px;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 5px;
  color: ${COLOR.TEXT};
  text-decoration: none;
  background-color: ${COLOR.BG_PRIMARY};
  align-items: center;
  color: #f2f2f2;
  padding: 0px 10px;
  position: relative;
  transition: all 0.25s ease 0s;
  cursor: pointer;
  user-select: none;
  a {
    width: 100%;
    display: flex;
    text-decoration: none;
    align-items: center;
    color: #f2f2f2;
  }
  :hover {
    background-color: ${COLOR.BG_THIRD};
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const WrapImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 10px;
  min-width: 36px;
  min-height: 36px;
`;

export const Image = styled.img`
  width: 36px;
  height: 36px;
  object-fit: contain;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
  min-width: 150px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Date = styled.div`
  display: flex;
  justify-content: center;
  color: ${COLOR.TEXT_4};
  font-weight: 300;
  font-size: 11px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 70px;
`;

export const Title = styled.div`
  display: flex;
  color: ${COLOR.TEXT};
  font-weight: 600;
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Subtitle = styled.div`
  display: flex;
  align-items: center;
  color: ${COLOR.TEXT};
  font-weight: 400;
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 2px;
`;

export const WrapOrganization = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-right: 10px;
`;

export const Organization = styled.img`
  width: 100%;
  max-width: 40px;
  max-height: 30px;
`;

export const Icon = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 2px;
  margin: 0 5px;

  ${breakpoints.down("md")} {
    display: none;
  }
`;

export const Badge = styled.div`
  display: flex;
  justify-content: center;
  min-width: 85px;
  height: 20px;

  padding: 3px 10px;
  margin-right: 10px;
  border-radius: 12px 12px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  font: ${FONT_WEIGHT.NORMAL} 13px ${FONT_FAMILY.GOTHIC};
  text-transform: uppercase;

  background-color: ${({ status }) => {
    switch (status) {
      case "open":
        return COLOR.GREEN;
      case "pending":
        return COLOR.YELLOW;
      case "closed":
        return COLOR.GREY;
      default:
        return COLOR.BG_INPUT;
    }
  }};
  ${breakpoints.down("md")} {
    display: ${props => (!props.status ? "none" : "flex")};
  }
`;

export const Status = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const ActionInfo = styled.div`
  display: flex;
  justify-self: flex-end;
  justify-content: flex-end;
  margin-left: auto;
`;

// MatchItem
export const Players = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${breakpoints.down("xs")} {
    flex-direction: column;
  }
`;

export const Player = styled.div`
  display: flex;
  ${({ reverse }) => (!!reverse ? `flex-direction: row-reverse;` : null)}
  align-items: center;
  min-width: 150px;
  margin-right: 10px;

  ${breakpoints.down("xs")} {
    margin-right: 0px;
    margin-bottom: 5px;
  }
`;

export const Name = styled.div`
  font: ${FONT_WEIGHT.NORMAL} 13px ${FONT_FAMILY.GOTHIC};
`;

export const Avatar = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 2px;
  padding: 1px;
  background-color: ${COLOR.FG_PRIMARY};
  margin: 0 5px;
`;

export const Dot = styled.div`
  width: 9px;
  height: 9px;
  margin-right: 5px;
  margin-left: 2px;
  border-radius: 50%;
  background-color: ${({ status }) =>
    status === "SUBMITTED" || status === "NOT READY" || status === "COMPLETE"
      ? COLOR.ROUGE
      : status === "PENDING" || status === "CHALLENGED"
      ? COLOR.YELLOW_2
      : COLOR.GREEN};
`;

export const Close = styled.div`
  position: absolute;
  top: -5px;
  right: -5px;
  z-index: 100;
`;
