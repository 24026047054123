import { api } from "../../index";
import { ORGANIZATIONS_ACTIONS } from "./constants";
import { propValueOr } from "../../helpers/common";

export const fetchOrganizationsAction = () => async dispatch => {
  dispatch({
    type: ORGANIZATIONS_ACTIONS.fetch.request
  });
  return api.admin.organizations
    .list()
    .then(resp => {
      const organizations = propValueOr(resp, "data", []);

      dispatch({
        type: ORGANIZATIONS_ACTIONS.fetch.success,
        payload: organizations
      });
    })
    .catch(err => {
      dispatch({ type: ORGANIZATIONS_ACTIONS.fetch.error, payload: err });
    });
};

export const fetchAffiliationsAction = () => async dispatch => {
  dispatch({
    type: ORGANIZATIONS_ACTIONS.fetchAffiliations.request
  });
  return api.admin.organizations
    .search({ type: "affiliation", isPrimary: false })
    .then(resp => {
      const affiliations = propValueOr(resp, "data.organizations", []);

      dispatch({
        type: ORGANIZATIONS_ACTIONS.fetchAffiliations.success,
        payload: affiliations
      });
    })
    .catch(err => {
      dispatch({
        type: ORGANIZATIONS_ACTIONS.fetchAffiliations.error,
        payload: err
      });
    });
};
