import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Container,
  MenuSelector,
  OrgPic,
  OrgName,
  ChevronIcon,
  WrapChevronIcon,
  WrapOrgMenu,
  OrgMenu,
  OrgList,
  OrgMenuItem,
  WrapItem
} from "./styled";
import { updateUserInfoAction } from "../../../store/user/actions";
import PopupKit from "../../../components/kit/Popup/PopupKit";
import withWidth from "../../../hoc/withWidth";

// Icons
import Chevron from "../../../static/icons/chevron-arrow-down.svg";
import { propValueOr, verifyPermission } from "../../../helpers/common";
import RivalR from "../../../static/images/logos/Rival-Logo-White.svg";

class OrgSwitcher extends Component {
  constructor(props) {
    super(props);
    const activeOrganization = props.organizations.find(
      org => org.id === props.user.organizationId
    );
    this.state = {
      organizationId: props.user.organizationId,
      activeOrganization,
      isMenuOpen: false,
      loading: true,
      updating: false,
      error: null
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.organizations !== prevProps.organizations) {
      const activeOrganization = this.props.organizations.find(
        org => org.id === this.props.user.organizationId
      );
      this.setState({ activeOrganization });
    }
  }

  updateOrganization = organizationId => {
    this.setState({
      updating: true
    });

    this.props
      .updateUserInfoAction({
        organizationId
      })
      .then(() => {
        this.setState({
          activeOrganization: this.props.organizations.find(
            org => org.id === this.props.user.organizationId
          ),
          updating: false
        });
        window.location.reload();
      })
      .catch(e => {
        this.setState({
          updating: false,
          error: e
        });
      });
  };

  render() {
    const { activeOrganization, isMenuOpen } = this.state;
    const { organizations, permissions } = this.props;
    const hasIcon = !!propValueOr(activeOrganization, "imageInfo.icon");
    const hasPermission = verifyPermission(
      "/account/organizations",
      permissions
    );
    const organizationList = hasPermission
      ? [{ name: "All Organizations", id: null }, ...organizations]
      : organizations;
    return (
      <Container>
        <MenuSelector onClick={() => this.setState({ isMenuOpen: true })}>
          <WrapItem>
            {hasIcon && (
              <OrgPic
                src={propValueOr(activeOrganization, "imageInfo.icon", RivalR)}
                onError={e => (e.target.src = RivalR)}
              />
            )}
            <OrgName>
              {propValueOr(activeOrganization, "name", "All Organizations")}
            </OrgName>
          </WrapItem>
          <WrapChevronIcon>
            <ChevronIcon src={Chevron} />
          </WrapChevronIcon>
        </MenuSelector>
        <WrapOrgMenu>
          <PopupKit
            disabledPortal
            isOpen={isMenuOpen}
            onClose={() => this.setState({ isMenuOpen: false })}
          >
            <OrgMenu>
              <OrgList>
                {organizationList.map((org, index) => {
                  return (
                    <OrgMenuItem
                      key={org.id}
                      isActive={
                        org.id === propValueOr(activeOrganization, "id")
                      }
                      onClick={() => this.updateOrganization(org.id)}
                    >
                      <OrgName>{propValueOr(org, "name")}</OrgName>
                    </OrgMenuItem>
                  );
                })}
              </OrgList>
            </OrgMenu>
          </PopupKit>
        </WrapOrgMenu>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.info,
  organizations: state.organizationsState.organizations,
  permissions: state.permissionsState.permissions
});

const mapDispatchToProps = {
  updateUserInfoAction
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withWidth(OrgSwitcher));
