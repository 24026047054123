import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { COLOR, FONT_FAMILY, FONT_WEIGHT } from "../../../../constants/theme";
import { InputKitSD, LabelKitSD } from "../styled";
import { addUnit } from "../../../../helpers/common";

export const FormFieldKitSD = styled.div`
  width: ${({ fullWidth, totalWidth }) =>
    fullWidth ? "100%" : `${totalWidth}px`};
  margin-bottom: ${({ gutterBottom }) => addUnit(gutterBottom)};

  ${LabelKitSD} {
    display: block;
    margin: 0 0 7px 0;

    ${({ hasError }) =>
      hasError &&
      css`
        color: ${COLOR.CHERRY_RED};
      `};
  }
`;
FormFieldKitSD.propTypes = {
  gutterBottom: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
    PropTypes.string
  ]).isRequired,
  hasError: PropTypes.bool
};

export const FormFieldLabelFieldGroupKitSD = styled.div`
  ${props => {
    const { horizontalLabel, totalWidth, horizontalLabelWidth } = props;

    if (!horizontalLabel) return "";

    return css`
      @media screen and (min-width: ${totalWidth + 20}px) {
        display: flex;
        align-items: center;

        ${LabelKitSD} {
          flex: 0 0 ${horizontalLabelWidth}px;
          margin: 0 0 3px;
          padding-right: 10px;
          text-align: right;
        }

        ${FieldWrapperKitSD} {
          flex: 1 0 0;
          min-width: 0;
        }
      }
    `;
  }}
`;

export const FieldWrapperKitSD = styled.div`
  position: relative;

  ${InputKitSD} {
    width: 100%;
    ${_getInputPaddingForIcon};
  }

  > * {
    color: ${COLOR.TEXT};
  }

  button {
    background-color: ${COLOR.BG_INPUT};
    border: 1px solid ${COLOR.BG_PRIMARY};
    border-radius: 2px;
    height: 40px;
    color: ${COLOR.TEXT};
  }

  ul {
    border: 0;
    background-color: ${COLOR.BG_INPUT};
    > * {
      background-color: ${COLOR.BG_INPUT};
    }
    input {
      background-color: ${COLOR.BG_SECONDARY};
      color: ${COLOR.TEXT};
      border: 0;
      &:placeholder {
        color: ${COLOR.GREY};
      }
    }
    li {
      &:hover {
        background-color: ${COLOR.BG_SECONDARY};
      }
    }
  }
`;
FieldWrapperKitSD.propTypes = {
  hasIcon: PropTypes.bool
};

export const FormFieldAdditionalKitSD = styled.p`
  margin: 4px 5px 0 0;

  color: ${COLOR.GREY};
  font: ${FONT_WEIGHT.MEDIUM} 12px / 1.1 ${FONT_FAMILY.THIN};
  letter-spacing: 0.3px;
`;

export const FormFieldErrorKitSD = styled(FormFieldAdditionalKitSD)`
  color: ${COLOR.CHERRY_RED};
`;

function _getInputPaddingForIcon(props) {
  const { hasIcon, iconAlign } = props;

  if (!hasIcon) return "";

  return iconAlign === "left" ? "padding-left: 40px;" : "padding-right: 40px;";
}

export const AdditionalTextLinkSD = styled.span`
  display: inline-block;
  color: ${COLOR.TEXT_LINK};
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
`;

export const FormFieldWrapMessagesSD = styled.div`
  margin-left: ${({ ml }) => (!!ml ? addUnit(ml) : 0)};
`;
