export const TOURNAMENT_ACTIONS = {
  fetch: {
    request: "TOURNAMENT/FETCH_REQUEST",
    success: "TOURNAMENT/FETCH_SUCCESS",
    error: "TOURNAMENT/FETCH_ERROR"
  },
  refresh: {
    request: "TOURNAMENT/REFRESH_REQUEST",
    success: "TOURNAMENT/REFRESH_SUCCESS",
    error: "TOURNAMENT/REFRESH_ERROR"
  },
  list: {
    request: "TOURNAMENT/LIST_REQUEST",
    success: "TOURNAMENT/LIST_SUCCESS",
    error: "TOURNAMENT/LIST_ERROR"
  },
  players: {
    request: "TOURNAMENT/USERS_REQUEST",
    success: "TOURNAMENT/USERS_SUCCESS",
    error: "TOURNAMENT/USERS_ERROR"
  },
  template: {
    request: "TOURNAMENT/TEMPLATE_REQUEST",
    success: "TOURNAMENT/TEMPLATE_SUCCESS",
    error: "TOURNAMENT/TEMPLATE_ERROR"
  },
  templates: {
    request: "TOURNAMENT/TEMPLATES_REQUEST",
    success: "TOURNAMENT/TEMPLATES_SUCCESS",
    error: "TOURNAMENT/TEMPLATES_ERROR"
  },
  reset: "TOURNAMENT/RESET_STATE"
};
