import calendar from "../../../../static/icons/calendar.svg";
import otf from "../../../../static/icons/on-the-fly.svg";

export const TYPE_OPTIONS = [
  {
    key: "scheduled",
    icon: calendar
  },
  {
    key: "on-the-fly",
    icon: otf
  }
];
