import { ethers } from "ethers";
import { useEffect, useState } from "react";
import axios from "axios";
import Web3Modal from "web3modal";
import {
  Container,
  Header,
  Content,
  Card,
  WrapImage,
  Image,
  Info,
  Title,
  Subtitle,
  Heading,
  Price,
  PriceIcon,
  PriceText
} from "./styled";

import Market from "../../../../artifacts/contracts/Market.sol/NFTMarket.json";
import NFT from "../../../../artifacts/contracts/NFT.sol/NFT.json";
import sgb from "../../../../static/icons/sgb.webp";

export default function CreatorDashboard() {
  const [nfts, setNfts] = useState([]);
  const [sold, setSold] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    loadNFTs();
  }, []);
  async function loadNFTs() {
    const web3Modal = new Web3Modal({
      network: "mainnet",
      cacheProvider: true
    });
    const connection = await web3Modal.connect();
    const provider = new ethers.providers.Web3Provider(connection);
    const signer = provider.getSigner();

    const marketContract = new ethers.Contract(
      process.env.REACT_APP_NFT_MARKET_ADDRESS,
      Market.abi,
      signer
    );
    const tokenContract = new ethers.Contract(
      process.env.REACT_APP_NFT_ADDRESS,
      NFT.abi,
      provider
    );
    const data = await marketContract.fetchItemsCreated();

    const items = await Promise.all(
      data.map(async i => {
        const tokenUri = await tokenContract.tokenURI(i.tokenId);
        const meta = await axios.get(tokenUri);
        let price = ethers.utils.formatUnits(i.price.toString(), "ether");
        let item = {
          price,
          tokenId: i.tokenId.toNumber(),
          seller: i.seller,
          owner: i.owner,
          sold: i.sold,
          image: meta.data.image,
          name: meta.data.name,
          description: meta.data.description
        };
        return item;
      })
    );
    /* create a filtered array of items that have been sold */
    const soldItems = items.filter(i => i.sold);
    setSold(soldItems);
    setNfts(items);
    setLoading(false);
  }
  if (!loading && !nfts.length) return <Header>No assets created</Header>;
  return (
    <>
      <Container>
        <Header>Items Created</Header>
        <Content>
          {nfts.map((nft, i) => (
            <Card>
              <WrapImage>
                <Image src={nft.image} alt="NFT" />
              </WrapImage>
              <Info>
                <Heading>
                  <Title>{nft.name}</Title>
                  <Price>
                    <PriceIcon src={sgb} />{" "}
                    <PriceText>{nft.price} SGB</PriceText>
                  </Price>
                </Heading>
                <Subtitle>{nft.description}</Subtitle>
              </Info>
            </Card>
          ))}
        </Content>
      </Container>
      <Container>
        {Boolean(sold.length) && (
          <>
            <Header>Items sold</Header>
            <Content>
              {sold.map((nft, i) => (
                <Card>
                  <WrapImage>
                    <Image src={nft.image} alt="NFT" />
                  </WrapImage>
                  <Info>
                    <Heading>
                      <Title>{nft.name}</Title>
                      <Price>
                        <PriceIcon src={sgb} />{" "}
                        <PriceText>{nft.price} SGB</PriceText>
                      </Price>
                    </Heading>
                    <Subtitle>{nft.description}</Subtitle>
                  </Info>
                </Card>
              ))}
            </Content>
          </>
        )}
      </Container>
    </>
  );
}
