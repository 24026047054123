import React, { Component } from "react";
import { connect } from "react-redux";
// import { RouteHomeSD } from "./styled";
import { withRouter, Redirect } from "react-router-dom";

class RouteHome extends Component {
  LandingPage = () => {
    return (
      <>
        <div>test</div>
      </>
    );
  };

  render() {
    return <Redirect to={"/login"} />;
    // return <RouteHomeSD>{this.LandingPage()}</RouteHomeSD>;
  }
}

const mapStateToProps = state => ({});
const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RouteHome));
