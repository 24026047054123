import React, { Component } from "react";
import DialogKit from "../../../../../../components/kit/Dialog/DialogKit";
import ButtonKit from "../../../../../../components/kit/Button/ButtonKit";
import { DialogButtons, Text, Success } from "./styled";
import { api } from "../../../../../..";
import { propValueOr } from "../../../../../../helpers/common";
import Loader from "../../../../../../components/presentation/Loader/Loader";
import iconCheck from "../../../../../../static/icons/circle-check-green.svg";
import iconX from "../../../../../../static/icons/circle-x-red.svg";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import FormFieldKit from "../../../../../../components/kit/Fields/FormField/FormField";
import { updateUserInfoAction } from "../../../../../../store/user/actions";

class OrganizationDialog extends Component {
  constructor(props) {
    super();

    this.state = {
      success: false,
      confirm: false,
      organization: null,
      list: [],
      error: null,
      isFetching: false,
      loading: false
    };
  }

  componentDidMount() {
    this.getOrganizations();
  }

  getOrganizations = () => {
    this.setState({ isFetching: true });
    api.admin.organizations
      .list()
      .then(resp =>
        this.setState({
          list: propValueOr(resp, "data", [])
            .filter(o => o.id !== this.props.currentOrganization)
            .map(o => ({
              label: o.name,
              value: o.id
            }))
        })
      )
      .catch(e =>
        this.setState({ error: propValueOr(e, "message", "Unknown error.") })
      )
      .finally(() => this.setState({ isFetching: false }));
  };

  changeOrg = () => {
    const { organization } = this.state;
    const { tournament, history } = this.props;
    this.setState({ loading: true });

    api.admin.tournament
      .update(tournament.id, { organizationId: organization })
      .then(resp => {
        window.setTimeout(() => {
          this.handleClose();
          history.push("/tournaments/manage");
        }, 5000);
      })
      .catch(err => {
        console.log("Update failed", err);
        this.setState({
          error: propValueOr(
            err,
            "response.message",
            typeof err === "object" ? JSON.stringify(err) : err
          )
        });
      })
      .finally(() => {
        this.setState({ loading: false, success: true });
      });
  };

  handleClose = () => {
    this.setState({
      success: false,
      confirm: false,
      organization: null,
      error: null,
      isFetching: false,
      loading: false
    });
    this.props.onClose();
  };

  render() {
    const {
      confirm,
      organization,
      list,
      isFetching,
      success,
      loading,
      error
    } = this.state;
    const { tournament, isOpen } = this.props;

    return (
      <DialogKit
        title={
          success
            ? error
              ? "Failed"
              : "Success!"
            : confirm
            ? "Are You Sure?"
            : "Change Organization"
        }
        renderBody={() =>
          isFetching ? (
            <Loader isBlock />
          ) : success ? (
            <Success>
              <img
                src={error ? iconX : iconCheck}
                width={65}
                height={65}
                alt="check"
              />
              <Text>
                {(error && " Please try again later.") ||
                  "Success! You now must switch organizations to find the new tournament location."}
              </Text>
            </Success>
          ) : confirm ? (
            <Text>{`Changing the tournament's organization will remove it from ${propValueOr(
              tournament,
              "organization.name"
            )} and move it to ${propValueOr(
              list.find(o => organization === o.value),
              "label",
              "the selected org"
            )}.`}</Text>
          ) : (
            <FormFieldKit
              select
              selectOptions={list}
              label={"Select a new organization for the tournament: "}
              description={`Current Organization: ${propValueOr(
                tournament,
                "organization.name"
              )}`}
              fullWidth
              placeholder={"Select new org..."}
              value={organization}
              onChange={val => this.setState({ organization: val })}
            />
          )
        }
        displayButtonClose
        onClose={this.handleClose}
        isOpen={isOpen}
        renderFooter={() =>
          !success && (
            <DialogButtons>
              <ButtonKit
                fullWidth
                preloader={loading}
                onClick={
                  success
                    ? this.handleClose
                    : confirm
                    ? this.changeOrg
                    : () => this.setState({ confirm: true })
                }
                disabled={!success && !confirm && !organization}
              >
                {success ? "Okay" : confirm ? "Confirm" : "Change Organization"}
              </ButtonKit>
              <ButtonKit
                fullWidth
                appearance={"secondary"}
                onClick={this.handleClose}
                color={"red"}
              >
                Cancel
              </ButtonKit>
            </DialogButtons>
          )
        }
      />
    );
  }
}

const mapStateToProps = state => ({
  currentOrganization: propValueOr(state, "user.info.organizationId"),
  tournament: state.tournamentState.tournament
});

const mapDispatchToProps = {
  updateUserInfoAction
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(OrganizationDialog));
