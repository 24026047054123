import styled, { css } from "styled-components";
import { COLOR, FONT_FAMILY, FONT_WEIGHT } from "../../constants/theme";
import { Link } from "react-router-dom";
import breakpoints from "../../helpers/breakpoints";
import PropTypes from "prop-types";

export const Container = styled.div`
  min-height: 100%;
  background-color: ${COLOR.BG_PRIMARY};
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  padding-bottom: 20px;
  user-select: text;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  padding: 30px;
  padding-bottom: 10px;
  border-radius: 16px;

  background-color: ${COLOR.BG_SECONDARY};
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  > :not(:last-child) {
    border-bottom: 1px solid ${COLOR.BORDER};
  }
`;

export const Item = styled(Link)`
  display: flex;
  flex-direction: column;

  padding: 10px 0px;

  color: ${COLOR.TEXT};
  text-decoration: none;

  &:hover {
    opacity: 0.4;
  }
`;

export const Header = styled.div`
  display: flex;

  ${breakpoints.down("md")} {
    flex-wrap: wrap;
  }
`;

export const SearchField = styled.div`
  display: flex;
  width: 100%;
  margin: 20px 0;
  user-select: none;
`;

export const Info = styled.div`
  display: flex;
  align-items: center;
  flex: 2;
`;

export const PlayerProfile = styled.div`
  display: flex;
  align-items: center;

  padding: 10px 0px;
`;

export const ProfilePic = styled.img`
  width: 20px;
  height: 20px;

  margin-right: 10px;
  user-select: none;
`;

export const PlayerName = styled.div`
  font: ${FONT_WEIGHT.NORMAL} 14px ${FONT_FAMILY.THIN};
`;

export const Status = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;

  ${breakpoints.down("sm")} {
    display: none;
  }
`;

export const Badge = styled.div`
  display: flex;
  justify-content: center;
  min-width: 85px;
  height: 20px;

  padding: 3px 10px;
  margin-right: 10px;
  border-radius: 12px 12px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  font: ${FONT_WEIGHT.NORMAL} 13px ${FONT_FAMILY.GOTHIC};
  text-transform: uppercase;

  background-color: ${({ status }) => {
    switch (status) {
      case "initialized":
        return COLOR.BLUE;
      case "playing":
        return COLOR.GREEN;
      case "pending":
        return COLOR.YELLOW;
      case "challenged":
        return COLOR.RED;
      case "complete":
        return COLOR.GREY;
      default:
        return COLOR.BG_INPUT;
    }
  }};

  ${breakpoints.down("md")} {
    display: ${props => (!props.status ? "none" : "flex")};
  }
`;

export const OpenIndicator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${COLOR.GREEN};
  margin-right: 10px;
  ${({ status }) => {
    switch (status) {
      case "pending":
        return `background-color: ${COLOR.YELLOW};`;
      case "closed":
        return "visibility: hidden;";
      default:
        return "";
    }
  }}
`;

export const Message = styled.div`
  display: flex;
  width: 100%;

  padding: 5px 0px;

  font: ${FONT_WEIGHT.NORMAL} 12px ${FONT_FAMILY.THIN};
`;

export const FilterHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  width: 100%;
  background-color: ${COLOR.BG_SECONDARY};

  padding: 10px 0px;
`;

export const Filter = styled.div`
  display: flex;
  justify-content: flex-end;
  user-select: none;

  color: ${COLOR.TEXT};
  overflow: hidden;

  margin-right: 20px;

  border: 2px solid ${COLOR.RIVAL_RED};
  border-radius: 8px;

  ${breakpoints.down("md")} {
    margin-right: 0px;
    margin-bottom: 20px;
  }
`;

const ACTIVE_SWITCHER_ITEM_STYLES = css`
  background-color: ${COLOR.RIVAL_RED};
  color: ${COLOR.WHITE};
`;

const NOT_ACTIVE_SWITCHER_ITEM_STYLES = css`
  background-color: ${COLOR.BG_SECONDARY};
  color: ${COLOR.WHITE};
`;

export const FilterItem = styled.div`
  width: 180px;
  padding: 12px 10px;

  font: ${FONT_WEIGHT.EXTRA_BOLD} 14px ${FONT_FAMILY.GOTHIC_BOLD};
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;

  cursor: pointer;
  transition: all 0.3s;

  ${_IS_ACTIVE_SWITCHER_ITEM_};

  &:hover {
    ${ACTIVE_SWITCHER_ITEM_STYLES};
  }

  &:not(:first-child) {
    margin-left: -1px;
  }

  ${breakpoints.down("xs")} {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 4px;
    padding-right: 4px;
  }

  ${breakpoints.down("md")} {
    width: 150px;
  }

  ${breakpoints.down("440")} {
    min-width: 0;
    font-size: 12px;
  }
`;

FilterItem.propTypes = {
  isActive: PropTypes.bool
};

function _IS_ACTIVE_SWITCHER_ITEM_({ isActive }) {
  return isActive
    ? ACTIVE_SWITCHER_ITEM_STYLES
    : NOT_ACTIVE_SWITCHER_ITEM_STYLES;
}

export const Players = styled.div`
  display: flex;
  align-items: center;
`;

export const Player = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
  min-width: 150px;
`;

export const Name = styled.div`
  font: ${FONT_WEIGHT.NORMAL} 13px ${FONT_FAMILY.GOTHIC};
`;

export const Avatar = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 2px;
  padding: 1px;
  background-color: ${COLOR.FG_PRIMARY};
  margin: 0 5px;
  user-select: none;
`;

export const Dot = styled.div`
  width: 9px;
  height: 9px;
  margin-right: 5px;
  margin-left: 2px;
  border-radius: 50%;
  background-color: ${({ status }) =>
    status === "SUBMITTED" || status === "NOT READY" || status === "COMPLETE"
      ? COLOR.ROUGE
      : status === "PENDING" || status === "CHALLENGED"
      ? COLOR.YELLOW_2
      : COLOR.GREEN};
`;

export const Icon = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 2px;
  margin: 0 5px;
  user-select: none;
`;
