import imageCompression from "browser-image-compression";
import { pick } from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { api } from "../../..";
import ButtonKit from "../../../components/kit/Button/ButtonKit";
import DialogKit from "../../../components/kit/Dialog/DialogKit";
import FormFieldKit from "../../../components/kit/Fields/FormField/FormField";
// import Asset from '../../assets/Asset/Asset';
import AssetsHub from "./AssetsHub";
import HubBar from "../../../components/presentation/HubBar/HubBar";
import { COLOR } from "../../../constants/theme";
import { propValueOr } from "../../../helpers/common";
import withWidth from "../../../hoc/withWidth";
import { fetchOrganizationsAction } from "../../../store/organizations/actions";
import { fetchAssetsAction } from "../../../store/assets/actions";
import { Container, Content, Text, Title, Tabs, Tab, Card } from "./styled";
import Uploader from "../Uploader/Uploader";

class OrganizationGroup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      slug: "",
      icon: null,
      confirm: false,
      creating: false,
      success: false,
      updated: false,
      errors: {},
      tab: 0
    };
  }

  componentDidMount() {
    if (this.props.match?.params?.slug) {
      const hub = this.props.organization.groups.find(
        group => group.slug === this.props.match?.params?.slug
      );
      if (hub) {
        const hubData = pick(hub || {}, [
          "name",
          "organizationId",
          "slug",
          "imageInfo"
        ]);
        if (hubData?.imageInfo?.icon) {
          hubData.icon = hubData.imageInfo.icon;
          delete hubData.imageInfo;
        }
        this.props.fetchAssetsAction({
          organizationId: this.props.organizationId,
          organizationGroupId: hub.id
        });
        this.setState({ updated: true, ...hubData });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (!prevProps.organizationId &&
        !!this.props.organizationId &&
        this.props.organizations?.length > 0) ||
      (!!this.props.organizationId &&
        prevProps.organizations?.length > 0 &&
        this.props.organizations?.length > 0 &&
        !this.state.updated) ||
      this.props.match?.params?.slug !== prevProps.match?.params?.slug
    ) {
      const hub = this.props.organization.groups.find(
        group => group.slug === this.props.match?.params?.slug
      );
      if (hub) {
        const hubData = pick(hub || {}, [
          "name",
          "organizationId",
          "slug",
          "imageInfo"
        ]);
        if (hubData?.imageInfo?.icon) {
          hubData.icon = hubData.imageInfo.icon;
          delete hubData.imageInfo;
        }
        this.props.fetchAssetsAction({
          organizationId: this.props.organizationId,
          organizationGroupId: hub.id
        });
        this.setState({ updated: true, ...hubData });
      }
    }
  }

  handleCreate = () => {
    const { organization } = this.props;
    const { name, slug, icon } = this.state;

    let body = {
      name,
      slug,
      organizationId: organization?.id,
      ...(icon instanceof File && {
        icon
      })
    };

    let data = new FormData();
    for (let key in body) {
      data.append(key, body[key]);
    }
    this.setState({ creating: true });
    api.admin.organizations.groups
      .create(organization?.id, data)
      .then(resp => {
        this.props.fetchOrganizationsAction();
        this.setState({
          name: "",
          slug: "",
          icon: null,
          confirm: false,
          success: true
        });
      })
      .catch(err => {
        this.setState({
          error:
            err?.response?.data?.errors?.[0]?.message ||
            propValueOr(
              err,
              "response.data.message",
              typeof err === "string" ? err : JSON.stringify(err)
            )
        });
      })
      .finally(() => this.setState({ creating: false }));
  };

  handleUpdate = () => {
    const { organization, organizationId, match } = this.props;
    const { name, slug, icon } = this.state;

    const hub = organization?.groups?.find(g => g.slug === match?.params?.slug);

    let body = {
      ...(name !== hub?.name && !!name && { name }),
      ...(slug !== hub?.slug && !!slug && { slug }),
      ...(icon instanceof File && {
        icon
      })
    };

    let data = new FormData();
    for (let key in body) {
      data.append(key, body[key]);
    }

    this.setState({ creating: true });
    api.admin.organizations.groups
      .update(organizationId, hub?.id, data)
      .then(resp => {
        this.props.fetchOrganizationsAction();
        this.props.history.push(
          `/organizations/groups/${slug !== hub?.slug ? slug : hub?.slug}`
        );
        this.setState({
          confirm: false,
          success: true
        });
      })
      .catch(err => {
        this.setState({
          error:
            err?.response?.data?.errors?.[0]?.message ||
            propValueOr(
              err,
              "response.data.message",
              typeof err === "string" ? err : JSON.stringify(err)
            )
        });
      })
      .finally(() => this.setState({ creating: false }));
  };

  canSubmit = () => {
    const { organization, organizationId } = this.props;
    const {
      name,
      slug,
      icon,
      affiliationId,
      accessCode,
      postToDiscord,
      errors
    } = this.state;
    return !!organizationId
      ? icon instanceof File ||
          (name !== organization?.name && !!name) ||
          (slug !== organization?.slug && !!slug) ||
          (affiliationId !== organization?.affiliationId && !!affiliationId) ||
          accessCode !== organization?.accessCode ||
          postToDiscord !== organization?.postToDiscord
      : name &&
          slug &&
          affiliationId &&
          icon &&
          Object.values(errors).some(v => !v);
  };

  renderConfirmDialog = () => {
    const { match, organization } = this.props;
    const urlSlug = match?.params?.slug;

    const hub = organization?.groups?.find(g => g.slug === urlSlug);

    const isUpdate = !!urlSlug && !!hub;

    return (
      <DialogKit
        isOpen={this.state.confirm}
        onClose={() => this.setState({ confirm: false })}
        title={"Are You Sure?"}
        renderBody={() => (
          <Text>
            Are you sure you want to {isUpdate ? "update" : "create"} this
            organization hub?
            <br />
            <br />
            Please verify the provided information is correct. This action
            cannot be undone.
          </Text>
        )}
        renderFooter={() => (
          <>
            {this.state.error && (
              <Text style={{ color: COLOR.CHERRY_RED }}>
                {this.state.error}
              </Text>
            )}
            <ButtonKit
              shape={"rounded"}
              color={"rival_red"}
              fullWidth
              onClick={isUpdate ? this.handleUpdate : this.handleCreate}
              preloader={this.state.creating}
            >
              {isUpdate ? "Update Hub " : "Create Hub"}
            </ButtonKit>
          </>
        )}
      />
    );
  };

  renderSuccessDialog = () => {
    return (
      <DialogKit
        isOpen={this.state.success}
        onClose={() => this.setState({ success: false })}
        title={"Success!"}
        renderBody={() => (
          <Text>
            The hub has been{" "}
            {!!this.props.organizationId ? "updated" : "created"}.
          </Text>
        )}
        renderFooter={() => (
          <ButtonKit
            shape={"rounded"}
            color={"rival_red"}
            fullWidth
            onClick={() => this.setState({ success: false })}
          >
            Okay
          </ButtonKit>
        )}
      />
    );
  };

  render() {
    const { tab, name, icon, slug, errors } = this.state;
    const { match, organization } = this.props;
    const urlSlug = match?.params?.slug;
    const hub = organization?.groups?.find(g => g.slug === urlSlug);

    const isUpdate = !!urlSlug && !!hub;
    /*
    const affSelectOptions = (affiliations || []).map(a => ({
      label: a.name,
      value: a.affiliationId
    }));

    const isAffiliation = isUpdate && organization?.type === "affiliation";
    */
    return (
      <Container>
        <HubBar organization={organization} loading={false} slug={urlSlug} />
        <Title>
          {isUpdate ? "Update" : "Create"} Hub{!!hub ? `: ${hub?.name}` : ""}
        </Title>
        <Text>
          {isUpdate
            ? `Update information for ${organization?.name} below. To create a new organization, please change to "All Organizations" in the organization selector.`
            : `Enter information for the new organization below. To update an organization, please switch to the desired one in the organization selector.`}
        </Text>
        <Content>
          {isUpdate && (
            <Tabs>
              <Tab onClick={() => this.setState({ tab: 0 })} active={tab === 0}>
                Info
              </Tab>
              <Tab onClick={() => this.setState({ tab: 1 })} active={tab === 1}>
                Assets
              </Tab>
            </Tabs>
          )}
          <Card>
            {this.state.tab === 0 && (
              <>
                <FormFieldKit
                  fullWidth
                  label="Name"
                  value={name}
                  onChange={val =>
                    this.setState({
                      name: val,
                      errors: { ...errors, name: null }
                    })
                  }
                  inputProps={{ maxLength: 50 }}
                  placeholder={"Enter a name..."}
                  required
                  onBlur={() =>
                    this.setState({
                      errors: {
                        ...this.state.errors,
                        name:
                          !name || !(name?.length > 0)
                            ? "Please enter a valid name."
                            : null
                      }
                    })
                  }
                  errorText={errors.name}
                />
                <FormFieldKit
                  fullWidth
                  label="Slug"
                  value={slug}
                  onChange={val => {
                    const newVal = (val?.toLowerCase() || "")
                      .replace(/\s/g, "-")
                      .replace(/[^a-z0-9-]/g, "");
                    this.setState({ slug: newVal });
                  }}
                  inputProps={{ maxLength: 50 }}
                  placeholder={"Enter a slug..."}
                  description={
                    isUpdate
                      ? "This is the URL for the community. (i.e. https://rivalgames.com/<SLUG_GOES_HERE>)"
                      : "This is the URL that the new community will live under. (i.e. https://rivalgames.com/<SLUG_GOES_HERE>)"
                  }
                  required
                  onBlur={() =>
                    this.setState({
                      errors: {
                        ...this.state.errors,
                        name:
                          !name || !(name?.length > 0)
                            ? "Please enter a valid name."
                            : null
                      }
                    })
                  }
                  errorText={errors.slug}
                />
                <Uploader
                  label="Community Logo"
                  description={
                    "This will show in the community selector or under the 'Find A Community' list on the landing page. **Note: looks best with an aspect ratio of 1:1"
                  }
                  onChange={async file => {
                    let compressedImage = await imageCompression(file, {
                      maxSizeMB: 0.2,
                      useWebWorker: true
                    });
                    this.setState({
                      icon: new File([compressedImage], "icon", {
                        type: file.type?.includes["image"]
                          ? file.type.replace("image/", ".")
                          : ".png"
                      })
                    });
                  }}
                  constraints={{ width: [25, 220], height: [25, 220] }}
                  imgLabels={[]}
                  imageDimensions={[100, 100]}
                  defaultImage={icon}
                  onReset={() => this.setState({ icon: null })}
                  name={"icon"}
                />
                <ButtonKit
                  style={{ marginTop: "15px" }}
                  disabled={!this.canSubmit()}
                  shape={"rounded"}
                  color={"rival_red"}
                  fullWidth
                  appearance={"secondary"}
                  onClick={() => this.setState({ confirm: true })}
                >
                  {isUpdate ? "Update" : "Create"}
                </ButtonKit>
              </>
            )}
            {this.state.tab === 1 && (
              <AssetsHub organizationGroupId={hub?.id} groupName={hub?.name} />
            )}
          </Card>
        </Content>
        {this.renderConfirmDialog()}
        {this.renderSuccessDialog()}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  organization: (state.organizationsState?.organizations || []).find(
    o => o.id === state.user?.info?.organizationId
  ),
  organizationId: state.user?.info?.organizationId,
  affiliations: state.organizationsState?.affiliations,
  organizations: state.organizationsState?.organizations,
  creating: state.organizationsState?.creating
});
const mapDispatchToProps = {
  fetchOrganizationsAction,
  fetchAssetsAction
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withWidth(OrganizationGroup)));
