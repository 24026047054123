import React, { Component } from "react";
import { connect } from "react-redux";
import RouteTournament from "./RouteTournament";
import { withRouter } from "react-router-dom";
import { getGlobalUser } from "../../store/user/selectors";
import PermissionDenied from "../denied/PermissionDenied";
import { fetchConsolesAction } from "../../store/consoles/actions";

class RouteTournamentContainer extends Component {
  componentDidMount() {
    this.props.fetchConsolesAction();
  }

  render() {
    const { permission } = this.props;
    if (!permission) {
      return <PermissionDenied />;
    }
    return <RouteTournament />;
  }
}

const mapStateProps = state => ({
  user: getGlobalUser(state)
});

const mapDispatchToProps = {
  fetchConsolesAction
};

export default connect(
  mapStateProps,
  mapDispatchToProps
)(withRouter(RouteTournamentContainer));
