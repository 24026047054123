import React, { Component } from "react";
import {
  Container,
  Header,
  HeaderInfo,
  HeaderButtons,
  CollapseButton,
  CloseButton,
  ChatContent,
  Info,
  Icon,
  Players,
  Player,
  Avatar,
  Name,
  WrapIcons
} from "./styled";
import Chat from "../Chat/Chat";
import { withRouter } from "react-router-dom";

// Icons
import CloseIcon from "../../../static/icons/icon-close.svg";
import UpArrowIcon from "../../../static/icons/chevron-arrow-up.svg";
import DownArrowIcon from "../../../static/icons/chevron-arrow-down-2.svg";
import { propValueOr, getUsername } from "../../../helpers/common";
import crossPlatform from "../../../static/icons/cross-platform.svg";
import placeholderUserImg from "../../../static/images/default-user.jpg";
class ChatWindow extends Component {
  onExpand(token) {
    const chatURL = document.location.origin + `/chat/${token}`;
    window.open(chatURL, "_blank", "height=600,width=600");
  }

  render() {
    const { channel, game, tournament, openWindow } = this.props;

    return (
      <Container>
        <Header onClick={() => this.props.toggleCollapse()}>
          <HeaderInfo>
            {game ? (
              <Info>
                <WrapIcons to={`/game/${game.token}`}>
                  <Icon src={propValueOr(game, "Game.imageInfo.icon", null)} />
                  <Icon
                    src={propValueOr(
                      game,
                      "console.imageInfo.icon",
                      crossPlatform
                    )}
                  />
                </WrapIcons>
                <Players>
                  {game.users.map((user, i) => (
                    <>
                      <Player>
                        <Avatar
                          src={propValueOr(
                            user,
                            "imageInfo.thumbnail",
                            placeholderUserImg
                          )}
                          onError={e => (e.target.src = placeholderUserImg)}
                        />
                        <Name>{getUsername(user)}</Name>
                      </Player>
                    </>
                  ))}
                </Players>
              </Info>
            ) : tournament ? (
              <Info>
                <WrapIcons to={`/tournaments/${tournament.token}`}>
                  <Icon
                    src={propValueOr(tournament, "Game.imageInfo.icon", null)}
                  />
                  <Icon
                    src={propValueOr(
                      tournament,
                      "console.imageInfo.icon",
                      crossPlatform
                    )}
                  />
                </WrapIcons>
                <Name>
                  ID {tournament.id}: {tournament.name}
                </Name>
              </Info>
            ) : (
              <Info>
                <Name>RCC GLOBAL CHAT</Name>
              </Info>
            )}
            <HeaderButtons>
              <CollapseButton
                src={openWindow ? DownArrowIcon : UpArrowIcon}
                onClick={() => this.props.toggleCollapse()}
              />
              <CloseButton
                src={CloseIcon}
                onClick={() => this.props.unpinChannel()}
              />
            </HeaderButtons>
          </HeaderInfo>
        </Header>
        <ChatContent isOpen={openWindow}>
          {game ? (
            <Chat
              key={game.token}
              channel={channel}
              game={game}
              status={game.status}
              onExpand={() => this.onExpand(channel)}
              startedAt={game.createdAt}
              endedAt={game.endedAt}
              height={"100%"}
            />
          ) : tournament ? (
            <Chat
              key={tournament.token}
              channel={channel}
              onExpand={() => this.onExpand(channel)}
              height={"100%"}
            />
          ) : (
            <Chat
              key={"rcc"}
              channel={channel}
              onExpand={() => this.onExpand(channel)}
              height={"100%"}
            />
          )}
        </ChatContent>
      </Container>
    );
  }
}

export default withRouter(ChatWindow);
