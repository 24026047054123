import React from "react";
import {
  InfoContainer,
  Players,
  Player,
  Avatar,
  Dot,
  Name,
  Status,
  Badge
} from "./styled";
import { getUsername, propValueOr } from "../../../../helpers/common";
import { getStatus } from "../constants";

// Icons
import placeholderUserImg from "../../../../static/images/default-user.jpg";

export const MatchItem = ({ match, tournament }) => {
  return (
    <InfoContainer>
      <Players>
        {match.users.map((user, index) => (
          <Player key={user.id} reverse={index}>
            <Avatar
              src={propValueOr(user, "imageInfo.thumbnail", placeholderUserImg)}
              onError={e => (e.target.src = placeholderUserImg)}
            />
            {match.status === "initialized" && (
              <Dot status={getStatus(user, match)} />
            )}
            <Name>{getUsername(user)}</Name>
          </Player>
        ))}
      </Players>
      <Status>
        {tournament && <Badge>{tournament.name}</Badge>}
        {tournament && <Badge>{`ID ${tournament.id}`}</Badge>}
      </Status>
    </InfoContainer>
  );
};

export default MatchItem;
