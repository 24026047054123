import logoWhite from "../static/images/logos/Rival-Logo-White.svg";

/* Configuration Objects for each client / whitelabel / affiliation */

// Default/Rival Classic config
export const defaultConfig = {
  hideSchools: false,
  xbox: true,
  playstation: true,
  openPlay: false,
  playTournaments: true,
  inviteToPlay: true,
  featuredTournaments: true,
  activeTournaments: true,
  giveaways: false,
  showAffiliations: true,
  transparentHeader: true,
  logoInfo: {
    logoHeight: "15px",
    logoWidth: "127px",
    logo: logoWhite
  },
  intro: {
    video: {
      link: "https://www.youtube.com/embed/5Qn5O8LwElc?autoplay=1"
    }
  },
  login: {
    logoHeight: "23px",
    logoWidth: "191.67px"
  }
};
