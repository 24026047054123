import styled from "styled-components";
import { COLOR, FONT_WEIGHT, FONT_FAMILY } from "../../../constants/theme";

export const Content = styled.div`
  min-height: 100%;
  max-width: 1440px;
  width: 100%;
  color: ${COLOR.WHITE};
  background-color: ${COLOR.BG_PRIMARY};
  z-index: 3;
  padding: 20px;
`;
export const Container = styled.div`
  min-height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const TableEmpty = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 19px;
  padding-bottom: 67px;
`;

export const TitleEmpty = styled.div`
  margin-bottom: 20px;
  color: ${COLOR.TEXT_4};
  font-size: 14px;
  font-weight: ${FONT_WEIGHT.MEDIUM};
  line-height: 21px;
  text-align: center;
  text-transform: uppercase;
`;

export const List = styled.div`
  color: ${COLOR.TEXT};
  margin: 10px 0px;
`;

export const SearchBar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  padding: 15px 10px;
`;

export const Text = styled.div`
  display: flex;
  color: ${COLOR.TEXT};
  font: ${FONT_WEIGHT.NORMAL} 14px / 16px ${FONT_FAMILY.THIN};
  text-decoration: none;
  flex-grow: 1;
`;

export const Error = styled.div`
  display: flex;
  width: 100%;
  color: ${COLOR.ROUGE};
  font: ${FONT_WEIGHT.NORMAL} 14px / 16px ${FONT_FAMILY.THIN};
  text-decoration: none;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  padding: 0 10px;
  height: 45px;
  text-decoration: none;
  &:not(:last-child) {
    border-bottom: 1px solid ${COLOR.BORDER};
  }
  background-color: ${COLOR.BG_SECONDARY};
`;

export const Delete = styled.div`
  margin-left: 5px;
  width: 70px;
`;

export const IconWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Icon = styled.img`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const Field = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const WrapField = styled.div`
  width: 300px;
`;

export const WrapIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Badge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 205px;
  height: 20px;

  margin-right: 10px;
  border-radius: 12px 12px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  font: ${FONT_WEIGHT.NORMAL} 13px ${FONT_FAMILY.GOTHIC};
  text-transform: uppercase;

  background-color: ${props =>
    props.global ? COLOR.BLUE : props.role ? COLOR.GREEN : COLOR.BG_THIRD};
`;

export const WrapAvatarRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-radius: 8px;
  margin: 10px 0;
  background-color: ${COLOR.BG_ACCENT};
`;

export const WrapAvatarImage = styled.img`
  width: 50px;
  height: 50px;
  cursor: zoom-in;
`;

export const StatusText = styled.div`
  display: flex;
  color: ${COLOR.TEXT};
  font: ${FONT_WEIGHT.NORMAL} 14px / 1.2 ${FONT_FAMILY.GOTHIC};
  text-transform: uppercase;
`;

export const StatusBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 85px;

  padding: 5px 13px;
  border-radius: 12px 12px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  font: 600 15px ${FONT_FAMILY.CONDENSED};
  text-transform: uppercase;

  background-color: ${({ status }) => {
    switch (status) {
      case "approved":
        return COLOR.GREEN;
      case "pending":
        return COLOR.YELLOW;
      case "rejected":
        return COLOR.ROUGE;
      default:
        return COLOR.GRAY;
    }
  }};
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const WrapButton = styled.div`
  margin-left: 10px;
  width: 100%;
`;

export const FullscreenBg = styled.div`
  background-color: rgba(0, 0, 0, 0.9);
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  top: 0px;
  justify-content: center;
  align-items: center;
  cursor: zoom-out;
  color: #ffffff;
  font-size: 24px;
  z-index: 999;
`;
export const FullscreenImage = styled.img`
  min-width: 30%;
  min-height: 30%;
  max-width: 100%;
  max-height: 80%;
`;

export const Section = styled.div`
  margin: 10px 0;
  padding: 20px;
  background-color: ${COLOR.BG_THIRD};
`;

export const SectionHeader = styled.div`
  width: 100%;
  font: ${FONT_WEIGHT.NORMAL} 20px ${FONT_FAMILY.GOTHIC_BOLD};
  margin-bottom: 10px;
`;

export const Input = styled.input`
  width: 150px;
  height: 18px;
  font: ${FONT_WEIGHT.NORMAL} 14px / 1.2 ${FONT_FAMILY.THIN};
  color: ${COLOR.TEXT};
  border: 1px solid ${COLOR.BORDER};
  background-color: ${COLOR.BG_INPUT};
  border-radius: 4px;
  padding: 12px 5px;
  margin-left: 3px;
  &:disabled {
    opacity: 0.9;
    pointer-events: none;
  }
`;

export const WrapInput = styled.div`
  display: flex;
  font: ${FONT_WEIGHT.NORMAL} 12px / 1.2 ${FONT_FAMILY.THIN};
  color: ${COLOR.GREY};
  align-items: center;
  flex-shrink: 1;
`;
