import React, { Component } from "react";
import {
  DialogContainer,
  DialogHeader,
  DialogTitle,
  PlayerSelection,
  SelectionText,
  PlayerRow,
  PlayerProfile,
  Buttons,
  WrapInputs,
  Success,
  Text
} from "./styled";
import {
  propValueOr,
  getUsername,
  hasOnlyDigitsInString
} from "../../../../../../helpers/common";
import DialogKit from "../../../../../../components/kit/Dialog/DialogKit";
import ButtonKit from "../../../../../../components/kit/Button/ButtonKit";
import placeholderUserImg from "../../../../../../static/images/default-user.jpg";
import FormFieldKit from "../../../../../../components/kit/Fields/FormField/FormField";
import { api } from "../../../../../../index";
import iconCheck from "../../../../../../static/icons/circle-check-green.svg";
import iconX from "../../../../../../static/icons/circle-x-red.svg";

class ChangeWinnerDialog extends Component {
  constructor(props) {
    super();

    this.state = {
      homeScore: "",
      awayScore: "",
      success: false,
      error: ""
    };
  }

  handleSubmitScores = () => {
    const { homeScore, awayScore } = this.state;

    api.admin.matches
      .update(this.props.game.token, {
        score: `${homeScore} - ${awayScore}`
      })
      .then(resp => this.setState({ success: true }))
      .catch(err =>
        this.setState({
          error: propValueOr(
            err,
            "response.data.message",
            err.message || "Unknown error. Please Try again later."
          )
        })
      );
  };

  handleChangeScore = (propName, value) => {
    if (hasOnlyDigitsInString(value) && value.length <= 3) {
      this.setState({ [propName]: value });
    }
  };

  handleClose = () => {
    const { onClose } = this.props;

    this.setState({ homeScore: "", awayScore: "", success: false });
    onClose();
  };

  renderSuccess = () => {
    const { error } = this.state;
    return (
      <DialogContainer>
        <DialogHeader>
          <DialogTitle>
            {error ? "Score Change Failed" : "Success!"}
          </DialogTitle>
        </DialogHeader>
        <Success>
          <img
            src={error ? iconX : iconCheck}
            width={65}
            height={65}
            alt="check"
          />
          <Text>{error || "The scores have been updated."}</Text>
        </Success>
        <Buttons>
          <ButtonKit
            fullWidth
            onClick={() =>
              error
                ? this.setState({
                    error: null,
                    homeScore: "",
                    awayScore: "",
                    success: false
                  })
                : this.handleClose()
            }
            color={error ? "primary" : "green"}
          >
            {error ? "Try Again" : "Okay"}
          </ButtonKit>
        </Buttons>
      </DialogContainer>
    );
  };

  renderDialog = () => {
    const { players } = this.props;
    const { homeScore, awayScore } = this.state;
    let isTeam = false;
    if (players && players.length === 2) {
      if (players[0].team) isTeam = true;
    } else {
      setTimeout(() => this.handleClose(), 3000);
      return (
        <DialogContainer>
          <DialogHeader>
            <DialogTitle>No Data</DialogTitle>
          </DialogHeader>
          <PlayerSelection>
            <SelectionText>Could not load player data.</SelectionText>
          </PlayerSelection>
        </DialogContainer>
      );
    }

    const sortedPlayers = players[0].isHome
      ? [...players]
      : [...players].reverse();

    return (
      <DialogContainer>
        <DialogHeader>
          <DialogTitle>Change Score</DialogTitle>
        </DialogHeader>
        <PlayerSelection>
          <SelectionText>
            Enter the new scores for each {isTeam ? "team" : "player"}
          </SelectionText>
          <WrapInputs>
            <FormFieldKit
              label={
                <PlayerRow>
                  <PlayerProfile
                    src={propValueOr(
                      sortedPlayers[0],
                      "imageInfo.thumbnail",
                      placeholderUserImg
                    )}
                    onError={e => (e.target.src = placeholderUserImg)}
                  />
                  {isTeam
                    ? sortedPlayers[0].team.name
                    : getUsername(sortedPlayers[0])}
                </PlayerRow>
              }
              value={homeScore || ""}
              onChange={val => this.handleChangeScore("homeScore", val)}
              placeholder={"Home score..."}
              type={"number"}
            />
            <FormFieldKit
              label={
                <PlayerRow>
                  <PlayerProfile
                    src={propValueOr(
                      sortedPlayers[1],
                      "imageInfo.thumbnail",
                      placeholderUserImg
                    )}
                    onError={e => (e.target.src = placeholderUserImg)}
                  />
                  {isTeam
                    ? sortedPlayers[1].team.name
                    : getUsername(sortedPlayers[1])}
                </PlayerRow>
              }
              value={awayScore || ""}
              onChange={val => this.handleChangeScore("awayScore", val)}
              placeholder={"Away score..."}
              type={"number"}
            />
          </WrapInputs>
        </PlayerSelection>
        <Buttons>
          <ButtonKit
            onClick={this.handleSubmitScores}
            disabled={!homeScore || !awayScore}
            fullWidth
            color={"red"}
          >
            Submit
          </ButtonKit>
          <ButtonKit
            fullWidth
            appearance={"secondary"}
            onClick={this.handleClose}
          >
            Cancel
          </ButtonKit>
        </Buttons>
      </DialogContainer>
    );
  };

  render() {
    const { isOpen } = this.props;

    return (
      <DialogKit
        isOpen={isOpen}
        onClose={this.handleClose}
        renderCustom={
          this.state.success || this.state.error
            ? this.renderSuccess
            : this.renderDialog
        }
        displayButtonClose={true}
        width={370}
      />
    );
  }
}

export default ChangeWinnerDialog;
