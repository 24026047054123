import React, { Component } from "react";
import PropTypes from "prop-types";
import EmailForm from "../EmailForm/EmailForm";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { ContentBody, ContentHead } from "../styled";
import TypographyKit from "../../../components/kit/Typography/TypographyKit";
import Breadcrumbs from "../../../components/presentation/Breadcrumbs/Breadcrumbs";
import {
  addEmail,
  createTemplatesEmail,
  createGlobalTemplatesEmail,
  deleteEmail
} from "../../../store/communication/actions";
import EmailDialogSuccess from "../../../components/dialogs/EmailDialog/EmailDialogSuccess";
import PermissionDenied from "../../denied/PermissionDenied";
import { verifyPermission } from "../../../helpers/common";

class CommunicationTemplatesCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCreatingTemplate: false,
      isCreatingTemplateDialogOpen: false
    };
  }

  handleCreateTemplate = async template => {
    this.setState({ isCreatingTemplate: true });
    try {
      if (this.props.isGlobalTemplates) {
        await this.props.createGlobalTemplatesEmail(template);
      } else {
        await this.props.createTemplatesEmail(template);
      }
      this.setState({
        isCreatingTemplate: false,
        isCreatingTemplateDialogOpen: true
      });
    } catch (err) {
      this.setState({ isCreatingTemplate: false });
    }
  };

  renderCreatedTemplateDialog = () => {
    const { isCreatingTemplateDialogOpen } = this.state;
    const { history } = this.props;

    return (
      <EmailDialogSuccess
        title={"TEMPLATE CREATED"}
        text={"Your template has been created at:"}
        date={new Date().getTime()}
        isOpen={isCreatingTemplateDialogOpen}
        confirmText={"SEE TEMPLATES"}
        onClose={() => this.setState({ isCreatingTemplateDialogOpen: false })}
        onConfirm={() => history.push("/communication/templates")}
      />
    );
  };

  render() {
    const {
      template,
      handleSetImage,
      addEmail,
      deleteEmail,
      isGlobalTemplates
    } = this.props;

    const { isCreatingTemplate } = this.state;

    const handleCreateTemplate = isGlobalTemplates
      ? null
      : this.handleCreateTemplate;
    const handleCreateGlobalTemplate = isGlobalTemplates
      ? this.handleCreateTemplate
      : null;

    const caption = isGlobalTemplates ? "Global templates" : "Templates";
    const globalTemplateAccessible = verifyPermission(
      "/communication/global_templates",
      this.props.permissions
    );
    if (!globalTemplateAccessible && isGlobalTemplates) {
      return <PermissionDenied />;
    }

    return (
      <>
        <ContentHead>
          <TypographyKit variant={"caption1"}>{caption}</TypographyKit>
        </ContentHead>
        <ContentBody>
          <Breadcrumbs />
          <EmailForm
            type={"template"}
            email={template}
            handleSetImage={handleSetImage}
            handleCreateTemplate={handleCreateTemplate}
            handleCreateGlobalTemplate={handleCreateGlobalTemplate}
            isCreatingTemplate={isCreatingTemplate}
            addEmail={addEmail}
            deleteEmail={deleteEmail}
          />
          {this.renderCreatedTemplateDialog()}
        </ContentBody>
      </>
    );
  }
}

CommunicationTemplatesCreate.propTypes = {
  template: PropTypes.object,
  createTemplatesEmail: PropTypes.func.isRequired,
  addEmail: PropTypes.func.isRequired,
  deleteEmail: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  template: state.communication.email
});

const mapDispatchToProps = {
  createTemplatesEmail,
  createGlobalTemplatesEmail,
  addEmail,
  deleteEmail
};

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(CommunicationTemplatesCreate);
