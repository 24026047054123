import React from "react";
import {
  InfoContainer,
  Players,
  Player,
  Avatar,
  Name,
  Status,
  Badge
} from "./styled";
import moment from "moment";
import { getUsername, propValueOr } from "../../../../helpers/common";

// Icons
import placeholderUserImg from "../../../../static/images/default-user.jpg";

export const ChallengeItem = ({ match, challenge }) => {
  return (
    <InfoContainer>
      <Players>
        {match.users.map((user, index) => (
          <Player key={user.id} reverse={index}>
            <Avatar
              src={propValueOr(user, "imageInfo.thumbnail", placeholderUserImg)}
              onError={e => (e.target.src = placeholderUserImg)}
            />
            <Name>{getUsername(user)}</Name>
          </Player>
        ))}
      </Players>
      <Status>
        {match.status === "complete" && (
          <Badge>
            Moderated by{" "}
            {propValueOr(challenge, "moderator.fullName", "system")}
          </Badge>
        )}
        <Badge status>
          {moment(match.updatedAt).format("MM/DD/YY @ hh:mm A")}
        </Badge>
      </Status>
    </InfoContainer>
  );
};

export default ChallengeItem;
