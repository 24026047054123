import { TOURNAMENT_ACTIONS } from "./constants";

const initialState = {
  tournament: {},
  tournaments: [],
  template: {},
  templates: [],
  page: 0,
  total: 0,
  error: null,
  isFetching: false,
  isRefreshing: false,
  players: [],
  isFetchingPlayers: false
};

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case TOURNAMENT_ACTIONS.fetch.request:
      return {
        ...state,
        error: null,
        isFetching: true
      };
    case TOURNAMENT_ACTIONS.fetch.success:
      return {
        ...state,
        tournament: action.payload,
        isFetching: false
      };
    case TOURNAMENT_ACTIONS.fetch.error:
      return {
        ...state,
        tournament: {},
        error: action.payload,
        isFetching: false
      };
    case TOURNAMENT_ACTIONS.refresh.request:
      return {
        ...state,
        isRefreshing: true,
        error: null
      };
    case TOURNAMENT_ACTIONS.refresh.success:
      return {
        ...state,
        tournament: action.payload,
        isRefreshing: false
      };
    case TOURNAMENT_ACTIONS.refresh.error:
      return {
        ...state,
        error: action.payload,
        isRefreshingFalse: false
      };
    case TOURNAMENT_ACTIONS.list.request:
      return {
        ...state,
        error: null,
        isFetching: true
      };
    case TOURNAMENT_ACTIONS.list.success:
      return {
        ...state,
        tournaments: action.payload.docs,
        page: action.payload.page,
        total: action.payload.pages,
        isFetching: false
      };
    case TOURNAMENT_ACTIONS.list.error:
      return {
        ...state,
        tournaments: [],
        error: action.payload,
        isFetching: false
      };
    case TOURNAMENT_ACTIONS.players.request:
      return {
        ...state,
        error: null,
        isFetchingPlayers: true
      };
    case TOURNAMENT_ACTIONS.players.success:
      return {
        ...state,
        players: action.payload,
        isFetchingPlayers: false
      };
    case TOURNAMENT_ACTIONS.players.error:
      return {
        ...state,
        players: [],
        error: action.payload,
        isFetchingPlayers: false
      };
    case TOURNAMENT_ACTIONS.template.request:
      return {
        ...state,
        error: null,
        isFetching: true
      };
    case TOURNAMENT_ACTIONS.template.success:
      return {
        ...state,
        template: action.payload,
        isFetching: false
      };
    case TOURNAMENT_ACTIONS.template.error:
      return {
        ...state,
        template: {},
        error: action.payload,
        isFetching: false
      };
    case TOURNAMENT_ACTIONS.templates.request:
      return {
        ...state,
        error: null,
        isFetching: true
      };
    case TOURNAMENT_ACTIONS.templates.success:
      return {
        ...state,
        templates: action.payload.docs,
        page: action.payload.page,
        total: action.payload.pages,
        isFetching: false
      };
    case TOURNAMENT_ACTIONS.templates.error:
      return {
        ...state,
        templates: [],
        error: action.payload,
        isFetching: false
      };
    case TOURNAMENT_ACTIONS.reset:
      return initialState;
    default:
      return state;
  }
};

export default reducers;
