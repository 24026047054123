import styled from "styled-components";
import { COLOR, FONT_WEIGHT, FONT_FAMILY } from "../../../constants/theme";

export const Container = styled.div`
  min-height: 100%;
  background-color: ${COLOR.BG_PRIMARY};
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  padding-bottom: 20px;
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;
  height: 100%;
  padding: 0px 30px;
  padding-bottom: 30px;
  border-radius: 15px;

  background-color: ${COLOR.BG_SECONDARY};
  color: ${COLOR.TEXT};
`;

export const Header = styled.div`
  display: flex;
  align-items: center;

  padding: 30px 0px;

  width: 100%;
`;

export const NavBar = styled.div`
  display: flex;
  align-items: center;

  > {
    margin-right: 10px;
  }
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
`;

export const NavItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  font: ${FONT_WEIGHT.NORMAL} 20px ${FONT_FAMILY.GOTHIC_BOLD};
  user-select: text;
`;

export const WrapArrow = styled.div`
  cursor: pointer;
`;

export const ArrowIcon = styled.img`
  width: 30px;
  height: 30px;
`;

export const WrapButton = styled.div`
  margin: 10px 0;
`;
