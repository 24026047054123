import styled from "styled-components";
import { COLOR, FONT_FAMILY, FONT_WEIGHT } from "../../../constants/theme";

export const DialogText = styled.div`
  color: ${COLOR.TEXT};
  font: ${FONT_WEIGHT} 16px ${FONT_FAMILY.THIN};
`;
export const Body = styled.div``;

export const WrapButton = styled.div`
  width: 100%;
  margin-top: 10px;
`;
