import styled from "styled-components";
import { COLOR, FONT_WEIGHT, FONT_FAMILY } from "../../../../constants/theme";
import breakpoints from "../../../../helpers/breakpoints";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 232px;

  ${breakpoints.down("sm")} {
    width: 100%;
  }
`;

export const Title = styled.div`
  width: 64px;
  height: 30px;
  font: ${FONT_WEIGHT.NORMAL} 12px / 30px ${FONT_FAMILY.THIN};
  text-align: center;
  color: ${COLOR.FG_PRIMARY};
  background-color: ${({ isHome }) => (isHome ? COLOR.NAVY_BLUE : COLOR.ROUGE)};
`;

export const WrapAvatar = styled.div``;

export const Avatar = styled.img`
  width: 45px;
  height: 45px;
  background-color: ${COLOR.FG_PRIMARY};
  border-radius: 2px;
  margin: 10px 0;
  padding: 1px;
`;

export const Text = styled.div`
  font: ${FONT_WEIGHT.MEDIUM} 18px / 18px ${FONT_FAMILY.THIN};
  letter-spacing: -0.29px;
  color: ${COLOR.FG_PRIMARY};
  user-select: all;
`;

export const WrapManualScore = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 10px;
  background-color: ${COLOR.BG_SECONDARY};
  padding: 10px;
  border-radius: 12px;
  height: 65px;
`;

export const WrapScore = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
`;

export const ManualScore = styled.div`
  font: ${FONT_WEIGHT.MEDIUM} 14px / 14px ${FONT_FAMILY.GOTHIC};
  letter-spacing: -0.29px;
  color: ${COLOR.FG_PRIMARY};
  user-select: all;
`;

export const Badge = styled.div`
  display: flex;
  justify-content: center;
  min-width: 85px;

  margin-top: 10px;
  padding: 3px 5px;
  border-radius: 12px 12px;
  padding-top: 4px;

  font: ${FONT_WEIGHT.NORMAL} 13px ${FONT_FAMILY.GOTHIC};

  background-color: ${({ status }) =>
    status === "SUBMITTED" || status === "NOT READY"
      ? COLOR.ROUGE
      : status === "PENDING" || status === "CHALLENGED"
      ? COLOR.YELLOW_2
      : status === "COMPLETE"
      ? COLOR.GREY
      : COLOR.GREEN};
`;

export const ScoreButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  > :not(:last-child) {
    margin-right: 10px;
  }
`;

export const ScoreButton = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 50px;

  margin-top: 10px;
  padding: 3px 5px;
  border-radius: 12px 12px;
  padding-top: 4px;

  font: ${FONT_WEIGHT.NORMAL} 13px ${FONT_FAMILY.GOTHIC};

  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

export const EditScore = styled(ScoreButton)`
  background-color: ${COLOR.BLUE};
`;

export const VerifyScore = styled(ScoreButton)`
  background-color: ${COLOR.GREEN};
`;
