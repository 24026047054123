import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import { COLOR, FONT_FAMILY, FONT_WEIGHT } from "../../../constants/theme";

export const NotificationsPaperSD = styled.div`
  border-radius: 4px;
  background: ${COLOR.BG_SECONDARY};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
`;

export const NotificationsPaperTitleSD = styled.div`
  ${({ isFixed }) =>
    isFixed &&
    css`
      position: sticky;
      top: 0;
      z-index: 1;
    `};

  background-color: ${COLOR.BG_SECONDARY};

  padding: 13px 22px;

  color: ${COLOR.TEXT_3};
  font: ${FONT_WEIGHT.MEDIUM} 14px / 1.2 ${FONT_FAMILY.THIN};
  letter-spacing: 0.25px;
`;
NotificationsPaperTitleSD.propTypes = {
  isFixed: PropTypes.bool
};

export const NotificationsPaperBodySD = styled.div``;

export const NotificationsPaperFooterSD = styled.div`
  ${({ isFixed }) =>
    isFixed &&
    css`
      position: sticky;
      bottom: 0;
    `};

  padding: 22px;
  background: ${COLOR.BG_ACCENT};
`;
NotificationsPaperFooterSD.propTypes = {
  isFixed: PropTypes.bool
};
