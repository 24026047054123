import styled from "styled-components";

const Svg = styled.svg`
  display: block;
  fill: currentcolor;
`;

const IconGlobe = props => (
  <Svg {...props} viewBox="0 0 24 24">
    <path d="M9.862 8a.49.49 0 0 1-.488-.572 17.01 17.01 0 0 1 .736-2.864 6.761 6.761 0 0 1 1.09-2.048c.374-.435.65-.516.8-.516.15 0 .424.081.798.516a6.761 6.761 0 0 1 1.09 2.048 17.043 17.043 0 0 1 .736 2.864.49.49 0 0 1-.488.572zm-2.537-.432a.507.507 0 0 1-.5.432h-3.23a.472.472 0 0 1-.43-.688A10.043 10.043 0 0 1 7.677 2.98a.497.497 0 0 1 .641.665c-.03.078-.06.157-.088.236a19.627 19.627 0 0 0-.906 3.688zM16.32 2.98a.496.496 0 0 0-.64.664c.03.078.06.157.087.236a19.627 19.627 0 0 1 .906 3.688.507.507 0 0 0 .5.432h3.23a.472.472 0 0 0 .431-.688 10.043 10.043 0 0 0-4.514-4.333zm-1.36 7.486a.493.493 0 0 0-.496-.466h-4.93a.493.493 0 0 0-.495.466 29.266 29.266 0 0 0 0 3.068.493.493 0 0 0 .495.466h4.93a.493.493 0 0 0 .495-.466 29.321 29.321 0 0 0 0-3.068zM17.47 14a.507.507 0 0 1-.505-.51v-.023q.07-1.466 0-2.934a.507.507 0 0 1 .48-.532l.023-.001h3.922a.483.483 0 0 1 .482.402 10.067 10.067 0 0 1 0 3.196.483.483 0 0 1-.482.402zm-2.846 2.572a.49.49 0 0 0-.488-.572H9.862a.49.49 0 0 0-.488.572 17.114 17.114 0 0 0 .736 2.864 6.761 6.761 0 0 0 1.09 2.048c.374.435.648.516.8.516s.424-.081.798-.516a6.761 6.761 0 0 0 1.09-2.048 17.043 17.043 0 0 0 .736-2.864zm1.696 4.448a.496.496 0 0 1-.64-.664c.03-.078.06-.157.087-.236a19.627 19.627 0 0 0 .906-3.688.507.507 0 0 1 .5-.432h3.23a.472.472 0 0 1 .431.688 10.043 10.043 0 0 1-4.514 4.333zm-8.642 0a.496.496 0 0 0 .641-.664q-.045-.117-.088-.236a19.627 19.627 0 0 1-.906-3.688.507.507 0 0 0-.5-.432h-3.23a.472.472 0 0 0-.43.688 10.043 10.043 0 0 0 4.513 4.333zm-.645-7.553a.507.507 0 0 1-.48.532L6.53 14H2.61a.483.483 0 0 1-.482-.402 10.072 10.072 0 0 1 0-3.196A.483.483 0 0 1 2.61 10h3.92a.506.506 0 0 1 .504.533q-.068 1.466 0 2.934z"></path>
  </Svg>
);

export default IconGlobe;
