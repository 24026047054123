import React from "react";
import PropsTypes from "prop-types";
import { Button, Container, Item } from "./styled";

const Sort = ({ items, ...props }) => {
  return (
    <Container>
      {items.map(item => (
        <Item key={item.label}>
          <Button
            active={item.type === props.sortingType}
            sortingDirection={props.sortingDirection}
            onClick={() => props.sorting(item.type)}
          >
            {item.label}
          </Button>
        </Item>
      ))}
    </Container>
  );
};

Sort.propTypes = {
  items: PropsTypes.arrayOf(PropsTypes.shape),
  sortingType: PropsTypes.string,
  sortingDirection: PropsTypes.oneOf(["asc", "desc"]),
  sorting: PropsTypes.func
};

export default Sort;
