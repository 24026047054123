export const FONT_SIZES = [
  "12",
  "14",
  "18",
  "24",
  "32",
  "48",
  "52",
  "60",
  "72"
];
