const LEAGUES_ACTIONS = {
  fetch: {
    request: "LEAGUES/FETCH_REQUEST",
    success: "LEAGUES/FETCH_SUCCESS",
    error: "LEAGUES/FETCH_ERROR"
  },
  list: {
    request: "LEAGUES/LIST_REQUEST",
    success: "LEAGUES/LIST_SUCCESS",
    error: "LEAGUES/LIST_ERROR"
  },
  seasons: {
    fetch: {
      request: "SEASONS/FETCH_REQUEST",
      success: "SEASONS/FETCH_SUCCESS",
      error: "SEASONS/FETCH_ERROR"
    },
    list: {
      request: "SEASONS/LIST_REQUEST",
      success: "SEASONS/LIST_SUCCESS",
      error: "SEASONS/LIST_ERROR"
    },
    create: {
      request: "SEASON/CREATE_REQUEST",
      success: "SEASON/CREATE_SUCCESS",
      error: "SEASON/CREATE_ERROR"
    },
    update: {
      request: "SEASON/UPDATE_REQUEST",
      success: "SEASON/UPDATE_SUCCESS",
      error: "SEASON/UPDATE_ERROR"
    },
    setState: "SEASON/SET_STATE",
    setProperty: "SEASON/SET_PROPERTY",
    reset: "SEASON/RESET"
  },
  resetLeague: "LEAGUE/RESET_STATE",
  reset: "LEAGUES/RESET_STATE"
};

export default LEAGUES_ACTIONS;
