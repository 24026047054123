import React from "react";
import PropTypes from "prop-types";
import { NotificationsGroupSD } from "./styled";
import LargeNotification from "../LargeNotification/LargeNotification";
import {
  NotificationsGroupTitleSD,
  NotificationsGroupWrapItemSD,
  NotificationsGroupListSD
} from "./styled";

function NotificationsGroup({ title, notifications }) {
  return (
    <NotificationsGroupSD>
      {!!title && (
        <NotificationsGroupTitleSD>{title}</NotificationsGroupTitleSD>
      )}

      <NotificationsGroupListSD>
        {notifications.map(notification => (
          <NotificationsGroupWrapItemSD key={notification.id}>
            <LargeNotification
              image={notification.image}
              title={notification.title}
              content={notification.content}
              dateString={notification.dateString}
              wasNotRead={notification.wasNotRead}
            />
          </NotificationsGroupWrapItemSD>
        ))}
      </NotificationsGroupListSD>
    </NotificationsGroupSD>
  );
}

NotificationsGroup.propTypes = {
  title: PropTypes.string,
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      image: PropTypes.string,
      /**can have b,a,link tags*/
      title: PropTypes.string,
      /**can have b,a,link tags*/
      content: PropTypes.string,
      dateString: PropTypes.string,
      wasNotRead: PropTypes.bool
    })
  ).isRequired
};

export default NotificationsGroup;
