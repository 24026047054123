import { propValueOr } from "../../helpers/common";
import { api } from "../../index";
import CHALLENGES_ACTIONS from "./constants";

export const fetchChallenges = query => dispatch => {
  dispatch({
    type: CHALLENGES_ACTIONS.list.request
  });
  api.challenge
    .list(query)
    .then(resp => {
      dispatch({
        type: CHALLENGES_ACTIONS.list.success,
        payload: propValueOr(resp, "data", [])
      });
    })
    .catch(err => {
      dispatch({
        type: CHALLENGES_ACTIONS.list.error,
        payload: err
      });
    });
};

export const resetChallenges = () => dispatch => {
  dispatch({
    type: CHALLENGES_ACTIONS.reset
  });
};
