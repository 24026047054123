import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { fetchOrganizationsAction } from "../../store/organizations/actions";
import PermissionDenied from "../denied/PermissionDenied";
import RouteOrganizations from "./RouteOrganizations";

class RouteOrganizationsContainer extends Component {
  render() {
    const { permission } = this.props;
    if (!permission) {
      return <PermissionDenied />;
    }
    return <RouteOrganizations {...this.props} />;
  }
}

const mapStateProps = state => ({});

const mapDispatchToProps = {
  fetchOrganizationsAction
};

export default connect(
  mapStateProps,
  mapDispatchToProps
)(withRouter(RouteOrganizationsContainer));
