import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Wallet from "./Wallet";
import {
  fetchWallet,
  fetchStats,
  fetchWallets,
  resetWallet
} from "../../../store/wallet/actions";
import PermissionDenied from "../../denied/PermissionDenied";

class WalletContainer extends Component {
  componentDidMount() {
    this.props.fetchWallet(this.props.match.params.id);
    this.props.fetchStats();
  }

  componentWillUnmount() {
    this.props.resetWallet();
  }

  render() {
    const { permission } = this.props;
    if (!permission) {
      return <PermissionDenied />;
    }
    return <Wallet {...this.props} />;
  }
}

const mapStateProps = state => ({
  user: state.user.info,
  wallet: state.walletState.wallet,
  stats: state.walletState.stats,
  wallets: state.walletState.wallets,
  total: state.walletState.total
});

const mapDispatchToProps = {
  fetchWallet,
  fetchStats,
  fetchWallets,
  resetWallet
};

export default connect(
  mapStateProps,
  mapDispatchToProps
)(withRouter(WalletContainer));
