import styled from "styled-components";
import { COLOR, FONT_WEIGHT, FONT_FAMILY } from "../../../../constants/theme";

export const InfoContainer = styled.div`
  display: flex;
  align-items: stretch;

  width: 320px;
  min-height: 6px;

  padding: 10px;

  color: ${COLOR.TEXT};
  background-image: linear-gradient(to right, #fb023e, #fd295d);
  border-radius: 8px;

  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  border-right: 1px solid ${COLOR.BORDER};
`;

export const Title = styled.div`
  margin-bottom: 4px;
  font: ${FONT_WEIGHT.NORMAL} 14px ${FONT_FAMILY.GOTHIC_BOLD};
`;

export const Text = styled.div`
  font: ${FONT_WEIGHT.NORMAL} 14px ${FONT_FAMILY.GOTHIC};

  a {
    color: ${COLOR.TEXT};
    font: ${FONT_WEIGHT.NORMAL} 15px ${FONT_FAMILY.GOTHIC_BOLD};
    cursor: pointer;
  }
`;

export const CloseButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0px 5px 0px 15px;

  font: ${FONT_WEIGHT.NORMAL} 14px ${FONT_FAMILY.GOTHIC};

  cursor: pointer;
  &:hover {
    opacity: 0.4;
  }
`;

export const WrapButton = styled.div`
  margin-left: auto;
`;
