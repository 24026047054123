import styled from "styled-components";
import {
  COLOR,
  FONT_FAMILY,
  FONT_SIZE,
  FONT_WEIGHT
} from "../../../constants/theme";
import breakpoints from "../../../helpers/breakpoints";

export const EmailDialogSuccessSD = styled.div``;

export const EmailDialogSuccessHeaderSD = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 32px;
`;

export const EmailDialogSuccessWrapIconSD = styled.figure`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 75px;
  height: 75px;
  margin: 30px 0 0;
  background-color: ${COLOR.NAVY_BLUE};
  border-radius: 50%;

  ${breakpoints.down("xs")} {
    width: 60px;
    height: 60px;
    margin-top: 20px;
  }
`;

export const EmailDialogSuccessIconSD = styled.img`
  max-width: 100%;
  width: 100%;
  height: auto;
  object-fit: contain;
`;

export const EmailDialogSuccessTitleSD = styled.div`
  color: ${COLOR.WHITE};
  font-size: ${FONT_SIZE.H3}px;
  font-family: ${FONT_FAMILY.THICK};
  text-transform: uppercase;
`;

export const EmailDialogSuccessBodySD = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 23px;
`;

export const EmailDialogSuccessTextSD = styled.div`
  color: ${COLOR.WHITE};
  font-size: 14px;
  line-height: 16px;
  text-align: center;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const EmailDialogSuccessDateSD = styled.time`
  color: ${COLOR.ROUGE};
  font-size: ${FONT_SIZE.H3}px;
  font-family: ${FONT_FAMILY.CONDENSED};
  font-weight: ${FONT_WEIGHT.BOLD};
  display: inline-block;
  text-align: center;
  padding: 10px 16px;
  border-radius: 26px;
  background: ${COLOR.BG_DATE};

  ${breakpoints.down("xs")} {
    font-size: ${FONT_SIZE.H5}px;
  }
`;

export const EmailDialogSuccessActionsSD = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px;
  padding-top: 17px;
  justify-content: center;
`;

export const EmailDialogSuccessActionsItemSD = styled.div`
  width: calc(50% - 16px);
  margin: 0 8px;
`;

export const EmailDialogSuccessFooterSD = styled.footer``;
