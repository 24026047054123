export const imageField = {
  key: "iamge",
  description:
    "This is the image to display and differentiate your league. It should be unique and recognizable!",
  constraints: {
    width: [1080, 2160],
    height: [606, 1212],
    aspectRatio: [{ value: 1.76 }, { value: 1.8 }]
  },
  imageDimensions: [400, 224],
  imgLabels: [
    "16:9 Aspect Ratio (width / height ≈ 1.78)",
    "Recommended image size of 1920px by 1080px"
  ]
};

export const compressionOptions = {
  maxSizeMB: 0.3,
  useWebWorker: true
};
