import RouteGame from "./RouteGame";
import TransactionsContainer from "./Transactions/TransactionsContainer";

export const routes = [
  {
    path: "/game/:token",
    component: RouteGame,
    exact: true
  },
  {
    path: "/game/:token/transactions",
    component: TransactionsContainer,
    exact: true
  }
];
