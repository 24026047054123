import React, { Component } from "react";
import {
  Avatar,
  AvatarEmpty,
  Content,
  Title,
  Container,
  Text,
  WrapAvatar,
  WrapIcon,
  Icon,
  WrapText,
  Input,
  WrapAction,
  Tooltip,
  Header,
  Time,
  Error,
  Highlight,
  Italics
} from "./styled";
import placeholderUserImg from "../../../../static/images/default-user.jpg";
import iconPencil from "../../../../static/icons/icon-pencil.svg";
import iconCheck from "../../../../static/icons/circle-check-green.svg";
import iconClose from "../../../../static/icons/circle-x-red.svg";
import iconTrash from "../../../../static/icons/icon-trash.svg";
import moment from "moment";

class Message extends Component {
  constructor(props) {
    super(props);

    this.state = {
      text: props.message,
      isEditing: false,
      error: ""
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.message !== this.props.message) {
      this.setState({ text: this.props.message });
    }
  }

  onSubmit = () => {
    this.props
      .onUpdate(this.state.text)
      .then(() => {
        this.setState({ isEditing: false, error: "" });
      })
      .catch(e => {
        // edited message already exists
        if (e.status && e.status.statusCode === 409) {
          this.setState({
            error: "Message already exists. Cannot update message."
          });
        }
      });
  };

  onDelete = () => {
    this.props.onDelete(this.state.text);
  };

  onEdit = e => {
    this.setState({ text: e.target.value });
  };

  parseText = text => {
    if (!text) {
      return text;
    }
    if (text === "Flagged for inappropriate activity.") {
      return <Italics>{text}</Italics>;
    }
    return text.split(" ").map(t => {
      if (t.includes("@")) {
        return <Highlight>{t}</Highlight>;
      }
      return `${t} `;
    });
  };

  render() {
    const { user, timetoken, chatUser, showUser, deleted } = this.props;
    const { text, error, isEditing } = this.state;

    const time = moment(
      new Date(parseInt(timetoken.toString().substring(0, 13)))
    ).format("h:mm A");

    return (
      <Container
        isAdmin={user.role === "admin"}
        deleted={deleted}
        isEditing={isEditing}
        showUser={showUser}
      >
        <Tooltip>
          <WrapIcon onClick={() => this.setState({ isEditing: true })}>
            <Icon src={iconPencil} />
          </WrapIcon>
          <WrapIcon onClick={this.onDelete}>
            <Icon src={iconTrash} />
          </WrapIcon>
        </Tooltip>
        {showUser ? (
          <WrapAvatar>
            <Avatar
              alt={""}
              src={chatUser.image || placeholderUserImg}
              onError={e => (e.target.src = placeholderUserImg)}
            />
          </WrapAvatar>
        ) : (
          <WrapAvatar hideColor={true}>
            {" "}
            <AvatarEmpty />
          </WrapAvatar>
        )}
        <Content>
          {showUser && (
            <Header>
              <Title>{chatUser.name}</Title>
              <Time>{time}</Time>
            </Header>
          )}
          <WrapText deleted={deleted} isAdmin={chatUser.role === "admin"}>
            {isEditing ? (
              <>
                <Input type="text" value={text} onChange={this.onEdit} />
                {error && <Error>{error}</Error>}
              </>
            ) : (
              <Text>{this.parseText(text)}</Text>
            )}
            {isEditing ? (
              <WrapAction>
                <WrapIcon
                  onClick={() =>
                    this.setState({
                      isEditing: false,
                      error: "",
                      text: this.props.message
                    })
                  }
                >
                  <Icon src={iconClose} />
                </WrapIcon>
                <WrapIcon onClick={this.onSubmit}>
                  <Icon src={iconCheck} />
                </WrapIcon>
              </WrapAction>
            ) : (
              <></>
            )}
          </WrapText>
        </Content>
      </Container>
    );
  }
}

export default Message;
