import styled from "styled-components";
import {
  getButtonKitBackgroundColor,
  getButtonKitBorder,
  getButtonKitFontColor
} from "./helpers";
import { BUTTON_STATE } from "./constants";
import { SVGIconPreloaderSD } from "../Icon/styled";
import { FONT } from "../../../constants/theme";
import { addUnit } from "../../../helpers/common";

export const ButtonKitSD = styled.button.attrs(props => ({
  "data-active": props.active,
  "data-preloader": props.preloader,
  disabled: props.preloader || props.disabled
}))`
  display: inline-block;
  min-height: ${({ small }) => (small ? 30 : 48)}px;
  padding: ${({ small }) => (small ? "8px 8px 7px" : "13px 16px 12px")};
  ${props =>
    props.fixedSize &&
    !props.fullWidth &&
    `width: ${props.small ? 140 : 200}px`};
  ${props => props.width && `width: ${addUnit(props.width)}`};
  ${props => props.fullWidth && `width: 100%`};

  background-color: ${props =>
    getButtonKitBackgroundColor(props, BUTTON_STATE.DEFAULT)};
  border: ${props => getButtonKitBorder(props, BUTTON_STATE.DEFAULT)};
  border-radius: ${({ shape }) =>
    shape === "rounded" ? "24px 24px 24px 24px" : "2px"};

  color: ${props => getButtonKitFontColor(props, BUTTON_STATE.DEFAULT)};
  font: ${props => (props.small ? FONT.BUTTON_SMALL : FONT.BUTTON)};
  text-align: center;
  text-decoration: none;
  ${({ uppercase }) => uppercase && "text-transform: uppercase"};
  letter-spacing: 1px;
  vertical-align: middle;

  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  transition: all 0.3s;
  -webkit-appearance: none;
  -moz-appearance: none;
  user-select: none;

  svg {
    ${({ preloader }) => preloader && "display: none"};
    box-sizing: content-box;
    height: auto;
    flex-shrink: 0;
    width: ${({ small }) => (small ? 14 : 16)}px;
    order: 2;
    padding-left: 10px;
    margin: ${props => (props.preloaderNoMargin ? "0" : "0 0 0 auto")};

    fill: ${props => getButtonKitFontColor(props, BUTTON_STATE.DEFAULT)};
    stroke: ${props => getButtonKitFontColor(props, BUTTON_STATE.DEFAULT)};
    transition: all 0.3s;
  }

  ${SVGIconPreloaderSD} {
    display: inline-block;
  }

  &:hover {
    &:not(:disabled) {
      background-color: ${props =>
        getButtonKitBackgroundColor(props, BUTTON_STATE.HOVER)};
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
      border-color: transparent;
      color: ${props => getButtonKitFontColor(props, BUTTON_STATE.HOVER)};

      svg {
        fill: ${props => getButtonKitFontColor(props, BUTTON_STATE.HOVER)};
        stroke: ${props => getButtonKitFontColor(props, BUTTON_STATE.HOVER)};
      }
    }
  }

  &[data-active="true"],
  &:active:not(:disabled) {
    background-color: ${props =>
      getButtonKitBackgroundColor(props, BUTTON_STATE.ACTIVE)};
    box-shadow: inset 0 2px 2px 0 rgba(0, 0, 0, 0.2);
    border-color: transparent;
    color: ${props => getButtonKitFontColor(props, BUTTON_STATE.ACTIVE)};

    svg {
      fill: ${props => getButtonKitFontColor(props, BUTTON_STATE.ACTIVE)};
      stroke: ${props => getButtonKitFontColor(props, BUTTON_STATE.ACTIVE)};
    }
  }

  &:disabled:not([data-preloader="true"]) {
    background-color: ${props =>
      getButtonKitBackgroundColor(props, BUTTON_STATE.DISABLED)};
    border: ${props => getButtonKitBorder(props, BUTTON_STATE.DISABLED)};
    color: ${props => getButtonKitFontColor(props, BUTTON_STATE.DISABLED)};
  }
`;

export const ButtonKitInnerFlexSD = styled.span`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
`;
