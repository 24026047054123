import styled from "styled-components";
import { COLOR, FONT_FAMILY, FONT_WEIGHT } from "../../../../constants/theme";

export const Container = styled.div``;

export const OrganizationGroups = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 20px;
  margin-bottom: 20px;
  border-radius: 5px;

  border: 1px solid ${COLOR.WHITE};
`;

export const InfoText = styled.div`
  text-align: center;
  font: ${FONT_FAMILY.MEDIUM} 16px ${FONT_FAMILY.THIN};
  margin-bottom: 10px;
`;

export const Options = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
  > :not(:last-child) {
    margin-right: 25px;
  }
`;
export const Option = styled.div`
  padding: 40px 15px 24px;
  background-color: ${COLOR.BG_SECONDARY};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;
  border-radius: 4px;
  border: ${({ selected }) =>
    selected ? `3px solid ${COLOR.RIVAL_RED}` : `1px solid ${COLOR.WHITE}`};
  cursor: pointer;

  > :not(:last-child) {
    margin-bottom: 38px;
  }

  &:hover {
    opacity: ${({ selected }) => !selected && "0.9"};
  }
`;

export const Icon = styled.img`
  width: 40px;
  height: 30px;
`;

export const Title = styled.div`
  font: ${FONT_WEIGHT.MEDIUM} 18px / 21px ${FONT_FAMILY.GOTHIC_BOLD};
  letter-spacing: 0.5px;
  text-transform: uppercase;
  text-align: center;
`;

export const ErrorText = styled.div`
  font: ${FONT_WEIGHT.MEDIUM} 12px / 1.1 ${FONT_FAMILY.THIN};
  color: ${COLOR.CHERRY_RED};
  margin: -5px 0 10px;
`;
