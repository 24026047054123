import React, { Component } from "react";
import { connect } from "react-redux";
import { verifyPermission } from "../../helpers/common";
import { Container, ContentCard } from "./styled";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import { routes } from "./routes";
import { fetchChallenges } from "../../store/challenges/actions";
import { resetActions } from "../../store/actions/actions";

class RouteNotifications extends Component {
  handleFilterChange(url) {
    this.props.resetActions();
    this.props.history.push({ pathname: url });
  }

  render() {
    return (
      <Container>
        <ContentCard>
          <Switch>
            {routes.map((route, i) => {
              const Component = route.component;
              return (
                <Route
                  key={i}
                  exact={route.exact}
                  path={route.path}
                  render={() => {
                    return (
                      <Component
                        {...this.props}
                        user={this.props.user}
                        permission={verifyPermission(
                          route.path,
                          this.props.permissions
                        )}
                      />
                    );
                  }}
                />
              );
            })}
            <Route render={() => <Redirect to={"/notifications/matches"} />} />
          </Switch>
        </ContentCard>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  permissions: state.permissionsState.permissions,
  matches: state.matchesState.matches,
  isFetchingMatches: state.matchesState.isFetching,
  challenges: state.challengesState.challenges,
  isFetchingChallenges: state.challengesState.isFetching
});
const mapDispatchToProps = {
  fetchChallenges,
  resetActions
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RouteNotifications));
