import styled from "styled-components";
import {
  COLOR,
  FONT_FAMILY,
  FONT_WEIGHT,
  FONT_SIZE
} from "../../../../constants/theme";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 4px;
  margin-bottom: 25px;
  padding: 0 10px;
  width: 100%;
`;

export const Content = styled.div`
  width: 100%;
`;

export const Info = styled.div`
  display: flex;
`;

export const Title = styled.div`
  text-align: left;
  max-width: 560px;
  letter-spacing: 0.71px;
  font: ${FONT_WEIGHT.MEDIUM} 24px ${FONT_FAMILY.THIN};
  text-transform: uppercase;
`;

export const Subtitle = styled.div`
  text-align: left;
  max-width: 560px;
  letter-spacing: 0.71px;
  font: ${FONT_WEIGHT.MEDIUM} 16px ${FONT_FAMILY.THIN};
  text-transform: uppercase;
`;

export const Path = styled.div`
  height: 1px;
  width: 100%;
  margin-top: 6px;
  margin-bottom: 6px;
  background-color: ${COLOR.BG_SECONDARY};
`;

export const DarkPath = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${COLOR.BG_PRIMARY};
`;

export const VertPath = styled.div`
  width: 1px;
  height: 150;
  margin-right: 12px;
  background-color: ${COLOR.BG_PRIMARY};
`;

export const ItemWrap = styled.div`
  width: 100%;
  display: flex;
  background-color: ${COLOR.BG_SECONDARY};
  margin-top: 20px;
  padding: 26px 26px 20px 20px;
  flex-direction: column;
`;

export const GameImage = styled.img`
  width: 67px;
  height: 100px;
  margin-right: 16px;
  margin-bottom: 16px;
`;

export const TournamentImage = styled.img`
  width: 100%;
  display: flex;
`;

export const TournamentIcon = styled.img`
  width: 25%;
  display: flex;
`;

export const TournamentBanner = styled.img`
  width: 90%;
  display: flex;
`;

export const TournamentThumbnail = styled.img`
  width: 25%;
  display: flex;
`;
export const Header = styled.div`
  width: 100%;
  height: 10%;
`;
export const TournamentName = styled.div`
  text-align: left;
  max-width: 560px;
  letter-spacing: 0.71px;
  font: ${FONT_WEIGHT.MEDIUM} 24px ${FONT_FAMILY.THIN};
`;

export const Label = styled.div`
  font: ${FONT_WEIGHT.MEDIUM} 14px ${FONT_FAMILY.THIN};
  margin: 16px;
  margin-left: 0px;
`;

export const LabelThin = styled.div`
  font: ${FONT_WEIGHT.NORMAL} 14px ${FONT_FAMILY.THIN};
  margin: 16px;
  margin-left: 0px;
`;

export const Date = styled.div`
  text-align: left;
  max-width: 560px;
  letter-spacing: 0.71px;
  font: ${FONT_WEIGHT.MEDIUM} 14px ${FONT_FAMILY.THIN};
  text-transform: uppercase;
`;

export const Icon = styled.img`
  max-width: 36px;
  height: 25px;
  margin-top: 12px;
  margin-right: 12px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const RowItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export const Type = styled.div``;

export const Description = styled.div`
  text-align: left;
  font: ${FONT_WEIGHT.LIGHT} ${FONT_SIZE.PARAGRAPH_SMALL} ${FONT_FAMILY.THIN};
  width: 100%;
  padding: 16px 0px;
  padding-right: 12px;
  ${"" /* margin-right: 22px; */}
`;

export const DescriptionWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 0px;
  width: 50%;
`;

export const ListWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

export const ImageWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 0px;
  width: 100%;
  margin: 16px;
`;

export const SmallImageWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 0px;
  width: 50%;
  margin: 16px;
`;

export const DateItem = styled.div``;

export const Stage = styled.div``;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 16px;
  padding-bottom: 16px;
`;

export const StageItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin: 5px;
`;
