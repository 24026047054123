import React, { Component } from "react";
import {
  Container,
  Content,
  Header,
  NavBar,
  WrapArrow,
  ArrowIcon,
  NavItem,
  List,
  WrapButton,
  Badge,
  Info,
  Text,
  Item,
  WrapCoinsCount,
  CoinsCount,
  CoinsIcon
} from "./styled";
import { propValueOr } from "../../../helpers/common";
import { withRouter } from "react-router";
import ArrowBack from "../../../static/icons/chevron-arrow.svg";
import FormFieldKit from "../../../components/kit/Fields/FormField/FormField";
import ButtonKit from "../../../components/kit/Button/ButtonKit";
import { api } from "../../../index";
import Loader from "../../../components/presentation/Loader/Loader";
import { numberToThousandsString } from "../../../helpers/common";
import CoinIcon from "../../../static/icons/rival_coin.svg";
import moment from "moment";

class Transaction extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isUpdating: false,
      canUpdate: false
    };
  }

  componentDidMount() {
    this.fetchTransaction();
  }

  fetchTransaction = () => {
    const transactionId = propValueOr(this.props.match, "params.id", undefined);
    this.props.fetchTransaction(transactionId).then(() => {
      const { status } = this.props.transaction;
      this.setState({
        status: status
      });
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      this.fetchTransaction();
    }
  }

  render() {
    const { transaction, history, isFetching } = this.props;
    const { status, canUpdate } = this.state;

    if (isFetching) {
      return <Loader />;
    }

    return (
      <Container>
        <Content>
          <Header>
            <NavBar>
              <WrapArrow onClick={() => history.goBack()}>
                <ArrowIcon src={ArrowBack} />
              </WrapArrow>
              <NavItem>{`Transaction Id #${transaction.id}`}</NavItem>
            </NavBar>
          </Header>
          <Info>
            <Text>{propValueOr(transaction, "metadata.memo", "")}</Text>
            <WrapCoinsCount>
              <CoinsCount>
                {numberToThousandsString(transaction.amount)}
              </CoinsCount>
              <CoinsIcon src={CoinIcon} />
            </WrapCoinsCount>
          </Info>
          <Item>
            <Info>
              <Badge>
                {propValueOr(transaction, "sender.walletType", "")}:
                {propValueOr(
                  transaction,
                  `sender.${propValueOr(
                    transaction,
                    "sender.walletType",
                    ""
                  )}.id`,
                  ""
                )}
              </Badge>
              {"->"}
              <Badge>
                {propValueOr(transaction, "recipient.walletType", "")}:
                {propValueOr(
                  transaction,
                  `recipient.${propValueOr(
                    transaction,
                    "recipient.walletType",
                    ""
                  )}.id`,
                  ""
                )}
              </Badge>
            </Info>

            <Info>
              <Badge>
                {moment(transaction.createdAt).format("M/DD/YY @ h:mm A")}
              </Badge>
            </Info>
          </Item>
          <List>
            <FormFieldKit
              label={"Status"}
              description={"Can only be changed if pending"}
              fullWidth
              multiline
              select
              disabled={status !== "pending"}
              selectOptions={["succeeded", "pending", "failed"]}
              value={status}
              onChange={val => {
                if (val === "succeeded") {
                  this.setState({
                    status: val,
                    canUpdate: true
                  });
                }
              }}
              placeholder={"Select transaction status"}
            />
          </List>
          <WrapButton>
            <ButtonKit
              color={"rival_red"}
              shape={"rounded"}
              fullWidth
              disabled={!canUpdate}
              preloader={this.state.isUpdating}
              onClick={() => {
                if (canUpdate && status === "succeeded") {
                  this.setState({ isUpdating: true });
                  api.wallet
                    .update(transaction.id, {
                      status
                    })
                    .then(() => {
                      this.setState({ isUpdating: false });
                      this.fetchTransaction();
                    })
                    .catch(() => this.setState({ isUpdating: false }));
                }
              }}
            >
              Update Status
            </ButtonKit>
          </WrapButton>
        </Content>
      </Container>
    );
  }
}

export default withRouter(Transaction);
