import React, { Component } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import TypographyKit from "../../../components/kit/Typography/TypographyKit";
import FormField from "../../../components/kit/Fields/FormField/FormField";
import IconSearch from "../../../components/kit/Icon/IconSearch";
import { ContentBody, ContentHead } from "../styled";
import Empty from "../../../components/presentation/Empty/Empty";
import Email from "../../../components/smart/Email/Email";
import {
  fetchSentEmails,
  setSentEmails
} from "../../../store/communication/actions";
import Loader from "../../../components/presentation/Loader/Loader";
import withWidth from "../../../hoc/withWidth";
import { api } from "../../../index";

class CommunicationSent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchValue: ""
    };
  }

  componentDidMount() {
    this.props.fetchSentEmails();
  }

  async searchEmails() {
    try {
      const resp = await api.searchEmails("sent", this.state.searchValue);
      const emails = resp.data.docs;
      this.props.setSentEmails(emails);
    } catch (err) {
      console.log(err);
    }
  }

  handleChange(value) {
    this.setState(
      {
        searchValue: value
      },
      this.searchEmails
    );
  }

  render() {
    const { searchValue } = this.state;
    const { emails, loading, width } = this.props;
    const emailsNotEmpty = emails && emails.length > 0;
    const emailsIsSearching = emailsNotEmpty || searchValue !== "";

    return loading ? (
      <Loader isBlock />
    ) : (
      <>
        <ContentHead>
          <TypographyKit variant={"caption1"}>Sent</TypographyKit>
          {emailsIsSearching && (
            <FormField
              label={
                width > 520
                  ? null
                  : "Search tournaments, organizations or by email address…"
              }
              fullWidth
              iconAlign={"left"}
              icon={<IconSearch />}
              value={searchValue}
              placeholder={
                width > 520
                  ? "Search tournaments, organizations or by email address…"
                  : null
              }
              onChange={this.handleChange.bind(this)}
            />
          )}
        </ContentHead>
        <ContentBody>
          {emailsNotEmpty ? (
            emails.map((item, index) => (
              <Email
                key={index}
                visibleRecipientsTooltip={true}
                visibleAuthor={true}
                {...item}
              />
            ))
          ) : (
            <Empty
              message={
                emailsIsSearching
                  ? "SENT EMAILS NOT FOUND"
                  : "YOU HAVE NO SENT EMAILS"
              }
            />
          )}
        </ContentBody>
      </>
    );
  }
}

CommunicationSent.propTypes = {
  loading: PropTypes.bool,
  emails: PropTypes.arrayOf(PropTypes.object),
  fetchSentEmails: PropTypes.func.isRequired,
  setSentEmails: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  emails: state.communication.sent,
  loading: state.communication.loading
});

const mapDispatchToProps = {
  fetchSentEmails,
  setSentEmails
};

export default compose(
  withWidth,
  connect(mapStateToProps, mapDispatchToProps)
)(CommunicationSent);
