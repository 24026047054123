import GAMESERIES_ACTIONS from "./constants";

const initialState = {
  series: {},
  allSeries: [],
  error: null,
  isFetching: false
};

const reducers = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GAMESERIES_ACTIONS.list.request:
      return {
        ...state,
        isFetching: true
      };
    case GAMESERIES_ACTIONS.list.success:
      return {
        ...state,
        allSeries: action.payload,
        isFetching: false,
        error: null
      };
    case GAMESERIES_ACTIONS.list.error:
      return {
        ...state,
        allSeries: [],
        isFetching: false,
        error: payload
      };
    case GAMESERIES_ACTIONS.fetch.request:
      return {
        ...state,
        isFetching: true
      };
    case GAMESERIES_ACTIONS.fetch.success:
      return {
        ...state,
        series: action.payload,
        isFetching: false,
        error: null
      };
    case GAMESERIES_ACTIONS.fetch.error:
      return {
        ...state,
        series: {},
        isFetching: false,
        error: payload
      };
    case GAMESERIES_ACTIONS.reset:
      return initialState;
    default:
      return state;
  }
};

export default reducers;
