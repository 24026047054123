import React from "react";
import PropTypes from "prop-types";
import { NotificationsPaperBodySD, NotificationsPaperSD } from "./styled";
import {
  NotificationsPaperFooterSD,
  NotificationsPaperTitleSD
} from "./styled";

function NotificationsPaper({
  title = "Notifications",
  children,
  footer,
  isFixed
}) {
  return (
    <NotificationsPaperSD>
      <NotificationsPaperTitleSD isFixed={isFixed}>
        {title}
      </NotificationsPaperTitleSD>

      <NotificationsPaperBodySD>{children}</NotificationsPaperBodySD>

      {!!footer && (
        <NotificationsPaperFooterSD isFixed={isFixed}>
          {footer}
        </NotificationsPaperFooterSD>
      )}
    </NotificationsPaperSD>
  );
}

NotificationsPaper.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  footer: PropTypes.node,
  /**fixed header and footer when scroll*/
  isFixed: PropTypes.bool
};

export default NotificationsPaper;
