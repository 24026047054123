import React from "react";

import DialogKit from "../../../../components/kit/Dialog/DialogKit";
import ButtonKit from "../../../../components/kit/Button/ButtonKit";
import {
  Container,
  Header,
  HeaderTitle,
  TextContent,
  Buttons,
  WrapButton
} from "./styled";

class DeleteDialog extends React.Component {
  renderDialog = () => {
    const { onClose, onDelete, name, color } = this.props;

    return (
      <Container>
        <Header>
          <HeaderTitle>
            {color ? "Reset Default Color" : "Delete Asset"}
          </HeaderTitle>
        </Header>
        <TextContent>
          {color
            ? "Are you sure you wish to reset to Rival default color for:"
            : "Are you sure you wish to delete:"}
          <br />
          {name}
        </TextContent>
        <Buttons>
          <WrapButton>
            <ButtonKit small fullWidth color="red" onClick={onDelete}>
              {color ? "Confirm Reset" : "Delete Asset"}
            </ButtonKit>
          </WrapButton>
          <WrapButton>
            <ButtonKit small fullWidth appearance="secondary" onClick={onClose}>
              Cancel
            </ButtonKit>
          </WrapButton>
        </Buttons>
      </Container>
    );
  };

  render() {
    const { isOpen, onClose } = this.props;

    return (
      <DialogKit
        isOpen={isOpen}
        onClose={onClose}
        renderCustom={this.renderDialog}
        displayButtonClose={false}
      />
    );
  }
}

export default DeleteDialog;
