import styled, { css } from "styled-components";
import PropTypes from "prop-types";

import { COLOR, FONT_FAMILY, FONT_WEIGHT } from "../../../constants/theme";
import { addUnit } from "../../../helpers/common";
import { zIndex } from "../../presentation/LevelUp/LevelUp";

export const DialogWrapperSD = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.6);

  z-index: ${zIndex};

  ${({ isOverflow }) =>
    isOverflow &&
    css`
      padding: 2.5% 0;
      overflow: auto;
    `};

  // fix bug on iphone when dialog height more then 100vh
  ${() => {
    const isIOSOnMobile = /iphone|ipod|ipad/i.test(navigator.userAgent);

    if (isIOSOnMobile) {
      return css`
        padding-bottom: 100px;
      `;
    }
  }}
`;

export const DialogInnerSD = styled.div`
  position: relative;
  ${({ width }) => (width ? `width: ${addUnit(width)}` : "")};
  min-width: 300px;
  max-width: 96%;
  max-height: ${({ isOverflow }) => (isOverflow ? "initial" : "95%")};
  padding: ${({ isMobile }) => (isMobile ? "5px 10px" : "22px 34px")};
  margin: auto;

  background-color: ${({ bgColor }) => bgColor};
  border: 1px solid ${({ bgColor }) => bgColor};
  border-radius: 2px;
`;
DialogInnerSD.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};
DialogInnerSD.defaultProps = {
  width: 435
};

export const DialogInnerCustomSD = styled.div`
  position: relative;
  margin: auto;
`;
DialogInnerCustomSD.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};
DialogInnerCustomSD.defaultProps = {
  width: 435
};

export const DialogHeaderSD = styled.div`
  padding-right: 34px;
  min-height: 46px;
  margin-bottom: 20px;

  border-bottom: 1px solid ${COLOR.BORDER};
`;

export const DialogTitleSD = styled.h2`
  margin: 0 0 5px;
  color: ${COLOR.TEXT_3};
  font: ${FONT_WEIGHT.MEDIUM} 28px / 1.2 ${FONT_FAMILY.THICK};
  letter-spacing: 1px;
  text-transform: uppercase;
`;

export const DialogBtnCloseSD = styled.div`
  position: absolute;
  top: 24px;
  right: 27px;

  display: flex;
  width: 30px;
  height: 30px;

  cursor: pointer;
  transition: all 0.3s;

  svg {
    margin: auto;
    transform: rotate(45deg);
    fill: ${COLOR.FG_PRIMARY};
  }

  &:hover {
    opacity: 0.8;
  }
`;

export const DialogBtnCloseCustomSD = styled.div`
  position: absolute;
  top: 15px;
  right: 17px;

  display: flex;
  width: 30px;
  height: 30px;

  cursor: pointer;
  transition: all 0.3s;

  svg {
    margin: auto;
    transform: rotate(45deg);
    fill: ${COLOR.WHITE};
  }

  &:hover {
    opacity: 0.8;
  }
`;

export const DialogFooterSD = styled.div`
  margin-top: 20px;
`;
