import React from "react";

import {
  Route404BackgroundImage,
  Route404CaptionSD,
  Route404ContentSD,
  Route404SD,
  Route404TextSD
} from "./styled";
import { Link } from "react-router-dom";
import ButtonKit from "../../components/kit/Button/ButtonKit";

function PermissionDenied() {
  return (
    <Route404SD>
      <Route404ContentSD>
        <Route404CaptionSD>Permission Denied.</Route404CaptionSD>
        <Route404TextSD>You do not have access to this page. </Route404TextSD>

        <ButtonKit
          fullWidth
          appearance={"primary"}
          as={({ appearance, fullWidth, uppercase, ...rest }) => (
            <Link {...rest} />
          )}
          to={"/"}
        >
          Go to homepage
        </ButtonKit>
      </Route404ContentSD>

      <Route404BackgroundImage />
    </Route404SD>
  );
}

PermissionDenied.propTypes = {};

export default PermissionDenied;
