import React from "react";
import {
  DialogContainer,
  DialogHeader,
  DialogTitle,
  PlayerSelection,
  SelectionText,
  PlayerRow,
  PlayerProfile,
  Buttons,
  Success,
  Text
} from "./styled";
import { propValueOr, getUsername } from "../../../../../../helpers/common";
import DialogKit from "../../../../../../components/kit/Dialog/DialogKit";
import ButtonKit from "../../../../../../components/kit/Button/ButtonKit";
import RadioButtonKit from "../../../../../../components/kit/Fields/RadioButton/RadioButtonKit";
import placeholderUserImg from "../../../../../../static/images/default-user.jpg";
import { api } from "../../../../../../index";
import iconCheck from "../../../../../../static/icons/circle-check-green.svg";
import iconX from "../../../../../../static/icons/circle-x-red.svg";
import { fetchGameAction } from "../../../../../../store/game/actions";
import { connect } from "react-redux";
class ReadyDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedIndex: null,
      readyPlayer: null,
      success: false,
      error: ""
    };
  }

  handleCheckboxClick(player, index) {
    this.setState({ checkedIndex: index, readyPlayer: player });
  }

  handleReady = () => {
    if (this.state.readyPlayer) {
      api.admin.matches
        .update(this.props.game.token, {
          ready: !this.state.readyPlayer.ready,
          userId: this.state.readyPlayer.id
        })
        .then(resp => {
          this.setState({ success: true });
          this.props.fetchGameAction(this.props.game.token);
          this.props.refreshTournament();
        })
        .catch(err =>
          this.setState({
            error: propValueOr(
              err,
              "response.data.message",
              err.message || "Unknown error. Please Try again later."
            )
          })
        );
    }
  };

  renderSuccess = () => {
    const { error } = this.state;
    return (
      <DialogContainer>
        <DialogHeader>
          <DialogTitle>
            {error ? "Ready Status Change Failed" : "Success!"}
          </DialogTitle>
        </DialogHeader>
        <Success>
          <img
            src={error ? iconX : iconCheck}
            width={65}
            height={65}
            alt="check"
          />
          <Text>{error || "The user's ready status has been updated."}</Text>
        </Success>
        <Buttons>
          <ButtonKit
            fullWidth
            onClick={() =>
              error
                ? this.setState({
                    error: null,
                    homeScore: "",
                    awayScore: "",
                    success: false
                  })
                : this.handleClose()
            }
            color={error ? "primary" : "green"}
          >
            {error ? "Try Again" : "Okay"}
          </ButtonKit>
        </Buttons>
      </DialogContainer>
    );
  };

  handleClose = () => {
    const { onClose } = this.props;

    this.setState({ readyplayer: null, checkedIndex: null, success: false });
    onClose();
  };

  renderDialog = () => {
    const { players, onClose } = this.props;
    const { checkedIndex, readyPlayer } = this.state;
    return (
      <DialogContainer>
        <DialogHeader>
          <DialogTitle>Change Ready Status</DialogTitle>
        </DialogHeader>
        <PlayerSelection>
          <SelectionText>
            Select a player to change the ready status for
          </SelectionText>
          {players.map((player, index) => (
            <PlayerRow>
              <RadioButtonKit
                value={checkedIndex === index}
                checked={checkedIndex === index}
                onChange={() => this.handleCheckboxClick(player, index)}
              />
              <PlayerProfile
                src={
                  player.team
                    ? propValueOr(
                        player,
                        "team.imageInfo.thumbnail",
                        placeholderUserImg
                      )
                    : propValueOr(
                        player,
                        "imageInfo.thumbnail",
                        placeholderUserImg
                      )
                }
                onError={e => (e.target.src = placeholderUserImg)}
              />
              {propValueOr(player, "team.name", getUsername(player))}
              {" - "}
              {player.ready ? "change to NOT READY" : "change to READY"}
            </PlayerRow>
          ))}
        </PlayerSelection>
        <Buttons>
          <ButtonKit
            disabled={!readyPlayer}
            onClick={this.handleReady}
            fullWidth
            color={"red"}
          >
            Submit
          </ButtonKit>
          <ButtonKit fullWidth appearance={"secondary"} onClick={onClose}>
            Cancel
          </ButtonKit>
        </Buttons>
      </DialogContainer>
    );
  };

  render() {
    const { isOpen } = this.props;
    return (
      <DialogKit
        isOpen={isOpen}
        onClose={this.handleClose}
        renderCustom={
          this.state.success || this.state.error
            ? this.renderSuccess
            : this.renderDialog
        }
        displayButtonClose={false}
      />
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  fetchGameAction
};

export default connect(mapStateToProps, mapDispatchToProps)(ReadyDialog);
