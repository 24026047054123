import styled from "styled-components";
import { COLOR } from "../../../constants/theme";
import ButtonKit from "../../../components/kit/Button/ButtonKit";

export const Container = styled.div`
  min-height: 100%;
  background-color: ${COLOR.BG_PRIMARY};
  max-width: 1440px;
  margin: 0 auto;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const Button = styled(ButtonKit)`
  margin-bottom: 10px;
`;

export const Error = styled.div`
  color: ${COLOR.CHERRY_RED};
`;
