import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import {
  Container,
  Content,
  ContentInner,
  FullscreenBg,
  FullscreenImage,
  FullscreenText
} from "./styled";
import communicationRoutes from "./routes";
import { verifyPermission } from "../../helpers/common";

class RouteCommunication extends Component {
  constructor(props) {
    super(props);

    this.state = this.initialState(props);
  }

  initialState = () => {
    return {
      image: null
    };
  };

  componentDidMount() {
    document.addEventListener("keydown", this.handleCloseImage);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleCloseImage);
  }

  handleCloseImage = () => {
    if (this.state.image) {
      this.setState({ image: null });
    }
  };

  handleSetImage = image => {
    this.setState({
      image: image
    });
  };

  renderContent = () => (
    <Switch>
      {communicationRoutes.map(route => (
        <Route
          exact={route.exact}
          key={route.path}
          path={route.path}
          render={props => {
            const ContentComponent = route.contentComponent;
            return (
              <ContentComponent
                {...props}
                {...route.props}
                permission={verifyPermission(
                  route.path,
                  this.props.permissions
                )}
                permissions={this.props.permissions}
                handleSetImage={this.handleSetImage}
                user={this.props.user}
              />
            );
          }}
        />
      ))}
      <Route render={() => <Redirect to={"/communication/create"} />} />
    </Switch>
  );
  render() {
    const { image } = this.state;

    return (
      <Container>
        {image ? (
          <FullscreenBg onClick={this.handleCloseImage}>
            <FullscreenText>Click anywhere to exit</FullscreenText>
            <FullscreenImage src={image} />
          </FullscreenBg>
        ) : (
          <Content>
            <ContentInner>{this.renderContent()}</ContentInner>
          </Content>
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  permissions: state.permissionsState.permissions
});

export default connect(mapStateToProps)(RouteCommunication);
