import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  Container,
  Body,
  Column,
  Header,
  HeaderTitle,
  Segment,
  SegmentTitle,
  Row
} from "./styled";

const Table = props => {
  const {
    data,
    columns,
    isSegmented,
    renderContentForEmptyTable,
    yourRank,
    headerBgColor,
    headerColor,
    cellPadding
  } = props;

  const renderSegmentedTable = (columns, data, yourRank) => {
    return data.map(segment => {
      const { rowData, renderTitle } = segment;

      return rowData.length ? (
        <Segment>
          <SegmentTitle>{renderTitle(segment)}</SegmentTitle>
          {rowData.map((rowData, rowIndex) => {
            return (
              <Row
                key={rowData.id}
                {...(!!rowData.to && { as: Link, to: rowData.to })}
              >
                {columns.map(column => (
                  <Column
                    key={column.propName}
                    innerWidth={column.innerWidth}
                    breakpointsWidth={column.breakpointsWidth}
                    hiddenBP={column.hidden}
                    centered={column.centered}
                  >
                    {column.renderItem(rowData, rowIndex)}
                  </Column>
                ))}
              </Row>
            );
          })}
        </Segment>
      ) : null;
    });
  };

  const renderTable = (columns, data, yourRank) => {
    return data.map((rowData, rowIndex) => {
      return (
        <Row
          key={`${rowData.id}-${rowIndex}`}
          {...(!!rowData.to && { as: Link, to: rowData.to })}
        >
          {columns.map(column => {
            return (
              <Column
                key={column.propName + rowData.id}
                innerWidth={column.innerWidth}
                breakpointsWidth={column.breakpointsWidth}
                hiddenBP={column.hidden}
                centered={column.centered}
                padding={cellPadding}
              >
                {column.renderItem(rowData, rowIndex)}
              </Column>
            );
          })}
        </Row>
      );
    });
  };

  return (
    <Container>
      <Header>
        <Row bgColor={headerBgColor}>
          {columns.map(column => (
            <Column
              key={column.propName}
              innerWidth={column.innerWidth}
              breakpointsWidth={column.breakpointsWidth}
              hiddenBP={column.hidden}
              centered={column.centered}
            >
              <HeaderTitle fontColor={headerColor}>{column.title}</HeaderTitle>
            </Column>
          ))}
        </Row>
      </Header>

      <Body>
        {data.length ? (
          isSegmented ? (
            renderSegmentedTable(columns, data, yourRank)
          ) : (
            renderTable(columns, data, yourRank)
          )
        ) : (
          <Row>
            {typeof renderContentForEmptyTable === "function" &&
              renderContentForEmptyTable()}
          </Row>
        )}
      </Body>
    </Container>
  );
};

Table.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      centered: PropTypes.bool,
      /**
       * if 'grow' flex-grow will equal 1 another 0
       * if 'auto' flex-basis and width will equal auto
       * if number it will be transform to pixels
       * if string without units will ber transform to pixels
       * if string pass to width and flex-basis properties of css
       * */
      innerWidth: PropTypes.oneOfType([
        PropTypes.oneOf(["grow"]),
        PropTypes.number,
        PropTypes.string
      ]),
      /**
       * It will be used as an identifier, it should be unique.
       * Also, when body columns will render it pass to the column render method
       * */
      propName: PropTypes.string.isRequired,
      /**
       * Render item of data array
       * @property rowData - item from data array
       * @property rowIndex - index of item from data array
       * */
      renderItem: PropTypes.func.isRequired,
      /**
       * Display none for column witch breakpoint select
       */
      hiddenBP: PropTypes.oneOf(["lg", "md", "sm", "xs"]),
      /**
       * Add diff view for your rank
       */
      yourRank: PropTypes.object
    })
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
  renderContentForEmptyTable: PropTypes.func
};

Table.defaultProps = {
  yourRank: {},
  isSegmented: false
};

export default Table;
