import styled from "styled-components";
import { COLOR } from "../../../constants/theme";
// import { Link } from "react-router-dom";
// import breakpoints from "../../../helpers/breakpoints";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  background-color: ${COLOR.BG_PRIMARY};
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  padding-bottom: 20px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;

  padding: 30px;
  border-radius: 16px;

  background-color: ${COLOR.BG_SECONDARY};
`;
