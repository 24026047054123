import styled from "styled-components";
import { COLOR, FONT_FAMILY, FONT_WEIGHT } from "../../constants/theme";
import breakpoints from "../../helpers/breakpoints";

export const RouteLoginSD = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  height: 100%;
  padding-right: 47px;

  ${breakpoints.down("md")} {
    padding: 56px 0;
  }
`;

export const RouteLoginWrapFormSD = styled.div`
  width: 435px;
  margin: auto;

  ${breakpoints.down("md")} {
    width: 100%;
    max-width: 435px;
  }
`;

export const RouteLoginForgotPassSD = styled.div`
  margin-top: 16px;
  text-align: center;
  color: ${COLOR.GREY};
  font: ${FONT_WEIGHT.MEDIUM} 12px / 1 ${FONT_FAMILY.THIN};
`;

export const Text = styled.div`
  font: ${FONT_WEIGHT.NORMAL} 14px / 16px ${FONT_FAMILY.THIN};
  color: ${COLOR.TEXT};
  text-align: center;
  margin: 24px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  &:before {
    content: "";
    display: flex;
    align-items: center;
    margin-right: 10px;
    width: 100%;
    height: 1px;
    background: ${COLOR.BORDER};
    flex: 0.5 0.5 0;
  }
  &:after {
    content: "";
    display: flex;
    align-items: center;
    margin-left: 10px;
    width: 100%;
    height: 1px;
    background: ${COLOR.BORDER};
    flex: 0.5 0.5 0;
  }
`;
