import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import { addUnit } from "../../../../helpers/common";
import { SVGIconSD } from "../../Icon/styled";
import { COLOR } from "../../../../constants/theme";

export const Container = styled.label`
  position: relative;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: ${({ width }) => addUnit(width)};
  height: ${({ height }) => addUnit(height)};
  padding: 0;
  margin: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  border: none;
  transition: all 0.3s;
  opacity: ${({ disabled }) => (disabled ? "0.5" : "1")};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};

  ${SVGIconSD} {
    transition: all 0.3s;
  }

  &:hover {
    ${SVGIconSD} {
      opacity: 0.6;
    }
  }
`;

Container.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

Container.defaultProps = {
  width: 40,
  height: 40
};

export const Content = styled.span`
  ${({ variant }) =>
    variant === "primary" &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      min-height: 48px;
      padding: 20px 16px;
      background-color: transparent;
      border: 1px dashed #ffffff;
      border-radius: 4px 4px;
      color: #ffffff;
      font: 500 14px / 1.5 "Roboto", sans-serif;
      text-align: center;
      text-decoration: none;
      text-transform: uppercase;
      letter-spacing: 1px;
      vertical-align: middle;
      cursor: pointer;
      transition: all 0.3s;
      user-select: none;

      &:hover {
        background-color: ${({ bgColor }) =>
          !!bgColor ? bgColor : COLOR.ROUGE};
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
        border-color: transparent;
        color: #ffffff;
      }

      svg {
        fill: #fff;
        margin-left: 10px;
      }
    `}
`;

export const Input = styled.input`
  position: absolute;
  left: -9999px;
  visibility: hidden;
  opacity: 0;
`;
