import { api } from "../../index";
import { USERS_ACTIONS } from "./constants";
import { propValueOr } from "../../helpers/common";

export const fetchUsersAction = filter => async dispatch => {
  dispatch({
    type: USERS_ACTIONS.reset
  });
  dispatch({
    type: USERS_ACTIONS.fetch.request
  });
  return api.admin.users
    .list(filter)
    .then(resp => {
      const data = propValueOr(resp, "data", []);

      dispatch({
        type: USERS_ACTIONS.fetch.success,
        payload: data
      });
    })
    .catch(err => {
      dispatch({ type: USERS_ACTIONS.fetch.error, payload: err });
    });
};
