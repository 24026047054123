import moment from "moment";

export const getSeasonErrors = (season, validate = false) => {
  const {
    // name,
    startDate,
    endDate,
    schedule
    // teamSize,
    // minPlayers,
    // maxPlayers,
  } = season;

  const errors = {};
  // if (!name) {
  //   if (validate) return false;
  //   errors.name = "Name is required";
  // }
  if (!startDate) {
    if (validate) return false;
    errors.startDate = "Start date is required";
  }
  if (!endDate) {
    if (validate) return false;
    errors.endDate = "End date is required";
  }
  if (!schedule || !(schedule.length >= 1)) {
    if (validate) return false;
    errors.schedule = "Must create at least one match for the season";
  }
  if (schedule?.length > 0) {
    for (let i = 0; i < schedule.length; i++) {
      if (
        i > 0 &&
        moment(schedule[i].startDate).isSameOrBefore(
          moment(schedule[i - 1]?.startDate)
        )
      ) {
        if (validate) return false;
        errors.schedule = `Round ${i + 1} must be after Round ${i}`;
      }
    }
  }
  // ctodo: add validation for all other fields

  return validate ? true : errors;
};

export const getUpdates = (initialSeason, newSeason) => {};

export const getNavItems = season => [
  "details",
  // "images",
  ...(season?.status === "initialized"
    ? ["schedule"]
    : season?.status === "in progress"
    ? ["schedule", "matches"]
    : season?.status === "complete"
    ? ["matches"]
    : []),
  season?.teamSize > 1 ? "teams" : "players"
];

export const SEASON_TYPES = [
  {
    value: "scheduled",
    label: "Scheduled - All matchups are set at the start of the season"
  },
  {
    value: "ranked",
    label:
      "Ranked - Matchups are determined round-by-round based on prior results"
  }
];
