import styled from "styled-components";
import { COLOR, FONT_WEIGHT, FONT_FAMILY } from "../../../constants/theme";
import breakpoints from "../../../helpers/breakpoints";
import { Link } from "react-router-dom";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 1000px;
  padding: 0px 5px;
  border-radius: 15px;

  background-color: ${COLOR.BG_SECONDARY};
  color: ${COLOR.TEXT};
  flex: 1;
  margin-left: 10px;

  ${breakpoints.down("md")} {
    display: none;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  height: 100%;
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: 10px;
  padding: 0 5px;
  overflow-y: auto;
  background-color: ${COLOR.BG_SECONDARY};
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${COLOR.BG_SECONDARY};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${COLOR.BG_PRIMARY};
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${COLOR.RIVAL_RED};
  }
`;

export const SearchBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  width: 100%;
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  padding: 10px;
  border-radius: 4px;
  ${({ active }) =>
    active
      ? `background-color: ${COLOR.BG_PRIMARY}`
      : `
      background-color: ${COLOR.BG_SECONDARY};
      &:hover {
      cursor: pointer;
      background-color: ${COLOR.BG_ACCENT};
    }`};
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

export const Text = styled.div`
  font: ${FONT_WEIGHT.BOLD} 12px ${FONT_FAMILY.GOTHIC};
`;

export const WrapIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-left: auto;
  margin-right: 10px;

  &:hover {
    opacity: 0.4;
  }
`;

export const Icon = styled.img`
  display: inline-block;
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

export const WrapItem = styled(Link)`
  display: flex;
  width: 100%;
  text-decoration: none;
  font: ${FONT_WEIGHT.NORMAL} 16px ${FONT_FAMILY.GOTHIC};
  color: ${COLOR.TEXT};
`;

export const Placeholder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;

  font: ${FONT_WEIGHT.NORMAL} 14px ${FONT_FAMILY.GOTHIC};
  text-transform: uppercase;
`;

export const Dot = styled.div`
  width: 9px;
  height: 9px;
  margin: 0px 5px;
  border-radius: 50%;
  background-color: ${({ status }) =>
    status === "SUBMITTED" || status === "NOT READY" || status === "COMPLETE"
      ? COLOR.ROUGE
      : status === "PENDING" || status === "CHALLENGED"
      ? COLOR.YELLOW_2
      : COLOR.GREEN};
`;

export const Players = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Player = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: ${({ left }) => (left ? "flex-end" : "flex-start")};
`;

export const Name = styled.div`
  font: ${FONT_WEIGHT.NORMAL} 13px ${FONT_FAMILY.GOTHIC};
`;

export const WrapAvatar = styled.div`
  display: flex;
  position: relative;
  width: 24px;
  height: 24px;
  margin: 0 10px;
`;
export const Avatar = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 2px;
  padding: 1px;
  background-color: ${COLOR.FG_PRIMARY};
`;

export const Badge = styled.div`
  display: flex;
  justify-content: center;

  padding: 2px 10px;
  border-radius: 12px 12px;

  font: ${FONT_WEIGHT.NORMAL} 12px ${FONT_FAMILY.GOTHIC};
  text-transform: uppercase;

  background-color: ${({ status }) => {
    switch (status) {
      case "initialized":
        return COLOR.BLUE;
      case "playing":
        return COLOR.GREEN;
      case "pending":
        return COLOR.YELLOW;
      case "challenged":
        return COLOR.RED;
      case "complete":
        return COLOR.GREY;
      default:
        return COLOR.BG_INPUT;
    }
  }};
`;

export const Outcome = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -8px;
  left: ${({ left }) => (left ? "-10px" : "15px")};
  padding: 2px 4px 1px;

  border-radius: 50px;
  width: 15px;
  height: 15px;

  font: ${FONT_WEIGHT.MEDIUM} 12px / 1 ${FONT_FAMILY.THIN};
  color: ${COLOR.FG_PRIMARY};
  background-color: ${COLOR.BG_INPUT};
`;

export const WrapFinalScore = styled.div`
  display: flex;
  align-items: center;
  font: ${FONT_WEIGHT.NORMAL} 14px ${FONT_FAMILY.THIN};
`;

export const FinalScoreBadge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ singleScore }) => (singleScore ? "35px" : "70px")};
  height: 20px;
  padding: 0 8px;
  border-radius: 10px;
  background-color: ${COLOR.GREY};
`;

export const FinalScoreText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
`;
