import React from "react";
import { RivalToastContainer } from "./styled";
import "./ReactToastify.css";

export const ToastNotifications = () => {
  return (
    <RivalToastContainer
      position={"top-right"}
      autoClose={false}
      hideProgressBar
      closeOnClick={false}
    />
  );
};
