import React from "react";
import {
  Container,
  Header,
  Title,
  Content,
  WrapImageContainer,
  ImageContainer
} from "./styled";
import DialogKit from "../../../components/kit/Dialog/DialogKit";

const InfoDialog = ({ title, images, isOpen, onClose }) => {
  const renderDialog = () => {
    return (
      <Container>
        <Header>
          <Title>{title}</Title>
        </Header>
        <Content>
          {images.map(image => (
            <WrapImageContainer>
              <ImageContainer src={`${image}#toolbar=0`} />
            </WrapImageContainer>
          ))}
        </Content>
      </Container>
    );
  };

  return (
    <DialogKit
      isOpen={isOpen}
      onClose={onClose}
      renderCustom={renderDialog}
      isMobile
    />
  );
};

export default InfoDialog;
