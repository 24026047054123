import React, { Component } from "react";

import {
  Avatar,
  Container,
  Content,
  Message,
  Name,
  WrapInfo,
  WrapName,
  Icon,
  IconPin,
  Time,
  WrapIcon,
  Tooltip,
  Input,
  OrderInput,
  WrapAction,
  Header,
  Title,
  Description,
  Note,
  NavItem,
  Markdown
} from "./styled";
import { connect } from "react-redux";
import moment from "moment";
import placeholderUserImg from "../../../../../../static/images/default-user.jpg";
import iconPencil from "../../../../../../static/icons/icon-pencil.svg";
import iconTrash from "../../../../../../static/icons/icon-trash.svg";
import iconUndelete from "../../../../../../static/icons/undelete.svg";
import iconCheck from "../../../../../../static/icons/circle-check-green.svg";
import iconClose from "../../../../../../static/icons/circle-x-red.svg";
import { api } from "../../../../../..";
import { propValueOr } from "../../../../../../helpers/common";
import { fetchTournamentAction } from "../../../../../../store/tournament/actions";
import ButtonKit from "../../../../../../components/kit/Button/ButtonKit";
import IconGlobe from "../../../../../../static/icons/globe";
import { Row } from "../styled";
import remarkGfm from "remark-gfm";
import { COLOR } from "../../../../../../constants/theme";

class Announcements extends Component {
  constructor(props) {
    super();

    this.state = {
      isEditing: null,
      text: null,
      order: null,
      error: "",
      newAnnouncements: [],
      languageKey: "en",
      languages: [
        { label: "English", key: "en" },
        { label: "Spanish", key: "es" },
        { label: "German", key: "de" },
        { label: "Korean", key: "ko" },
        { label: "Japanese", key: "ja" },
        { label: "French", key: "fr" },
        { label: "Italian", key: "it" },
        { label: "Indian", key: "id" },
        { label: "Portuguese", key: "pt" },
        { label: "Tagalog", key: "tl" },
        { label: "Danish", key: "da" }
      ]
    };
  }

  onSubmit = id => {
    const { tournament } = this.props;
    const { text, order, languageKey } = this.state;
    api.admin.tournament
      .update(tournament?.id, {
        announcements: {
          id,
          message: text || "",
          order,
          ...(languageKey !== "en" && { language: languageKey })
        }
      })
      .then(resp => {})
      .catch(err =>
        this.setState({
          error: propValueOr(
            err,
            "response.message",
            err?.message || "Error editing message"
          )
        })
      )
      .finally(() => this.setState({ text: null, isEditing: null }));
  };

  onTranslate = (all, id) => {
    const { tournament } = this.props;
    const { languageKey } = this.state;

    api.admin.tournament
      .update(tournament?.id, {
        announcements: all
          ? { translateAll: languageKey }
          : { id, translateOne: languageKey }
      })
      .then(resp => {})
      .catch(err =>
        this.setState({
          error: propValueOr(
            err,
            "response.message",
            err?.message || "Error editing message"
          )
        })
      );
    // .finally(() => this.setState({ }));
  };

  onDelete = (id, deleteVal) => {
    const { tournament } = this.props;
    if (typeof id === "number") {
      api.admin.tournament
        .update(tournament?.id, { announcements: { id, deletedAt: deleteVal } })
        .then(resp => {})
        .catch(err =>
          this.setState({
            error: propValueOr(
              err,
              "response.error.message",
              err?.message || "Error deleting message"
            )
          })
        );
    }
  };

  onChangeOrder = e => {
    const val = parseInt(e.target.value);
    if (e.target.value === "" || (val && !isNaN(val) && val > 0)) {
      this.setState({ order: e.target.value === "" ? null : val });
    }
  };

  render() {
    const { user, announcements } = this.props;
    const {
      isEditing,
      text,
      order,
      newAnnouncements,
      languages,
      languageKey
    } = this.state;
    const announcementsList = [
      ...(languageKey === "en" ? newAnnouncements : []),
      ...(announcements || [])
    ];
    const noAnnouncements = !(announcements?.length > 0);
    return (
      <>
        <Header>
          <Title>Announcements</Title>
          <ButtonKit
            small
            appearance={"secondary"}
            shape={"rounded"}
            color={"rival_red"}
            disabled={languageKey !== "en"}
            onClick={() =>
              this.setState({
                isEditing: `new${newAnnouncements.length + 1}`,
                newAnnouncements: [
                  {
                    id: `new${newAnnouncements.length + 1}`,
                    message: "",
                    order: null
                  },
                  ...newAnnouncements
                ]
              })
            }
          >
            Create +
          </ButtonKit>
        </Header>
        <Description>
          Announcements appear on the tournament page above the chat box in
          sequence order.
        </Description>
        <Note>
          How to create hyperlinks: Click [HERE](twitch.com) or
          [TWITCH](https://twitch.com) or [](twitch.com) Note: No spaces between
        </Note>
        <Row style={{ margin: "5px 0" }}>
          {(languages || [])?.map(({ label, key }) => (
            <NavItem
              disabled={!!isEditing}
              active={languageKey === key}
              onClick={() => {
                if (!!isEditing) {
                  return;
                }
                const ann = announcements.find(a => isEditing === a.id);
                this.setState({
                  languageKey: key,
                  ...(!!isEditing &&
                    ann && {
                      text:
                        key === "en"
                          ? ann.message
                          : (ann.translations || {})[key] || ""
                    })
                });
              }}
            >
              {label}
            </NavItem>
          ))}
          <ButtonKit
            style={{ marginLeft: "auto" }}
            small
            disabled={!!isEditing || languageKey === "en" || noAnnouncements}
            shape={"rounded"}
            color={"rival_red"}
            onClick={() => {
              if (noAnnouncements) return;
              if (
                (announcements || []).every(
                  a => !a?.translations?.[languageKey]
                )
              ) {
                this.onTranslate(true);
              } else if (
                window.confirm(
                  `Are you sure? You will overwrite any translations that have been translated and modified from the original.`
                )
              ) {
                this.onTranslate(true);
              }
            }}
          >
            Translate All
          </ButtonKit>
        </Row>
        <Note>
          To translate all announcements for a specific language, click the
          "Translate All" button.
          <br />
          To translate a specific announcement for a specific language, switch
          to the language and click the globe icon for the announcement you want
          to change.
        </Note>
        {/* <Row style={{margin: '5px 0'}}>
          {(languages || [])?.map(({key, label, value}) => <CheckboxKit label={label}
            checked={!!value}
            isBig
            onChange={() =>
              this.setState(({languages: prevLanguages}) => ({languages: prevLanguages.map((item) => item?.key === key ? {...item, value: !item?.value} : item)}))
            }
          />)}
        </Row> */}
        <Container>
          {announcementsList.map((a, index) => (
            <Message
              isAdmin={user.role === "admin"}
              deleted={!!a.deletedAt}
              isEditing={isEditing === a.id}
              key={a.id}
            >
              <Tooltip>
                {languageKey !== "en" && typeof a.id !== "string" && (
                  <WrapIcon
                    onClick={() => {
                      if (!a?.translations?.[languageKey]) {
                        this.onTranslate(false, a.id);
                      } else if (
                        !!a?.translations?.[languageKey] &&
                        window.confirm(
                          `Are you sure? You will overwrite any edits made to this translation with the automated one.`
                        )
                      ) {
                        this.onTranslate(false, a.id);
                      }
                    }}
                    style={{ padding: "0" }}
                  >
                    <IconGlobe width={20} height={20} />
                  </WrapIcon>
                )}
                <WrapIcon
                  onClick={() =>
                    this.setState({
                      isEditing: a.id,
                      text:
                        languageKey === "en"
                          ? a.message
                          : (a.translations || {})[languageKey] || "",
                      order: a.order,
                      error: ""
                    })
                  }
                >
                  <Icon src={iconPencil} />
                </WrapIcon>
                {languageKey === "en" && (
                  <WrapIcon
                    onClick={() => {
                      if (typeof a.id === "string") {
                        this.setState({
                          newAnnouncements: newAnnouncements.filter(
                            na => na.id !== a.id
                          )
                        });
                      } else if (
                        window.confirm(
                          !!a.deletedAt
                            ? "Are you sure you want to un-delete this announcement? It will become visible on the tournament page. You can always undo this action later."
                            : "Are you sure you want to delete this announcement? You can always undo this action later."
                        )
                      ) {
                        this.onDelete(a.id, !a.deletedAt);
                      }
                    }}
                  >
                    <Icon
                      src={!!a.deletedAt ? iconUndelete : iconTrash}
                      scale={!!a.deletedAt}
                    />
                  </WrapIcon>
                )}
              </Tooltip>
              <Avatar
                src={a?.moderator?.imageInfo?.original || placeholderUserImg}
                alt=""
                onError={e => (e.target.src = placeholderUserImg)}
              />
              <Content>
                <WrapInfo deleted={!!a.deletedAt}>
                  {isEditing === a.id && languageKey === "en" ? (
                    <WrapName>
                      <Name
                        style={{ marginRight: "8px", color: COLOR.RIVAL_RED }}
                      >
                        ID#{a.id}
                      </Name>
                      <Name>Order #: </Name>
                      <OrderInput
                        type={"number"}
                        inputProps={{ min: 1 }}
                        value={order}
                        onChange={this.onChangeOrder}
                      />
                    </WrapName>
                  ) : (
                    <WrapName>
                      <Name
                        style={{ marginRight: "8px", color: COLOR.RIVAL_RED }}
                      >
                        ID#{a.id}
                      </Name>
                      <Name>
                        Order #: <span>{a.order || "Not Set"}</span>
                      </Name>
                      <IconPin />
                    </WrapName>
                  )}
                  <Time>
                    {!!a.deletedAt
                      ? `(deleted) ${moment(a.updatedAt || a.createdAt).format(
                          "M/DD/YY @ h:mm A"
                        )}`
                      : moment(a.updatedAt || a.createdAt).format(
                          "M/DD/YY @ h:mm A"
                        )}
                  </Time>
                </WrapInfo>
                {isEditing === a.id ? (
                  <Input
                    type="text"
                    value={text}
                    onChange={e => this.setState({ text: e.target.value })}
                  />
                ) : (
                  <Markdown
                    linkTarget="_blank"
                    remarkPlugins={[[remarkGfm, { singleTilde: false }]]}
                  >
                    {languageKey === "en"
                      ? a.message
                      : (a?.translations || {})[languageKey] ||
                        "<No Translation Yet>"}
                  </Markdown>
                )}
              </Content>
              {isEditing === a.id ? (
                <WrapAction>
                  <WrapIcon
                    onClick={() =>
                      this.setState({
                        isEditing: null,
                        text: a.message
                      })
                    }
                  >
                    <Icon src={iconClose} />
                  </WrapIcon>
                  <WrapIcon onClick={() => this.onSubmit(a.id)}>
                    <Icon src={iconCheck} />
                  </WrapIcon>
                </WrapAction>
              ) : (
                <></>
              )}
            </Message>
          ))}
        </Container>
      </>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user?.info || {},
  announcements: state.tournamentState.tournament?.announcements,
  tournament: state.tournamentState.tournament
});

const mapDispatchToProps = {
  fetchTournamentAction
};

export default connect(mapStateToProps, mapDispatchToProps)(Announcements);
