import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Container,
  Content,
  Header,
  HeaderInfo,
  Info,
  WrapIcons,
  Icon,
  Players,
  Player,
  Avatar,
  Name
} from "./styled";
import {
  fetchGameAction,
  pusherGameAction,
  resetGameAction
} from "../../store/game/actions";
import {
  fetchTournamentAction,
  resetTournamentAction
} from "../../store/tournament/actions";
import PermissionDenied from "../denied/PermissionDenied";
import Chat from "../../components/smart/Chat/Chat";
import { withRouter } from "react-router-dom";
import {
  propValueOr,
  getUsername,
  verifyPermission
} from "../../helpers/common";

// Icons
import crossPlatform from "../../static/icons/cross-platform.svg";
import placeholderUserImg from "../../static/images/default-user.jpg";

class RouteChat extends Component {
  constructor(props) {
    super(props);
    this.channelToken = propValueOr(this.props.match, "params.channel", "");
    if (this.channelToken === "rcc") {
      this.hasPermissions = !!verifyPermission(
        "/global-moderator-chat",
        this.props.permissions
      );
    } else {
      this.hasPermissions = !!verifyPermission(
        "/tournaments/:token",
        this.props.permissions
      );
    }
    if (this.hasPermissions) {
      this.tournamentToken = null;
      this.gameToken = null;
      this.getTokens(this.channelToken);
    }
  }

  getTokens(channelToken) {
    let tokenSplit = [];
    if (channelToken.includes("tournament-")) {
      tokenSplit = channelToken.split("tournament-");
      if (tokenSplit.length > 1) {
        this.tournamentToken = tokenSplit[1];
        this.fetchTournament();
      }
    } else {
      tokenSplit = channelToken.split(".");
      if (tokenSplit[0] !== "rcc" && tokenSplit.length > 1) {
        this.tournamentToken = tokenSplit[0];
        this.gameToken = tokenSplit[1];
        this.fetchGame();
      }
    }
  }

  fetchGame = () => {
    if (!!this.gameToken) {
      this.props
        .fetchGameAction(this.gameToken)
        .then(() => this.fetchTournament());
    }
  };

  fetchTournament = () => {
    if (this.tournamentToken) {
      this.props.fetchTournamentAction(this.tournamentToken);
    }
  };

  render() {
    const { game, tournament } = this.props;
    return (
      <Container>
        {this.hasPermissions ? (
          <Content>
            <Header onClick={() => this.props.toggleCollapse()}>
              <HeaderInfo>
                {!!game.id ? (
                  <Info>
                    <WrapIcons to={`/game/${game.token}`}>
                      <Icon
                        src={propValueOr(game, "Game.imageInfo.icon", null)}
                      />
                      <Icon
                        src={propValueOr(
                          game,
                          "console.imageInfo.icon",
                          crossPlatform
                        )}
                      />
                    </WrapIcons>
                    <Players>
                      {propValueOr(game, "users", []).map((user, i) => (
                        <>
                          <Player>
                            <Avatar
                              src={propValueOr(
                                user,
                                "imageInfo.thumbnail",
                                placeholderUserImg
                              )}
                              onError={e => (e.target.src = placeholderUserImg)}
                            />
                            <Name>{getUsername(user)}</Name>
                          </Player>
                        </>
                      ))}
                    </Players>
                  </Info>
                ) : !!tournament.id ? (
                  <Info>
                    <WrapIcons to={`/tournaments/${tournament.token}`}>
                      <Icon
                        src={propValueOr(
                          tournament,
                          "Game.imageInfo.icon",
                          null
                        )}
                      />
                      <Icon
                        src={propValueOr(
                          tournament,
                          "console.imageInfo.icon",
                          crossPlatform
                        )}
                      />
                    </WrapIcons>
                    <Name>
                      ID {tournament.id}: {tournament.name}
                    </Name>
                  </Info>
                ) : (
                  <Info>
                    <Name>RCC GLOBAL CHAT</Name>
                  </Info>
                )}
              </HeaderInfo>
            </Header>
            <Chat
              key={this.channelToken}
              channel={this.channelToken}
              height={"100%"}
            />
          </Content>
        ) : (
          <PermissionDenied />
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  chats: state.chat.chats,
  game: state.gameState.game,
  tournament: state.tournamentState.tournament
});

const mapDispatchToProps = {
  fetchTournamentAction,
  resetTournamentAction,
  fetchGameAction,
  pusherGameAction,
  resetGameAction
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RouteChat));
