import styled from "styled-components";
import breakpoints from "../../../helpers/breakpoints";
import { COLOR } from "../../../constants/theme";
import { getSubdomainFromHost } from "../../../helpers/common";

export const FooterSD = styled.footer`
  position: relative;
  padding: 30px 0 56px;

  background-color: ${getSubdomainFromHost()
    .toLowerCase()
    .includes("mls")
    ? "#192756"
    : COLOR.BG_FOOTER};

  z-index: 100;
  overflow: hidden;

  ${breakpoints.down("md")} {
    padding: 33px 20px 56px;
  }

  ${breakpoints.down("md")} {
    padding-left: 15px;
    padding-right: 15px;
  }

  ${breakpoints.down("md")} {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

export const FooterContainerSD = styled.div`
  margin: 0 11.93%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  ${breakpoints.down("md")} {
    margin: 0;
  }
`;

export const FooterWrapNavSD = styled.div`
  width: 100%;

  ${breakpoints.down("sm")} {
    order: 5;
  }
`;

export const FooterWrapLogoSD = styled.div`
  ${breakpoints.down("768")} {
    width: 100%;
    margin-bottom: 30px;
  }

  ${breakpoints.down("xs")} {
    display: flex;
    justify-content: center;
  }
`;

export const FooterWrapSubscribeSD = styled.div`
  ${breakpoints.down("768")} {
    width: 100%;
  }
`;

export const FooterWrapSocialsSD = styled.div`
  ${breakpoints.down("sm")} {
    order: 4;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 30px;
  }
`;

export const FooterCopySD = styled.p`
  width: 100%;
  margin: 0;

  color: #b9b2b4;
  font-size: 14px;
  text-align: center;

  ${breakpoints.down("sm")} {
    order: 6;
    font-size: 12px;
  }
`;

export const FooterStripSD = styled.div`
  width: 100%;
  margin: 26px 0 45px;
  border-bottom: 1px solid #343334;

  ${breakpoints.down("md")} {
    margin: 28px -20px 51px;
  }

  ${breakpoints.down("sm")} {
    order: 3;
  }
`;

export const SocialMedia = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1 33.33%;
  margin: 25px 20px;
  a > img {
    &:hover {
      opacity: 0.9;
    }
  }
`;
