import styled from "styled-components";
import { COLOR, FONT_FAMILY, FONT_WEIGHT } from "../../constants/theme";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;

  color: ${COLOR.TEXT};
  background-color: ${COLOR.BG_PRIMARY};

  padding: 0 20px 20px 20px;
`;

export const Title = styled.div`
  font: ${FONT_WEIGHT.BOLD} 20px ${FONT_FAMILY.THIN};
  margin-bottom: 20px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const Text = styled.div`
  font: ${FONT_WEIGHT.NORMAL} 16px ${FONT_FAMILY.THIN};
  color: ${COLOR.TEXT};
  margin-bottom: 30px;
`;

export const WrapDiscordButton = styled.div`
  max-width: 400px;
  padding: 12px 0;
`;
