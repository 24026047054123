import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import RouteMarketplace from "./RouteMarketplace";
import PermissionDenied from "../denied/PermissionDenied";

class RouteMarketplaceContainer extends Component {
  render() {
    const { permission } = this.props;
    if (!permission) {
      return <PermissionDenied />;
    }
    return <RouteMarketplace />;
  }
}

const mapStateProps = state => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateProps,
  mapDispatchToProps
)(withRouter(RouteMarketplaceContainer));
