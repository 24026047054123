import styled from "styled-components";
import { COLOR, FONT_WEIGHT } from "../../../constants/theme";

export const BlankSD = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  min-height: 198px;
  padding: 15px;

  text-transform: uppercase;
`;

export const BlankTitleSD = styled.div`
  color: ${COLOR.TEXT_4};
  font-size: 14px;
  font-weight: ${FONT_WEIGHT.BOLD};
  letter-spacing: 1px;
  text-align: center;
`;
