import styled from "styled-components";
import { COLOR, FONT_FAMILY, FONT_WEIGHT } from "../../../constants/theme";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: ${props => (props.width ? props.width : "320px")};
  height: ${props => (props.height ? props.height : "500px")};
  justify-content: space-between;
  border-radius: 2px;

  width: 100%;
`;

export const WindowHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 5px;
`;

export const WrapPinButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  overflow: hidden;
  padding: 5px;
  padding-right: 15px;
  border-radius: 5px;
  z-index: 100000;
`;

export const PinChatButton = styled.img`
  width: 15px;
  height: 15px;

  cursor: pointer;
  &:hover {
    opacity: 0.4;
  }
`;

export const WindowIcon = styled.img`
  width: 35px;
  height: 35px;
`;

export const Body = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  list-style: none;
  flex: 1;
  padding: 0 10px;
  background-color: ${props => props.theme.body.bgColor};
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${props => props.theme.body.bgColor};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${COLOR.BG_PRIMARY};
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${COLOR.RIVAL_RED};
  }
`;

export const Panel = styled.div`
  padding: 12px;
  background-color: ${props => props.theme.footer.bgColor};
`;

const Text = styled.div`
  color: ${props => props.theme.main.textColor};
  font: ${FONT_WEIGHT.MEDIUM} 11px / 1 ${FONT_FAMILY.THIN};
  text-align: center;
  margin-bottom: 5px;
`;

export const Time = styled(Text)`
  color: ${props => props.theme.main.textColor};
  margin: 10px 0;
  text-transform: capitalize;
`;

export const WrapMessage = styled.div`
  & + & {
    margin: 3px 0;
  }
`;

export const Subtitle = styled(Text)`
  text-transform: capitalize;
`;

export const MessageListContainer = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  list-style: none;
  padding-top: 10px;
  flex: 1;
`;

export const Name = styled.div`
  display: flex;
  align-items: center;
  font: ${FONT_WEIGHT.BOLD} 12px / 1 ${FONT_FAMILY.THIN};
  margin: 2px 0 5px 0;
  color: #222;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
`;

export const Content = styled.div`
  margin-bottom: 10px;
`;

export const Footer = styled(Text)`
  text-transform: capitalize;
  margin-top: 10px;
`;
