import React, { Component } from "react";
import {
  Container,
  Content,
  Header,
  NavBar,
  WrapArrow,
  ArrowIcon,
  NavItem,
  List,
  WrapButton
} from "./styled";
import { propValueOr } from "../../../helpers/common";
import { withRouter } from "react-router";
import ArrowBack from "../../../static/icons/chevron-arrow.svg";
import FormFieldKit from "../../../components/kit/Fields/FormField/FormField";
import ButtonKit from "../../../components/kit/Button/ButtonKit";
import { api } from "../../../index";
import Loader from "../../../components/presentation/Loader/Loader";

class Template extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isUpdating: false,
      isCreating: false,
      created: false
    };
  }

  componentDidMount() {
    this.fetchTemplate();
  }

  fetchTemplate = () => {
    const templateId = propValueOr(this.props.match, "params.id", undefined);
    this.props.fetchTemplate(templateId).then(() => {
      const {
        name,
        description,
        minPlayers,
        maxPlayers,
        autoCreate
      } = this.props.template;
      this.setState({
        name: name,
        description: description,
        minPlayers: minPlayers,
        maxPlayers: maxPlayers,
        autoCreate: autoCreate
      });
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      this.fetchTemplate();
    }
  }

  render() {
    const { template, history, isFetching } = this.props;
    const {
      name,
      description,
      minPlayers,
      maxPlayers,
      autoCreate
    } = this.state;

    if (isFetching) {
      return <Loader />;
    }

    return (
      <Container>
        <Content>
          <Header>
            <NavBar>
              <WrapArrow onClick={() => history.goBack()}>
                <ArrowIcon src={ArrowBack} />
              </WrapArrow>
              <NavItem>{`Template Id #${template.id}: ${template.name}`}</NavItem>
            </NavBar>
          </Header>
          <List>
            <FormFieldKit
              label={"Name of the Tournament"}
              fullWidth
              placeholder={template.name || "Enter new name..."}
              value={name}
              inputProps={{ maxLength: 50 }}
              onChange={val => this.setState({ name: val })}
            />
            <FormFieldKit
              label={"Description"}
              fullWidth
              multiline
              placeholder={template.description || "Write a new description..."}
              value={description}
              onChange={val => this.setState({ description: val })}
            />
            <FormFieldKit
              label={template.teamSize ? "Minimum Teams" : "Minimum Players"}
              fullWidth
              required
              placeholder={
                template.teamSize
                  ? "Enter minimum teams"
                  : "Enter minimum players"
              }
              type={"number"}
              min={"2"}
              max={maxPlayers && maxPlayers.toString()}
              value={minPlayers}
              onChange={val => {
                const newVal = parseInt(val);
                this.setState({
                  minPlayers: isNaN(newVal) ? null : newVal
                });
              }}
              errorText={
                (!minPlayers ||
                  (minPlayers && maxPlayers && minPlayers > maxPlayers)) &&
                "Invalid minimum players"
              }
            />
            <FormFieldKit
              label={template.teamSize ? "Maximum Teams" : "Maximum Players"}
              fullWidth
              placeholder={
                template.teamSize ? "Enter max teams" : "Enter max players"
              }
              type={"number"}
              min={
                ((minPlayers || minPlayers === 0) && minPlayers.toString()) ||
                "2"
              }
              value={maxPlayers}
              onChange={val => {
                const newVal = parseInt(val);
                this.setState({
                  maxPlayers: isNaN(newVal) ? null : newVal
                });
              }}
            />
            <FormFieldKit
              label={"Auto-Create"}
              fullWidth
              placeholder={"Enter number of tournaments to auto create"}
              type={"number"}
              min={
                ((minPlayers || minPlayers === 0) && minPlayers.toString()) ||
                "0"
              }
              disabled={template.type !== "on-the-fly"}
              value={autoCreate}
              onChange={val => {
                const newVal = parseInt(val);
                this.setState({
                  autoCreate: isNaN(newVal) ? null : newVal
                });
              }}
            />
          </List>
          <WrapButton>
            <ButtonKit
              color={"rival_red"}
              shape={"rounded"}
              fullWidth
              preloader={this.state.isUpdating}
              onClick={() => {
                this.setState({ isUpdating: true });
                api.templates
                  .update(template.id, {
                    name,
                    description,
                    minPlayers,
                    maxPlayers,
                    autoCreate
                  })
                  .then(() => {
                    this.setState({ isUpdating: false });
                    this.fetchTemplate();
                  })
                  .catch(() => this.setState({ isUpdating: false }));
              }}
            >
              Update Template
            </ButtonKit>
          </WrapButton>
          {/* <WrapButton>
            <ButtonKit
              color={"rival_red"}
              shape={"rounded"}
              fullWidth
              preloader={this.state.isCreating}
              disabled={created}
              onClick={() => {
                this.setState({ isCreating: true });
                let data = new FormData();
                for (let key in template.options) {
                  if (template.options[key] != null) {
                    data.append(key, template.options[key]);
                  }
                }
                api.admin.tournament
                  .create(data)
                  .then(() => {
                    this.setState({
                      isCreating: false,
                      created: true,
                    });
                  })
                  .catch((err) => {
                    this.setState({
                      isCreating: false,
                      errorMessage: propValueOr(
                        err,
                        "response.data.message",
                        err.message || "Unknown error. Please Try again later."
                      ),
                    });
                  });
              }}
            >
              Create Tournament from Template
            </ButtonKit>
          </WrapButton> */}
        </Content>
      </Container>
    );
  }
}

export default withRouter(Template);
