export const FILTER_OPTIONS = [
  {
    label: "RIVAL USERS",
    value: "user"
  },
  {
    label: "RCC USERS",
    value: "admin"
  }
];
