import React from "react";
import { Container, Image, ImageWrap, Text } from "./styled";
import TypographyKit from "../../../components/kit/Typography/TypographyKit";
import iconEmails from "../../../static/images/emails.png";

const Empty = ({ message }) => {
  return (
    <Container>
      <ImageWrap>
        <Image src={iconEmails} alt={"Emails"} />
      </ImageWrap>
      <Text>
        <TypographyKit variant={"h4"}>{message}</TypographyKit>
      </Text>
    </Container>
  );
};

export default Empty;
