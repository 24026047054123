import React from "react";
import PropTypes from "prop-types";
import { CheckboxRootSD } from "./styled";
import IconChecked from "../../Icon/IconChecked";

function CheckboxKit(props) {
  const {
    className,
    onChange,
    defaultChecked,
    checked,
    value,
    name,
    label,
    disabled,
    onBlur,
    ...restProps
  } = props;

  return (
    <CheckboxRootSD disabled={disabled} className={className} {...restProps}>
      <input
        className="checkbox__input"
        type="checkbox"
        value={value}
        name={name}
        checked={checked}
        defaultChecked={defaultChecked}
        onChange={onChange}
        disabled={disabled}
        onBlur={onBlur}
      />

      <span className="checkbox__icon">
        <IconChecked />
      </span>

      {label != null && <span className="checkbox__text">{label}</span>}
    </CheckboxRootSD>
  );
}

CheckboxKit.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  defaultChecked: PropTypes.bool,
  checked: PropTypes.bool,
  value: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  onBlur: PropTypes.func
};

export default CheckboxKit;
