import PropTypes from "prop-types";
import styled from "styled-components";
import { addUnit } from "../../../helpers/common";

export const SVGIconSD = styled.svg`
  width: ${({ width }) => addUnit(width)};
  height: auto;
`;
SVGIconSD.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};
SVGIconSD.defaultProps = {
  width: 20
};

export const SVGIconPreloaderSD = styled(SVGIconSD)`
  width: ${({ width }) => addUnit(width)};
  height: auto;
`;
SVGIconPreloaderSD.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};
SVGIconPreloaderSD.defaultProps = {
  width: 20
};
