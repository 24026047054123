import { ACTIONS_ACTIONS } from "./constants";

const initialState = {
  actions: [],
  isFetching: false,
  error: null,
  total: 0
};

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS_ACTIONS.list.request:
      return {
        ...state,
        isFetching: true
      };
    case ACTIONS_ACTIONS.list.success:
      return {
        ...state,
        actions: action.payload.docs,
        page: action.payload.page,
        total: action.payload.pages,
        isFetching: false
      };
    case ACTIONS_ACTIONS.list.error:
      return {
        ...state,
        actions: [],
        total: 0,
        isFetching: false,
        error: action.payload
      };
    case ACTIONS_ACTIONS.reset:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default reducers;
