import styled, { css } from "styled-components";
import { COLOR, FONT_FAMILY, FONT_WEIGHT } from "../../../constants/theme";

export const Container = styled.div`
  width: 100%;

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      opacity: 0.6;
    `}
`;
export const Input = styled.input`
  margin-bottom: 5px;
  width: 0;
  height: 0;
  display: none;
`;
export const Label = styled.div`
  text-transform: capitalize;
  font: ${FONT_WEIGHT.MEDIUM} 14px ${FONT_FAMILY.THIN};
  margin-bottom: 7px;
`;

export const Description = styled.div`
  font: ${FONT_WEIGHT.NORMAL} 12px ${FONT_FAMILY.THIN};
  margin-bottom: 14px;
`;

export const ImageTitle = styled.div`
  font: ${FONT_WEIGHT.NORMAL} 14px ${FONT_FAMILY.GOTHIC_BOLD};
  margin: 5px 0px;
`;

export const ConstraintList = styled.ul`
  font: ${FONT_WEIGHT.NORMAL} 12px ${FONT_FAMILY.GOTHIC};
  user-select: text;
  margin: 0px;
  padding-left: 20px;
`;

export const ConstraintItem = styled.li`
  display: flex;
  align-items: center;
  ${({ isValid }) => {
    return isValid === null
      ? `color: ${COLOR.TEXT};`
      : isValid
      ? `color: ${COLOR.LIGHT_GREEN};`
      : `color: ${COLOR.CHERRY_RED};`;
  }}
`;

export const ConstraintIcon = styled.img`
  width: 10px;
  margin-left: 5px;

  & > svg {
    fill: green;
    stroke: green;
  }
`;
export const WrapSpecs = styled.div`
  display: flex;
  align-items: center;
`;

export const Preview = styled.img`
  max-height: 100%;
  max-width: 100%;
  display: inline-block;
`;

export const SelectButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  color: ${COLOR.BG_SECONDARY};
  background-color: ${COLOR.WHITE};

  padding: 10px 15px;
  border-radius: 12px;

  cursor: pointer;
  &:hover {
    opacity: 0.4;
  }
`;

export const SelectText = styled.div`
  text-align: center;
  font: ${FONT_WEIGHT.NORMAL} 12px ${FONT_FAMILY.GOTHIC_BOLD};
  overflow: hidden;
  white-space: wrap;
  text-overflow: ellipsis;
`;

export const PreviewWrap = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  max-width: ${({ width }) => (!!width ? `${width}px` : "400px")};
  height: ${({ height }) => (!!height ? `${height}px` : "220px")};

  border: 1px solid ${COLOR.WHITE};

  margin: 5px 0px 10px 0px;
`;

export const WrapButtons = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;

  > :not(:last-child) {
    margin-right: 5px;
  }
`;
// export const Container = styled.div`

// `;
