import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Content, Header, Title, BackIcon } from "../styled";
import { withRouter } from "react-router-dom";
import FormFieldKit from "../../../components/kit/Fields/FormField/FormField";
import Button from "../../../components/kit/Button/ButtonKit";
import { api } from "../../..";
import TypographyKit from "../../../components/kit/Typography/TypographyKit";
import { COLOR } from "../../../constants/theme";

// Icons
import BackArrow from "../../../static/icons/chevron-arrow.svg";
class UsersCreate extends Component {
  constructor(props) {
    super();

    this.state = {
      name: "",
      email: "",
      username: "",
      password: "",
      creating: false,
      created: false,
      errorEmail: "",
      errorPass: "",
      generalError: "",
      success: ""
    };
  }

  createUser = () => {
    const { name, email, username, password } = this.state;
    this.setState({ creating: true });
    api.admin.users
      .create({
        fullName: name,
        email: email,
        username: username,
        password: password,
        organizationId: this.props.user.organizationId
      })
      .then(() => {
        this.setState({ creating: false, success: "User created!" });
      })
      .catch(err => {
        const data = (err.response && err.response.data) || {};

        if (data.error) {
          this.setState({ generalError: data.error, loading: false });
        }

        if (data.errors) {
          // this case never happens because the form doesn't send if validate is not passing.
          const mapFieldToErrors = {
            email: "errorEmail",
            password: "errorPass",
            name: "errorName",
            username: "errorUsername"
          };
          const newState = data.errors.reduce((acc, item) => {
            acc[mapFieldToErrors[item.path]] = item.message;
            return acc;
          }, {});
          this.setState(newState);
        }
      });
    this.setState({ loading: false });
  };

  isNameValid = () => {
    if (!this.state.name) return false;

    const names = this.state.name.replace(/\s{2,}/, " ").split(" ");

    if (names.length < 2) return false;

    // noinspection RedundantIfStatementJS
    if (names[0].length < 2 || names[1].length < 2) return false;

    return true;
  };

  isEmailValid = () => {
    const pattern = /\b[\w.-]+@[\w.-]+\.\w{2,6}\b/gi;
    return pattern.test(this.state.email);
  };
  isPassValid = () => {
    const { password } = this.state;

    return password.length >= 8;
  };

  isSubmitDisabled = () => {
    if (!this.isEmailValid()) {
      return true;
    }

    if (!this.isNameValid()) {
      return true;
    }

    return !this.isPassValid();
  };
  handleChangeName = name =>
    this.setState({ name, errorName: "", generalError: "", success: "" });
  handleChangeUsername = username =>
    this.setState({ username, errorName: "", generalError: "", success: "" });
  handleChangeEmail = email =>
    this.setState({ email, errorEmail: "", generalError: "", success: "" });
  handleChangePass = password =>
    this.setState({ password, errorPass: "", generalError: "", success: "" });
  handleBlurName = () => {
    if (!this.isNameValid()) {
      this.setState({
        errorName:
          "First Name and Last Name should be spaced with gap and  not less than 2 characters"
      });
    }
  };
  handleBlurEmail = () => {
    if (!this.isEmailValid()) {
      this.setState({ errorEmail: "Enter email address" });
    }
  };
  handleBlurPass = () => {
    if (!this.isPassValid()) {
      this.setState({
        errorPass: "Password length must be 8 character minimum"
      });
    }
  };

  render() {
    const {
      name,
      email,
      username,
      password,
      errorPass,
      errorEmail,
      errorName,
      creating
    } = this.state;
    const { permission } = this.props;
    const readOnly = permission === "read";
    return (
      <Container>
        <Content>
          <Header>
            <BackIcon
              src={BackArrow}
              onClick={() => this.props.history.goBack()}
            />
            <Title>CREATE NEW USER</Title>
          </Header>
          <FormFieldKit
            label={"Name"}
            fullWidth
            placeholder={"Enter name..."}
            required
            value={name}
            disabled={readOnly}
            errorText={errorName}
            onChange={this.handleChangeName}
            onBlur={this.handleBlurName}
          />
          <FormFieldKit
            label={"Rival Platform Chat Name (optional)"}
            fullWidth
            placeholder={"Enter Chat Name (example Mod_RG)..."}
            required
            value={username}
            disabled={readOnly}
            onChange={this.handleChangeUsername}
          />
          <FormFieldKit
            label={
              "Email Address (used for login, password reset, IP address approval)"
            }
            fullWidth
            placeholder={"Enter email address..."}
            required
            value={email}
            disabled={readOnly}
            onChange={this.handleChangeEmail}
            onBlur={this.handleBlurEmail}
            errorText={errorEmail}
          />
          <FormFieldKit
            fullWidth
            label={"Password"}
            placeholder={"Enter password"}
            type="password"
            required
            value={password}
            disabled={readOnly}
            onChange={this.handleChangePass}
            onBlur={this.handleBlurPass}
            errorText={errorPass}
          />
          {this.state.generalError && (
            <TypographyKit color={COLOR.CHERRY_RED}>
              {this.state.generalError}
            </TypographyKit>
          )}
          {this.state.success && (
            <TypographyKit color={COLOR.GREEN}>
              {this.state.success}
            </TypographyKit>
          )}
          {!readOnly && (
            <Button
              onClick={() => this.createUser()}
              fullWidth
              small
              disabled={this.isSubmitDisabled()}
              preloader={creating}
              appearance={"secondary"}
            >
              CREATE USER
            </Button>
          )}
        </Content>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.info
});
const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UsersCreate));
