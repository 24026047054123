import { NOTIFICATIONS_ACTIONS } from "./constants";

const initialState = {
  wasLoaded: false,
  isLoading: false,
  list: [],
  error: null
};

const reducers = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case NOTIFICATIONS_ACTIONS.fetch.success:
      return {
        ...state,
        wasLoaded: true,
        list: payload
      };
    case NOTIFICATIONS_ACTIONS.refresh.request:
      return {
        ...state,
        isLoading: true
      };
    case NOTIFICATIONS_ACTIONS.refresh.success:
      return {
        ...state,
        list: payload,
        isLoading: false
      };
    case NOTIFICATIONS_ACTIONS.refresh.error:
      return {
        ...state,
        isLoading: false,
        error: payload
      };
    case NOTIFICATIONS_ACTIONS.read.success:
      return {
        ...state,
        isLoading: false,
        list: state.list.map(item => (item.id === payload.id ? payload : item))
      };
    case NOTIFICATIONS_ACTIONS.read.error:
      return {
        ...state,
        isLoading: false,
        error: payload
      };
    case NOTIFICATIONS_ACTIONS.readAll.success:
      return {
        ...state,
        list: state.list.map(item => ({ ...item, read: new Date() }))
      };

    default:
      return state;
  }
};

export default reducers;
