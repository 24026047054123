import React, { Component } from "react";
import { connect } from "react-redux";
import {
  WrapPage,
  Content,
  Header,
  HeaderRow,
  Title,
  WrapInfoIcon,
  SubText,
  WrapAsset
} from "./styled";
import InfoDialog from "./InfoDialog/InfoDialog";
import { withRouter } from "react-router-dom";
import Asset from "./Asset/Asset";
import PermissionDenied from "../denied/PermissionDenied";
import { COLOR } from "../../constants/theme";

// Images & Icons
import InfoIcon from "../../static/icons/info-icon.svg";
import LeaderboardPDF from "../../static/images/infoPDFs/leaderboard-info.pdf";
class AssetsLeaderboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isInfoDialogOpen: false
    };
  }

  renderInfoDialog() {
    return (
      <InfoDialog
        isOpen={this.state.isInfoDialogOpen}
        onClose={() => this.setState({ isInfoDialogOpen: false })}
        title={"Leaderboard Image Info"}
        images={[LeaderboardPDF]}
      />
    );
  }

  render() {
    if (!this.props.permission) {
      return <PermissionDenied />;
    }
    return (
      <WrapPage>
        <Content>
          <Header>
            <HeaderRow>
              <Title>Leaderboard</Title>
              <WrapInfoIcon
                src={InfoIcon}
                onClick={() => this.setState({ isInfoDialogOpen: true })}
              />
            </HeaderRow>
            <SubText>All images will be compressed down to 400KB</SubText>
          </Header>
          <>
            {/* <WrapAsset>
                <Asset
                  name="leaderboard-desktop"
                  link={{ notRequired: true }}
                  image
                  imageDimensions={[970, 250]}
                  imgLabels={[
                    "Recommended minimum width of 1550px",
                    "Recommended minimum height of 400px"
                  ]}
                  aspectRatioLimits={[{ value: 3.8 }, { value: 3.9 }]}
                  inputStyles={{ backgroundColor: `${COLOR.BG_SECONDARY}` }}
                />
              </WrapAsset>
              <WrapAsset>
                <Asset
                  name="leaderboard-tablet"
                  link={{ notRequired: true }}
                  image
                  imageDimensions={[730, 90]}
                  imgLabels={[
                    "Recommended minimum width of 730px",
                    "Recommended minimum height of 90px"
                  ]}
                  aspectRatioLimits={[{ value: 8.05 }, { value: 8.15 }]}
                  inputStyles={{ backgroundColor: `${COLOR.BG_SECONDARY}` }}
                />
              </WrapAsset>
              <WrapAsset>
                <Asset
                  name="leaderboard-mobile"
                  link={{ notRequired: true }}
                  image
                  imageDimensions={[320, 50]}
                  imgLabels={[
                    "Recommended minimum width of 640px",
                    "Recommended minimum height of 100px"
                  ]}
                  aspectRatioLimits={[{ value: 6.35 }, { value: 6.45 }]}
                  inputStyles={{ backgroundColor: `${COLOR.BG_SECONDARY}` }}
                />
              </WrapAsset> */}
            <WrapAsset>
              <Asset
                label={"Leaderboard Banner"}
                name="dashboard-banner"
                image
                imageDimensions={[720, 110]}
                imgLabels={["Recommended minimum height of 220px"]}
                aspectRatioLimits={[{ value: 5.75 }, { value: 7.05 }]}
                inputStyles={{ backgroundColor: `${COLOR.BG_SECONDARY}` }}
              />
            </WrapAsset>
          </>
          {this.renderInfoDialog()}
        </Content>
      </WrapPage>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user
});
const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AssetsLeaderboard));
