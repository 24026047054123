import React, { Component } from "react";
import { connect } from "react-redux";
import styled, { css } from "styled-components";
import { FONT_WEIGHT, FONT_FAMILY, COLOR } from "../../../constants/theme";
import * as queryString from "query-string";
import { listActionsAction } from "../../../store/actions/actions";
import { withRouter } from "react-router-dom";
import Pusher from "pusher-js";
import SelectKit from "../../../components/kit/Fields/Select/SelectKit";
import NotificationList from "./NotificationList";
import Pagination from "../../../components/presentation/Pagination/Pagination";
import Loader from "../../../components/presentation/Loader/Loader";
import PermissionDenied from "../../denied/PermissionDenied";
import { getFilterOptions } from "./constants";

const Container = styled.div`
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  min-height: 400px;
  padding: 10px 0;
  font-size: 20px;
  font-weight: 400;
  color: #f2f2f2;
  transition: all 0.25s ease 0s;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 40px;
`;

export const Filter = styled.div`
  display: flex;
  justify-content: flex-end;
  user-select: none;
  width: 100%;
  max-width: 360px;
  min-height: 40px;

  color: ${COLOR.TEXT};
  overflow: hidden;

  border: 2px solid ${COLOR.RIVAL_RED};
  border-radius: 8px;
`;

export const WrapTypeSelect = styled.div`
  display: flex;
  width: 100%;
  max-width: 200px;
`;

const ACTIVE_SWITCHER_ITEM_STYLES = css`
  background-color: ${COLOR.RIVAL_RED};
  color: ${COLOR.WHITE};
`;

const NOT_ACTIVE_SWITCHER_ITEM_STYLES = css`
  background-color: ${COLOR.BG_SECONDARY};
  color: ${COLOR.WHITE};
`;

export const FilterItem = styled.div`
  width: 100%;
  padding: 12px 10px;

  font: ${FONT_WEIGHT.EXTRA_BOLD} 14px ${FONT_FAMILY.GOTHIC_BOLD};
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;

  cursor: pointer;
  transition: all 0.3s;

  ${_IS_ACTIVE_SWITCHER_ITEM_};

  &:hover {
    ${ACTIVE_SWITCHER_ITEM_STYLES};
  }

  &:not(:first-child) {
    margin-left: -1px;
  }
`;

function _IS_ACTIVE_SWITCHER_ITEM_({ isActive }) {
  return isActive
    ? ACTIVE_SWITCHER_ITEM_STYLES
    : NOT_ACTIVE_SWITCHER_ITEM_STYLES;
}

class ListContainer extends Component {
  constructor(props) {
    super(props);

    this.pusher = new Pusher(process.env.REACT_APP_PUSHER_ID || "", {
      cluster: "us3",
      forceTLS: true
    });

    const query = this.getQueryParams();

    this.state = {
      message: query.message || "all",
      status: query.status || "open",
      search: query.q || ""
    };
  }

  componentDidMount() {
    const channel = this.pusher.subscribe("actions");

    channel.bind("new-action", () => this.fetchActions());

    this.fetchActions();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.search !== this.props.location.search) {
      this.fetchActions();
    }
  }

  getQueryParams() {
    const parsed = queryString.parse(this.props.location.search);

    return {
      status: parsed.status || "open",
      message: parsed.message || "all",
      organizationIds: [this.props.user.organizationId],
      q: parsed.q,
      page: parsed.page || 1,
      limit: 10
    };
  }

  fetchActions() {
    this.props.fetchActions(this.getQueryParams());
  }

  handleFilterTypeChange(message) {
    const oldSearch = queryString.parse(this.props.location.search);
    const newSearch = `?${queryString.stringify({
      ...oldSearch,
      message,
      page: 1
    })}`;
    this.props.history.push({ search: newSearch });
    this.setState({ message });
  }

  handleStatusChange(status) {
    const oldSearch = queryString.parse(this.props.location.search);
    const newSearch = `?${queryString.stringify({
      ...oldSearch,
      status,
      page: 1
    })}`;
    this.props.history.push({ search: newSearch });
    this.setState({ status });
  }

  render() {
    const { permissions, permission, actions, total, isFetching } = this.props;
    const { status, message } = this.state;
    if (!permission) {
      return <PermissionDenied />;
    }
    return (
      <Container>
        <Header>
          <Filter>
            <FilterItem
              key={"open"}
              isActive={status === "open"}
              onClick={() => this.handleStatusChange("open")}
            >
              OPEN
            </FilterItem>
            <FilterItem
              key={"closed"}
              isActive={status === "closed"}
              onClick={() => this.handleStatusChange("closed")}
            >
              CLOSED
            </FilterItem>
          </Filter>
          <WrapTypeSelect>
            <SelectKit
              fullWidth
              value={message}
              options={getFilterOptions(permissions)}
              onChange={val => this.handleFilterTypeChange(val)}
            />
          </WrapTypeSelect>
        </Header>
        {isFetching ? (
          <Loader isBlock />
        ) : (
          <NotificationList
            actions={actions}
            fetchActions={() => this.props.fetchActions(this.getQueryParams())}
          />
        )}
        <Pagination withUrl total={total} />
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.info,
  permissions: state.permissionsState.permissions,
  actions: state.actionState.actions,
  total: state.actionState.total,
  isFetching: state.actionState.isFetching
});

const mapDispatchToProps = {
  fetchActions: listActionsAction
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ListContainer));
