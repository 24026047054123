import axios from "axios";
import { USER_ACTIONS } from "../store/user/constants";
import { parseJwt, propValueOr } from "../helpers/common";
import { setAuthorizedUserInfoAction } from "../store/user/actions";
import { buildFilter } from "./util";

export default class API {
  constructor(store, options = {}) {
    this.store = store;
    this.client =
      options.client ||
      axios.create({
        baseURL: process.env.REACT_APP_DEVELOPMENT + "api/v1/"
      });

    this.token = options.token || window.localStorage.getItem("jwt");
    this.refreshToken =
      options.refreshToken || window.localStorage.getItem("jwt_refresh");

    this.client.interceptors.request.use(
      config => {
        if (!this.token) {
          return config;
        }

        const newConfig = {
          headers: {},
          ...config
        };

        newConfig.headers.Authorization = `Bearer ${this.token}`;

        return newConfig;
      },
      err => Promise.reject(err)
    );

    this.client.interceptors.response.use(
      res => res,
      err => {
        if (propValueOr(err, "response.status", null) === 500) {
          this.store.dispatch({
            type: USER_ACTIONS.request.status,
            payload: { status: err.response.status }
          });
        }

        if (
          !this.refreshToken ||
          (err.response && err.response.status !== 401) ||
          err.config.retry
        ) {
          throw err;
        }

        return this.updateTokens(err.config);
      }
    );
  }

  setTokens = (token, refreshToken) => {
    this.token = token;
    this.refreshToken = refreshToken;
    window.localStorage.setItem("jwt", token);
    window.localStorage.setItem("jwt_refresh", refreshToken);

    // update user information in the redux store.
    this.store.dispatch(setAuthorizedUserInfoAction(parseJwt(token)));
  };

  login({ username, password, fingerprint }) {
    return this.client.post(`admin/login`, {
      username,
      password,
      fingerprint
    });
  }

  logout() {
    this.token = null;
    this.refreshToken = null;
    window.localStorage.removeItem("jwt");
    window.localStorage.removeItem("jwt_refresh");
    window.location.replace("/login");
  }

  registration(body) {
    return this.client.post("user", body).then(resp => {
      const { data } = resp;
      this.setTokens(data["access_token"], data["refresh_token"]);
      return resp;
    });
  }

  updateUser(body) {
    return this.client.put("admin/user", body).then(resp => {
      const { data } = resp;
      window.localStorage.setItem("jwt", data["access_token"]);

      return resp;
    });
  }

  deleteAccount() {
    return this.client.delete("user");
  }

  ipAddress({ key, fingerprint }) {
    return this.client.post("admin/ip_address", { key, fingerprint });
  }

  /* Admin APIs */
  admin = {
    team: {
      get: async (slug, q) => {
        return this.client.get(`admin/teams/${slug}${q || ""}`);
      }
    },
    tournament: {
      get: async token => {
        return this.client.get(`admin/tournaments/${token}`);
      },
      create: async body => {
        return this.client.post(`admin/tournaments`, body);
      },
      update: async (id, body) => {
        return this.client.put(`admin/tournaments/${id}`, body);
      },
      discord: async id => {
        return this.client.put(`admin/tournaments/${id}/discord`);
      },
      delete: async id => {
        return this.client.delete(`admin/tournaments/${id}`);
      },
      report: async orgId => {
        return this.client.post(`admin/tournaments/${orgId}/report`);
      },
      users: {
        add: async (id, query) => {
          return this.client.put(
            `admin/tournaments/${id}/users${query &&
              "?userIds=" + query.join(",")}`
          );
        },
        addTeam: async (id, userId, body) => {
          return this.client.put(
            `admin/tournaments/${id}/users/${userId}`,
            body
          );
        },
        remove: async (id, query) => {
          return this.client.delete(
            `admin/tournaments/${id}/users${query &&
              "?userIds=" + query.join(",")}`
          );
        },
        update: async (id, body) => {
          return this.client.post(`admin/tournaments/${id}/users`, body);
        },
        list: async id => {
          return this.client.get(`admin/tournaments/${id}/users`);
        }
      }
    },
    matches: {
      list: async query => {
        return this.client.get(`admin/matches?${buildFilter(query)}`);
      },
      update: async (token, body) => {
        return this.client.put(`admin/matches/${token}`, body);
      }
    },
    matchTemplates: {
      get: async () => {
        return this.client.get("admin/matchTemplates");
      },
      update: async (id, body) => {
        return this.client.put(`admin/matchTemplates/${id}`, body);
      }
    },
    organizations: {
      add: async (userId, body) => {
        return this.client.post(`admin/organizations/${userId}`, body);
      },
      delete: async (id, query) => {
        return this.client.delete(
          `admin/organizations/${id}?${buildFilter(query)}`
        );
      },
      list: async () => {
        return this.client.get(`admin/organizations`);
      },
      search: async query => {
        return this.client.get(
          `admin/organizations/search?${buildFilter(query)}`
        );
      },
      fetch: async orgId => {
        return this.client.get(`admin/organizations/${orgId}`);
      },
      report: async orgId => {
        return this.client.post(`admin/organizations/${orgId}/report`);
      },
      update: async (orgId, body) => {
        return this.client.put(`admin/organizations/${orgId}`, body);
      },
      create: async body => {
        return this.client.post(`admin/organizations`, body);
      },
      groups: {
        create: async (orgId, body) => {
          return this.client.post(`admin/organizations/${orgId}/groups`, body);
        },
        update: async (orgId, groupId, body) => {
          return this.client.put(
            `admin/organizations/${orgId}/groups/${groupId}`,
            body
          );
        }
      }
    },
    permissions: {
      list: async query => {
        return this.client.get(`admin/permissions?${buildFilter(query)}`);
      },
      add: async (userId, body) => {
        return this.client.post(`admin/permissions/${userId}`, body);
      },
      update: async (id, body) => {
        return this.client.put(`admin/permissions/${id}`, body);
      },
      delete: async id => {
        return this.client.delete(`admin/permissions/${id}`);
      },
      search: async query => {
        return this.client.get(
          `admin/permissions/search?${buildFilter(query)}`
        );
      }
    },
    users: {
      get: async id => {
        return this.client.get(`admin/users/${id}`);
      },
      update: async (id, body) => {
        return this.client.put(`admin/users/${id}`, body);
      },
      create: async body => {
        return this.client.post(`admin/users`, body);
      },
      list: async query => {
        return this.client.get(`admin/users?${buildFilter(query)}`);
      },
      upload: async (id, body) => {
        return this.client.post(`admin/users/${id}/upload`, body);
      },
      confirmIdentity: async (id, body) => {
        return this.client.post(`admin/users/${id}/confirmIdentity`, body);
      }
    },
    history: {
      list: async (userId, query) => {
        return this.client.get(
          `admin/userHistory/${userId}?${buildFilter(query)}`
        );
      }
    },
    ips: {
      list: async userId => {
        return this.client.get(`admin/users/ip/${userId}`);
      }
    },
    consoles: {
      get: async () => {
        return this.client.get("admin/consoles");
      }
    }
  };

  /* Asset APIs */
  assets = {
    list: async query => {
      return this.client.get(`assets?${buildFilter(query)}`);
    },
    update: async body => {
      return this.client.post("assets", body);
    },
    delete: async id => {
      return this.client.delete(`assets/${id}`);
    }
  };

  /* Config APIs */
  config = {
    get: async slug => {
      return this.client.get(`config/${slug}`);
    }
  };

  /* Match APIs */
  match = {
    get: async (token, body) => {
      return this.client.post(`admin/matches/${token}`, body);
    }
  };

  /* Challenge APIs */
  challenge = {
    get: async token => {
      return this.client.get(`admin/challenges/${token}`);
    },
    update: async (token, body) => {
      return this.client.post(`admin/challenges/${token}`, body);
    },
    list: async query => {
      return this.client.get(`admin/challenges?${buildFilter(query)}`);
    }
  };

  matchTemplates = {
    get: async () => {
      return this.client.get("admin/matchTemplates");
    },
    update: async (id, body) => {
      return this.client.put(`admin/matchTemplates/${id}`, body);
    }
  };

  games = {
    create: async body => {
      return this.client.post("admin/games/", body);
    },
    fetch: async id => {
      return this.client.get(`admin/games/${id}`);
    },
    update: async (id, body) => {
      return this.client.put(`admin/games/${id}`, body);
    },
    list: async () => {
      return this.client.get(`admin/games`);
    }
  };

  series = {
    create: async body => {
      return this.client.post("admin/gameseries/", body);
    },
    update: async (id, body) => {
      return this.client.put(`admin/gameseries/${id}`, body);
    },
    list: async () => {
      return this.client.get(`admin/gameseries`);
    }
  };

  matches = {
    fetch: async token => {
      return this.client.get(`admin/matches/${token}`);
    },
    list: async query => {
      return this.client.get(`admin/matches?${buildFilter(query)}`);
    }
  };

  /* Action APIs */
  action = {
    list: async body => {
      return this.client.post(`admin/actions`, body);
    },
    close: async actionId => {
      return this.client.put(`admin/actions/${actionId}`);
    }
  };

  notifications = {
    get: async () => {
      return this.client.get("notifications");
    },
    read: async id => {
      return this.client.put(`/notifications/${id}`);
    },
    readAll: async () => {
      return this.client.put("notifications");
    }
  };

  /* Teams APIs */
  team = {
    create: async body => {
      return this.client.post("teams", body);
    },
    get: async slug => {
      return this.client.get(`teams/${slug}`);
    },
    list: async query => {
      return this.client.get(`teams?${buildFilter(query)}`);
    },
    invites: {
      list: async (slug, query) => {
        return this.client.get(`teams/${slug}/invites?${buildFilter(query)}`);
      },
      create: async (slug, query, body) => {
        return this.client.post(
          `teams/${slug}/invites?${buildFilter(query)}`,
          body
        );
      },
      update: async (slug, token, body) => {
        return this.client.put(`teams/${slug}/invites/${token}`, body);
      }
    },
    users: {
      list: async (slug, query) => {
        return this.client.get(`teams/${slug}/users?${buildFilter(query)}`);
      },
      remove: async (slug, id) => {
        return this.client.delete(`teams/${slug}/users/${id}`);
      }
    }
  };

  /* Template APIs */
  templates = {
    get: async id => {
      return this.client.get(`admin/tournamentTemplates/${id}`);
    },
    update: async (id, body) => {
      return this.client.put(`admin/tournamentTemplates/${id}`, body);
    },
    list: async query => {
      return this.client.get(`admin/tournamentTemplates?${buildFilter(query)}`);
    }
  };

  /* Tournament APIs */
  tournament = {
    get: async token => {
      return this.client.get(`tournaments/${token}`);
    },
    join: async (token, body) => {
      return this.client.post(`tournaments/${token}/join`, body);
    },
    update: async (token, body) => {
      return this.client.post(`tournaments/${token}`, body);
    },
    list: async query => {
      return this.client.get(`admin/tournaments?${buildFilter(query)}`);
    },
    teams: {
      replace: async (token, teamId, body) => {
        return this.client.put(`tournaments/${token}/teams/${teamId}`, body);
      }
    }
  };

  league = {
    get: async token => {
      return this.client.get(`admin/leagues/${token}`);
    },
    create: async body => {
      return this.client.post(`admin/leagues`, body);
    },
    list: async query => {
      return this.client.get(`admin/leagues?${buildFilter(query)}`);
    },
    update: async (slug, body) => {
      return this.client.put(`admin/leagues/${slug}`, body);
    }
  };

  season = {
    get: async token => {
      return this.client.get(`admin/seasons/${token}`);
    },
    create: async body => {
      return this.client.post(`admin/seasons`, body);
    },
    update: async (token, body) => {
      return this.client.put(`admin/seasons/${token}`, body);
    },
    list: async query => {
      return this.client.get(`admin/seasons?${buildFilter(query)}`);
    },
    users: {
      add: async (token, query) => {
        return this.client.put(
          `admin/seasons/${token}/users${query &&
            "?userIds=" + query.join(",")}`
        );
      },
      addTeam: async (token, userId, body) => {
        return this.client.put(`admin/seasons/${token}/users/${userId}`, body);
      },
      remove: async (token, userId) => {
        return this.client.delete(`admin/seasons/${token}/users/${userId}`);
      }
      // update: async (id, body) => {
      //   return this.client.post(`admin/tournaments/${id}/users`, body);
      // },
      // list: async id => {
      //   return this.client.get(`admin/tournaments/${id}/users`);
      // }
    }
  };

  user = {
    update: async body => {
      return this.client.put("admin/user", body).then(resp => {
        const { data } = resp;
        window.localStorage.setItem("jwt", data["access_token"]);

        return resp;
      });
    }
  };

  /* Organization APIs */
  organization = {
    get: async query => {
      return this.client.get(`organizations${query}`);
    },
    games: {
      list: async id => {
        return this.client.get(`admin/organizations/${id}/games/${id}`);
      },
      add: async (id, gameId) => {
        return this.client.post(`admin/organizations/${id}/games`, {
          organizationId: id,
          gameId
        });
      },
      remove: async (orgId, orgGameId) => {
        return this.client.delete(
          `admin/organizations/${orgId}/games/${orgGameId}`
        );
      }
    }
  };

  /* Wallet APIs */
  wallet = {
    transaction: async id => {
      return this.client.get(`admin/wallet/transactions/${id}`);
    },
    update: async id => {
      return this.client.put(`admin/wallet/transactions/${id}`);
    },
    transactions: async query => {
      return this.client.get(`admin/wallet/transactions?${buildFilter(query)}`);
    },
    stats: async () => {
      return this.client.get(`admin/wallet/stats`);
    },
    list: async query => {
      return this.client.get(`admin/wallet/wallets?${buildFilter(query)}`);
    },
    fetch: async id => {
      return this.client.get(id ? `admin/wallet/${id}` : `admin/wallet`);
    },
    transfer: async body => {
      return this.client.post(`admin/wallet`, body);
    },
    search: async query => {
      return this.client.get(`admin/wallet/search?${buildFilter(query)}`);
    }
  };

  updateTokens(lastRequestConfig) {
    // if some requests fire together
    if (!this.refreshRequest) {
      this.refreshRequest = this.client.post("admin/refresh_token", {
        refresh_token: this.refreshToken
      });
    }

    return this.refreshRequest
      .then(resp => {
        this.setTokens(
          propValueOr(resp, "data.access_token"),
          propValueOr(resp, "data.refresh_token")
        );

        this.refreshRequest = null;

        if (!lastRequestConfig) {
          return;
        }

        const repeatedRequest = {
          ...lastRequestConfig,
          retry: true
        };

        return this.client(repeatedRequest);
      })
      .catch(() => {
        this.logout();
      });
  }

  search(searchString, limit = 5, page = 1, users, consoleId) {
    return this.client.get("search", {
      params: {
        q: searchString,
        limit,
        page,
        users,
        ...(consoleId && { consoleId })
      }
    });
  }

  searchUsers(query) {
    return this.client.get(`search?${buildFilter(query)}`);
  }

  searchRecipients(query) {
    return this.client.get(`cc/recipients?q=${query}`);
  }

  // Communication center

  getRecipients(type = "organization", id) {
    return this.client.get(`cc/recipients/${type}/${id}`);
  }

  searchEmails(type, query) {
    return this.client.get(`cc/${type}?q=${query}`);
  }

  uploadAttachments(body) {
    return this.client.post(`cc/attachment`, body);
  }

  getS3ImagePolicy(url, fileName) {
    return this.client
      .post(url, {
        file: fileName
      })
      .then(res => res.data);
  }

  uploadS3File(file, policy, onUploadProgress) {
    const formData = new FormData();

    const params = {
      key: policy.folder + policy.filename,
      AWSAccessKeyId: policy.s3key,
      acl: policy.acl,
      policy: policy.policy,
      signature: policy.signature,
      "Content-Type": policy.mimetype,
      file
    };

    for (const [key, value] of Object.entries(params)) {
      formData.append(key, value);
    }

    return axios
      .post(policy.bucket_url, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        },
        onUploadProgress
      })
      .then(resp => resp);
  }

  getScheduledEmails() {
    return this.client.get(`cc/scheduled`);
  }

  createScheduledEmail(email) {
    return this.client.post(`cc/scheduled`, email);
  }

  updateScheduledEmail(email) {
    return this.client.put(`cc/scheduled/${email.id}`, email);
  }

  deleteScheduledEmail(id) {
    return this.client.delete(`cc/scheduled/${id}`);
  }

  getDraftsEmails() {
    return this.client.get(`cc/drafts`);
  }

  updateDraftsEmail(email) {
    return this.client.put(`cc/drafts/${email.id}`, email);
  }

  deleteDraftsEmail(id) {
    return this.client.delete(`cc/drafts/${id}`);
  }

  createDraftsEmail(email) {
    return this.client.post(`cc/drafts`, email);
  }

  getSentEmails() {
    return this.client.get(`cc/sent`);
  }

  createGlobalTemplatesEmail(email) {
    return this.client.post(`cc/global_templates`, email);
  }

  createTemplatesEmail(email) {
    return this.client.post(`cc/templates`, email);
  }

  getTemplatesEmails() {
    return this.client.get(`cc/templates`);
  }

  getGlobalTemplatesEmails() {
    return this.client.get(`cc/global_templates`);
  }

  updateTemplatesEmail(email) {
    return this.client.put(`cc/templates/${email.id}`, email);
  }

  updateGlobalTemplatesEmail(email) {
    return this.client.put(`cc/global_templates/${email.id}`, email);
  }

  deleteTemplatesEmail(id) {
    return this.client.delete(`cc/templates/${id}`);
  }

  deleteGlobalTemplatesEmail(id) {
    return this.client.delete(`cc/global_templates/${id}`);
  }
}
