import ORG_GAMES_ACTIONS from "./constants";

const initialState = {
  games: [],
  error: null,
  isFetching: false
};

const reducers = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ORG_GAMES_ACTIONS.list.request:
      return {
        ...state,
        isFetching: true
      };
    case ORG_GAMES_ACTIONS.list.success:
      return {
        ...state,
        games: action.payload,
        isFetching: false,
        error: null
      };
    case ORG_GAMES_ACTIONS.list.error:
      return {
        ...state,
        games: [],
        isFetching: false,
        error: payload
      };
    case ORG_GAMES_ACTIONS.reset:
      return initialState;
    default:
      return state;
  }
};

export default reducers;
