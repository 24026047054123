import React from "react";
import { SVGIconSD } from "./styled";
import PropTypes from "prop-types";

function IconThreeDots(props) {
  return (
    <SVGIconSD width={props.width} viewBox="0 0 4 16">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-1068.000000, -514.000000)" fill="#FFFFFF">
          <g transform="translate(1068.000000, 514.000000)">
            <path
              d="M2,4 C3.1,4 4,3.1 4,2 C4,0.9 3.1,0 2,0 C0.9,0 0,0.9
              0,2 C0,3.1 0.9,4 2,4 L2,4 Z M2,6 C0.9,6 0,6.9 0,8 C0,9.1 0.9,10
              2,10 C3.1,10 4,9.1 4,8 C4,6.9 3.1,6 2,6 L2,6 Z M2,12 C0.9,12 0,12.9
              0,14 C0,15.1 0.9,16 2,16 C3.1,16 4,15.1 4,14 C4,12.9
              3.1,12 2,12 L2,12 Z"
            />
          </g>
        </g>
      </g>
    </SVGIconSD>
  );
}

IconThreeDots.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default IconThreeDots;
