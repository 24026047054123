import styled from "styled-components";
import { COLOR, FONT_FAMILY, FONT_WEIGHT } from "../../../../constants/theme";

export const RadioButtonLabelTextSD = styled.span`
  display: inline-block;
  vertical-align: middle;

  color: ${COLOR.TEXT_3};
  font: ${FONT_WEIGHT.MEDIUM} 14px / 16px ${FONT_FAMILY.THIN};
`;

export const RadioButtonSD = styled.label`
  position: relative;
  display: inline-block;
  cursor: pointer;

  input {
    appearance: none;
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;

    border: 2px solid ${COLOR.GREY};
    border-radius: 50px;

    vertical-align: middle;

    &:after {
      content: "";
      position: absolute;
      top: 3px;
      left: 3px;

      width: 10px;
      height: 10px;

      background-color: ${COLOR.GREY};
      border-radius: 50px;

      opacity: 0;
    }

    &:focus {
      outline: none;
      border-color: ${COLOR.ROUGE};
    }

    &:checked {
      border-color: ${COLOR.ROUGE};

      &:after {
        background-color: ${COLOR.ROUGE};
        opacity: 1;
      }
    }

    &:disabled,
    &:disabled ~ ${RadioButtonLabelTextSD} {
      opacity: 0.6;
    }
  }

  ${RadioButtonLabelTextSD} {
    margin-left: 10px;
  }
`;
