import { TEAM_ACTIONS } from "./constants";

const initialState = {
  team: {},
  list: [],
  total: 1,
  fetching: false
};

const reducers = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case TEAM_ACTIONS.fetch.request:
      return {
        ...state,
        fetching: true
      };
    case TEAM_ACTIONS.fetch.success:
      return {
        ...state,
        team: payload,
        fetching: false
      };
    case TEAM_ACTIONS.fetch.error:
      return {
        ...state,
        fetching: false
      };
    case TEAM_ACTIONS.reset:
      return initialState;
    default:
      return state;
  }
};

export default reducers;
