import styled from "styled-components";

import { COLOR, FONT_FAMILY, FONT_WEIGHT } from "../../constants/theme";
import page404bg from "../../static/images/404/page-bg.svg";
import breakpoints from "../../helpers/breakpoints";

export const Route404SD = styled.div`
  min-height: 100%;
  padding: 12.57% 0 11.41%;
  position: relative;
  width: 100%;

  ${breakpoints.down(1432)} {
    padding: 20px 0 414px;
  }

  ${breakpoints.down(810)} {
    padding: 20px 0 0;
  }
`;

export const Route404BackgroundImage = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 1020px;
  height: 673px;
  
  background: url("${page404bg}") right 0 no-repeat;
  background-size: 101%;
  
  pointer-events: none;
  
  
  ${breakpoints.down(810)} {
    position:relative;
    width: 100%;
    height: 0;
    padding-bottom: 65.9804%;
  }
`;

export const Route404ContentSD = styled.div`
  position: relative;
  max-width: 407px;
  margin-left: 11.11111%;

  text-align: center;
  z-index: 1;

  ${breakpoints.down("xs")} {
    width: 94%;
    margin: 10px auto;
  }
`;

export const Route404ErrorSD = styled.div`
  color: ${COLOR.NAVY_BLUE};
  font: ${FONT_WEIGHT.BOLD} 200px / 1.2 ${FONT_FAMILY.THIN};
  letter-spacing: -3.71px;
  text-shadow: 11px 0 0 ${COLOR.GREY_2};

  ${breakpoints.down("xs")} {
    margin-bottom: 17px;

    font-size: 100px;
    letter-spacing: -1.85px;
    text-shadow: 5px 0 0 ${COLOR.GREY_2};
  }
`;

export const Route404CaptionSD = styled.div`
  margin-bottom: 18px;

  color: ${COLOR.TEXT_3};
  font: ${FONT_WEIGHT.BOLD} 26px / 1 ${FONT_FAMILY.THIN};
  letter-spacing: 0.23px;

  ${breakpoints.down("xs")} {
    margin-bottom: 2px;
    font-size: 16px;
  }
`;

export const Route404TextSD = styled.div`
  margin-bottom: 23px;

  color: #b3b3b3;
  font: ${FONT_WEIGHT.BOLD} 16px / 1 ${FONT_FAMILY.THIN};

  ${breakpoints.down("xs")} {
    margin-bottom: 37px;
    font-size: 14px;
  }
`;
