import React from "react";
import NavFooter from "./NavFooter";
import {
  FooterSD,
  FooterContainerSD,
  FooterCopySD,
  FooterWrapNavSD
} from "./styled";

function Footer(props) {
  return (
    <FooterSD>
      <FooterContainerSD>
        <FooterWrapNavSD>
          <NavFooter />
        </FooterWrapNavSD>

        <FooterCopySD>Powered by Rival Games All Rights Reserved.</FooterCopySD>
      </FooterContainerSD>
    </FooterSD>
  );
}

Footer.propTypes = {};

export default Footer;
