import React from "react";
import PropTypes from "prop-types";

import DialogKit from "../../kit/Dialog/DialogKit";
import {
  EmailDialogSuccessBodySD,
  EmailDialogSuccessDateSD,
  EmailDialogSuccessFooterSD,
  EmailDialogSuccessHeaderSD,
  EmailDialogSuccessIconSD,
  EmailDialogSuccessTextSD,
  EmailDialogSuccessTitleSD,
  EmailDialogSuccessWrapIconSD
} from "./styled";

import iconCheck from "../../../static/icons/circle-check-green.svg";
import ButtonKit from "../../kit/Button/ButtonKit";
import { dateToSting } from "../../../helpers/date";

const EmailDialogSuccess = ({
  isOpen,
  onClose,
  onConfirm,
  confirmText,
  ...props
}) => {
  const renderHeader = () => (
    <EmailDialogSuccessHeaderSD>
      <EmailDialogSuccessTitleSD>{props.title}</EmailDialogSuccessTitleSD>
      <EmailDialogSuccessWrapIconSD>
        <EmailDialogSuccessIconSD src={iconCheck} alt={"check"} />
      </EmailDialogSuccessWrapIconSD>
    </EmailDialogSuccessHeaderSD>
  );

  const renderBody = () => (
    <EmailDialogSuccessBodySD>
      <EmailDialogSuccessTextSD>{props.text}</EmailDialogSuccessTextSD>
      {props.date ? (
        <EmailDialogSuccessDateSD>
          {dateToSting(props.date, "M/DD/YY @ h:mmA", true)}
        </EmailDialogSuccessDateSD>
      ) : null}
    </EmailDialogSuccessBodySD>
  );

  const renderFooter = () =>
    confirmText ? (
      <EmailDialogSuccessFooterSD>
        <ButtonKit appearance={"secondary"} fullWidth onClick={onConfirm}>
          {confirmText}
        </ButtonKit>
      </EmailDialogSuccessFooterSD>
    ) : null;

  return (
    <div>
      <DialogKit
        isOpen={isOpen}
        onClose={onClose}
        renderHeader={renderHeader}
        renderBody={renderBody}
        renderFooter={renderFooter}
        displayButtonClose={false}
      />
    </div>
  );
};

EmailDialogSuccess.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.node,
  text: PropTypes.node
};

EmailDialogSuccess.defaultProps = {
  title: "",
  text: ""
};

export default EmailDialogSuccess;
