import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 35px 0 130px;
`;

export const ImageWrap = styled.div`
  width: 132px;
  margin: auto 0 42px;
`;

export const Image = styled.img`
  display: block;
  max-width: 100%;
`;

export const Text = styled.div`
  text-align: center;
`;
