import styled from "styled-components";
import { COLOR, FONT_WEIGHT, FONT_FAMILY } from "../../../../constants/theme";
import breakpoints from "../../../../helpers/breakpoints";

export const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  max-height: 60px;
  min-height: 60px;
  min-width: 130px;
  ${breakpoints.down("xs")} {
    display: none;
  }
`;

export const Left = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  padding-left: 5px;
`;

export const Right = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  padding-right: 5px;
`;

export const Avatar = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 2px;
  padding: 1px;
  background-color: ${COLOR.FG_PRIMARY};
  margin: 0 5px;
`;

export const Score = styled.div`
  font: ${FONT_WEIGHT.MEDIUM} 22px / 18px ${FONT_FAMILY.THIN};
  letter-spacing: -0.29px;
  color: ${COLOR.FG_PRIMARY};
  margin: 0 5px;
  min-width: 50px;
  text-align: center;
  ${breakpoints.down("sm")} {
    display: none;
  }
`;

export const Outcome = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font: ${FONT_WEIGHT.MEDIUM} 18px / 18px ${FONT_FAMILY.THIN};
  letter-spacing: -0.29px;
  color: ${COLOR.FG_PRIMARY};
  margin: 0 5px;
  border-radius: 2px;
  background-color: ${({ outcome }) =>
    outcome === "W"
      ? COLOR.GREEN
      : outcome === "T"
      ? COLOR.YELLOW
      : COLOR.ROUGE};
  width: 25px;
  height: 25px;
`;
