import React, { Component } from "react";
import {
  Actions,
  Body,
  Container,
  Content,
  Date,
  Header,
  HeaderAction,
  HeaderActions,
  Name,
  PopupWrapper
} from "./styled";

import TypographyKit from "../../../components/kit/Typography/TypographyKit";
import IconCalendar from "../../../components/kit/Icon/IconCalendar";
import ButtonKit from "../../../components/kit/Button/ButtonKit";
import ButtonIconKit from "../../kit/ButtonIcon/ButtonIconKit";
import IconThreeDots from "../../kit/Icon/IconThreeDots";
import {
  MenuItemKitSD,
  MenuItemLinkKitSD,
  MenuKitSD
} from "../../kit/Menu/styled";
import PopupKit from "../../kit/Popup/PopupKit";
import {
  getPlainTextFromRaw,
  isJSON,
  safePropValueOr,
  truncateString
} from "../../../helpers/common";
import { dateToSting } from "../../../helpers/date";
import EmailDialog from "../../dialogs/EmailDialog/EmailDialog";

class EmailTemplate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      popupIsOpen: false,
      isDeleteTemplateDialogOpen: false,
      isDeletingTemplate: false
    };
  }

  closePopup = () => this.setState({ popupIsOpen: false });

  togglePopup = () => {
    this.setState(prevState => ({
      popupIsOpen: !prevState.popupIsOpen
    }));
  };

  onDiscard = async id => {
    this.setState({ isDeletingTemplate: true });

    try {
      await this.props.deleteTemplatesEmail(id);
      this.setState({
        isDeletingTemplate: false,
        isDeleteTemplateDialogOpen: false
      });
    } catch (err) {
      this.setState({ isDeletingTemplate: false });
    }
  };

  renderDeleteDialog = () => {
    const { isDeleteTemplateDialogOpen, isDeletingTemplate } = this.state;
    const { email } = this.props;

    return (
      <EmailDialog
        title={"DELETE TEMPLATE"}
        text={"Are you sure you want to delete this template?"}
        isOpen={isDeleteTemplateDialogOpen}
        onClose={() => this.setState({ isDeleteTemplateDialogOpen: false })}
        onConfirm={() => this.onDiscard(email.id)}
        confirmPreloader={isDeletingTemplate}
      />
    );
  };

  handleUseTemplate = email => {
    const { history, addEmail } = this.props;
    addEmail(email);
    history.push({
      pathname: "/communication/create",
      state: {
        saveEmail: true
      }
    });
  };

  render() {
    const { handleEdit, handlePreview, email } = this.props;
    const text = isJSON(email.message) ? JSON.parse(email.message) : "";
    return (
      <Container template>
        <Content>
          <Header>
            <Name>
              <TypographyKit component={"h5"} variant={"h5"}>
                {safePropValueOr(email, "name", "(No Name)")}
              </TypographyKit>
            </Name>
            <Date>
              <IconCalendar width={15} />
              <TypographyKit component={"strong"} variant={"label"}>
                Created{" "}
                {(safePropValueOr(email, "updatedAt", null) &&
                  dateToSting(email.updatedAt, "MMMM DD, Y @ h:mmA")) ||
                  "Not set"}
              </TypographyKit>
            </Date>
            <HeaderActions>
              <HeaderAction>
                <ButtonIconKit
                  width={12}
                  height={20}
                  onClick={this.togglePopup}
                >
                  <IconThreeDots width={4} />
                </ButtonIconKit>
                <PopupWrapper>
                  <PopupKit
                    disabledPortal
                    isOpen={this.state.popupIsOpen}
                    onClose={this.closePopup}
                  >
                    <MenuKitSD onClick={this.closePopup}>
                      <MenuItemKitSD>
                        <MenuItemLinkKitSD onClick={handlePreview}>
                          Preview
                        </MenuItemLinkKitSD>
                      </MenuItemKitSD>
                      <MenuItemKitSD>
                        <MenuItemLinkKitSD onClick={handleEdit}>
                          Edit
                        </MenuItemLinkKitSD>
                      </MenuItemKitSD>
                      <MenuItemKitSD>
                        <MenuItemLinkKitSD
                          onClick={() =>
                            this.setState({ isDeleteTemplateDialogOpen: true })
                          }
                        >
                          Delete
                        </MenuItemLinkKitSD>
                      </MenuItemKitSD>
                    </MenuKitSD>
                  </PopupKit>
                </PopupWrapper>
              </HeaderAction>
            </HeaderActions>
          </Header>
          <Body>
            <TypographyKit variant={"p3"} color={"currentColor"}>
              {safePropValueOr(email, "title", "(No Subject)")}
            </TypographyKit>
            <TypographyKit variant={"p3"} color={"currentColor"}>
              {truncateString(getPlainTextFromRaw(text), 226, false) ||
                "(No Preview Available)"}
            </TypographyKit>
            <Actions>
              <ButtonKit
                width={180}
                appearance={"secondary"}
                shape={"rounded"}
                color={"red"}
                onClick={() => this.handleUseTemplate(email)}
              >
                Use
              </ButtonKit>
            </Actions>
          </Body>
        </Content>
        {this.renderDeleteDialog(email)}
      </Container>
    );
  }
}

export default EmailTemplate;
