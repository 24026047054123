import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Transactions from "./Transactions";
import { fetchTransactions, resetWallet } from "../../../store/wallet/actions";
import PermissionDenied from "../../denied/PermissionDenied";

class TransactionsContainer extends Component {
  render() {
    const { permission } = this.props;
    if (!permission) {
      return <PermissionDenied />;
    }
    return <Transactions {...this.props} />;
  }
}

const mapStateProps = state => ({
  user: state.user.info,
  transactions: state.walletState.transactions,
  total: state.walletState.total
});

const mapDispatchToProps = {
  fetchTransactions,
  resetWallet
};

export default connect(
  mapStateProps,
  mapDispatchToProps
)(withRouter(TransactionsContainer));
