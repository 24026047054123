import React, { Component } from "react";
import { DialogText, Body, WrapButton } from "./styled";
import DialogKit from "../../../components/kit/Dialog/DialogKit";
import ButtonKit from "../../../components/kit/Button/ButtonKit";

class Dialog extends Component {
  render() {
    const { isDialogOpen, onClose } = this.props;

    return (
      <DialogKit
        isOpen={isDialogOpen}
        onClose={() => onClose()}
        displayButtonClose={true}
        title={"New IP Address"}
        renderBody={() => (
          <Body>
            <DialogText>
              Looks like you're logging in from somewhere new. Please check your
              email and verify your IP address, then try logging in again.
            </DialogText>
            <WrapButton>
              <ButtonKit fullWidth onClick={() => onClose()}>
                Ok
              </ButtonKit>
            </WrapButton>
          </Body>
        )}
      />
    );
  }
}

export default Dialog;
