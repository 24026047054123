import React, { Component } from "react";
import FormFieldKit from "../../../../components/kit/Fields/FormField/FormField";
import FieldTypeAndSelect from "../../../../components/smart/FieldTypeAndSelect/FieldTypeAndSelect";
import InputChips from "../../../../components/smart/ChipInput/InputChips";
import { getUsername, snakeToText } from "../../../../helpers/common";

import { api } from "../../../../index";
import { VISIBILITY_OPTIONS } from "./constants";

import { Container, Options, Option, Icon, Title } from "./styled";

class Players extends Component {
  handleSearchUsers = searchWord => {
    return api
      .search(searchWord, 10, 1, true)
      .then(resp =>
        resp.data.users.map(u => ({ label: getUsername(u), value: u.id, ...u }))
      );
  };

  renderUsersField = (value, setValue, errorText, handleBlur) => {
    const { removeUser, addUser, visibility } = this.props;

    return (
      <>
        <FormFieldKit fullWidth errorText={errorText}>
          <FieldTypeAndSelect
            placeholder={"Search by gamertag..."}
            onChange={e => addUser(e)}
            onSearch={this.handleSearchUsers}
            selectedOption={value}
            onBlur={handleBlur}
            hasError={!!errorText}
            readOnly={visibility === "anyone"}
          />
        </FormFieldKit>

        <InputChips
          value={value}
          onChange={e => removeUser(e)}
          readOnly={true}
          placeholder={"Add users in the field above"}
        />
      </>
    );
  };

  render() {
    const { visibility, onChangeVisibility, users } = this.props;

    return (
      <Container style={{ color: "#fff" }}>
        <Options>
          {VISIBILITY_OPTIONS.map(o => (
            <Option
              key={o.key}
              selected={visibility === o.key}
              onClick={() => onChangeVisibility(o.key)}
            >
              <Icon src={o.icon} />
              <Title>{snakeToText(o.key)}</Title>
            </Option>
          ))}
        </Options>
        {visibility === "invite-only" &&
          this.renderUsersField(users.map(u => getUsername(u)))}
      </Container>
    );
  }
}

export default Players;
