import styled from "styled-components";
import { COLOR, FONT_FAMILY, FONT_WEIGHT } from "../../../constants/theme";
import breakpoints from "../../../helpers/breakpoints";
import { Link } from "react-router-dom";
import DynamicGridImg from "../../../static/images/dynamic-grid.png";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 250px;
  min-width: 250px;
  height: 100vh;

  background-color: ${COLOR.RIVAL_RED};
  background-image: url(${DynamicGridImg}),
    linear-gradient(180deg, rgba(252, 23, 79, 0) 0%, #920139 100%);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;

  color: ${COLOR.TEXT};

  z-index: 1003;
  overflow: auto;
  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ${breakpoints.down("sm")} {
    position: absolute;
    display: ${props => (props.isOpen ? "flex" : "none")};
  }
`;

export const Menu = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

export const ProfilePic = styled.img`
  width: 50px;
  height: 50px;

  border-radius: 50%;

  margin-bottom: 15px;
`;

export const ProfileName = styled.div`
  font: ${FONT_WEIGHT.NORMAL} 12px ${FONT_FAMILY.GOTHIC_BOLD};
  text-align: center;
`;

export const Profile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: 25px 0px;

  ${breakpoints.down("sm")} {
    display: none;
  }
`;

export const Icon = styled.img`
  width: 18px;
  height: 18px;

  margin-right: 15px;
`;

export const Title = styled.div`
  font: ${FONT_WEIGHT.NORMAL} 14px ${FONT_FAMILY.GOTHIC};
  text-align: center;
`;

export const Button = styled.div`
  display: flex;
  align-items: center;

  width: 100%;

  color: ${COLOR.TEXT};
  text-decoration: none;
`;

export const SubMenuButton = styled(Link)`
  display: flex;
  align-items: center;

  width: 100%;
  padding: 10px 0px 0px 45px;
  font: ${FONT_WEIGHT.BOLD} 13px / 1.2 ${FONT_FAMILY.GOTHIC};

  ${({ isActive }) =>
    isActive
      ? `color: ${COLOR.RIVAL_RED};`
      : `color: ${COLOR.TEXT};
        &:hover {
            opacity: 0.4;
        };`}
  text-decoration: none;
`;

export const SubMenuLink = styled.a`
  display: flex;
  align-items: center;

  width: 100%;
  padding: 10px 0px 0px 45px;
  font: ${FONT_WEIGHT.BOLD} 13px / 1.2 ${FONT_FAMILY.GOTHIC};

  ${({ isActive }) =>
    isActive
      ? `color: ${COLOR.RIVAL_RED};`
      : `color: ${COLOR.TEXT};
        &:hover {
            opacity: 0.4;
        };`}
  text-decoration: none;
`;

export const DisabledSubMenuButton = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
  padding: 10px 0px 0px 45px;
  color: ${COLOR.GREY};
  font: ${FONT_WEIGHT.BOLD} 13px / 1.2 ${FONT_FAMILY.GOTHIC};

  text-decoration: none;
`;

export const WrapNavItem = styled.div`
  width: 100%;
  border-radius: 8px 0 0 8px;

  padding: 20px 15px;
  text-decoration: none;

  ${({ isActive }) =>
    isActive
      ? `background-color: ${COLOR.BG_PRIMARY};`
      : `background-color: transparent;
        &:hover {
            opacity: 0.4;
        };`}
`;
export const WrapNavLink = styled(Link)`
  width: 100%;
  text-decoration: none;
`;

export const NavItemList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  padding: 0px 0px 0px 20px;

  ${breakpoints.down("sm")} {
    padding-top: 100px;
  }
`;

export const WrapProfileButton = styled.div`
  width: 100%;
  background-color: transparent;
  border-radius: 8px 0 0 8px;

  padding: 20px 15px;

  &:hover {
    opacity: 0.4;
  }
`;

export const ProfileButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  padding: 0px 0px 0px 20px;
  margin-top: auto;
  margin-bottom: 20px;
`;
