import styled, { css } from "styled-components";
import { COLOR, FONT_FAMILY, FONT_WEIGHT } from "../../../constants/theme";

export const Container = styled.div``;

export const Header = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding-bottom: 20px;
`;

export const BackIcon = styled.img`
  cursor: pointer;
  width: 32px;
  height: 32px;
  margin-right: 10px;
  margin-bottom: 5px;
`;

export const PlusIcon = styled.div`
  cursor: pointer;
  width: 25px;
  height: 25px;
  color: ${COLOR.TEXT};
  text-decoration: none;
`;

export const WrapIcons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const EditIcon = styled.img`
  cursor: pointer;
  width: 22px;
  height: 22px;
  margin-right: 10px;
  margin-top: -3px;

  @media (hover: hover) {
    &:hover {
      opacity: 0.4;
    }
  }
  &:active {
    opacity: 0.4;
  }
`;

export const Title = styled.div`
  flex: 1;
  text-align: center;
  font: ${FONT_WEIGHT.MEDIUM} 32px ${FONT_FAMILY.GOTHIC_BOLD};
  text-transform: uppercase;

  a {
    color: ${COLOR.TEXT};
    text-decoration: none;
    cursor: pointer;
    margin-right: 16px;
    position: relative;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const List = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: calc(100vw - 290px);
  overflow-x: auto;
  padding: 7px 0 0;

  ::-webkit-scrollbar {
    height: 8px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: ${COLOR.BG_SECONDARY};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${COLOR.BG_PRIMARY};
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${COLOR.RIVAL_RED};
  }

  > :not(:last-child) {
    margin-right: 8px;
  }
`;

export const Description = styled.div`
  font: ${FONT_WEIGHT.NORMAL} 16px ${FONT_FAMILY.THIN};
  color: ${COLOR.TEXT};
  margin-bottom: 20px;
`;

export const Players = styled.div`
  font: ${FONT_WEIGHT.NORMAL} 16px ${FONT_FAMILY.THIN};
  color: ${COLOR.TEXT};
  margin: 7px 0;
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  ${({ center }) =>
    center &&
    css`
      align-items: center;
    `}
`;

export const Row = styled.div`
  display: flex;
  color: ${({ color }) => color || COLOR.TEXT};
  font: ${FONT_WEIGHT.MEDIUM} 14px ${FONT_FAMILY.THIN};
  margin: ${({ margin }) => margin || `10px 0 4px`};
`;

export const Season = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  background-color: ${COLOR.BG_PRIMARY};
  padding: 15px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s;
  text-decoration: none;
  color: ${COLOR.TEXT};
  min-width: 150px;
  height: 60px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  &:hover {
    background-color: ${COLOR.RIVAL_RED};
    transition: 0.3s all;
  }

  position: relative;

  &:after {
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    top: 15px;
    right: 12px;
    border-radius: 50%;
    background-color: ${({ status }) =>
      status === "initialized"
        ? COLOR.BLUE
        : status === "in progress"
        ? COLOR.GREEN
        : status === "complete"
        ? COLOR.GREY
        : "transparent"};

    ${({ status }) =>
      status === "in progress" &&
      css`
        animation: blinker 2s ease-out infinite;
        @keyframes blinker {
          50% {
            opacity: 0;
          }
        }
      `})}
  }
`;

export const SeasonRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const SeasonTitle = styled.div`
  font: ${FONT_WEIGHT.BOLD} 16px ${FONT_FAMILY.THIN};
  margin-right: 5px;
`;

export const SeasonDate = styled.div`
  font: ${FONT_WEIGHT.BOLD} 12px ${FONT_FAMILY.THIN};
  color: ${COLOR.GREY_2};
  margin-top: 5px;
`;

export const LinkIcon = styled.img`
  position: absolute;
`;
export const Console = styled.img`
  margin: 0 0 3px;
  max-width: 100px;
  max-height: 20px;
`;

export const Game = styled.img`
  margin: 0 8px 3px;
  max-width: 100px;
  max-height: 20px;
`;

export const WrapFilter = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 0 7px;
  width: 100%;
`;

export const Image = styled.img``;

export const Info = styled.div`
  padding: 0 20px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  flex-grow: 1;
`;
