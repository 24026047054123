import styled from "styled-components";
import { COLOR, FONT_FAMILY, FONT_WEIGHT } from "../../../constants/theme";

export const Container = styled.div`
  min-height: 100%;
  background-color: ${COLOR.BG_PRIMARY};
  max-width: 1440px;
  margin: 0 auto;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

export const Report = styled.iframe`
  width: 100%;
  max-width: 1200px;
`;

export const Text = styled.div`
  font: ${FONT_WEIGHT.MEDIUM} 18px ${FONT_FAMILY.THIN};
  color: ${COLOR.TEXT};
  margin: 30px auto;
`;

export const Edit = styled.div`
  width: 100%;
  padding: 15px;
`;
