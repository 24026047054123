import React from "react";
import CountryInput from "react-flags-select";
import {
  FormFieldKitSD,
  FieldWrapperKitSD,
  FormFieldAdditionalKitSD,
  FormFieldErrorKitSD,
  FormFieldLabelFieldGroupKitSD,
  FormFieldWrapMessagesSD
} from "./styled";
import { LabelKitSD } from "../styled";
import { COUNTRIES } from "./constants";

function CountryFieldKit(props) {
  const {
    label,
    icon,
    iconAlign,
    onChange,
    selected,
    fullWidth,
    width,
    horizontalLabelWidth,
    additionalText,
    errorText,
    horizontalLabel,
    children,
    required,
    gutterBottom,
    onBlur
  } = props;

  const totalWidth = horizontalLabel ? width + horizontalLabelWidth : width;
  const hasError = !!errorText;

  return (
    <FormFieldKitSD
      fullWidth={fullWidth}
      totalWidth={totalWidth}
      gutterBottom={gutterBottom}
      hasError={hasError}
    >
      <FormFieldLabelFieldGroupKitSD
        horizontalLabel={horizontalLabel}
        horizontalLabelWidth={horizontalLabelWidth}
        totalWidth={totalWidth}
        fullWidth={fullWidth}
      >
        {label && (
          <LabelKitSD>
            {label}{" "}
            {required && (
              <span style={{ color: "red", fontWeight: "bold" }}>*</span>
            )}
          </LabelKitSD>
        )}

        {children || (
          <FieldWrapperKitSD
            hasIcon={!!icon}
            iconAlign={iconAlign}
            onBlur={onBlur && (() => onBlur())}
          >
            <CountryInput
              searchable
              selected={selected}
              onSelect={onChange && (val => onChange(val))}
              countries={COUNTRIES}
            />
          </FieldWrapperKitSD>
        )}
      </FormFieldLabelFieldGroupKitSD>

      <FormFieldWrapMessagesSD ml={horizontalLabel && horizontalLabelWidth}>
        {additionalText && (
          <FormFieldAdditionalKitSD>{additionalText}</FormFieldAdditionalKitSD>
        )}

        {errorText && <FormFieldErrorKitSD>{errorText}</FormFieldErrorKitSD>}
      </FormFieldWrapMessagesSD>
    </FormFieldKitSD>
  );
}

export default CountryFieldKit;
