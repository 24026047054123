import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Container,
  Menu,
  Profile,
  ProfilePic,
  ProfileName,
  NavItemList,
  WrapNavItem,
  WrapNavLink,
  Button,
  Icon,
  Title,
  SubMenuButton,
  DisabledSubMenuButton,
  ProfileButtons,
  WrapProfileButton,
  SubMenuLink
} from "./styled";
import { NavItems } from "./constants";
import { logoutAction } from "../../../store/user/actions";
import { propValueOr, verifyPermission } from "../../../helpers/common";

// Icons
import PlaceHolderImg from "../../../static/images/default-user.jpg";
import AccountIcon from "../../../static/icons/settings-icon.svg";
import LogoutIcon from "../../../static/icons/logout-icon.svg";
import { Link } from "react-router-dom";

class SideNav extends Component {
  render() {
    const { user, isOpen } = this.props;
    const firstName = propValueOr(user, "info.fullName", "").split(" ")[0];
    const id = propValueOr(user, "info.id");
    const filterMenuItems = items => {
      return items.filter(route => {
        return (
          !route.checkPermission ||
          verifyPermission(route.to, this.props.permissions)
        );
      });
    };
    return (
      <Container isOpen={isOpen}>
        <Menu>
          <Profile>
            <ProfilePic
              src={propValueOr(
                user,
                "info.imageInfo.original",
                propValueOr(user, "info.imageInfo.thumbnail", PlaceHolderImg)
              )}
              onError={e => (e.target.src = PlaceHolderImg)}
            />
            <ProfileName>{`Hello, ${firstName}`}</ProfileName>
          </Profile>
          <NavItemList>
            {filterMenuItems(NavItems).map(item => {
              const path = window.location.pathname.split("/");
              const title = path[1] || "";
              const subtitle = path[2] || "";
              const isActive =
                title === item.to.split("/")[1] ||
                (item.alternates && item.alternates.includes(title));
              return (
                <WrapNavItem isActive={isActive} key={item?.title}>
                  <WrapNavLink to={item.to} key={item.title}>
                    <Button>
                      <Icon src={item.icon} />
                      <Title>{item.title}</Title>
                    </Button>
                  </WrapNavLink>
                  {isActive &&
                    filterMenuItems(propValueOr(item, "subItems", [])).map(
                      subItem => {
                        if (subItem.href) {
                          return (
                            <SubMenuLink
                              key={subItem.title}
                              href={subItem.href}
                              target="_blank"
                              rel="noreferrer noopener"
                            >
                              {subItem.title}
                            </SubMenuLink>
                          );
                        }
                        const subPath = subItem.to.split("/");
                        const subIsActive =
                          subtitle === subPath[2] ||
                          (!!!subPath[2] && !!!subtitle);
                        return subItem.disabled ? (
                          <DisabledSubMenuButton>
                            {subItem.title}
                          </DisabledSubMenuButton>
                        ) : (
                          <SubMenuButton
                            key={subItem.title}
                            to={subItem.to}
                            isActive={subIsActive}
                          >
                            {subItem.title}
                          </SubMenuButton>
                        );
                      }
                    )}
                </WrapNavItem>
              );
            })}
          </NavItemList>
          <ProfileButtons>
            <WrapNavItem as={Link} to={`/users/${id}`}>
              <Button>
                <Icon src={AccountIcon} />
                <Title>Account</Title>
              </Button>
            </WrapNavItem>
            <WrapProfileButton to={"/login"}>
              <Button onClick={() => this.props.logoutAction()}>
                <Icon src={LogoutIcon} />
                <Title>Logout</Title>
              </Button>
            </WrapProfileButton>
          </ProfileButtons>
        </Menu>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  permissions: state.permissionsState.permissions
});

const mapDispatchToProps = {
  logoutAction
};

export default connect(mapStateToProps, mapDispatchToProps)(SideNav);
