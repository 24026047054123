import { TEAM_ACTIONS } from "./constants";
import { api } from "../../index";
import { propValueOr } from "../../helpers/common";

export const fetchTeamAction = (slug, tournamentId) => dispatch => {
  dispatch({
    type: TEAM_ACTIONS.fetch.request
  });
  api.admin.team
    .get(slug, tournamentId ? `?tournamentId=${tournamentId}` : "")
    .then(resp => {
      const data = propValueOr(resp, "data", {});

      dispatch({
        type: TEAM_ACTIONS.fetch.success,
        payload: data
      });
    })
    .catch(err => {
      dispatch({
        type: TEAM_ACTIONS.fetch.error,
        payload: err
      });
    });
};

export const resetTeamAction = () => dispatch => {
  dispatch({
    type: TEAM_ACTIONS.reset
  });
};
