import styled from "styled-components";
import { FONT_WEIGHT, COLOR } from "../../../../constants/theme";

export const Tooltip = styled.div`
  position: absolute;
  z-index: 1;
  display: none;
  top: -6px;
  right: 20px;
  padding: 5px;
  border-radius: 8px;
  background-color: ${COLOR.BG_PRIMARY};
`;

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  padding: ${({ showUser }) => (showUser ? "5px 0" : "0px")};
  margin-top: ${({ showUser }) => (showUser ? "10px" : "0px")};

  &:hover ${Tooltip} {
    display: ${({ isEditing, isAdmin, deleted }) =>
      deleted || isEditing || !isAdmin ? "none" : "flex"};
    top: ${({ showUser }) => (showUser ? "8px" : "-10px")};
  }
  position: relative;
`;

export const WrapAvatar = styled.div`
  flex: 0 0 28px;
  margin: 0 4px 0 0;
  height: 28px;
  width: 28px;

  border: 1px solid ${props => props.theme.message.borderAvatarColor};
  border-radius: 2px;
  border-color: ${props => (props.hideColor ? "transparent" : "#fff")};
  box-shadow: ${props =>
    props.hideColor ? "" : "0 2px 4px 0 rgba(0, 0, 0, 0.5)"};
`;

export const Avatar = styled.img`
  position: relative;
  top: 50%;
  display: block;
  width: 26px;
  height: 26px;

  transform: translateY(-50%);
  object-fit: contain;
`;

export const AvatarEmpty = styled.div`
  position: relative;
  top: 50%;
  display: block;
  width: 26px;
  height: 26px;

  transform: translateY(-50%);
  object-fit: contain;
`;

export const Content = styled.div`
  flex-grow: 1;
  min-width: 0;

  color: ${props => props.theme.main.textColor};
  font-size: 12px;
  font-weight: ${FONT_WEIGHT.BOLD};
  letter-spacing: -0.22px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`;

export const Title = styled.div`
  user-select: text;
`;

export const Time = styled.div`
  margin-left: 5px;
  color: ${COLOR.FG_INPUT};
  font-weight: 400;
  user-select: text;
`;

export const WrapText = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px 10px 15px;
  border-radius: 8px;
  background-color: ${props =>
    props.deleted ? COLOR.GREY : props.theme.message.bgColor};
  border: 1px solid;
  border-color: ${props =>
    props.isAdmin
      ? props.theme.message.bgColorModerator
      : props.theme.message.bgColor};
  color: ${COLOR.TEXT};
  user-select: text;
`;

export const Text = styled.div`
  color: ${COLOR.TEXT};
`;

export const Highlight = styled.span`
  color: ${COLOR.TEXT};
  background-color: ${COLOR.RIVAL_RED};
  padding: 2px 4px;
  border-radius: 4px;
  margin-right: 4px;
  cursor: pointer;
`;

export const Italics = styled.span`
  color: ${COLOR.GREY};
  font-style: italic;
`;

export const Error = styled.div`
  color: ${COLOR.RIVAL_RED};
`;

export const Input = styled.textarea`
  width: 100%;
  height: 100%;
  border: 1px solid rgba(204, 204, 204, 0);
  background-color: ${props => props.theme.message.bgColor};
  color: ${props => props.theme.main.textColor};
  font-size: 12px;
  font-weight: ${FONT_WEIGHT.BOLD};
  letter-spacing: -0.22px;
  resize: none;
  word-break: break-word;

  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: ${props => props.theme.field.placeholderColor};
    opacity: 0.4;
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: ${props => props.theme.field.placeholderColor};
    opacity: 0.4;
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    color: ${props => props.theme.field.placeholderColor};
    opacity: 0.4;
  }
  :-moz-placeholder {
    /* Firefox 18- */
    color: ${props => props.theme.field.placeholderColor};
    opacity: 0.4;
  }
`;

export const WrapIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  padding: 5px;
  margin: 0 5px;

  cursor: pointer;
  border-radius: 4px;
  opacity: 0.8;
  transition: all 0.2s;

  &:hover {
    opacity: 1;
    background-color: ${COLOR.BG_INPUT};
  }
`;

export const WrapAction = styled.div`
  display: flex;
`;

export const Icon = styled.img`
  width: 15px;
  height: 15px;
`;
