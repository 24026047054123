import React from "react";

import DialogKit from "../../../../components/kit/Dialog/DialogKit";
import ButtonKit from "../../../../components/kit/Button/ButtonKit";
import {
  Container,
  Header,
  HeaderTitle,
  TextContent,
  Buttons,
  WrapButton
} from "./styled";

class DeleteDialog extends React.Component {
  renderDialog = () => {
    const { onClose, onDelete, id } = this.props;

    return (
      <Container>
        <Header>
          <HeaderTitle>Delete Message</HeaderTitle>
        </Header>
        <TextContent>Are you sure you wish to delete this message?</TextContent>
        <Buttons>
          <WrapButton>
            <ButtonKit small fullWidth color="red" onClick={() => onDelete(id)}>
              Delete
            </ButtonKit>
          </WrapButton>
          <WrapButton>
            <ButtonKit small fullWidth appearance="secondary" onClick={onClose}>
              Cancel
            </ButtonKit>
          </WrapButton>
        </Buttons>
      </Container>
    );
  };

  render() {
    const { isOpen, onClose } = this.props;

    return (
      <DialogKit
        isOpen={isOpen}
        onClose={onClose}
        renderCustom={this.renderDialog}
        displayButtonClose={false}
      />
    );
  }
}

export default DeleteDialog;
