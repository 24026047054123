import styled from "styled-components";
import { Link } from "react-router-dom";
import { COLOR } from "../../../constants/theme";

export const Container = styled.div`
  width: 100%;
  padding: 9px 26px;
  background-color: #0c141d;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const Item = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  color: ${COLOR.TEXT};
  text-transform: capitalize;

  &:not(:last-child) {
    margin-right: 5px;
  }
`;

export const ItemText = styled.span`
  color: currentColor;
  font-size: inherit;
  font-weight: inherit;
  display: inline-block;

  &:not(:last-child) {
    margin-right: 5px;
  }
`;

export const ItemLink = styled(Link)`
  color: currentColor;
  display: inline-block;
  text-decoration: none;

  &:not(:last-child) {
    margin-right: 5px;
  }

  &:hover {
    text-decoration: underline;
  }
`;
