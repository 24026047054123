import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import configureStore from "./store";
import App from "./app/App";
import * as serviceWorker from "./serviceWorker";
import API from "./api/index";
import Config from "./config/index";
import { GlobalStyleSD } from "./app/styled";
import { BrowserRouter } from "react-router-dom";

/* Added styling to app */
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.min.css";

export const store = configureStore();
export const api = new API(store);
export const config = new Config(store);

ReactDOM.render(
  <Provider store={store}>
    <GlobalStyleSD />
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
