import styled from "styled-components";
import PropTypes from "prop-types";
import {
  COLOR,
  FONT_FAMILY_DEFAULT as FONT_FAMILY,
  FONT_WEIGHT
} from "../../../constants/theme";
import { addUnit } from "../../../helpers/common";

export const Container = styled.div`
  font: ${FONT_WEIGHT.MEDIUM} 14px / 1.5 ${FONT_FAMILY.THIN};
  opacity: ${({ readOnly }) => (readOnly ? "0.5" : "1")};
  cursor: ${({ readOnly }) => (readOnly ? "not-allowed" : "initial")};

  .public-DraftEditor-content {
    min-height: ${({ minHeight }) => addUnit(minHeight)};
  }

  .public-DraftEditorPlaceholder-root {
    font-size: 14px;
    color: ${COLOR.GREY};
  }

  .draftJsLinkifyPlugin__link__2ittM,
  .draftJsLinkifyPlugin__link__2ittM:visited {
    color: currentColor;
    text-decoration: underline;
    cursor: pointer;
  }

  .draftJsLinkifyPlugin__link__2ittM:hover,
  .draftJsLinkifyPlugin__link__2ittM:focus {
    color: currentColor;
    outline: 0;
    cursor: pointer;
    text-decoration: none;
  }

  .draftJsLinkifyPlugin__link__2ittM:active {
    color: currentColor;
  }
`;

export const Link = styled.a`
  color: currentColor;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
`;

Container.defaultProps = {
  minHeight: 352
};

Container.propTypes = {
  minHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};
