import React from "react";
import Tippy from "@tippyjs/react";

import {
  Body,
  BodyAuthor,
  BodyRow,
  BodySubject,
  Container,
  Content,
  Date,
  From,
  Header,
  Row,
  To,
  Tooltip,
  TooltipInner,
  TooltipRecipient
} from "./styled";
import TypographyKit from "../../../components/kit/Typography/TypographyKit";
import IconCalendar from "../../../components/kit/Icon/IconCalendar";
import {
  getAllRecipients,
  getPlainTextFromRaw,
  isJSON,
  safePropValueOr,
  truncateString
} from "../../../helpers/common";
import { dateToSting } from "../../../helpers/date";

const Email = ({
  handleClick,
  visibleRecipientsTooltip,
  visibleAuthor,
  ...email
}) => {
  const text = isJSON(email.message) ? JSON.parse(email.message) : "";
  const to = visibleRecipientsTooltip
    ? safePropValueOr(email, "recipients", [])
    : getAllRecipients(email.to);

  const renderRecipients = () => {
    if (visibleRecipientsTooltip) {
      return to && to.length > 0 ? (
        to.length > 2 ? (
          <Tippy
            delay={100}
            interactive={true}
            maxWidth={480}
            content={
              <Tooltip>
                <TooltipInner>
                  {to.map((r, index) => (
                    <TooltipRecipient key={index} state={r.state}>
                      {r.to}
                    </TooltipRecipient>
                  ))}
                </TooltipInner>
              </Tooltip>
            }
          >
            <span>{truncateString(to.map(r => r.to).join(", "), 36)}</span>
          </Tippy>
        ) : (
          truncateString(to.map(r => r.to).join(", "), 36)
        )
      ) : (
        "Empty"
      );
    } else {
      return to && to.length > 0 ? (
        <span>{truncateString(to.join(", "), 36)}</span>
      ) : (
        "Empty"
      );
    }
  };

  return (
    <Container onClick={handleClick}>
      <Row>
        <Content>
          <Header>
            <From>
              <TypographyKit variant={"label"}>From</TypographyKit>
              <TypographyKit component={"strong"} variant={"label"}>
                {safePropValueOr(email, "from", "Not Selected")}
              </TypographyKit>
            </From>
            <To>
              <TypographyKit variant={"label"}>To</TypographyKit>
              <TypographyKit component={"strong"} variant={"label"}>
                {renderRecipients()}
              </TypographyKit>
            </To>
            <Date>
              <IconCalendar width={15} />
              <TypographyKit component={"strong"} variant={"label"}>
                {(safePropValueOr(email, "sendAt", null) &&
                  dateToSting(email.sendAt, "MMMM DD, Y @ h:mmA")) ||
                  "Not set"}
              </TypographyKit>
            </Date>
          </Header>
          <Body>
            {visibleAuthor ? (
              <BodyRow>
                <BodyAuthor>
                  <TypographyKit variant={"p3"} color={"#fff"}>
                    {safePropValueOr(email, "author.fullName", "(No Author)")}
                  </TypographyKit>
                </BodyAuthor>
                <BodySubject>
                  <TypographyKit variant={"p3"} color={"currentColor"}>
                    {safePropValueOr(email, "title", "(No Subject)")}
                  </TypographyKit>
                </BodySubject>
              </BodyRow>
            ) : (
              <TypographyKit variant={"p3"} color={"currentColor"}>
                {safePropValueOr(email, "title", "(No Subject)")}
              </TypographyKit>
            )}
            <TypographyKit variant={"p3"} color={"currentColor"}>
              {truncateString(getPlainTextFromRaw(text), 226, false) ||
                "(No Preview Available)"}
            </TypographyKit>
          </Body>
        </Content>
      </Row>
    </Container>
  );
};

export default Email;
