import React from "react";
import PropTypes from "prop-types";
import { ChipSD, ChipTitleSD, ChipWrapIconSD } from "./styled";
import IconClose from "../../kit/Icon/IconClose";

const Chip = ({ className, incorrect, onDelete, value }) => {
  return (
    <ChipSD className={className} incorrect={incorrect}>
      <ChipTitleSD>{value}</ChipTitleSD>
      <ChipWrapIconSD onClick={onDelete}>
        <IconClose width={"1em"} />
      </ChipWrapIconSD>
    </ChipSD>
  );
};

Chip.propTypes = {
  incorrect: PropTypes.bool,
  onDelete: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
};

export default Chip;
