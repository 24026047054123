import styled from "styled-components";
import { COLOR, FONT } from "../../../constants/theme";
import PropsTypes from "prop-types";

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const Item = styled.div`
  &:not(:last-child) {
    margin-right: 30px;
  }
`;

export const Button = styled.button`
  font: ${FONT.BUTTON};
  color: ${COLOR.WHITE};
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: none;
  box-shadow: none;
  padding: 0 20px 0 0;
  border-radius: 0;
  background: transparent;

  &::before {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid ${COLOR.TEAL};
    border-radius: 1px;
    transform: ${({ sortingDirection, active }) => {
      return sortingDirection === "desc" && active
        ? "translateY(-50%) rotate(0)"
        : "translateY(-50%) rotate(180deg)";
    }};
  }
`;

Button.propTypes = {
  sortingDirection: PropsTypes.oneOf(["asc", "desc"]),
  active: PropsTypes.bool
};
