import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Templates from "./Templates";
import { fetchTemplates } from "../../../store/tournament/actions";
import PermissionDenied from "../../denied/PermissionDenied";

class TemplatesContainer extends Component {
  render() {
    const { permission } = this.props;
    if (!permission) {
      return <PermissionDenied />;
    }
    return <Templates {...this.props} />;
  }
}

const mapStateProps = state => ({
  user: state.user.info,
  templates: state.tournamentState.templates,
  total: state.tournamentState.total
});

const mapDispatchToProps = {
  fetchTemplates
};

export default connect(
  mapStateProps,
  mapDispatchToProps
)(withRouter(TemplatesContainer));
