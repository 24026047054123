import { api } from "../../index";
import { PERMISSIONS_ACTIONS } from "./constants";
import { propValueOr } from "../../helpers/common";

export const fetchPermissionsAction = filter => async dispatch => {
  dispatch({
    type: PERMISSIONS_ACTIONS.fetch.request
  });
  return api.admin.permissions
    .list(filter)
    .then(resp => {
      const permissions = propValueOr(resp, "data", []);

      dispatch({
        type: PERMISSIONS_ACTIONS.fetch.success,
        payload: permissions
      });
    })
    .catch(err => {
      dispatch({ type: PERMISSIONS_ACTIONS.fetch.error, payload: err });
    });
};
