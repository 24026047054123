import styled, { css } from "styled-components";
import { COLOR, FONT_FAMILY, FONT_WEIGHT } from "../../constants/theme";
import breakpoints from "../../helpers/breakpoints";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

export const Container = styled.div`
  min-height: 100%;
  background-color: ${COLOR.BG_PRIMARY};
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  padding-bottom: 20px;
`;

export const ContentCard = styled.div`
  display: flex;
  flex-direction: column;

  padding: 30px;
  border-radius: 16px;

  background-color: ${COLOR.BG_SECONDARY};
`;

export const Header = styled.div`
  color: ${COLOR.TEXT};
  font: ${FONT_WEIGHT.MEDIUM} 24px ${FONT_FAMILY.GOTHIC};
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-bottom: 20px;

  border-bottom: 1px solid ${COLOR.BORDER};
`;

export const Content = styled.div`
  color: ${COLOR.TEXT};
  background-color: ${COLOR.BG_INPUT};
  padding: 30px 11.11%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* height: 80vh; */

  ${breakpoints.down("1400")} {
    padding: 20px;
  }
`;

export const FilterHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  width: 100%;
  background-color: ${COLOR.BG_SECONDARY};

  padding: 10px 0px;
`;

export const Filter = styled.div`
  display: flex;
  justify-content: flex-end;

  color: ${COLOR.TEXT};
  overflow: hidden;

  margin-right: 20px;

  border: 2px solid ${COLOR.RIVAL_RED};
  border-radius: 8px;
`;

const ACTIVE_SWITCHER_ITEM_STYLES = css`
  background-color: ${COLOR.RIVAL_RED};
  color: ${COLOR.WHITE};
`;

const NOT_ACTIVE_SWITCHER_ITEM_STYLES = css`
  background-color: ${COLOR.BG_SECONDARY};
  color: ${COLOR.WHITE};
`;

export const FilterItem = styled(Link)`
  width: 180px;
  padding: 12px 10px;

  font: ${FONT_WEIGHT.EXTRA_BOLD} 14px ${FONT_FAMILY.GOTHIC_BOLD};
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;

  cursor: pointer;
  transition: all 0.3s;

  ${_IS_ACTIVE_SWITCHER_ITEM_};

  &:hover {
    ${ACTIVE_SWITCHER_ITEM_STYLES};
  }

  &:not(:first-child) {
    margin-left: -1px;
  }

  ${breakpoints.down("xs")} {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 4px;
    padding-right: 4px;
  }

  ${breakpoints.down("440")} {
    min-width: 0;
    font-size: 12px;
  }
`;

FilterItem.propTypes = {
  isActive: PropTypes.bool
};

function _IS_ACTIVE_SWITCHER_ITEM_({ isActive }) {
  return isActive
    ? ACTIVE_SWITCHER_ITEM_STYLES
    : NOT_ACTIVE_SWITCHER_ITEM_STYLES;
}
