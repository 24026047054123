export const COMMUNICATION_ACTIONS = {
  email: {
    add: "COMMUNICATION/EMAIL_ADD",
    delete: "COMMUNICATION/EMAIL_DELETE"
  },
  scheduled: {
    request: "COMMUNICATION/SCHEDULED_EMAILS_REQUEST",
    success: "COMMUNICATION/SCHEDULED_EMAILS_SUCCESS",
    error: "COMMUNICATION/SCHEDULED_EMAILS_ERROR",
    create: "COMMUNICATION/SCHEDULED_EMAILS_CREATE",
    update: "COMMUNICATION/SCHEDULED_EMAILS_UPDATE",
    delete: "COMMUNICATION/SCHEDULED_EMAILS_DELETE"
  },
  drafts: {
    request: "COMMUNICATION/DRAFTS_REQUEST",
    success: "COMMUNICATION/DRAFTS_SUCCESS",
    error: "COMMUNICATION/DRAFTS_ERROR",
    create: "COMMUNICATION/DRAFTS_EMAIL_CREATE",
    update: "COMMUNICATION/DRAFTS_EMAILS_UPDATE",
    delete: "COMMUNICATION/DRAFTS_EMAILS_DELETE"
  },
  templates: {
    request: "COMMUNICATION/TEMPLATES_REQUEST",
    success: "COMMUNICATION/TEMPLATES_SUCCESS",
    error: "COMMUNICATION/TEMPLATES_ERROR",
    create: "COMMUNICATION/TEMPLATES_EMAIL_CREATE",
    update: "COMMUNICATION/TEMPLATES_EMAILS_UPDATE",
    delete: "COMMUNICATION/TEMPLATES_EMAILS_DELETE"
  },
  global_templates: {
    request: "COMMUNICATION/GLOBAL_TEMPLATES_REQUEST",
    success: "COMMUNICATION/GLOBAL_TEMPLATES_SUCCESS",
    error: "COMMUNICATION/GLOBAL_TEMPLATES_ERROR",
    create: "COMMUNICATION/GLOBAL_TEMPLATES_EMAIL_CREATE",
    update: "COMMUNICATION/GLOBAL_TEMPLATES_EMAILS_UPDATE",
    delete: "COMMUNICATION/GLOBAL_TEMPLATES_EMAILS_DELETE"
  },
  sent: {
    request: "COMMUNICATION/SENT_REQUEST",
    success: "COMMUNICATION/SENT_SUCCESS",
    error: "COMMUNICATION/SENT_ERROR"
  }
};
