/*Note: for all routes property exact is true !!!*/
import League from "./League/League";
import LeagueCreate from "./LeagueCreate/LeagueCreate";
import SeasonCreate from "./Season/SeasonCreate";
import SeasonUpdate from "./Season/SeasonUpdate";
const routes = [
  {
    path: "/leagues/create",
    contentComponent: LeagueCreate
  },
  {
    path: "/leagues/:slug/create",
    contentComponent: SeasonCreate
  },
  {
    path: "/leagues/:slug/seasons/:token",
    contentComponent: SeasonUpdate
  },
  {
    path: "/leagues/:slug",
    contentComponent: League
  }
];

export default routes;
