const ROUND_NAMES = [
  "FINALS",
  "SEMIFINALS",
  "QUARTERFINALS",
  "ROUND OF 16",
  "ROUND OF 32",
  "ROUND OF 64",
  "ROUND OF 128",
  "ROUND OF 256",
  "ROUND OF 512"
];

export const getRoundTitle = (
  round,
  eliminationType,
  maxPlayers,
  numRounds = 9
) => {
  // if autosize...
  if (eliminationType === "single elimination" && !maxPlayers) {
    return ROUND_NAMES?.[round - 1] || `ROUND ${numRounds - round + 1}`;
  }
  return `ROUND ${round}`;
};

export let TOURNAMENT_ASIDE_NAV_ITEMS = [
  {
    title: "Type",
    subtitle: "What Type of Tournament do you want to create?",
    to: "/tournaments/create/type",
    next: "/tournaments/create/info",
    prev: null,
    disabled: false
  },
  {
    title: "Info",
    subtitle: "TOURNAMENT INFO",
    to: "/tournaments/create/info",
    prev: "/tournaments/create/type",
    next: "/tournaments/create/prizes",
    disabled: true
  },
  {
    title: "Prizes",
    subtitle: "WHAT DO THE WINNERS GET?",
    to: "/tournaments/create/prizes",
    prev: "/tournaments/create/info",
    next: "/tournaments/create/images",
    disabled: true
  },
  {
    title: "Images",
    subtitle: "UPLOAD TOURNAMENT PICS (Optional)",
    to: "/tournaments/create/images",
    prev: "/tournaments/create/prizes",
    next: "/tournaments/create/stages",
    disabled: true
  },
  // {
  //   title: "Players",
  //   subtitle: "WHO CAN PLAY IN THIS TOURNAMENT?",
  //   to: "/tournament/create/players",
  //   prev: "/tournament/create/images",
  //   next: "/tournament/create/stages"
  // },
  {
    title: "Stages",
    to: "/tournaments/create/stages",
    prev: "/tournaments/create/images",
    next: "/tournaments/create/comms",
    disabled: true
  },
  {
    title: "Comms",
    subtitle: "Choose the templates that meet the tournament's event",
    to: "/tournaments/create/comms",
    prev: "/tournaments/create/stages",
    next: "/tournaments/create/review",
    disabled: true
  },
  {
    title: "Review",
    to: "/tournaments/create/review",
    prev: "/tournaments/create/comms",
    next: null,
    disabled: true
  }
];

export const eliminationTypeOptions = [
  {
    label: "Single Elimination",
    value: "single elimination"
  },
  {
    label: "Double Elimination",
    value: "double elimination"
  },
  {
    label: "Swiss",
    value: "swiss"
  },
  {
    label: "Free for All (FFA)",
    value: "free for all"
  }
  // {
  //   label: "Best Of",
  //   value: "best of"
  // }
  // {
  //   label: 'Round Robin',
  //   value: 'round-robin',
  // }
];

export const readyOptions = [
  {
    label: "Game starts when both present",
    value: false
  },
  {
    label: "Game starts when both click ready",
    value: true
  }
];

export const bracketSizeOptions = [
  {
    label: "Auto-Size (auto-sets bracket size)",
    value: null
  },
  {
    label: "2 player bracket (finals)",
    value: 2
  },
  {
    label: "4 player bracket",
    value: 4
  },
  {
    label: "8 player bracket",
    value: 8
  },
  {
    label: "16 player bracket",
    value: 16
  },
  {
    label: "32 player bracket",
    value: 32
  },
  {
    label: "64 player bracket",
    value: 64
  },
  {
    label: "128 player bracket",
    value: 128
  },
  {
    label: "256 player bracket",
    value: 256
  },
  {
    label: "512 player bracket",
    value: 512
  }
];

export const teamBracketSizeOptions = [
  {
    label: "Auto-size (auto-sets bracket size)",
    value: null
  },
  {
    label: "2 team bracket (finals)",
    value: 2
  },
  {
    label: "4 team bracket",
    value: 4
  },
  {
    label: "8 team bracket",
    value: 8
  },
  {
    label: "16 team bracket",
    value: 16
  },
  {
    label: "32 team bracket",
    value: 32
  },
  {
    label: "64 team bracket",
    value: 64
  },
  {
    label: "128 team bracket",
    value: 128
  },
  {
    label: "256 team bracket",
    value: 256
  },
  {
    label: "512 team bracket",
    value: 512
  }
];

export const timeOptions = [
  {
    label: "seconds",
    value: "seconds"
  },
  {
    label: "minutes",
    value: "minutes"
  },
  {
    label: "hours",
    value: "hours"
  },
  {
    label: "days",
    value: "days"
  }
];

// returns error string if not valid, else returns null
export const validateStage = (stage, tournament) => {
  const name = stage.name || "Stage " + (stage.index + 1);
  if (!stage) return name + " is invalid";
  if (!stage.stage) return name + " must have a stage number";
  if (tournament.type === "scheduled") {
    if (stage.startDate < tournament.startDate) {
      return name + " cannot begin before tournament start";
    }
  }
  if (
    stage.type === "single elimination" ||
    stage.type === "double elimination"
  ) {
    if (tournament.minPlayers < stage.minPlayers) {
      return name + " Stage min players cannot be more than tournament minimum";
    }
    if (
      (stage.maxPlayers !== null && stage.maxPlayers < stage.minPlayers) ||
      (stage.minPlayers < 2 && stage.maxPlayers !== null)
    ) {
      return name + " Min and max players are invalid";
    }
  } else if (stage.type === "swiss" || stage.type === "free for all") {
    if (
      (stage.maxPlayers !== null && stage.maxPlayers < stage.minPlayers) ||
      stage.minPlayers < 2
    ) {
      return name + " Min and max players are invalid";
    }
    if (tournament.minPlayers < stage.minPlayers) {
      return name + " Stage min players cannot be more than tournament minimum";
    }
    if (!stage.maxRounds) {
      return "Must set a number of rounds for " + name;
    }
  } else return "Must set elimination type for " + name;
  return null;
};

export const bestOfOptions = [
  {
    label: "Single Game Series (standard)",
    value: null
  },
  {
    label: "Best Of 3 Series",
    value: "3"
  },
  {
    label: "Best Of 5 Series",
    value: "5"
  },
  {
    label: "Best Of 7 Series",
    value: "7"
  },
  {
    label: "Best Of 9 Series",
    value: "9"
  }
];

export const verificationOptions = [
  {
    label:
      "Score Entry w/ No Pic Upload (Same winner entered = advance, standard challenge process)",
    value: null
  },
  {
    label:
      "Score Entry w/ Pic Upload Optional (same winner entered = advance, standard challenge process)",
    value: "image"
  },
  {
    label:
      "Moderator Must Approve Score Entered (Total score entered, pic proof & info for all game(s) in round)",
    value: "moderator"
  },
  {
    label:
      "Moderator Must Verify Both Players Scores (Even if same score & winner entered, requires pic proof, info optional)",
    value: "moderator+"
  }
];

export const ffaVerificationOptions = [
  {
    label:
      "Moderator Must Approve Score Entered (Total score entered, pic proof & info for all game(s) in round)",
    value: "moderator"
  }
];

export const compressionOptions = {
  maxSizeMB: 0.4,
  useWebWorker: true
};
