import anyone from "../../../../static/icons/users-white.svg";
import mail from "../../../../static/icons/mail.svg";

export const VISIBILITY_OPTIONS = [
  {
    key: "anyone",
    icon: anyone
  },
  {
    key: "invite-only",
    icon: mail
  }
];
