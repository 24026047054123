import styled from "styled-components";
import { COLOR, FONT_FAMILY, FONT_WEIGHT } from "../../../../constants/theme";

export const Container = styled.div``;
export const Options = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
  > :not(:last-child) {
    margin-right: 25px;
  }
`;
export const Option = styled.div`
  padding: 40px 15px 24px;
  background-color: ${COLOR.BG_SECONDARY};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;
  border-radius: 4px;
  border: ${({ selected }) => selected && "2px solid #005CFF"};
  cursor: pointer;

  > :not(:last-child) {
    margin-bottom: 38px;
  }

  &:hover {
    opacity: ${({ selected }) => !selected && "0.9"};
  }
`;
export const Icon = styled.img`
  width: 40px;
  height: 30px;
`;
export const Title = styled.div`
  font: ${FONT_WEIGHT.MEDIUM} 18px / 21px ${FONT_FAMILY.THIN};
  letter-spacing: 0.5px;
  text-transform: uppercase;
  text-align: center;
`;
