import React, { Component } from "react";
import {
  MessageListContainer,
  Subtitle,
  WrapMessage,
  Time,
  Content,
  Footer
} from "./styled";
import Message from "./Message/Message";
import { propValueOr } from "../../../helpers/common";
import DeleteDialog from "./DeleteDialog/DeleteDialog";

export default class MessageList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deleting: null
    };
  }
  updateMessage = (timetoken, message) => {
    return this.props.pubnub.addMessageAction({
      channel: this.props.channel,
      messageTimetoken: timetoken,
      action: {
        type: "updated",
        value: message
      }
    });
  };

  deleteMessage = (timetoken, message) => {
    this.props.pubnub
      .addMessageAction({
        channel: this.props.channel,
        messageTimetoken: timetoken,
        action: {
          type: "deleted",
          value: `${message} (deleted)`
        }
      })
      .then(() => {
        this.setState({ deleting: null });
      });
  };

  render() {
    const { messages, startedAt, endedAt, getDate, user } = this.props;
    const { deleting } = this.state;

    return (
      <MessageListContainer>
        <DeleteDialog
          isOpen={deleting}
          onClose={() => this.setState({ deleting: null })}
          onDelete={() => this.deleteMessage(deleting.timetoken, deleting.text)}
        />
        <Subtitle>{startedAt}</Subtitle>
        <Subtitle>Chat room created.</Subtitle>
        <Content>
          {messages.map((m, i) => {
            if (!m) {
              return <></>;
            }

            const date = getDate(m.timetoken, "historyMessage", i);

            // check if message was updated
            const updated = Object.entries(
              m.actions && m.actions.updated ? m.actions.updated : {}
            )
              .map(action => ({
                message: action[0],
                date: new Date(
                  parseInt(
                    action[1][0].actionTimetoken.toString().substring(0, 13)
                  )
                )
              }))
              .sort((a, b) => b.date - a.date);

            const deleted = m.deleted
              ? [m.deleted]
              : Object.entries(
                  m.actions && m.actions.deleted ? m.actions.deleted : {}
                ).map(action => ({
                  message: action[0],
                  date: new Date(
                    parseInt(
                      action[1][0].actionTimetoken.toString().substring(0, 13)
                    )
                  )
                }));

            // hide messages from non admin users
            if (deleted.length > 0 && user.role !== "admin") {
              return <></>;
            }

            const message =
              m.deleted ||
              (deleted[0] && deleted[0].message) ||
              m.updated ||
              (updated[0] && updated[0].message) ||
              m.message; //get latest message

            const chatUser = propValueOr(
              m,
              "message.senderId",
              propValueOr(m, "meta", {})
            );

            return (
              <WrapMessage key={m.timetoken}>
                {date && <Time>{date}</Time>}
                <Message
                  user={user}
                  deleted={deleted.length > 0}
                  chatUser={chatUser}
                  showUser={i === 0 || date || messages[i - 1].uuid !== m.uuid}
                  timetoken={m.timetoken}
                  message={propValueOr(message, "text", message)}
                  onUpdate={text => this.updateMessage(m.timetoken, text)}
                  onDelete={text =>
                    this.setState({
                      deleting: { timetoken: m.timetoken, text: text }
                    })
                  }
                />
              </WrapMessage>
            );
          })}
        </Content>
        <Footer>{endedAt}</Footer>
        {endedAt && <Subtitle>Chat room closed.</Subtitle>}
      </MessageListContainer>
    );
  }
}
