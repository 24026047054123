import MATCHES_ACTIONS from "./constants";

const initialState = {
  matches: [],
  pages: 0,
  total: 0,
  err: null,
  isFetching: false
};

const reducers = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case MATCHES_ACTIONS.list.request:
      return {
        ...state,
        isFetching: true
      };
    case MATCHES_ACTIONS.list.success:
      return {
        ...state,
        matches: action.payload.docs,
        page: action.payload.page,
        total: action.payload.pages,
        isFetching: false,
        err: null
      };
    case MATCHES_ACTIONS.list.error:
      return {
        ...state,
        isFetching: false,
        err: payload
      };
    case MATCHES_ACTIONS.reset:
      return initialState;
    default:
      return state;
  }
};

export default reducers;
