import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Content, Header, Title, BackIcon } from '../styled';
import {
  List,
  Text,
  Row,
  SearchBar,
  Error,
  TableEmpty,
  TitleEmpty,
  IconWrap,
  Icon,
  Delete,
  Field,
  WrapIcon,
  Badge,
  WrapField,
  WrapAvatarRow,
  WrapAvatarImage,
  StatusBadge,
  StatusText,
  Buttons,
  WrapButton,
  FullscreenBg,
  FullscreenImage,
  Section,
  SectionHeader,
  Input,
  WrapInput,
} from './styled';
import DeleteDialog from './DeleteDialog/DeleteDialog';
import IdentityConfirmDialog from './IdentityConfirmDialog/IdentityConfirmDialog';
import DeleteIcon from '../../../static/icons/remove.svg';
import { withRouter } from 'react-router-dom';
import { api } from '../../..';
import FormFieldKit from '../../../components/kit/Fields/FormField/FormField';
import FieldTypeAndSelect from '../../../components/smart/FieldTypeAndSelect/FieldTypeAndSelect';
import Loader from '../../../components/presentation/Loader/Loader';
import Table from '../../../components/presentation/Table/Table';
import Pagination from '../../../components/presentation/Pagination/Pagination';
import { COLOR } from '../../../constants/theme';
import withLocationSearch from '../../../hoc/withLocationSearch';
import { getUsername, propValueOr } from '../../../helpers/common';
import moment from 'moment';
import IconSearchGrey from '../../../static/icons/icon-search-grey.svg';
import Button from '../../../components/kit/Button/ButtonKit';

// Icons
import BackArrow from '../../../static/icons/chevron-arrow.svg';
import ButtonKit from '../../../components/kit/Button/ButtonKit';
import { orderBy } from 'lodash';

class User extends Component {
  constructor(props) {
    super();

    this.state = {
      user: {},
      initialUser: {},
      updatedUser: {},
      error: '',
      permissions: [],
      groups: [],
      organizationId: null,
      loading: false,
      deleting: null,
      confirming: null,
      history: [],
      ips: [],
      page: 1,
      total: 1,
      searchWord: '',
      updated: null,
      role: 'read',
      image: null,
      document: null,
    };
  }
  componentDidMount() {
    this.getUser();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.organizationId !== this.state.organizationId) {
      this.getPermissions();
    }
    if (prevProps.location !== this.props.location) {
      this.getUser();
    }
  }

  handleUpdate = params => {
    if (!this.canUpdateUser() && !params) {
      return;
    }

    api.admin.users
      .update(
        this.props.match.params.id,
        Object.assign({}, this.state.updatedUser, params)
      )
      .then(() => {
        this.setState({ updated: 'User has been updated.' });
        this.getUser();
      });
  };

  handleCloseImage = () => {
    if (this.state.image) {
      this.setState({ image: null });
    }
  };

  canUpdateUser = () => {
    const { user, initialUser } = this.state;
    if (user.fullName !== initialUser.fullName) {
      return true;
    }
    if (user.email !== initialUser.email) {
      return true;
    }

    if (user.username !== initialUser.username) {
      return true;
    }

    if (user.twitchUsername !== initialUser.twitchUsername) {
      return true;
    }

    if (user.discordId !== initialUser.discordId) {
      return true;
    }

    if (user.playstationId !== initialUser.playstationId) {
      return true;
    }

    if (user.activisionId !== initialUser.activisionId) {
      return true;
    }

    if (user.steamId !== initialUser.steamId) {
      return true;
    }

    if (user.nintendoId !== initialUser.nintendoId) {
      return true;
    }

    if (user.riotId !== initialUser.riotId) {
      return true;
    }

    if (user.epicId !== initialUser.epicId) {
      return true;
    }

    if (user.eaId !== initialUser.eaId) {
      return true;
    }

    if (user.battlenetId !== initialUser.battlenetId) {
      return true;
    }

    if (user.twitterId !== initialUser.twitterId) {
      return true;
    }

    if (user.gamertag !== initialUser.gamertag) {
      return true;
    }

    if (user.metadata !== initialUser.metadata) {
      return true;
    }

    if (user.phone !== initialUser.phone) {
      return true;
    }

    if (user.dateOfBirth !== initialUser.dateOfBirth) {
      return true;
    }

    if (user.alias !== initialUser.alias) {
      return true;
    }

    if (user.country !== initialUser.country) {
      return true;
    }
    if (user.region !== initialUser.region) {
      return true;
    }
    if (user.city !== initialUser.city) {
      return true;
    }
    if (user.zipcode !== initialUser.zipcode) {
      return true;
    }

    return false;
  };

  getTableColumns = () => {
    return [
      {
        title: 'Action',
        propName: 'action',
        renderItem(rowData, rowIndex) {
          return <Text>{rowData.action}</Text>;
        },
        innerWidth: 'grow',
      },
      {
        title: 'Description',
        propName: 'description',
        renderItem(rowData, rowIndex) {
          return <Text>{rowData.description}</Text>;
        },
        innerWidth: 'grow',
      },
      {
        title: 'Date',
        propName: 'date',
        renderItem(rowData, rowIndex) {
          return (
            <Text>
              {moment(rowData.createdAt).format('MM/DD/YY h:mm:ss A')}
            </Text>
          );
        },
        innerWidth: 'grow',
      },
    ];
  };

  handleAddOrganization = o => {
    if (!o || !o.name) {
      return;
    }

    api.admin.organizations
      .add(this.props.match.params.id, { organizationId: o.id })
      .then(() => {
        this.getUser();
      })
      .catch(e => {
        console.log(e);
        this.setState({
          error: 'Could not add organization',
        });
      });
  };

  handleSearchOrganizations = searchWord => {
    const organizations = this.state.user.organizations || [];
    return api.admin.organizations
      .search({
        q: searchWord,
        organizationIds: organizations.map(o => o.id),
      })
      .then(resp =>
        resp.data.organizations.map(o => ({
          label: o.name,
          value: o.id,
          ...o,
        }))
      );
  };

  deleteOrganization = id => {
    api.admin.organizations
      .delete(this.props.match.params.id, { organizationId: id })
      .then(() => {
        this.getUser();
        this.setState({ deleting: null });
      });
  };

  getUser = () => {
    api.admin.users.get(this.props.match.params.id).then(resp => {
      this.setState({
        user: resp.data,
        organizationId: resp.data?.organizations?.[0]?.id,
        initialUser: resp.data,
      });
      this.getHistory();
      this.getPermissions();
      this.getIps();
    });
  };

  getPermissions = () => {
    api.admin.permissions
      .list({
        userId: this.props.match.params.id,
        organizationId: this.state.organizationId,
        groupId: 1,
      })
      .then(resp => {
        this.setState({ permissions: resp.data, error: null });
      });
  };

  handleAddPermission = (p, role) => {
    if (!p || !p.name) {
      return;
    }
    const body = {
      organizationId: this.state.organizationId,
      permissionId: p.id,
      role: role ? role : this.state.role,
      status: 'approved',
    };

    body.permissions = p.permissions ? p.permissions.map(p => p.id) : undefined;

    api.admin.permissions
      .add(this.props.match.params.id, body)
      .then(() => {
        this.getPermissions();
      })
      .catch(e => {
        console.log(e);
        this.setState({
          error: 'Could not add permission',
        });
      });
  };

  handleSearchPermissions = searchWord => {
    const { permissions } = this.state;
    return api.admin.permissions
      .search({
        q: searchWord,
        permissionIds: permissions.map(p => p.PermissionId),
      })
      .then(resp => [
        ...resp.data.groups.map(g => ({
          label: `Group: ${g.name}`,
          value: g.id,
          ...g,
        })),
        ...resp.data.permissions.map(p => ({
          label: p.name,
          value: p.id,
          ...p,
        })),
      ]);
  };

  updatePermission = (id, body) => {
    return api.admin.permissions.update(id, body).then(() => {
      this.getPermissions();
    });
  };

  deletePermission = id => {
    api.admin.permissions.delete(id).then(() => {
      this.getPermissions();
      this.setState({ deleting: null });
    });
  };

  getHistory = q => {
    this.setState({ loading: true });
    const { page = 1 } = this.props.getLocationSearch();
    const query = { page: page };
    if (q) {
      query.q = q;
    }
    api.admin.history.list(this.props.match.params.id, query).then(resp => {
      const data = propValueOr(resp, 'data', []);
      this.setState({
        history: data.docs,
        page: page,
        total: data.pages,
        loading: false,
      });
    });
  };

  getIps = q => {
    this.setState({ loading: true });
    api.admin.ips.list(this.props.match.params.id).then(resp => {
      const data = propValueOr(resp, 'data', []);
      this.setState({
        ips: data,
      });
    });
  };

  handleSearchHistory = searchWord => {
    this.setState({ searchWord: searchWord });
    return this.getHistory(searchWord);
  };

  render() {
    const {
      user,
      updatedUser,
      permissions,
      organizationId,
      deleting,
      confirming,
      image,
      ips,
      document,
    } = this.state;
    const readOnly = this.props.permission === 'read';

    return (
      <Container>
        {image && (
          <FullscreenBg onClick={this.handleCloseImage}>
            <FullscreenImage src={image} />
          </FullscreenBg>
        )}
        <DeleteDialog
          isOpen={!!deleting}
          onClose={() => this.setState({ deleting: null })}
          onDelete={() => this.deleteOrganization(deleting)}
          id={deleting}
        />
        <IdentityConfirmDialog
          isOpen={!!confirming}
          onClose={() => this.setState({ confirming: null })}
          user={user}
          getUser={this.getUser}
          document={document}
        />
        <Content>
          <Header>
            <BackIcon
              src={BackArrow}
              onClick={() => this.props.history.goBack()}
            />
            <Title>USER INFO</Title>
          </Header>
          <Field>
            <Text>USER ID: {user.id}</Text>
            <Text>{this.state.updated}</Text>
            <Button
              small
              onClick={() =>
                this.handleUpdate({
                  emailConfirmedAt: user.emailConfirmedAt ? null : new Date(),
                })
              }
              appearance={'secondary'}
              color={'rival_red'}
              disabled={false}
            >
              {user.emailConfirmedAt ? 'UNVERIFY EMAIL' : 'VERIFY EMAIL'}
            </Button>
            <Button
              small
              onClick={() =>
                this.handleUpdate({
                  deactivatedAt: user.deactivatedAt ? null : new Date(),
                })
              }
              appearance={'secondary'}
              color={'rival_red'}
              disabled={false}
            >
              {user.deactivatedAt ? 'REACTIVATE USER' : 'DEACTIVATE USER'}
            </Button>
            <Button
              small
              onClick={() => this.handleUpdate({ logout: true })}
              appearance={'secondary'}
              color={'rival_red'}
              disabled={false}
            >
              LOGOUT USER
            </Button>
            <Button
              small
              onClick={() => this.handleUpdate()}
              appearance={'secondary'}
              color={'rival_red'}
              disabled={false}
            >
              UPDATE USER
            </Button>
          </Field>
          <FormFieldKit
            label={'Full Name'}
            fullWidth
            placeholder={'Enter full name...'}
            readOnly={readOnly}
            disabled={readOnly}
            value={user.fullName}
            onChange={val =>
              this.setState({
                user: { ...user, fullName: val },
                updatedUser: { ...updatedUser, fullName: val },
                updated: null,
              })
            }
          />
          <FormFieldKit
            label={`Email Address ${
              user.role !== 'user'
                ? '(used for login, password reset, IP address approval)'
                : ''
            }`}
            fullWidth
            placeholder={'Enter email address...'}
            readOnly={readOnly}
            disabled={readOnly}
            value={user.email}
            onChange={val =>
              this.setState({
                user: { ...user, email: val },
                updatedUser: { ...updatedUser, email: val },
                updated: null,
              })
            }
          />

          <FormFieldKit
            label={
              user.role === 'user'
                ? 'Rival Display Name (currently selected for leaderboards etc. Not used for brackets)'
                : 'Rival Platform Chat Name'
            }
            fullWidth
            placeholder={
              user.role === 'user' ? 'Enter username...' : 'Enter chat name...'
            }
            readOnly={readOnly}
            disabled={readOnly}
            value={getUsername(user)}
            onChange={val =>
              this.setState({
                user: { ...user, username: val },
                updatedUser: { ...updatedUser, username: val },
                updated: null,
              })
            }
          />
          <FormFieldKit
            label={
              user.role === 'user'
                ? 'Alias Name (celebrities use to mask gamer name in player lists & brackets - not match page)'
                : 'Alias (may be used as additional login username)'
            }
            fullWidth
            placeholder={
              user.role === 'user'
                ? `Enter Alias (ex. King Kenny, Pele, Michael Jordan)...`
                : 'Enter Alias (must be email format and unique ex. (admin@person.com))...'
            }
            readOnly={readOnly}
            disabled={readOnly}
            value={user.alias}
            onChange={val =>
              this.setState({
                user: { ...user, alias: val },
                updatedUser: { ...updatedUser, alias: val },
                updated: null,
              })
            }
          />
          {user.role === 'user' && (
            <>
              <Field>
                <FormFieldKit
                  label={'Phone'}
                  fullWidth
                  placeholder={'Enter phone number...'}
                  readOnly={readOnly}
                  disabled={readOnly}
                  value={user.phone}
                  onChange={val =>
                    this.setState({
                      user: { ...user, phone: val },
                      updatedUser: { ...updatedUser, phone: val },
                      updated: null,
                    })
                  }
                />
              </Field>
              <Field>
                <FormFieldKit
                  label={'Date of Birth'}
                  fullWidth
                  placeholder={'Enter date of birth...'}
                  readOnly={readOnly}
                  disabled={readOnly}
                  value={user.dateOfBirth}
                  onChange={val =>
                    this.setState({
                      user: { ...user, dateOfBirth: val },
                      updatedUser: { ...updatedUser, dateOfBirth: val },
                      updated: null,
                    })
                  }
                />
              </Field>
              <Field>
                <FormFieldKit
                  label={'Country'}
                  fullWidth
                  placeholder={'Enter country...'}
                  readOnly={readOnly}
                  disabled={readOnly}
                  value={user.country}
                  onChange={val =>
                    this.setState({
                      user: { ...user, country: val },
                      updatedUser: { ...updatedUser, country: val },
                      updated: null,
                    })
                  }
                />
                <FormFieldKit
                  label={'State / Region'}
                  fullWidth
                  placeholder={'Enter state / region...'}
                  readOnly={readOnly}
                  disabled={readOnly}
                  value={user.region}
                  onChange={val =>
                    this.setState({
                      user: { ...user, region: val },
                      updatedUser: { ...updatedUser, region: val },
                      updated: null,
                    })
                  }
                />
              </Field>
              <Field>
                <FormFieldKit
                  label={'City / Town'}
                  fullWidth
                  placeholder={'Enter city / town...'}
                  readOnly={readOnly}
                  disabled={readOnly}
                  value={user.city}
                  onChange={val =>
                    this.setState({
                      user: { ...user, city: val },
                      updatedUser: { ...updatedUser, city: val },
                      updated: null,
                    })
                  }
                />
                <FormFieldKit
                  label={'Zipcode'}
                  fullWidth
                  placeholder={'Enter zipcode...'}
                  readOnly={readOnly}
                  disabled={readOnly}
                  value={user.zipcode}
                  onChange={val =>
                    this.setState({
                      user: { ...user, zipcode: val },
                      updatedUser: { ...updatedUser, zipcode: val },
                      updated: null,
                    })
                  }
                />
              </Field>
              <Field>
                <FormFieldKit
                  label={'Xbox Gamertag'}
                  fullWidth
                  placeholder={`Enter gamertag...`}
                  readOnly={readOnly}
                  disabled={readOnly}
                  value={user.gamertag}
                  onChange={val =>
                    this.setState({
                      user: { ...user, gamertag: val },
                      updatedUser: { ...updatedUser, gamertag: val },
                      updated: null,
                    })
                  }
                />
                <FormFieldKit
                  label={'PlayStation Id'}
                  fullWidth
                  placeholder={'Enter PlayStation Id..'}
                  readOnly={readOnly}
                  disabled={readOnly}
                  value={user.playstationId}
                  onChange={val =>
                    this.setState({
                      user: { ...user, playstationId: val },
                      updatedUser: { ...updatedUser, playstationId: val },
                      updated: null,
                    })
                  }
                />
              </Field>
              <Field>
                <FormFieldKit
                  label={'Activision Id'}
                  fullWidth
                  placeholder={`Enter Activision Id...`}
                  readOnly={readOnly}
                  disabled={readOnly}
                  value={user.activisionId}
                  onChange={val =>
                    this.setState({
                      user: { ...user, activisionId: val },
                      updatedUser: { ...updatedUser, activisionId: val },
                      updated: null,
                    })
                  }
                />
                <FormFieldKit
                  label={'Steam Id'}
                  fullWidth
                  placeholder={'Enter Steam Id..'}
                  readOnly={readOnly}
                  disabled={readOnly}
                  value={user.steamId}
                  onChange={val =>
                    this.setState({
                      user: { ...user, steamId: val },
                      updatedUser: { ...updatedUser, steamId: val },
                      updated: null,
                    })
                  }
                />
              </Field>
              <Field>
                <FormFieldKit
                  label={'Nintendo Id'}
                  fullWidth
                  placeholder={`Enter Nintendo Id...`}
                  readOnly={readOnly}
                  disabled={readOnly}
                  value={user.nintendoId}
                  onChange={val =>
                    this.setState({
                      user: { ...user, nintendoId: val },
                      updatedUser: { ...updatedUser, nintendoId: val },
                      updated: null,
                    })
                  }
                />
                <FormFieldKit
                  label={'Riot Id'}
                  fullWidth
                  placeholder={'Enter Riot Id..'}
                  readOnly={readOnly}
                  disabled={readOnly}
                  value={user.riotId}
                  onChange={val =>
                    this.setState({
                      user: { ...user, riotId: val },
                      updatedUser: { ...updatedUser, riotId: val },
                      updated: null,
                    })
                  }
                />
              </Field>
              <Field>
                <FormFieldKit
                  label={'Epic Id'}
                  fullWidth
                  placeholder={`Enter Epic Id...`}
                  readOnly={readOnly}
                  disabled={readOnly}
                  value={user.epicId}
                  onChange={val =>
                    this.setState({
                      user: { ...user, epicId: val },
                      updatedUser: { ...updatedUser, epicId: val },
                      updated: null,
                    })
                  }
                />
                <FormFieldKit
                  label={'EA Id'}
                  fullWidth
                  placeholder={'Enter EA Id..'}
                  readOnly={readOnly}
                  disabled={readOnly}
                  value={user.eaId}
                  onChange={val =>
                    this.setState({
                      user: { ...user, eaId: val },
                      updatedUser: { ...updatedUser, eaId: val },
                      updated: null,
                    })
                  }
                />
              </Field>
              <Field>
                <FormFieldKit
                  label={'Battlenet Id'}
                  fullWidth
                  placeholder={'Enter Battlenet Id..'}
                  readOnly={readOnly}
                  disabled={readOnly}
                  value={user.battlenetId}
                  onChange={val =>
                    this.setState({
                      user: { ...user, battlenetId: val },
                      updatedUser: { ...updatedUser, battlenetId: val },
                      updated: null,
                    })
                  }
                />
                <FormFieldKit
                  label={'Twitch Username'}
                  fullWidth
                  placeholder={'Enter twitch username..'}
                  readOnly={readOnly}
                  disabled={readOnly}
                  value={user.twitchUsername}
                  onChange={val =>
                    this.setState({
                      user: { ...user, twitchUsername: val },
                      updatedUser: { ...updatedUser, twitchUsername: val },
                      updated: null,
                    })
                  }
                />
              </Field>
              <Field>
                <FormFieldKit
                  label={'Twitter Username'}
                  fullWidth
                  placeholder={'Enter twitter username..'}
                  readOnly={readOnly}
                  disabled={readOnly}
                  value={user?.twitterId}
                  onChange={val => {
                    let usernameValidation = RegExp(/^@?(\w){0,15}$/);
                    if (val.charAt(0) !== '@') val = '@' + val;
                    if (usernameValidation.test(val)) {
                      this.setState({
                        user: { ...user, twitterId: val },
                        updatedUser: { ...updatedUser, twitterId: val },
                        updated: null,
                      });
                    }
                  }}
                />
                <FormFieldKit
                  label={'Discord Username'}
                  fullWidth
                  placeholder={'Enter discord username..'}
                  readOnly={readOnly}
                  disabled={readOnly}
                  value={user.discordId}
                  inputProps={{ maxLength: 30 }}
                  onChange={val =>
                    this.setState({
                      user: { ...user, discordId: val },
                      updatedUser: { ...updatedUser, discordId: val },
                      updated: null,
                    })
                  }
                />
              </Field>
              <FormFieldKit
                label={'Notes'}
                onChange={val =>
                  this.setState({
                    user: {
                      ...user,
                      metadata: { ...user.metadata, notes: val },
                    },
                    updatedUser: {
                      ...updatedUser,
                      metadata: { ...updatedUser.metadata, notes: val },
                    },
                    updated: null,
                  })
                }
                inputProps={{ maxLength: 200 }}
                placeholder={'Notes about user...'}
                value={
                  this.state.user.metadata && this.state.user.metadata.notes
                }
                fullWidth
                multiline
                disabled={readOnly}
                readOnly={readOnly}
              />
            </>
          )}

          {user.uploads?.filter(({ uploadType }) => uploadType !== 'identity')
            .length > 0 && (
            <Section>
              <SectionHeader>Avatar Requests</SectionHeader>
              {user.uploads
                .filter(({ uploadType }) => uploadType !== 'identity')
                .map(u => (
                  <WrapAvatarRow key={u.id}>
                    <WrapAvatarImage
                      onClick={() =>
                        this.setState({
                          image: u.imageInfo?.thumbnail,
                        })
                      }
                      src={u.imageInfo?.thumbnail}
                    />
                    <StatusBadge>
                      <StatusText>
                        {moment(u.updatedAt).format('M/DD/YY h:mm A')}
                      </StatusText>
                    </StatusBadge>
                    {u?.moderator && (
                      <StatusBadge>
                        <StatusText>{u?.moderator?.username}</StatusText>
                      </StatusBadge>
                    )}
                    {u?.status === 'pending' ? (
                      <Buttons>
                        <WrapButton>
                          <ButtonKit
                            small
                            rounded
                            fullWidth
                            color="red"
                            onClick={() => {
                              api.admin.users
                                .upload(user.id, {
                                  status: 'rejected',
                                  uploadId: u.id,
                                })
                                .then(() => this.getUser());
                            }}
                          >
                            Reject
                          </ButtonKit>
                        </WrapButton>
                        <WrapButton>
                          <ButtonKit
                            small
                            rounded
                            fullWidth
                            color="green"
                            onClick={() => {
                              api.admin.users
                                .upload(user.id, {
                                  status: 'approved',
                                  uploadId: u.id,
                                })
                                .then(() => this.getUser());
                            }}
                          >
                            Approve
                          </ButtonKit>
                        </WrapButton>
                      </Buttons>
                    ) : (
                      <Buttons>
                        <StatusBadge status={u?.status}>
                          <StatusText>{u.status}</StatusText>
                        </StatusBadge>
                        <WrapButton>
                          <ButtonKit
                            small
                            rounded
                            fullWidth
                            onClick={() => {
                              api.admin.users
                                .upload(user.id, {
                                  status: 'pending',
                                  uploadId: u.id,
                                })
                                .then(() => this.getUser());
                            }}
                          >
                            Undo
                          </ButtonKit>
                        </WrapButton>
                      </Buttons>
                    )}
                  </WrapAvatarRow>
                ))}
            </Section>
          )}
          {user.uploads?.filter(
            ({ uploadType, status }) =>
              uploadType === 'identity' && status === 'pending'
          ).length > 0 && (
            <Section>
              <SectionHeader>Identity Confirmation Requests</SectionHeader>
              {user.uploads
                .filter(
                  ({ uploadType, status }) =>
                    uploadType === 'identity' && status === 'pending'
                )
                .map(u => (
                  <WrapAvatarRow key={u.id}>
                    <WrapAvatarImage
                      onClick={() =>
                        this.setState({
                          image: u.imageInfo?.src,
                        })
                      }
                      src={u.imageInfo?.src}
                    />
                    <StatusBadge>
                      <StatusText>
                        {moment(u.updatedAt).format('M/DD/YY h:mm A')}
                      </StatusText>
                    </StatusBadge>
                    <StatusText>
                      {`Expires ${moment(u.expiresAt).fromNow()}`}
                    </StatusText>
                    {u?.moderator && (
                      <StatusBadge>
                        <StatusText>{u?.moderator?.username}</StatusText>
                      </StatusBadge>
                    )}
                    {u?.status === 'pending' ? (
                      <Buttons>
                        <WrapButton>
                          <ButtonKit
                            small
                            rounded
                            fullWidth
                            color="green"
                            onClick={() => {
                              this.setState({
                                confirming: true,
                                document: {
                                  id: u.id,
                                  src: u.imageInfo?.src,
                                  expiresAt: u.expiresAt,
                                  uuid: u.uploadcareUuid,
                                },
                              });
                            }}
                          >
                            Review
                          </ButtonKit>
                        </WrapButton>
                      </Buttons>
                    ) : (
                      <Buttons>
                        <StatusBadge status={u?.status}>
                          <StatusText>{u.status}</StatusText>
                        </StatusBadge>
                        <WrapButton>
                          <ButtonKit
                            small
                            rounded
                            fullWidth
                            onClick={() => {
                              api.admin.users
                                .upload(user.id, {
                                  status: 'pending',
                                  uploadId: u.id,
                                })
                                .then(() => this.getUser());
                            }}
                          >
                            Undo
                          </ButtonKit>
                        </WrapButton>
                      </Buttons>
                    )}
                  </WrapAvatarRow>
                ))}
            </Section>
          )}

          <Section>
            <SectionHeader>Organizations</SectionHeader>
            {!readOnly && (
              <SearchBar>
                <FormFieldKit fullWidth gutterBottom={false}>
                  <FieldTypeAndSelect
                    placeholder={
                      'Search for organization to add to this user profile...'
                    }
                    onChange={o => this.handleAddOrganization(o)}
                    onSearch={this.handleSearchOrganizations}
                  />
                </FormFieldKit>
              </SearchBar>
            )}
            <Error>{this.state.error}</Error>
            {user && user.organizations && (
              <List>
                {user.organizations.length === 0 ? (
                  <TableEmpty>
                    {this.state.loading ? (
                      <Loader isBlock />
                    ) : (
                      <TitleEmpty>NO ORGANIZATIONS</TitleEmpty>
                    )}
                  </TableEmpty>
                ) : (
                  orderBy(user.organizations, ['name'], ['asc']).map(
                    (o, index) => {
                      const orgAccount =
                        o.accountType &&
                        (user.accounts || [])?.find(
                          a => a.type === o.accountType
                        );
                      return (
                        <Row key={index}>
                          <Text>{o.name}</Text>
                          {o.accountType && (
                            <WrapInput>
                              Connected Account:
                              <Input
                                disabled={true}
                                value={orgAccount?.username || 'Not connected'}
                              />
                            </WrapInput>
                          )}
                          {!readOnly && (
                            <Delete>
                              <IconWrap
                                onClick={() =>
                                  this.setState({ deleting: o.id })
                                }
                              >
                                <Icon src={DeleteIcon} />
                              </IconWrap>
                            </Delete>
                          )}
                        </Row>
                      );
                    }
                  )
                )}
              </List>
            )}
          </Section>
          <Section>
            {user.organizations && user.role === 'admin' && (
              <>
                <SectionHeader>Permissions</SectionHeader>
                <FormFieldKit
                  fullWidth
                  label={"Manage This User's Permissions By Organization"}
                  select={true}
                  gutterBottom={10}
                  selectOptions={[
                    { label: 'None', value: null },
                    ...user.organizations.map(o => ({
                      label: o.name,
                      value: o.id,
                    })),
                  ]}
                  placeholder={
                    'Select a current organization to manage permissions'
                  }
                  onChange={id => this.setState({ organizationId: id })}
                  value={organizationId}
                ></FormFieldKit>
                <SearchBar>
                  <FormFieldKit fullWidth gutterBottom={false}>
                    <FieldTypeAndSelect
                      placeholder={
                        'Add permissions (search by group or permission)...'
                      }
                      onChange={p => this.handleAddPermission(p)}
                      onSearch={this.handleSearchPermissions}
                    />
                  </FormFieldKit>
                  <FormFieldKit
                    select={true}
                    gutterBottom={0}
                    selectOptions={[
                      { label: 'read', value: 'read' },
                      { label: 'write', value: 'write' },
                      { label: 'admin', value: 'admin' },
                    ]}
                    onChange={role => {
                      this.setState({ role: role });
                    }}
                    value={this.state.role}
                  ></FormFieldKit>
                </SearchBar>{' '}
              </>
            )}
            <Error>{this.state.error}</Error>
            {this.state.user.role === 'admin' && (
              <List>
                {permissions.length === 0 ? (
                  <TableEmpty>
                    {this.state.loading ? (
                      <Loader isBlock />
                    ) : (
                      <TitleEmpty>NO PERMISSIONS</TitleEmpty>
                    )}
                  </TableEmpty>
                ) : (
                  permissions.map((p, i) => (
                    <Row key={i}>
                      <Text>{p.name}</Text>
                      <Text>{p.description}</Text>
                      <Badge role={p.role} global={!p.OrganizationId && p.role}>
                        {!p.OrganizationId && p.role
                          ? 'Global'
                          : p.role
                          ? 'Local'
                          : 'None'}
                      </Badge>
                      <WrapField>
                        <FormFieldKit
                          fullWidth
                          select={true}
                          gutterBottom={0}
                          selectOptions={[
                            { label: 'read', value: 'read' },
                            { label: 'write', value: 'write' },
                            { label: 'admin', value: 'admin' },
                          ]}
                          placeholder={'N/A'}
                          onChange={role => {
                            if (!p.role) {
                              this.handleAddPermission(p, role);
                            } else {
                              this.updatePermission(p.id, {
                                role: role,
                              });
                            }
                          }}
                          value={p.role}
                        ></FormFieldKit>
                      </WrapField>
                      <Delete>
                        {p.role && (
                          <IconWrap onClick={() => this.deletePermission(p.id)}>
                            <Icon src={DeleteIcon} />
                          </IconWrap>
                        )}
                      </Delete>
                    </Row>
                  ))
                )}
              </List>
            )}
          </Section>
          {this.state.user.role === 'admin' && (
            <Section>
              <SectionHeader>Approved IP Addresses</SectionHeader>
              <List>
                {ips.map((ip, i) => (
                  <Row key={i}>
                    <Text>{ip.address}</Text>
                    {/* {!readOnly && (
                    <Delete>
                      <IconWrap
                        onClick={() => this.setState({ deleting: ip.id })}
                      >
                        <Icon src={DeleteIcon} />
                      </IconWrap>
                    </Delete>
                  )} */}
                  </Row>
                ))}
              </List>
            </Section>
          )}
          <Section>
            <SectionHeader>History</SectionHeader>
            <FormFieldKit
              fullWidth
              value={this.state.searchWord}
              placeholder="Search by action..."
              onChange={this.handleSearchHistory}
              icon={
                <WrapIcon>
                  <img src={IconSearchGrey} alt="search icon" />
                </WrapIcon>
              }
              iconAlign="left"
            />
            <List>
              <Table
                columns={this.getTableColumns()}
                data={this.state.history}
                headerBgColor={COLOR.BG_PRIMARY}
                headerColor={COLOR.GREY}
                renderContentForEmptyTable={() => (
                  <TableEmpty>
                    {this.state.loading ? (
                      <Loader isBlock />
                    ) : (
                      <TitleEmpty>NO HISTORY</TitleEmpty>
                    )}
                  </TableEmpty>
                )}
              />
              <Pagination withUrl total={this.state.total} />
            </List>
          </Section>
        </Content>
      </Container>
    );
  }
}

const mapStateToProps = state => ({});
const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withLocationSearch(User)));
