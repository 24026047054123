import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import RouteGames from "./RouteGames";
import { fetchGames, resetGames, fetchGame } from "../../store/games/actions";
import { fetchConsolesAction } from "../../store/consoles/actions";
import { fetchAllGameSeries } from "../../store/gameSeries/actions";
import PermissionDenied from "../denied/PermissionDenied";

class RouteGamesContainer extends Component {
  componentDidMount() {
    this.props.fetchGames();
    this.props.fetchAllGameSeries();
    this.props.fetchConsolesAction();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      this.props.fetchGames();
      this.props.fetchAllGameSeries();
    }
  }

  componentWillUnmount() {
    this.props.resetGames();
  }

  render() {
    const { permission } = this.props;

    if (!permission) {
      return <PermissionDenied />;
    }
    return <RouteGames {...this.props} />;
  }
}

const mapStateProps = state => ({
  user: state.user.info,
  games: state.gamesState.games,
  gameSeries: state.gameSeriesState.series,
  allSeries: state.gameSeriesState.allSeries,
  game: state.gamesState.game,
  consoles: state.consoles.list,
  isFetching: state.gamesState.isFetching
});

const mapDispatchToProps = {
  fetchGames,
  fetchGame,
  fetchAllGameSeries,
  resetGames,
  fetchConsolesAction
};

export default connect(
  mapStateProps,
  mapDispatchToProps
)(withRouter(RouteGamesContainer));
