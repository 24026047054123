import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import {
  FONTS_BY_VARIANTS,
  LETTER_SPACING_BY_VARIANTS,
  SHOULD_UPPERCASE_BY_VARIANT
} from "./constants";
import { COLOR } from "../../../constants/theme";
import { addUnit } from "../../../helpers/common";

export const TypographyKitSD = styled.span`
  margin: 0;
  ${_hasGutterBottom};
  color: ${({ color }) => color || COLOR.TEXT_2};
  font: ${_getFontByVariant};
  text-align: ${({ textAlign }) => textAlign};
  ${_shouldUppercaseByVariant};
  ${_writeLetterSpacingByPropsOrVariant};

  & & {
    margin-left: ${({ itemMarginLeft }) => addUnit(itemMarginLeft)};
  }
`;
TypographyKitSD.propsTypes = {
  children: PropTypes.node,
  /**
   * The component used for the root node.
   * Either a string to use a DOM element or a component.
   * By default, it is span.
   */
  as: PropTypes.oneOfType([
    PropTypes.oneOf([
      "span",
      "strong",
      "h1",
      "h2",
      "h3",
      "h4",
      "h5",
      "h6",
      "p",
      "div"
    ]),
    PropTypes.element
  ]),
  /**
   * Applies the typography styles.
   */
  variant: PropTypes.oneOf([
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "p1",
    "p2",
    "caption1",
    "caption2",
    "caption3",
    "label"
  ]),
  textAlign: PropTypes.oneOf(["left", "center", "right"]),
  /**
   * Number (for pixels) or string this units
   * */
  letterSpacing: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /*
   * 	Bool or number (in pixels) or string value with units. If just true will set '0.35em' by default/
   * */
  gutterBottom: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
    PropTypes.string
  ]),
  itemMarginLeft: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};
TypographyKitSD.defaultProps = {
  testAlign: "left",
  variant: "p1",
  itemMarginLeft: 6
};

function _getFontByVariant(props) {
  const { variant } = props;
  return FONTS_BY_VARIANTS[variant] || FONTS_BY_VARIANTS.p1;
}
function _shouldUppercaseByVariant(props) {
  const { variant, uppercase } = props;
  return (
    (SHOULD_UPPERCASE_BY_VARIANT[variant] || uppercase) &&
    css`
      text-transform: uppercase;
    `
  );
}
function _writeLetterSpacingByPropsOrVariant(props) {
  const { variant, letterSpacing } = props;
  const ls = letterSpacing || LETTER_SPACING_BY_VARIANTS[variant];
  return (
    ls &&
    css`
      letter-spacing: ${addUnit(ls)};
    `
  );
}
function _hasGutterBottom(props) {
  const { gutterBottom } = props;
  return (
    gutterBottom &&
    css`
      margin-bottom: ${gutterBottom === true
        ? "0.35em"
        : addUnit(gutterBottom)};
    `
  );
}
