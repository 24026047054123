import React from "react";
import {
  Item,
  Badge,
  Info,
  Status,
  WrapCoinsCount,
  CoinsCount,
  CoinsIcon
} from "./styled";
import {
  numberToThousandsString,
  propValueOr
} from "../../../../helpers/common";

// Icons
import CoinIcon from "../../../../static/icons/rival_coin.svg";

const WalletListItem = ({ link, wallet }) => {
  const renderMasterInfo = wallet => {
    return (
      <Info>
        <Badge>{`ID: ${wallet.id}`}</Badge>
        <Badge>MASTER</Badge>
      </Info>
    );
  };

  const renderOrganizationInfo = wallet => {
    const org = propValueOr(wallet, "organization");
    return (
      <Info>
        <Badge>{`ID: ${wallet.id}`}</Badge>
        {!!org && (
          <Info>
            <Badge width={200}>{org.displayName}</Badge>
            <Badge width={125}>{`ORG ID: ` + org.id}</Badge>
          </Info>
        )}
      </Info>
    );
  };

  const renderTournamentInfo = wallet => {
    const tournament = propValueOr(wallet, "tournament");
    return (
      <Info>
        <Badge>{`ID: ${wallet.id}`}</Badge>
        {!!tournament && (
          <Info>
            <Badge width={200}>{tournament.name}</Badge>
            <Badge width={200}>{`TOURNAMENT ID: ` + tournament.id}</Badge>
          </Info>
        )}
      </Info>
    );
  };

  const renderMatchInfo = wallet => {
    const match = propValueOr(wallet, "match");
    return (
      <Info>
        <Badge>{`ID : ${wallet.id}`}</Badge>
        {!!match && <Badge width={150}>{`MATCH ID: ${match.id}`}</Badge>}
      </Info>
    );
  };

  const renderPlayerInfo = wallet => {
    const user = propValueOr(wallet, "user");
    return (
      <Info>
        <Badge>{`ID: ${wallet.id}`}</Badge>
        {!!user && (
          <Info>
            <Badge width={200}>{user.username}</Badge>
            <Badge width={150}>{`USER ID: ` + user.id}</Badge>
          </Info>
        )}
      </Info>
    );
  };

  let infoSection = null;
  switch (wallet.walletType) {
    case "organization":
      infoSection = renderOrganizationInfo(wallet);
      break;
    case "tournament":
      infoSection = renderTournamentInfo(wallet);
      break;
    case "user":
      infoSection = renderPlayerInfo(wallet);
      break;
    case "match":
      infoSection = renderMatchInfo(wallet);
      break;
    case "master":
    default:
      infoSection = renderMasterInfo(wallet);
      break;
  }

  return (
    <Item key={wallet.id} to={link}>
      {infoSection}
      <Info>
        <WrapCoinsCount>
          <CoinsCount>{numberToThousandsString(wallet.total)}</CoinsCount>
          <CoinsIcon src={CoinIcon} />
        </WrapCoinsCount>
        <Status status={wallet.status}>{wallet.status}</Status>
      </Info>
    </Item>
  );
};

export default WalletListItem;
