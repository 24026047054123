import React, { Component } from "react";
import { connect } from "react-redux";
import { Content, Container } from "./styled";
import { withRouter } from "react-router-dom";
import tournamentRoutes from "./routes";
import { Route, Switch, Redirect } from "react-router-dom";
import { verifyPermission } from "../../helpers/common";

class RouteTournament extends Component {
  renderDesktopAside = () => {
    const adminRoutesInfo = tournamentRoutes.reduce((acc, item) => {
      if (!acc[item.asideComponent]) {
        acc[item.asideComponent] = {
          path: [],
          component: item.asideComponent
        };
      }

      acc[item.asideComponent].path.push(item.path);

      return acc;
    }, {});

    return (
      <Switch>
        {Object.values(adminRoutesInfo).map(route => {
          const AsideComponent = route.component;

          return (
            <Route
              exact
              key={route.path}
              path={route.path}
              render={props => (
                <AsideComponent {...props} user={this.props.user} />
              )}
            />
          );
        })}
      </Switch>
    );
  };

  renderDesktopContent = () => (
    <Switch>
      {tournamentRoutes.map(route => (
        <Route
          exact
          key={route.path}
          path={route.path}
          render={props => {
            const ContentComponent = route.contentComponent;
            return (
              <ContentComponent
                {...props}
                user={this.props.user}
                permission={verifyPermission(
                  route.path,
                  this.props.permissions
                )}
              />
            );
          }}
        />
      ))}
      <Route render={() => <Redirect to={"/tournaments"} />} />
    </Switch>
  );

  render() {
    return (
      <Container>
        <Content>{this.renderDesktopContent()}</Content>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  organizations: state.organizationsState.organizations,
  permissions: state.permissionsState.permissions
});
const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RouteTournament));
