import styled from "styled-components";
import { COLOR, FONT_WEIGHT, FONT_FAMILY } from "../../../constants/theme";
import iconStreamNotFound from "../../../static/icons/stream-not-found.svg";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Navigation = styled.div`
  display: flex;
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 30px;

  border: 1px solid
    ${({ isActive, isHome }) =>
      isActive ? (isHome ? COLOR.NAVY_BLUE : COLOR.ROUGE) : COLOR.BG_SECONDARY};
  background-color: ${({ isActive, isHome }) =>
    isActive ? (isHome ? COLOR.NAVY_BLUE : COLOR.ROUGE) : COLOR.BG_PRIMARY};

  color: ${COLOR.WHITE};
  font: ${FONT_WEIGHT.MEDIUM} 14px / 21px ${FONT_FAMILY.THIN};
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  cursor: pointer;
`;

export const WrapStream = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  background-color: ${COLOR.BG_ACCENT};

  color: ${COLOR.TEXT_4};
  font: 12px / 1.2 ${FONT_FAMILY.THIN};
  text-align: center;
`;

export const Stream = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
`;

export const Content = styled.div`
  margin: auto;
`;

export const Icon = styled.div`
	width: 42px;
	height: 41px;
	margin: 0 auto 14px;
	background: url("${iconStreamNotFound}") center no-repeat;
`;

export const Title = styled.div`
  margin-bottom: 4px;
  font-size: 16px;
  font-weight: ${FONT_WEIGHT.MEDIUM};
`;

export const Text = styled.div`
  max-width: 220px;

  a {
    color: #1065fb;

    &:hover {
      text-decoration: none;
    }
  }
`;

export const IFrame = styled.iframe`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  border: 0;
  overflow: hidden;
`;
