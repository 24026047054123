import React, { Component } from "react";
import { Container, Content, Button, Error } from "./styled";
import Loader from "../../../components/presentation/Loader/Loader";
import { api } from "../../..";
import { propValueOr } from "../../../helpers/common";
import { CSVLink } from "react-csv";
import moment from "moment";
import PermissionDenied from "../../denied/PermissionDenied";
import FormFieldKit from "../../../components/kit/Fields/FormField/FormField";

class Tournament extends Component {
  constructor(props) {
    super();

    this.state = {
      tournamentId: null,
      report: [],
      error: null
    };
  }

  getTournament = () => {
    if (this.state.tournamentId) {
      const { permission } = this.props;
      if (["admin", "write"].includes(permission)) {
        api.admin.tournament
          .report(this.state.tournamentId)
          .then(resp => {
            if (propValueOr(resp, "data", []).length === 0) {
              this.setState({
                error: "No user report for this tournament"
              });
            } else {
              this.setState({
                report: propValueOr(resp, "data", []),
                error: null
              });
            }
          })
          .catch(e =>
            this.setState({
              error:
                "Tournament not found (make sure you're in the correct organization)"
            })
          );
      }
    }
  };

  render() {
    const { tournamentId, report, loading, error } = this.state;
    const { slug, permission } = this.props;
    if (!permission) {
      return <PermissionDenied />;
    }
    return (
      <Container>
        <Content>
          {loading ? (
            <Loader isBlock />
          ) : (
            <>
              <FormFieldKit
                label={"ID of the Tournament"}
                fullWidth
                placeholder={
                  "Enter tournament id (must be in current organization)..."
                }
                value={tournamentId}
                onChange={val => this.setState({ tournamentId: val })}
              />
              <Button
                fullWidth
                shape={"rounded"}
                color={"rival_red"}
                onClick={this.getTournament}
              >
                GET TOURNAMENT
              </Button>
              {report.length > 0 && (
                <CSVLink
                  filename={`${slug}-${moment().format("MM-DD-YY")}.csv`}
                  data={report}
                >
                  <Button fullWidth small appearance={"secondary"}>
                    Download Tournament User List
                  </Button>
                </CSVLink>
              )}
              <Error>{error}</Error>
            </>
          )}
        </Content>
      </Container>
    );
  }
}

export default Tournament;
