import React from "react";
import {
  Container,
  Header,
  Title,
  Content,
  WrapGameCard,
  GameCard,
  WrapText,
  WrapButtons
} from "./styled";
import DialogKit from "../../../components/kit/Dialog/DialogKit";
import ButtonKit from "../../../components/kit/Button/ButtonKit";
import { propValueOr } from "../../../helpers/common";

const RemoveGameDialog = ({ game, onRemove, isOpen, onClose }) => {
  const renderDialog = () => {
    return (
      <Container>
        <Header>
          <Title>Remove Organization Game</Title>
        </Header>
        <Content>
          <WrapGameCard key={game.id}>
            <GameCard
              src={propValueOr(
                game,
                "game.imageInfo.original",
                propValueOr(game, "imageInfo.original")
              )}
              onError={e => (e.target.src = "")}
              alt=""
            />
          </WrapGameCard>
          <WrapText>
            {`Are you sure you want to remove ${game?.game?.name} from the organizations game list?`}
          </WrapText>
          <WrapButtons>
            <ButtonKit appearance={"secondary"} fullWidth onClick={onClose}>
              Cancel
            </ButtonKit>
            <ButtonKit
              appearance={"primary"}
              color={"red"}
              fullWidth
              onClick={() => onRemove(game?.id)}
            >
              Remove
            </ButtonKit>
          </WrapButtons>
        </Content>
      </Container>
    );
  };

  return (
    <DialogKit
      isOpen={isOpen}
      onClose={onClose}
      renderCustom={renderDialog}
      isMobile
    />
  );
};

export default RemoveGameDialog;
