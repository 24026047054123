import styled, { css } from "styled-components";
import PropTypes from "prop-types";

import iconBurgerMenu from "../../../static/icons/icon-burger-menu.png";
import { Link, NavLink } from "react-router-dom";
import { COLOR, FONT_FAMILY, FONT_WEIGHT } from "../../../constants/theme";
import breakpoints from "../../../helpers/breakpoints";
import Grid from "../../kit/Grid/Grid";
import React from "react";
import { logoWhite } from "../../../constants/logo";
import { getSubdomainFromHost, propValueOr } from "../../../helpers/common";

export const HeaderSD = styled.header`
  transition: all 0.3s;
  background-color: ${({ isTransparent }) =>
    isTransparent
      ? "transparent"
      : getSubdomainFromHost()
          .toLowerCase()
          .includes("mls")
      ? "#192756"
      : COLOR.BG_HEADER};
`;
HeaderSD.propTypes = {
  isTransparent: PropTypes.bool
};

export const HeaderWrapTopLineSD = styled.div`
  position: relative;
  z-index: 1;
`;

export const HeaderNavLineSD = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-basis: 0;
  padding: 25px 42px 20px 20px;
  text-align: center;

  ${({ isStickyMode }) =>
    isStickyMode &&
    css`
      box-shadow: 0 1px 6px rgba(0, 0, 0, 0.5);
    `};

  transition: all 0.3s;

  ${_IF_HEADER_NAV_LINE_IS_TRANSPARENT_};

  ${breakpoints.down("xs")} {
    padding: 20px 15px 15px;
  }
`;
HeaderNavLineSD.propTypes = {
  isTransparent: PropTypes.bool,
  isStickyMode: PropTypes.bool
};

export const HeaderNavLineWrapLogoSD = styled.div``;

export const HeaderNavLinePlaceholderSD = styled.div`
  width: 63px;
  ${breakpoints.up("sm")} {
    display: none;
  }
`;

export const HeaderNavLineWrapNavSD = styled.div`
  margin-left: 10px;

  ${breakpoints.down("sm")} {
    display: none;
  }
`;
export const HeaderNavLineWrapActionSD = styled.div`
  display: flex;
  align-items: center;
  ${breakpoints.down("sm")} {
    display: none;
  }
`;

export const HeaderNavLineWrapToggleSD = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 100%;
  /* background-color: ${COLOR.ROUGE}; */
  cursor: pointer;

  ${breakpoints.down("sm")} {
    display: flex;
  }

  ${breakpoints.down("xs")} {
    width: 44px;
    height: 100%;
  }
`;

export const HeaderToggleSD = styled.div`
  width: 19px;
  height: 17px;
  background: url(${iconBurgerMenu}) 0 0 no-repeat;
  &:hover {
    transition: 0.3s;
    opacity: 0.8;
  }
`;

export const HeaderNavLineWrapPopupSD = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  display: none;
  overflow: auto;

  background: rgba(0, 0, 0, 0.5);

  box-shadow: 0 1px 15px 0 rgba(0, 0, 0, 0.1);

  ${breakpoints.down("md")} {
    display: block;
  }
`;

export const HeaderNavLineWrapPopupContentSD = styled.div`
  max-width: 300px;
  margin-left: auto;
`;

export const HeaderButtonYellowSD = styled(Link)`
  display: block;
  width: 120px;
  height: 50px;

  background-color: #ffb700;

  color: #000;
  font: ${FONT_WEIGHT.BOLD} 13px / 50px ${FONT_FAMILY.THIN};
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;

  cursor: pointer;
`;

export const HeaderButtonSignUpSD = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 63px;
  height: 27px;
  padding: 7px 6px;
  border-radius: 15px;
  background-color: ${({ login }) => (login ? "#053385" : COLOR.ROUGE)};
  color: ${COLOR.FG_PRIMARY};
  font: ${FONT_WEIGHT.NORMAL} 12px ${FONT_FAMILY.THIN};
  text-align: center;
  // text-transform: uppercase;
  text-decoration: none;

  cursor: pointer;

  :hover {
    opacity: 0.9;
  }
`;

export const HeaderLogoSD = styled(({ isTransparent, ...rest }) => (
  <Link {...rest} />
))`
  display: block;
  width: ${props => propValueOr(props, "logo.logoWidth", "127px")};
  height: ${props => propValueOr(props, "logo.logoHeight", "15px")};

  background: url(${({ logo, path }) =>
      propValueOr(logo, "logo") || logoWhite(path)})
    0 0 no-repeat;
  background-size: 100%;

  ${breakpoints.down("sm")} {
    ${props =>
      propValueOr(props, "logo.mobile.logo") &&
      `background-image: url(${props.logo.mobile.logo})`};
    width: ${props => propValueOr(props, "logo.mobile.logoWidth", undefined)};
    height: ${props => propValueOr(props, "logo.mobile.logoHeight", undefined)};
  }
`;
HeaderLogoSD.propTypes = {
  isTransparent: PropTypes.bool
};

export const HeaderMobileDropDownMenuSD = styled.div`
  height: calc(100vh - ${({ topBarHeight }) => topBarHeight}px);
  background-color: ${COLOR.BG_SECONDARY};
  overflow: auto;
`;

export const HeaderWrapMobileDropDownMenuTopGridSD = styled.div`
  padding: 20px 20px;
`;
export const HeaderMobileDropDownMenuTopGridSD = styled(Grid)``;
export const HeaderMobileNavSD = styled.div`
  height: calc(100vh - 50px);
  background-color: ${COLOR.BG_SECONDARY};
  overflow: auto;
`;

export const HeaderMobileNavListSD = styled.div``;

export const HeaderMobileNavItemSD = styled.div`
  margin: 0;
  border-bottom: 1px solid ${COLOR.BORDER};
  &:hover {
    background-color: ${COLOR.BG_PRIMARY};
    transition: 0.3s;
  }
`;

export const HeaderMobileNavLinkSD = styled(NavLink)`
  display: block;
  padding: 24px;

  color: ${COLOR.FG_PRIMARY};
  font: ${FONT_WEIGHT.MEDIUM} 12px / 1 ${FONT_FAMILY.THIN};
  text-transform: uppercase;
  text-decoration: none;

  &.active {
    font-weight: ${FONT_WEIGHT.EXTRA_BOLD};
  }
`;

function _IF_HEADER_NAV_LINE_IS_TRANSPARENT_(props) {
  const { isTransparent, isStickyMode } = props;

  return (
    (isTransparent || isStickyMode) &&
    css`
      // position: relative;
      ${isStickyMode &&
        css`
          // padding-top: 75px;
        `};

      ${breakpoints.down("md")} {
        margin-bottom: 0;
      }
    `
  );
}

export const HeaderBtnLoginSD = styled(({ className }) => (
  <Link to={"/login"} className={className}>
    <span className="__text">Log In</span>
  </Link>
))`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 63px;
  height: 27px;
  padding: 7px 6px;
  border-radius: 15px;
  background-color: ${COLOR.ROUGE};
  color: ${COLOR.FG_PRIMARY};
  font: ${FONT_WEIGHT.NORMAL} 12px ${FONT_FAMILY.THIN};
  text-align: center;
  // text-transform: uppercase;
  text-decoration: none;

  cursor: pointer;
  ${breakpoints.down("xs")} {
    font: ${FONT_WEIGHT.NORMAL} 11px ${FONT_FAMILY.THIN};
    width: 50px;
  }
  :hover {
    opacity: 0.9;
  }
`;

export const HeaderNavLineLeftOfLogoSD = styled.div`
  width: 63px;
  ${breakpoints.up("sm")} {
    display: none;
  }
`;
export const HeaderBtnLoginLynnWrapSD = styled.div`
  margin-right: 10px;
  width: 63px;
  ${breakpoints.down("xs")} {
    display: none;
  }
`;
export const HeaderBtnLoginWrapSD = styled.div`
  margin-right: 15px;
  width: 63px;
  ${breakpoints.down("xs")} {
    display: none;
  }
`;
export const HeaderBtnLoginWrapOuterSD = styled.div`
  width: 63px;
`;

export const WrapNav = styled.div`
  ${breakpoints.down("sm")} {
    display: none;
  }
`;
export const HeaderMobilePopupSD = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  display: none;
  overflow: auto;

  background: rgba(0, 0, 0, 0.5);

  box-shadow: 0 1px 15px 0 rgba(0, 0, 0, 0.1);

  ${breakpoints.down("sm")} {
    display: block;
  }
`;
export const HeaderMobilePopupContentSD = styled.div`
  max-width: 300px;
  margin-left: auto;
`;
