import React from "react";
import { Link } from "./styled";

function findLinkEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges(character => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === "LINK"
    );
  }, callback);
}

const config = () => {
  // Styles are overwritten instead of merged as merging causes a lot of confusion.

  // Why? Because when merging a developer needs to know all of the underlying
  // styles which needs a deep dive into the code. Merging also makes it prone to
  // errors when upgrading as basically every styling change would become a major
  // breaking change. 1px of an increased padding can break a whole layout.

  const { target = "_self", rel = "noreferrer noopener" } = config;

  const LinkElement = props => {
    const { url } = props.contentState.getEntity(props.entityKey).getData();
    return (
      <Link
        href={url}
        target={target}
        rel={rel}
        {...props}
        onClick={() => window.open(url, target)}
      >
        {props.children}
      </Link>
    );
  };

  return {
    decorators: [
      {
        strategy: findLinkEntities,
        component: LinkElement
      }
    ]
  };
};
export default config;
