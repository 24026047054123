import React from "react";
import PropTypes from "prop-types";
import { ButtonIconInnerFlexKitSD, ButtonIconKitSD } from "./styled";

function ButtonIconKit(props) {
  const { width, height } = props;

  return (
    <ButtonIconKitSD width={width} height={height} {...props}>
      <ButtonIconInnerFlexKitSD>{props.children}</ButtonIconInnerFlexKitSD>
    </ButtonIconKitSD>
  );
}

ButtonIconKit.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  stroke: PropTypes.string,
  fill: PropTypes.string,
  strokeHover: PropTypes.string,
  fillHover: PropTypes.string,
  hoverOpacity: PropTypes.bool,
  type: PropTypes.string
};
ButtonIconKit.defaultProps = {
  hoverOpacity: true,
  type: "button"
};

export default ButtonIconKit;
