import styled from "styled-components";
import { COLOR, FONT_SIZE } from "../../../../constants/theme";

export const ChipSD = styled.div`
  display: inline-flex;
  align-items: center;
  max-width: 100%;
  padding: 4px 3px 4px 6px;
  background-color: ${props =>
    props.incorrect ? COLOR.CHERRY_RED : COLOR.RIVAL_RED};
  border-radius: 2px;
`;

export const ChipTitleSD = styled.span`
  margin-right: 5px;
  color: ${COLOR.TEXT_3};
  font-size: ${FONT_SIZE.LABEL}px;
  line-height: 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const ChipWrapIconSD = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  cursor: pointer;
  font-size: 12px;
  color: ${COLOR.TEXT_3};
`;

export const ChipIconSD = styled.img`
  max-width: 100%;
  height: auto;
  object-fit: contain;
`;
