import React, { Component, Fragment } from "react";
import { debounce } from "../helpers/common";

function withWidth(ReceivedComponent) {
  return class WithWidth extends Component {
    state = {
      width: null
    };

    iFrameRef = React.createRef();

    constructor(props) {
      super(props);
      this.setWidth = debounce(this.setWidth, 100);
    }

    componentDidMount() {
      this.iFrameRef.current.contentWindow.addEventListener(
        "resize",
        this.setWidth
      );
      this.setWidth();
    }

    setWidth = () => {
      if (!this.iFrameRef) return;
      const width = this.iFrameRef.current.offsetWidth;
      this.setState({ width });
    };

    componentWillUnmount() {
      this.iFrameRef.current.contentWindow.removeEventListener(
        "resize",
        this.setWidth
      );
      this.iFrameRef = null;
    }

    render() {
      const { width } = this.state;

      return (
        <Fragment>
          <div style={{ overflow: "hidden", position: "relative" }}>
            <iframe
              ref={this.iFrameRef}
              title="iframe"
              style={{
                position: "absolute",
                width: "100%",
                zIndex: -1,
                border: "none",
                pointerEvents: "none"
              }}
            />
          </div>

          {width !== null && (
            <ReceivedComponent {...this.props} width={width} />
          )}
        </Fragment>
      );
    }
  };
}

export default withWidth;
