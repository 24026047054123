import React, { Component } from "react";
import PropTypes from "prop-types";
import MenuKit from "../../kit/Menu/MenuKit";
import { debounce, safePropValueOr } from "../../../helpers/common";

import {
  FieldTypeAndSelectInputKitSD,
  FieldTypeAndSelectPopupOptionIconSD,
  FieldTypeAndSelectPopupOptionInnerSD,
  FieldTypeAndSelectPopupOptionSD,
  FieldTypeAndSelectPopupOptionTextSD,
  FieldTypeAndSelectPopupSD,
  FieldTypeAndSelectSD,
  PlayerCount,
  Token
} from "./styled";
import IconThreeDots from "../../kit/Icon/IconThreeDots";
import ButtonIconKit from "../../kit/ButtonIcon/ButtonIconKit";
import { LabelKitSD } from "../../kit/Fields/styled";

class FieldTypeAndSelect extends Component {
  static propTypes = {
    /** pass the search word. Should return Promise with array of options*/
    onSearch: PropTypes.func.isRequired,
    /** pass selected options */
    onChange: PropTypes.func.isRequired,
    onKeyDown: PropTypes.func,
    /** selected options*/
    selectedOption: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    }),
    defaultOptions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      })
    ),
    onBlur: PropTypes.func,
    placeholder: PropTypes.string,
    hasError: PropTypes.bool,
    noResultText: PropTypes.node,
    readOnly: PropTypes.bool,
    icon: PropTypes.node,
    searchWord: PropTypes.string,
    showItemId: PropTypes.bool,
    skipKeys: PropTypes.arrayOf(PropTypes.string)
  };

  static defaultProps = {
    defaultOptions: [],
    searchWord: "",
    noResultText: "0 Results found",
    showItemId: false,
    skipKeys: []
  };

  constructor(props) {
    super(props);

    this.listRef = React.createRef();

    this.state = {
      searchWord: safePropValueOr(props, "selectedOption.label", ""),
      options: props.defaultOptions,
      isPopupOpen: false
    };
  }

  componentDidMount() {
    this.debouncedSearchOptions = debounce(this.searchOptions, 300);
    this.searchOptions();
  }

  componentDidUpdate(prevProps, prevState, s) {
    const { selectedOption } = this.props;
    if (
      JSON.stringify(prevProps.selectedOption) !==
        JSON.stringify(selectedOption) &&
      selectedOption
    ) {
      this.setState({ searchWord: selectedOption.label }, this.searchOptions);
    }
  }

  isValueSelected = () => {
    return !!this.props.selectedOption;
  };

  handleBlurSearchWord = () => {
    setTimeout(() => {
      const { onBlur } = this.props;

      this.setState({ isPopupOpen: false });
      if (typeof onBlur === "function") {
        onBlur();
      }
    }, 200); // todo: for fast fix that popup is hidden before click was fired
  };

  handleFocusSearchWord = () => {
    this.setState({ isPopupOpen: true });
  };

  handleChangeSearchWord = e => {
    let searchWord = e.target.value;

    if (this.props.skipKeys.length > 0) {
      searchWord =
        this.props.skipKeys.indexOf(searchWord) > -1 ? "" : searchWord;
    }

    const optionWithSearchWordInLabel =
      this.state.options.find(opt => opt.label === searchWord) || null;

    this.props.onChange(optionWithSearchWordInLabel);
    this.setState(
      { searchWord },
      this.debouncedSearchOptions(false, !!this.state.searchWord && !searchWord)
    );
  };

  handleSelectOption = (option, notSelect) => {
    this.setState({ searchWord: "", selectedOption: { ...option } });
    this.props.onChange(option, notSelect);
  };

  handleOnKeyDown = e => {
    this.props.onKeyDown &&
      this.props.onKeyDown(e, () => this.setState({ searchWord: "" }));
  };

  searchOptions = (newPage, resetPage) => {
    this.props
      .onSearch(this.state.searchWord, newPage, resetPage)
      .then(options => {
        this.setState({ options });
      });
  };

  renderOptions = () => {
    const { options, isPopupOpen, searchWord } = this.state;
    const {
      noResultText,
      icon,
      showItemId,
      onShowPopup,
      onNewPage,
      isTeam
    } = this.props;

    if (!isPopupOpen) return null;

    return (
      <FieldTypeAndSelectPopupSD
        ref={this.listRef}
        onScroll={e => {
          if (
            !!onNewPage &&
            !searchWord &&
            e?.currentTarget?.scrollTop >=
              e?.currentTarget?.scrollHeight - e?.currentTarget?.clientHeight
          ) {
            this.debouncedSearchOptions(true);
          }
        }}
      >
        {options.length ? (
          <MenuKit items={options} propNameForText={"label"}>
            {item => (
              <FieldTypeAndSelectPopupOptionSD
                onClick={() => this.handleSelectOption(item)}
              >
                <FieldTypeAndSelectPopupOptionInnerSD>
                  <FieldTypeAndSelectPopupOptionTextSD iconAfter={!!icon}>
                    <span>
                      <span>
                        {item.label}
                        {isTeam && <Token>#{item.token}</Token>}
                      </span>
                      {showItemId && item.id && <span>ID:{item.id}</span>}
                    </span>
                  </FieldTypeAndSelectPopupOptionTextSD>
                  {isTeam && (
                    <PlayerCount>{item.playerCount} Players</PlayerCount>
                  )}
                  {icon && (
                    <FieldTypeAndSelectPopupOptionIconSD>
                      {" "}
                      {icon}{" "}
                    </FieldTypeAndSelectPopupOptionIconSD>
                  )}
                  {typeof onShowPopup === "function" && (
                    <ButtonIconKit
                      width={12}
                      height={20}
                      onClick={async e => {
                        e.stopPropagation();
                        const notSelect = await onShowPopup(item);
                        this.handleSelectOption(item, notSelect);
                      }}
                    >
                      <IconThreeDots width={4} />
                    </ButtonIconKit>
                  )}
                </FieldTypeAndSelectPopupOptionInnerSD>
              </FieldTypeAndSelectPopupOptionSD>
            )}
          </MenuKit>
        ) : (
          <FieldTypeAndSelectPopupOptionSD>
            <FieldTypeAndSelectPopupOptionTextSD>
              {noResultText}
            </FieldTypeAndSelectPopupOptionTextSD>
            {}
          </FieldTypeAndSelectPopupOptionSD>
        )}
      </FieldTypeAndSelectPopupSD>
    );
  };

  render() {
    const { label } = this.props;
    return (
      <FieldTypeAndSelectSD fullWidth={this.props.fullWidth}>
        {label && <LabelKitSD>{label} </LabelKitSD>}
        <FieldTypeAndSelectInputKitSD
          disabled={this.props.readOnly}
          ref={this.inputRef}
          onBlur={this.handleBlurSearchWord}
          onFocus={this.handleFocusSearchWord}
          onChange={this.handleChangeSearchWord}
          value={this.state.searchWord}
          isSelected={this.isValueSelected()}
          placeholder={this.props.placeholder}
          hasError={this.props.hasError}
          onKeyDown={this.handleOnKeyDown}
          fullWidth={this.props.fullWidth}
        />

        {this.renderOptions()}
      </FieldTypeAndSelectSD>
    );
  }
}

export default FieldTypeAndSelect;
