import React from "react";
import ButtonKit from "../../../../components/kit/Button/ButtonKit";
import DialogKit from "../../../../components/kit/Dialog/DialogKit";
import { Document, RadioButtonsGroup, WrapDocument } from "./styled";
import { InputKitSD } from "../../../../components/kit/Fields/styled";
import {
  Buttons,
  Container,
  Header,
  HeaderTitle,
  TextContent,
  WrapButton,
  WrapRadioGroup
} from "./styled";
import RadioButtonKit from "../../../../components/kit/Fields/RadioButton/RadioButtonKit";
import { api } from "../../../..";
import moment from "moment";

class IdentityConfirmDialog extends React.Component {
  constructor(props) {
    super();
    this.state = {
      gender: null,
      other: ""
    };
  }
  renderDialog = () => {
    const { onClose, user, document, getUser } = this.props;

    const { gender, other } = this.state;

    const genderOptions = [
      { value: "male", label: "Male" },
      { value: "female", label: "Female" },
      { value: "other", label: "Other" }
    ];

    const handleSubmit = () => {
      api.admin.users
        .confirmIdentity(user.id, {
          status: "approved",
          gender: gender !== "other" ? gender : other,
          document
        })
        .then(() => {
          getUser();
          onClose();
        });
    };

    const handleReject = () => {
      api.admin.users
        .upload(user.id, {
          status: "rejected",
          uploadId: document.id
        })
        .then(() => {
          getUser();
          onClose();
        });
    };

    const canSubmit =
      gender === "male" || gender === "female" || (gender === "other" && other);

    return (
      <Container>
        <Header>
          <HeaderTitle>Confirm Identity</HeaderTitle>
          {`Expires ${moment(document.expiresAt).fromNow()}`}
        </Header>
        <WrapDocument>
          <Document src={document.src} alt="document" />
        </WrapDocument>
        <WrapRadioGroup>
          <RadioButtonsGroup>
            {genderOptions.map(option => (
              <RadioButtonKit
                key={option.value}
                value={option.value}
                label={option.label}
                onChange={() => {
                  this.setState({ gender: option.value });
                }}
                checked={gender === option.value}
              />
            ))}
          </RadioButtonsGroup>
          <InputKitSD
            value={other}
            onChange={e => {
              this.setState({ other: e.target.value });
            }}
            // disabled={gender !== 'other'}
            placeholder="If other, enter the specified gender"
            onClick={() => {
              this.setState({ gender: "other" });
            }}
          />
        </WrapRadioGroup>

        <TextContent>
          Note: After submitting or rejecting this form, the identity document
          will be deleted permanently.
        </TextContent>
        <Buttons>
          <WrapButton>
            <ButtonKit small fullWidth appearance="secondary" onClick={onClose}>
              Cancel
            </ButtonKit>
          </WrapButton>
          <WrapButton>
            <ButtonKit small fullWidth color="red" onClick={handleReject}>
              Reject
            </ButtonKit>
          </WrapButton>
          <WrapButton>
            <ButtonKit
              small
              fullWidth
              color="green"
              onClick={handleSubmit}
              disabled={!canSubmit}
            >
              Submit
            </ButtonKit>
          </WrapButton>
        </Buttons>
      </Container>
    );
  };

  render() {
    const { isOpen, onClose, user, document, getUser } = this.props;
    return (
      <DialogKit
        isOpen={isOpen}
        onClose={onClose}
        renderCustom={this.renderDialog}
        displayButtonClose={false}
        user={user}
        image={document}
        getUser={getUser}
      />
    );
  }
}

export default IdentityConfirmDialog;
