import React from "react";
import { addUnit, safePropValueOr } from "../../../helpers/common";
import { FONT_SIZES } from "../CommunicationCreate/constants";
import {
  FormPanel,
  FormPanelFields,
  FormPanelItem,
  PopupWrapper
} from "../styled";
import {
  MenuItemKitSD,
  MenuItemLinkKitSD,
  MenuKitSD
} from "../../../components/kit/Menu/styled";
import ButtonKit from "../../../components/kit/Button/ButtonKit";
import FileKit from "../../../components/kit/Fields/File/FileKit";
import IconPaperClip from "../../../components/kit/Icon/IconPaperClip";
import ButtonIconKit from "../../../components/kit/ButtonIcon/ButtonIconKit";
import IconLetterA from "../../../components/kit/Icon/IconLetterA";
import PopupKit from "../../../components/kit/Popup/PopupKit";
import IconHyperLink from "../../../components/kit/Icon/IconHyperLink";
import FormFieldKit from "../../../components/kit/Fields/FormField/FormField";

const EmailFormPanel = props => {
  const {
    fontSizePopupIsOpen,
    linkFormPopupIsOpen,
    sendAt,
    readOnly,
    linkUrlValue,
    type,
    handleChangeFile,
    toggleFontSizePopup,
    closeFontSizePopup,
    currentFontSize,
    toggleFontSize,
    toggleLinkFromPopup,
    closeLinkFromPopup,
    onChangeDate,
    changeLinkUrlValue,
    confirmLink,
    inputLinkRef,
    errors
  } = props;

  return !readOnly ? (
    <FormPanel>
      <FormPanelItem>
        <FileKit
          onChange={e => handleChangeFile(e)}
          multiple
          accept={"image/*|audio/*|video/*|application/pdf"}
        >
          <IconPaperClip width={11} />
        </FileKit>
      </FormPanelItem>
      <FormPanelItem>
        <ButtonIconKit
          onMouseDown={e => e.preventDefault()}
          onClick={toggleFontSizePopup}
        >
          <IconLetterA width={15} />
        </ButtonIconKit>
        <PopupWrapper>
          <PopupKit
            disabledPortal
            isOpen={fontSizePopupIsOpen}
            onClose={closeFontSizePopup}
          >
            <MenuKitSD onClick={closeFontSizePopup}>
              {FONT_SIZES.map(size => (
                <MenuItemKitSD
                  key={size}
                  isActive={currentFontSize === addUnit(size)}
                >
                  <MenuItemLinkKitSD
                    onClick={() => toggleFontSize(size)}
                    onMouseDown={e => e.preventDefault()}
                  >
                    {addUnit(size)}
                  </MenuItemLinkKitSD>
                </MenuItemKitSD>
              ))}
            </MenuKitSD>
          </PopupKit>
        </PopupWrapper>
      </FormPanelItem>
      <FormPanelItem>
        <ButtonIconKit
          onMouseDown={e => e.preventDefault()}
          onClick={toggleLinkFromPopup}
        >
          <IconHyperLink width={16} />
        </ButtonIconKit>
        <PopupWrapper>
          <PopupKit
            disabledPortal
            isOpen={linkFormPopupIsOpen}
            onClose={closeLinkFromPopup}
          >
            <FormPanelFields>
              <FormFieldKit
                inputLinkRef={inputLinkRef}
                fullWidth
                label={"URL"}
                horizontalLabel
                horizontalLabelWidth={48}
                placeholder={"http://"}
                value={linkUrlValue}
                inputProps={{ maxLength: 200 }}
                inputFocus={true}
                onChange={changeLinkUrlValue}
                errorText={safePropValueOr(errors, "linkUrlValue", null)}
              />
              <ButtonKit
                fullWidth
                small
                color={"primary"}
                onClick={confirmLink}
              >
                Confirm
              </ButtonKit>
            </FormPanelFields>
          </PopupKit>
        </PopupWrapper>
      </FormPanelItem>
      {type !== "template" && (
        <FormPanelItem>
          <FormFieldKit
            dateTimePicker
            iconAlign={"left"}
            placeholder={"Not Set"}
            gutterBottom={0}
            onChange={onChangeDate}
            value={sendAt}
          />
        </FormPanelItem>
      )}
    </FormPanel>
  ) : null;
};

export default EmailFormPanel;
