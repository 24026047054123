import { WALLET_ACTIONS } from "./constants";
import { api } from "../../index";
import { propValueOr } from "../../helpers/common";

export const fetchWallet = id => dispatch => {
  dispatch({
    type: WALLET_ACTIONS.wallet.request
  });

  api.wallet
    .fetch(id)
    .then(resp => {
      dispatch({
        type: WALLET_ACTIONS.wallet.success,
        payload: propValueOr(resp, "data", {})
      });
    })
    .catch(err => {
      dispatch({ type: WALLET_ACTIONS.wallet.error });
      throw err;
    });
};

export const fetchStats = () => dispatch => {
  dispatch({
    type: WALLET_ACTIONS.stats.request
  });

  api.wallet
    .stats()
    .then(resp => {
      dispatch({
        type: WALLET_ACTIONS.stats.success,
        payload: propValueOr(resp, "data", [])
      });
    })
    .catch(err => {
      dispatch({ type: WALLET_ACTIONS.stats.error });
      throw err;
    });
};

export const fetchTransaction = query => dispatch => {
  dispatch({
    type: WALLET_ACTIONS.transaction.request
  });

  return api.wallet
    .transaction(query)
    .then(resp => {
      dispatch({
        type: WALLET_ACTIONS.transaction.success,
        payload: propValueOr(resp, "data", {})
      });
    })
    .catch(err => {
      dispatch({ type: WALLET_ACTIONS.transaction.error });
      throw err;
    });
};

export const fetchTransactions = query => dispatch => {
  dispatch({
    type: WALLET_ACTIONS.transactions.request
  });

  api.wallet
    .transactions(query)
    .then(resp => {
      dispatch({
        type: WALLET_ACTIONS.transactions.success,
        payload: propValueOr(resp, "data", {})
      });
    })
    .catch(err => {
      dispatch({ type: WALLET_ACTIONS.transactions.error });
      throw err;
    });
};

export const fetchWallets = query => dispatch => {
  dispatch({
    type: WALLET_ACTIONS.wallets.request
  });

  api.wallet
    .list(query)
    .then(resp => {
      dispatch({
        type: WALLET_ACTIONS.wallets.success,
        payload: propValueOr(resp, "data", {})
      });
    })
    .catch(err => {
      dispatch({ type: WALLET_ACTIONS.wallets.error });
      throw err;
    });
};

export const resetWallet = () => ({
  type: WALLET_ACTIONS.resetState
});
