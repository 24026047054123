import { propValueOr } from "../../helpers/common";
import moment from "moment";

export const getTournamentTeam = (tournament, slug) => {
  const teamUsers = propValueOr(tournament, "users", []).filter(user => {
    return propValueOr(user, "team.slug") === slug;
  });
  if (teamUsers.length > 0) {
    const teamObj = teamUsers[0].team;
    return {
      ...teamObj,
      users: teamUsers
    };
  }
};

export const getTournamentCalendarEvents = tournaments => {
  const events = tournaments.map(t => ({
    id: t?.id,
    token: t?.token,
    title: t?.name,
    start: new Date(t?.startDate),
    end: moment(t?.startDate).add(2, "hours")
  }));
  return events;
};
