import { cloneDeep, startCase } from "lodash";
import React, { Component } from "react";
import FormFieldKit from "../../../../components/kit/Fields/FormField/FormField";
import { propValueOr, safePropValueOr } from "../../../../helpers/common";
import { imageFields, adFields, pdfFields } from "./constants";
import { Container, Copied } from "./styled";
import Uploader from "./Uploader/Uploader";
import UploaderFile from "./Uploader/UploaderFile";
import downloadIcon from "../../../../static/icons/download-arrow.svg";
import ButtonKit from "../../../../components/kit/Button/ButtonKit";
class Images extends Component {
  render() {
    const {
      onChange,
      onChangeField,
      onChangeAd,
      onChangePDF,
      formState,
      onResetField,
      onResetAd
    } = this.props;

    return (
      <Container>
        {pdfFields.map(field => {
          const usingCopy =
            formState?.imageInfo?.[field.key] && !this.state?.[field.key];
          const pdfLinkStrArr = formState?.imageInfo?.[field.key]?.link?.split(
            "/"
          );
          return (
            <>
              {usingCopy && (
                <Copied>
                  <span style={{ textTransform: "capitalize" }}>
                    {field.key} - Copied from Other Tournament
                  </span>
                  <br />
                  <div style={{ marginBottom: "12px" }}>
                    <ButtonKit
                      small
                      color={"rival_red"}
                      shape={"rounded"}
                      style={{ marginRight: "4px" }}
                      onClick={() => {
                        if (
                          window.confirm(
                            "Are you sure you want to clear the copied PDF & Title? If you want these back you'll have to refresh the page, which will clear any other changes you've made on this copied tournament."
                          )
                        ) {
                          const imageInfo = cloneDeep(
                            formState.imageInfo || {}
                          );
                          delete imageInfo[field.key];
                          onChange("imageInfo", imageInfo);
                          this.setState({ [field.key]: true });
                        }
                      }}
                    >
                      Remove Copied PDF
                    </ButtonKit>
                    <ButtonKit
                      small
                      color={"rival_red"}
                      appearance={"secondary"}
                      shape={"rounded"}
                    >
                      <img src={downloadIcon} width={18} height={16} alt="" />
                      <a
                        style={{ color: "#fff", textDecoration: "none" }}
                        href={formState?.imageInfo?.[field.key]?.link}
                      >
                        {formState?.imageInfo?.[field.key]?.title?.slice(
                          0,
                          20
                        ) ||
                          pdfLinkStrArr?.[pdfLinkStrArr?.length - 1]?.slice(
                            0,
                            20
                          ) ||
                          "Download PDF"}
                      </a>
                    </ButtonKit>
                  </div>
                </Copied>
              )}
              {!usingCopy && (
                <UploaderFile
                  key={field.key}
                  label={startCase(field.key) + " (optional)"}
                  description={field.description}
                  onChange={file => onChangePDF(field.key, "link", file)}
                  onReset={() => onResetAd(field.key)}
                />
              )}
              {usingCopy ? (
                <FormFieldKit
                  label={`${startCase(field.key)} Title`}
                  name={field.key}
                  onChange={val => {
                    const imageInfo = cloneDeep(formState.imageInfo || {});
                    imageInfo[field.key].title = val;
                    onChange("imageInfo", imageInfo);
                  }}
                  placeholder={`Enter ${field.key} title...`}
                  errorText={
                    formState.clickedNext &&
                    safePropValueOr(
                      formState,
                      `errors.Images.${field.key}.title`
                    )
                  }
                  value={formState?.imageInfo?.[field.key]?.title}
                />
              ) : (
                <FormFieldKit
                  label={`${startCase(field.key)} Title`}
                  name={field.key}
                  onChange={val => onChangeAd(field.key, "title", val)}
                  placeholder={`Enter ${field.key} title...`}
                  errorText={
                    formState.clickedNext &&
                    safePropValueOr(
                      formState,
                      `errors.Images.${field.key}.title`
                    )
                  }
                  value={formState?.[field.key]?.title || ""}
                />
              )}
            </>
          );
        })}
        {imageFields.map(field => (
          <Uploader
            key={field.key}
            label={field.key + " (optional)"}
            description={field.description}
            onChange={file => onChangeField(field.key, file)}
            constraints={field.constraints}
            imgLabels={field.imgLabels}
            imageDimensions={field.imageDimensions}
            defaultImage={propValueOr(formState, `imageInfo.${field.key}`)}
            onReset={() => onResetField(field.key)}
          />
        ))}
        {adFields.map(field => {
          const usingCopy =
            formState?.imageInfo?.[field.key] && !this.state?.[field.key];
          return (
            <>
              {usingCopy && (
                <Copied>
                  <span style={{ textTransform: "capitalize" }}>
                    {field.key} - Copied from Other Tournament
                  </span>
                  <br />
                  <img
                    src={formState?.imageInfo?.[field.key]?.image}
                    width={207.6}
                    height={173}
                    alt=""
                  />
                  <div style={{ marginBottom: "12px" }}>
                    <ButtonKit
                      small
                      color={"rival_red"}
                      shape={"rounded"}
                      style={{ marginRight: "4px" }}
                      onClick={() => {
                        if (
                          window.confirm(
                            "Are you sure you want to clear the copied Ad Image & Link? If you want these back you'll have to refresh the page, which will clear any other changes you've made on this copied tournament."
                          )
                        ) {
                          const imageInfo = cloneDeep(
                            formState.imageInfo || {}
                          );
                          delete imageInfo[field.key];
                          onChange("imageInfo", imageInfo);
                          this.setState({ [field.key]: true });
                        }
                      }}
                    >
                      Remove Copied Ad
                    </ButtonKit>
                  </div>
                </Copied>
              )}
              {!usingCopy && (
                <Uploader
                  key={field.key}
                  label={startCase(field.key) + " (optional)"}
                  description={field.description}
                  onChange={file => onChangeAd(field.key, "image", file)}
                  constraints={field.constraints}
                  imgLabels={field.imgLabels}
                  imageDimensions={field.imageDimensions}
                  defaultImage={propValueOr(
                    formState,
                    `imageInfo.${field.key}.image`
                  )}
                  onReset={() => onResetAd(field.key)}
                />
              )}
              {usingCopy ? (
                <FormFieldKit
                  label={`${startCase(field.key)} Link`}
                  name={field.key}
                  onChange={val => {
                    const imageInfo = cloneDeep(formState.imageInfo || {});
                    imageInfo[field.key].link = val;
                    onChange("imageInfo", imageInfo);
                  }}
                  placeholder={`Enter ${field.key} link...`}
                  errorText={
                    formState.clickedNext &&
                    safePropValueOr(
                      formState,
                      `errors.Images.${field.key}.link`
                    )
                  }
                  value={formState?.imageInfo?.[field.key]?.link || ""}
                />
              ) : (
                <FormFieldKit
                  label={`${startCase(field.key)} Link`}
                  name={field.key}
                  onChange={val => onChangeAd(field.key, "link", val)}
                  placeholder={`Enter ${field.key} link...`}
                  errorText={
                    formState.clickedNext &&
                    safePropValueOr(
                      formState,
                      `errors.Images.${field.key}.link`
                    )
                  }
                  value={formState[field.key]?.link || ""}
                />
              )}
            </>
          );
        })}
      </Container>
    );
  }
}

export default Images;
