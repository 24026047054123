import React from "react";
import PropTypes from "prop-types";
import { SVGIconSD } from "./styled";

function IconClose(props) {
  return (
    <SVGIconSD width={props.width} viewBox="0 0 12 12">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-638.000000, -544.000000)">
          <g transform="translate(547.000000, 537.000000)">
            <g>
              <g>
                <g transform="translate(88.000000, 4.000000)">
                  <g>
                    <polygon
                      fill="currentColor"
                      fillRule="nonzero"
                      points="14.25 4.8075 13.1925 3.75 9 7.9425 4.8075
                     3.75 3.75 4.8075 7.9425 9 3.75 13.1925 4.8075 14.25 9 10.0575 13.1925 14.25
                      14.25 13.1925 10.0575 9"
                    />
                    <polygon points="0 0 18 0 18 18 0 18" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </SVGIconSD>
  );
}

IconClose.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default IconClose;
