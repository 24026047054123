import UsersCreate from "./UsersCreate/UsersCreate";
import User from "./User/User";

/*Note: for all routes property exact is true !!!*/
const routes = [
  {
    path: "/users/create",
    contentComponent: UsersCreate
  },
  {
    path: "/users/:id",
    contentComponent: User
  }
];

export default routes;
