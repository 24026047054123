import React, { Component } from "react";
import { connect } from "react-redux";
import { verifyPermission } from "../../helpers/common";
import { Container, Content } from "./styled";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import { routes } from "./routes";
class RouteReports extends Component {
  render() {
    return (
      <Container>
        <Content>
          <Switch>
            {routes.map(route => {
              const Component = route.component;
              return (
                <Route
                  exact={route.exact}
                  path={route.path}
                  render={() => {
                    return (
                      <Component
                        {...this.props}
                        user={this.props.user}
                        permission={verifyPermission(
                          route.path,
                          this.props.permissions
                        )}
                      />
                    );
                  }}
                />
              );
            })}
            <Route render={() => <Redirect to={"/reports/tournament"} />} />
          </Switch>
        </Content>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  permissions: state.permissionsState.permissions
});
const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RouteReports));
