import React, { Component } from "react";
import {
  WrapContainer,
  Container,
  Header,
  Card,
  CardTitle,
  TeamCard,
  Icon,
  TitleBox,
  HeaderTitle,
  TournamentTitle,
  UnregisterButton,
  TeamGame,
  ProfileList,
  ThumbnailImages,
  ImageThumbnail,
  Console,
  PlaceHolderCard
} from "./styled";
import PlayerItem from "../PlayerList/PlayerItem";
import ButtonKit from "../../../../../../components/kit/Button/ButtonKit";
import UnregisterDialog from "../Dialogs/UnregisterDialog";
import ReplaceDialog from "../Dialogs/ReplaceDialog";
import { propValueOr, isObjectEmpty } from "../../../../../../helpers/common";
import {
  fetchTeamAction,
  resetTeamAction
} from "../../../../../../store/team/actions";
import { getTournamentTeam } from "../../../../../../store/tournament/selectors";
import iconXbox from "../../../../../../static/icons/icon-xbox-green.svg";
import iconPS4 from "../../../../../../static/icons/icon-ps4.svg";
import backArrow from "../../../../../../static/icons/chevron-arrow.svg";
import Loader from "../../../../../../components/presentation/Loader/Loader";
import { connect } from "react-redux";
import { Link, Redirect, withRouter } from "react-router-dom";
import { api } from "../../../../../..";

class TeamRoster extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isUnregisterDialogOpen: false,
      isReplaceDialogOpen: false,
      loading: false
    };
  }

  componentDidMount() {
    const slug = this.props.match.params.slug;
    this.props.fetchTeamAction(slug);
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;

    if (prevProps.location !== location) {
      const slug = this.props.match.params.slug;
      this.props.fetchTeamAction(slug);
    }
  }

  componentWillUnmount() {
    this.props.resetTeamAction();
  }

  handleLeave = () => {
    this.setState({ isLoading: true });
    const {
      tournament: { token }
    } = this.props;
    this.props.leaveTournamentAction(token).then(() => {
      this.setState({
        isUnregisterDialogOpen: false
      });
    });
    return <Redirect to={`/tournaments/${token}`} />;
  };

  removeUsers() {
    const {
      roster,
      tournament: { id, token }
    } = this.props;
    const uid = (propValueOr(roster, "users", []).find(u => u.isPrimary) || {})
      .id;

    if (!id) {
      window.alert("No tournament id");
      return;
    }
    if (!uid) {
      window.alert("Invalid user id");
      return;
    }
    this.setState({ loading: true });
    api.admin.tournament.users
      .remove(id, [uid])
      .then(resp => {
        this.props.history.push(`/tournaments/${token}/players`);
      })
      .finally(() =>
        this.setState({ loading: false, isUnregisterDialogOpen: false })
      );
  }

  renderUnregisterDialog() {
    const { roster } = this.props;
    const { isUnregisterDialogOpen, loading } = this.state;

    return (
      <UnregisterDialog
        unregisteredUser={roster}
        isDialogOpen={isUnregisterDialogOpen}
        onUnregister={() => {
          this.removeUsers();
        }}
        onClose={() => this.setState({ isUnregisterDialogOpen: false })}
        loading={loading}
      />
    );
  }

  renderReplaceDialog() {
    const { team, roster } = this.props;
    const { isReplaceDialogOpen, replacePlayer } = this.state;
    const rosterIds = roster.users.map(user => user.id);
    const playerList = team.users.filter(user => !rosterIds.includes(user.id));

    return (
      <ReplaceDialog
        isOpen={isReplaceDialogOpen}
        replacePlayer={replacePlayer}
        players={playerList}
        onClose={() => this.setState({ isReplaceDialogOpen: false })}
      />
    );
  }

  renderContent() {
    const { tournament, roster, width } = this.props;
    const isMobileMode = width < 650;
    const users = propValueOr(roster, "users", []);
    const captain = users.find(user => user.isPrimary);
    const game = propValueOr(roster, "Game");
    const gameThumbnail = propValueOr(game, "imageInfo.icon");

    return (
      <WrapContainer>
        <Container>
          <Header>
            <Link to={`/tournaments/${tournament.token}/players`}>
              <Icon src={backArrow} />
            </Link>
            <TitleBox>
              <HeaderTitle>Tournament Team Status</HeaderTitle>
              <TournamentTitle>{tournament.name}</TournamentTitle>
            </TitleBox>
          </Header>
          <TeamCard>
            <TeamGame isMobile={isMobileMode}>
              <div>{roster.name}</div>
              <ThumbnailImages>
                <Console src={roster.ConsoleId === 1 ? iconXbox : iconPS4} />
                <ImageThumbnail
                  src={gameThumbnail}
                  onError={e => {
                    e.target = null;
                  }}
                />
              </ThumbnailImages>
            </TeamGame>
            <UnregisterButton>
              <ButtonKit
                onClick={() => {
                  this.setState({ isUnregisterDialogOpen: true });
                }}
                appearance={"secondary"}
                small
              >
                Remove Team
              </ButtonKit>
            </UnregisterButton>
          </TeamCard>
          <Card>
            <CardTitle>Team Captain</CardTitle>
            <ProfileList>
              <PlayerItem rowData={captain} />
            </ProfileList>
          </Card>
          <Card>
            <CardTitle>Team Roster</CardTitle>
            {users.length ? (
              <ProfileList>
                {users.map(user => {
                  return !user.isPrimary ? (
                    <PlayerItem rowData={user} key={user.id}>
                      <ButtonKit
                        onClick={() =>
                          this.setState({
                            isReplaceDialogOpen: true,
                            replacePlayer: user
                          })
                        }
                        appearance={"secondary"}
                        small
                      >
                        Replace
                      </ButtonKit>
                    </PlayerItem>
                  ) : null;
                })}
              </ProfileList>
            ) : (
              <PlaceHolderCard>No Players on this team</PlaceHolderCard>
            )}
          </Card>
          {this.renderUnregisterDialog()}
          {this.renderReplaceDialog()}
        </Container>
      </WrapContainer>
    );
  }

  render() {
    const { tournament, team, roster } = this.props;

    if (!roster) {
      return <Redirect to={`/tournaments/${tournament.token}/players`} />;
    }

    return isObjectEmpty(tournament) || isObjectEmpty(team) ? (
      <Loader isBlock />
    ) : (
      this.renderContent()
    );
  }
}

const mapStateProps = (state, props) => ({
  user: state.user,
  team: state.teamState.team,
  roster: getTournamentTeam(
    state.tournamentState.tournament,
    props.match.params.slug
  ),
  tournament: state.tournamentState.tournament,
  isFetching: state.tournamentState.isFetching
});

const mapDispatchToProps = {
  fetchTeamAction,
  resetTeamAction
};

export default connect(
  mapStateProps,
  mapDispatchToProps
)(withRouter(TeamRoster));
