import React, { Component } from "react";
import { connect } from "react-redux";
import {
  WrapPage,
  Content,
  Header,
  HeaderRow,
  Title,
  WrapRequirements,
  WrapInfoIcon,
  SubText,
  SectionHeader,
  SectionTitle,
  SectionSubtitle,
  WrapAsset,
  ImageList,
  ScrollContainer,
  SocialMediaContainer,
  SocialMediaColumn,
  GameContainer,
  List,
  ListContainer,
  WrapGameCard,
  GameCard,
  GameHover,
  AddGameSection,
  WrapAddGame,
  AddGameText,
  AddGameIcon
} from "./styled";
import { api } from "../..";
import { ImageTitle, ConstraintList, ConstraintItem } from "./Asset/styled";
import InfoDialog from "./InfoDialog/InfoDialog";
import AddGameDialog from "./AddGameDialog/AddGameDialog";
import RemoveGameDialog from "./RemoveGameDialog/RemoveGameDialog";
import { withRouter } from "react-router-dom";
import Asset from "./Asset/Asset";
import AssetPanels from "./AssetPanels/AssetPanels";
import AssetScroll from "./AssetScroll/AssetScroll";
import PermissionDenied from "../denied/PermissionDenied";
import { COLOR } from "../../constants/theme";
import { propValueOr } from "../../helpers/common";
import { fetchOrganizationsAction } from "../../store/organizations/actions";
import { fetchOrganizationGames } from "../../store/orgGames/actions";

// Images & Icons
import InfoIcon from "../../static/icons/info-icon.svg";
import DashboardPDF1 from "../../static/images/infoPDFs/dashboard-info.pdf";
import Uploader from "./Uploader/Uploader";
import imageCompression from "browser-image-compression";
import PlusIcon from "../../static/icons/minus-icon.svg";

class AssetsDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isInfoDialogOpen: false,
      isAddGameDialogOpen: false,
      isRemoveGameDialogOpen: false,
      removeGame: null,
      icon: null,
      defaultImage: props?.organization?.imageInfo?.icon
    };
  }

  componentDidUpdate(prevProps) {
    const icon = this.props.organization?.imageInfo?.icon;
    if (prevProps.organization?.imageInfo?.icon !== icon && !!icon) {
      this.setState({
        defaultImage: icon
      });
    }
  }

  renderInfoDialog() {
    return (
      <InfoDialog
        isOpen={this.state.isInfoDialogOpen}
        onClose={() => this.setState({ isInfoDialogOpen: false })}
        title={"Home Image Info"}
        images={[DashboardPDF1]}
      />
    );
  }

  handleAddGame(gameId) {
    const { organization } = this.props;
    api.organization.games.add(organization?.id, gameId).then(res => {
      if (res?.status === 200) {
        this.props.fetchOrganizationGames(organization?.id);
      }
      this.setState({ isAddGameDialogOpen: false });
    });
  }

  handleOnClickRemove(game) {
    this.setState({
      isRemoveGameDialogOpen: true,
      removeGame: game
    });
  }

  handleRemoveGame(orgGameId) {
    const { organization } = this.props;
    api.organization.games.remove(organization?.id, orgGameId).then(res => {
      if (res?.status === 200) {
        this.props.fetchOrganizationGames(organization?.id);
        this.setState({ isRemoveGameDialogOpen: false });
      }
    });
  }

  renderAddGameDialog() {
    const { games, orgGames } = this.props;
    return (
      <AddGameDialog
        isOpen={this.state.isAddGameDialogOpen}
        onClose={() => this.setState({ isAddGameDialogOpen: false })}
        games={games}
        orgGames={orgGames}
        addGame={gameId => this.handleAddGame(gameId)}
      />
    );
  }

  renderRemoveGameDialog() {
    const { removeGame, isRemoveGameDialogOpen } = this.state;
    return (
      <RemoveGameDialog
        isOpen={isRemoveGameDialogOpen}
        onClose={() => this.setState({ isRemoveGameDialogOpen: false })}
        game={removeGame}
        onRemove={orgGameId => this.handleRemoveGame(orgGameId)}
      />
    );
  }

  handleUpdateIcon = () => {
    const { organization } = this.props;
    const { icon } = this.state;

    if (!(icon instanceof File)) return;

    let data = new FormData();
    data.append("icon", icon);

    this.setState({ submitting: true });
    api.admin.organizations
      .update(organization?.id, data)
      .then(resp => {
        this.setState({
          confirm: false,
          success: true,
          icon: null,
          defaultImage: this.state.defaultImage + `?${Date.now()}`
        });
        // ^^^ this is a hack to force the image to update even though its at the same url
        this.props.fetchOrganizationsAction();
      })
      .catch(err => {
        this.setState({
          error:
            err?.response?.data?.errors?.[0]?.message ||
            err?.response?.data?.message ||
            typeof err === "string"
              ? err
              : JSON.stringify(err)
        });
      })
      .finally(() => this.setState({ submitting: false }));
  };

  render() {
    const { orgGames } = this.props;
    if (!this.props.permission) {
      return <PermissionDenied />;
    }

    return (
      <WrapPage>
        <Content>
          <Header>
            <HeaderRow>
              <Title>Home</Title>
              <WrapInfoIcon
                src={InfoIcon}
                onClick={() => this.setState({ isInfoDialogOpen: true })}
              />
            </HeaderRow>
            <SubText>All images will be compressed down to 400KB</SubText>
          </Header>
        </Content>
        <Content>
          <WrapAsset>
            <Uploader
              label="Community Logo"
              description={
                "This will show in the community selector or under the 'Find A Community' list on the landing page. **Note: looks best with an aspect ratio of 1:1"
              }
              onChange={async file => {
                let compressedImage = await imageCompression(file, {
                  maxSizeMB: 0.2,
                  useWebWorker: true
                });
                this.setState({
                  icon: new File([compressedImage], "icon", {
                    type: file.type?.includes["image"]
                      ? file.type.replace("image/", ".")
                      : ".png"
                  })
                });
              }}
              constraints={{ width: [25, 220], height: [25, 220] }}
              imgLabels={[]}
              imageDimensions={[100, 100]}
              defaultImage={this.state.defaultImage}
              onReset={() => this.setState({ icon: null })}
              name={"icon"}
              onSubmit={this.handleUpdateIcon}
              submitting={this.state.submitting}
              disableSubmit={!this.state.icon}
            />
          </WrapAsset>
          <WrapAsset>
            <Asset
              label={"Home / Tournaments / Leaderboard Banner"}
              name="dashboard-banner"
              image
              imageDimensions={[720, 110]}
              imgLabels={["Recommended minimum height of 220px"]}
              aspectRatioLimits={[{ value: 5.75 }, { value: 7.05 }]}
            />
          </WrapAsset>
        </Content>
        <br />
        <>
          <AssetPanels
            title={"Home Slides"}
            seriesNum={4}
            asset={{
              name: "dashboard-slider",
              panelTitle: "Slide",
              label: "Home Slider",
              title: true,
              description: true,
              link: { notRequired: true },
              image: true,
              video: { notRequired: true },
              imageDimensions: [512, 288],
              imgLabels: [
                "Recommended minimum width of 1024px",
                "Recommended minimum height of 576px"
              ],
              aspectRatioLimits: [{ value: 1.7 }, { value: 1.85 }],
              titleLimit: 48,
              descriptionLimit: 140
            }}
          />
          <br />
          <br />
          <Content>
            <AssetScroll
              title={"Big Partner Logos"}
              seriesNum={4}
              asset={{
                label: "LOGO",
                name: "landingpage-big-presented",
                centerItems: true,
                link: { notRequired: true },
                image: true,
                heightLimits: [null, 260],
                displayConstraints: false
              }}
              imageSpecs={["Height must be less than 260px"]}
            />
            <AssetScroll
              title={"Partner Logos"}
              seriesNum={16}
              asset={{
                label: "LOGO",
                name: "landingpage-presented",
                centerItems: true,
                link: { notRequired: true },
                image: true,
                heightLimits: [null, 150],
                displayConstraints: false
              }}
              imageSpecs={["Height must be less than 150px"]}
            />
            <br />
            <br />
            <SectionHeader>
              <SectionTitle>Home Ads</SectionTitle>
              <WrapRequirements>
                <ImageTitle>Image Requirements:</ImageTitle>
                <ConstraintList>
                  <ConstraintItem isValid={null}>
                    Recommended minimum width of 672px
                  </ConstraintItem>
                  <ConstraintItem isValid={null}>
                    Recommended minimum height of 560px
                  </ConstraintItem>
                  <ConstraintItem isValid={null}>
                    Required width : height ratio of 1.2
                  </ConstraintItem>
                </ConstraintList>
              </WrapRequirements>
            </SectionHeader>
            <ImageList>
              <ScrollContainer style={{ width: "100%" }}>
                <Asset
                  name="dashboard-ad-1"
                  label={"Ad 1"}
                  link={{ notRequired: true }}
                  image
                  centerItems
                  imageDimensions={[336, 280]}
                  aspectRatioLimits={[{ value: 1.15 }, { value: 1.25 }]}
                  inputStyles={{ backgroundColor: `${COLOR.BG_SECONDARY}` }}
                  displayConstraints={false}
                />
                <Asset
                  name="dashboard-ad-2"
                  label={"Ad 2"}
                  link={{ notRequired: true }}
                  image
                  centerItems
                  imageDimensions={[336, 280]}
                  aspectRatioLimits={[{ value: 1.15 }, { value: 1.25 }]}
                  inputStyles={{ backgroundColor: `${COLOR.BG_SECONDARY}` }}
                  displayConstraints={false}
                />
                <Asset
                  name="dashboard-asideAd-1"
                  label={"Ad 3"}
                  link={{ notRequired: true }}
                  image
                  centerItems
                  imageDimensions={[336, 280]}
                  aspectRatioLimits={[{ value: 1.15 }, { value: 1.25 }]}
                  inputStyles={{ backgroundColor: `${COLOR.BG_SECONDARY}` }}
                  displayConstraints={false}
                />
              </ScrollContainer>
            </ImageList>
            <WrapAsset>
              <Asset
                label={"Home Header Image"}
                name="landingpage-banner"
                title
                description
                image
                imageDimensions={[960, 400]}
                imgLabels={[
                  "Recommended minimum width of 1440px",
                  "Recommended minimum height of 600px"
                ]}
                aspectRatioLimits={[{ value: 2.2 }, { value: 2.6 }]}
                inputStyles={{ backgroundColor: `${COLOR.BG_SECONDARY}` }}
              />
            </WrapAsset>
            <WrapAsset>
              <Asset
                label={"Home Header Image (Mobile)"}
                name="landingpage-banner-mobile"
                image
                imageDimensions={[330, 400]}
                imgLabels={[
                  "Recommended minimum width of 760px",
                  "Recommended minimum height of 920px"
                ]}
                aspectRatioLimits={[{ value: 0.75 }, { value: 0.9 }]}
                inputStyles={{ backgroundColor: `${COLOR.BG_SECONDARY}` }}
              />
            </WrapAsset>
            <WrapAsset>
              <Asset name="store" link />
            </WrapAsset>
            <WrapAsset>
              <Asset
                name="store"
                image
                imageDimensions={[640, 360]}
                imgLabels={[
                  "Recommended minimum width of 640px",
                  "Recommended minimum height of 360px"
                ]}
                aspectRatioLimits={[{ value: 1.7 }, { value: 1.85 }]}
                inputStyles={{ backgroundColor: `${COLOR.BG_SECONDARY}` }}
              />
            </WrapAsset>
            <SectionHeader>
              <SectionTitle>Organization Games</SectionTitle>
              <SectionSubtitle>
                Add game to your organization dashboard or remove them by
                clicking on them
              </SectionSubtitle>
            </SectionHeader>
            <GameContainer>
              <AddGameSection>
                <WrapAddGame
                  onClick={() => {
                    this.setState({ isAddGameDialogOpen: true });
                  }}
                  addGame
                >
                  <AddGameIcon src={PlusIcon} />
                  <AddGameText>Add Game</AddGameText>
                </WrapAddGame>
              </AddGameSection>
              <List>
                <ListContainer>
                  {(orgGames || []).map(game => {
                    return (
                      <WrapGameCard
                        key={game.id}
                        onClick={() => {
                          this.handleOnClickRemove(game);
                        }}
                      >
                        <GameHover />
                        <GameCard
                          src={propValueOr(
                            game,
                            "game.imageInfo.original",
                            propValueOr(game, "imageInfo.original")
                          )}
                          onError={e => (e.target.src = "")}
                          alt=""
                        />
                      </WrapGameCard>
                    );
                  })}
                </ListContainer>
              </List>
            </GameContainer>
            <SectionHeader>
              <SectionTitle>Social Media</SectionTitle>
              <SectionSubtitle>
                Enter links to your social media
              </SectionSubtitle>
            </SectionHeader>
            <SocialMediaContainer>
              <SocialMediaColumn>
                <Asset
                  label={"Twitter"}
                  name="social-twitter"
                  link
                  inputStyles={{ backgroundColor: `${COLOR.BG_SECONDARY}` }}
                />
                <Asset
                  label={"Youtube"}
                  name="social-youtube"
                  link
                  inputStyles={{ backgroundColor: `${COLOR.BG_SECONDARY}` }}
                />
              </SocialMediaColumn>
              <SocialMediaColumn>
                <Asset
                  label={"Facebook"}
                  name="social-facebook"
                  link
                  inputStyles={{ backgroundColor: `${COLOR.BG_SECONDARY}` }}
                />
                <Asset
                  label={"Twitch"}
                  name="social-twitch"
                  link
                  inputStyles={{ backgroundColor: `${COLOR.BG_SECONDARY}` }}
                />
              </SocialMediaColumn>
              <SocialMediaColumn>
                <Asset
                  label={"Instagram"}
                  name="social-instagram"
                  link
                  inputStyles={{ backgroundColor: `${COLOR.BG_SECONDARY}` }}
                />
                <Asset
                  label={"TikTok"}
                  name="social-tiktok"
                  link
                  inputStyles={{ backgroundColor: `${COLOR.BG_SECONDARY}` }}
                />
              </SocialMediaColumn>
            </SocialMediaContainer>
            {/* {this.renderInfoDialog()} */}
            {this.renderAddGameDialog()}
            {this.renderRemoveGameDialog()}
          </Content>
        </>
      </WrapPage>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  organization: (state.organizationsState?.organizations || []).find(
    o => o.id === state.user?.info?.organizationId
  ),
  games: state.gamesState.games,
  orgGames: state.orgGamesState.games,
  consoles: state.consoles.list,
  isFetching: state.gamesState.isFetching
});

const mapDispatchToProps = {
  fetchOrganizationsAction,
  fetchOrganizationGames
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AssetsDashboard));
