import { createGlobalStyle } from "styled-components";
import styledNormalize from "styled-normalize";
import { COLOR, FONT_FAMILY, FONT_WEIGHT } from "../constants/theme";

export const GlobalStyleSD = createGlobalStyle`
    ${styledNormalize}
   
    * {        
        &,
        &:before,
        &:after {
            box-sizing: border-box;
        }
        
        &:focus {
          outline: none;
        }
    }

    body {
        font: ${FONT_WEIGHT.NORMAL} 16px / 1.2 ${FONT_FAMILY.THIN};
        overflow-y: auto;
        background-color: ${COLOR.BG_PRIMARY};
          /* width */
        ::-webkit-scrollbar {
          width: 10px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
          background: ${COLOR.BG_PRIMARY};
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
          background: ${COLOR.BG_SECONDARY};
          border-radius: 10px;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
          background: ${COLOR.RIVAL_RED};
        }
    }
    
    html, body {
        &.global-modal-is-open {
        	overflow: hidden;
          position: relative;
          width: 100%;
          height: 100%;
        }
    }

		#root-modal {
			position:relative;
		}
    
    .global-modal-container {
    	
    }
`;
