const CHALLENGES_ACTIONS = {
  list: {
    request: "CHALLENGES/LIST_REQUEST",
    success: "CHALLENGES/LIST_SUCCESS",
    error: "CHALLENGES/LIST_ERROR"
  },
  reset: "CHALLENGES/RESET_STATE"
};

export default CHALLENGES_ACTIONS;
