import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Container, Left, Right, Avatar, Score, Outcome } from "./styled";
import { safePropValueOr } from "../../../../helpers/common";
import placeholderUserImg from "../../../../static/images/default-user.jpg";
import { getPlayers } from "../../../../store/game/selectors";

function Scores({ players }) {
  if (!players || players.length !== 2) {
    return null;
  }

  return (
    <Container>
      <Left>
        <Outcome outcome={safePropValueOr(players[0], "outcome", "")}>
          {safePropValueOr(players[0], "outcome", "")}
        </Outcome>
        <Avatar
          src={safePropValueOr(
            players[0],
            "team.imageInfo.thumbnail",
            safePropValueOr(
              players[0],
              "imageInfo.thumbnail",
              placeholderUserImg
            )
          )}
          onError={e => (e.target.src = placeholderUserImg)}
        />
      </Left>
      <Score>
        {safePropValueOr(players[0], "score", "")} -{" "}
        {safePropValueOr(players[1], "score", "")}
      </Score>
      <Right>
        <Avatar
          src={safePropValueOr(
            players[1],
            "team.imageInfo.thumbnail",
            safePropValueOr(
              players[1],
              "imageInfo.thumbnail",
              placeholderUserImg
            )
          )}
          onError={e => (e.target.src = placeholderUserImg)}
        />
        <Outcome outcome={safePropValueOr(players[1], "outcome", "")}>
          {safePropValueOr(players[1], "outcome", "")}
        </Outcome>
      </Right>
    </Container>
  );
}

const mapStateProps = state => ({
  players: getPlayers(state)
});

export default connect(mapStateProps)(withRouter(Scores));
