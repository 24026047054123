import moment from "moment";
import React, { Component } from "react";
import ButtonKit from "../../../../../components/kit/Button/ButtonKit";
import { Container, Label, Description, Input, WrapButtons } from "./styled";

class UploaderFile extends Component {
  constructor(props) {
    super();

    this.inputRef = React.createRef();

    this.state = this.initialState(props);
  }

  initialState = (props = undefined) => ({
    file: null,
    preview: null,
    processing: false,
    error: false,
    submitting: false,
    deleting: false,
    updated: false,
    uploaded: false,
    reset: false
  });

  handlePDF = async event => {
    if (event.target.files && event.target.files[0]) {
      this.setState({ processing: true, file: null });
      const originalFile = event.target.files[0];
      this.setState({ file: originalFile });
      this.props.onChange(originalFile);
    }
  };

  reset = () => {
    this.setState(this.initialState());
    this.props.onReset();
  };

  render() {
    const { label, description, disabled, defaultFile } = this.props;
    const { file } = this.state;

    // Check if safari to fix some safari versions whitescreen...
    // https://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browser
    const isSafari =
      /constructor/i.test(window.HTMLElement) ||
      (function(p) {
        return p.toString() === "[object SafariRemoteNotification]";
      })(
        !window["safari"] ||
          (typeof safari !== "undefined" && window["safari"].pushNotification)
      );

    const fileName =
      file?.name ||
      (defaultFile &&
        defaultFile
          ?.split("/")
          ?.[defaultFile.split("/").length - 1]?.split("-")?.[0] + ".pdf") ||
      null;
    const fileModified =
      defaultFile
        ?.split("/")
        ?.[defaultFile?.split("/")?.length - 1]?.split("-")?.[1]
        ?.split(".")?.[0] || null;

    return (
      <Container disabled={disabled}>
        <Label>{label}</Label>
        <Description>{description}</Description>
        <Input
          ref={this.inputRef}
          type="file"
          name={label}
          onChange={this.handlePDF}
          accept="application/pdf"
        />
        <WrapButtons>
          <ButtonKit
            color={"rival_red"}
            shape={"rounded"}
            small
            onClick={() => this.inputRef.current.click()}
          >
            {fileName || "Select PDF"}
          </ButtonKit>
          {!isSafari && !file && fileModified && (
            <Label>
              Last Modified:{" "}
              {moment(parseInt(fileModified)).format("M/D/YY H:mm:ss")}
            </Label>
          )}
          <ButtonKit
            appearance={"secondary"}
            shape={"rounded"}
            width={100}
            small
            onClick={this.reset}
          >
            Reset
          </ButtonKit>
        </WrapButtons>
      </Container>
    );
  }
}

export default UploaderFile;
