import styled from "styled-components";
import { COLOR, FONT_WEIGHT, FONT_FAMILY } from "../../../constants/theme";

export const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  max-width: 370px;
  padding: 15px 20px;

  background-color: ${COLOR.BG_SECONDARY};
  color: ${COLOR.TEXT};
  width: 370px;
`;

export const DialogHeader = styled.div`
  display: flex;
  justify-content: space-between;

  width: 100%;
  padding-bottom: 12px;
  border-bottom: 1px solid ${COLOR.BORDER};
`;

export const DialogTitle = styled.div`
  font: ${FONT_WEIGHT.NORMAL} 28px ${FONT_FAMILY.THICK};
  text-transform: uppercase;
`;

export const PlayerSelection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const SelectionText = styled.div`
  display: flex;
  width: 100%;
  padding: 15px 0px;

  font: ${FONT_WEIGHT.NORMAL} 14px ${FONT_FAMILY.THIN};
`;

export const PlayerRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  margin-top: 15px;
  font: ${FONT_WEIGHT.NORMAL} 14px ${FONT_FAMILY.THIN};
  ${({ away }) =>
    !!away &&
    `justify-content: flex-end;
  flex-direction: row-reverse;`}
`;

export const PlayerProfile = styled.img`
  height: 32px;
  width: 32px;
`;

export const ForfeitWarning = styled.p`
  display: flex;
  width: 100%;

  text-align: center;
  font: ${FONT_WEIGHT.NORMAL} 12px ${FONT_FAMILY.THIN};
  .warning {
    font: ${FONT_WEIGHT.MEDIUM} 12px ${FONT_FAMILY.THIN};
    color: ${COLOR.CHERRY_RED};
    text-transform: uppercase;
  }
`;

export const Buttons = styled.div`
  flex: display;
  flex-direction: column;
  width: 100%;

  > * {
    margin-top: 15px;
  }
`;

export const WrapInputs = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  > :not(:last-child) {
    margin-right: 10px;
  }
`;

export const Success = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 25px;
  > :not(:last-child) {
    margin-bottom: 15px;
  }
`;

export const Text = styled.div`
  width: 100%;
  text-align: center;
  font: ${FONT_WEIGHT.NORMAL} 15px ${FONT_FAMILY.THIN};
`;

export const EntryOptions = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  > :not(:last-child) {
    margin-bottom: 15px;
  }
`;

export const VerificationEntry = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  background-color: ${COLOR.BG_PRIMARY};
`;

export const Image = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font: ${FONT_WEIGHT.LIGHT} 18px ${FONT_FAMILY.GOTHIC};
  color: ${COLOR.TEXT};
  min-height: 200px;
  margin-bottom: 15px;
`;
export const WrapPhoto = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
export const Photo = styled.img`
  max-height: 300px;
  max-width: 100%;
  cursor: zoom-in;
`;

export const Message = styled.div`
  margin: 15px 0;
  border-bottom: 1px solid ${COLOR.BORDER};
  border-top: 1px solid ${COLOR.BORDER};
  padding: 23px 0 23px 0;
`;

export const WrapImageList = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  overflow: scroll;
  margin-top: 10px;
  padding: 15px 0px;
  border-top: 2px solid ${COLOR.BORDER};
  border-bottom: 2px solid ${COLOR.BORDER};
  margin-bottom: 25px;
`;

export const ImageList = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;

  > :not(:last-child) {
    margin-right: 15px;
  }
`;

export const WrapImageItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 52px;
  overflow: hidden;
  ${({ active }) =>
    active
      ? `border: 2px solid ${COLOR.RIVAL_RED};`
      : `border: 1px solid ${COLOR.WHITE};
  cursor: pointer;
  opacity: 0.4;
  &:hover {
    opacity: 1;
  }`}
`;

export const ImageItem = styled.img`
  max-height: 100%;
  max-width: 100%;
  display: inline-block;
`;

export const VsFields = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 370px;
  > :not(:last-child) {
    margin-right: 5px;
  }
`;

export const PlayerScore = styled.div`
  display: flex;
  flex-direction: column;
`;

export const WrapVsField = styled.div`
  display: flex;
  width: 100%;
  max-width: 150px;
  margin-top: 5px;
`;

export const Fields = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  > :not(:first-child) {
    margin-top: 10px;
  }
`;

export const WrapField = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 15px;
`;
