import { propValueOr } from "../../helpers/common";
import { api } from "../../index";
import GAMESERIES_ACTIONS from "./constants";

export const fetchAllGameSeries = () => dispatch => {
  dispatch({
    type: GAMESERIES_ACTIONS.list.request
  });
  console.log("fetching all game series");
  api.series
    .list()
    .then(resp => {
      dispatch({
        type: GAMESERIES_ACTIONS.list.success,
        payload: propValueOr(resp, "data", [])
      });
    })
    .catch(err => {
      dispatch({
        type: GAMESERIES_ACTIONS.list.error,
        payload: err
      });
    });
};

export const resetGameSeries = () => dispatch => {
  dispatch({
    type: GAMESERIES_ACTIONS.reset
  });
};
