import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Transactions from "./Transactions";
import { fetchTransactions, resetWallet } from "../../../store/wallet/actions";
import {
  Container,
  Content,
  Header,
  NavBar,
  NavItem,
  ArrowIcon,
  WrapArrow
} from "../styled";
import ArrowBack from "../../../static/icons/chevron-arrow.svg";
class TransactionsContainer extends Component {
  render() {
    const { history } = this.props;
    return (
      <Container>
        <Content>
          <Header>
            <NavBar>
              <WrapArrow onClick={() => history.goBack()}>
                <ArrowIcon src={ArrowBack} />
              </WrapArrow>
              <NavItem>{`Game Id#: ${this.props.match.params.token}`}</NavItem>
            </NavBar>
          </Header>
          <Transactions {...this.props} />
        </Content>
      </Container>
    );
  }
}

const mapStateProps = state => ({
  user: state.user.info,
  transactions: state.walletState.transactions,
  total: state.walletState.total,
  game: state.gameState.game
});

const mapDispatchToProps = {
  fetchTransactions,
  resetWallet
};

export default connect(
  mapStateProps,
  mapDispatchToProps
)(withRouter(TransactionsContainer));
