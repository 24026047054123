import React, { Component } from "react";
import {
  Container,
  Content,
  Title,
  Field,
  Label,
  Wrapper,
  Balance,
  Error,
  Icon,
  WrapIcon
} from "./styled";
import ButtonKit from "../../../components/kit/Button/ButtonKit";
import InputChips from "./ChipInput/InputChips";
import FieldTypeAndSelect from "./FieldTypeAndSelect/FieldTypeAndSelect";
import FormField from "../../../components/kit/Fields/FormField/FormField";
import IconSearch from "../../../components/kit/Icon/IconSearch";
import { api } from "../../..";
import { propValueOr } from "../../../helpers/common";
import { fetchWallet } from "../../../store/wallet/actions";
import { connect } from "react-redux";
import greenCheck from "../../../static/icons/circle-check-green.svg";

class Transfer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transferring: false,
      success: false,
      senderValue: "",
      recipientValue: "",
      sender: {
        label: propValueOr(props, "wallet.organization.name")
          ? `Organization: ${propValueOr(
              props,
              "wallet.organization.name",
              ""
            )}`
          : "master",
        value: propValueOr(props, "wallet.id"),
        ...props.wallet
      },
      recipient: null,
      amount: "",
      code: "",
      error: ""
    };
  }

  handleTransfer = () => {
    const { sender, recipient, amount, code } = this.state;
    this.setState({ transferring: true });
    api.wallet
      .transfer({
        senderId: sender.id,
        recipientId: recipient.id,
        amount: amount,
        code: code
      })
      .then(() => {
        this.setState({
          transferring: false,
          sender: this.props.wallet,
          recipient: null,
          amount: "",
          code: "",
          senderValue: "",
          recipientValue: "",
          error: "",
          success: true
        });
        this.props.fetchWallet();
        setTimeout(() => {
          this.setState({ success: false });
        }, 3000);
      })
      .catch(e => {
        this.setState({
          error: propValueOr(e, "response.data.error", "Error"),
          transferring: false
        });
      });
  };

  handleSearch = searchWord => {
    return api.wallet
      .search({
        q: searchWord,
        limit: 10,
        page: 1
      })
      .then(resp => [
        ...resp.data.masterWallet.map(w => ({
          label: `${propValueOr(w, "walletType", "")}`,
          value: w.id,
          ...w
        })),
        ...resp.data.userWallets.map(w => ({
          label: `User ${propValueOr(w, "user.id", "")}: ${propValueOr(
            w,
            "user.username",
            ""
          )}`,
          value: w.id,
          ...w
        })),
        ...resp.data.organizationWallets.map(w => ({
          label: `Organization ${propValueOr(
            w,
            "organization.id",
            ""
          )}: ${propValueOr(w, "organization.name", "")}`,
          value: w.id,
          ...w
        })),
        ...resp.data.tournamentWallets.map(w => ({
          label: `Tournament ${propValueOr(
            w,
            "tournament.id",
            ""
          )}: ${propValueOr(w, "tournament.name", "")}`,
          value: w.id,
          ...w
        })),
        ...resp.data.matchWallets.map(w => ({
          label: `Match ${propValueOr(w, "match.id", "")}: ${propValueOr(
            w,
            "match.token",
            ""
          )}`,
          value: w.id,
          ...w
        }))
      ]);
  };

  addRecipient = wallet => {
    const { sender } = this.state;
    if (!!!wallet || wallet.id === propValueOr(sender, "id", null)) {
      return;
    }
    this.setState({ recipient: wallet });
  };

  removeRecipient = () => {
    this.setState({ recipient: null });
  };

  addSender = wallet => {
    const { recipient } = this.state;
    if (!!!wallet || wallet.id === propValueOr(recipient, "id", null)) {
      return;
    }
    this.setState({ sender: wallet });
  };

  removeSender = () => {
    this.setState({ sender: null });
  };

  validateFields = () => {
    const { transferring, sender, recipient, amount } = this.state;
    if (!transferring && sender && recipient && amount) {
      return true;
    }
    return false;
  };

  renderTransfer = () => {
    const {
      sender,
      recipient,
      senderValue,
      recipientValue,
      amount,
      code,
      error
    } = this.state;

    return (
      <Container>
        <Content>
          <Title>How much would you like to transfer?</Title>
          <Field>
            <Label>To</Label>
            <Wrapper>
              {!recipient ? (
                <FormField
                  iconAlign={"left"}
                  fullWidth
                  icon={<IconSearch />}
                  value={recipientValue}
                  placeholder={
                    "Search for user, organization, and tournament wallets..."
                  }
                  gutterBottom={false}
                >
                  <FieldTypeAndSelect
                    placeholder={
                      "Search for user, organization, and tournament wallets..."
                    }
                    onChange={w => this.addRecipient(w)}
                    onSearch={this.handleSearch}
                  />
                </FormField>
              ) : (
                <InputChips
                  value={recipient}
                  onChange={this.removeRecipient}
                  readOnly={true}
                />
              )}
            </Wrapper>
            <Balance>{propValueOr(recipient, "total", "")}</Balance>
          </Field>
          <Field>
            <Label>From</Label>
            <Wrapper>
              {!sender ? (
                <FormField
                  iconAlign={"left"}
                  fullWidth
                  icon={<IconSearch />}
                  value={senderValue}
                  placeholder={
                    "Search for user, organization, and tournament wallets..."
                  }
                  gutterBottom={false}
                >
                  <FieldTypeAndSelect
                    placeholder={
                      "Search for user, organization, and tournament wallets..."
                    }
                    onChange={w => this.addSender(w)}
                    onSearch={this.handleSearch}
                  />
                </FormField>
              ) : (
                <InputChips
                  value={sender}
                  onChange={this.removeSender}
                  readOnly={true}
                />
              )}
            </Wrapper>
            <Balance>{propValueOr(sender, "total", 0)}</Balance>
          </Field>
          <Field>
            <Label>Amount</Label>
            <Wrapper>
              <FormField
                fullWidth
                placeholder={"Enter amount to transfer..."}
                type={"number"}
                min={"0"}
                max={propValueOr(sender, "total", "0")}
                value={amount}
                onChange={val => {
                  const newVal = val;
                  this.setState({
                    amount:
                      isNaN(newVal) ||
                      newVal > propValueOr(sender, "total", "0")
                        ? null
                        : newVal
                  });
                }}
              />
            </Wrapper>
          </Field>
          <Field>
            <Label>Code</Label>
            <Wrapper>
              <FormField
                fullWidth
                placeholder={"Enter access code..."}
                value={code}
                onChange={val => {
                  this.setState({ code: val });
                }}
              />
            </Wrapper>
          </Field>
          <ButtonKit
            shape={"rounded"}
            color={"rival_red"}
            fullWidth
            onClick={() => this.handleTransfer()}
            preloader={this.state.transferring}
            disabled={!this.validateFields()}
          >
            Transfer
          </ButtonKit>
          <Error>{error}</Error>
        </Content>
      </Container>
    );
  };

  renderSuccess = () => {
    return (
      <Container>
        <Content>
          <Title>Success!</Title>
          <WrapIcon>
            <Icon src={greenCheck} />
          </WrapIcon>
        </Content>
      </Container>
    );
  };

  render() {
    const { success } = this.state;

    if (success) {
      return this.renderSuccess();
    }

    return this.renderTransfer();
  }
}

const mapStateProps = state => ({
  wallet: state.walletState.wallet
});

const mapDispatchToProps = {
  fetchWallet
};

export default connect(mapStateProps, mapDispatchToProps)(Transfer);
