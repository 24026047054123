// import rocketLeagueTitle from "./rocketleague-title.webp";

export const imageFields = [
  {
    key: "thumbnail",
    description:
      "This will show in the list of tournaments on the Game Center page and Dashboard. (min 296px by 444px, 2:3)"
  },
  {
    key: "background",
    description:
      "This will show at the top of the tournaments details page for desktop window widths. (min 1222px by 185px)",
    imageSpecs:
      "Minimum required width and height of 1222px by 185px. Required aspect ratio between 6.59 and 6.61"
  },
  {
    key: "icon",
    description:
      "This icon will be shown on lists of tournaments & the tournament detail page. (220px by 220px)"
  },
  {
    key: "event",
    description: `This image will appear on the "Other events you may be interested in" area of the tournament details page & when being featured. (16:9)`
  },
  {
    key: "match",
    description: `This image will appear on the matchmaking and lobby pages. (min 1440px by 855px)`
  },
  // {
  //   key: "title",
  //   description: `This image will appear in match rows`,
  //   example: rocketLeagueTitle
  // },
  {
    key: "mobile",
    description:
      "This image will appear at the tops of the tournament details page for mobile window widths. (Recommended 759px by 366.66px)(Asepct ratio of 2.07)"
  }
];
