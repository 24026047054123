import Players from "./Players/Players";
import TeamRoster from "./Players/TeamRoster/TeamRoster";
import Games from "./Games/Games";
import Update from "./Update/Update";
import Transactions from "./Transactions/TransactionsContainer";

export const MANAGE_ROUTES = [
  {
    path: "/tournaments/:token/matches",
    component: Games
  },
  {
    path: "/tournaments/:token/players",
    component: Players
  },
  {
    path: "/tournaments/:token/players/:slug",
    component: TeamRoster
  },
  {
    path: "/tournaments/:token",
    component: Update
  },
  {
    path: "/tournaments/:token/transactions",
    component: Transactions
  }
];
