import React from "react";

import {
  Route404BackgroundImage,
  Route404CaptionSD,
  Route404ContentSD,
  Route404ErrorSD,
  Route404SD,
  Route404TextSD
} from "./styled";
import { Link } from "react-router-dom";
import ButtonKit from "../../components/kit/Button/ButtonKit";

function Route404() {
  return (
    <Route404SD>
      <Route404ContentSD>
        <Route404ErrorSD>404</Route404ErrorSD>
        <Route404CaptionSD>Oh no! It looks like you’re lost</Route404CaptionSD>
        <Route404TextSD>
          The page you are looking for can not be found.{" "}
        </Route404TextSD>

        <ButtonKit
          fullWidth
          appearance={"primary"}
          as={({ appearance, fullWidth, uppercase, ...rest }) => (
            <Link {...rest} />
          )}
          to={"/"}
        >
          Go to homepage
        </ButtonKit>
      </Route404ContentSD>

      <Route404BackgroundImage />
    </Route404SD>
  );
}

Route404.propTypes = {};

export default Route404;
