export const getStatus = (player, game) => {
  if (game.status === "complete") {
    return "COMPLETE";
  }

  if (game.status === "challenged") {
    return !!player.imageUrl ? "CHALLENGED" : "PENDING";
  }

  if (!!player.submitted) {
    return "PENDING";
  }

  if (!!player.ready && !player.submitted && game.status === "initialized") {
    return "READY";
  }

  if ((!!player.ready && !player.submitted) || game.status === "playing") {
    return "PLAYING";
  }

  if (!!player.ready) {
    return "READY";
  }

  return "NOT READY";
};
