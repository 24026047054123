import styled from "styled-components";
import { COLOR, FONT_WEIGHT, FONT_FAMILY } from "../../../constants/theme";
import breakpoints from "../../../helpers/breakpoints";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  padding: 25px;
  border-radius: 16px;

  width: 900px;

  background-color: ${COLOR.BG_SECONDARY};
  color: ${COLOR.TEXT};

  ${breakpoints.down("sm")} {
    width: 600px;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
  padding-bottom: 20px;
  border-bottom: 1px solid ${COLOR.BORDER};
`;

export const Title = styled.div`
  font: ${FONT_WEIGHT.NORMAL} 20px ${FONT_FAMILY.GOTHIC_BOLD};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  padding-top: 20px;

  height: 100%;
  max-height: 700px;

  overflow-y: auto;

  > :not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const WrapImageContainer = styled.div`
  width: 100%;
  height: 660px;

  ${breakpoints.down("sm")} {
    height: 440px;
  }
`;

export const ImageContainer = styled.iframe`
  width: 100%;
  height: 660px;

  ${breakpoints.down("sm")} {
    height: 440px;
  }
`;
