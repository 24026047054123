import React, { Component } from "react";
import { Container, Form, Field, Icon, WrapIcon } from "./styled";
import iconFlag from "../../../../static/icons/icon-flag.svg";

export default class InputPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: ""
    };
  }

  onChange = e => {
    e.preventDefault();
    this.setState({
      message: e.target.value
    });
  };

  onSubmit = e => {
    const { message } = this.state;
    e.preventDefault();
    this.props.scrollToBottom();

    if (!message.length) {
      return;
    }

    if (
      ["@moderator", "@rival", "@rivalgames", "@mod", "@admin"].indexOf(
        message
      ) >= 0
    ) {
      if (this.props.showModeratorDialog) {
        this.props.showModeratorDialog();
      }
    }

    this.props.pubnub.publish({
      message: message,
      meta: this.props.meta,
      channel: this.props.channel
    });

    this.setState({
      message: ""
    });
  };

  render() {
    const { showModeratorDialog, moderatorAt } = this.props;
    return (
      <Container>
        <Form onSubmit={this.onSubmit}>
          <Field
            value={this.state.message}
            onChange={this.onChange}
            placeholder={"Type Message"}
          />
        </Form>
        {showModeratorDialog && (
          <WrapIcon
            moderatorAt={moderatorAt}
            onClick={() => showModeratorDialog()}
          >
            <Icon src={iconFlag} />
          </WrapIcon>
        )}
      </Container>
    );
  }
}
