import React, { Component } from "react";
import {
  Container,
  WrapMatch,
  MatchSelector,
  Item,
  ItemInfo,
  WrapItem,
  DeleteIcon,
  Content,
  Text,
  Error
} from "./styled";
import Match from "./Match/Match";
import { cloneDeep, groupBy } from "lodash";
import moment from "moment";
import momentTz from "moment-timezone";
import IconCirclePlus from "../../../../components/kit/Icon/IconCirclePlus";
import ButtonKit from "../../../../components/kit/Button/ButtonKit";
import iconTrash from "../../../../static/icons/icon-trash.svg";
const zone_name = momentTz.tz.guess();
const timezone = momentTz.tz(zone_name).zoneAbbr();

class Schedule extends Component {
  constructor(props) {
    super();

    this.state = {
      matchIndex: 0
    };
  }

  renderMatchSelector = matches => {
    const { season } = this.props;
    const isInProgress = season?.status === "in progress";
    const seasonMatches = season?.seasonMatches || [];
    return (
      <MatchSelector>
        {(matches || []).map((match, index) => {
          const m = seasonMatches.find(sm => sm?.round === match?.round);
          return (
            <WrapItem
              onClick={() => this.setState({ matchIndex: index })}
              isSelected={this.state.matchIndex === index}
              key={`${match?.round} ${index}`}
            >
              <DeleteIcon
                hide={
                  isInProgress &&
                  (!!season?.created || index !== (matches || [])?.length - 1)
                }
                onClick={() => {
                  if (
                    window.confirm(
                      "Are you sure you wish to delete this match? Any unsaved data will have to be re-entered manually."
                    )
                  ) {
                    this.props.deleteMatch(match, index);
                    this.setState({ matchIndex: 0 });
                  }
                }}
              />
              <Item>Round {m?.round || match?.round}</Item>
              <ItemInfo isSelected={this.state.matchIndex === index}>
                {moment(m?.startDate || match?.startDate).format(
                  "MMM Do 'YY, hh:mm A "
                )}
                {timezone}
              </ItemInfo>
            </WrapItem>
          );
        })}
        {(isInProgress ? !season?.deleted : true) && (
          <WrapItem
            onClick={() => {
              this.props.addMatch(
                isInProgress
                  ? matches && matches?.length > 0
                    ? {
                        round: matches[matches?.length - 1]?.round,
                        startDate: moment(
                          matches[matches?.length - 1]?.startDate
                        ).add(15, "minutes"),
                        settings: matches[matches?.length - 1]?.settings
                      }
                    : {
                        round: 1,
                        startDate: moment(new Date()).add(15, "minutes"),
                        settings: { settings: {} }
                      }
                  : {
                      ...(cloneDeep(matches[matches?.length - 1]) || {}),
                      startDate: moment(
                        matches[matches?.length - 1]?.startDate ||
                          season?.startDate ||
                          new Date()
                      ).add(15, "minutes")
                    }
              );
              this.setState({
                matchIndex: isInProgress
                  ? matches?.length || 0
                  : this.props.schedule?.length
              });
            }}
            style={{
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Item style={{ marginRight: "8px", marginTop: "4px" }}>
              Create Round
            </Item>
            <IconCirclePlus width={20} height={20} />
          </WrapItem>
        )}
      </MatchSelector>
    );
  };

  renderInitialized = matches => {
    return (
      <WrapMatch>
        <Match
          match={matches[this.state.matchIndex]}
          schedule={matches}
          handleMatch={this.props.handleMatch}
          deleteMatch={this.props.deleteMatch}
          currentIndex={this.state.matchIndex}
        />
      </WrapMatch>
    );
  };

  renderInProgress = matches => {
    const { season } = this.props;
    const newMatches = Object.values(groupBy(matches || [], "round"))
      .filter((round, index) => {
        const seasonMatch = season?.seasonMatches?.find(
          sm => round[0]?.round && sm?.round === round[0]?.round
        );
        return !(round || []).some(m => !!m?.matchId) && !seasonMatch?.delete;
      })
      .map((round, index) => round[0] || {})
      .concat(season?.seasonMatches?.filter(sm => !!sm.create) || []);

    return (
      <Content>
        {this.props.season?.status === "in progress" && (
          <ButtonKit
            style={{ margin: "0 0 10px" }}
            appearance="secondary"
            color={"rival_red"}
            shape={"rounded"}
            width={"275px"}
            disabled={!(this.props.season?.seasonMatches?.length > 0)}
            onClick={() => {
              if (
                window.confirm(
                  "Are you sure you want to discard changes to the season schedule?"
                )
              ) {
                this.props.setSeasonProperty("seasonMatches", []);
                this.props.setSeasonState({
                  created: undefined,
                  deleted: undefined
                });
              }
            }}
          >
            <>
              <img
                src={iconTrash}
                width={18}
                height={18}
                style={{ margin: "0 7px 0 0" }}
                alt=""
              />
              Clear Schedule Changes
            </>
          </ButtonKit>
        )}
        {this.renderMatchSelector(newMatches)}
        <Text>
          **Note: If a match is not displayed, that means it has already been
          started. You can update individual matchups in the 'Matches' section.
        </Text>
        <WrapMatch>
          <Match
            match={newMatches[this.state.matchIndex]}
            schedule={newMatches}
            currentIndex={this.state.matchIndex}
          />
        </WrapMatch>
      </Content>
    );
  };

  renderForCreate = matches => {
    return (
      <WrapMatch>
        <Match
          match={matches[this.state.matchIndex]}
          schedule={matches}
          handleMatch={this.props.handleMatch}
          deleteMatch={this.props.deleteMatch}
          currentIndex={this.state.matchIndex}
          isCreate
        />
      </WrapMatch>
    );
  };

  render() {
    const { schedule, isCreate, season, errors } = this.props;

    const matches =
      season?.status === "initialized" || isCreate
        ? season?.schedule
        : season?.status === "in progress"
        ? season?.matches
        : [];

    return (
      <Container>
        {(season?.status === "initialized" || isCreate) &&
          this.renderMatchSelector(matches)}
        {errors?.schedule && <Error>{errors.schedule}</Error>}
        {(schedule || []).length > 0 ? (
          isCreate ? (
            this.renderForCreate(matches)
          ) : season?.status === "initialized" ? (
            this.renderInitialized(matches)
          ) : season?.status === "in progress" ? (
            this.renderInProgress(matches)
          ) : null
        ) : (
          <div>
            {season?.status === "complete"
              ? "SEASON OVER -- Cannot update matches for a completed season"
              : "No Rounds Created - Must have at least one round in a season"}
          </div>
        )}
      </Container>
    );
  }
}

export default Schedule;
