import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { fetchTransaction } from "../../../store/wallet/actions";
import Transaction from "./Transaction";
import PermissionDenied from "../../denied/PermissionDenied";

class TransactionContainer extends Component {
  render() {
    const { permission } = this.props;
    if (!permission) {
      return <PermissionDenied />;
    }
    return <Transaction {...this.props} />;
  }
}

const mapStateProps = state => ({
  user: state.user.info,
  transaction: state.walletState.transaction,
  isFetching: state.walletState.isFetching
});

const mapDispatchToProps = {
  fetchTransaction
};

export default connect(
  mapStateProps,
  mapDispatchToProps
)(withRouter(TransactionContainer));
