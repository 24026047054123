import styled from "styled-components";
import { COLOR, FONT_WEIGHT, FONT_FAMILY } from "../../constants/theme";

export const Container = styled.div`
  display: flex;
  background-color: ${COLOR.BG_PRIMARY};
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  padding-bottom: 20px;
  user-select: text;
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  padding: 20px;
  border-radius: 16px;

  background-color: ${COLOR.BG_SECONDARY};
`;

export const List = styled.div`
  display: grid;
  grid-auto-flow: column;
  width: 500px;

  padding: 15px;
`;

export const ListContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  margin-bottom: 10px;

  background-color: ${COLOR.BG_ACCENT};
  border-radius: 8px;

  overflow-y: auto;
`;

export const GameCard = styled.img`
  width: 94px;
  height: 141px;

  border-radius: 8px;
  overflow: hidden;
`;

export const GameHover = styled.div`
  width: 94px;
  height: 141px;
  position: absolute;
  z-index: 3;
  border-radius: 8px;
  overflow: hidden;
`;

export const WrapGameCard = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  border: ${props =>
    props.selected ? `3px solid ${COLOR.RIVAL_RED}` : "3px solid transparent"};
  border-radius: 10px;

  cursor: pointer;
  &:hover {
    > ${GameHover} {
      background-color: rgba(0, 0, 0, 0.4);
    }
  }
`;

export const CheckmarkImage = styled.img`
  width: 25px;
  margin-top: 5px;
`;

export const WrapCheckmark = styled.div`
  position: absolute;
  z-index: 4;
  display: ${props => (props.show ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  z-index: 1000;

  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;

  width: 31px;
  height: 31px;

  border-radius: 50%;
  background-color: ${COLOR.RIVAL_RED};
`;

export const TemplateContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  width: 100%;
  margin-bottom: 10px;

  background-color: ${COLOR.BG_ACCENT};
  border-radius: 8px;
  color: ${COLOR.WHITE};
`;

export const WrapButtons = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  > :not(:last-child) {
    margin-bottom: 10px;
  }
`;

export const Icon = styled.div`
  cursor: pointer;
`;

export const Wrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const WrapEmpty = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  text-align: center;
  margin: 20px 0;
`;

export const ConsoleList = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  > :first-child {
    border-radius: 8px 0 0 8px;
  }
  > :last-child {
    border-radius: 0 8px 8px 0;
  }
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const ConsoleCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 160px;
  height: 60px;
  padding: 15px;

  border: 1px solid ${COLOR.RIVAL_RED};
  ${({ active }) => (active ? `background-color: ${COLOR.RIVAL_RED};` : null)}

  cursor: pointer;
  &:hover {
    background-color: ${COLOR.RIVAL_RED};
  }

  transition: all 0.4s;
`;

export const ConsoleImage = styled.img`
  max-height: 20px;
  max-width: 80%;
  overflow: hidden;
`;

export const WrapInput = styled.div`
  width: 100%;
  margin-top: 15px;
  position: relative;
`;
export const Input = styled.input`
  width: 100%;
  margin-bottom: 5px;
`;
export const Label = styled.div`
  text-transform: capitalize;
  font: ${FONT_WEIGHT.MEDIUM} 14px ${FONT_FAMILY.GOTHIC};
  margin-bottom: 3px;
`;

export const Description = styled.div`
  font: ${FONT_WEIGHT.NORMAL} 12px ${FONT_FAMILY.GOTHIC};
  margin-bottom: 10px;
  color: ${COLOR.GREY};
`;

export const Preview = styled.img`
  max-height: 100px;
`;

export const PreviewWrap = styled.div`
  height: 100px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const Info = styled.div`
  display: flex;
  margin-top: 10px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
  padding-bottom: 20px;
  margin-bottom: 20px;

  border-bottom: 1px solid ${COLOR.BORDER};
`;

export const Title = styled.div`
  width: 100%;
  font: ${FONT_WEIGHT.NORMAL} 16px ${FONT_FAMILY.GOTHIC_BOLD};
`;

export const WrapTitle = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const WrapLabel = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
`;

export const WrapError = styled.div`
  margin: 4px 5px 0 0;
  font: ${FONT_WEIGHT.MEDIUM} 12px / 1.1 ${FONT_FAMILY.GOTHIC};
  letter-spacing: 0.3px;
  color: ${({ error }) => (error ? COLOR.CHERRY_RED : COLOR.WHITE)};
`;

export const Example = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const SettingFields = styled.div`
  display: flex;
  flex-direction: row;
`;

export const SettingField = styled.div`
  margin: 10px;
  width: 100%;
`;
