import React, { Component } from "react";
import PropTypes from "prop-types";
import EmailForm from "../EmailForm/EmailForm";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import EmailDialogSuccess from "../../../components/dialogs/EmailDialog/EmailDialogSuccess";
import EmailDialog from "../../../components/dialogs/EmailDialog/EmailDialog";
import {
  addEmail,
  createDraftsEmail,
  deleteEmail,
  deleteScheduledEmail,
  updateScheduledEmail
} from "../../../store/communication/actions";

class CommunicationScheduledEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: null,
      isEmpty: false,
      isUpdatingScheduled: false,
      isUpdateScheduledDialogOpen: false,
      isCreatingDraft: false,
      isCreatingDraftDialogOpen: false,
      isDiscarding: false,
      isDiscardDialogOpen: false
    };
  }

  componentDidMount() {
    const { emails, match, history } = this.props;

    (!emails || emails.length === 0) &&
      history.push("/communication/scheduled");

    if (match.params.id) {
      this.setState({
        email: emails.filter(e => "" + e.id === match.params.id)[0]
      });
    }
  }

  handleUpdateScheduled = async email => {
    this.setState({ isUpdatingScheduled: true });
    try {
      await this.props.updateScheduledEmail(email);
      this.setState({
        isUpdatingScheduled: false,
        isUpdateScheduledDialogOpen: true
      });
    } catch (err) {
      this.setState({ isUpdatingScheduled: false });
    }
  };

  handleCreateDraft = async email => {
    this.setState({ isCreatingDraft: true });
    try {
      await this.props.createDraftsEmail(email);
      this.setState({
        isCreatingDraft: false,
        isCreatingDraftDialogOpen: true
      });
    } catch (err) {
      this.setState({ isCreatingDraft: false });
    }
  };

  handleDiscard = () => {
    this.setState({
      isDiscardDialogOpen: true,
      isEmpty: false
    });
  };

  handleRedirectToCreateTemplate = email => {
    const { history, addEmail } = this.props;
    addEmail(email);
    history.push({
      pathname: "/communication/templates/create",
      state: {
        saveEmail: true
      }
    });
  };

  onDiscard = async () => {
    const { email } = this.state;
    const { history } = this.props;

    this.setState({ isDiscarding: true });

    try {
      await this.props.deleteScheduledEmail(email.id);
      this.setState({
        isDiscarding: false,
        isEmpty: true,
        isDiscardDialogOpen: false
      });
      history.push("/communication/scheduled");
    } catch (err) {
      this.setState({ isDiscarding: false });
    }
  };

  renderUpdatedDialog = () => {
    const { isUpdateScheduledDialogOpen } = this.state;
    return (
      <EmailDialogSuccess
        title={"EMAIL UPDATED"}
        text={"Your email has been updated"}
        confirmText={"Ok"}
        isOpen={isUpdateScheduledDialogOpen}
        onClose={() => this.setState({ isUpdateScheduledDialogOpen: false })}
        onConfirm={() => this.setState({ isUpdateScheduledDialogOpen: false })}
      />
    );
  };

  renderCreatingDraftDialog = () => {
    const { isCreatingDraftDialogOpen } = this.state;
    const { history } = this.props;

    return (
      <EmailDialogSuccess
        title={"DRAFT CREATED"}
        text={"Your draft has been created for:"}
        date={new Date().getTime()}
        isOpen={isCreatingDraftDialogOpen}
        onClose={() => this.setState({ isCreatingDraftDialogOpen: false })}
        onConfirm={() => history.push("/communication/drafts")}
        confirmText={"SEE DRAFTS"}
      />
    );
  };

  renderDiscardDialog = () => {
    const { isDiscardDialogOpen, isDiscarding } = this.state;

    return (
      <EmailDialog
        title={"DISCARD EMAIL"}
        text={"Are you sure you want to delete this email?"}
        isOpen={isDiscardDialogOpen}
        onClose={() => this.setState({ isDiscardDialogOpen: false })}
        confirmPreloader={isDiscarding}
        onConfirm={this.onDiscard}
      />
    );
  };

  render() {
    const { email, isEmpty, isCreatingDraft, isUpdatingScheduled } = this.state;

    const { handleSetImage, addEmail, deleteEmail } = this.props;

    return (
      <>
        <EmailForm
          email={email}
          isEmpty={isEmpty}
          handleSetImage={handleSetImage}
          handleUpdate={this.handleUpdateScheduled}
          handleCreateDraft={this.handleCreateDraft}
          handleDiscard={this.handleDiscard}
          handleRedirectToCreateTemplate={this.handleRedirectToCreateTemplate}
          isUpdating={isUpdatingScheduled}
          isCreatingDraft={isCreatingDraft}
          addEmail={addEmail}
          deleteEmail={deleteEmail}
        />
        {this.renderUpdatedDialog()}
        {this.renderDiscardDialog()}
        {this.renderCreatingDraftDialog()}
      </>
    );
  }
}

CommunicationScheduledEdit.propTypes = {
  emails: PropTypes.arrayOf(PropTypes.object),
  addEmail: PropTypes.func.isRequired,
  deleteEmail: PropTypes.func.isRequired,
  createDraftsEmail: PropTypes.func.isRequired,
  deleteScheduledEmail: PropTypes.func.isRequired,
  updateScheduledEmail: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  emails: state.communication.scheduled
});

const mapDispatchToProps = {
  addEmail,
  deleteEmail,
  createDraftsEmail,
  deleteScheduledEmail,
  updateScheduledEmail
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(CommunicationScheduledEdit);
