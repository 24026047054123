import GAMES_ACTIONS from "./constants";

const initialState = {
  game: {},
  games: [],
  templates: [],
  error: null,
  isFetching: false,
  isFetchingTemplates: false
};

const reducers = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GAMES_ACTIONS.list.request:
      return {
        ...state,
        isFetching: true
      };
    case GAMES_ACTIONS.list.success:
      return {
        ...state,
        games: action.payload,
        isFetching: false,
        error: null
      };
    case GAMES_ACTIONS.list.error:
      return {
        ...state,
        games: [],
        isFetching: false,
        error: payload
      };
    case GAMES_ACTIONS.fetch.request:
      return {
        ...state,
        isFetching: true
      };
    case GAMES_ACTIONS.fetch.success:
      return {
        ...state,
        game: action.payload,
        isFetching: false,
        error: null
      };
    case GAMES_ACTIONS.fetch.error:
      return {
        ...state,
        game: {},
        isFetching: false,
        error: payload
      };
    case GAMES_ACTIONS.templates.request:
      return {
        ...state,
        isFetchingTemplates: true
      };
    case GAMES_ACTIONS.templates.success:
      return {
        ...state,
        templates: action.payload,
        isFetchingTemplates: false
      };
    case GAMES_ACTIONS.templates.error:
      return {
        ...state,
        isFetchingTemplates: false
      };
    case GAMES_ACTIONS.reset:
      return initialState;
    default:
      return state;
  }
};

export default reducers;
