import styled, { css } from "styled-components";
import PropTypes from "prop-types";

import { COLOR, FONT_FAMILY, FONT_WEIGHT } from "../../../../constants/theme";
import { SVGIconSD } from "../../Icon/styled";

export const CheckboxRootSD = styled.label`
	position:relative;
	display: inline-block;
	font-size: 0;
	
	${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
    `};
		
	&:hover {
		cursor: ${props => (props.isBig ? "pointer" : "")};
	}
	.checkbox__input {
		position:absolute;
		appearance: none;
		
		&:focus {
			outline: none;
			
			& ~ .checkbox__icon {
				border-color: ${COLOR.WHITE};
			}
		}
		
		&:checked ~ .checkbox__icon {
			background-color: ${props => (props.isBig ? "transparent" : COLOR.GREY)};
			//border-color: ${COLOR.NAVY_BLUE};
			
			${SVGIconSD} {
				display: block;
			}
		}
		
		&:disabled ~ .checkbox__icon,
		&:disabled ~ .checkbox__text {
			opacity: 0.6;
		}
	}
	
	.checkbox__icon {
		display: inline-flex;
		width: ${props => (props.isBig ? "20px" : "14px")};
		height: ${props => (props.isBig ? "20px" : "14px")};
		margin-right: 10px;
		
		border: 1px solid ${COLOR.WHITE};
		border-radius: 3px;
		
		vertical-align: middle;
		
		${SVGIconSD} {
			display: none;
			width: ${props => (props.isBig ? "16px" : "10px")};
			margin:auto;
			fill: ${COLOR.WHITE};
		}
	}
	
	.checkbox__text {
		display: inline-block;
				
		color: ${COLOR.TEXT};
		font: ${FONT_WEIGHT.NORMAL} 15px / 1 ${FONT_FAMILY.THIN};
		vertical-align: middle;
	}
`;
CheckboxRootSD.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  defaultChecked: PropTypes.bool,
  checked: PropTypes.bool,
  value: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool
};
