import { useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

function AppScrollToTop({ children, location: { pathname } }) {
  useEffect(() => window.scrollTo(0, 0), [pathname]);

  return children;
}

AppScrollToTop.propTypes = {
  children: PropTypes.any,
  location: PropTypes.shape({
    pathname: PropTypes.string
  })
};

export default withRouter(AppScrollToTop);
