import React from "react";
import {
  Container,
  Avatar,
  Text,
  WrapManualScore,
  WrapScore,
  ManualScore,
  Badge,
  ScoreButtons,
  EditScore,
  VerifyScore
} from "./styled";
import { propValueOr, getUsername } from "../../../../helpers/common";
import placeholderUserImg from "../../../../static/images/default-user.jpg";
import { getStatus } from "./constants";

export const Player = ({
  game,
  player,
  singlePlayer,
  handleVerifyScore,
  handleEditScore
}) => (
  <Container>
    <Avatar
      src={propValueOr(
        player,
        "team.imageInfo.thumbnail",
        propValueOr(player, "imageInfo.thumbnail", placeholderUserImg)
      )}
      onError={e => (e.target.src = placeholderUserImg)}
    />
    <Text>{propValueOr(player, "team.name", getUsername(player))}</Text>
    <Badge status={getStatus(player, game)}>{getStatus(player, game)}</Badge>
    {game.status === "pending" && (
      <WrapManualScore>
        <WrapScore>
          <ManualScore>
            {`Submitted Score: ${propValueOr(
              player,
              "scores.manual",
              "Pending..."
            )}`}
          </ManualScore>
        </WrapScore>
        {propValueOr(player, "scores.manual") && (
          <ScoreButtons>
            {singlePlayer && (
              <EditScore onClick={() => handleEditScore(player)}>
                EDIT
              </EditScore>
            )}
            <VerifyScore onClick={() => handleVerifyScore(player)}>
              REVIEW
            </VerifyScore>
          </ScoreButtons>
        )}
      </WrapManualScore>
    )}
  </Container>
);
