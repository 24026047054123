import { verifyPermission } from "../../../helpers/common";

export const getFilterOptions = permissions => {
  let options = [
    {
      label: "ALL",
      value: "all"
    },
    {
      label: "CHAT REQUEST",
      value: "chat request"
    },
    {
      label: "REVIEW SCORE",
      value: "review score"
    },
    {
      label: "CHALLENGE",
      value: "match challenge"
    }
  ];

  if (verifyPermission("/notifications/avatar-requests", permissions)) {
    options.push({
      label: "AVATAR REQUEST",
      value: "avatar request"
    });
  }

  return options;
};

export const getStatus = (player, game) => {
  if (game.status === "complete") {
    return "COMPLETE";
  }

  if (game.status === "challenged") {
    return !!player.imageUrl ? "CHALLENGED" : "PENDING";
  }

  if (!!player.submitted) {
    return "PENDING";
  }

  if (!!player.ready && !player.submitted && game.status === "initialized") {
    return "READY";
  }

  if ((!!player.ready && !player.submitted) || game.status === "playing") {
    return "PLAYING";
  }

  if (!!player.ready) {
    return "READY";
  }

  return "NOT READY";
};
