import styled from "styled-components";
//import { COLOR, FONT_FAMILY, FONT_WEIGHT } from "../../../constants/theme";

export const Container = styled.div`
  padding: 20px 10px;
`;

export const Error = styled.div`
  color: red;
  margin: 10px 0;
  font-size: 18px;
`;
