import React, { Component } from "react";
import { Container, AddStageBtn } from "./styled";
import Panel from "../../../../TournamentCreate/Stages/Panel/Panel";
import Stage from "./Stage/Stage";
import Button from "../../../../../../components/kit/Button/ButtonKit";

class Stages extends Component {
  render() {
    const {
      stages,
      handleStage,
      addStage,
      deleteStage,
      formState
    } = this.props;

    return (
      <Container>
        {stages.length > 0 ? (
          stages
            .sort((a, b) => a.stage - b.stage)
            .map((stage, index) => (
              <Panel
                key={index}
                open={!!stage.open ? stage.open : false}
                overflowWhenOpen="auto"
                trigger={stage.name ? stage.name : `Stage ${stage.stage}`}
                triggerStyle={{ padding: "15px 25px" }}
                onDelete={() => {
                  if (
                    window.confirm(
                      "Are you sure you want to delete this stage?"
                    )
                  ) {
                    deleteStage(stage);
                  }
                }}
                hideDelete={stage.stage === 1 || !stage.stage}
              >
                <Stage
                  deleteStage={deleteStage}
                  handleStage={handleStage}
                  stage={stage}
                  stages={stages}
                  formState={formState}
                />
              </Panel>
            ))
        ) : (
          <div>
            NO STAGES -- Must have at least one stage to create the tournament.
          </div>
        )}
        <AddStageBtn>
          <Button onClick={addStage} appearance={"secondary"}>
            CREATE A STAGE
          </Button>
        </AddStageBtn>
      </Container>
    );
  }
}

export default Stages;
