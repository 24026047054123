import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { layoutGeneralRoutes, layoutLoggedRoutes } from "./routes";
import Route404 from "../../routes/404/Route404";
import LayoutGeneral from "../../layouts/general/LayoutGeneral";
import Route500 from "../../routes/500/Route500";

const RedirectToLoginPage = () => <Redirect to={"/login"} />;

function NotAuthorizedLayoutRouting({ user }) {
  return (
    <Switch>
      {/*Render LayoutGeneral*/}
      <Route
        render={props => (
          <LayoutGeneral {...props} user={user}>
            {user.requestStatus === 500 ? (
              <Route500 />
            ) : user.requestStatus === 404 ? (
              <Route404 />
            ) : (
              <Switch>
                {layoutGeneralRoutes.map(route => {
                  return (
                    <Route
                      key={route.path}
                      path={route.path}
                      exact={route.exact}
                      render={props => (
                        <route.component {...props} user={user} />
                      )}
                    />
                  );
                })}

                {/*Redirect not authorized user from layoutLoggedRoutes to login page*/}
                {/*The routes with same paths don't redirect to login page*/}
                {layoutLoggedRoutes.map(route => (
                  <Route
                    exact={route.exact}
                    key={route.path}
                    path={route.path}
                    component={RedirectToLoginPage}
                  />
                ))}

                <Route component={Route404} />
              </Switch>
            )}
          </LayoutGeneral>
        )}
      />
    </Switch>
  );
}

NotAuthorizedLayoutRouting.propTypes = {};

export default NotAuthorizedLayoutRouting;
