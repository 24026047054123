import styled from "styled-components";
import { FONT_WEIGHT, FONT_FAMILY, COLOR } from "../../../../constants/theme";
import breakpoints from "../../../../helpers/breakpoints";

export const Container = styled.div`
  width: 434px;
  margin: auto;

  border-radius: 2px;
  background-color: ${COLOR.BG_SECONDARY};
  color: ${COLOR.TEXT};
  position: relative;
  padding: 22px 25px;

  > * {
    margin-bottom: 20px;
  }

  ${breakpoints.down("xs")} {
    width: 320px;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: 1px solid ${COLOR.BORDER};
`;

export const BtnClose = styled.div`
  cursor: pointer;
  transition: all 0.3s;

  svg {
    transform: rotate(45deg);
    fill: ${COLOR.FG_PRIMARY};
    height: 19px;
  }

  &:hover {
    opacity: 0.8;
  }
`;

export const HeaderTitle = styled.div`
  padding: 0px 7px 0;
  color: ${COLOR.TEXT};
  font: ${FONT_WEIGHT.NORMAL} 28px / 32px ${FONT_FAMILY.THICK};
  text-transform: uppercase;
  text-align: center;
  display: flex;
`;

export const TextContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  font: ${FONT_WEIGHT.BOLD} 14px ${FONT_FAMILY.THIN};
  text-align: center;
`;

export const DeleteText = styled.div`
  color: ${COLOR.ROUGE};
`;

export const ProfileList = styled.ul`
  padding: 0;
  margin: 0;

  > li {
    padding: 10px 0px;

    &:not(:last-child) {
      border-bottom: 1px solid ${COLOR.BG_PRIMARY};
    }
  }
`;

export const InviteStatus = styled.div`
  margin: 0px 20px;
  color: white;
  text-transform: uppercase;
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const WrapButton = styled.div`
  margin: 0px 10px;
  width: 100%;
`;
