import styled from "styled-components";
import {
  COLOR,
  FONT_WEIGHT,
  FONT_FAMILY
} from "../../../../../../constants/theme";

export const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  max-width: 370px;
  padding: 15px 20px;

  background-color: ${COLOR.BG_SECONDARY};
  color: ${COLOR.TEXT};
  width: 370px;
`;

export const DialogHeader = styled.div`
  display: flex;
  justify-content: space-between;

  width: 100%;
  padding-bottom: 12px;
  border-bottom: 1px solid ${COLOR.BORDER};
`;

export const DialogTitle = styled.div`
  font: ${FONT_WEIGHT.NORMAL} 28px ${FONT_FAMILY.THICK};
  text-transform: uppercase;
`;

export const PlayerSelection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const SelectionText = styled.div`
  display: flex;
  width: 100%;
  padding: 15px 0px;

  font: ${FONT_WEIGHT.NORMAL} 14px ${FONT_FAMILY.THIN};
`;

export const PlayerRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  margin: 10px 0px;
  font: ${FONT_WEIGHT.NORMAL} 14px ${FONT_FAMILY.THIN};
`;

export const PlayerProfile = styled.img`
  height: 32px;
  width: 32px;
  margin: 0px 10px 0 0;
`;

export const ForfeitWarning = styled.p`
  display: flex;
  width: 100%;

  text-align: center;
  font: ${FONT_WEIGHT.NORMAL} 12px ${FONT_FAMILY.THIN};
  .warning {
    font: ${FONT_WEIGHT.MEDIUM} 12px ${FONT_FAMILY.THIN};
    color: ${COLOR.CHERRY_RED};
    text-transform: uppercase;
  }
`;

export const Buttons = styled.div`
  flex: display;
  flex-direction: column;
  width: 100%;

  > * {
    margin-top: 15px;
  }
`;

export const WrapInputs = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  > :not(:last-child) {
    margin-right: 10px;
  }
`;

export const Success = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 25px;
  > :not(:last-child) {
    margin-bottom: 15px;
  }
`;

export const Text = styled.div`
  width: 100%;
  text-align: center;
  font: ${FONT_WEIGHT.NORMAL} 15px ${FONT_FAMILY.THIN};
`;
