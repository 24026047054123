import styled from "styled-components";
import { COLOR, FONT_WEIGHT, FONT_FAMILY } from "../../../constants/theme";

export const Container = styled.div`
  min-height: 100%;
  background-color: ${COLOR.BG_PRIMARY};
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  padding-bottom: 20px;
`;

export const ContainerWrap = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  margin-top: 25px;
  padding: 0 11.11%;
  margin: 25px auto 0;
`;

export const ContentCard = styled.div`
  display: flex;
  flex-direction: column;

  padding: 30px;
  border-radius: 16px;

  background-color: ${COLOR.BG_SECONDARY};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ItemWrap = styled.div`
  & + & {
    margin-top: 14px;
  }
`;

export const TableCard = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  width: 100%;
  padding: 30px;
  border-radius: 16px;

  background-color: ${COLOR.BG_SECONDARY};
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  padding: 30px;
  font: ${FONT_WEIGHT.BOLD} 28px ${FONT_FAMILY.THIN};
`;

export const HeaderText = styled.div`
  display: flex;
  text-transform: uppercase;
`;
