import styled from "styled-components";
import { COLOR, FONT_FAMILY, FONT_WEIGHT } from "../../../../constants/theme";

export const Container = styled.div``;

export const Platform = styled.div`
  display: flex;
  border-radius: 3px;
  cursor: pointer;
  border: ${props =>
    props.selected ? `3px solid ${COLOR.NAVY_BLUE}` : "3px solid transparent;"};
`;
export const PlatformWrap = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Content = styled.div`
  display: flex;
  flex: 1;
  background-color: ${COLOR.BG_SECONDARY};
  box-shadow: 0 0px 4px rgba(0, 0, 0, 0.5);
  border-radius: 2px;
  align-items: center;
  justify-content: center;
  padding: 8px;
  ${props => props.disabled && `opacity: 0.8;`}
`;
export const Image = styled.img`
  max-height: 20px;
`;

export const WrapInput = styled.div`
  width: 100%;
`;
export const Input = styled.input`
  width: 100%;
  margin-bottom: 5px;
`;
export const Label = styled.div`
  text-transform: capitalize;
  font: ${FONT_WEIGHT.MEDIUM} 14px ${FONT_FAMILY.THIN};
  margin-bottom: 7px;
`;

export const Description = styled.div`
  font: ${FONT_WEIGHT.NORMAL} 12px ${FONT_FAMILY.THIN};
  margin-bottom: 14px;
`;

export const Title = styled.div`
  margin: 20px 0 10px;
`;
