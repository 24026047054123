import React, { Component } from "react";
import { propValueOr } from "../../../../helpers/common";
import { imageFields } from "./constants";
import { Container } from "./styled";
import Uploader from "../../Uploader/Uploader";
class Images extends Component {
  render() {
    const { onChangeField, formState, onResetField } = this.props;
    return (
      <Container>
        {imageFields.map(field => (
          <Uploader
            key={field.key}
            label={field.key + " (optional)"}
            description={field.description}
            onChange={file => onChangeField(field.key, file)}
            constraints={field.constraints}
            imgLabels={field.imgLabels}
            imageDimensions={field.imageDimensions}
            defaultImage={propValueOr(formState, `imageInfo.${field.key}`)}
            onReset={() => onResetField(field.key)}
          />
        ))}
      </Container>
    );
  }
}

export default Images;
