import React from "react";
import styled from "styled-components";
import { COLOR, FONT_WEIGHT, FONT_FAMILY } from "../../../constants/theme";
import Item from "./Item/Item";

const List = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  position: relative;
  width: 100%;
  height: 100%;
  flex: 1;
  /* width */
  ::-webkit-scrollbar {
    width: 16px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${COLOR.BG_ACCENT};
    border: solid 6px ${COLOR.BG_ACCENT};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${COLOR.BG_SECONDARY};
    border-radius: 18px;
    border: solid 6px ${COLOR.BG_ACCENT};
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${COLOR.RIVAL_RED};
  }
`;

const EmptyList = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font: ${FONT_WEIGHT.BOLD} 16px ${FONT_FAMILY.THIN};
  width: 100%;
  height: auto;
  margin: auto;
`;

const NotificationList = ({ actions, fetchActions }) => {
  return (
    <List>
      {!!actions?.length ? (
        (actions || []).map(a => (
          <Item data={a} key={a?.id} fetchActions={fetchActions} />
        ))
      ) : (
        <EmptyList>NO RCC NOTIFICATIONS</EmptyList>
      )}
    </List>
  );
};

export default NotificationList;
