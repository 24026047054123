import styled from "styled-components";
import {
  COLOR,
  FONT_FAMILY_DEFAULT as FONT_FAMILY,
  FONT_WEIGHT
} from "../../../../constants/theme";

export const Container = styled.div``;

export const TableItem = styled.div`
  color: ${COLOR.TEXT};
  font: ${FONT_WEIGHT.NORMAL} 14px / 16px ${FONT_FAMILY.THIN};
  text-decoration: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
`;

export const TableEmpty = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 89px;
  padding-bottom: 67px;
`;

export const TableEmptyEmpty = styled.div`
  margin-bottom: 20px;
  color: ${COLOR.TEXT_4};
  font-size: 14px;
  font-weight: ${FONT_WEIGHT.MEDIUM};
  line-height: 21px;
  text-align: center;
  text-transform: uppercase;
`;
