import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Config,
  Header,
  Title,
  Subtitle,
  Error,
  WrapField,
  WrapCheckboxes,
} from './styled';
import { withRouter } from 'react-router-dom';
import PermissionDenied from '../denied/PermissionDenied';
import FormFieldKit from '../../components/kit/Fields/FormField/FormField';
import { api } from '../..';
import ButtonKit from '../../components/kit/Button/ButtonKit';
import {
  isObjectEmpty,
  propValueOr,
  safePropValueOr,
} from '../../helpers/common';
import { cloneDeep, isEqual, startCase } from 'lodash';

import Panel from '../../components/kit/Panel/Panel';

import { Content, WrapPanel } from './styled';

class AssetsConfig extends Component {
  constructor(props) {
    super();

    this.state = {
      config: '',
      originalConfig: '',
      email: '',
      emailRecipients: '',
      error: '',
      loading: false,
      accessCode: '',
      originalAccessCode: '',
      templates: [],
      icon: '',
      originalIcon: '',
      registrationCheckbox: '',
      registrationCheckboxChanged: false,
      tournamentCheckboxes: ['', '', ''],
      tournamentCheckboxesChanged: false,
      primaryColor: '',
      secondaryColor: '',
    };
  }

  componentDidMount() {
    this.fetchOrganization();
    this.fetchTemplates();
  }

  fetchTemplates = () => {
    Promise.all([api.getGlobalTemplatesEmails()]).then(resp => {
      const [globalTemplatesResp] = resp;

      let templates = [];
      if (globalTemplatesResp) {
        templates = templates.concat(
          globalTemplatesResp.data.docs.map(t => ({
            label: `[GLOBAL] ${t.name}`,
            value: t.name,
            id: t.id,
            type: 'global_template',
          }))
        );
      }

      this.setState({ templates });
    });
  };

  fetchOrganization = () => {
    api.admin.organizations
      .fetch(propValueOr(this.props, 'user.info.organizationId'))
      .then(resp => {
        const config = propValueOr(resp, 'data.config', {});
        const email = propValueOr(resp, 'data.emailTemplate', null);
        const emailRecipients = propValueOr(resp, 'data.emailRecipients', {});
        const accessCode = propValueOr(resp, 'data.accessCode', null);
        const icon = propValueOr(resp, 'data.imageInfo.icon', null);
        this.setState({
          config,
          originalConfig: config,
          email,
          emailRecipients,
          originalRecipients: emailRecipients,
          originalEmail: email,
          accessCode,
          originalAccessCode: accessCode,
          icon: icon || '',
          originalIcon: icon || '',
          registrationCheckbox: resp?.data?.config?.registrationCheckbox || '',
          tournamentCheckboxes: resp?.data?.config?.tournamentCheckboxes || [
            '',
            '',
            '',
          ],
          primaryColor: config?.primaryColor || '',
          secondaryColor: config?.secondaryColor || '',
        });
      });
  };

  updateConfigObject = () => {
    const {
      config,
      originalConfig,
      email,
      originalEmail,
      emailRecipients,
      originalRecipients,
      accessCode,
      originalAccessCode,
      icon,
      originalIcon,
      registrationCheckbox,
      tournamentCheckboxes,
      registrationCheckboxChanged,
      tournamentCheckboxesChanged,
      primaryColor,
      secondaryColor,
    } = this.state;
    this.setState({ loading: true });
    const newConfig = cloneDeep(config);
    if (
      !isEqual(registrationCheckbox, originalConfig?.registrationCheckbox) &&
      registrationCheckboxChanged
    ) {
      newConfig.registrationCheckbox = registrationCheckbox;
    }
    if (
      !isEqual(tournamentCheckboxes, originalConfig?.tournamentCheckboxes) &&
      tournamentCheckboxesChanged
    ) {
      newConfig.tournamentCheckboxes = tournamentCheckboxes;
    }
    if (!isEqual(primaryColor, originalConfig?.primaryColor)) {
      newConfig.primaryColor = `#${primaryColor}`;
    }
    if (!isEqual(secondaryColor, originalConfig?.secondaryColor)) {
      newConfig.secondaryColor = `#${secondaryColor}`;
    }
    api.admin.organizations
      .update(propValueOr(this.props, 'user.info.organizationId'), {
        ...(!isEqual(newConfig, originalConfig) && { config: newConfig }),
        ...(!isEqual(email, originalEmail) && { emailTemplate: email }),
        ...(!isEqual(emailRecipients, originalRecipients) && {
          emailRecipients,
        }),
        ...(!isEqual(accessCode, originalAccessCode) && {
          accessCode: accessCode || null,
        }),
        ...(!isEqual(icon, originalIcon) && {
          imageInfo: {
            ...(icon && { icon }),
          },
        }),
      })
      .then(() => this.fetchOrganization())
      .catch(err =>
        this.setState({ error: propValueOr(err, 'response.data.message') })
      )
      .finally(() => this.setState({ loading: false }));
  };

  render() {
    const {
      config,
      originalConfig,
      email,
      originalEmail,
      emailRecipients,
      originalRecipients,
      accessCode,
      originalAccessCode,
      loading,
      error,
      icon,
      originalIcon,
      registrationCheckbox,
      tournamentCheckboxes,
      primaryColor,
      secondaryColor,
    } = this.state;

    if (!this.props.permission) {
      return <PermissionDenied />;
    }

    const readOnly = this.props.permission === 'read';

    function smartCase(value) {
      let result = startCase(value);
      return result
        .split(' ')
        .map(v => (v === 'Rcc' ? 'RCC' : v))
        .join(' ');
    }

    return (
      <Config>
        <Header>
          <Title>RIVAL CONFIG</Title>
        </Header>
        <WrapField>
          <FormFieldKit
            placeholder="Enter custom checkbox wording..."
            label={'Registration Checkbox Override (optional)'}
            description={
              <>
                Set custom wording for the privacy policy checkbox shown to user
                upon adding or registering for a community. To insert links, use
                the format: [LINK TEXT](LINK URL).
                <br />
                Rival default checkbox consent wording for sharing data with
                community used, unless overridden here.
              </>
            }
            value={registrationCheckbox}
            inputProps={{ maxLength: 600 }}
            onChange={val =>
              this.setState({
                registrationCheckboxChanged: true,
                registrationCheckbox: val,
              })
            }
            type={'text'}
            multiline={true}
            disabled={readOnly}
            fullWidth
          />
        </WrapField>
        <WrapCheckboxes>
          {tournamentCheckboxes?.map((cb, i) => (
            <FormFieldKit
              placeholder="Enter custom checkbox wording..."
              label={`Tournament Checkbox ${i + 1} (Markdown Compatible)`}
              description={
                'Set custom wording for the privacy policy checkbox shown to user upon adding or registering for a community. To insert links, use the format: [LINK TEXT](LINK URL).'
              }
              value={cb}
              inputProps={{ maxLength: 600 }}
              onChange={val =>
                this.setState({
                  tournamentCheckboxesChanged: true,
                  tournamentCheckboxes: [
                    ...tournamentCheckboxes.slice(0, i),
                    val,
                    ...tournamentCheckboxes.slice(i + 1),
                  ],
                })
              }
              type={'text'}
              multiline={true}
              disabled={readOnly}
              fullWidth
            />
          ))}
        </WrapCheckboxes>
        <WrapField>
          <FormFieldKit
            label={'Collect Gender On Registration'}
            value={config?.genderField}
            onChange={val =>
              this.setState({
                config: { ...config, genderField: val },
              })
            }
            fullWidth
            checked={config?.genderField}
            checkbox={true}
            isBig
            disabled={readOnly}
          />
        </WrapField>
        {config &&
          Object.entries(config).map(([prop, val], index) => {
            if (prop === 'registrationCheckbox') {
              return null;
            }
            if (prop === 'tournamentCheckboxes') {
              return null;
            }
            if (prop === 'primaryColor') {
              return null;
            }
            if (prop === 'secondaryColor') {
              return null;
            }
            if (prop === 'genderField') {
              return null;
            }
            const isFlag = typeof val === 'boolean';
            return (
              <WrapField>
                <FormFieldKit
                  label={startCase(prop)}
                  value={val}
                  onChange={val =>
                    this.setState({
                      config: { ...config, [prop]: isFlag ? val : val || '' },
                    })
                  }
                  type={'text'}
                  fullWidth
                  checked={val}
                  checkbox={isFlag}
                  isBig
                  disabled={readOnly}
                />
              </WrapField>
            );
          })}
        <WrapPanel>
          <Panel
            trigger={`Client's Internal Email Addresses - automated notifications`}
            customMargin={'0'}
            overflowWhenOpen="auto"
            open={false}
            customTitleStyling={{
              customPadding: '15px 15px 15px 0px',
            }}
            customTitleHeaderStyling={{
              customFontSize: '16px',
            }}
          >
            <Content>
              {[
                'erasure_request_email',
                'marketing_contact_email',
                'rcc_administrator_email',
                'tournament_winner_notification_email',
                'rival_account_representative',
              ].map(propName => {
                return (
                  <WrapField>
                    <FormFieldKit
                      disabled={readOnly}
                      label={
                        smartCase(propName) +
                        ' (separate emails with a semi-colon ";")'
                      }
                      value={propValueOr(emailRecipients, propName, '')}
                      onChange={val => {
                        const newOb = emailRecipients
                          ? { ...emailRecipients }
                          : {};
                        if (!val) {
                          delete newOb[propName];
                          this.setState({
                            emailRecipients: isObjectEmpty(newOb)
                              ? null
                              : newOb,
                          });
                          return;
                        }
                        this.setState({
                          emailRecipients: {
                            ...(emailRecipients && { ...emailRecipients }),
                            [propName]: val || '',
                          },
                        });
                      }}
                      type={'text'}
                      fullWidth
                    />
                  </WrapField>
                );
              })}
            </Content>
          </Panel>
        </WrapPanel>
        <Subtitle>Email Templates</Subtitle>
        {['welcome'].map(propName => {
          const template = this.state.templates.find(
            ev => ev.value === safePropValueOr(this.state.email, propName, '')
          );

          return (
            <WrapField>
              <FormFieldKit
                disabled={readOnly || !this.state.templates.length}
                select
                selectOptions={[
                  { label: 'None', value: null },
                  ...this.state.templates,
                ]}
                placeholder={
                  this.state.templates.length
                    ? 'Select template...'
                    : 'Templates not found...'
                }
                label={startCase(propName)}
                value={safePropValueOr(template, 'label', null)}
                onChange={val => {
                  const newOb = email ? { ...email } : {};
                  if (!val) {
                    delete newOb[propName];
                    this.setState({
                      email: isObjectEmpty(newOb) ? null : newOb,
                    });
                    return;
                  }
                  this.setState({
                    email: {
                      ...(email && { ...email }),
                      [propName]: val || '',
                    },
                  });
                }}
                fullWidth
              />
            </WrapField>
          );
        })}
        <WrapField>
          <FormFieldKit
            disabled={readOnly}
            label={'Access Code'}
            value={accessCode}
            onChange={val => {
              this.setState({
                accessCode: val,
              });
            }}
            inputProps={{ maxLength: 25 }}
            type={'text'}
            fullWidth
          />
        </WrapField>
        <WrapField>
          <FormFieldKit
            disabled={readOnly}
            label={'Icon URL'}
            description={'Paste link of new image url to use here'}
            placeholder={'https://...'}
            value={icon}
            onChange={val => {
              this.setState({
                icon: val,
              });
            }}
            type={'text'}
            fullWidth
          />
        </WrapField>
        <Subtitle>Community Colors</Subtitle>
        <WrapField>
          <FormFieldKit
            disabled={readOnly}
            label={'Primary Color (Hex Code)'}
            description={
              'The primary color will be used on various buttons and components throughout the site'
            }
            placeholder={'Enter hex code...'}
            value={`${primaryColor?.startsWith('#') ? '' : '#'}${primaryColor ||
              ''}`}
            inputProps={{ maxLength: 7 }}
            onChange={val => {
              const newVal = val.replace(/[^0-9a-fA-F]/g, '');
              this.setState({
                primaryColor: newVal,
              });
            }}
            type={'text'}
            fullWidth
          />
        </WrapField>
        <WrapField>
          <FormFieldKit
            disabled={readOnly}
            label={'Secondary Color (Hex Code)'}
            description={
              'The secondary color will be used on various buttons and components throughout the site'
            }
            placeholder={'Enter hex code...'}
            value={`${
              secondaryColor?.startsWith('#') ? '' : '#'
            }${secondaryColor || ''}`}
            inputProps={{ maxLength: 7 }}
            onChange={val => {
              const newVal = val.replace(/[^0-9a-fA-F]/g, '');
              this.setState({
                secondaryColor: newVal,
              });
            }}
            type={'text'}
            fullWidth
          />
        </WrapField>
        {error && <Error>{error}</Error>}
        <ButtonKit
          disabled={
            isEqual(
              {
                ...config,
                registrationCheckbox,
                tournamentCheckboxes,
                primaryColor,
                secondaryColor,
              },
              originalConfig
            ) &&
            isEqual(email, originalEmail) &&
            isEqual(accessCode, originalAccessCode) &&
            isEqual(emailRecipients, originalRecipients) &&
            isEqual(icon, originalIcon)
          }
          width={150}
          shape={'rounded'}
          appearance={'secondary'}
          color={'rival_red'}
          onClick={this.updateConfigObject}
          preloader={loading}
        >
          Update
        </ButtonKit>
      </Config>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
});
const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AssetsConfig));
