import React, { Component } from "react";
import PropTypes from "prop-types";
import TypographyKit from "../../../components/kit/Typography/TypographyKit";
import { ContentBody, ContentHead, Items, Panel } from "../styled";
import Empty from "../../../components/presentation/Empty/Empty";
import EmailTemplate from "../../../components/smart/Email/EmailTemplate";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import Loader from "../../../components/presentation/Loader/Loader";
import {
  addEmail,
  addGlobalTemplateEmail,
  deleteTemplatesEmail,
  deleteGlobalTemplatesEmail,
  fetchTemplatesEmails,
  fetchGlobalTemplatesEmails
} from "../../../store/communication/actions";
import Sort from "../../../components/kit/Sort/Sort";
import { getSortedTemplates } from "../../../helpers/common";
import PermissionDenied from "../../denied/PermissionDenied";

class CommunicationTemplates extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sort: {
        type: "createdAt",
        direction: "desc"
      }
    };
  }

  componentDidMount() {
    if (this.props.isGlobalTemplates) {
      this.props.fetchGlobalTemplatesEmails();
    } else {
      this.props.fetchTemplatesEmails();
    }
  }

  handleRedirect = (id, preview) =>
    this.props.history.push({
      pathname: `${this.props.location.pathname}/${id}`,
      state: {
        preview: preview
      }
    });

  handleSort = newType => {
    const {
      sort: { type, direction }
    } = this.state;

    let newDirection = !direction
      ? "asc"
      : direction === "asc"
      ? "desc"
      : "asc";

    if (type !== newType) {
      newDirection = "asc";
    }

    this.setState({
      sort: {
        type: newType,
        direction: newDirection
      }
    });
  };

  render() {
    const {
      templates,
      globalTemplates,
      loading,
      deleteTemplatesEmail,
      deleteGlobalTemplatesEmail,
      addEmail,
      history,
      isGlobalTemplates
    } = this.props;

    const { sort } = this.state;

    if (!this.props.permission) {
      return <PermissionDenied />;
    }

    const sortedTemplates = getSortedTemplates(
      isGlobalTemplates ? globalTemplates : templates,
      sort
    );

    const deleteEmail = isGlobalTemplates
      ? deleteGlobalTemplatesEmail
      : deleteTemplatesEmail;

    return loading ? (
      <Loader isBlock />
    ) : (
      <>
        <ContentHead>
          <TypographyKit variant={"caption1"}>
            {isGlobalTemplates ? "Global Templates" : "Templates"}
          </TypographyKit>
        </ContentHead>
        <ContentBody>
          {sortedTemplates && sortedTemplates.length > 0 ? (
            <>
              <Panel>
                <Sort
                  items={[
                    {
                      label: "A-Z",
                      type: "name"
                    },
                    {
                      label: "Date Created",
                      type: "createdAt"
                    }
                  ]}
                  sortingType={sort.type}
                  sortingDirection={sort.direction}
                  sorting={this.handleSort}
                />
              </Panel>
              <Items>
                {sortedTemplates.map((item, index) => (
                  <EmailTemplate
                    handleEdit={() => this.handleRedirect(item.id)}
                    handlePreview={() => this.handleRedirect(item.id, true)}
                    addEmail={addEmail}
                    deleteTemplatesEmail={deleteEmail}
                    key={index}
                    email={item}
                    history={history}
                  />
                ))}
              </Items>
            </>
          ) : (
            <Empty message={"YOU HAVE NO TEMPLATES"} />
          )}
        </ContentBody>
      </>
    );
  }
}

CommunicationTemplates.propTypes = {
  loading: PropTypes.bool,
  templates: PropTypes.arrayOf(PropTypes.object),
  fetchTemplatesEmails: PropTypes.func.isRequired,
  deleteTemplatesEmail: PropTypes.func.isRequired,
  addEmail: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  templates: state.communication.templates,
  globalTemplates: state.communication.globalTemplates,
  loading: state.communication.loading
});

const mapDispatchToProps = {
  fetchTemplatesEmails,
  fetchGlobalTemplatesEmails,
  deleteTemplatesEmail,
  deleteGlobalTemplatesEmail,
  addEmail,
  addGlobalTemplateEmail
};

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(CommunicationTemplates);
