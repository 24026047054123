import styled from "styled-components";

export const Container = styled.div`
  > :not(:last-child) {
    margin-bottom: 30px;
  }
`;

export const Copied = styled.div`
  color: #fff;
  text-decoration: none;
`;
