import React from "react";
import { SVGIconSD } from "./styled";
import PropTypes from "prop-types";

function IconPaperClip(props) {
  return (
    <SVGIconSD width={props.width} viewBox="0 0 11 21">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-374.000000, -1083.000000)" fill="#52C3D0">
          <path
            d="M385,1098 C385,1101 382.5,1103.5 379.5,1103.5 C376.5,1103.5 374,1101 374,1098 L374,1087.5
            C374,1085.3 375.8,1083.5 378,1083.5 C380.2,1083.5 382,1085.3 382,1087.5 L382,1096 C382,1097.4
            380.9,1098.5 379.5,1098.5 C378.1,1098.5 377,1097.4 377,1096 L377,1088.5 L378.5,1088.5 L378.5,1096
            C378.5,1096.6 378.9,1097 379.5,1097 C380.1,1097 380.5,1096.6 380.5,1096 L380.5,1087.5
            C380.5,1086.1 379.4,1085 378,1085 C376.6,1085 375.5,1086.1 375.5,1087.5 L375.5,1098 C375.5,1100.2
            377.3,1102 379.5,1102 C381.7,1102 383.5,1100.2 383.5,1098 L383.5,1088.5
            L385,1088.5 L385,1098 Z"
          />
        </g>
      </g>
    </SVGIconSD>
  );
}

IconPaperClip.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default IconPaperClip;
