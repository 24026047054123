import styled, { css } from "styled-components";
import { COLOR, FONT_FAMILY, FONT_WEIGHT } from "../../../constants/theme";

export const Container = styled.div``;

export const Header = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding-bottom: 20px;
`;

export const BackIcon = styled.img`
  cursor: pointer;
  width: 32px;
  height: 32px;
  margin-right: 10px;
  margin-bottom: 5px;
`;

export const TrashIcon = styled.img`
  width: 32px;
  height: 32px;
  margin-right: 10px;
  margin-bottom: 5px;
  padding: 4px;
  cursor: pointer;
`;

export const WrapIconButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ disabled }) =>
    disabled
      ? `opacity: 0.4;`
      : `cursor: pointer;
      &:hover {
        opacity: 0.4;
      }
    `}
`;

export const RemoveIcon = styled.img`
  width: 30px;
  height: 30px;
`;

export const LinkIcon = styled.img`
  position: absolute;
`;

export const Title = styled.div`
  flex: 1;
  text-align: center;
  font: ${FONT_WEIGHT.NORMAL} 28px ${FONT_FAMILY.THIN};
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    color: ${COLOR.TEXT};
    text-decoration: none;
    cursor: pointer;
    margin-right: 16px;
    position: relative;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const List = styled.div``;

export const Row = styled.div`
  display: flex;
  > :not(:last-child) {
    margin-right: 20px;
  }
`;

export const Settings = styled.div`
  width: 100%;
  margin-bottom: 30px;
`;

export const SettingsTitle = styled.div`
  margin: ${({ margin }) => margin || "20px 0 10px"};
  font-weight: ${FONT_WEIGHT.MEDIUM};
  display: flex;
  align-items: center;
`;
export const Wrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

export const WrapEmpty = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  text-align: center;
  margin: 20px 0;
`;

export const Icon = styled.div`
  cursor: pointer;
  margin-bottom: -6px;
`;

export const Label = styled.div`
  font: ${FONT_WEIGHT.NORMAL} 14px ${FONT_FAMILY.THIN};
`;

export const WrapButtons = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  > :not(:last-child) {
    margin-bottom: 10px;
  }
`;

export const Description = styled.div`
  font: ${FONT_WEIGHT.NORMAL} 14px ${FONT_FAMILY.THIN};
  color: ${COLOR.GREY};
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
`;

export const Subtitle = styled.div`
  font: ${FONT_WEIGHT.MEDIUM} 18px ${FONT_FAMILY.THIN};
  color: ${COLOR.TEXT};
  padding-top: 15px;
  border-top: 1px solid ${COLOR.BORDER};
`;

export const Status = styled.div`
  color: ${({ status }) =>
    status === "initialized"
      ? COLOR.BLUE
      : status === "in progress"
      ? COLOR.GREEN
      : COLOR.GREY};
  margin-right: 5px;
  font-size: 14px;
  text-transform: capitalize;
`;

export const ID = styled.div`
  color: ${COLOR.GREY};
  font-size: 11px;
  margin-left: 5px;
`;

export const SelectLabel = styled.div`
  display: flex;
  align-items: center;
`;

export const NavBar = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding: 0 0 20px;
`;

export const NavItem = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  font: ${FONT_WEIGHT.NORMAL} 18px ${FONT_FAMILY.THIN};
  color: ${COLOR.TEXT};
  margin-right: 25px;
  text-transform: capitalize;
  transition: all 0.3s ease 0s;
  text-decoration-color: transparent;

  &:hover {
    color: ${COLOR.RIVAL_RED};
  }

  ${({ isActive }) =>
    isActive &&
    css`
      text-decoration: underline;
      text-decoration-thickness: 3px;
      text-underline-offset: 10px;
      color: ${COLOR.RIVAL_RED};
      text-decoration-color: ${COLOR.RIVAL_RED};
    `};
`;

export const Console = styled.img`
  margin: 0 0 3px;
  max-width: 100px;
  max-height: 20px;
`;

export const Game = styled.img`
  margin: 0 8px 3px;
  max-width: 100px;
  max-height: 20px;
`;

export const Error = styled.div`
  color: ${COLOR.CHERRY_RED};
  font-size: 18px;
  margin: 10px 0;
`;

export const OtherIcon = styled.img`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 3px;
`;

export const WrapLabel = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const IconWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
`;

export const IconDelete = styled.img`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 3px;
`;
