import styled, { css } from "styled-components";
import breakpoints from "../../../../helpers/breakpoints";
import { COLOR, FONT_WEIGHT } from "../../../../constants/theme";
import PropTypes from "prop-types";
import iconOrganization from "../../../../static/icons/school-default-icon.svg";

// Table data

export const TableItemsContentSD = styled.div`
  display: flex;
  align-items: center;

  ${({ direction }) =>
    direction &&
    css`
      flex-direction: ${direction};
    `};
`;

TableItemsContentSD.propTypes = {
  direction: PropTypes.string
};

export const TableItemsWrapAvatarSD = styled.figure`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  margin: 0 20px 0 0;

  ${breakpoints.down("sm")} {
    width: 28px;
    height: 28px;
    max-width: 100%;
  }
`;

export const TableItemsAvatarSD = styled.img`
  max-width: 100%;
  height: auto;
  object-fit: contain;
`;

export const TableItemsWrapCoinSD = styled.figure`
  margin: 0 4px 0 0;
  width: 24px;
  height: 24px;

  ${breakpoints.down("sm")} {
    width: 16px;
    height: 16px;
  }
`;

export const TableItemsWrapCheckSD = styled.figure`
  width: 24px;
  height: 24px;

  ${breakpoints.down("sm")} {
    width: 16px;
    height: 16px;
  }
`;

export const TableItemsCoinSD = styled.img`
  width: 100%;
  height: auto;
  max-width: 100%;
  object-fit: cover;
`;

export const TableItemsCheckSD = styled.img`
  width: 100%;
  height: auto;
  max-width: 100%;
  object-fit: cover;
`;

export const TableItemsTextSD = styled.div`
  color: ${({ color }) => color};
  font-size: ${({ size }) => size};
  line-height: 16px;
  font-weight: ${FONT_WEIGHT.MEDIUM};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ bold }) =>
    bold &&
    css`
      font-size: 18px;
      font-weight: ${FONT_WEIGHT.BOLD};
      letter-spacing: -0.29px;
    `};

  ${breakpoints.down("xs")} {
    font-size: 12px;
  }
`;

TableItemsTextSD.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string
};

TableItemsTextSD.defaultProps = {
  color: COLOR.TEXT_3,
  size: "16px"
};

export const TableItemsTeamLinkSD = styled.div`
  padding-left: 18px;
  background: url(${iconOrganization}) left center no-repeat;
`;
