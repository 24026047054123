import React, { Component } from "react";
import { connect } from "react-redux";
import {
  WrapPage,
  Content,
  Header,
  HeaderRow,
  WrapInfoIcon,
  Title,
  SubText,
  WrapAsset
} from "./styled";
import { withRouter } from "react-router-dom";
import Asset from "./Asset/Asset";
import InfoDialog from "./InfoDialog/InfoDialog";
import PermissionDenied from "../denied/PermissionDenied";
import { COLOR } from "../../constants/theme";

// Icons & Images
import InfoIcon from "../../static/icons/info-icon.svg";
import EmailPDF from "../../static/images/infoPDFs/email-info.pdf";

class AssetsEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isInfoDialogOpen: false
    };
  }

  renderInfoDialog() {
    return (
      <InfoDialog
        isOpen={this.state.isInfoDialogOpen}
        onClose={() => this.setState({ isInfoDialogOpen: false })}
        title={"Dashboard Image Info"}
        images={[EmailPDF]}
      />
    );
  }

  render() {
    if (!this.props.permission) {
      return <PermissionDenied />;
    }
    return (
      <WrapPage>
        <Content>
          <Header>
            <HeaderRow>
              <Title>Email</Title>
              <WrapInfoIcon
                src={InfoIcon}
                onClick={() => this.setState({ isInfoDialogOpen: true })}
              />
            </HeaderRow>
            <SubText>All images will be compressed down to 500KB</SubText>
          </Header>
          <WrapAsset>
            <Asset
              name="email-header"
              link={{ notRequired: true }}
              image
              imageDimensions={[720, 110]}
              imgLabels={[
                "Recommended minimum width of 1440px",
                "Recommended minimum height of 220px"
              ]}
              aspectRatioLimits={[{ value: 5.75 }, { value: 7.05 }]}
              inputStyles={{ backgroundColor: `${COLOR.BG_SECONDARY}` }}
            />
          </WrapAsset>
          <WrapAsset>
            <Asset
              name="email-footer"
              link={{ notRequired: true }}
              image
              imageDimensions={[720, 110]}
              imgLabels={[
                "Recommended minimum width of 1440px",
                "Recommended minimum height of 220px"
              ]}
              aspectRatioLimits={[{ value: 5.75 }, { value: 7.05 }]}
              inputStyles={{ backgroundColor: `${COLOR.BG_SECONDARY}` }}
            />
          </WrapAsset>
          {this.renderInfoDialog()}
        </Content>
      </WrapPage>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user
});
const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AssetsEmail));
