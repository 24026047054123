import styled from "styled-components";
import { COLOR, FONT_FAMILY, FONT_WEIGHT } from "../../../constants/theme";

export const Container = styled.div`
  background-color: ${COLOR.BG_SECONDARY};
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  margin: ${props => (props.customMargin ? props.customMargin : "5px 20px")};
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${COLOR.TEXT_3};
  font: ${props =>
    FONT_WEIGHT.NORMAL +
    " " +
    (props.customFontSize ? props.customFontSize : "14px") +
    " / 1 " +
    FONT_FAMILY.GOTHIC_BOLD};
  text-decoration: none;
  user-select: none;
  cursor: pointer;
  padding: ${props => (props.customPadding ? props.customPadding : "15px")};
`;

export const Icon = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  color: ${props => (props.triggerColor ? props.triggerColor : COLOR.TEXT_3)};
  font: ${props =>
    FONT_WEIGHT.NORMAL +
    " " +
    (props.customFontSize ? props.customFontSize : "14px") +
    " / 1 " +
    FONT_FAMILY.GOTHIC_BOLD};
  text-decoration: none;
  cursor: pointer;
`;
