import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Container,
  Left,
  Row,
  Column,
  Right,
  Title,
  Image,
  Icon,
  Divider,
  Name,
  WrapOrganization,
  OrgPic,
  WrapTimer
} from "./styled";
import { safePropValueOr, isObjectEmpty } from "../../../helpers/common";
import crossPlatform from "../../../static/icons/cross-platform.svg";
import Scores from "./Scores/Scores";
import Timer from "../../../components/smart/Timer/Timer";
import moment from "moment";

function Info({ game }) {
  if (isObjectEmpty(game)) return null;
  return (
    <Container>
      <Left>
        <Image
          src={safePropValueOr(game, "Game.imageInfo.thumbnail", "")}
        ></Image>
        <Column>
          <Title>{safePropValueOr(game, "Game.name", "")}</Title>
          <Icon
            src={safePropValueOr(game, "console.imageInfo.icon", crossPlatform)}
            width={22}
            height={22}
          />
        </Column>
      </Left>
      {game.status === "complete" ? (
        <Scores />
      ) : safePropValueOr(game, "users.length") === 1 ||
        (game.status === "playing" && game.readyRequired) ||
        game.status !== "playing" ? (
        <WrapTimer>
          {game.status === "pending"
            ? "Score Entry"
            : game.status === "challenged"
            ? "Challenge Clock"
            : game.status === "initialized"
            ? "Forfeit Clock"
            : "Play Window"}
          <span>
            <Timer
              endDate={
                game.status === "pending"
                  ? game.type === "open"
                    ? moment(game.updatedAt).add(20, "minutes")
                    : moment(game.updatedAt).add(3, "minutes")
                  : game.status === "challenged"
                  ? moment(game.updatedAt).add(15, "minutes")
                  : moment(game.forfeitAt)
              }
            />
          </span>
        </WrapTimer>
      ) : (
        <Divider />
      )}
      <Right>
        <Row>
          <Name>{safePropValueOr(game, "tournament.name", "")}</Name>
          <WrapOrganization>
            {safePropValueOr(game, "organization.imageInfo", null) && (
              <OrgPic
                src={safePropValueOr(game, "organization.imageInfo.icon", "")}
              />
            )}
            <Name>{safePropValueOr(game, "organization.name", "")}</Name>
          </WrapOrganization>
        </Row>
      </Right>
    </Container>
  );
}

const mapStateProps = state => ({
  game: state.gameState.game
});

export default connect(mapStateProps)(withRouter(Info));
