import { CONSOLES_ACTIONS } from "./constants";
import { api } from "../../index";
import CrossPlatformIcon from "../../static/icons/cross-platform.svg";
import CrossPlatformThumbnail from "../../static/images/cross-platform.svg";

export const fetchConsolesAction = () => dispatch => {
  dispatch({
    type: CONSOLES_ACTIONS.consoles.request
  });

  return api.admin.consoles
    .get()
    .then(resp => {
      dispatch({
        type: CONSOLES_ACTIONS.consoles.success,
        payload: [
          ...(resp.data || []),
          {
            id: null,
            name: "Cross-Platform",
            imageInfo: {
              icon: CrossPlatformIcon,
              thumbnail: CrossPlatformThumbnail
            }
          }
        ]
      });
    })
    .catch(err => {
      dispatch({ type: CONSOLES_ACTIONS.consoles.error, payload: err });
      throw err;
    });
};

export const resetConsolesStateAction = () => ({
  type: CONSOLES_ACTIONS.resetState
});
