import styled from "styled-components";
import { FONT_FAMILY, FONT_WEIGHT } from "../../../constants/theme";

const Badge = styled.div`
  padding: 2px 5px;
  border-radius: 2px;
  background-color: #ec1c29;
  color: #ffffff;
  text-align: center;
  line-height: 17px;
  margin-left: 10px;
  font: ${FONT_WEIGHT.MEDIUM} 14px / 1 ${FONT_FAMILY.THIN};
`;

export default Badge;
