import styled from "styled-components";
import { COLOR, FONT_WEIGHT, FONT_FAMILY } from "../../../constants/theme";
import { Link } from "react-router-dom";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${COLOR.BG_PRIMARY};
  margin-bottom: 10px;
  border-radius: 4px;
  padding: 10px 0 25px 0;
`;

export const Versus = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 37px;
  min-width: 37px;
  height: 37px;
  margin: 10px 0;

  background-color: ${COLOR.BG_SECONDARY};
  border-radius: 50%;

  color: ${COLOR.TEXT};
  font: ${FONT_WEIGHT.EXTRA_BOLD} 12px / 37px ${FONT_FAMILY.THIN};
  text-align: center;
`;

export const View = styled.div`
  display: flex;
  justify-content: center;
  min-width: 100px;

  padding: 3px 5px;
  border-radius: 12px 12px;
  padding-top: 4px;

  font: ${FONT_WEIGHT.NORMAL} 13px ${FONT_FAMILY.GOTHIC};

  background-color: ${COLOR.BG_SECONDARY};

  &:hover {
    background-color: ${COLOR.NAVY_BLUE};
    cursor: pointer;
  }
`;

export const Button = styled(Link)`
  display: flex;
  justify-content: center;
  min-width: 85px;
  text-align: center;

  cursor: pointer;
  padding: 3px 7px;
  padding-top: 4px;
  top: 8px;
  position: relative;
  border-radius: 12px 12px;

  font: ${FONT_WEIGHT.NORMAL} 13px ${FONT_FAMILY.GOTHIC};
  color: ${COLOR.TEXT};
  text-decoration: none;
  text-transform: uppercase;

  background-color: ${COLOR.RIVAL_RED};

  &:hover {
    opacity: 0.5;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const WrapFinalScore = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100px;
  height: 24px;
  padding: 0 10px;
  border-radius: 12px;
  font: ${FONT_WEIGHT.THIN} 14px ${FONT_FAMILY.THIN};
  text-align: center;
  margin-top: 5px;
  background-color: ${COLOR.GREY};
`;
