import React from "react";
import PropTypes from "prop-types";
import parser, { Tag } from "bbcode-to-react";

import {
  LargeNotificationContentSD,
  LargeNotificationDateSD,
  LargeNotificationImgSD,
  LargeNotificationSD,
  LargeNotificationTitleSD,
  LargeNotificationWrapContentSD,
  LargeNotificationWrapDateSD,
  LargeNotificationWrapImgSD
} from "./styled";
import { Link } from "react-router-dom";
import { getImageSrcFromThumbnail } from "../../../helpers/images";

class BoldTag extends Tag {
  toReact() {
    // using this.getComponents() to get children components.
    return <b>{this.getComponents()}</b>;
  }
}

class LinkTag extends Tag {
  toReact() {
    return <Link to={this.params.to}>{this.getComponents()}</Link>;
  }
}

parser.registerTag("b", BoldTag);
parser.registerTag("link", LinkTag);

/*
 * Parses a sting of title or content by tags and set special component (or element)
 * instead of tags. It should support [b], [url], [link] tags with return b a Link components.
 * @example
 * "[b]Elementary School[/b] K-5 Public Tournament => <b>Elementary School</b> {'K-5 Public Tournament'}
 * [link to="/inner-path"]a new match[/link] => <Link to={'/inner-path'}>a new match</Link>{' '}
 * [url href="http://google.com"]link to google[/url] => <a href={'http://google.com'}>link to google</a>
 */
function LargeNotification(props) {
  const { image, title, content, dateString, wasNotRead } = props;

  const jsxTitle = parser.toReact(title);
  const jsxContent = parser.toReact(content);

  return (
    <LargeNotificationSD wasNotRead={wasNotRead}>
      {!!image && (
        <LargeNotificationWrapImgSD>
          <LargeNotificationImgSD
            src={getImageSrcFromThumbnail(image)}
            alt=""
          />
        </LargeNotificationWrapImgSD>
      )}

      <LargeNotificationWrapContentSD>
        {!!title && (
          <LargeNotificationTitleSD>{jsxTitle}</LargeNotificationTitleSD>
        )}

        {!!content && (
          <LargeNotificationContentSD>{jsxContent}</LargeNotificationContentSD>
        )}
      </LargeNotificationWrapContentSD>

      <LargeNotificationWrapDateSD>
        <LargeNotificationDateSD>{dateString}</LargeNotificationDateSD>
      </LargeNotificationWrapDateSD>
    </LargeNotificationSD>
  );
}

LargeNotification.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string,
  dateString: PropTypes.string.isRequired,
  wasNotRead: PropTypes.bool
};

export default LargeNotification;
