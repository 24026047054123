import { USERS_ACTIONS } from "./constants";

const initialState = {
  users: [],
  page: 1,
  total: 1,
  loading: false,
  error: null
};

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case USERS_ACTIONS.fetch.request:
      return {
        ...state,
        loading: true,
        error: null
      };
    case USERS_ACTIONS.fetch.success:
      return {
        ...state,
        loading: false,
        users: action.payload.docs,
        page: action.payload.page,
        total: action.payload.pages
      };
    case USERS_ACTIONS.fetch.error:
      return {
        ...state,
        users: [],
        page: 1,
        total: 1,
        loading: false,
        error: action.payload
      };

    case USERS_ACTIONS.reset:
      return initialState;
    default:
      return state;
  }
};

export default reducers;
