import React, { Fragment } from "react";
import PropTypes from "prop-types";
import {
  AsideNavItemBodySD,
  AsideNavItemLinkSD,
  AsideNavItemSD,
  AsideNavListSD,
  AsideNavSD,
  Text,
  DisabledText
} from "./styled";
import { Route } from "react-router-dom";
import { LinkWithFilteredProps } from "../../kit/Button/ButtonLinkKit";

function AsideNav({
  items = [],
  shouldActiveItemWithBody,
  renderActiveItemBody,
  notClickable
}) {
  const hasActiveItemBodyRenderMethod =
    typeof renderActiveItemBody === "function";

  return (
    <AsideNavSD>
      <AsideNavListSD>
        {items.map(item =>
          item.disabled === false || item.disabled === null ? (
            <AsideNavItemSD key={item.title}>
              <Route
                exact
                path={item.to}
                children={({ match }) => {
                  const isActive = !!match;

                  return (
                    <Fragment>
                      <AsideNavItemLinkSD
                        isActive={isActive}
                        as={
                          !isActive
                            ? (item.to && LinkWithFilteredProps) || item.as
                            : undefined
                        }
                        to={!isActive ? item.to : undefined}
                        onClick={!isActive ? item.onClick : undefined}
                        notClickable={notClickable}
                      >
                        <Text isActive={isActive}>{item.title}</Text>
                      </AsideNavItemLinkSD>
                      {isActive && hasActiveItemBodyRenderMethod ? (
                        <AsideNavItemBodySD>
                          {renderActiveItemBody(item)}
                        </AsideNavItemBodySD>
                      ) : (
                        /*todo: remove this props after merge all home student pages*/
                        shouldActiveItemWithBody &&
                        isActive &&
                        typeof item.renderBody === "function" && (
                          <AsideNavItemBodySD>
                            {item.renderBody()}
                          </AsideNavItemBodySD>
                        )
                      )}
                    </Fragment>
                  );
                }}
              />
            </AsideNavItemSD>
          ) : (
            <AsideNavItemLinkSD key={item.title}>
              <DisabledText>{item.title}</DisabledText>
            </AsideNavItemLinkSD>
          )
        )}
      </AsideNavListSD>
    </AsideNavSD>
  );
}

AsideNav.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      /**should be unique*/
      title: PropTypes.string.isRequired,
      /** if it has value. The component will render as Link*/
      to: PropTypes.string,
      isActive: PropTypes.bool,
      onClick: PropTypes.func,
      /** doesn't effect to 'to' property. If it set, component will used as Link*/
      as: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
      /** If item is active and it has a renderBody method and property shouldActiveItemWithBody equal true. This item will have a body */
      renderBody: PropTypes.func
    })
  ).isRequired,
  shouldActiveItemWithBody: PropTypes.bool,
  renderActiveItemBody: PropTypes.func
};

export default AsideNav;
