import moment from "moment";
import React, { useState, useEffect } from "react";
import {
  Container,
  WrapImage,
  Image,
  Info,
  Title,
  Date,
  Subtitle,
  ActionInfo,
  Badge,
  Close
} from "./styled";
import { propValueOr } from "../../../../helpers/common";
import MatchItem from "./MatchItem";
import TournamentItem from "./TournamentItem";
import ChallengeItem from "./ChallengeItem";

// Icons
import PlaceHolderImg from "../../../../static/images/default-user.jpg";
import iconClose from "../../../../static/icons/remove.svg";
import { api } from "../../../..";
import { Link } from "react-router-dom";

moment.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "%ds",
    m: "a minute",
    mm: "%dm",
    h: "an hour",
    hh: "%dhr",
    d: "a day",
    dd: "%d days",
    M: "a month",
    MM: "%d months",
    y: "a year",
    yy: "%d years"
  }
});

const Item = ({ data, fetchActions }) => {
  const [date, setDate] = useState(
    moment(
      data?.status === "closed" ? data?.updatedAt : data?.createdAt
    ).fromNow()
  );
  useEffect(() => {
    const intrvl = setInterval(
      () =>
        setDate(
          moment(
            data?.status === "closed" ? data?.updatedAt : data?.createdAt
          ).fromNow()
        ),
      1000
    );

    // returned function will be called on component unmount
    return () => {
      clearInterval(intrvl);
    };
  }, [data]);

  const closeAction = async id => {
    await api.action.close(id).then(resp => fetchActions());
  };

  let link = "";
  let subText = "";
  let renderInfo = () => null;
  if (data?.challenge) {
    link = `/challenge/${data?.challenge?.token}`;
    subText = `Game Id#: ${data?.match?.token}`;
    renderInfo = () => (
      <ChallengeItem match={data?.match} challenge={data?.challenge} />
    );
  } else if (data?.match) {
    link = `/game/${data?.match?.token}`;
    subText = `Game Id#: ${data?.match?.token}`;
    renderInfo = () => (
      <MatchItem match={data?.match} tournament={data?.tournament} />
    );
  } else if (data?.tournament) {
    link = `/tournaments/${data?.tournament?.token}`;
    subText = data?.tournament?.name;
    renderInfo = () => <TournamentItem tournament={data?.tournament} />;
  } else if (data?.user) {
    link = `/users/${data?.user?.id}`;
    subText = data?.user?.username;
  }

  return (
    <Container>
      <Link to={link}>
        <WrapImage>
          <Image
            src={propValueOr(
              data,
              "tournament.imageInfo.icon",
              propValueOr(data, "user.imageInfo.thumbnail", PlaceHolderImg)
            )}
          />
        </WrapImage>
        <Info>
          <Title>{data?.message}</Title>
          <Subtitle>{subText}</Subtitle>
        </Info>
        {renderInfo()}
        <ActionInfo>
          <Badge status={data?.status}>{data?.status}</Badge>
          <Date>{date}</Date>
        </ActionInfo>
      </Link>
      {data?.message?.toLowerCase()?.includes("avatar") &&
        data?.status === "open" && (
          <Close
            onClick={e => {
              closeAction(data?.id);
              e?.preventDefault();
              e?.stopPropagation();
            }}
          >
            <img width={22} height={22} src={iconClose} alt="" />
          </Close>
        )}
    </Container>
  );
};

export default Item;
