import React from "react";
import { Container, Content, Input } from "./styled";
import PropTypes from "prop-types";
import IconPreloader from "../../Icon/IconPreloader";

function FileKit({
  onChange,
  children,
  width,
  height,
  color,
  preloader,
  variant,
  disabled,
  ...props
}) {
  return (
    <Container width={width} height={height} disabled={disabled}>
      <Input
        type="file"
        onChange={onChange}
        disabled={disabled && "disabled"}
        {...props}
      />
      <Content variant={variant} bgColor={color}>
        <span>{children}</span>
        {preloader && <IconPreloader />}
      </Content>
    </Container>
  );
}

FileKit.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  preloader: PropTypes.bool,
  variant: PropTypes.oneOf(["default", "primary", "secondary"]),
  color: PropTypes.string
};

FileKit.defaultProps = {
  variant: "default"
};

export default FileKit;
