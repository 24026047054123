import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { MenuItemKitSD, MenuItemLinkKitSD, MenuKitSD } from "./styled";

function MenuKit(props) {
  const { children, activeItemIndex, items, propNameForText } = props;

  const hasRenderItemMethod = typeof children === "function";

  return (
    <MenuKitSD>
      {items.map((item, itemIndex) => {
        return hasRenderItemMethod ? (
          <Fragment key={itemIndex}>{children(item, itemIndex)}</Fragment>
        ) : (
          <MenuItemKitSD
            key={itemIndex}
            isActive={activeItemIndex === itemIndex}
            withItemHover
          >
            <MenuItemLinkKitSD>{item[propNameForText]}</MenuItemLinkKitSD>
            {/*{renderItemContent(item, itemIndex)}*/}
          </MenuItemKitSD>
        );
      })}
    </MenuKitSD>
  );
}

MenuKit.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  children: PropTypes.func,
  activeItemsIndexes: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number)
  ]),
  withItemHover: PropTypes.bool,
  /**
   * полезно иметь возможность передавать любой массив если нет реднер метода.
   */
  propNameForText: PropTypes.string
};

MenuKit.defaultProps = {
  withItemHover: true,
  propNameForText: "name"
};

export default MenuKit;
