import React from "react";
import PropTypes from "prop-types";
import { SVGIconSD } from "./styled";

function IconCalendar(props) {
  const { width } = props;

  return (
    <SVGIconSD viewBox="0 0 14 14" width={width}>
      <path
        style={{ fillRule: "evenodd" }}
        d="M1.3,5.1V2.5H2.6V3.8H3.9V2.5H6.4V3.8H7.7V2.5h2.5V3.8h1.3V2.5h1.3V5H1.3Zm0,7.6H12.8V6.4H1.3ZM11.5,1.3V0H10.2V1.3H7.6V0H6.4V1.3H3.8V0H2.5V1.3H0V14H14V1.3Z"
      />
    </SVGIconSD>
  );
}

IconCalendar.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default IconCalendar;
