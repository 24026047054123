import React from "react";
import PropTypes from "prop-types";
import { SVGIconSD } from "./styled";

function IconPlus(props) {
  return (
    <SVGIconSD viewBox="0 0 14 14" width={props.width} onClick={props.onClick}>
      <g transform="translate(-170.000000, -13.000000)">
        <g id="Button">
          <g id="Label" transform="translate(15.000000, 10.000000)">
            <g transform="translate(154.000000, 2.000000)">
              <path className="st0" d="M9,7h6v2H9v6H7V9H1V7h6V1h2V7z" />
            </g>
          </g>
        </g>
      </g>
    </SVGIconSD>
  );
}

IconPlus.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default IconPlus;
