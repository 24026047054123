import styled, { css } from "styled-components";
import { COLOR, FONT_FAMILY, FONT_WEIGHT } from "../../../../constants/theme";
import breakpoints from "../../../../helpers/breakpoints";
import iconPencil from "../../../../static/icons/icon-pencil.svg";
import arrow from "../../../../static/icons/chevron-arrow.svg";

export const Container = styled.div`
  background-color: #0a0e13;
  border-radius: 16px;
  pointer-events: none;
`;

const NON_EDITABLE = css`
  pointer-events: none;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  overflow: visible;

  :last-child {
    border-radius: 0 0 16px 16px;
  }
`;

export const Header = styled.div`
  font-weight: ${FONT_WEIGHT.BOLD};
  font-family: ${FONT_FAMILY.THIN};
  color: ${COLOR.TEXT};
  font-size: 18px;
  border-bottom: 1px solid ${COLOR.BG_SECONDARY};
  padding: 20px;
  padding-bottom: 10px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TimeSubtitle = styled.div`
  font-size: 18px;
  font-weight: ${FONT_WEIGHT.BOLD};
  /* top: -9px; */
  margin-left: 4px;

  cursor: pointer;
  ${({ editable }) =>
    editable &&
    css`
      cursor: url(${iconPencil}), pointer;
    `}

  /* ${({ editable }) => !editable && NON_EDITABLE}; */
  /* position: relative; */
  ${breakpoints.down("sm")} {
    font-size: 16px;
    /* top: -8px; */
  }
`;

export const Type = styled.div`
  display: flex;
  font-weight: ${FONT_WEIGHT.BOLD};
  font-family: ${FONT_FAMILY.THIN};
  color: ${COLOR.TEXT};
  opacity: 0.9;
  font-size: 14px;
  text-transform: uppercase;
  ${breakpoints.down("sm")} {
    font-size: 16px;
  }
  ${breakpoints.down("xs")} {
    display: none;
  }
`;

export const Users = styled.div`
  display: ${({ mobile }) => (mobile ? "none" : "flex")};
  align-items: center;
  flex: 5;
  justify-content: center;
  margin-right: 35px;
  ${breakpoints.down("sm")} {
    display: ${({ mobile }) => (mobile ? "flex" : "none")};
    flex-direction: column;
    margin-bottom: 5px;
  }
`;

export const User = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: all 0.25s ease 0s;
  flex: 1;
  :first-child {
    flex-direction: row-reverse;
    text-align: right;
  }
  ${breakpoints.down("sm")} {
    :first-child {
      flex-direction: row;
    }
    width: 80px;
  }
  opacity: ${({ outcome }) => (outcome === "L" ? 0.4 : 1)};

  ${({ editable }) =>
    editable &&
    css`
      cursor: url(${iconPencil}), pointer;
    `}
`;

export const Card = styled.div`
  display: flex;
  height: 75px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  cursor: ${({ to }) => (!!to ? "pointer" : "default")};
  transition: all 0.25s ease 0s;
  padding: 20px 20px 15px;
  border-bottom: 1px solid ${COLOR.BG_SECONDARY};
  text-decoration: none;
  color: ${COLOR.TEXT};
  overflow: visible;
  background-color: ${({ isActive }) =>
    isActive ? COLOR.BG_INPUT : COLOR.BG_PRIMARY};
  :hover {
    background-color: ${COLOR.BG_SECONDARY};
    padding: 20px 25px 15px;
  }
  :hover ${User} {
    opacity: 1;
  }
`;

export const WrapAvatar = styled.div`
  width: 40px;
  height: 40px;
  ${({ small }) =>
    small &&
    css`
      width: 25px;
      height: 25px;
    `}
  ${breakpoints.down("sm")} {
    width: 25px;
    height: 25px;
    margin: 4px 0;
    margin-right: 5px;
  }
`;

export const Avatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  ${({ small }) =>
    small &&
    css`
      width: 25px;
      height: 25px;
    `}
  ${breakpoints.down("sm")} {
    width: 25px;
    height: 25px;
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 10px;
`;

export const Username = styled.div`
  font-weight: ${FONT_WEIGHT.EXTRA_BOLD};
  font-family: ${FONT_FAMILY.THIN};
  color: ${COLOR.TEXT};
  font-size: 16px;
  ${breakpoints.down("sm")} {
    display: flex;
    font-size: 15px;
    font-weight: ${FONT_WEIGHT.BOLD};
    min-width: 110px;
  }
`;

export const Score = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 75px;
  font-weight: ${FONT_WEIGHT.BOLD};
  font-family: ${FONT_FAMILY.THIN};
  color: ${COLOR.TEXT};
  font-size: 18px;
`;

export const UserScore = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: ${FONT_WEIGHT.BOLD};
  font-family: ${FONT_FAMILY.THIN};
  color: ${COLOR.TEXT};
  font-size: 18px;
  margin-left: 20px;
  ${breakpoints.down("sm")} {
    font-size: 16px;
  }
`;

export const Update = styled.div`
  width: 350px;
  background-color: ${COLOR.BG_PRIMARY};
  color: ${COLOR.TEXT};
  padding: 25px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
`;

export const UpdateText = styled.div`
  font: ${FONT_WEIGHT.MEDIUM} 18px ${FONT_FAMILY.THIN};
  text-transform: capitalize;
  margin-bottom: 25px;
`;

export const Token = styled.div`
  font: ${FONT_WEIGHT.MEDIUM} 18px ${FONT_FAMILY.THIN};
`;

export const Arrow = styled.img.attrs({ src: arrow, alt: "" })`
  font: ${FONT_WEIGHT.MEDIUM} 18px ${FONT_FAMILY.THIN};
  cursor: url(${iconPencil}), pointer;
  transform: rotate(-90deg);
  width: 20px;
  height: 20px;
  position: relative;
  ${({ isLeft }) =>
    isLeft
      ? css`
          left: 5px;
        `
      : css`
          right: 5px;
        `}
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: ${COLOR.BG_SECONDARY};
  position: absolute;
  top: 105%;
  ${({ right }) =>
    !right
      ? css`
          right: 0px;
        `
      : css`
          left: 0px;
        `}
  z-index: 100;
  border-radius: 8px;
  max-height: 300px;
  overflow-y: auto;
  width: 200px;
  overflow-x: hidden;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
`;

export const Player = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 7px;
  /* z-index: 100; */
  cursor: pointer;
  position: relative;
  transition: all 0.2s ease 0s;

  ${({ right }) =>
    !right &&
    css`
      align-items: flex-end;
      flex-direction: row-reverse;
    `}

  &:hover {
    background-color: ${COLOR.BG_ACCENT};
    transition: all 0.2s ease 0s;
  }
`;
