import React, { Component } from "react";
import PropTypes from "prop-types";

import { ChipInputField, ChipInputSD } from "./styled";
import Chip from "../../presentation/Chip/Chip";
import { COLOR } from "../../../constants/theme";

class InputChips extends Component {
  static propTypes = {
    placeholder: PropTypes.string,
    onBlur: PropTypes.func,
    hasError: PropTypes.bool,
    readOnly: PropTypes.bool,
    hiddenInput: PropTypes.bool
  };

  inputRef = React.createRef();

  state = {
    inputValue: ""
  };

  isCharSeparator = char => char === "," || char === ";";

  handleChangeInputValue = e => {
    const value = e.target.value;

    if (this.isCharSeparator(value)) return;

    this.setState({ inputValue: e.target.value });
  };

  handleKeyDownInputValue = e => {
    const inputValue = this.state.inputValue;
    if (inputValue.trim() === "") return;

    if (e.key === "Enter" || this.isCharSeparator(e.key)) {
      this.props.onChange([...this.props.value, inputValue]);
      this.setState({ inputValue: "" });
      e.target.value = "";
    }
  };

  deleteItemFromValue = index => {
    const newValue = this.props.value.filter((_, i) => i !== index);

    this.props.onChange(newValue);
  };

  focusOnInput = () => {
    this.inputRef.current.focus();
  };

  renderItems = () => {
    return this.props.value.map((str, i) => {
      if (str !== undefined) {
        return (
          <Chip
            value={str}
            key={i}
            onDelete={() => this.deleteItemFromValue(i)}
          />
        );
      } else return null;
    });
  };

  renderField = () => {
    const { placeholder, value } = this.props;
    const { inputValue } = this.state;

    return (
      <ChipInputField
        ref={this.inputRef}
        small={value.length !== 0}
        value={inputValue}
        onChange={this.handleChangeInputValue}
        onKeyDown={this.handleKeyDownInputValue}
        placeholder={value.length === 0 ? placeholder : undefined}
        onBlur={this.props.onBlur}
        readOnly={this.props.readOnly}
        hiddenInput={this.props.hiddenInput}
      />
    );
  };

  render() {
    return (
      <ChipInputSD
        onClick={this.focusOnInput}
        bgColor={COLOR.BG_INPUT}
        hasError={this.props.hasError}
      >
        {this.renderItems()}
        {this.renderField()}
      </ChipInputSD>
    );
  }
}

export default InputChips;
