import React, { Component } from "react";
import {
  Container,
  Content,
  WalletTypeColumn,
  WalletTypeHeader,
  WalletTypeTable,
  WalletTypeItem,
  ItemText,
  WrapCoinsCount,
  WrapCoinsTotal,
  CoinsCount,
  CoinsIcon,
  CoinsText,
  CoinsTotal,
  ListSection,
  ListHeader,
  WrapSearchField,
  WrapList,
  List,
  EmptyList
} from "./styled";
import WalletListItem from "./WalletListItem/WalletListItem";
import withLocationSearch from "../../../hoc/withLocationSearch";
import * as queryString from "query-string";
import { numberToThousandsString, propValueOr } from "../../../helpers/common";
import FieldKit from "../../../components/kit/Fields/FormField/FormField";
import SelectKit from "../../../components/kit/Fields/Select/SelectKit";
import Pagination from "../../../components/presentation/Pagination/Pagination";
import { Redirect } from "react-router-dom";

// Icons
import CoinIcon from "../../../static/icons/rival_coin.svg";
import IconSearch from "../../../components/kit/Icon/IconSearch";
class Wallet extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchWord: "",
      activeStatus: null,
      activeWalletType: "master"
    };
  }

  walletTypes = [
    {
      type: "master",
      label: "MASTER WALLET"
    },
    {
      type: "organization",
      label: "ALL ORGANIZATIONS"
    },
    {
      type: "tournament",
      label: "ALL TOURNAMENTS"
    },
    {
      type: "match",
      label: "ALL MATCHES"
    },
    {
      type: "user",
      label: "ALL PLAYERS"
    }
  ];

  componentDidMount() {
    this.fetchWallets({ type: this.state.activeWalletType });
    const filter = {
      ...queryString.parse(this.props.location.search),
      type: this.state.walletType
    };
    this.setState({ ...filter });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      this.fetchWallets({
        type: this.state.activeWalletType,
        status: this.state.activeStatus,
        q: this.state.searchWord
      });
    }
  }

  fetchWallets = query => {
    const { page = 1 } = this.props.getLocationSearch();
    this.props.fetchWallets({ page: page, ...query });
  };

  handleTypeChange(type) {
    this.fetchWallets({ type });
    this.setPageToUrl(1);
    this.setState({
      activeWalletType: type,
      activeStatus: null,
      searchWord: ""
    });
  }

  handleSearch = searchWord => {
    this.setState({ searchWord });
    this.setPageToUrl(1);
    this.props.fetchWallets({
      page: 1,
      type: this.state.activeWalletType,
      status: this.state.activeStatus,
      q: searchWord
    });
  };

  handleStatusChange(activeStatus) {
    this.setState({ activeStatus, searchWord: "" });
    this.props.fetchWallets({
      page: 1,
      type: this.state.activeWalletType,
      status: activeStatus
    });
  }

  setPageToUrl = page => {
    const oldSearch = queryString.parse(this.props.location.search);

    const newSearch = {
      ...oldSearch,
      page
    };

    this.props.history.push({
      search: `?${queryString.stringify(newSearch)}`
    });
  };

  render() {
    const { permission, wallet, stats, wallets, total } = this.props;
    const { activeWalletType, searchWord, activeStatus } = this.state;

    if (propValueOr(this.props.user, "organizationId")) {
      return <Redirect to={"/wallet/transactions"} />;
    }

    const statSorting = stats;
    const typeButtons = this.walletTypes
      .map(wallet => {
        var n = statSorting.findIndex(s => s.wallet_type === wallet.type);
        return [n, { ...wallet, ...statSorting[n] }];
      })
      .sort((a, b) => a[0] >= 0 && a[0] - b[0])
      .map(j => j[1]);

    return (
      <Container>
        {(permission === "write" || permission === "admin") && (
          <Content>
            <WalletTypeColumn>
              <WalletTypeHeader>
                {wallet.walletTotal && (
                  <WrapCoinsTotal>
                    <CoinsTotal>
                      <CoinsText>TOTAL SUPPLY</CoinsText>
                      <WrapCoinsCount>
                        <CoinsCount>
                          {numberToThousandsString(wallet.walletTotal)}
                        </CoinsCount>
                        <CoinsIcon src={CoinIcon} />
                      </WrapCoinsCount>
                    </CoinsTotal>
                  </WrapCoinsTotal>
                )}
              </WalletTypeHeader>
              <WalletTypeTable>
                {typeButtons.map(stat => {
                  return (
                    <WalletTypeItem
                      key={stat.type}
                      active={activeWalletType === stat.type}
                      onClick={() => this.handleTypeChange(stat.type)}
                    >
                      <ItemText>{stat.label}</ItemText>
                      {!!stat.sum_total ? (
                        <WrapCoinsCount>
                          <CoinsCount>
                            {numberToThousandsString(stat.sum_total)}
                          </CoinsCount>
                          <CoinsIcon src={CoinIcon} />
                        </WrapCoinsCount>
                      ) : (
                        <CoinsCount>N/A</CoinsCount>
                      )}
                    </WalletTypeItem>
                  );
                })}
              </WalletTypeTable>
            </WalletTypeColumn>
            <ListSection>
              <ListHeader>
                <WrapSearchField>
                  <FieldKit
                    fullWidth
                    placeholder={"Enter keywords..."}
                    value={searchWord}
                    onChange={q => this.handleSearch(q)}
                    gutterBottom={false}
                    icon={<IconSearch />}
                    iconAlign={"left"}
                  />
                </WrapSearchField>
                <WrapSearchField>
                  <SelectKit
                    fullWidth
                    value={activeStatus}
                    options={[
                      { label: "STATUS: ALL", value: null },
                      { label: "STATUS: OPEN", value: "open" },
                      { label: "STATUS: PENDING", value: "pending" },
                      { label: "STATUS: CLOSED", value: "closed" }
                    ]}
                    placeholder={"STATUS: ALL"}
                    onChange={val => this.handleStatusChange(val)}
                  />
                </WrapSearchField>
              </ListHeader>
              <WrapList>
                {wallets.length > 0 ? (
                  <List>
                    {(wallets || []).map(wallet => {
                      const link =
                        wallet.walletType === "match"
                          ? `game/${propValueOr(wallet, "match.token", "")}`
                          : wallet.walletType === "tournament"
                          ? `tournaments/${propValueOr(
                              wallet,
                              "tournament.token",
                              ""
                            )}`
                          : wallet.walletType === "user"
                          ? `${wallet.walletType}s/${wallet.walletId}`
                          : "#";
                      return (
                        <WalletListItem
                          key={wallet.id}
                          link={link}
                          wallet={wallet}
                        />
                      );
                    })}
                  </List>
                ) : (
                  <EmptyList>NO WALLETS FOUND</EmptyList>
                )}
              </WrapList>
              <Pagination withUrl total={total} />
            </ListSection>
          </Content>
        )}
      </Container>
    );
  }
}

export default withLocationSearch(Wallet);
