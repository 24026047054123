import styled, { css } from "styled-components";
import { COLOR, FONT_FAMILY, FONT_WEIGHT } from "../../constants/theme";
import breakpoints from "../../helpers/breakpoints";
import { Link } from "react-router-dom";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;

  color: ${COLOR.TEXT};
  background-color: ${COLOR.BG_PRIMARY};

  padding: 0 20px 20px 20px;
`;

export const Content = styled.div`
  display: flex;

  width: 100%;
  max-width: 1200px;

  color: ${COLOR.TEXT};

  ${breakpoints.down("md")} {
    flex-direction: column;
  }
`;

export const CardTitle = styled.div`
  font: ${FONT_WEIGHT.NORMAL} 16px ${FONT_FAMILY.GOTHIC_BOLD};
`;

export const SeeAllLink = styled(Link)`
  color: ${COLOR.RIVAL_RED};
  font: ${FONT_WEIGHT.NORMAL} 14px ${FONT_FAMILY.GOTHIC};

  margin-left: auto;

  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

export const ArrowIcon = styled.img`
  width: 15px;
  margin-left: 10px;
`;

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  margin-bottom: 15px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: calc(100vh - 75px);

  padding: 0px 10px 20px 10px;
`;

export const ContentCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  height: 100%;

  background-color: ${COLOR.BG_SECONDARY};

  padding: 25px;
  border-radius: 16px;
`;

export const Title = styled.div`
  color: ${COLOR.WHITE};
  margin: 200px 0px 30px 0px;
`;

export const SearchBar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  padding: 15px 10px;
`;

export const FilterHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  width: 100%;
  background-color: ${COLOR.BG_SECONDARY};

  padding: 10px 0px;
`;

export const Tabs = styled.div`
  display: flex;
  justify-content: center;

  width: 100%;
  max-width: 400px;

  color: ${COLOR.TEXT};
  overflow: hidden;

  border: 2px solid ${COLOR.RIVAL_RED};
  border-radius: 8px;
`;

const ACTIVE_SWITCHER_ITEM_STYLES = css`
  background-color: ${COLOR.RIVAL_RED};
  color: ${COLOR.WHITE};
`;

const NOT_ACTIVE_SWITCHER_ITEM_STYLES = css`
  background-color: ${COLOR.BG_SECONDARY};
  color: ${COLOR.WHITE};
`;

export const TabItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  max-width: 200px;
  padding: 12px 10px;

  font: ${FONT_WEIGHT.EXTRA_BOLD} 16px ${FONT_FAMILY.GOTHIC_BOLD};
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;

  cursor: pointer;
  transition: all 0.3s;

  ${_IS_ACTIVE_SWITCHER_ITEM_};

  &:hover {
    ${ACTIVE_SWITCHER_ITEM_STYLES};
  }

  &:not(:first-child) {
    margin-left: -1px;
  }

  ${breakpoints.down("xs")} {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 4px;
    padding-right: 4px;
  }

  ${breakpoints.down("440")} {
    min-width: 0;
    font-size: 12px;
  }
`;

function _IS_ACTIVE_SWITCHER_ITEM_({ isActive }) {
  return isActive
    ? ACTIVE_SWITCHER_ITEM_STYLES
    : NOT_ACTIVE_SWITCHER_ITEM_STYLES;
}

export const EmptyTable = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
`;

export const EmptyTableIcon = styled.img`
  width: 100%;
  max-width ${({ maxWidth }) => (!!maxWidth ? maxWidth : "200px")};
  
  margin-bottom: 35px;
`;

export const EmptyTableText = styled.div`
  font: ${FONT_WEIGHT.NORMAL} 20px ${FONT_FAMILY.GOTHIC_BOLD};
  text-align: center;
`;

export const WrapChatList = styled.div`
  display: flex;

  width: 100%;

  overflow-y: auto;
`;

export const WelcomeCard = styled.div`
  display: flex;

  background-color: ${COLOR.BG_SECONDARY};
  padding: 35px 20px;
  border-radius: 16px;

  > :not(:last-child) {
    margin-right: 15px;
  }
`;

export const CardColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
`;

export const RCCGraphic = styled.img`
  width: 100%;
  max-width: 390px;
`;

export const WelcomeTitle = styled.div`
  font: ${FONT_WEIGHT.NORMAL} 32px ${FONT_FAMILY.GOTHIC_BOLD};
`;

export const WelcomeDivider = styled.div`
  width: 40px;
  height: 1px;
  border: 6px solid ${COLOR.RIVAL_RED};
  margin: 16px 0px;
  align-self: flex-start;
`;

export const Sections = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  > :not(:last-child) {
    margin-bottom: 12px;
  }
`;

export const WelcomeSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const SubtitleContent = styled.div`
  font: ${FONT_WEIGHT.NORMAL} 16px ${FONT_FAMILY.GOTHIC};
`;

export const WelcomeSectionTitle = styled.div`
  font: ${FONT_WEIGHT.NORMAL} 16px ${FONT_FAMILY.GOTHIC_BOLD};
`;

export const SectionContent = styled.div`
  font: ${FONT_WEIGHT.NORMAL} 14px ${FONT_FAMILY.GOTHIC};
`;
