import styled, { css } from "styled-components";
import PropTypes from "prop-types";

import { InputKitSD } from "../../kit/Fields/styled";
import { COLOR, FONT_FAMILY, FONT_WEIGHT } from "../../../constants/theme";

export const FieldTypeAndSelectSD = styled.div`
  position: relative;
  z-index: 100;
  ${({ fullWidth }) => fullWidth && `width: 100%;`}
`;

export const FieldTypeAndSelectInputKitSD = styled(InputKitSD)`
  &&& {
    color: ${COLOR.TEXT_4};

    &:focus {
      color: ${COLOR.GREY};
      border-color: ${COLOR.GREY};
      box-shadow: none;
    }

    ${({ isSelected }) =>
      isSelected &&
      css`
        color: ${COLOR.TEXT}!important;
      `};
  }
`;
FieldTypeAndSelectInputKitSD.propTypes = {
  isSelected: PropTypes.bool,
  hasError: PropTypes.bool
};

export const FieldTypeAndSelectPopupSD = styled.div`
  position: absolute;
  top: 100%;
  left: 0;

  width: 100%;
  max-height: 140px;
  overflow: auto;

  background-color: ${COLOR.BG_SECONDARY};
  border: 1px solid ${COLOR.BORDER};
  border-radius: 2px;
  z-index: 100;
`;

export const FieldTypeAndSelectPopupOptionSD = styled.li`
  cursor: pointer;
  list-style: none;
  padding: 0 12px;
  transition: all 0.3s;

  &:hover {
    background-color: ${COLOR.BG_ACCENT};
  }
`;

export const FieldTypeAndSelectPopupOptionInnerSD = styled.span`
  display: flex;
  align-items: center;
`;

export const FieldTypeAndSelectPopupOptionTextSD = styled.span`
  padding: 12px 0;
  color: ${COLOR.TEXT_3};
  font: ${FONT_WEIGHT.MEDIUM} 14px / 1.1 ${FONT_FAMILY.THIN};
  display: block;
  width: 100%;
  max-width: ${({ iconAfter }) => (iconAfter ? "calc(100% - 34px)" : "100%")};

  & > span {
    display: flex;
    justify-content: space-between;

    & > span {
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      &:nth-child(1) {
        width: calc(100% - 80px);
      }

      &:nth-child(2) {
        width: 80px;
        text-align: right;
      }
    }
  }
`;

export const FieldTypeAndSelectPopupOptionIconSD = styled.span`
  font-size: 24px;
  flex-shrink: 0;
  margin-left: auto;
  display: flex;
  padding-left: 10px;
  padding-right: 10px;

  &:last-child {
    padding-right: 0;
  }
`;

export const PlayerCount = styled.div`
  font-size: 14px;
  color: ${COLOR.TEXT};
  display: inline-block;
  white-space: nowrap;
`;

export const Token = styled.div`
  font-size: 14px;
  display: inline-block;
  color: ${COLOR.GREY};
  margin-left: 5px;
`;
