import styled, { css } from "styled-components";
import { COLOR, FONT_FAMILY, FONT_WEIGHT } from "../../../../constants/theme";
import iconDelete from "../../../../static/icons/remove.svg";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${COLOR.BG_SECONDARY};
  border-radius: 4px;
  margin-bottom: 25px;
  padding: 0 10px;
  width: 100%;
  z-index: 10;
  > :first-child {
    margin-bottom: 25px;
  }
`;

export const WrapMatches = styled.div`
  width: 100%;
`;

export const MatchSelector = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: calc(100vw - 320px);
  overflow: auto;
  padding: 7px 0 0;

  ::-webkit-scrollbar {
    height: 8px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: ${COLOR.BG_SECONDARY};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${COLOR.BG_PRIMARY};
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${COLOR.RIVAL_RED};
  }

  > :not(:last-child) {
    margin-right: 8px;
  }
`;

export const DeleteIcon = styled.img.attrs({ src: iconDelete })`
  cursor: pointer;
  position: absolute;
  top: -10px;
  right: -7px;
  transition: all 0.2s ease-in-out;
  ${({ hide }) =>
    hide &&
    css`
      display: none;
    `}
`;

export const WrapItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: center;
  margin-bottom: 10px;
  background-color: ${COLOR.BG_PRIMARY};
  padding: 10px 15px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s;
  text-decoration: none;
  color: ${COLOR.TEXT};
  min-width: 190px;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 55px;
  &:hover {
    opacity: 0.85;
    transition: 0.3s all;
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      background-color: ${COLOR.RIVAL_RED};
      > * {
        color: ${COLOR.TEXT};
      }
    `}
`;

export const Item = styled.div``;

export const ItemInfo = styled.div`
  font: ${FONT_WEIGHT.MEDIUM} 14px / 1.1 ${FONT_FAMILY.THIN};
  color: ${COLOR.GREY};

  ${({ isSelected }) => isSelected && `color: ${COLOR.TEXT}`}
`;

export const WrapMatch = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  /* pointer-events: none;
  opacity: 0.8; */
`;

export const Text = styled.div`
  font: ${FONT_WEIGHT.MEDIUM} 16px / 1.1 ${FONT_FAMILY.THIN};
  margin: 15px 0;
  color: ${COLOR.GREY};
`;

export const Error = styled.div`
  font: ${FONT_WEIGHT.MEDIUM} 16px / 1.1 ${FONT_FAMILY.THIN};
  margin: 15px 0;
  color: ${COLOR.CHERRY_RED};
  margin-top: -7px;
  margin-bottom: 25px;
`;
