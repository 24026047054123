export const WALLET_ACTIONS = {
  wallet: {
    request: "WALLET/FETCH_REQUEST",
    success: "WALLET/FETCH_SUCCESS",
    error: "WALLET/FETCH_ERROR"
  },
  stats: {
    request: "WALLET/STATS_REQUEST",
    success: "WALLET/STATS_SUCCESS",
    error: "WALLET/STATS_ERROR"
  },
  wallets: {
    request: "WALLET/LIST_REQUEST",
    success: "WALLET/LIST_SUCCESS",
    error: "WALLET/LIST_ERROR"
  },
  transaction: {
    request: "WALLET/TRANSACTION_REQUEST",
    success: "WALLET/TRANSACTION_SUCCESS",
    error: "WALLET/TRANSACTION_ERROR"
  },
  transactions: {
    request: "WALLET/TRANSACTIONS_REQUEST",
    success: "WALLET/TRANSACTIONS_SUCCESS",
    error: "WALLET/TRANSACTIONS_ERROR"
  },
  resetState: "WALLET/RESET_STATE"
};
