import { USER_ACTIONS } from "./constants";

const initialState = {
  verified: false,
  logged: false,
  info: null,
  requestStatus: null
};

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case USER_ACTIONS.login.setAuthorizedUserInfo:
      return {
        ...state,
        logged: true,
        verified: true,
        info: action.payload.info
      };
    case USER_ACTIONS.login.setUserAsNotAuthorized:
      return {
        ...initialState,
        logged: false,
        verified: true,
        info: {}
      };
    case USER_ACTIONS.update.updateAuthorizedUserInfo:
      return {
        ...state,
        logged: true,
        verified: true,
        info: action.payload.info
      };
    case USER_ACTIONS.request.status:
      return {
        ...state,
        requestStatus: action.payload.status
      };
    default:
      return state;
  }
};

export default reducers;
