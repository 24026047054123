import _ from "lodash";
import React, { Component } from "react";
import FormFieldKit from "../../../../components/kit/Fields/FormField/FormField";
import { Container } from "./styled";
import { TOURNAMENT_EVENTS } from "./constants";
import { safePropValueOr } from "../../../../helpers/common";

class Communications extends Component {
  componentDidMount() {
    this._assignDefaults();
  }
  componentDidUpdate(oldProps) {
    if (
      _.isEqual(oldProps.formState.templates, this.props.formState.templates)
    ) {
      return;
    }

    this._assignDefaults();
  }

  _assignDefaults() {
    for (const te of TOURNAMENT_EVENTS) {
      if (!te.defaultValue) {
        continue;
      }

      const template = this.props.formState.templates.find(
        t => t.label === te.defaultValue
      );
      if (!template) {
        continue;
      }

      this.changeEventTemplate(template.id, te.name);
    }
  }

  changeEventTemplate = (value, name) => {
    let events = [...this.props.formState.events];

    let index = events.findIndex(e => e.name === name);

    if (index === -1 && value !== undefined) {
      events.push({
        name,
        templateId: value
      });
    }

    if (index !== -1) {
      events[index] = {
        ...events[index],
        templateId: value
      };
    }

    this.props.onChangeField("events", events);
  };

  render() {
    const { formState } = this.props;
    const templatesNotEmpty =
      formState.templates && formState.templates.length > 0;
    return (
      <Container>
        {TOURNAMENT_EVENTS.map((item, i) => {
          let template = null;
          const index = formState.events.findIndex(ev => ev.name === item.name);

          if (index !== -1) {
            template = formState.templates.find(t => {
              const id = safePropValueOr(
                formState.events[index],
                "templateId",
                null
              );
              return t.id === id;
            });
          }

          return (
            <FormFieldKit
              value={safePropValueOr(template, "label", null)}
              key={i}
              label={item.title}
              description={item.description}
              fullWidth
              multiline
              select
              disabled={!templatesNotEmpty}
              selectOptions={[
                { label: "None", value: null },
                ...formState.templates
              ]}
              onChange={val => this.changeEventTemplate(val, item.name)}
              placeholder={
                templatesNotEmpty
                  ? "Select template..."
                  : "Templates not found..."
              }
            />
          );
        })}
      </Container>
    );
  }
}

export default Communications;
