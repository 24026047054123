export const NOTIFICATIONS_ACTIONS = {
  fetch: {
    success: "NOTIFICATIONS/FETCH_SUCCESS",
    error: "NOTIFICATIONS/FETCH_ERROR"
  },
  refresh: {
    request: "NOTIFICATIONS/REFRESH_REQUEST",
    success: "NOTIFICATIONS/REFRESH_SUCCESS",
    error: "NOTIFICATIONS/REFRESH_ERROR"
  },
  read: {
    success: "NOTIFICATIONS/READ_SUCCESS",
    error: "NOTIFICATIONS/READ_ERROR"
  },
  readAll: {
    success: "NOTIFICATIONS/READ_ALL_SUCCESS",
    error: "NOTIFICATIONS/READ_ALL_ERROR"
  }
};
