import styled from "styled-components";
import { COLOR, FONT_WEIGHT, FONT_FAMILY } from "../../../constants/theme";
import breakpoints from "../../../helpers/breakpoints";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  padding: 25px;
  border-radius: 16px;

  width: 657px;

  background-color: ${COLOR.BG_SECONDARY};
  color: ${COLOR.TEXT};

  ${breakpoints.down("sm")} {
    width: 650px;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
  padding-bottom: 10px;
  border-bottom: 1px solid ${COLOR.BORDER};
`;

export const Title = styled.div`
  font: ${FONT_WEIGHT.NORMAL} 20px ${FONT_FAMILY.GOTHIC_BOLD};
`;

export const Subtitle = styled.div`
  font: 500 14px ${FONT_FAMILY.GOTHIC};
  margin-top: 10px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  padding-top: 10px;

  height: 100%;
  max-height: 700px;

  overflow-y: auto;
`;

export const List = styled.div`
  display: flex;
  flex-wrap: wrap;

  padding: 15px;
`;

export const ListContainer = styled.div`
  width: 100%;
  margin-bottom: 10px;

  background-color: ${COLOR.BG_ACCENT};
  border-radius: 8px;

  overflow-y: auto;
`;

export const GameCard = styled.img`
  width: 94px;
  height: 141px;

  border-radius: 8px;
  overflow: hidden;

  ${({ added }) =>
    added &&
    `opacity: 0.4;
    pointer-events: none;
    cursor: default;`}
`;

export const GameHover = styled.div`
  width: 94px;
  height: 141px;
  position: absolute;
  z-index: 3;
  border-radius: 8px;
  overflow: hidden;
`;

export const WrapGameCard = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  border: ${props =>
    props.selected ? `4px solid ${COLOR.RIVAL_RED}` : "3px solid transparent"};
  border-radius: 10px;

  ${props => (props.addGame ? `background-color: ${COLOR.RIVAL_RED};` : null)}

  cursor: pointer;
  &:hover {
    > ${GameHover} {
      background-color: rgba(0, 0, 0, 0.4);
    }
  }
`;

export const WrapCheckmark = styled.div`
  position: absolute;
  z-index: 4;
  display: ${props => (props.show ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  z-index: 1000;

  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;

  width: 31px;
  height: 31px;

  border-radius: 50%;
  background-color: ${COLOR.RIVAL_RED};
`;

export const CheckmarkImage = styled.img`
  width: 25px;
  margin-top: 5px;
`;

export const WrapButtons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 100%;
  padding: 15px 0;

  > :not(:last-child) {
    margin-bottom: 20px;
  }
`;
