import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import imageCompression from "browser-image-compression";
import {
  Container,
  Column,
  Header,
  Delete,
  Download,
  Icon,
  IconWrap,
  WrapImageInput,
  ImageInputButton,
  ImageIcon,
  ImageInput,
  PreviewWrap,
  Preview,
  SelectButton,
  SelectText,
  WrapField,
  ImageTitle,
  ConstraintList,
  ConstraintItem,
  ConstraintIcon,
  ImageError,
  UpdatedDate,
  Swatch,
  InsideColor,
  WrapButtons,
  WrapButton
} from "./styled";
import { api } from "../../../index";
import FormFieldKit from "../../../components/kit/Fields/FormField/FormField";
import ButtonKit from "../../../components/kit/Button/ButtonKit";
import { snakeToText } from "../../../helpers/common";
import DeleteDialog from "./DeleteDialog/DeleteDialog";
import DeleteImageDialog from "./DeleteImageDialog/DeleteImageDialog";
import { connect } from "react-redux";
import { ChromePicker } from "react-color";
import moment from "moment";
import { getGlobalUser } from "../../../store/user/selectors";
import { fetchAssetsAction } from "../../../store/assets/actions";
import Loader from "../../../components/presentation/Loader/Loader";

// Icons
import DefaultImageIcon from "../../../static/icons/default-image.svg";
import DeleteIcon from "../../../static/icons/remove.svg";
import DownloadArrow from "../../../static/icons/download-arrow.svg";
import Checkmark from "../../../static/icons/icon-check-green-type.svg";
import RedX from "../../../static/icons/circle-x-red.svg";

class Asset extends Component {
  constructor(props) {
    super(props);

    this.inputRef = React.createRef();

    this.initialState = {};
    this.state = {
      id: null,
      image: null,
      imageConstraints: this.createConstraintsObj(),
      imagePreview: null,
      processingImage: false,
      imageError: false,
      color: null,
      title: null,
      description: null,
      link: null,
      video: null,
      submitting: false,
      deleting: false,
      deletingImage: false,
      assetUpdated: false,
      assetUploaded: false,
      reset: false,
      displayColorPicker: false,
      order: null
    };
  }

  createConstraintsObj() {
    const makeLabel = (param, limits, units) => {
      if (!!limits && limits.length > 0) {
        if (limits.length === 2) {
          if (limits[0] === null)
            return `Required ${param} less than ${limits[1]}${units}`;
          else if (limits[1] === null)
            return `Required ${param} greater than ${limits[0]}${units}`;
          else
            return `Required ${param} between ${limits[0]}${units} and ${limits[1]}${units}`;
        } else if (limits.length === 1) {
          return `${param} of ${limits[0]}${units}`;
        }
      }
      return null;
    };

    const { widthLimits, heightLimits, aspectRatioLimits } = this.props;
    const width = !!widthLimits
      ? {
          limits: widthLimits,
          isValid: null,
          text: makeLabel("width", widthLimits, "px")
        }
      : null;
    const height = !!heightLimits
      ? {
          limits: heightLimits,
          isValid: null,
          text: makeLabel("height", heightLimits, "px")
        }
      : null;

    let aspectRatio = null;
    if (!!aspectRatioLimits) {
      const aspectRatioLabels = aspectRatioLimits.map(limit =>
        !!limit.label ? limit.label : `${limit.value}`
      );
      aspectRatio = {
        limits: aspectRatioLimits.map(limit => limit.value),
        isValid: null,
        text: makeLabel("width : height ratio", aspectRatioLabels, "")
      };
    }
    return {
      ...(width && { width }),
      ...(height && { height }),
      ...(aspectRatio && { aspectRatio })
    };
  }

  componentDidMount() {
    this.loadAsset();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.assets.length !== this.props.assets.length ||
      prevProps.name !== this.props.name
    ) {
      this.loadAsset();
    }
  }

  loadAsset = () => {
    const { assets, name, color } = this.props;
    if (assets.length > 0) {
      const asset = assets.find(a => a.name === name);
      if (asset) {
        this.setState({
          id: asset.id,
          image: asset.image,
          imagePreview: asset.image,
          title: asset.title,
          description: asset.description,
          link: asset.link,
          video: asset.video,
          updatedAt: asset.updatedAt,
          assetUpdated: false,
          order: asset.order
        });
        if (this.state.description !== color) {
          this.setState({
            reset: true
          });
        }
      }
    }
  };

  handleTitle = value => {
    this.setState({ title: value, assetUpdated: true });
  };

  handleDescription = value => {
    this.setState({ description: value, assetUpdated: true });
  };

  handleLink = value => {
    this.setState({ link: value, assetUpdated: true });
  };

  handleInputClick = event => {
    this.inputRef.current.click();
  };

  handleImage = async event => {
    if (event.target.files && event.target.files[0]) {
      const options = {
        maxSizeMB: 0.4,
        useWebWorker: true
      };
      this.setState({ processingImage: true, image: null });
      const originalFile = event.target.files[0];
      this.setState({ image: originalFile });
      let file;
      if (originalFile.type && originalFile.type.includes("gif")) {
        file = originalFile;
        const fileNameArr = file.name.split(".");
        if (fileNameArr.length > 1) {
          Object.defineProperty(file, "name", {
            writable: true,
            value: `${fileNameArr[0]}_${moment().format("Hmm-DMMYY")}${
              fileNameArr[1]
            }`
          });
        }
      } else {
        file = await imageCompression(event.target.files[0], options);
        const fileNameArr = file.name.split(".");
        if (fileNameArr.length > 1) {
          file.name = `${fileNameArr[0]}_${moment().format("Hmm-DMMYY")}${
            fileNameArr[1]
          }`;
        }
      }
      this.setState({ image: file });

      let reader = new FileReader();
      reader.onload = readerEvent => {
        const img = new Image();
        img.src = readerEvent.target.result;
        img.onload = e => {
          const error = this.validateImage(img.width, img.height);
          if (!!error) {
            this.setState({
              image: null,
              imagePreview: null,
              processingImage: false,
              imageError: true
            });
            return;
          }
          this.setState({
            imagePreview: img.src,
            processingImage: false,
            imageError: false,
            assetUpdated: true
          });
        };
      };

      reader.readAsDataURL(originalFile);
    }
  };

  handleVideo = value => {
    this.setState({ video: value, assetUpdated: true });
  };

  handleColor = value => {
    const name = this.props.name;
    const title = name.split(".")[1];
    this.setState({
      color: value,
      description: value.hex,
      assetUpdated: true,
      title: title
    });
  };

  handleSwatch = () => {
    this.setState({
      displayColorPicker: !this.state.displayColorPicker
    });
  };

  handleUpload = () => {
    this.setState({ submitting: true });
    let data = new FormData();
    if (this.state.title) {
      data.append("title", this.state.title);
    }
    if (this.state.description) {
      data.append("description", this.state.description);
    }
    if (this.state.link) {
      data.append("link", this.state.link);
    }
    if (this.state.image) {
      data.append("image", this.state.image);
    }
    if (this.state.video) {
      data.append("video", this.state.video);
    }
    if (this.props.organizationGroupId) {
      data.append("organizationGroupId", this.props.organizationGroupId);
    }
    if (!!data.entries().next().value) {
      data.append("name", this.props.name);
      api.assets.update(data).then(resp => {
        this.setState({ submitting: false });
        this.props
          .fetchAssetsAction({
            organizationId: this.props.user.info.organizationId
          })
          .then(() => {
            this.loadAsset();
            this.setState({
              assetUploaded: true,
              reset: true,
              displayColorPicker: false
            });
          });
      });
    } else {
      this.handleDelete();
    }
  };

  handleDelete = () => {
    if (this.state.id) {
      api.assets.delete(this.state.id).then(resp => {
        this.props
          .fetchAssetsAction({
            organizationId: this.props.user.info.organizationId,
            organizationGroupId: this.props.organizationGroupId
          })
          .then(() => {
            this.setState({
              id: null,
              title: "",
              image: null,
              imagePreview: null,
              imageConstraints: this.createConstraintsObj(),
              link: "",
              video: "",
              description: "",
              submitting: false,
              deleting: false,
              deletingImage: false,
              color: this.props.color,
              reset: false,
              displayColorPicker: false,
              order: null
            });
            window.location.reload();
          });
      });
    } else {
      this.setState({
        id: null,
        title: "",
        image: null,
        imagePreview: null,
        imageConstraints: this.createConstraintsObj(),
        link: "",
        video: "",
        description: "",
        submitting: false,
        deleting: false,
        deletingImage: false,
        color: this.props.color,
        reset: false,
        displayColorPicker: false,
        order: null
      });
    }
  };

  handleImageDelete = () => {
    if (this.state.id) {
      this.setState(
        {
          image: null,
          imagePreview: null,
          imageConstraints: this.createConstraintsObj(),
          deleting: false,
          deletingImage: false
        },
        () => this.handleUpload()
      );
    } else {
      this.setState({
        image: null,
        imagePreview: null,
        imageConstraints: this.createConstraintsObj(),
        submitting: false,
        deleting: false,
        deletingImage: false,
        reset: false
      });
    }
  };

  isValid = () => {
    if (!this.state.assetUpdated) return false;

    if (
      this.props.title &&
      !this.props.title.notRequired &&
      !this.state.title
    ) {
      return false;
    }
    if (
      (this.props.image &&
        !this.props.image.notRequired &&
        !this.state.image) ||
      this.state.imageError
    ) {
      return false;
    }
    if (
      this.props.video &&
      !this.props.video.notRequired &&
      !this.state.video
    ) {
      return false;
    }
    if (
      this.props.description &&
      !this.props.description.notRequired &&
      !this.state.description
    ) {
      return false;
    }
    if (this.props.link && !this.props.link.notRequired && !this.state.link) {
      return false;
    }
    return true;
  };

  validateImage = (width, height) => {
    const aspectRatio = width / height;
    let { imageConstraints } = this.state;

    // Width Range
    if (!!imageConstraints.width) {
      if (imageConstraints.width.limits.length === 2) {
        if (
          imageConstraints.width.limits[0] === null &&
          width > imageConstraints.width.limits[1]
        )
          imageConstraints.width.isValid = false;
        else if (
          imageConstraints.width.limits[1] === null &&
          width < imageConstraints.width.limits[0]
        )
          imageConstraints.width.isValid = false;
        else if (
          (!!imageConstraints.width.limits[0] &&
            width < imageConstraints.width.limits[0]) ||
          (!!imageConstraints.width.limits[1] &&
            width > imageConstraints.width.limits[1])
        )
          imageConstraints.width.isValid = false;
        else imageConstraints.width.isValid = true;
      } else if (
        imageConstraints.width.limits.length === 1 &&
        width !== imageConstraints.width.limits[0]
      ) {
        imageConstraints.width.isValid = false;
      } else {
        imageConstraints.width.isValid = true;
      }
    }

    // Height Range
    if (!!imageConstraints.height) {
      if (imageConstraints.height.limits.length === 2) {
        if (
          imageConstraints.height.limits[0] === null &&
          height > imageConstraints.height.limits[1]
        )
          imageConstraints.height.isValid = false;
        else if (
          imageConstraints.height.limits[1] === null &&
          height < imageConstraints.height.limits[0]
        )
          imageConstraints.height.isValid = false;
        else if (
          (!!imageConstraints.height.limits[0] &&
            height < imageConstraints.height.limits[0]) ||
          (!!imageConstraints.height.limits[1] &&
            height > imageConstraints.height.limits[1])
        )
          imageConstraints.height.isValid = false;
        else imageConstraints.height.isValid = true;
      } else if (
        imageConstraints.height.limits.length === 1 &&
        height !== imageConstraints.height.limits[0]
      ) {
        imageConstraints.height.isValid = false;
      } else {
        imageConstraints.height.isValid = true;
      }
    }

    // Aspect Ratio
    if (!!imageConstraints.aspectRatio) {
      if (imageConstraints.aspectRatio.limits.length === 2) {
        if (
          imageConstraints.aspectRatio.limits[0] === null &&
          aspectRatio > imageConstraints.aspectRatio.limits[1]
        )
          imageConstraints.aspectRatio.isValid = false;
        else if (
          imageConstraints.aspectRatio.limits[1] === null &&
          aspectRatio < imageConstraints.aspectRatio.limits[0]
        )
          imageConstraints.aspectRatio.isValid = false;
        else if (
          (!!imageConstraints.aspectRatio.limits[0] &&
            aspectRatio < imageConstraints.aspectRatio.limits[0]) ||
          (!!imageConstraints.aspectRatio.limits[1] &&
            aspectRatio > imageConstraints.aspectRatio.limits[1])
        )
          imageConstraints.aspectRatio.isValid = false;
        else imageConstraints.aspectRatio.isValid = true;
      } else if (
        imageConstraints.aspectRatio.limits.length === 1 &&
        aspectRatio !== imageConstraints.aspectRatio.limits[0]
      ) {
        imageConstraints.aspectRatio.isValid = false;
      } else {
        imageConstraints.aspectRatio.isValid = true;
      }
    }

    const imageError = Object.entries(imageConstraints).some(
      constraint => !constraint[1].isValid
    );
    this.setState({
      imageConstraints
    });
    return imageError;
  };

  renderImageSpecs(labels, constraints) {
    if (!!constraints.length || !!labels.length) {
      const labelBullets = labels.map((label, index) => {
        return (
          <ConstraintItem key={`label${index}`} isValid={null}>
            {label}
          </ConstraintItem>
        );
      });
      const constraintBullets = constraints.map(constraint => {
        const [key, obj] = constraint;
        return (
          <ConstraintItem key={key} isValid={obj.isValid}>
            {obj.text}
            {obj.isValid !== null && (
              <ConstraintIcon src={obj.isValid ? Checkmark : RedX} />
            )}
          </ConstraintItem>
        );
      });

      return [...labelBullets, ...constraintBullets];
    } else {
      return <ConstraintItem isValid={null}>None</ConstraintItem>;
    }
  }

  render() {
    const {
      imageConstraints,
      deleting,
      deletingImage,
      processingImage
    } = this.state;
    const {
      name,
      label,
      imgLabels,
      image,
      imageDimensions,
      title,
      description,
      link,
      video,
      centerItems,
      inputStyles,
      color,
      displayConstraints,
      titleLimit,
      descriptionLimit
    } = this.props;

    const twoColumnLayout = description && (image || video);
    let assetName = !!label ? label : snakeToText(name);
    if (!!this.state.order) {
      assetName =
        assetName
          .split(" ")
          .slice(0, -1)
          .join(" ") + ` ${this.state.order}`;
    }
    const constraints = Object.entries(imageConstraints);
    const uploadValid = this.isValid();

    return (
      <Container twoColumnLayout={twoColumnLayout}>
        <Column centerItems={centerItems} twoColumnLayout={twoColumnLayout}>
          <DeleteDialog
            isOpen={deleting}
            onClose={() => this.setState({ deleting: false })}
            onDelete={() => this.handleDelete()}
            name={name}
            color={color}
          />
          <DeleteImageDialog
            isOpen={deletingImage}
            onClose={() => this.setState({ deletingImage: false })}
            onDelete={() => this.handleImageDelete()}
            name={name}
          />
          {!color && <Header>{assetName}</Header>}
          {title && (
            <WrapField>
              <FormFieldKit
                fullWidth
                required={!title.notRequired}
                label={`${assetName} Title`}
                placeholder={"Enter title..."}
                value={this.state.title}
                onChange={this.handleTitle}
                inputStyles={inputStyles}
                gutterBottom={false}
                inputProps={titleLimit ? { maxLength: titleLimit } : undefined}
              />
            </WrapField>
          )}
          {description && (
            <WrapField>
              <FormFieldKit
                fullWidth
                required={!description.notRequired}
                label={`${assetName} Description`}
                placeholder={"Enter description..."}
                value={this.state.description}
                onChange={this.handleDescription}
                inputStyles={inputStyles}
                multiline
                gutterBottom={false}
                inputProps={
                  descriptionLimit ? { maxLength: descriptionLimit } : undefined
                }
              />
            </WrapField>
          )}
          {video && (
            <WrapField>
              <FormFieldKit
                fullWidth
                required={!video.notRequired}
                label={`${assetName} Video`}
                placeholder={"Enter video embed link..."}
                value={this.state.video}
                onChange={this.handleVideo}
                inputStyles={inputStyles}
                gutterBottom={false}
              />
            </WrapField>
          )}
        </Column>
        <Column
          row={color}
          twoColumnLayout={twoColumnLayout}
          centerItems={color}
        >
          {color && (
            <Fragment>
              <Header>{assetName} : </Header>
              <Swatch onClick={this.handleSwatch}>
                <InsideColor
                  color={
                    this.state.color
                      ? this.state.color.hex
                      : this.state.description
                      ? this.state.description
                      : color
                  }
                ></InsideColor>
              </Swatch>
              {this.state.displayColorPicker && (
                <ChromePicker
                  color={
                    this.state.color
                      ? this.state.color
                      : this.state.description
                      ? this.state.description
                      : color
                  }
                  onChange={this.handleColor}
                />
              )}
            </Fragment>
          )}
          {image && (
            <Fragment>
              {displayConstraints && (
                <Fragment>
                  <ImageTitle>Image Specs:</ImageTitle>
                  <ConstraintList>
                    {this.renderImageSpecs(imgLabels, constraints)}
                  </ConstraintList>
                </Fragment>
              )}
              {!this.state.image && !processingImage && (
                <WrapImageInput
                  width={imageDimensions[0]}
                  height={imageDimensions[1]}
                >
                  <ImageInputButton onClick={this.handleInputClick}>
                    <ImageIcon src={DefaultImageIcon} />
                    <SelectText>SELECT NEW IMAGE</SelectText>
                  </ImageInputButton>
                  <ImageInput
                    type="file"
                    ref={this.inputRef}
                    onChange={this.handleImage}
                  />
                </WrapImageInput>
              )}
              {(this.state.image || processingImage) && (
                <>
                  <PreviewWrap
                    width={imageDimensions[0]}
                    height={imageDimensions[1]}
                  >
                    {processingImage ? (
                      <Fragment>
                        <Loader isBlock />
                      </Fragment>
                    ) : (
                      <Fragment>
                        <Preview src={this.state.imagePreview} />
                        <SelectButton onClick={this.handleInputClick}>
                          <SelectText>SELECT NEW IMAGE</SelectText>
                        </SelectButton>
                        {!(this.state.image instanceof Blob) && (
                          <Download href={this.state.image} download>
                            <IconWrap>
                              <Icon src={DownloadArrow} />
                            </IconWrap>
                          </Download>
                        )}
                        <Delete>
                          <IconWrap
                            onClick={() =>
                              this.setState({ deletingImage: true })
                            }
                          >
                            <Icon src={DeleteIcon} />
                          </IconWrap>
                        </Delete>
                      </Fragment>
                    )}
                  </PreviewWrap>
                  <ImageInput
                    type="file"
                    ref={this.inputRef}
                    onChange={this.handleImage}
                  />
                </>
              )}
            </Fragment>
          )}
          {link && (
            <WrapField>
              <FormFieldKit
                fullWidth
                required={!link.notRequired}
                label={`${assetName} Link`}
                placeholder={"Enter link..."}
                value={this.state.link}
                onChange={this.handleLink}
                gutterBottom={false}
                inputStyles={inputStyles}
              />
            </WrapField>
          )}
          {this.state.imageError && (
            <ImageError>Error: Image must meet requirements</ImageError>
          )}
          <WrapButtons column={centerItems}>
            <WrapButton>
              <ButtonKit
                disabled={!uploadValid}
                style={{ margin: "10px", marginBottom: "0px", padding: "10px" }}
                appearance={uploadValid ? "primary" : "secondary"}
                shape={"rounded"}
                color={"green"}
                width={150}
                small
                onClick={this.handleUpload}
                preloader={this.state.submitting}
              >
                {color ? "UPDATE" : "UPLOAD"}
              </ButtonKit>
              {!!this.state.updatedAt && (
                <UpdatedDate
                  style={{ margin: "10px" }}
                  uploaded={this.state.assetUploaded}
                >
                  {`Last Updated: ${moment
                    .duration(moment(this.state.updatedAt).diff(moment()))
                    .humanize(true)}`}
                </UpdatedDate>
              )}
            </WrapButton>
            {this.state.reset && (
              <ButtonKit
                disabled={!this.state.reset}
                style={{ margin: "10px 10px 0 10px", padding: "10px" }}
                appearance={this.state.reset ? "primary" : "secondary"}
                shape={"rounded"}
                color={"red"}
                width={150}
                small
                onClick={() => this.setState({ deleting: true })}
              >
                {color ? "Reset Default Color" : "Delete Asset"}
              </ButtonKit>
            )}
          </WrapButtons>
        </Column>
      </Container>
    );
  }
}

Asset.propTypes = {
  // name of asset in database
  name: PropTypes.string.isRequired,
  // String that will be used as a header
  label: PropTypes.string,
  /* An array Determines dimensions of image upload button
  [ width, height ]
  */
  imageDimensions: PropTypes.array,
  // Determines whether title field appears
  title: PropTypes.bool,
  // Determines whether Description field appears
  description: PropTypes.bool,
  // will center items
  centerItems: PropTypes.bool,
  // Title field character length limit
  titleLimit: PropTypes.number
};

Asset.defaultProps = {
  centerItems: false,
  imageDimensions: [400, 220],
  displayConstraints: true,
  imgLabels: []
};

const mapStateToProps = state => ({
  user: getGlobalUser(state),
  assets: state.assetsState.assets
});

const mapDispatchToProps = {
  fetchAssetsAction
};

export default connect(mapStateToProps, mapDispatchToProps)(Asset);
