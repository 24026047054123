import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import RouteMatches from "./RouteMatches";
import { fetchMatches, resetMatches } from "../../store/matches/actions";
import Pusher from "pusher-js";
import * as queryString from "query-string";
import PermissionDenied from "../denied/PermissionDenied";

class RouteMatchesContainer extends Component {
  constructor(props) {
    super(props);

    this.pusher = new Pusher(process.env.REACT_APP_PUSHER_ID || "", {
      cluster: "us3",
      forceTLS: true
    });

    this.state = {
      searchValue: ""
    };
  }

  componentDidMount() {
    this.fetchMatches();

    const channel = this.pusher.subscribe(`matches`);

    channel.unbind();

    channel.bind("reload", () => {
      this.fetchMatches();
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      this.fetchMatches();
    }
  }

  componentWillUnmount() {
    this.pusher.unsubscribe(`matches`);
    this.props.resetMatches();
  }

  fetchMatches = () => {
    this.props.fetchMatches(this.getQueryParams());
  };

  getQueryParams = () => {
    const parsed = queryString.parse(this.props.location.search);

    return {
      status: parsed.status || "all",
      page: parsed.page || 1,
      q: parsed.q,
      userIds: parsed.userIds,
      organizationId: this.props.user.organizationId
    };
  };

  onChangeSearch = q => {
    this.setState({ searchValue: q });
  };

  onChangeFilter = filter => {
    const oldFilter = queryString.parse(this.props.location.search);

    const newFilter = {
      ...oldFilter,
      page: 1,
      ...filter
    };

    this.props.history.push({
      pathname: "/matches",
      search: `?${queryString.stringify(newFilter)}`
    });
  };

  render() {
    const { permission } = this.props;
    if (!permission) {
      return <PermissionDenied />;
    }
    return (
      <RouteMatches
        filter={this.getQueryParams()}
        onChangeFilter={this.onChangeFilter}
        onChangeSearch={this.onChangeSearch}
        searchValue={this.state.searchValue}
        {...this.props}
      />
    );
  }
}

const mapStateProps = state => ({
  user: state.user.info,
  matches: state.matchesState.matches,
  isFetching: state.matchesState.isFetching,
  total: state.matchesState.total
});

const mapDispatchToProps = {
  fetchMatches,
  resetMatches
};

export default connect(
  mapStateProps,
  mapDispatchToProps
)(withRouter(RouteMatchesContainer));
