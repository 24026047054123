import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Container,
  Content,
  List,
  Item,
  Header,
  WrapItem,
  Placeholder,
  Dot,
  Players,
  Player,
  WrapAvatar,
  Avatar,
  Name,
  Badge,
  Outcome,
  WrapFinalScore,
  FinalScoreBadge,
  FinalScoreText
} from "./styled";
import {
  isObjectEmpty,
  getUsername,
  propValueOr
} from "../../../helpers/common";
import { getStatus } from "./constants";
import placeholderUserImg from "../../../static/images/default-user.jpg";

class Sidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      games: []
    };
  }

  componentDidUpdate(prevProps) {
    const { tournament, isFetching } = this.props;

    if (tournament.id !== prevProps.tournament.id) {
      this.getGames(tournament);
    }

    if (isFetching !== prevProps.isFetching) {
      this.getGames(tournament);
    }
  }

  getGames(tournament) {
    const games = [];
    if (!isObjectEmpty(tournament)) {
      tournament.stages.forEach(stage => {
        stage.brackets.forEach(bracket => {
          bracket.matches.forEach(match => {
            games.push(match);
          });
        });
      });
      this.setState({
        games: games.sort((a, b) => this.sortGames(a, b))
      });
    }
  }

  sortGames(a, b) {
    const sortOrder = [
      "complete",
      "initialized",
      "pending",
      "challenged",
      "in progress",
      "playing"
    ];
    return (
      sortOrder.findIndex(status => status === b.status) -
      sortOrder.findIndex(status => status === a.status)
    );
  }

  render() {
    const { games } = this.state;
    const { tournament } = this.props;
    const isTeamTournament = !!propValueOr(this.props.tournament, "teamSize");

    if (isObjectEmpty(tournament) || games.length < 2) {
      return <></>;
    }

    return (
      <Container>
        <Content>
          <List>
            {games.length > 0 ? (
              games.map(game => {
                const users = propValueOr(game, "users", []).sort(
                  (a, b) => b.isHome - a.isHome
                );
                return (
                  <WrapItem to={`/game/${game.token}`} replace key={game.token}>
                    <Item
                      status={game.status}
                      active={game.token === this.props.game.token}
                    >
                      <Header>
                        <Badge>Round {game.round}</Badge>
                        <Badge status={game.status}>{game.status}</Badge>
                      </Header>
                      <Players>
                        <Player left key={users[0].id}>
                          {game.status === "initialized" && (
                            <Dot status={getStatus(users[0], game)} />
                          )}
                          <Name>
                            {isTeamTournament
                              ? users[0].team.name
                              : getUsername(users[0])}
                          </Name>

                          <WrapAvatar>
                            <Avatar
                              src={propValueOr(
                                users[0],
                                "imageInfo.thumbnail",
                                placeholderUserImg
                              )}
                              onError={e => (e.target.src = placeholderUserImg)}
                            />
                            {users[0].outcome && users.length > 1 && (
                              <Outcome
                                left
                                outcome={propValueOr(users[0], "outcome", "")}
                              >
                                {propValueOr(users[0], "outcome", "")}
                              </Outcome>
                            )}
                          </WrapAvatar>
                        </Player>
                        {game.status === "complete" && (
                          <WrapFinalScore>
                            <FinalScoreBadge singleScore={users.length === 1}>
                              {users.length > 1 ? (
                                <>
                                  <FinalScoreText>
                                    {propValueOr(users[0], "score", 0)}
                                  </FinalScoreText>
                                  <FinalScoreText>-</FinalScoreText>
                                  <FinalScoreText>
                                    {propValueOr(users[1], "score", 0)}
                                  </FinalScoreText>
                                </>
                              ) : (
                                propValueOr(users[0], "score", "0")
                              )}
                            </FinalScoreBadge>
                          </WrapFinalScore>
                        )}
                        {users[1] && (
                          <Player key={users[1].id}>
                            <WrapAvatar>
                              <Avatar
                                src={propValueOr(
                                  users[1],
                                  "imageInfo.thumbnail",
                                  placeholderUserImg
                                )}
                                onError={e =>
                                  (e.target.src = placeholderUserImg)
                                }
                              />
                              {users[1].outcome && (
                                <Outcome
                                  outcome={propValueOr(users[1], "outcome", "")}
                                >
                                  {propValueOr(users[1], "outcome", "")}
                                </Outcome>
                              )}
                            </WrapAvatar>

                            <Name>
                              {isTeamTournament
                                ? users[1].team.name
                                : getUsername(users[1])}
                            </Name>
                            {game.status === "initialized" && (
                              <Dot status={getStatus(users[1], game)} />
                            )}
                          </Player>
                        )}
                      </Players>
                    </Item>
                  </WrapItem>
                );
              })
            ) : (
              <Placeholder>No Games Found</Placeholder>
            )}
          </List>
        </Content>
      </Container>
    );
  }
}

const mapStateProps = state => ({
  game: state.gameState.game,
  tournament: state.tournamentState.tournament,
  isFetching: state.tournamentState.isFetching
});

export default connect(mapStateProps)(withRouter(Sidebar));
