const ROUND_NAMES = [
  "FINALS",
  "SEMIFINALS",
  "QUARTERFINALS",
  "ROUND OF 16",
  "ROUND OF 32",
  "ROUND OF 64",
  "ROUND OF 128",
  "ROUND OF 256",
  "ROUND OF 512"
];

export const getRoundTitle = (
  round,
  eliminationType,
  maxPlayers,
  numRounds = 9
) => {
  // if autosize...
  if (eliminationType === "single elimination" && !maxPlayers) {
    return ROUND_NAMES?.[round - 1] || `ROUND ${numRounds - round + 1}`;
  }
  return `ROUND ${round}`;
};
