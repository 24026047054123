import React from "react";
import PropTypes from "prop-types";
import { TypographyKitSD } from "./styled";
import { ELEMENT_BY_VARIANT } from "./constants";

function TypographyKit(props) {
  const { children, component, ...restProps } = props;

  const Component =
    component || ELEMENT_BY_VARIANT[props.variant] || ELEMENT_BY_VARIANT.p1;

  return (
    <TypographyKitSD as={Component} {...restProps}>
      {children}
    </TypographyKitSD>
  );
}

TypographyKit.propTypes = {
  children: PropTypes.node,
  /**
   * The component used for the root node.
   * Either a string to use a DOM element or a component.
   * By default, it is span.
   */
  component: PropTypes.oneOfType([
    PropTypes.oneOf([
      "span",
      "strong",
      "h1",
      "h2",
      "h3",
      "h4",
      "h5",
      "h6",
      "p",
      "div"
    ]),
    PropTypes.element
  ]),
  /**
   * Applies the typography styles.
   */
  variant: PropTypes.oneOf([
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "p1",
    "p2",
    "p3",
    "caption1",
    "caption2",
    "caption3",
    "label"
  ]),
  textAlign: PropTypes.oneOf(["left", "center", "right"]),
  /**
   * Number (for pixels) or string this units
   * */
  letterSpacing: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /*
   * 	Bool or number (in pixels) or string value with units. If just true will set '0.35em' by default/
   * */
  gutterBottom: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
    PropTypes.string
  ]),
  color: PropTypes.string,
  itemMarginLeft: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  uppercase: PropTypes.bool
};
TypographyKit.defaultProps = {
  testAlign: "left",
  variant: "p1",
  gutterBottom: true
};

export default TypographyKit;
