import { COMMUNICATION_ACTIONS } from "./constants";
import { api } from "../../index";

export const addEmail = item => {
  return {
    type: COMMUNICATION_ACTIONS.email.add,
    payload: item
  };
};

export const addGlobalTemplateEmail = item => {
  return {
    type: COMMUNICATION_ACTIONS.email.add,
    payload: item
  };
};

export const deleteEmail = () => {
  return {
    type: COMMUNICATION_ACTIONS.email.delete
  };
};

const scheduledEmailsRequested = () => {
  return {
    type: COMMUNICATION_ACTIONS.scheduled.request
  };
};

const scheduledEmailsFailed = error => {
  return {
    type: COMMUNICATION_ACTIONS.scheduled.error,
    payload: error
  };
};

const scheduledEmailsLoaded = items => {
  return {
    type: COMMUNICATION_ACTIONS.scheduled.success,
    payload: items
  };
};

export const fetchScheduledEmails = () => dispatch => {
  dispatch(scheduledEmailsRequested());
  return api
    .getScheduledEmails()
    .then(resp => {
      dispatch(scheduledEmailsLoaded(resp.data.docs));
    })
    .catch(err => {
      dispatch(scheduledEmailsFailed(err));
    });
};

export const setScheduledEmails = items => dispatch => {
  dispatch(scheduledEmailsLoaded(items));
};

const scheduledEmailCreated = item => {
  return {
    type: COMMUNICATION_ACTIONS.scheduled.create,
    payload: item
  };
};

export const createScheduledEmail = item => dispatch => {
  return api
    .createScheduledEmail(item)
    .then(resp => {
      dispatch(scheduledEmailCreated(resp));
      return resp.data;
    })
    .catch(err => {
      dispatch(scheduledEmailsFailed(err));
      return err;
    });
};

const scheduledEmailUpdated = item => {
  return {
    type: COMMUNICATION_ACTIONS.scheduled.update,
    payload: item
  };
};

export const updateScheduledEmail = item => dispatch => {
  return api
    .updateScheduledEmail(item)
    .then(resp => {
      dispatch(scheduledEmailUpdated(resp.data));
    })
    .catch(err => {
      dispatch(scheduledEmailsFailed(err));
    });
};

const scheduledEmailDeleted = item => {
  return {
    type: COMMUNICATION_ACTIONS.scheduled.delete,
    payload: item
  };
};

export const deleteScheduledEmail = id => dispatch => {
  return api
    .deleteScheduledEmail(id)
    .then(() => {
      dispatch(scheduledEmailDeleted(id));
    })
    .catch(err => {
      dispatch(scheduledEmailsFailed(err));
    });
};

const draftsEmailsRequested = () => {
  return {
    type: COMMUNICATION_ACTIONS.drafts.request
  };
};

const draftsEmailsFailed = error => {
  return {
    type: COMMUNICATION_ACTIONS.drafts.error,
    payload: error
  };
};

const draftsEmailsLoaded = items => {
  return {
    type: COMMUNICATION_ACTIONS.drafts.success,
    payload: items
  };
};

export const fetchDraftsEmails = () => dispatch => {
  dispatch(draftsEmailsRequested());
  return api
    .getDraftsEmails()
    .then(resp => {
      dispatch(draftsEmailsLoaded(resp.data.docs));
    })
    .catch(err => {
      dispatch(draftsEmailsFailed(err));
    });
};

export const setDraftsEmails = items => dispatch => {
  dispatch(draftsEmailsLoaded(items));
};

const draftEmailCreated = item => {
  return {
    type: COMMUNICATION_ACTIONS.drafts.create,
    payload: item
  };
};

export const createDraftsEmail = item => dispatch => {
  return api
    .createDraftsEmail(item)
    .then(resp => {
      dispatch(draftEmailCreated(resp));
      return resp.data;
    })
    .catch(err => {
      dispatch(scheduledEmailsFailed(err));
      return err;
    });
};

const draftsEmailUpdated = item => {
  return {
    type: COMMUNICATION_ACTIONS.drafts.update,
    payload: item
  };
};

export const updateDraftsEmail = item => dispatch => {
  return api
    .updateDraftsEmail(item)
    .then(resp => {
      dispatch(draftsEmailUpdated(resp.data));
    })
    .catch(err => {
      dispatch(draftsEmailsFailed(err));
    });
};

const draftsEmailDeleted = item => {
  return {
    type: COMMUNICATION_ACTIONS.drafts.delete,
    payload: item
  };
};

export const deleteDraftsEmail = id => dispatch => {
  return api
    .deleteDraftsEmail(id)
    .then(() => {
      dispatch(draftsEmailDeleted(id));
    })
    .catch(err => {
      dispatch(draftsEmailsFailed(err));
    });
};

const sentEmailsRequested = () => {
  return {
    type: COMMUNICATION_ACTIONS.sent.request
  };
};

const sentEmailsFailed = error => {
  return {
    type: COMMUNICATION_ACTIONS.sent.error,
    payload: error
  };
};

const sentEmailsLoaded = items => {
  return {
    type: COMMUNICATION_ACTIONS.sent.success,
    payload: items
  };
};

export const fetchSentEmails = () => dispatch => {
  dispatch(sentEmailsRequested());
  return api
    .getSentEmails()
    .then(resp => {
      dispatch(sentEmailsLoaded(resp.data.docs));
    })
    .catch(err => {
      dispatch(sentEmailsFailed(err));
    });
};

export const setSentEmails = items => dispatch => {
  dispatch(sentEmailsLoaded(items));
};

const templatesEmailsRequested = () => {
  return {
    type: COMMUNICATION_ACTIONS.templates.request
  };
};

const globalTemplatesEmailsRequested = () => {
  return {
    type: COMMUNICATION_ACTIONS.global_templates.request
  };
};

const templatesEmailsFailed = error => {
  return {
    type: COMMUNICATION_ACTIONS.templates.error,
    payload: error
  };
};

const globalTemplatesEmailsFailed = error => {
  return {
    type: COMMUNICATION_ACTIONS.global_templates.error,
    payload: error
  };
};

const templatesEmailsLoaded = items => {
  return {
    type: COMMUNICATION_ACTIONS.templates.success,
    payload: items
  };
};

const globalTemplatesEmailsLoaded = items => {
  return {
    type: COMMUNICATION_ACTIONS.global_templates.success,
    payload: items
  };
};

export const fetchTemplatesEmails = () => dispatch => {
  dispatch(templatesEmailsRequested());
  return api
    .getTemplatesEmails()
    .then(resp => {
      dispatch(templatesEmailsLoaded(resp.data.docs));
    })
    .catch(err => {
      dispatch(templatesEmailsFailed(err));
    });
};

export const fetchGlobalTemplatesEmails = () => dispatch => {
  dispatch(globalTemplatesEmailsRequested());
  return api
    .getGlobalTemplatesEmails()
    .then(resp => {
      dispatch(globalTemplatesEmailsLoaded(resp.data.docs));
    })
    .catch(err => {
      dispatch(globalTemplatesEmailsFailed(err));
    });
};

export const setTemplatesEmails = items => dispatch => {
  dispatch(templatesEmailsLoaded(items));
};

const templatesEmailCreated = item => {
  return {
    type: COMMUNICATION_ACTIONS.templates.create,
    payload: item
  };
};

const globalTemplatesEmailCreated = item => {
  return {
    type: COMMUNICATION_ACTIONS.global_templates.create,
    payload: item
  };
};

export const createTemplatesEmail = item => dispatch => {
  return api
    .createTemplatesEmail(item)
    .then(resp => {
      dispatch(templatesEmailCreated(resp));
      return resp.data;
    })
    .catch(err => {
      dispatch(templatesEmailsFailed(err));
      return err;
    });
};

export const createGlobalTemplatesEmail = item => dispatch => {
  return api
    .createGlobalTemplatesEmail(item)
    .then(resp => {
      dispatch(globalTemplatesEmailCreated(resp));
      return resp.data;
    })
    .catch(err => {
      dispatch(globalTemplatesEmailsFailed(err));
      return err;
    });
};

const templatesEmailUpdated = item => {
  return {
    type: COMMUNICATION_ACTIONS.templates.update,
    payload: item
  };
};

const globalTemplatesEmailUpdated = item => {
  return {
    type: COMMUNICATION_ACTIONS.global_templates.update,
    payload: item
  };
};

export const updateTemplatesEmail = item => dispatch => {
  return api
    .updateTemplatesEmail(item)
    .then(resp => {
      dispatch(templatesEmailUpdated(resp.data));
    })
    .catch(err => {
      dispatch(templatesEmailsFailed(err));
    });
};

export const updateGlobalTemplatesEmail = item => dispatch => {
  return api
    .updateGlobalTemplatesEmail(item)
    .then(resp => {
      dispatch(globalTemplatesEmailUpdated(resp.data));
    })
    .catch(err => {
      dispatch(globalTemplatesEmailsFailed(err));
    });
};

const templatesEmailDeleted = id => {
  return {
    type: COMMUNICATION_ACTIONS.templates.delete,
    payload: id
  };
};

const globalTemplatesEmailDeleted = id => {
  return {
    type: COMMUNICATION_ACTIONS.global_templates.delete,
    payload: id
  };
};

export const deleteTemplatesEmail = id => dispatch => {
  return api
    .deleteTemplatesEmail(id)
    .then(() => {
      dispatch(templatesEmailDeleted(id));
    })
    .catch(err => {
      dispatch(templatesEmailsFailed(err));
    });
};

export const deleteGlobalTemplatesEmail = id => dispatch => {
  return api
    .deleteGlobalTemplatesEmail(id)
    .then(() => {
      dispatch(globalTemplatesEmailDeleted(id));
    })
    .catch(err => {
      dispatch(globalTemplatesEmailsFailed(err));
    });
};
