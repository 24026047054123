import React, { useState } from "react";
import styled, { css } from "styled-components";
import { api } from "../../..";
import ButtonKit from "../../../components/kit/Button/ButtonKit";
import DialogKit from "../../../components/kit/Dialog/DialogKit";
import FormFieldKit from "../../../components/kit/Fields/FormField/FormField";
import Search from "../../../components/Search";
import InputChips from "../../../components/smart/ChipInput/InputChips";
import FieldTypeAndSelect from "../../../components/smart/FieldTypeAndSelect/FieldTypeAndSelect";
import { COLOR, FONT_FAMILY, FONT_WEIGHT } from "../../../constants/theme";
import { getUsername } from "../../../helpers/common";
import { IconHeadset, IconPeople } from "../../../static/icons";
import placeholderUserImg from "../../../static/images/default-user.jpg";
import AddTeamDialog from "./Dialogs/AddTeamDialog";

export default function Players({ season = {}, fetchSeason }) {
  const [searchList, setSearchList] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [addUsers, setAddUsers] = useState([]);
  const [removeUser, setRemoveUser] = useState(null);
  const [isOpen, setOpen] = useState(null);
  const [search, setSearch] = useState(null);

  const isTeam = season?.teamSize > 1;
  const username = season?.matchTemplate?.username;
  const searchString = (search || "").toLowerCase();
  const players = isTeam
    ? season?.users?.filter(
        u =>
          !!u?.isPrimary &&
          ((u?.team?.name || "")?.toLowerCase().includes(searchString) ||
            searchString.includes((u?.team?.name || "").toLowerCase()))
      )
    : season?.users?.filter(
        u =>
          getUsername(u?.user, username)
            ?.toLowerCase()
            .includes(searchString) ||
          searchString.includes(getUsername(u?.user, username)?.toLowerCase())
      );

  const addSeasonUsers = () => {
    if (!season?.token) {
      window.alert("No season token");
      return;
    }
    if (!addUsers.length) {
      window.alert("No users to add");
      return;
    }
    setLoading(true);
    api.season.users
      .add(
        season?.token,
        addUsers.map(u => u.id)
      )
      .then(resp => {
        fetchSeason(season?.token);
        if (resp.data && resp.data.errors && resp.data.errors.length) {
          setErrors(resp.data.errors);
          setLoading(false);
          setAddUsers([]);
        } else {
          setOpen(null);
          setLoading(false);
          setAddUsers([]);
        }
      });
  };

  const removeSeasonUser = () => {
    if (!season?.token) {
      window.alert("No season token");
      return;
    }
    if (!removeUser || !removeUser.user?.id) {
      window.alert("No users to remove");
      return;
    }
    setLoading(true);
    api.season.users
      .remove(season?.token, removeUser.user.id)
      .then(resp => {
        fetchSeason(season?.token);
        if (resp.data && resp.data.errors && resp.data.errors.length) {
          setErrors(resp.data.errors);
          setLoading(false);
          setRemoveUser(null);
        } else {
          setOpen(null);
          setLoading(false);
          setRemoveUser(null);
        }
      })
      .catch(err => {
        console.log(
          err,
          JSON.stringify(Object.keys(err || {})),
          JSON.stringify(Object.keys(err.response || {})),
          err.response.data,
          err.toJSON()
        );
        setErrors([err?.response?.statusText]);
        setLoading(false);
      });
  };

  const handleSearchUsers = (
    searchWord,
    newPage = false,
    resetPage = false
  ) => {
    let data = [];

    if (isTeam) {
      data = api.team
        .list({
          name: searchWord,
          q: searchWord,
          limit: 25,
          ...(!searchWord &&
            !resetPage && {
              page: newPage ? page + 1 : page
            })
        })
        .then(resp => {
          if (resp?.data?.errors && resp.data.errors.length) {
            setErrors(resp.data.errors);
            return [];
          }
          const retList = (resp?.data.docs || []).map(team => ({
            label: team.name,
            value: team.id,
            ...team
          }));

          const compList = [
            ...(!searchWord && !resetPage ? searchList : []),
            ...retList
          ];

          setSearchList(compList);
          setPage(resetPage ? 1 : newPage ? page + 1 : page);
          return compList;
        })
        .catch(err => {
          console.log(err, "Team search failed");
        });
    } else {
      data = api.search(searchWord, 10, 1, true, season?.consoleId).then(resp =>
        resp.data.users
          .filter(
            u =>
              !players.find(p => p?.user?.id === u.id) &&
              !addUsers.find(p => p.id === u.id)
          )
          .map(u => ({
            label: getUsername(u, username),
            value: u.id,
            ...u
          }))
      );
    }
    return data;
  };

  const addUser = user => {
    // FieldTypeAndSelect sometimes can return null
    if (!!!user) {
      return;
    }
    let users = [...addUsers];

    let index = users.findIndex(c => c.id === user.id);
    if (
      index === -1 &&
      user.id !== undefined &&
      (isTeam ? users.length === 0 : true)
    ) {
      users.push(user);
    }

    setAddUsers(users);
  };

  const removeUserAction = (newUsers = []) => {
    setAddUsers(
      addUsers.filter(u =>
        newUsers.includes(isTeam ? u?.team?.name : getUsername(u, username))
      )
    );
  };

  const AddDialog = () => {
    return (
      <DialogKit
        title={isTeam ? "Add Team" : "Add Players"}
        renderBody={() => {
          const players = addUsers.map(u =>
            isTeam ? u?.team?.name : getUsername(u, username)
          );
          return errors.length ? (
            <Errors>
              Failed to add users:
              {errors.map(err => (
                <Error>
                  {err.user} - {err.message}
                </Error>
              ))}
            </Errors>
          ) : (
            <>
              <FormFieldKit fullWidth>
                {
                  <FieldTypeAndSelect
                    placeholder={"Search by gamertag..."}
                    onChange={user => addUser(user)}
                    onSearch={handleSearchUsers}
                  />
                }
              </FormFieldKit>

              {!isTeam && (
                <InputChips
                  value={players}
                  onChange={removeUserAction}
                  readOnly={true}
                  placeholder={"Add users in the field above"}
                />
              )}
            </>
          );
        }}
        displayButtonClose
        onClose={() => setOpen(null)}
        isOpen={isOpen === "add"}
        renderFooter={() => (
          <DialogButtons>
            <ButtonKit
              fullWidth
              preloader={loading}
              onClick={() => {
                if (errors.length) {
                  setErrors([]);
                  setOpen(null);
                } else {
                  addSeasonUsers();
                }
              }}
            >
              {errors.length ? "Okay" : isTeam ? "Add Team" : "Add Players"}
            </ButtonKit>
            {!errors.length && (
              <ButtonKit
                fullWidth
                appearance={"secondary"}
                color={"red"}
                onClick={() => setOpen(null)}
              >
                Cancel
              </ButtonKit>
            )}
          </DialogButtons>
        )}
      />
    );
  };

  const RemoveDialog = () => {
    return (
      <DialogKit
        title={isTeam ? "Remove Team" : "Remove Player"}
        renderBody={() => {
          return errors.length ? (
            <Errors>
              Failed to remove user:{" "}
              {errors.map(err => (
                <Error>{err}</Error>
              ))}
            </Errors>
          ) : (
            <>
              <Info>
                <Avatar
                  src={
                    (isTeam
                      ? removeUser?.team?.imageInfo?.thumbnail
                      : removeUser?.user?.imageInfo?.thumbnail) ||
                    placeholderUserImg
                  }
                  onError={e => (e.target.src = placeholderUserImg)}
                  alt=""
                />
                <Name>
                  {isTeam
                    ? removeUser?.team?.name
                    : getUsername(removeUser?.user, username)}
                </Name>
              </Info>
            </>
          );
        }}
        displayButtonClose
        onClose={() => setOpen(null)}
        isOpen={isOpen === "remove"}
        renderFooter={() => (
          <DialogButtons>
            <ButtonKit
              fullWidth
              preloader={loading}
              onClick={() => {
                if (errors.length) {
                  setErrors([]);
                  setOpen(null);
                } else {
                  removeSeasonUser();
                }
              }}
            >
              {errors.length
                ? "Okay"
                : isTeam
                ? "Remove Team"
                : "Remove Player"}
            </ButtonKit>
            {!errors.length && (
              <ButtonKit
                fullWidth
                appearance={"secondary"}
                color={"red"}
                onClick={() => setOpen(null)}
              >
                Cancel
              </ButtonKit>
            )}
          </DialogButtons>
        )}
      />
    );
  };

  return (
    <Container>
      <Title>
        <Search
          search={search}
          onChangeSearch={val => setSearch(val)}
          placeholder={isTeam ? "Search Teams" : "Search Players"}
          width={200}
        />
        {true && (
          <ButtonKit
            width={"200px"}
            small
            appearance={"secondary"}
            onClick={() => setOpen(isTeam ? "addTeam" : "add")}
          >
            {isTeam ? "Add Team" : "Add Players"}
          </ButtonKit>
        )}
      </Title>
      <br />
      <List>
        {players?.length > 0
          ? players.map(p => {
              const teamUsers = isTeam
                ? season?.users?.filter(
                    su => su?.teamId === p?.team?.id && !su?.isPrimary
                  ) || []
                : [];
              return (
                <Item team={isTeam}>
                  <Info>
                    <Avatar
                      team={isTeam}
                      src={
                        (isTeam
                          ? p?.team?.imageInfo?.thumbnail
                          : p?.user?.imageInfo?.thumbnail) || placeholderUserImg
                      }
                      onError={e => (e.target.src = placeholderUserImg)}
                      alt=""
                    />
                    <Name team={isTeam}>
                      {isTeam ? (
                        <TeamName>
                          {p?.team?.name}
                          <TeamId>#{p?.team?.token}</TeamId>
                        </TeamName>
                      ) : (
                        getUsername(p?.user, username)
                      )}
                      {/* {!isTeam && <TeamId style={{marginLeft: '0', fontSize: '10px'}}>Rival ID#: {p?.user.id}</TeamId>} */}
                      {isTeam && (
                        <Members>
                          <Captain>
                            <IconHeadset
                              width={18}
                              height={18}
                              style={{ marginRight: "8px", color: "#fff" }}
                            />
                            <Avatar
                              small
                              src={
                                p?.user?.imageInfo?.thumbnail ||
                                placeholderUserImg
                              }
                              onError={e => (e.target.src = placeholderUserImg)}
                              alt=""
                            />
                            {getUsername(p?.user, username)}
                          </Captain>{" "}
                          <IconPeople
                            width={22.5}
                            height={18}
                            style={{ marginRight: "8px" }}
                          />
                          {teamUsers?.map((u, i) => (
                            <Player>
                              <Avatar
                                small
                                src={
                                  u?.user?.imageInfo?.thumbnail ||
                                  placeholderUserImg
                                }
                                onError={e =>
                                  (e.target.src = placeholderUserImg)
                                }
                                alt=""
                              />
                              {getUsername(u.user, username)}
                              {teamUsers.length - 1 === i ? " " : ", "}
                            </Player>
                          ))}
                        </Members>
                      )}
                    </Name>
                  </Info>
                  <Actions>
                    {// season?.status !== "complete" &&
                    //   !season?.matches?.find(m => m?.startedAt || m?.endedAt)
                    season?.status === "initialized" && (
                      <ButtonKit
                        small
                        appearance={"secondary"}
                        color={"red"}
                        shape={"rounded"}
                        onClick={() => {
                          setRemoveUser(p);
                          setOpen("remove");
                        }}
                      >
                        Remove
                      </ButtonKit>
                    )}
                  </Actions>
                </Item>
              );
            })
          : `No ${isTeam ? "Teams" : "Players"} Yet`}
      </List>
      <AddDialog />
      <RemoveDialog />
      <AddTeamDialog
        isDialogOpen={isOpen === "addTeam"}
        onClose={() => setOpen(null)}
      />
    </Container>
  );
}

const Container = styled.div`
  color: #fff;
  width: 100%;
  padding: 0 0 20px;
`;

const List = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  list-style: none;
  padding: 0;
  margin-top: 0;
`;

const Item = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 7px 5px;
  cursor: pointer;
  /* transition: background-color 0.2s ease-out; */
  :hover,
  :active {
    background-color: rgb(150, 150, 150, 0.1);
    transition: background-color 0.2s ease-out;
  }

  ${({ team }) =>
    team &&
    css`
      padding: 10px 5px;
    `}
`;

const Info = styled.div`
  display: flex;
  align-items: center;
  color: #ffffff;
`;

const Avatar = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 8px;

  ${({ team }) =>
    team &&
    css`
      width: 42px;
      height: 42px;
      margin-right: 8px;
    `}

  ${({ small }) =>
    small &&
    css`
      width: 18px;
      height: 18px;
      margin-right: 3px;
      border: 1px solid #fff;
    `}
`;

const Name = styled.div`
  font-size: ${({ team }) => (team ? "20px" : "inherit")};
`;

const Title = styled.div`
  font-size: 18px;
  color: ${COLOR.GREY};
  display: flex;
  justify-content: space-between;
  align-items: center;

  > :not(:last-child) {
    margin-right: 10px;
  }
`;

const DialogButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  width: 100%;
  > :not(:last-child) {
    margin-right: 0;
    margin-bottom: 10px;
  }
`;
const Errors = styled.ul`
  font: ${FONT_WEIGHT.MEDIUM} 16px / 20px ${FONT_FAMILY.GOTHIC};
  color: ${COLOR.CHERRY_RED};
`;
const Error = styled.li`
  font: ${FONT_WEIGHT.NORMAL} 14px / 20px ${FONT_FAMILY.GOTHIC};
  color: ${COLOR.TEXT};
  margin-top: 10px;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const Members = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 2px;
`;

const Captain = styled.div`
  display: flex;
  font-weight: 700;
  margin-right: 20px;
  font-size: 15px;
  color: #bbb;
  align-items: center;
`;

const Player = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 15px;
  color: #bbb;
  margin-right: 8px;
`;

const TeamName = styled.div`
  display: flex;
  align-items: center;
`;

const TeamId = styled.div`
  font-size: 13px;
  color: ${COLOR.GREY};
  font-weight: 900;
  margin-left: 5px;
`;
