import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";

import { api } from "../../../index";
import { fetchAssetsAction } from "../../../store/assets/actions";
import { getGlobalUser } from "../../../store/user/selectors";

import Asset from "../Asset/Asset";
import ButtonKit from "../../../components/kit/Button/ButtonKit";
import SelectKit from "../../../components/kit/Fields/Select/SelectKit";

import { COLOR, FONT_WEIGHT, FONT_FAMILY } from "../../../constants/theme";

class AssetScroll extends Component {
  constructor(props) {
    super(props);

    const seriesAssets = this.props.assets.filter(a =>
      a.name.includes(this.props.asset.name)
    );
    const assetsOrder = seriesAssets.map((a, i) =>
      !!a?.order ? a?.order - 1 : i
    );
    this.state = {
      changeOrderMode: false,
      submitting: false,
      order: assetsOrder,
      assetsOrder
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.assets.length !== this.props.assets.length) {
      const seriesAssets = this.props.assets.filter(a =>
        a.name.includes(this.props.asset.name)
      );

      const assetsOrder = seriesAssets.map((a, i) => {
        return !!a?.order ? a?.order - 1 : i;
      });

      this.setState({
        order: assetsOrder,
        assetsOrder
      });
    }
  }

  handleOrderChange(listIndex, newIndex) {
    const { order } = this.state;

    let newOrder = [...order];
    let assetIndex = newOrder.findIndex(o => o === listIndex);

    let min = newIndex;
    let max = listIndex;
    let increment = 1;
    if (listIndex < newIndex) {
      min = listIndex;
      max = newIndex;
      increment = -1;
    }

    for (let i = 0; i < order.length; i++) {
      if (i === assetIndex) {
        newOrder[i] = newIndex;
      } else {
        if (newOrder[i] >= min && newOrder[i] <= max) {
          newOrder[i] = order[i] + increment;
        }
      }
    }

    this.setState({ order: newOrder });
  }

  handleSubmitOrder() {
    const { order } = this.state;
    const { asset } = this.props;
    this.setState({ submitting: true });
    const promises = order.map((o, i) => {
      return new Promise((resolve, reject) => {
        let data = new FormData();
        data.append("name", `${asset.name}-${i + 1}`);
        data.append("order", o + 1);
        api.assets.update(data).then(resp => {
          resp.data.success
            ? resolve(i)
            : reject(
                new Error(`Unable to update order of ${asset.name}-${i + 1}`)
              );
        });
      });
    });
    Promise.all(promises)
      .then(values => {
        this.setState({ submitting: false });
        window.location.reload();
      })
      .catch(error => {
        this.setState({ error: error.message });
      });
  }

  render() {
    const { title, subtitle, imageSpecs, asset, seriesNum } = this.props;
    const { order, assetsOrder, changeOrderMode, submitting } = this.state;

    return (
      <Container>
        {!!title && (
          <SectionHeader>
            <SectionInfo>
              <SectionTitle>{title}</SectionTitle>
              {!!subtitle && <SectionSubtitle>{subtitle}</SectionSubtitle>}
              {!!imageSpecs && (
                <WrapRequirements>
                  <ImageTitle>Image Requirements:</ImageTitle>
                  <ConstraintList>
                    {imageSpecs.map(spec => {
                      return (
                        <ConstraintItem isValid={null}>{spec}</ConstraintItem>
                      );
                    })}
                  </ConstraintList>
                </WrapRequirements>
              )}
            </SectionInfo>
            {changeOrderMode ? (
              <WrapButtons>
                {submitting && <SectionSubtitle>Submitting...</SectionSubtitle>}
                <WrapButton>
                  <ButtonKit
                    shape={"rounded"}
                    color={"green"}
                    fullWidth
                    small
                    onClick={() => this.handleSubmitOrder()}
                    disabled={order.every(
                      (val, index) => val === assetsOrder[index]
                    )}
                  >
                    SUBMIT ORDER
                  </ButtonKit>
                </WrapButton>
                <WrapButton>
                  <ButtonKit
                    shape={"rounded"}
                    appearance={"secondary"}
                    color={"red"}
                    fullWidth
                    small
                    onClick={() =>
                      this.setState({
                        changeOrderMode: false,
                        order: assetsOrder
                      })
                    }
                  >
                    CANCEL
                  </ButtonKit>
                </WrapButton>
              </WrapButtons>
            ) : (
              <WrapButtons>
                <WrapButton>
                  <ButtonKit
                    shape={"rounded"}
                    appearance={"secondary"}
                    fullWidth
                    small
                    onClick={() => this.setState({ changeOrderMode: true })}
                  >
                    CHANGE ORDER
                  </ButtonKit>
                </WrapButton>
              </WrapButtons>
            )}
          </SectionHeader>
        )}
        <ImageList>
          <ScrollContainer>
            {[...Array(seriesNum)].map((_, i) => {
              const hasAsset = i < order.length;
              let assetNum = i;
              let sectionNum = i + 1;

              if (hasAsset) {
                assetNum = order.findIndex(o => o === i);
                sectionNum = assetsOrder[assetNum] + 1;
              }

              return (
                <WrapAsset disabled={i >= order.length + 1}>
                  <Asset
                    name={`${asset.name}-${assetNum + 1}`}
                    label={`${asset.label} ${sectionNum}`}
                    title={asset.title}
                    description={asset.description}
                    organizationGroupId={asset?.organizationGroupId}
                    link={asset.link}
                    image={asset.image}
                    video={asset.video}
                    imageDimensions={asset.imageDimensions}
                    aspectRatioLimits={asset.aspectRatioLimits}
                    titleLimit={asset.titleLimit}
                    descriptionLimit={asset.descriptionLimit}
                  />
                  {changeOrderMode && hasAsset && (
                    <WrapOrderField>
                      <WrapSelectField>
                        <SelectKit
                          select
                          fullWidth
                          value={i}
                          onChange={val => this.handleOrderChange(i, val)}
                          options={[...Array(order.length)].map((_, j) => {
                            return { value: j, label: j + 1 };
                          })}
                        />
                      </WrapSelectField>
                    </WrapOrderField>
                  )}
                </WrapAsset>
              );
            })}
          </ScrollContainer>
        </ImageList>
      </Container>
    );
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: ${COLOR.WHITE};
`;

const WrapAsset = styled.div`
  width: 100%;
  position: relative;

  ${({ disabled }) =>
    disabled &&
    `opacity: 0.4;
  pointer-events: none;`}
`;

const SectionHeader = styled.div`
  display: flex;
  margin-bottom: 15px;
  color: ${COLOR.WHITE};
`;

const SectionInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const WrapButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 250px;
  > :not(:last-child) {
    margin-right: 10px;
  }
`;

const WrapButton = styled.div`
  width: 100%;
  max-width: 125px;
`;

const SectionTitle = styled.div`
  font: ${FONT_WEIGHT.NORMAL} 16px ${FONT_FAMILY.GOTHIC_BOLD};
`;

const SectionSubtitle = styled.div`
  margin-top: 10px;
  font: ${FONT_WEIGHT.NORMAL} 14px ${FONT_FAMILY.GOTHIC};
`;

const WrapRequirements = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0px 0px 0px;
`;

const ConstraintList = styled.ul`
  font: ${FONT_WEIGHT.NORMAL} 12px ${FONT_FAMILY.GOTHIC};
  user-select: text;
  margin: 0px;
  padding-left: 20px;
`;

const ConstraintItem = styled.li`
  display: flex;
  align-items: center;
  ${({ isValid }) => {
    return isValid === null
      ? `color: ${COLOR.TEXT};`
      : isValid
      ? `color: ${COLOR.LIGHT_GREEN};`
      : `color: ${COLOR.CHERRY_RED};`;
  }}
`;

const ImageTitle = styled.div`
  font: ${FONT_WEIGHT.NORMAL} 14px ${FONT_FAMILY.GOTHIC_BOLD};
  margin: 5px 0px;
`;

const WrapOrderField = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  top: 5px;
  right: 60px;
`;

const WrapSelectField = styled.div`
  width: 60px;
`;

const ImageList = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  margin-bottom: 40px;

  background-color: ${COLOR.BG_ACCENT};
  border-radius: 8px;

  overflow-y: auto;
`;

const ScrollContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  width: 500px;

  padding: 25px;
`;

const mapStateToProps = state => ({
  user: getGlobalUser(state),
  assets: state.assetsState.assets
});

const mapDispatchToProps = {
  fetchAssetsAction
};

export default connect(mapStateToProps, mapDispatchToProps)(AssetScroll);
