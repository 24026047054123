import React from "react";
import PropTypes from "prop-types";
import {
  FormInFrameBodySD,
  FormInFrameFooter,
  FormInFrameHeaderSD,
  FormInFrameSD
} from "./styled";
import { COLOR } from "../../../constants/theme";
import TypographyKit from "../../kit/Typography/TypographyKit";

function FormInFrame(props) {
  const { title, body, footer, onSubmit } = props;

  const handleSubmit = e => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <FormInFrameSD onSubmit={handleSubmit}>
      <FormInFrameHeaderSD>
        <TypographyKit
          variant={"h3"}
          gutterBottom={false}
          color={COLOR.TEXT_3}
          textAlign={"center"}
        >
          {title}
        </TypographyKit>
      </FormInFrameHeaderSD>

      <FormInFrameBodySD>{body}</FormInFrameBodySD>

      <FormInFrameFooter>{footer}</FormInFrameFooter>
    </FormInFrameSD>
  );
}

FormInFrame.propTypes = {
  title: PropTypes.string,
  body: PropTypes.node.isRequired,
  footer: PropTypes.node.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default FormInFrame;
