import { COMMUNICATION_ACTIONS } from "./constants";

const initialState = {
  email: null,
  scheduled: [],
  sent: [],
  drafts: [],
  templates: [],
  globalTemplates: [],
  loading: false,
  error: false
};

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case COMMUNICATION_ACTIONS.email.add:
      return {
        ...state,
        email: action.payload
      };
    case COMMUNICATION_ACTIONS.email.delete:
      return {
        ...state,
        email: null
      };
    case COMMUNICATION_ACTIONS.scheduled.request:
      return {
        ...state,
        loading: true,
        error: false
      };
    case COMMUNICATION_ACTIONS.scheduled.success:
      return {
        ...state,
        scheduled: action.payload,
        loading: false,
        error: null
      };
    case COMMUNICATION_ACTIONS.scheduled.error:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case COMMUNICATION_ACTIONS.scheduled.create:
      return {
        ...state,
        scheduled: [...state.scheduled, action.payload],
        loading: false,
        error: false
      };
    case COMMUNICATION_ACTIONS.scheduled.update:
      return {
        ...state,
        scheduled: updateObjectInArray(state.scheduled, action.payload)
      };
    case COMMUNICATION_ACTIONS.scheduled.delete:
      return {
        ...state,
        scheduled: state.scheduled.filter(item => item.id !== action.payload)
      };
    case COMMUNICATION_ACTIONS.drafts.request:
      return {
        ...state,
        loading: true,
        error: false
      };
    case COMMUNICATION_ACTIONS.drafts.success:
      return {
        ...state,
        drafts: action.payload,
        loading: false,
        error: null
      };
    case COMMUNICATION_ACTIONS.drafts.error:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case COMMUNICATION_ACTIONS.drafts.create:
      return {
        ...state,
        drafts: [...state.drafts, action.payload],
        loading: false,
        error: false
      };
    case COMMUNICATION_ACTIONS.drafts.update:
      return {
        ...state,
        drafts: updateObjectInArray(state.drafts, action.payload)
      };
    case COMMUNICATION_ACTIONS.drafts.delete:
      return {
        ...state,
        drafts: state.drafts.filter(item => item.id !== action.payload)
      };
    case COMMUNICATION_ACTIONS.sent.request:
      return {
        ...state,
        loading: true,
        error: false
      };
    case COMMUNICATION_ACTIONS.sent.success:
      return {
        ...state,
        sent: action.payload,
        loading: false,
        error: null
      };
    case COMMUNICATION_ACTIONS.sent.error:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case COMMUNICATION_ACTIONS.global_templates.request:
      return {
        ...state,
        loading: true,
        error: false
      };

    case COMMUNICATION_ACTIONS.global_templates.success:
      return {
        ...state,
        globalTemplates: action.payload,
        loading: false,
        error: null
      };

    case COMMUNICATION_ACTIONS.global_templates.error:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case COMMUNICATION_ACTIONS.global_templates.create:
      return {
        ...state,
        globalTemplates: [...state.globalTemplates, action.payload],
        loading: false,
        error: false
      };

    case COMMUNICATION_ACTIONS.global_templates.update:
      return {
        ...state,
        templates: updateObjectInArray(state.globalTemplates, action.payload)
      };

    case COMMUNICATION_ACTIONS.global_templates.delete:
      return {
        ...state,
        globalTemplates: state.globalTemplates.filter(
          item => item.id !== action.payload
        )
      };

    case COMMUNICATION_ACTIONS.templates.request:
      return {
        ...state,
        loading: true,
        error: false
      };
    case COMMUNICATION_ACTIONS.templates.success:
      return {
        ...state,
        templates: action.payload,
        loading: false,
        error: null
      };
    case COMMUNICATION_ACTIONS.templates.error:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case COMMUNICATION_ACTIONS.templates.create:
      return {
        ...state,
        templates: [...state.templates, action.payload],
        loading: false,
        error: false
      };
    case COMMUNICATION_ACTIONS.templates.update:
      return {
        ...state,
        templates: updateObjectInArray(state.templates, action.payload)
      };
    case COMMUNICATION_ACTIONS.templates.delete:
      return {
        ...state,
        templates: state.templates.filter(item => item.id !== action.payload)
      };
    default:
      return state;
  }
};

function updateObjectInArray(array, payload) {
  return array.map(item => {
    if (item.id !== payload.id) {
      return item;
    }
    return {
      ...item,
      ...payload
    };
  });
}

export default reducers;
