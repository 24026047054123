import React, { Component } from "react";
import PropTypes from "prop-types";
import { omit } from "lodash";
import Editor from "draft-js-plugins-editor";
import { Container } from "./styled";
import "draft-js/dist/Draft.css";
import createLinkifyPlugin from "draft-js-linkify-plugin";
import createCustomLinkPlugin from "./CustomLink";

const linkifyPlugin = createLinkifyPlugin({
  target: "_blank",
  component: props => {
    const newProps = omit(props, ["blockKey"]);
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    return <a {...newProps} onClick={() => window.open(newProps.href)} />;
  }
});

const customLinkPlugin = createCustomLinkPlugin({
  target: "_blank"
});

const plugins = [linkifyPlugin, customLinkPlugin];

class RichTextEditor extends Component {
  render() {
    const {
      minHeight,
      placeholder,
      editorState,
      customStyleFn,
      onChange,
      readOnly,
      setEditorRef,
      focusEditor
    } = this.props;

    return (
      <Container
        minHeight={minHeight}
        onClick={focusEditor}
        readOnly={readOnly}
      >
        <Editor
          ref={setEditorRef}
          plugins={plugins}
          customStyleFn={customStyleFn}
          editorState={editorState}
          onChange={onChange}
          placeholder={placeholder}
          readOnly={readOnly}
          {...this.props}
        />
      </Container>
    );
  }
}

RichTextEditor.propTypes = {
  minHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default RichTextEditor;
