import styled from "styled-components";
import { COLOR } from "../../constants/theme";
import breakpoints from "../../helpers/breakpoints";

export const LayoutLoggedSD = styled.div`
  display: flex;
  height: 100%;
  user-select: none;
`;

export const LayoutLoggedWrapHeaderSD = styled.div`
  display: flex;
  justify-content: center;

  width: 100%;
  z-index: 1003;
`;

export const WrapContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
`;

export const Content = styled.div`
  display: flex;
  background-color: ${COLOR.BG_PRIMARY};
  overflow-y: auto;
  width: 100%;
  height: 100%;

  ${breakpoints.down("sm")} {
    padding: 0px;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${COLOR.BG_PRIMARY};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${COLOR.BG_SECONDARY};
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${COLOR.RIVAL_RED};
  }
`;

// for child component will be enough set height 100% for cover all height.
export const LayoutLoggedMainSD = styled.div`
  flex: 0 0 100%;
  flex-grow: 1;
  min-width: 0;
`;

export const LayoutLoggedWrapFooterSD = styled.div`
  flex: 0 0 auto;
`;

export const LayoutLoggedMatchNotificationSD = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 101;

  width: 100%;

  box-shadow: 0 -16px 20px 0 rgba(0, 0, 0, 0.2);
`;
