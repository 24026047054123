import styled, { css } from "styled-components";
import { COLOR, FONT_FAMILY, FONT_WEIGHT } from "../../constants/theme";
import breakpoints from "../../helpers/breakpoints";

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  min-height: 100%;
  max-width: 1440px;
  width: 100%;
  color: ${COLOR.WHITE};
  z-index: 3;

  padding: 30px;
  border-radius: 16px;

  background-color: ${COLOR.BG_SECONDARY};
`;

export const Container = styled.div`
  min-height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 20px 20px 20px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
  padding-bottom: 20px;
  margin-bottom: 20px;

  border-bottom: 1px solid ${COLOR.BORDER};
`;

export const Title = styled.div`
  width: 100%;
  font: ${FONT_WEIGHT.NORMAL} 16px ${FONT_FAMILY.GOTHIC_BOLD};
`;

export const TableEmpty = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 89px;
  padding-bottom: 67px;
`;

export const TitleEmpty = styled.div`
  margin-bottom: 20px;
  color: ${COLOR.TEXT_4};
  font-size: 14px;
  font-weight: ${FONT_WEIGHT.MEDIUM};
  line-height: 21px;
  text-align: center;
  text-transform: uppercase;
`;

export const List = styled.div`
  color: ${COLOR.TEXT};
`;

export const Text = styled.div`
  color: ${COLOR.TEXT};
  font: ${FONT_WEIGHT.NORMAL} 14px / 16px ${FONT_FAMILY.THIN};
  text-decoration: none;
`;

export const FilterBar = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
  padding-bottom: 20px;

  background-color: ${COLOR.BG_SECONDARY};
  overflow: hidden;
`;

export const Filter = styled.div`
  color: ${COLOR.TEXT};
  display: inline-flex;
  display: flex;
  align-items: center;
  overflow: hidden;

  border: 3px solid ${COLOR.RIVAL_RED};
  border-radius: 8px;
`;

const ACTIVE_SWITCHER_ITEM_STYLES = css`
  background-color: ${COLOR.RIVAL_RED};
  color: ${COLOR.WHITE};
`;

const NOT_ACTIVE_SWITCHER_ITEM_STYLES = css`
  background-color: ${COLOR.BG_SECONDARY};
  color: ${COLOR.WHITE};
`;

export const FilterItem = styled.div`
  width: 100%;
  min-width: 160px;
  padding: 10px;
  flex-grow: 1;

  font: ${FONT_WEIGHT.MEDIUM} 14px / 1.2 ${FONT_FAMILY.GOTHIC_BOLD};
  text-align: center;
  text-transform: uppercase;

  cursor: pointer;
  transition: all 0.3s;

  ${_IS_ACTIVE_SWITCHER_ITEM_};

  &:hover {
    ${ACTIVE_SWITCHER_ITEM_STYLES};
  }

  ${breakpoints.down("md")} {
    padding-top: 11px;
    padding-bottom: 11px;
    width: auto;
  }

  ${breakpoints.down("xs")} {
    display: flex;
    min-width: 100px;
    align-items: center;
    justify-content: center;
    padding-left: 4px;
    padding-right: 4px;
  }

  ${breakpoints.down("440")} {
    min-width: 0;
    font-size: 12px;
  }
`;

function _IS_ACTIVE_SWITCHER_ITEM_({ isActive }) {
  return isActive
    ? ACTIVE_SWITCHER_ITEM_STYLES
    : NOT_ACTIVE_SWITCHER_ITEM_STYLES;
}

export const WrapIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BackIcon = styled.img`
  height: 100%;
  max-height: 40px;
  margin-right: 5px;

  cursor: pointer;
`;

export const WrapButton = styled.div`
  margin-left: auto;
`;
