import styled from "styled-components";
import { COLOR } from "../../constants/theme";

export const Container = styled.div`
  min-height: 100%;
  background-color: ${COLOR.BG_PRIMARY};
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  padding-bottom: 20px;
`;

export const Content = styled.div`
  /* display: flex;
  flex-direction: column;

  padding: 30px;
  border-radius: 16px;

  background-color: ${COLOR.BG_SECONDARY}; */
`;
