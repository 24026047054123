import React from "react";
import PropTypes from "prop-types";
import { RadioButtonLabelTextSD, RadioButtonSD } from "./styled";

function RadioButtonKit(props) {
  const {
    label,
    value,
    checked,
    defaultChecked,
    onChange,
    name,
    disabled,
    onBlur
  } = props;
  const inputProps = props.inputProps || {};

  return (
    <RadioButtonSD>
      <input
        type="radio"
        value={value}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        defaultChecked={defaultChecked}
        checked={checked}
        disabled={disabled}
        {...inputProps}
      />
      <RadioButtonLabelTextSD>{label}</RadioButtonLabelTextSD>
    </RadioButtonSD>
  );
}

RadioButtonKit.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  checked: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  onChange: PropTypes.func,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
  inputProps: PropTypes.object
};

export default RadioButtonKit;
