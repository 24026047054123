import React from "react";
import { SVGIconSD } from "./styled";
import PropTypes from "prop-types";

function IconCirclePlus(props) {
  return (
    <SVGIconSD width={props.width} viewBox="0 0 24 24">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-1029.000000, -493.000000)">
          <g transform="translate(1029.000000, 493.000000)">
            <polygon points="0 0 24 0 24 24 0 24" />
            <path
              d="M13,7 L11,7 L11,11 L7,11 L7,13 L11,13 L11,17 L13,17 L13,13 L17,13 L17,11 L13,11 L13,7 Z M12,2
           C6.48,2 2,6.48 2,12 C2,17.52 6.48,22 12,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 12,2 Z M12,20
            C7.59,20 4,16.41 4,12 C4,7.59 7.59,4 12,4 C16.41,4 20,7.59 20,12 C20,16.41 16.41,20 12,20 Z"
              fill="#FFFFFF"
              fillRule="nonzero"
            />
          </g>
        </g>
      </g>
    </SVGIconSD>
  );
}

IconCirclePlus.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default IconCirclePlus;
