import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Switch, Route } from "react-router-dom";
import {
  fetchGameAction,
  pusherGameAction,
  resetGameAction
} from "../../store/game/actions";
import {
  fetchTournamentAction,
  resetTournamentAction
} from "../../store/tournament/actions";
import { propValueOr, debounce, verifyPermission } from "../../helpers/common";
import Pusher from "pusher-js";
import { routes } from "./routes";
class RouteGameContainer extends Component {
  constructor(props) {
    super(props);

    this.pusher = new Pusher(process.env.REACT_APP_PUSHER_ID || "", {
      cluster: "us3",
      forceTLS: true
    });

    this.state = {
      isFocused: false
    };
  }

  componentDidMount() {
    const token = propValueOr(this.props, "match.params.token", null);
    this.fetchGame(token);
    this.subscribePusher(token);

    window.addEventListener("focus", this.handleFocus);
    window.addEventListener("blur", this.handleBlur);
  }

  componentDidUpdate(prevProps) {
    const token = propValueOr(this.props, "match.params.token", null);
    if (prevProps.game.token !== undefined && prevProps.game.token !== token) {
      this.unsubscribePusher(prevProps.game.token);
      this.fetchGame(token);
      this.subscribePusher(token);
    }

    if (prevProps.tournament.id !== this.props.tournament.id) {
      this.subscribeTournament(this.props.tournament.id);
    }
  }

  componentWillUnmount() {
    const token = propValueOr(this.props, "match.params.token", null);
    this.unsubscribePusher(token);
    this.unsubscribeTournament(this.props.tournament.id);
    this.props.resetGameAction();
    this.props.resetTournamentAction();
    window.removeEventListener("focus", this.handleFocus);
    window.removeEventListener("blur", this.handleBlur);
  }

  fetchGame = token => {
    this.props.fetchGameAction(token).then(() => this.fetchTournament());
  };

  fetchTournament = () => {
    const token = propValueOr(this.props, "game.tournament.token", null);
    if (token) {
      this.props.fetchTournamentAction(token);
    }
  };

  subscribeTournament = tournamentId => {
    this.pusher.subscribe(`tournament-${tournamentId}`);

    this.pusher.bind("status", packet => {
      let data = packet.message;

      if (data.status === "started") {
        debounce(this.fetchTournament(), 500);
      } else if (data.status === "update") {
        debounce(this.fetchTournament(), 500);
      }
    });

    this.pusher.bind("new-matches", () => {
      debounce(this.fetchTournament(), 500);
    });

    this.pusher.bind("new-brackets", () => {
      debounce(this.fetchTournament(), 500);
    });
  };

  unsubscribeTournament = tournamentId => {
    if (tournamentId) {
      this.pusher.unsubscribe(`tournament-${tournamentId}`);
    }
  };

  handleFocus = () => {
    if (this.state.isFocused === false) {
      const token = propValueOr(this.props, "match.params.token", null);
      this.setState({ isFocused: true });
      this.fetchGame(token);
      this.subscribePusher(token);
    }
  };

  handleBlur = () => {
    if (this.state.isFocused === true) {
      this.setState({ isFocused: false });
    }
  };

  subscribePusher = token => {
    const channel = this.pusher.subscribe(`match-${token}`);

    // Unbind any existing connections
    channel.unbind();

    channel.bind("reload", () => {
      this.fetchGame(token);
    });

    channel.bind("status", () => {
      this.fetchGame(token);
    });

    channel.bind("match-info", data => {
      // Update users scores in redux from pusher
      this.props.pusherGameAction(data.message);
    });
  };

  unsubscribePusher = token => {
    if (token) {
      this.pusher.unsubscribe(`match-${token}`);
    }
  };

  render() {
    return (
      <Switch>
        {routes.map(route => {
          const Component = route.component;
          return (
            <Route
              exact={route.exact}
              path={route.path}
              render={() => {
                return (
                  <Component
                    {...this.props}
                    user={this.props.user}
                    permission={verifyPermission(
                      route.path,
                      this.props.permissions
                    )}
                  />
                );
              }}
            />
          );
        })}
      </Switch>
    );
  }
}

const mapStateProps = state => ({
  user: state.user.info,
  game: state.gameState.game,
  tournament: state.tournamentState.tournament,
  error: state.gameState.error,
  permissions: state.permissionsState.permissions
});

const mapDispatchToProps = {
  fetchGameAction,
  pusherGameAction,
  resetGameAction,
  fetchTournamentAction,
  resetTournamentAction
};

export default connect(
  mapStateProps,
  mapDispatchToProps
)(withRouter(RouteGameContainer));
