import LEAGUES_ACTIONS from "./constants";

const initialSeasonState = {
  name: "",
  success: null,
  startDate: null,
  endDate: null,
  registrationStart: null,
  registrationEnd: null,
  teamSize: 1,
  schedule: [],
  minPlayers: undefined,
  settings: {},
  maxPlayers: undefined,
  isTeamSeason: false,
  background: undefined,
  mobile: undefined,
  matchInfo: {
    Verification: "image"
  },
  created: undefined,
  deleted: undefined,
  type: "scheduled"
};

const initialState = {
  league: {},
  leagues: [],
  season: initialSeasonState,
  seasons: [],
  isFetching: false,
  error: null,
  seasonLoading: false
};

const reducers = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LEAGUES_ACTIONS.fetch.request:
      return {
        ...state,
        isFetching: true
      };
    case LEAGUES_ACTIONS.fetch.success:
      return {
        ...state,
        isFetching: false,
        league: payload,
        error: null
      };
    case LEAGUES_ACTIONS.fetch.error:
      return {
        ...state,
        isFetching: false,
        error: payload
      };
    case LEAGUES_ACTIONS.list.request:
      return {
        ...state,
        isFetching: true
      };
    case LEAGUES_ACTIONS.list.success:
      return {
        ...state,
        isFetching: false,
        leagues: payload,
        error: null
      };
    case LEAGUES_ACTIONS.list.error:
      return {
        ...state,
        isFetching: false,
        error: payload
      };
    case LEAGUES_ACTIONS.seasons.fetch.request:
      return {
        ...state,
        isFetching: true
      };
    case LEAGUES_ACTIONS.seasons.fetch.success:
      return {
        ...state,
        isFetching: false,
        season: payload,
        error: null
      };
    case LEAGUES_ACTIONS.seasons.fetch.error:
      return {
        ...state,
        isFetching: false,
        error: payload
      };
    case LEAGUES_ACTIONS.seasons.list.request:
      return {
        ...state,
        isFetching: true
      };
    case LEAGUES_ACTIONS.seasons.list.success:
      return {
        ...state,
        isFetching: false,
        seasons: payload,
        error: null
      };
    case LEAGUES_ACTIONS.seasons.list.error:
      return {
        ...state,
        isFetching: false,
        error: payload
      };
    case LEAGUES_ACTIONS.seasons.update.request:
      return {
        ...state,
        seasonLoading: true
      };
    case LEAGUES_ACTIONS.seasons.update.success:
      return {
        ...state,
        seasonLoading: false,
        // season: payload,
        error: null
      };
    case LEAGUES_ACTIONS.seasons.update.error:
      return {
        ...state,
        error: payload
      };
    case LEAGUES_ACTIONS.seasons.create.request:
      return {
        ...state,
        seasonLoading: true
      };
    case LEAGUES_ACTIONS.seasons.create.success:
      return {
        ...state,
        seasonLoading: false,
        season: payload,
        error: null
      };
    case LEAGUES_ACTIONS.seasons.create.error:
      return {
        ...state,
        error: payload
      };
    case LEAGUES_ACTIONS.seasons.setProperty:
      return {
        ...state,
        season: {
          ...state.season,
          [payload.key]: payload.value
        }
      };
    case LEAGUES_ACTIONS.seasons.setState:
      return {
        ...state,
        season: {
          ...state.season,
          ...payload
        }
      };
    case LEAGUES_ACTIONS.seasons.reset:
      return {
        ...state,
        season: {
          ...initialSeasonState,
          settings: state?.season?.settings
        }
      };
    case LEAGUES_ACTIONS.resetLeague:
      return {
        ...state,
        league: {}
      };
    case LEAGUES_ACTIONS.reset:
      return initialState;
    default:
      return state;
  }
};

export default reducers;
