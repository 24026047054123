import React, { Component } from "react";
import { connect } from "react-redux";
import {
  WrapPage,
  Content,
  Header,
  HeaderRow,
  Title,
  WrapRequirements,
  WrapInfoIcon,
  SubText,
  WrapAsset,
  ImageList,
  ScrollContainer,
  SectionHeader,
  SectionTitle
} from "./styled";
import { ImageTitle, ConstraintList, ConstraintItem } from "./Asset/styled";
import InfoDialog from "./InfoDialog/InfoDialog";
import { withRouter } from "react-router-dom";
import Asset from "./Asset/Asset";
import PermissionDenied from "../denied/PermissionDenied";
import { COLOR } from "../../constants/theme";

// Images & Icons
import InfoIcon from "../../static/icons/info-icon.svg";
import ProfilePDF from "../../static/images/infoPDFs/profile-info.pdf";
class AssetsProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isInfoDialogOpen: false
    };
  }

  renderInfoDialog() {
    return (
      <InfoDialog
        isOpen={this.state.isInfoDialogOpen}
        onClose={() => this.setState({ isInfoDialogOpen: false })}
        title={"Profile Image Info"}
        images={[ProfilePDF]}
      />
    );
  }

  render() {
    if (!this.props.permission) {
      return <PermissionDenied />;
    }
    return (
      <WrapPage>
        <Content>
          <Header>
            <HeaderRow>
              <Title>Profile</Title>
              <WrapInfoIcon
                src={InfoIcon}
                onClick={() => this.setState({ isInfoDialogOpen: true })}
              />
            </HeaderRow>
            <SubText>All images will be compressed down to 400KB</SubText>
          </Header>
          <WrapAsset>
            <Asset
              name="profile-banner"
              link={{ notRequired: true }}
              image
              imageDimensions={[720, 110]}
              imgLabels={[
                "Recommended minimum width of 1440px",
                "Recommended minimum height of 220px"
              ]}
              aspectRatioLimits={[{ value: 5.75 }, { value: 7.05 }]}
              inputStyles={{ backgroundColor: `${COLOR.BG_SECONDARY}` }}
            />
          </WrapAsset>
          <SectionHeader>
            <SectionTitle>Profile Ads</SectionTitle>
            <WrapRequirements>
              <ImageTitle>Image Requirements:</ImageTitle>
              <ConstraintList>
                <ConstraintItem isValid={null}>
                  Recommended minimum width of 600px
                </ConstraintItem>
                <ConstraintItem isValid={null}>
                  Recommended minimum height of 500px
                </ConstraintItem>
                <ConstraintItem isValid={null}>
                  Required width : height ratio between 1.15 and 1.25
                </ConstraintItem>
              </ConstraintList>
            </WrapRequirements>
          </SectionHeader>
          <ImageList>
            <ScrollContainer>
              <Asset
                name="profile-ad-1"
                label={"Ad 1"}
                link={{ notRequired: true }}
                image
                centerItems
                inputStyles={{ backgroundColor: `${COLOR.BG_SECONDARY}` }}
                imageDimensions={[300, 250]}
                aspectRatioLimits={[{ value: 1.15 }, { value: 1.25 }]}
                displayConstraints={false}
              />
              <Asset
                name="profile-ad-2"
                label={"Ad 2"}
                link={{ notRequired: true }}
                image
                centerItems
                inputStyles={{ backgroundColor: `${COLOR.BG_SECONDARY}` }}
                imageDimensions={[300, 250]}
                aspectRatioLimits={[{ value: 1.15 }, { value: 1.25 }]}
                displayConstraints={false}
              />
            </ScrollContainer>
          </ImageList>
          <WrapAsset>
            <Asset
              name="profile-skyscraper"
              label={"Profile Skyscraper Ad"}
              image
              link={{ notRequired: true }}
              imageDimensions={[300, 600]}
              imgLabels={[
                "Recommended minimum width of 600px",
                "Recommended minimum height of 1200px"
              ]}
              aspectRatioLimits={[{ value: 0.45 }, { value: 0.55 }]}
              inputStyles={{ backgroundColor: `${COLOR.BG_SECONDARY}` }}
            />
          </WrapAsset>
          {this.renderInfoDialog()}
        </Content>
      </WrapPage>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user
});
const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AssetsProfile));
