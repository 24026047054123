import React, { Component } from "react";
import { propValueOr } from "../../helpers/common";
import {
  Container,
  Content,
  List,
  ListContainer,
  WrapGameCard,
  GameHover,
  GameCard,
  WrapCheckmark,
  CheckmarkImage,
  TemplateContainer,
  ConsoleImage,
  ConsoleCard,
  ConsoleList,
  Label,
  Info,
  Wrap,
  Title,
  WrapEmpty,
  Icon,
  WrapButtons,
  WrapInput,
  Input,
  WrapLabel,
  Description,
  PreviewWrap,
  Preview,
  WrapError,
  WrapTitle,
  Example,
  SettingField,
  SettingFields
} from "./styled";
import CheckmarkIcon from "../../static/icons/icon-check-white-for-join-btn.svg";
import CrossPlatformThumbnail from "../../static/images/cross-platform.svg";
import FormFieldKit from "../../components/kit/Fields/FormField/FormField";
import DialogKit from "../../components/kit/Dialog/DialogKit";
import ButtonKit from "../../components/kit/Button/ButtonKit";
import { api } from "../../index";
import createGameIcon from "./createGame.svg";
import createSeriesIcon from "./createSeries.svg";
import GameCreate from "./GameCreate/GameCreate";
import iconX from "../../static/icons/remove.svg";
import IconCirclePlus from "../../components/kit/Icon/IconCirclePlus";
import * as queryString from "query-string";
import TypographyKit from "../../components/kit/Typography/TypographyKit";
import { COLOR } from "../../constants/theme";
import Button from "../../components/kit/Button/ButtonKit";
import moment from "moment";
import imageCompression from "browser-image-compression";
import { imageFields } from "./imageFields";
import Panel from "../../components/kit/Panel/Panel";
import { WrapPanel } from "../assets/styled";
import CheckboxKit from "../../components/kit/Fields/Checkbox/CheckboxKit";

const _ = require("lodash");

class RouteGames extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedGame: null,
      selectedTemplate: {},
      applyToAll: false,
      createGame: false,
      createSeries: false,
      settings: [{ name: "", value: "" }],
      updating: false,
      updated: false,
      generalError: "",
      success: "",
      imageUpdated: false,
      isAddSettingDialogOpen: false,
      isAddSettingFieldOpen: false,
      currentIndex: 0,
      series: {}
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedGame !== this.state.selectedGame) {
      this.props.fetchGame(this.state.selectedGame);
      imageFields.map(field =>
        this.setState({
          [field.key]: null,
          [field.key + "Preview"]: null,
          imageUpdated: false,
          [field.key + "Uploaded"]: null,
          imageError: false,
          ["error" + field.key]: "",
          [field.key + "Error"]: false,
          processingImage: false,
          image: null
        })
      );
    }
  }

  handleChangeImage = async (propName, val) => {
    if (val) {
      const options = {
        maxSizeMB: 0.4,
        useWebWorker: true
      };
      this.setState({
        processingImage: true,
        [propName]: null
      });
      const originalFile = val;
      this.setState({ [propName]: originalFile });
      let file;
      if (originalFile.type && originalFile.type.includes("gif")) {
        file = originalFile;
        const fileNameArr = file.name.split(".");
        if (fileNameArr.length > 1) {
          Object.defineProperty(file, "name", {
            writable: true,
            value: `${fileNameArr[0]}_${moment().format("Hmm-DMMYY")}${
              fileNameArr[1]
            }`
          });
        }
      } else {
        file = await imageCompression(val, options);
        const fileNameArr = file.name.split(".");
        if (fileNameArr.length > 1) {
          file.name = `${fileNameArr[0]}_${moment().format("Hmm-DMMYY")}${
            fileNameArr[1]
          }`;
        }
      }
      this.setState({ [propName]: file });

      this.createImgConstraints(propName);

      let reader = new FileReader();
      reader.onload = readerEvent => {
        const img = new Image();
        img.src = readerEvent.target.result;
        img.onload = e => {
          const error = this.validateImage(img.width, img.height);
          if (!!error) {
            this.setState({
              image: null,
              imagePreview: null,
              processingImage: false,
              imageError: true,
              [propName + "Uploaded"]: false,
              imageUpdated: false,
              ["error" + propName]: this.state.imageConstraints.text,
              [propName + "Error"]: true
            });
            return;
          }
          this.setState({
            [propName + "Preview"]: img.src,
            processingImage: false,
            imageError: false,
            [propName + "Uploaded"]: true,
            ["error" + propName]: "",
            [propName + "Error"]: false,
            imageUpdated: true
          });
        };
      };

      reader.readAsDataURL(originalFile);
    }
  };

  handleBlurImage = image => {
    if (!this.isImageValid(image))
      this.setState({
        imageUpdated: false
      });
  };

  isImageValid = image => {
    if (!this.state[image + "Uploaded"]) return false;

    return true;
  };

  createImgConstraints = imgType => {
    let widthLimits, heightLimits, aspectRatioLimits, text;
    if (imgType === "thumbnail") {
      widthLimits = 296;
      heightLimits = 444;
      aspectRatioLimits = widthLimits / heightLimits;
      text = "Image must be min 296px by 444px and have an aspect ratio of 2:3";
    } else if (imgType === "background") {
      widthLimits = 1222;
      heightLimits = 185;
      aspectRatioLimits = [6.59, 6.61];
      text =
        "Image must be minimium 1222px by 185px with aspect ratio between 6.59 - 6.61";
    } else if (imgType === "icon") {
      widthLimits = 220;
      heightLimits = 220;
      aspectRatioLimits = 1;
      text = "Image must be 220px by 220px";
    } else if (imgType === "event") {
      widthLimits = null;
      heightLimits = null;
      aspectRatioLimits = 16 / 9;
      text = "Image must have an aspect ratio of 16:9";
    } else if (imgType === "match") {
      //no limits
      widthLimits = 1440;
      heightLimits = 750;
      aspectRatioLimits = null;
      text = "Image must be min 1440px by 855px";
    } else if (imgType === "title") {
      //no limits
      widthLimits = null;
      heightLimits = null;
      aspectRatioLimits = null;
      text = "";
    } else if (imgType === "mobile") {
      widthLimits = null;
      heightLimits = null;
      aspectRatioLimits = 2.07;
      text = "Image must have aspect ratio of 2.07";
    }

    this.setState({
      imageConstraints: {
        width: widthLimits,
        height: heightLimits,
        aspectRatio: aspectRatioLimits,
        text: text
      }
    });

    return true;
  };

  validateImage = (width, height) => {
    const aspectRatio = width / height;
    let { imageConstraints } = this.state;
    if (!!imageConstraints.aspectRatio) {
      if (imageConstraints.aspectRatio?.length > 1) {
        if (
          aspectRatio < imageConstraints.aspectRatio[0] ||
          aspectRatio > imageConstraints.aspectRatio[1]
        ) {
          return true;
        }
      } else if (
        Math.floor(imageConstraints.aspectRatio) !== Math.floor(aspectRatio)
      ) {
        return true;
      }
    }
    if (!!imageConstraints.width) {
      if (width < imageConstraints.width) return true;
    }
    if (!!imageConstraints.height) {
      if (height < imageConstraints.height) return true;
    }

    return false;
  };

  handleChangeGame = value => {
    this.setState({ type: value.id });
    const oldFilter = queryString.parse(this.props.location.search);

    const newFilter = {
      ...oldFilter,
      type: value.id
    };

    this.props.history.push({
      pathname: "/games",
      search: `?${queryString.stringify(newFilter)}`
    });

    this.setState({
      selectedGame: value.id,
      name: value.name,
      rules: value.rules || "",
      seriesId: value.seriesId,
      settings: [],
      selectedTemplate: [],
      success: "",
      updated: false,
      createGame: false
    });
  };

  addSettingField = () => {
    this.setState({
      settings: [...this.state.settings, { name: "", value: "" }]
    });
  };

  deleteSettingGroup = index => {
    const { settingGroups } = this.state;

    let newSettingGroups = [...settingGroups];

    newSettingGroups.splice(index, 1);

    this.setState({
      settingGroups: newSettingGroups
    });
  };

  renderSettingGroupField = () => {
    const {
      isAddSettingFieldOpen,
      addSetting,
      settingGroups,
      currentIndex
    } = this.state;

    return (
      <DialogKit
        isOpen={isAddSettingFieldOpen}
        onClose={this.handleCloseSettingDialog}
        title={"Create Settings"}
        renderBody={() => (
          <>
            <FormFieldKit
              fullWidth
              label={"Settings Name"}
              placeholder={"Enter settings name"}
              onChange={val => this.setState({ addSetting: val })}
              value={addSetting}
            />
          </>
        )}
        renderFooter={() => (
          <ButtonKit
            fullWidth
            small
            disabled={!addSetting}
            onClick={() => {
              this.setState({
                settingGroups: [
                  ...this.state.settingGroups.slice(0, currentIndex),
                  {
                    ...settingGroups[currentIndex],
                    settings: {
                      ...settingGroups[currentIndex].settings,
                      [addSetting]: ""
                    }
                  },
                  ...this.state.settingGroups.slice(currentIndex + 1)
                ],
                isAddSettingFieldOpen: false,
                addSetting: ""
              });
            }}
          >
            Create
          </ButtonKit>
        )}
      />
    );
  };

  handleCloseSettingDialog = () => {
    this.setState({
      isAddSettingDialogOpen: false,
      isAddSettingFieldOpen: false,
      addSetting: "",
      settings: [{ name: "", value: "" }]
    });
  };

  renderAddSettingDialog = () => {
    const { isAddSettingDialogOpen, addSetting, settings } = this.state;

    return (
      <DialogKit
        isOpen={isAddSettingDialogOpen}
        onClose={this.handleCloseSettingDialog}
        title={"Create Settings"}
        renderBody={() => (
          <>
            <FormFieldKit
              fullWidth
              label={"Settings Name"}
              placeholder={"Enter settings name"}
              onChange={val => this.setState({ addSetting: val })}
              value={addSetting}
            />
            {settings?.map((setting, id) => {
              return (
                <SettingFields id={id}>
                  <SettingField>
                    <FormFieldKit
                      fullWidth
                      id={id}
                      label={
                        <Wrap>
                          Setting {id + 1} Name
                          <img
                            style={{ opacity: 0 }}
                            src={iconX}
                            alt=""
                            width={22}
                            height={22}
                          />
                        </Wrap>
                      }
                      placeholder={`Enter Setting ${id + 1} Name`}
                      onChange={val =>
                        this.setState({
                          settings: [
                            ...settings.slice(0, id),
                            { ...setting, name: val },
                            ...settings.slice(id + 1)
                          ]
                        })
                      }
                      value={setting.name}
                    />
                  </SettingField>
                  <SettingField>
                    <FormFieldKit
                      fullWidth
                      id={id}
                      label={
                        <Wrap>
                          Setting {id + 1} Value
                          <img
                            onClick={() => {
                              let newSettings = [
                                ...settings.slice(0, id),
                                ...settings.slice(id + 1)
                              ];
                              this.setState({
                                settings: newSettings
                              });
                            }}
                            style={{ cursor: "pointer" }}
                            src={iconX}
                            alt=""
                            width={22}
                            height={22}
                          />
                        </Wrap>
                      }
                      placeholder={`Enter Setting ${id + 1} Value`}
                      onChange={val =>
                        this.setState({
                          settings: [
                            ...settings.slice(0, id),
                            { ...setting, value: val },
                            ...settings.slice(id + 1)
                          ]
                        })
                      }
                      value={setting.value}
                    />
                  </SettingField>
                </SettingFields>
              );
            })}
            <Icon onClick={this.addSettingField}>
              <IconCirclePlus width={25} />
            </Icon>
          </>
        )}
        renderFooter={() => (
          <WrapButtons>
            <ButtonKit
              fullWidth
              small
              disabled={
                Object.keys(settings).includes(addSetting) || !addSetting
              }
              onClick={() => {
                const { settingGroups, settings, addSetting } = this.state;
                if (Object.keys(settings).includes(addSetting)) {
                  return;
                }
                let newSettings = {};
                settings.forEach(setting => {
                  newSettings[setting.name] = setting.value;
                });

                this.setState({
                  settingGroups: [
                    ...settingGroups,
                    { name: addSetting, settings: newSettings }
                  ],
                  // settings: [{ name: "", value: "" }],
                  isAddSettingDialogOpen: false,
                  addSetting: ""
                });
                this.handleCloseSettingDialog();
              }}
            >
              Add
            </ButtonKit>
            <ButtonKit
              fullWidth
              small
              appearance={"secondary"}
              color={"red"}
              onClick={this.handleCloseSettingDialog}
            >
              Cancel
            </ButtonKit>
          </WrapButtons>
        )}
      />
    );
  };

  createSeries = () => {
    const { seriesName } = this.state;
    const { allSeries } = this.props;

    if (
      allSeries.filter(series => series.name === seriesName.trim()).length > 0
    ) {
      this.setState({
        seriesError: `Series with the name ${seriesName} already exists`,
        seriesSuccess: ""
      });
      return;
    }
    if (seriesName.trim().length === 0) {
      this.setState({
        seriesError: `Please enter a series name`,
        seriesSuccess: ""
      });
      return;
    }

    let data = new FormData();

    if (seriesName) data.append("name", seriesName);

    api.series
      .create(data)
      .then(() => {
        this.setState({
          seriesSuccess: "Series created!",
          seriesError: ""
        });
      })
      .catch(err => {
        const errData = (err.response && err.response.data) || {};

        this.setState({
          seriesError: errData.error ? errData.error : "Error"
        });
      });
  };

  handleCloseCreateSeriesDialog = () => {
    this.setState({
      createSeries: false,
      seriesName: "",
      seriesSuccess: "",
      seriesError: ""
    });
  };

  renderCreateSeriesDialog = () => {
    const { createSeries, seriesName } = this.state;

    return (
      <DialogKit
        isOpen={createSeries}
        onClose={this.handleCloseCreateSeriesDialog}
        title={"Add Series"}
        renderBody={() => (
          <>
            <FormFieldKit
              fullWidth
              label={"Series Name"}
              placeholder={"Enter new series name"}
              onChange={val => this.setState({ seriesName: val })}
              value={seriesName}
            />
          </>
        )}
        renderFooter={() => (
          <WrapButtons>
            {this.state.seriesError && (
              <TypographyKit color={COLOR.CHERRY_RED}>
                {this.state.seriesError}
              </TypographyKit>
            )}
            {this.state.seriesSuccess && (
              <TypographyKit color={COLOR.GREEN}>
                {this.state.seriesSuccess}
              </TypographyKit>
            )}
            <ButtonKit
              fullWidth
              small
              onClick={() => {
                this.createSeries();
              }}
            >
              Add
            </ButtonKit>
            <ButtonKit
              fullWidth
              small
              appearance={"secondary"}
              color={"red"}
              onClick={this.handleCloseCreateSeriesDialog}
            >
              Cancel
            </ButtonKit>
          </WrapButtons>
        )}
      />
    );
  };

  isSubmitDisabled = () => {
    const {
      name,
      rules,
      seriesId,
      username,
      settingGroups,
      selectedTemplate,
      selectedGame,
      imageUpdated
    } = this.state;
    const { game } = this.props;
    if (selectedGame && !selectedTemplate.id) {
      if (
        name !== game.name ||
        (game.rules && rules !== game.rules) ||
        (!game.rules && rules !== "")
      ) {
        return false;
      }
      if (seriesId !== game.seriesId) {
        return false;
      }
      if (imageUpdated) {
        return false;
      }
    }
    if (selectedGame && selectedTemplate.id) {
      if (
        name !== game.name ||
        (game.rules && rules !== game.rules) ||
        (!game.rules && rules !== "")
      ) {
        return false;
      }
      if (seriesId !== game.seriesId) {
        return false;
      }
      if (imageUpdated) {
        return false;
      }
      if (username !== propValueOr(selectedTemplate, "username")) {
        return false;
      }
      if (!_.isEqual(settingGroups, selectedTemplate.settings)) {
        return false;
      }
    }

    return true;
  };

  updateGame = () => {
    const {
      name,
      rules,
      settingGroups,
      username,
      selectedTemplate,
      applyToAll,
      selectedGame,
      thumbnail,
      icon,
      background,
      event,
      match,
      title,
      mobile,
      seriesId
    } = this.state;

    if (selectedTemplate) {
      let data = new FormData();

      if (name) data.append("name", name);
      if (rules) data.append("rules", rules);
      if (settingGroups) {
        data.append("settingGroups", JSON.stringify(settingGroups));
      }
      if (username) data.append("username", username);
      if (selectedTemplate.id)
        data.append("matchTemplateId", selectedTemplate.id);
      if (applyToAll === true) data.append("applyToAll", applyToAll);
      if (thumbnail) {
        data.append("thumbnail", thumbnail);
        data.append("original", thumbnail);
      }
      if (icon) data.append("icon", icon);
      if (background) data.append("background", background);
      if (event) data.append("event", event);
      if (match) data.append("match", match);
      if (title) data.append("title", title);
      if (mobile) data.append("mobile", mobile);
      if (seriesId) data.append("seriesId", seriesId);
      this.setState({ updating: true });

      api.games
        .update(selectedGame, data)
        .then(() => {
          this.setState({
            updating: false,
            updated: true,
            success: "Game Updated!"
          });
          this.props.fetchGame(this.state.selectedGame);
        })
        .catch(err => {
          const errData = (err.response && err.response.data) || {};

          this.setState({
            generalError: errData.error ? errData.error : "Error",
            loading: false,
            updating: false
          });
        });
    }
  };

  render() {
    const { games, game, permission, allSeries } = this.props;
    const {
      selectedGame,
      selectedTemplate,
      createGame,
      settings,
      updating,
      settingGroups,
      seriesId
    } = this.state;

    const readOnly = !["write", "admin"].includes(permission);

    return (
      <Container>
        {this.renderAddSettingDialog()}
        {this.renderCreateSeriesDialog()}
        {this.renderSettingGroupField()}
        <Content>
          <ListContainer>
            <List>
              {!readOnly && (
                <WrapGameCard
                  onClick={() => {
                    this.setState({ createSeries: true });
                  }}
                >
                  <GameHover />
                  <GameCard
                    src={createSeriesIcon}
                    onError={e => (e.target.src = "")}
                    alt=""
                  />
                </WrapGameCard>
              )}
              {!readOnly && (
                <WrapGameCard
                  onClick={() => {
                    this.setState({ createGame: true, selectedGame: "" });
                  }}
                >
                  <GameHover />
                  <GameCard
                    src={createGameIcon}
                    onError={e => (e.target.src = "")}
                    alt=""
                  />
                </WrapGameCard>
              )}
              {(games || []).map(game => {
                return (
                  <WrapGameCard
                    key={game.id}
                    selected={game.id === selectedGame}
                    onClick={() => this.handleChangeGame(game)}
                  >
                    <WrapCheckmark show={game.id === selectedGame}>
                      <CheckmarkImage src={CheckmarkIcon} />
                    </WrapCheckmark>
                    <GameHover />
                    <GameCard
                      src={propValueOr(
                        game,
                        "Game.imageInfo.original",
                        propValueOr(game, "imageInfo.original")
                      )}
                      onError={e => (e.target.src = "")}
                      alt=""
                    />
                  </WrapGameCard>
                );
              })}
            </List>
          </ListContainer>
          {createGame && <GameCreate {...this.props} />}
          {propValueOr(game, "matchTemplates", null) && (
            <TemplateContainer>
              <FormFieldKit
                fullWidth
                required={true}
                label={`Game Name`}
                placeholder={"Enter description..."}
                value={this.state.name}
                onChange={val => this.setState({ name: val, updated: false })}
                disabled={readOnly}
              />
              <FormFieldKit
                label={"Game Rules (Markdown Compatible)"}
                description={
                  "These are game title specific Rival Rules (users must accept to play in Arenas & Leagues games)"
                }
                fullWidth
                placeholder={"Enter rules..."}
                multiline
                type={"textarea"}
                value={this.state.rules}
                inputProps={{ maxLength: 10000 }}
                onChange={val => this.setState({ rules: val, updated: false })}
              />
              <FormFieldKit
                label={"Game Series"}
                description={"If Game is a part of series"}
                fullWidth
                multiline
                select
                selectOptions={allSeries.map(gameSeries => ({
                  label: gameSeries.name,
                  value: gameSeries.id
                }))}
                value={seriesId}
                onChange={val => {
                  this.setState({ seriesId: val, updated: false });
                }}
                placeholder={seriesId === null ? "Select Series" : seriesId}
              />
              <Title>GAME IMAGES</Title>
              {imageFields.map(field => (
                <WrapInput key={field.key}>
                  <WrapError error={this.state[field.key + "Error"]}>
                    <WrapLabel>
                      <Label>{"Game " + field.key} </Label>
                    </WrapLabel>
                  </WrapError>
                  <Description>{field.description}</Description>
                  {field?.example && (
                    <Example>
                      EXAMPLE of a Game {field.key} image:
                      <img src={field.example} alt="" height={30} />
                    </Example>
                  )}
                  <Input
                    type="file"
                    name={field.key}
                    onChange={e =>
                      this.handleChangeImage(field.key, e.target.files[0])
                    }
                    onBlur={() => this.handleBlurImage(field.key)}
                  />
                  {(this.state[field.key + "Preview"] ||
                    this.props.game.imageInfo[field.key]) && (
                    <PreviewWrap>
                      <Preview
                        src={
                          this.state[field.key + "Preview"]
                            ? this.state[field.key + "Preview"]
                            : `${
                                this.props.game.imageInfo[field.key]
                              }?${new Date().getTime()}`
                        }
                      />
                    </PreviewWrap>
                  )}
                  <WrapError error={this.state[field.key + "Error"]}>
                    {this.state["error" + field.key]}
                  </WrapError>
                </WrapInput>
              ))}
              <WrapTitle>
                <Title>CONSOLE SPECIFIC UPDATES</Title>
              </WrapTitle>
              Select Console to Update
              <ConsoleList>
                {propValueOr(game, "matchTemplates", []).map(template => (
                  <ConsoleCard
                    key={template.id}
                    active={template.id === selectedTemplate.id}
                    onClick={() => {
                      let settingGroups = propValueOr(template, "settings", []);

                      if (propValueOr(template, "metadata", null)) {
                        let exists;
                        if (settingGroups !== []) {
                          exists = settingGroups.find(group =>
                            _.isEqual(group.name, "Default")
                          );
                        }
                        if (!exists) {
                          settingGroups.unshift({
                            name: "Default",
                            settings: template.metadata.settings
                          });
                        }
                      }
                      this.setState({
                        selectedTemplate: template,
                        updated: false,
                        success: "",
                        username: propValueOr(template, "username", ""),
                        settingGroups: settingGroups
                      });
                    }}
                  >
                    <ConsoleImage
                      src={propValueOr(
                        template,
                        "Console.imageInfo.thumbnail",
                        CrossPlatformThumbnail
                      )}
                      onError={e => (e.target.src = "")}
                      alt=""
                    />
                  </ConsoleCard>
                ))}
              </ConsoleList>
              {selectedTemplate.id && (
                <>
                  <Info>
                    <FormFieldKit
                      fullWidth
                      required={true}
                      label={`Game Username (gamertag, playstationId, activisionId, steamId, epicId, nintendoId, riotId, eaId, battlenetId)`}
                      placeholder={"Enter description..."}
                      value={this.state.username}
                      disabled={readOnly}
                      onChange={val =>
                        this.setState({ username: val, updated: false })
                      }
                    />
                  </Info>
                  {settings && (
                    <>
                      <Wrap>
                        <Title>GAME SETTINGS</Title>
                        <Icon
                          onClick={() =>
                            this.setState({ isAddSettingDialogOpen: true })
                          }
                        >
                          <IconCirclePlus width={25} />
                        </Icon>
                      </Wrap>
                      <CheckboxKit
                        label={"Apply Settings To All Consoles"}
                        onChange={() =>
                          this.setState({ applyToAll: !this.state.applyToAll })
                        }
                      />
                      {propValueOr(Object.entries(settingGroups), "length") >
                      0 ? (
                        <WrapPanel>
                          {settingGroups.map((settingGroup, index) => (
                            <div key={index}>
                              <img
                                onClick={() => this.deleteSettingGroup(index)}
                                style={{ cursor: "pointer", float: "right" }}
                                src={iconX}
                                alt=""
                                width={22}
                                height={22}
                              />
                              <Panel
                                trigger={settingGroup.name}
                                open={false}
                                style={{ padding: 20 }}
                              >
                                {Object.entries(settingGroup.settings).map(
                                  (item, id) => (
                                    <SettingFields key={id}>
                                      <SettingField>
                                        <FormFieldKit
                                          key={id}
                                          width={"50%"}
                                          placeholder={"Enter setting..."}
                                          label={
                                            <Wrap
                                              style={{ marginBottom: "-5px" }}
                                            >
                                              <Label>{item[0]}</Label>
                                              <img
                                                onClick={() => {
                                                  let newSettings = [
                                                    ...this.state.settingGroups.slice(
                                                      0,
                                                      index
                                                    ),
                                                    {
                                                      ...settingGroup
                                                    },
                                                    ...this.state.settingGroups.slice(
                                                      index + 1
                                                    )
                                                  ];
                                                  delete newSettings[index]
                                                    .settings[item[0]];
                                                  this.setState({
                                                    settingGroups: newSettings
                                                  });
                                                }}
                                                style={{ cursor: "pointer" }}
                                                src={iconX}
                                                alt=""
                                                width={22}
                                                height={22}
                                              />
                                            </Wrap>
                                          }
                                          onChange={val => {
                                            this.setState({
                                              settingGroups: [
                                                ...this.state.settingGroups.slice(
                                                  0,
                                                  index
                                                ),
                                                {
                                                  ...settingGroup,
                                                  settings: {
                                                    ...settingGroup.settings,
                                                    [item[0]]: val
                                                  }
                                                },
                                                ...this.state.settingGroups.slice(
                                                  index + 1
                                                )
                                              ]
                                            });
                                          }}
                                          value={item[1]}
                                          disabled={readOnly}
                                        />
                                      </SettingField>
                                    </SettingFields>
                                  )
                                )}
                                <Icon
                                  onClick={() => {
                                    this.setState({
                                      currentIndex: index,
                                      isAddSettingFieldOpen: true
                                    });
                                  }}
                                >
                                  <IconCirclePlus width={25} />
                                </Icon>
                              </Panel>
                            </div>
                          ))}
                        </WrapPanel>
                      ) : (
                        <WrapEmpty>
                          No Game Settings. You can add settings by clicking the
                          '+' icon above.
                        </WrapEmpty>
                      )}
                    </>
                  )}
                </>
              )}
              {this.state.generalError && (
                <TypographyKit color={COLOR.CHERRY_RED}>
                  {this.state.generalError}
                </TypographyKit>
              )}
              {this.state.success && (
                <TypographyKit color={COLOR.GREEN}>
                  {this.state.success}
                </TypographyKit>
              )}
              {readOnly && (
                <TypographyKit color={COLOR.CHERRY_RED}>
                  You do not have permissions to update the Rival games list
                </TypographyKit>
              )}
              <Button
                onClick={() => this.updateGame()}
                fullWidth
                small
                disabled={this.isSubmitDisabled() || readOnly}
                preloader={updating}
                appearance={"secondary"}
              >
                UPDATE GAME
              </Button>
            </TemplateContainer>
          )}
        </Content>
      </Container>
    );
  }
}

export default RouteGames;
