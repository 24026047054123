export const FONT_FAMILY_MLS = {
  BOLD: "'DIN Bold', sans-serif", // in psd it is BebasNeueBold
  THICK: "'DIN Medium', sans-serif", // in psd it is BebasNeueBold
  THIN: "'Roboto', sans-serif" // in psd it is SofiaProLight
};
export const FONT_FAMILY_DEFAULT = {
  THICK: "'Barlow Condensed', sans-serif", // in psd it is BebasNeueBold
  THIN: "'Pill Gothic 600mg', sans-serif", // in psd it is SofiaProLight
  CONDENSED: "'Roboto Condensed', sans-serif",
  GOTHIC: "'Pill Gothic 600mg', sans-serif",
  GOTHIC_BOLD: "'Pill Gothic 600mg Bold', sans-serif"
};
export const FONT_FAMILY = FONT_FAMILY_DEFAULT;

export const FONT_SIZE = {
  H1: 60,
  H2: 34,
  H3: 28,
  H4: 22,
  H5: 18,
  H6: 18,
  PARAGRAPH: 16,
  PARAGRAPH_SMALL: 14,
  CAPTION_1: 28,
  CAPTION_2: 14,
  CAPTION_3: 16,
  SMALL: 12,
  LINK: 16,
  LINK_SMALL: 14,
  BUTTON: 14,
  BUTTON_SMALL: 12,
  LABEL: 14,
  FIELD: 14
};

export const FONT_WEIGHT = {
  LIGHT: 300,
  NORMAL: 400,
  MEDIUM: 500,
  BOLD: 600,
  EXTRA_BOLD: 700
};

export const FONT = {
  CAPTION_1: `${FONT_WEIGHT.BOLD} ${FONT_SIZE.CAPTION_1}px / 1.29 ${FONT_FAMILY.THICK}`,
  BUTTON: `${FONT_WEIGHT.MEDIUM} ${FONT_SIZE.BUTTON}px / 1.5 ${FONT_FAMILY.THIN}`,
  BUTTON_SMALL: `${FONT_WEIGHT.BOLD} ${FONT_SIZE.BUTTON_SMALL}px / 1.29 ${FONT_FAMILY.THIN}`,
  PARAGRAPH: `${FONT_WEIGHT.LIGHT} 14px ${FONT_FAMILY.THIN}`
};

// colors were called by zeplin template
export const COLOR_MLS = {
  CHERRY_RED: "#ec1c29",
  ROUGE: "#a5111b",

  NAVY_BLUE: "#053385",
  DARK_BLUE: "#062e5f",
  LIGHT_BLUE: "#6485c1",
  TEXT_LINK: "#005cff",

  GREEN: "#169617",
  YELLOW: "#ffb700",
  YELLOW_2: "#fdc02d", // new color for button
  BLACK: "#111111",
  BLACK_2: "#222222",
  BLACK_3: "#444444",
  GREY: "#888888",
  GREY_2: "#cccccc",
  GREY_3: "#eeeeee",
  GREY_4: "#f2f2f2",
  WHITE: "#ffffff",

  BG_PRIMARY: "#0c141d",
  BG_SECONDARY: "#152431",
  BG_THIRD: "#0c141d",
  BG_ACCENT: "#0E1922",
  BG_INPUT: "#0e1a25",
  BG_HEADER_NOTAUTH: "#192756",
  BG_FOOTER_NOTAUTH: "#192756",
  BG_HEADER: "#111111",
  BG_FOOTER: "#000000",
  FG_PRIMARY: "#ffffff",
  FG_SECONDARY: "#a5111b",
  FG_ACCENT: "#053385",
  FG_INPUT: "#bdbdbd",
  TEXT: "#ffffff", //BLACK
  TEXT_2: "#ffffff", //BLACK_2
  TEXT_3: "#ffffff", //BLACK_3
  TEXT_4: "#ffffff", //GREY
  TEXT_5: "#ffffff", //GREY_2
  LINK: "#80acff", //light blue
  BORDER: "#0C141D",
  BORDER_2: "#152431",
  BLUE: "#0253e2",

  // button kit appearance
  BUTTON_DISABLED_BG_PRIMARY: "#EEEEEE",
  BUTTON_DISABLED_BG_SECONDARY: "transparent",
  BUTTON_DISABLED_BORDER_PRIMARY: "#CCCCCC",
  BUTTON_DISABLED_BORDER_SECONDARY: "#CCCCCC",
  BUTTON_DISABLED_FONT_COLOR: "#888888",

  // button kit color
  BUTTON_DEFAULT_BG_PRIMARY: "#053385",
  BUTTON_HOVER_BG_PRIMARY: "#062e5f"
};

const COLOR_DEFAULT = {
  CHERRY_RED: "#ec1c29",
  ROUGE: "#a5111b",
  RIVAL_RED: "#FD174F",

  NAVY_BLUE: "#053385",
  DARK_BLUE: "#062e5f",
  LIGHT_BLUE: "#6485c1",
  TEXT_LINK: "#005cff",

  LIGHT_GREEN: "#2DC12F",
  GREEN: "#169617",
  YELLOW: "#ffb700",
  YELLOW_2: "#fdc02d", // new color for button
  BLACK: "#111111",
  BLACK_2: "#222222",
  BLACK_3: "#444444",
  GREY: "#888888",
  GREY_2: "#cccccc",
  GREY_3: "#eeeeee",
  GREY_4: "#f2f2f2",
  GREY_5: "#C7C9CC",
  WHITE: "#ffffff",

  BG_PRIMARY: "#0c141d",
  BG_SECONDARY: "#152431",
  BG_THIRD: "#0c141d",
  BG_ACCENT: "#0E1922",
  BG_INPUT: "#0e1a25",
  BG_DATE: "#0F1A24",
  BG_HEADER_NOTAUTH: "#111111",
  BG_FOOTER_NOTAUTH: "#000000",
  BG_HEADER: "#111111",
  BG_FOOTER: "#000000",
  FG_PRIMARY: "#ffffff",
  FG_SECONDARY: "#a5111b",
  FG_ACCENT: "#053385",
  FG_INPUT: "#bdbdbd",
  TEXT: "#ffffff", //BLACK
  TEXT_2: "#ffffff", //BLACK_2
  TEXT_3: "#ffffff", //BLACK_3
  TEXT_4: "#ffffff", //GREY
  TEXT_5: "#ffffff", //GREY_2
  LINK: "#80acff", //light blue
  BORDER: "#0C141D",
  BORDER_2: "#152431",
  BORDER_3: "#0E1A25",
  BLUE: "#0253e2",
  TEAL: "#52C3D0",

  // button kit appearance
  BUTTON_DISABLED_BG_PRIMARY: "#EEEEEE",
  BUTTON_DISABLED_BG_SECONDARY: "transparent",
  BUTTON_DISABLED_BORDER_PRIMARY: "#CCCCCC",
  BUTTON_DISABLED_BORDER_SECONDARY: "#CCCCCC",
  BUTTON_DISABLED_FONT_COLOR: "#888888",

  // button kit color
  BUTTON_DEFAULT_BG_PRIMARY: "#053385",
  BUTTON_HOVER_BG_PRIMARY: "#062e5f"
};

export const COLOR = COLOR_DEFAULT;

export const BOX_SHADOW = {
  PRIMARY: "0 1px 15px rgba(0, 0, 0, 0.1);",
  SECONDARY: "0 0 4px 0 rgba(0,0,0,0.1)",
  THIRD: "0 2px 8px 0 rgba(0,0,0,0.5)"
};
