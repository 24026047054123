import { TOURNAMENT_ACTIONS } from "./constants";
import { api } from "../../index";
import { propValueOr } from "../../helpers/common";

export const fetchTournamentAction = token => dispatch => {
  dispatch({
    type: TOURNAMENT_ACTIONS.fetch.request
  });

  return api.admin.tournament
    .get(token)
    .then(resp => {
      dispatch({
        type: TOURNAMENT_ACTIONS.fetch.success,
        payload: propValueOr(resp, "data", {})
      });
    })
    .catch(err => {
      dispatch({ type: TOURNAMENT_ACTIONS.fetch.error });
      throw err;
    });
};

export const refreshTournamentAction = token => dispatch => {
  dispatch({
    type: TOURNAMENT_ACTIONS.refresh.request
  });
  return api.admin.tournament
    .get(token)
    .then(resp => {
      dispatch({
        type: TOURNAMENT_ACTIONS.refresh.success,
        payload: propValueOr(resp, "data", {})
      });
    })
    .catch(err => {
      dispatch({ type: TOURNAMENT_ACTIONS.refresh.error });
      throw err;
    });
};

export const listTournamentAction = token => dispatch => {
  dispatch({
    type: TOURNAMENT_ACTIONS.list.request
  });

  return api.tournament
    .list(token)
    .then(resp => {
      dispatch({
        type: TOURNAMENT_ACTIONS.list.success,
        payload: propValueOr(resp, "data", {})
      });
    })
    .catch(err => {
      dispatch({ type: TOURNAMENT_ACTIONS.list.error });
      throw err;
    });
};

export const fetchPlayersAction = id => dispatch => {
  dispatch({
    type: TOURNAMENT_ACTIONS.players.request
  });

  return api.admin.tournament.users
    .list(id)
    .then(resp => {
      dispatch({
        type: TOURNAMENT_ACTIONS.players.success,
        payload: propValueOr(resp, "data", [])
      });
    })
    .catch(err => {
      dispatch({ type: TOURNAMENT_ACTIONS.players.error });
      throw err;
    });
};

export const fetchTemplate = query => dispatch => {
  dispatch({
    type: TOURNAMENT_ACTIONS.template.request
  });

  return api.templates
    .get(query)
    .then(resp => {
      dispatch({
        type: TOURNAMENT_ACTIONS.template.success,
        payload: propValueOr(resp, "data", {})
      });
    })
    .catch(err => {
      dispatch({ type: TOURNAMENT_ACTIONS.template.error });
      throw err;
    });
};

export const fetchTemplates = query => dispatch => {
  dispatch({
    type: TOURNAMENT_ACTIONS.templates.request
  });

  api.templates
    .list(query)
    .then(resp => {
      dispatch({
        type: TOURNAMENT_ACTIONS.templates.success,
        payload: propValueOr(resp, "data", {})
      });
    })
    .catch(err => {
      dispatch({ type: TOURNAMENT_ACTIONS.templates.error });
      throw err;
    });
};

export const resetTournamentAction = () => dispatch => {
  dispatch({
    type: TOURNAMENT_ACTIONS.reset
  });
};
