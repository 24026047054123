import React, { Component } from "react";
import {
  Container,
  Section,
  Header,
  Card,
  TimeSubtitle,
  Users,
  User,
  WrapAvatar,
  Avatar,
  Info,
  Username,
  Score,
  UserScore,
  Type,
  Update,
  UpdateText,
  Token,
  Arrow,
  List,
  Player
} from "./styled";
import { cloneDeep } from "lodash";
import moment from "moment";
import { connect } from "react-redux";
import { safePropValueOr } from "../../../../helpers/common";
import placeholderUserImg from "../../../../static/images/default-user.jpg";
import { Link } from "react-router-dom";
import FormFieldKit from "../../../../components/kit/Fields/FormField/FormField";
import { setSeasonProperty } from "../../../../store/leagues/actions";
import DialogKit from "../../../../components/kit/Dialog/DialogKit";
import ButtonKit from "../../../../components/kit/Button/ButtonKit";

class Schedule extends Component {
  constructor(props) {
    super();

    this.state = {
      matchIndex: 0,
      editing: { id: null, field: null },
      selectPlayers: false,
      omit: []
    };
  }

  handleChangeMatchField = (id, key, value) => {
    const newMatches = this.props.matches.map(match => {
      if (match?.id === id) {
        const newMatch = cloneDeep(match);
        newMatch[key] = value;
        return newMatch;
      }
      return match;
    });
    this.props.setSeasonProperty("matches", newMatches);
  };

  handleSubmit = e => {
    e.preventDefault();
    this.setState({ editing: { id: null, field: null } });
  };

  updateFieldDialog = () => {
    const match = this.props.matches?.find(m => m.id === this.state.editing.id);
    const isValid = match?.startDate;

    return (
      <DialogKit
        onClose={() =>
          isValid ? this.setState({ editing: { id: null, field: null } }) : null
        }
        isOpen={!!this.state.editing?.id && !!this.state.editing?.field}
        renderCustom={() => {
          return (
            <Update>
              <UpdateText>Update {this.state.editing?.field}</UpdateText>
              <FormFieldKit
                fullWidth
                dateTimePicker
                value={match?.startDate}
                onChange={value =>
                  this.handleChangeMatchField(match?.id, "startDate", value)
                }
                inputFocus
                onSubmit={this.handleSubmit}
                errorText={
                  !isValid ? "Please select a valid date and time" : null
                }
              />
              <ButtonKit
                onSubmit={this.handleSubmit}
                type={"submit"}
                appearance="secondary"
                shape={"rounded"}
                color={"rival_red"}
                onClick={() =>
                  this.setState({ editing: { id: null, field: null } })
                }
                disabled={!isValid}
              >
                Continue
              </ButtonKit>
            </Update>
          );
        }}
      />
    );
  };

  renderUserSelect = (match, right = false) => {
    const { selectPlayers, omit } = this.state;
    return (
      <List right={right}>
        {(this.props.season?.users || [])
          .filter(u => !(omit || [])?.includes(u?.userId))
          .map(u => (
            <Player
              right={right}
              onClick={() => {
                const users = cloneDeep(match?.users);
                users.splice(
                  users?.findIndex(usr => usr?.userId === selectPlayers?.user),
                  1,
                  u
                );
                this.handleChangeMatchField(
                  selectPlayers?.match,
                  "users",
                  users
                );
              }}
            >
              <WrapAvatar small>
                <Avatar
                  small
                  src={safePropValueOr(
                    u,
                    "team.imageInfo.thumbnail",
                    safePropValueOr(
                      u,
                      "user.imageInfo.thumbnail",
                      placeholderUserImg
                    )
                  )}
                  onError={e => (e.target.src = placeholderUserImg)}
                />
              </WrapAvatar>
              <Info>
                <Username>{u?.user?.username}</Username>
              </Info>
            </Player>
          ))}
      </List>
    );
  };

  renderMatches = () => {
    const { selectPlayers } = this.state;
    const { season } = this.props;
    const matches = season?.matches?.sort(
      (a, b) => a?.round - b?.round || a?.matchId - b?.matchId
    );

    return (
      <Section>
        <Header>
          {season?.name} Matches
          <Token>Match ID</Token>
        </Header>
        {matches?.map(match => {
          const players = match?.users;
          const editable = !match?.matchId || !match?.match;

          return (
            <Card
              {...((match?.match?.token ||
                match?.match?.id ||
                match?.matchId) && {
                as: Link,
                to: match?.match?.token ? `/game/${match?.match?.token}` : {},
                key: match?.match?.token || match?.match?.id || match?.matchId
              })}
            >
              <TimeSubtitle
                editable={editable}
                onClick={
                  editable
                    ? () =>
                        this.setState({
                          editing: { id: match?.id, field: "date" }
                        })
                    : undefined
                }
              >
                {moment(match?.startDate).format("MMM DD @ hh mm A")}
              </TimeSubtitle>
              {season?.status !== "initialized" && (
                <>
                  <Users>
                    {players?.[0] && (
                      <User
                        outcome={players?.[0]?.outcome}
                        editable={editable}
                        onClick={
                          editable
                            ? () =>
                                this.setState({
                                  selectPlayers: !selectPlayers
                                    ? {
                                        match: match?.id,
                                        user: players?.[0]?.userId
                                      }
                                    : null,
                                  omit: [
                                    players?.[0]?.userId,
                                    players?.[1]?.userId
                                  ]
                                })
                            : undefined
                        }
                      >
                        <WrapAvatar>
                          <Avatar
                            src={safePropValueOr(
                              players?.[0],
                              "team.imageInfo.thumbnail",
                              safePropValueOr(
                                players?.[0],
                                "imageInfo.thumbnail",
                                placeholderUserImg
                              )
                            )}
                            onError={e => (e.target.src = placeholderUserImg)}
                          />
                        </WrapAvatar>
                        <Info>
                          <Username>{players?.[0]?.user?.username}</Username>
                        </Info>
                        {editable && <Arrow isLeft={true} />}
                        {selectPlayers &&
                          selectPlayers?.match === match?.id &&
                          selectPlayers?.user === players?.[0]?.userId &&
                          this.renderUserSelect(match)}
                      </User>
                    )}
                    <Score>
                      {players?.[0]?.score != null
                        ? `${players?.[0]?.score} -`
                        : ""}{" "}
                      {players?.[1]?.score}
                    </Score>

                    {players?.[1] ? (
                      <User
                        outcome={players?.[1]?.outcome}
                        editable={editable}
                        onClick={
                          editable
                            ? () =>
                                this.setState({
                                  selectPlayers: !selectPlayers
                                    ? {
                                        match: match?.id,
                                        user: players?.[1]?.userId
                                      }
                                    : null,
                                  omit: [
                                    players?.[0]?.userId,
                                    players?.[1]?.userId
                                  ]
                                })
                            : undefined
                        }
                      >
                        <WrapAvatar>
                          <Avatar
                            src={safePropValueOr(
                              players?.[1],
                              "team.imageInfo.thumbnail",
                              safePropValueOr(
                                players?.[1],
                                "imageInfo.thumbnail",
                                placeholderUserImg
                              )
                            )}
                          />
                        </WrapAvatar>
                        <Info>
                          <Username>{players?.[1]?.user?.username}</Username>
                        </Info>
                        {editable && <Arrow />}
                        {selectPlayers &&
                          selectPlayers?.match === match?.id &&
                          selectPlayers?.user === players?.[1]?.userId &&
                          this.renderUserSelect(match, true)}
                      </User>
                    ) : (
                      <User outcome={"L"}>
                        <WrapAvatar>
                          <Avatar src={placeholderUserImg} />
                        </WrapAvatar>
                        <Info>
                          <Username>BYE</Username>
                        </Info>
                      </User>
                    )}
                  </Users>
                  <Users mobile>
                    {players?.[0] && (
                      <User outcome={players?.[0]?.outcome}>
                        <WrapAvatar>
                          <Avatar
                            src={safePropValueOr(
                              players?.[0],
                              "team.imageInfo.thumbnail",
                              safePropValueOr(
                                players?.[0],
                                "imageInfo.thumbnail",
                                placeholderUserImg
                              )
                            )}
                            onError={e => (e.target.src = placeholderUserImg)}
                          />
                        </WrapAvatar>
                        <Username>{players?.[0]?.username}</Username>
                        <UserScore>{players?.[0]?.score}</UserScore>
                      </User>
                    )}
                    {players?.[1] ? (
                      <User outcome={players?.[1]?.outcome}>
                        <WrapAvatar>
                          <Avatar
                            src={safePropValueOr(
                              players?.[1],
                              "team.imageInfo.thumbnail",
                              safePropValueOr(
                                players?.[1],
                                "imageInfo.thumbnail",
                                placeholderUserImg
                              )
                            )}
                          />
                        </WrapAvatar>
                        <Username>{players?.[1]?.username}</Username>
                        <UserScore>{players?.[1]?.score}</UserScore>
                      </User>
                    ) : (
                      <User outcome={"L"}>
                        <WrapAvatar>
                          <Avatar src={placeholderUserImg} />
                        </WrapAvatar>
                        <Username>BYE</Username>
                      </User>
                    )}
                  </Users>
                </>
              )}
              <Type>{match?.match?.token || match?.token}</Type>
            </Card>
          );
        })}
      </Section>
    );
  };

  render() {
    const { matches } = this.props;

    return (
      <>
        Click on the area of a match you want to edit. Note you can currently
        only edit the start time and the players in the match. You cannot edit a
        match that has already started.
        <br />
        <br />
        <Container>
          {(matches || []).length > 0 ? (
            this.renderMatches()
          ) : (
            <div>NO MATCHES</div>
          )}
        </Container>
        {this.updateFieldDialog()}
      </>
    );
  }
}

const mapStateToProps = state => ({
  matches: state.leaguesState?.season?.matches || [],
  season: state.leaguesState?.season || {}
});

const mapDispatchToProps = {
  setSeasonProperty
};

export default connect(mapStateToProps, mapDispatchToProps)(Schedule);
