import React, { Component } from "react";
import FormFieldKit from "../../../components/kit/Fields/FormField/FormField";
import Button from "../../../components/kit/Button/ButtonKit";
import {
  Container,
  Title,
  Header,
  BackIcon,
  Row,
  WrapIconButton,
  RemoveIcon,
  Label,
  WrapButtons,
  Description,
  Status,
  ID,
  SelectLabel,
  NavItem,
  NavBar,
  Game,
  Console,
  LinkIcon,
  OtherIcon,
  Subtitle,
  WrapLabel,
  IconDelete,
  IconWrap
} from "./styled";
import backArrow from "../../../static/icons/chevron-arrow.svg";
import { connect } from "react-redux";
import {
  fetchLeague,
  fetchSeason,
  resetSeasonState,
  setSeasonProperty,
  setSeasonState,
  updateSeason
} from "../../../store/leagues/actions";
import Schedule from "./Schedule/Schedule";
import Matches from "./Matches/Matches";
import moment from "moment";
import { fetchTemplates } from "../../../store/games/actions";
import ButtonKit from "../../../components/kit/Button/ButtonKit";
import DialogKit from "../../../components/kit/Dialog/DialogKit";
import { getNavItems, getSeasonErrors } from "./constants";
import Images from "./Images/Images";
import imageCompression from "browser-image-compression";
import { compressionOptions } from "./Images/constants";
import SelectKit from "../../../components/kit/Fields/Select/SelectKit";
import { api } from "../../..";
import { debounce, isEqual, cloneDeep, maxBy } from "lodash";
import { Link } from "react-router-dom";
import { COLOR } from "../../../constants/theme";

// Icons & images
import removeSVG from "../../../static/icons/remove.svg";
import crossPlatform from "../../../static/images/cross-platform.svg";
import iconNewWindow from "../../../static/icons/icon-new-window.svg";
import rb from "../../../static/icons/rival_coin.svg";
// import rp from "../../../static/icons/rival_points.svg";
import Panel from "../../../components/kit/Panel/Panel";
import Players from "./Players";
import Uploader from "../Uploader/Uploader";
import DeleteIcon from "../../../static/icons/remove.svg";
import UndoIcon from "../../../static/icons/undo.svg";

class SeasonUpdate extends Component {
  constructor(props) {
    super(props);

    this.getTournaments = debounce(this.getTournaments, 500, {
      leading: true,
      trailing: true
    });

    this.state = {
      isAddSettingDialogOpen: false,
      addSetting: "",
      template: null,
      search: "",
      tournaments: [],
      selected: null,
      currentItem: "details",
      deletePrize: false
    };
  }

  componentDidMount() {
    this.getSeason();
  }

  componentDidUpdate(prevProps) {}

  componentWillUnmount() {
    this.props.resetSeasonState();
  }

  getTournaments = () => {
    const { organizationId } = this.props;

    api.tournament
      .list({
        organizationId,
        q: this.state.search,
        limit: 25,
        page: 1,
        status: "initialized"
      })
      .then(resp =>
        this.setState({
          tournaments: (resp?.data?.docs || []).map(t => ({
            label: (
              <SelectLabel>
                {t?.name}
                <ID>#{t.id}</ID>
              </SelectLabel>
            ),
            value: t.id
          }))
        })
      )
      .catch(err =>
        console.log(
          "error fetching tournaments: ",
          err?.response?.message || err?.message || err
        )
      );
  };

  getSeason = () => {
    this.props
      .fetchSeason(this.props.match?.params?.token)
      .then(() => this.setState({ initialSeason: this.props.season }));
  };

  updateSeason = () => {
    const {
      initialSeason
      // firstPlace,
      // secondPlace
    } = this.state;
    const { season } = this.props;
    const {
      // name,
      description,
      startDate,
      endDate,
      minPlayers,
      maxPlayers,
      registrationStart,
      registrationEnd,
      schedule,
      settings,
      image,
      tournamentId,
      seasonMatches,
      survey,
      walletFee,
      entryFee,
      // coins,
      // prizes,
      inviteToken,
      prizeImage,
      prizeTitle,
      prizeDescription
      // matches
    } = season || {};
    this.setState({ loading: true });

    const newEntryFee = parseInt(entryFee * 100);

    const body = {
      // ...((firstPlace !== propValueOr(season, "prizes", [])[0] ||
      //   secondPlace !== propValueOr(season, "prizes", [])[1]) && {
      //   prizes: JSON.stringify([
      //     firstPlace || propValueOr(season, "prizes", [])[0] || 500,
      //     secondPlace || propValueOr(season, "prizes", [])[1] || 250
      //   ])
      // }),
      ...(newEntryFee !== initialSeason.entryFee && {
        entryFee: newEntryFee && !isNaN(newEntryFee) ? newEntryFee : null
      }),
      ...(walletFee !== initialSeason.walletFee && {
        walletFee: walletFee || null
      }),
      //eslint-disable-next-line eqeqeq
      ...(survey != initialSeason.survey && { survey }),
      //eslint-disable-next-line eqeqeq
      ...(inviteToken != initialSeason.inviteToken && { inviteToken }),
      ...(initialSeason.description !== description && { description }),
      ...(initialSeason.startDate !== startDate && {
        startDate: moment(startDate).format("YYYY-MM-DD HH:mm:ssZZ")
      }),
      ...(initialSeason.endDate !== endDate && {
        endDate: moment(endDate).format("YYYY-MM-DD HH:mm:ssZZ")
      }),
      ...(initialSeason.minPlayers !== minPlayers && {
        minPlayers: minPlayers > 1 ? minPlayers : 2
      }),
      ...(initialSeason.maxPlayers !== maxPlayers && {
        maxPlayers: maxPlayers
      }),
      ...(initialSeason.tournamentId !== tournamentId && { tournamentId }),
      ...(!moment(registrationStart).isSame(
        moment(initialSeason.registrationStart)
      ) &&
        initialSeason.registrationStart !== registrationStart && {
          registrationStart: moment(registrationStart).format(
            "YYYY-MM-DD HH:mm:ssZZ"
          )
        }),
      ...(!moment(registrationEnd).isSame(
        moment(initialSeason.registrationEnd)
      ) &&
        initialSeason.registrationEnd !== registrationEnd && {
          registrationEnd: moment(registrationEnd).format(
            "YYYY-MM-DD HH:mm:ssZZ"
          )
        }),
      ...(image instanceof File && {
        image: image
      }),
      ...(this.state.deletePrize
        ? { prize: "delete" }
        : (prizeImage instanceof File ||
            prizeTitle !== initialSeason?.imageInfo?.prize?.title ||
            prizeDescription !==
              initialSeason?.imageInfo?.prize?.description) && {
            ...(prizeImage instanceof File && { prize: prizeImage }),
            ...(prizeTitle !== initialSeason?.imageInfo?.prize?.title && {
              prizeTitle
            }),
            ...(prizeDescription !==
              initialSeason?.imageInfo?.prize?.description && {
              prizeDescription
            })
          }),
      ...(!isEqual(initialSeason.settings, settings) && {
        settings: JSON.stringify(settings || {})
      }),
      ...(!isEqual(initialSeason.schedule, schedule) && {
        schedule: JSON.stringify(schedule)
      }),
      ...(season?.status === "in progress" &&
        seasonMatches?.length > 0 && {
          matches: JSON.stringify(seasonMatches)
        })
      // ...(season?.status === "in progress" && !isEqual(initialSeason.matches, matches) && {
      //   matches
      // })
    };
    let data = new FormData();
    for (let key in body) {
      if (key === "tournamentId" || body[key] !== undefined) {
        data.append(key, body[key]);
      }
    }

    this.props
      .updateSeason(this.props.match?.params?.token, data)
      .then(() => {
        this.props.resetSeasonState();
        this.getSeason();
        this.setState({
          loading: false,
          success: "Season updated!",
          deletePrize: false
        });
      })
      .catch(err => {
        const data = (err.response && err.response.data) || {};

        if (data.error) {
          this.setState({ generalError: data.error, loading: false });
        }
      });
  };

  handleChangeField = (propName, val) => {
    this.props.setSeasonProperty(propName, val);
  };

  handleChangeImage = async (propName, val) => {
    let compressedImage = await imageCompression(val, compressionOptions);
    compressedImage.lastModifiedDate = new Date();
    const newFile = new File([compressedImage], compressedImage.name);
    this.props.setSeasonProperty(propName, newFile);
  };

  addMatch = (initial = null) => {
    if (this.props.season?.status === "in progress") {
      if (!this.props.season?.created) {
        this.props.setSeasonProperty("created", true);
      }
      let seasonMatches = cloneDeep(this.props.season?.seasonMatches);
      if (!seasonMatches) {
        seasonMatches = [];
      }

      //add match
      const newMatch = {
        ...(initial
          ? { ...initial }
          : {
              startDate: moment(new Date()),
              settings: {
                settings: this.props.season?.settings || {}
              }
            }),
        create: true,
        round:
          (Math.max(
            maxBy(this.props.season?.matches, m => m?.round)?.round || 0,
            maxBy(seasonMatches, m => (m?.delete ? 0 : m?.round))?.round || 0
          ) || 0) + 1
      };
      seasonMatches.push(newMatch);
      this.handleChangeField("seasonMatches", seasonMatches);

      return;
    }

    let schedule = cloneDeep(this.props.season?.schedule);
    if (!schedule) {
      schedule = [];
    }

    //add match
    schedule.push({
      ...(initial
        ? { ...initial }
        : {
            startDate: moment(new Date()),
            settings: {
              settings:
                this.props.season?.settings || this.state.defaultSettings || {}
            }
          }),
      round: schedule.length + 1
    });
    this.handleChangeField("schedule", schedule);
  };

  handleMatch = match => {
    if (this.props.season?.status === "in progress") {
      const seasonMatches = cloneDeep(this.props.season?.seasonMatches || []);
      const index = seasonMatches.findIndex(m => m.id === match.id);
      if (index !== -1) {
        seasonMatches.splice(index, 1, match);
      } else {
        seasonMatches.push(match);
      }
      // seasonMatches.splice(matches.findIndex(m => m.id === match.id), 1, match);
      this.handleChangeField("seasonMatches", seasonMatches);
      return;
    }

    let schedule = this.props.season?.schedule;
    if (!schedule) {
      schedule = [];
    }

    //edit existing match or add new one
    if (match.index !== undefined && match.index !== null) {
      schedule[match.index] = { ...match };
      this.setState({ schedule: schedule });
    }
  };

  deleteMatch = (match, index) => {
    if (this.props.season?.status === "in progress") {
      if (!this.props.season?.deleted) {
        this.props.setSeasonProperty("deleted", true);
      }
      let seasonMatches = cloneDeep(this.props.season?.seasonMatches || []);
      const mIndex = seasonMatches.findIndex(
        m => m.id === match.id || m.round === match.round
      );
      const deleteMatch = { round: match.round, delete: true };

      if (mIndex !== -1) {
        seasonMatches.splice(mIndex, 1, deleteMatch);
      } else {
        seasonMatches.push(deleteMatch);
      }
      seasonMatches = seasonMatches.map((m, i) => {
        if (m.round > match.round) {
          m.round = m.round - 1;
        }
        return m;
      });

      this.handleChangeField("seasonMatches", seasonMatches);
      return;
    }

    let schedule = this.props.season?.schedule;
    if (!schedule) {
      schedule = [];
    }

    //edit existing match or add new one
    if (index !== undefined && index !== null && schedule.length > 1) {
      if (index > -1) {
        schedule.splice(index, 1);
        //reset the round numbers for each item
        schedule = schedule.map((m, i) => ({ ...m, round: i + 1 }));
      }
      this.handleChangeField("schedule", schedule);
    }
  };

  handleCloseSettingDialog = () => {
    this.setState({ isAddSettingDialogOpen: false, addSetting: "" });
  };

  renderAddSettingDialog = () => {
    const { isAddSettingDialogOpen, addSetting } = this.state;
    const { season } = this.props;
    const settings = season?.settings || {};
    return (
      <DialogKit
        isOpen={isAddSettingDialogOpen}
        onClose={this.handleCloseSettingDialog}
        title={"Add Setting"}
        renderBody={() => (
          <>
            <FormFieldKit
              inputProps={{ autoFocus: true }}
              fullWidth
              label={"Setting Name"}
              placeholder={"Enter new setting name"}
              onChange={val => this.setState({ addSetting: val })}
              value={addSetting}
            />
          </>
        )}
        renderFooter={() => (
          <WrapButtons>
            <ButtonKit
              fullWidth
              small
              disabled={
                Object.keys(settings).includes(addSetting) || !addSetting
              }
              onClick={() => {
                const { addSetting } = this.state;
                if (Object.keys(settings).includes(addSetting)) {
                  return;
                }

                this.props.setSeasonState({
                  settings: { ...settings, [addSetting]: "" }
                });
                this.handleCloseSettingDialog();
              }}
            >
              Add
            </ButtonKit>
            <ButtonKit
              fullWidth
              small
              appearance={"secondary"}
              color={"red"}
              onClick={this.handleCloseSettingDialog}
            >
              Cancel
            </ButtonKit>
          </WrapButtons>
        )}
      />
    );
  };

  renderDetails = () => {
    const {
      search,
      tournaments,
      selected
      // firstPlace,
      // secondPlace
    } = this.state;
    const { season } = this.props;

    const {
      description,
      startDate,
      endDate,
      minPlayers,
      maxPlayers,
      registrationStart,
      registrationEnd,
      isTeamSeason,
      status,
      tournamentName,
      index,
      walletFee,
      entryFee,
      survey,
      inviteToken,
      imageInfo
    } = season || {};

    return (
      <>
        <FormFieldKit
          label={"Name of the Season (Limit 30 Characters)"}
          fullWidth
          placeholder={"Enter name... (ex. Season 1)"}
          required
          value={`Season ${index}`}
          inputProps={{ maxLength: 30, autoFocus: true }}
          onChange={val => this.handleChangeField("name", val)}
          disabled
        />
        <FormFieldKit
          label={
            "Description of the Season (*Markdown Compatible) -- Limit 2500 Characters"
          }
          fullWidth
          placeholder={"Enter description..."}
          required
          multiline
          value={description}
          inputProps={{ maxLength: 2500 }}
          onChange={val => this.handleChangeField("description", val)}
        />
        <Row>
          <FormFieldKit
            dateTimePicker
            fullWidth
            required
            label={"Season Start Date & Time"}
            onChange={val => {
              this.handleChangeField("startDate", val);
            }}
            value={startDate}
            disabled={status !== "initialized"}
          />
          <FormFieldKit
            dateTimePicker
            fullWidth
            required
            label={"Season End Date & Time"}
            onChange={val => {
              this.handleChangeField("endDate", val);
            }}
            value={endDate}
            disabled={status === "complete"}
          />
        </Row>
        <Row>
          <FormFieldKit
            dateTimePicker
            fullWidth
            label={"Registration Start Time (optional)"}
            description={"Defaults to opening registration immediately"}
            onChange={val => this.handleChangeField("registrationStart", val)}
            value={registrationStart}
            disabled={status !== "initialized"}
          />
          <FormFieldKit
            dateTimePicker
            fullWidth
            label={"Registration End Time (optional)"}
            description={"Defaults to Season Start Time"}
            onChange={val => this.handleChangeField("registrationEnd", val)}
            value={registrationEnd}
            disabled={status !== "initialized"}
          />
        </Row>
        <Row>
          <FormFieldKit
            label={isTeamSeason ? "Minimum Teams" : "Minimum Players"}
            description={`Minimum ${
              isTeamSeason ? "teams" : "players"
            } required for season to start`}
            fullWidth
            required
            disabled={status !== "initialized"}
            placeholder={
              isTeamSeason ? "Enter minimum teams" : "Enter minimum players"
            }
            type={"number"}
            min={"2"}
            max={maxPlayers && maxPlayers.toString()}
            value={minPlayers}
            onChange={val => {
              const newVal = parseInt(val);
              this.handleChangeField("minPlayers", newVal);
            }}
          />
          <FormFieldKit
            label={
              isTeamSeason
                ? "Maximum Teams"
                : "Maximum Players (blank = no cap)"
            }
            description={`Maximum ${
              isTeamSeason ? "teams" : "players"
            } allowed to register for season`}
            fullWidth
            disabled={status !== "initialized"}
            placeholder={"Leave blank for no limit on registrations"}
            type={"number"}
            min={
              ((minPlayers || minPlayers === 0) && minPlayers.toString()) || "2"
            }
            value={maxPlayers}
            onChange={val => {
              const newVal = parseInt(val);
              this.handleChangeField(
                "maxPlayers",
                isNaN(newVal) ? null : newVal
              );
            }}
          />
        </Row>
        <br />
        <Panel
          trigger={"PRIZES & ENTRIES"}
          customMargin={"0px"}
          // triggerStyle={{ padding: "15px 25px" }}
          open={false}
        >
          <br />
          <Subtitle>PRIZE INFORMATION</Subtitle>
          <br />
          <WrapLabel>
            <Label>Prize Image</Label>
            <div>
              {imageInfo?.prize?.image && (
                <IconWrap
                  onClick={() => {
                    this.setState(s => ({ deletePrize: !s.deletePrize }));
                    // this.setState({ preview: null, isDeleted: !isDeleted });
                  }}
                >
                  <IconDelete
                    src={this.state.deletePrize ? UndoIcon : DeleteIcon}
                  />
                  {this.state.deletePrize && "Undo Delete"}
                </IconWrap>
              )}
            </div>
          </WrapLabel>
          <Description>
            Upload a prize image to show in the points and prizes section
          </Description>
          {/* <br/> */}
          <Uploader
            key={"prize"}
            // label={"Prize Image"}
            onChange={file => this.handleChangeImage("prizeImage", file)}
            constraints={{
              width: [100, 600],
              height: [56, 340],
              aspectRatio: [{ value: 1.7 }, { value: 1.8 }]
            }}
            imgLabels={["16:9 Aspect Ratio"]}
            imageDimensions={[160, 90]}
            defaultImage={imageInfo?.prize?.image || undefined}
            disabled={this.state.deletePrize}
            onReset={() => this.handleChangeImage("prizeImage", null)}
          />
          <br />
          <FormFieldKit
            label={"Prize Title"}
            name={"prizeTitle"}
            onChange={val => this.handleChangeField("prizeTitle", val)}
            value={season?.prizeTitle || imageInfo?.prize?.title}
            placeholder={"Enter prize title..."}
            fullWidth
            disabled={
              (!season?.prizeImage && !imageInfo?.prize?.image) ||
              this.state.deletePrize
            }
          />
          <br />
          <FormFieldKit
            label={"Prize Description"}
            name={"prizeDescription"}
            onChange={val => this.handleChangeField("prizeDescription", val)}
            value={season?.prizeDescription || imageInfo?.prize?.description}
            placeholder={"Enter prize description..."}
            multiline
            fullWidth
            disabled={
              (!season?.prizeImage && !imageInfo?.prize?.image) ||
              this.state.deletePrize
            }
          />
          <br />
          <Subtitle>Invite Code (optional)</Subtitle>
          <br />
          <FormFieldKit
            label={"Invite Code (will be required for tournament entry)"}
            fullWidth
            placeholder={"Enter code..."}
            inputProps={{ maxLength: 50 }}
            value={inviteToken}
            onChange={val => {
              const newVal = val || null;
              this.handleChangeField("inviteToken", newVal);
            }}
          />
          <br />
          <Subtitle>ENTRY FEE (optional)</Subtitle>
          <br />
          <FormFieldKit
            label={"Entry Fee (Leave blank if non paid tournament)"}
            fullWidth
            placeholder={"Enter fee to join tournament..."}
            type={"number"}
            min={"0"}
            max={"1000"}
            value={entryFee}
            disabled={!!walletFee && walletFee > 0}
            onChange={val => {
              const newVal = val;
              this.handleChangeField("entryFee", isNaN(newVal) ? null : newVal);
            }}
          />
          <br />
          <Subtitle
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start"
            }}
          >
            RIVALS FEE
            <OtherIcon
              src={rb}
              width={20}
              height={20}
              style={{ marginLeft: "5px" }}
              alt=""
            />
            (optional)
          </Subtitle>
          <br />
          <FormFieldKit
            label={"Rivals Entry Fee (Leave blank if non paid tournament)"}
            fullWidth
            placeholder={"Enter Rivals fee to join tournament..."}
            type={"number"}
            min={"0"}
            max={"1000"}
            value={walletFee}
            disabled={!!entryFee && entryFee > 0}
            onChange={val => {
              const newVal = val;
              this.handleChangeField(
                "walletFee",
                isNaN(newVal) ? null : newVal
              );
            }}
          />
          <br />
          <Subtitle>SURVEY (optional)</Subtitle>
          <br />
          <FormFieldKit
            label={"Survey (Only for non-paid)"}
            fullWidth
            placeholder={"Enter link to embed..."}
            value={survey}
            onChange={val => {
              const newVal = val || null;
              this.handleChangeField("survey", newVal);
            }}
          />
          <br />
          {/* <Subtitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            
          }}
        >
          RIVAL POINTS
          <OtherIcon
            src={rp}
            width={20}
            height={20}
            style={{ marginLeft: "5px" }}
            alt=""
          />
          (optional)
        </Subtitle>
        <br/>
        <FormFieldKit
          label={"1st place (Rival Points awarded)"}
          fullWidth
          required
          placeholder={"Enter number of points..."}
          type={"number"}
          min={(secondPlace && secondPlace.toString()) || "0"}
          max={"10000"}
          value={firstPlace}
          onChange={val => {
            const newVal = parseInt(val);
            this.handleChangeField("firstPlace", isNaN(newVal) ? null : newVal);
          }}
        />
        <FormFieldKit
          label={"2nd Place (Rival Points awarded)"}
          required
          fullWidth
          placeholder={"Enter number of points..."}
          type={"number"}
          min={"0"}
          max={(firstPlace && firstPlace.toString()) || "10000"}
          value={secondPlace}
          onChange={val => {
            const newVal = parseInt(val);
            this.handleChangeField("secondPlace", isNaN(newVal) ? null : newVal);
          }}
        /> */}
        </Panel>
        <br />
        <Label style={{ margin: "10px 0 5px" }}>
          Playoffs / Finals Tournament ID (optional)
        </Label>
        <Description style={{ fontSize: "12px" }}>
          Select a Tournament to use for the playoffs or finals of this season.
          If you haven't made the tournament yet, you can make it in the{" "}
          <Link
            style={{ color: COLOR.LIGHT_BLUE, marginLeft: "5px" }}
            to={"/tournaments/create"}
          >
            tournament creator
          </Link>
          .
        </Description>
        <Row>
          <SelectKit
            disabled={status === "complete"}
            fullWidth
            placeholder={"Select a tournament..."}
            value={selected || tournamentName}
            onChange={val => {
              this.handleChangeField("tournamentId", val);
              this.setState({
                selected: tournaments.find(t => t.value === val)?.label
              });
            }}
            select
            options={tournaments}
            searchValue={search}
            onChangeSearchValue={val =>
              this.setState({ search: val }, this.getTournaments)
            }
            searchPlaceholder={"Search by name, id, or token..."}
          />
          <WrapIconButton
            disabled={!selected && !tournamentName}
            onClick={() => {
              this.handleChangeField("tournamentId", null);
              this.handleChangeField("tournamentName", null);
              this.setState({ selected: null, search: null });
            }}
          >
            <RemoveIcon src={removeSVG} />
          </WrapIconButton>
        </Row>
      </>
    );
  };

  renderSchedule = () => {
    const { season } = this.props;
    const errors = getSeasonErrors(season);
    return (
      <Schedule
        season={season}
        schedule={season?.schedule}
        handleMatch={this.handleMatch}
        addMatch={this.addMatch}
        deleteMatch={this.deleteMatch}
        errors={errors}
        setSeasonProperty={this.props.setSeasonProperty}
        setSeasonState={this.props.setSeasonState}
        isUpdate
      />
    );
  };

  renderMatches = () => {
    const { errors } = this.state;
    const { season } = this.props;

    return (
      <Matches
        season={season}
        schedule={season?.schedule}
        handleMatch={this.handleMatch}
        addMatch={this.addMatch}
        deleteMatch={this.deleteMatch}
        errors={errors}
        isUpdate
      />
    );
  };

  renderNavbar = () => {
    const { currentItem } = this.state;

    return (
      <NavBar>
        {getNavItems(this.props.season || {}).map(item => (
          <NavItem
            isActive={currentItem === item}
            onClick={() => this.setState({ currentItem: item })}
            key={item}
          >
            {["players", "teams"].includes(item)
              ? `${item} (${this.props.season?.users?.filter(u => u?.isPrimary)
                  ?.length || 0})`
              : item}
          </NavItem>
        ))}
      </NavBar>
    );
  };

  render() {
    const { loading, currentItem, initialSeason } = this.state;
    const { history, season, match } = this.props;
    const env = process.env.NODE_ENV;
    const link = `leagues/${match?.params?.slug}`;
    const { status } = season || {};
    return (
      <Container>
        <Header>
          <BackIcon src={backArrow} onClick={() => history.goBack()} />
          <Title>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={
                env === "development"
                  ? `http://localhost:3000/${link}?season=${season?.token}`
                  : `https://${
                      env === "production" &&
                      !window?.location?.hostname?.includes("luigi")
                        ? ""
                        : "luigi."
                    }rivalgames.com/${link}?season=${season?.token}`
              }
            >
              Season {season?.index}
              <LinkIcon src={iconNewWindow} width={12} height={12} />
            </a>{" "}
            -
            {initialSeason?.game !== undefined && (
              <Game src={initialSeason?.game?.imageInfo?.title} />
            )}
            {initialSeason?.console !== undefined && (
              <Console
                src={
                  initialSeason?.console?.imageInfo?.thumbnail || crossPlatform
                }
              />
            )}
          </Title>
          <Status status={status}>{status}</Status>
        </Header>
        {this.renderNavbar()}
        <br />
        {currentItem === "details" ? (
          this.renderDetails()
        ) : currentItem === "images" ? (
          <Images
            onChangeField={(key, val) => this.handleChangeImage(key, val)}
            formState={season}
            onResetField={key => this.props.setSeasonProperty(key, undefined)}
          />
        ) : currentItem === "schedule" ? (
          this.renderSchedule()
        ) : currentItem === "matches" ? (
          this.renderMatches()
        ) : ["players", "teams"].includes(currentItem) ? (
          <Players season={season} fetchSeason={this.props.fetchSeason} />
        ) : null}
        <br />
        <br />
        {!["players", "teams"].includes(currentItem) && (
          <Button
            onClick={() => this.updateSeason()}
            fullWidth
            shape={"rounded"}
            color={"rival_red"}
            width={"200px"}
            disabled={
              !this.props.user.organizationId ||
              !getSeasonErrors(season, true) ||
              (isEqual(this.state.initialSeason, season) &&
                !this.state.deletePrize)
            }
            preloader={loading}
          >
            UPDATE SEASON
          </Button>
        )}
        {this.renderAddSettingDialog()}
      </Container>
    );
  }
}

const mapStateProps = state => ({
  league: state.leaguesState.league,
  season: state.leaguesState.season,
  templates: state.gamesState.templates,
  isFetchingTemplates: state.gamesState.isFetchingTemplates,
  organizationId: state.user?.info?.organizationId
});

const mapDispatchToProps = {
  fetchLeague,
  setSeasonState,
  fetchTemplates,
  setSeasonProperty,
  resetSeasonState,
  fetchSeason,
  updateSeason
};

export default connect(mapStateProps, mapDispatchToProps)(SeasonUpdate);
