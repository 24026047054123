import React, { Component } from "react";
import {
  Container,
  Row,
  Title,
  Description,
  Round,
  Line,
  WrapTitle,
  Wrap,
  Icon,
  WrapButtons,
  // WrapButtonsRow,
  Label
} from "./styled";
import FormFieldKit from "../../../../../components/kit/Fields/FormField/FormField";
import { propValueOr, safePropValueOr } from "../../../../../helpers/common";
import {
  bracketSizeOptions,
  teamBracketSizeOptions,
  eliminationTypeOptions,
  timeOptions,
  readyOptions,
  bestOfOptions,
  verificationOptions,
  ffaVerificationOptions
} from "../../constants";
import IconCirclePlus from "../../../../../components/kit/Icon/IconCirclePlus";
import DialogKit from "../../../../../components/kit/Dialog/DialogKit";
import ButtonKit from "../../../../../components/kit/Button/ButtonKit";
import iconX from "../../../../../static/icons/remove.svg";
import moment from "moment";
import { getRoundTitle } from "./constants";
import { api } from "../../../../../index";
import TypographyKit from "../../../../../components/kit/Typography/TypographyKit";
import { COLOR } from "../../../../../constants/theme";

const _ = require("lodash");

class Stage extends Component {
  constructor(props) {
    super(props);

    this.state = this.initialState(props);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state !== prevState) {
      this.props.handleStage(this.getStrippedState());
    }
  }

  initialState = props => {
    const { stage, formState } = props;

    let stageSettings = [];

    const matchTemplate = propValueOr(
      formState,
      "matchTemplateOptions",
      []
    ).find(o => o.value === propValueOr(formState, "matchTemplateId"));

    let metadata = propValueOr(matchTemplate, "metadata.settings", null);
    let settingGroups = propValueOr(matchTemplate, "settings", null);

    if (settingGroups) {
      for (let key in settingGroups) {
        stageSettings.push(settingGroups[key]);
      }
      if (metadata) {
        const exists = stageSettings.find(group =>
          _.isEqual(group, {
            name: "Default",
            settings: metadata
          })
        );

        if (!exists) {
          stageSettings.unshift({
            name: "Default",
            settings: metadata
          });
        }
      }
    }

    let settings = {};
    if (typeof stage.settings[0] !== "object") {
      settings = stage.settings;
    }

    return {
      index: stage.index,
      name: stage.name,
      stage: stage.stage,
      type: stage.type,
      startDate: stage.startDate,
      minPlayers: stage.minPlayers,
      maxPlayers: stage.maxPlayers,
      maxRounds: stage.maxRounds,
      maxBrackets: stage.maxBrackets,
      prizes: stage.prizes,
      roundInfo: stage.roundInfo,
      validated: stage.validated,
      isAddSettingDialogOpen: false,
      isSaveSettingDialogOpen: false,
      settingsId: null,
      addSetting: "",
      settings: settings,
      settingGroups: stageSettings,
      ...(stage.id && { id: stage.id })
    };
  };

  getStrippedState = () => {
    const {
      validated,
      isAddSettingDialogOpen,
      addSetting,
      ...rest
    } = this.state;
    return rest;
  };

  validateFields = () => {
    const { type, stage, minPlayers, maxRounds, validated } = this.state;

    const valid =
      !!type &&
      !!minPlayers &&
      !!stage &&
      !(stage > this.props.stages.length + 1) &&
      // (minPlayers <= this.props.minimum) &&
      (type === "swiss" ? !!maxRounds : true);

    if (valid && !validated) {
      this.setState({ validated: true });
    } else if (!valid && validated) {
      this.setState({ validated: false });
    }
    return valid;
  };

  shouldError = (s, err) => {
    if (s && err && err.includes(s)) {
      return true;
    }
  };
  updateRounds = rounds => {
    let roundInfo = {};
    for (let i = 1; i <= rounds; i++) {
      roundInfo[i] = {};
      // set default to moderator for FFA
      if (this.state.type === "free for all") {
        roundInfo[i]["Verification"] = "moderator";
      } else {
        roundInfo[i]["Verification"] = "image";
      }
    }
    this.setState({
      roundInfo: roundInfo
    });
  };

  revertSettingsChanges = () => {
    const { stage } = this.props;
    const { settingsId } = this.state;

    let stageSettings = [];
    if (stage.settingGroups) {
      for (let key in stage.settingGroups) {
        stageSettings.push(stage.settingGroups[key]);
      }
    }

    this.setState({
      settingGroups: stageSettings,
      settings: stageSettings[settingsId].settings
    });
  };

  updateSettings = () => {
    const { settings, settingsId, settingGroups } = this.state;

    let newSettingGroups = [...settingGroups];

    newSettingGroups[settingsId].settings = settings;

    this.setState({ settingGroups: newSettingGroups });

    const { formState } = this.props;

    const { matchTemplateId } = formState;
    let data = new FormData();

    data.append("settings", JSON.stringify(newSettingGroups));

    api.matchTemplates
      .update(matchTemplateId, data)
      .then(() =>
        this.setState({
          updating: false,
          updated: true,
          success: "Settings Updated!"
        })
      )
      .catch(err => {
        const errData = (err.response && err.response.data) || {};

        this.setState({
          generalError: errData.error ? errData.error : "Error",
          loading: false,
          updating: false
        });
      });
  };

  saveNewSettings = () => {
    const { settings, newSettingsName, settingGroups } = this.state;

    let newSettingGroups = [...settingGroups];
    let newSettingsId = newSettingGroups.length;

    newSettingGroups.push({ name: newSettingsName, settings: settings });

    this.setState({ settingGroups: newSettingGroups });

    const { formState } = this.props;

    const { matchTemplateId } = formState;
    let data = new FormData();

    data.append("settings", JSON.stringify(newSettingGroups));

    api.matchTemplates
      .update(matchTemplateId, data)
      .then(() =>
        this.setState({
          updating: false,
          updated: true,
          isSaveSettingDialogOpen: false,
          settingsId: newSettingsId.toString(),
          success: "Settings saved!"
        })
      )
      .catch(err => {
        const errData = (err.response && err.response.data) || {};

        this.setState({
          generalError: errData.error ? errData.error : "Error",
          loading: false,
          updating: false
        });
      });
  };

  renderSaveSettingDialog = () => {
    const { isSaveSettingDialogOpen, newSettingsName } = this.state;
    return (
      <DialogKit
        isOpen={isSaveSettingDialogOpen}
        onClose={this.handleCloseSettingDialog}
        title={"Save Settings"}
        renderBody={() => (
          <>
            <FormFieldKit
              fullWidth
              label={"Settings Name"}
              placeholder={"Enter new settings name"}
              onChange={val =>
                this.setState({ newSettingsName: val, success: "" })
              }
              value={newSettingsName}
            />
          </>
        )}
        renderFooter={() => (
          <WrapButtons>
            <ButtonKit
              fullWidth
              small
              disabled={false}
              onClick={this.saveNewSettings}
            >
              Save
            </ButtonKit>
            <ButtonKit
              fullWidth
              small
              appearance={"secondary"}
              color={"red"}
              onClick={this.handleCloseSettingDialog}
            >
              Cancel
            </ButtonKit>
          </WrapButtons>
        )}
      />
    );
  };

  handleCloseSettingDialog = () => {
    this.setState({ isAddSettingDialogOpen: false, addSetting: "" });
  };

  renderAddSettingDialog = () => {
    const { isAddSettingDialogOpen, addSetting, settings } = this.state;
    return (
      <DialogKit
        isOpen={isAddSettingDialogOpen}
        onClose={this.handleCloseSettingDialog}
        title={"Add Setting"}
        renderBody={() => (
          <>
            <FormFieldKit
              fullWidth
              label={"Setting Name"}
              placeholder={"Enter new setting name"}
              onChange={val => this.setState({ addSetting: val, success: "" })}
              value={addSetting}
            />
          </>
        )}
        renderFooter={() => (
          <WrapButtons>
            <ButtonKit
              fullWidth
              small
              disabled={
                Object.keys(settings).includes(addSetting) || !addSetting
              }
              onClick={() => {
                const { settings, addSetting } = this.state;
                if (Object.keys(settings).includes(addSetting)) {
                  return;
                }

                this.setState({ settings: { ...settings, [addSetting]: "" } });
                this.handleCloseSettingDialog();
              }}
            >
              Add
            </ButtonKit>
            <ButtonKit
              fullWidth
              small
              appearance={"secondary"}
              color={"red"}
              onClick={this.handleCloseSettingDialog}
            >
              Cancel
            </ButtonKit>
          </WrapButtons>
        )}
      />
    );
  };

  render() {
    const { minimum, stages, formState } = this.props;
    const {
      stage,
      type,
      name,
      startDate,
      minPlayers,
      maxPlayers,
      maxRounds,
      maxBrackets,
      roundInfo,
      settings,
      settingsId,
      settingGroups
    } = this.state;

    const { errors, clickedNext } = formState;

    let settingOptions;

    if (settingGroups) {
      settingOptions = settingGroups.map((item, id) => {
        if (settingsId === null && _.isEqual(settings, item.settings)) {
          this.setState({ settingsId: id.toString() });
        }
        return {
          label: item.name,
          value: id.toString()
        };
      });
      settingOptions.unshift({ label: "Create New Setting...", value: "new" });
    } else {
      settingOptions = [{ label: "Create New Setting...", value: "new" }];
    }

    return (
      <Container>
        {this.renderAddSettingDialog()}
        {this.renderSaveSettingDialog()}
        <FormFieldKit
          label={"Stage Name (optional - Limit 50 Characters)"}
          description={
            "Stage name not required. If entered, it will appear in the Tournament Schedule Box"
          }
          fullWidth
          placeholder={"Enter name..."}
          value={name}
          inputProps={{ maxLength: 50 }}
          onChange={val => val.length < 50 && this.setState({ name: val })}
        />
        <FormFieldKit
          label={"Number of Stage"}
          fullWidth
          required
          placeholder={"Enter the stage number"}
          type={"number"}
          min={"1"}
          max={`${stages.length + 1}`}
          value={stage}
          disabled
          onChange={val => {
            this.setState({ stage: parseInt(val) });
          }}
        />
        {formState.type === "scheduled" && (
          <FormFieldKit
            dateTimePicker
            fullWidth
            label={"Stage Start Time (optional)"}
            description={`If left blank, this stage will start upon ${
              stage === 1 ? "tournament start" : "end of previous stage"
            }`}
            placeholder={"Enter a start time..."}
            onChange={dt => {
              this.setState({ startDate: dt });
            }}
            value={startDate}
            errorText={
              startDate &&
              clickedNext &&
              (safePropValueOr(errors, "Stage.startDate") ||
                (this.shouldError(
                  stage,
                  safePropValueOr(errors, "Stage.stageStart")
                ) &&
                  safePropValueOr(errors, "Stage.stageStart")))
            }
          />
        )}
        <FormFieldKit
          label={"Elimination Type"}
          description={
            <div>
              Single Elimination: A single game or a best of series -
              elimination by round
              <br />
              Double Elimination: A single game or a best of series -
              elimination by two losses
              <br />
              Swiss Style: Multiple rounds vs random opponents - no eliminations
              by round
              <br />
              Free for all (FFA): Multiple rounds for position based games
              (battle royale, racing, etc.) - no eliminations by round
            </div>
          }
          fullWidth
          multiline
          required
          select
          selectOptions={eliminationTypeOptions}
          value={safePropValueOr(
            eliminationTypeOptions.find(opt => type === opt.value),
            "label",
            undefined
          )}
          onChange={val => {
            this.setState({
              type: val,
              maxPlayers: null,
              minPlayers: 2,
              maxBrackets:
                val === "swiss" || val === "free for all" ? 1 : maxBrackets,
              maxRounds:
                val === "swiss" || val === "free for all" ? maxRounds : null
            });
            this.updateRounds(
              val === "swiss" || val === "free for all"
                ? maxRounds
                : val.includes("elimination")
                ? 9
                : 1
            );
          }}
          errorText={
            !type && clickedNext && safePropValueOr(errors, "Stage.nullType")
          }
          placeholder={"Select an Elimination Type"}
        />
        {type === "single elimination" || type === "double elimination" ? (
          <>
            <Row>
              <FormFieldKit
                label={"Bracket Size"}
                fullWidth
                required
                select
                selectOptions={
                  formState.teamSize
                    ? teamBracketSizeOptions
                    : bracketSizeOptions
                }
                placeholder={"Select a bracket size"}
                value={
                  formState.teamSize
                    ? safePropValueOr(
                        teamBracketSizeOptions.find(
                          opt => maxPlayers === opt.value
                        ),
                        "label",
                        undefined
                      )
                    : safePropValueOr(
                        bracketSizeOptions.find(
                          opt => maxPlayers === opt.value
                        ),
                        "label",
                        undefined
                      )
                }
                onChange={val => {
                  this.setState({
                    maxPlayers: val,
                    minPlayers: !val ? 2 : val / 2 + 1
                  });
                  this.updateRounds(val ? Math.log(val) / Math.log(2) : 7);
                }}
                onBlur={() => {
                  if (maxPlayers !== null && maxPlayers < minPlayers)
                    this.setState({ maxPlayers: minPlayers });
                }}
              />
              <FormFieldKit
                label={`Minimum ${
                  formState.teamSize ? "Teams" : "Players"
                } For Stage To Start`}
                fullWidth
                required
                placeholder={
                  formState.teamSize
                    ? "Enter amount of teams"
                    : "Enter amount of players"
                }
                type={"number"}
                min={minimum || `${maxPlayers / 2 + 1}`}
                max={maxPlayers && maxPlayers.toString()}
                value={minPlayers}
                errorText={
                  clickedNext &&
                  (formState.minPlayers < minPlayers
                    ? safePropValueOr(errors, "Stage.min")
                    : "")
                }
                onChange={val => {
                  const newVal = parseInt(val);
                  this.setState({ minPlayers: newVal });
                }}
                onBlur={() => {
                  const min = maxPlayers / 2 + 1;
                  if (minPlayers > maxPlayers && maxPlayers !== null)
                    this.setState({ minPlayers: maxPlayers });
                  else if (minPlayers < min) this.setState({ minPlayers: min });
                }}
              />
            </Row>
            <FormFieldKit
              label={"Number of Separate Brackets In This Stage (optional)"}
              description={
                "Multiple brackets results in multiple stage winners. Leave blank for no limit on number of brackets"
              }
              disabled
              fullWidth
              placeholder={"Enter max number of brackets"}
              type={"number"}
              value={maxBrackets}
              onChange={val => {
                const newVal = parseInt(val);
                this.setState({ maxBrackets: isNaN(newVal) ? null : newVal });
              }}
              errorText={
                clickedNext && safePropValueOr(errors, "Stage.maxBrackets")
              }
            />
          </>
        ) : type === "swiss" || type === "free for all" ? (
          <>
            <Row>
              <FormFieldKit
                label={`Minimum ${
                  formState.teamSize ? "Teams" : "Players"
                } Required For Stage`}
                fullWidth
                required
                placeholder={
                  formState.teamSize
                    ? "Enter minimum teams"
                    : "Enter minimum players"
                }
                type={"number"}
                min={minimum || 2}
                max={maxPlayers && maxPlayers.toString()}
                errorText={
                  clickedNext &&
                  (formState.minPlayers < minPlayers
                    ? safePropValueOr(errors, "Stage.min")
                    : "")
                }
                value={minPlayers}
                onChange={val => {
                  const newVal = parseInt(val);
                  this.setState({ minPlayers: newVal });
                }}
              />
              <FormFieldKit
                label={`Maximum ${
                  formState.teamSize ? "Teams" : "Players"
                } Allowed In Stage`}
                fullWidth
                placeholder={"Leave blank for no limit"}
                type={"number"}
                value={maxPlayers || ""}
                onChange={val => {
                  const newVal = parseInt(val);
                  this.setState({ maxPlayers: isNaN(newVal) ? null : newVal });
                }}
              />
            </Row>
            <FormFieldKit
              label={"Number of Rounds"}
              fullWidth
              required
              placeholder={"Enter the number of rounds"}
              type={"number"}
              min={"1"}
              value={maxRounds || ""}
              onChange={val => {
                this.setState({ maxRounds: parseInt(val) });
                this.updateRounds(val);
              }}
            />
            <FormFieldKit
              label={"Number of Separate Brackets In This Stage"}
              description={
                "Multiple brackets results in multiple stage winners (blank = unlimited)"
              }
              errorText={
                clickedNext && safePropValueOr(errors, "Stage.maxBrackets")
              }
              fullWidth
              placeholder={"Enter max number of brackets"}
              type={"number"}
              value={maxBrackets}
              onChange={val => {
                const newVal = parseInt(val);
                this.setState({ maxBrackets: isNaN(newVal) ? null : newVal });
              }}
            />
          </>
        ) : (
          <></>
        )}
        {type &&
          roundInfo &&
          Object.keys(roundInfo).map((round, index) => {
            const roundTitle = getRoundTitle(
              round,
              type,
              maxPlayers,
              Object.keys(roundInfo)?.length
            );
            return (
              <Round key={index + round}>
                <WrapTitle>
                  <Title round>{roundTitle}</Title>
                  <Line />
                </WrapTitle>
                {formState.type === "scheduled" &&
                  (formState.mode !== "game-driven" ||
                    type === "free for all") && (
                    <FormFieldKit
                      dateTimePicker
                      fullWidth
                      label={`${roundTitle} Start Date & Time (optional)`}
                      description={
                        "Leave blank to start immediately after prior round is complete"
                      }
                      placeholder={"Click to set a specific start date & time"}
                      onChange={dt => {
                        if (dt) {
                          let info = roundInfo;
                          info[round].date = moment(dt).format(
                            "YYYY-MM-DD HH:mm:ssZZ"
                          );
                          this.setState({ roundInfo: info });
                        } else {
                          let info = roundInfo;
                          delete info[round].date;
                          this.setState({ roundInfo: info });
                        }
                      }}
                      value={
                        roundInfo[round]?.date
                          ? moment(roundInfo[round].date).toISOString()
                          : null
                      }
                      errorText={
                        clickedNext &&
                        ((this.shouldError(
                          round,
                          safePropValueOr(errors, "Stage.rounds")
                        ) &&
                          safePropValueOr(errors, "Stage.rounds")) ||
                          safePropValueOr(errors, `Stage.round${round}Start`) ||
                          safePropValueOr(
                            errors,
                            `Stage.round${round}Tournament`
                          ))
                      }
                    />
                  )}
                <FormFieldKit
                  label={"Ready Required?"}
                  description={
                    "Set whether the players have to (1): Go to the game page to start the game (this is standard), or (2): Click a 'ready' button in order to start the game"
                  }
                  fullWidth
                  multiline
                  required
                  select
                  selectOptions={readyOptions}
                  value={safePropValueOr(
                    readyOptions.find(opt =>
                      roundInfo[round]
                        ? roundInfo[round].ready
                          ? true === opt.value
                          : false === opt.value
                        : false === opt.value
                    ),
                    "value",
                    undefined
                  )}
                  onChange={val => {
                    let info = roundInfo;
                    info[round].ready = val;
                    this.setState({ roundInfo: info });
                  }}
                  placeholder={"Select Ready Required"}
                  errorText={
                    roundInfo[round].ready === undefined &&
                    clickedNext &&
                    safePropValueOr(errors, "Stage.readyRequired")
                  }
                />
                <Row>
                  {roundInfo[round].ready ? "Play Window" : "Forfeit Clock"}
                </Row>
                <Description>
                  {roundInfo[round].ready
                    ? "Players must ready-up and enter scores before this amount of time passes, otherwise the winner will be selected by the system"
                    : "Players must ready-up (go to the game page) before this amount of time passes, otherwise they will forfeit the game"}
                </Description>
                <Row key={index}>
                  <FormFieldKit
                    type="number"
                    label={
                      roundInfo[round].ready
                        ? `Game Ends After`
                        : "Forfeit After"
                    }
                    placeholder={"15"}
                    fullWidth
                    onChange={f => {
                      let info = roundInfo;
                      let forfeitArr = info[round].forfeit
                        ? info[round].forfeit.split(",")
                        : ["15", "minutes"];
                      const val =
                        isNaN(parseInt(f)) || parseInt(f) <= 0 ? "" : f;
                      forfeitArr[0] = val;
                      info[round].forfeit = forfeitArr.join(",");
                      if (!val) {
                        delete info[round].forfeit;
                      }
                      this.setState({ roundInfo: info });
                    }}
                    onBlur={() => {
                      // If user enters null value, reset forfeit to default (15 minutes)
                      let info = roundInfo;
                      if (info[round].forfeit) {
                        let forfeitArr = info[round].forfeit.split(",");
                        if (!forfeitArr[0] || !parseInt(forfeitArr[0])) {
                          delete info[round].forfeit;
                          this.setState({ roundInfo: info });
                        }
                      }
                    }}
                    value={
                      propValueOr(roundInfo[round], "forfeit", "").split(
                        ","
                      )[0] || ""
                    }
                  />
                  <FormFieldKit
                    select
                    selectOptions={timeOptions}
                    label={"Time"}
                    placeholder={"min"}
                    fullWidth
                    onChange={f => {
                      let info = roundInfo;
                      let forfeitArr = info[round].forfeit
                        ? info[round].forfeit.split(",")
                        : ["15", "minutes"];
                      forfeitArr[1] = f;
                      info[round].forfeit = forfeitArr.join(",");
                      this.setState({ roundInfo: info });
                    }}
                    value={
                      propValueOr(roundInfo[round], "forfeit", "").split(
                        ","
                      )[1] || "minutes"
                    }
                  />
                </Row>
                <Row>
                  <FormFieldKit
                    label={`Best Of Series`}
                    description={
                      "How many games per round? The winner of the round will be determined by who wins the majority of the games in the series."
                    }
                    select
                    selectOptions={bestOfOptions}
                    multiline
                    fullWidth
                    placeholder={"Single Game Series (standard)"}
                    onChange={val => {
                      let info = roundInfo;
                      info[round]["Best Of"] = val;
                      if (!val) {
                        delete info[round]["Best Of"];
                      }
                      this.setState({ roundInfo: info });
                    }}
                    value={
                      roundInfo[round] ? roundInfo[round]["Best Of"] : null
                    }
                  />
                </Row>
                <Row>
                  <FormFieldKit
                    label={`Result Verification Method for Matches`}
                    description={
                      "What kind of result verification? Matches will not complete until verified with the chosen method."
                    }
                    select
                    selectOptions={
                      type === "free for all"
                        ? ffaVerificationOptions
                        : verificationOptions
                    }
                    multiline
                    fullWidth
                    placeholder={
                      "Score Entry w/ No Pic Upload (Same winner entered = advance, standard challenge process)"
                    }
                    onChange={val => {
                      let info = roundInfo;
                      info[round]["Verification"] = val;
                      if (!val) {
                        delete info[round]["Verification"];
                      }
                      this.setState({ roundInfo: info });
                    }}
                    value={
                      roundInfo[round] ? roundInfo[round]["Verification"] : null
                    }
                  />
                </Row>
              </Round>
            );
          })}
        {settingGroups && (
          <>
            <Wrap>
              <Title>
                GAME SETTINGS (
                {propValueOr(
                  propValueOr(formState, "matchTemplateOptions", []).find(
                    o => o.value === propValueOr(formState, "matchTemplateId")
                  ),
                  "label",
                  propValueOr(formState, "Game.name")
                )}
                )
              </Title>
              {!(propValueOr(Object.entries(settingGroups), "length") > 0) && (
                <Icon
                  onClick={() =>
                    this.setState({ isAddSettingDialogOpen: true })
                  }
                >
                  <IconCirclePlus width={25} />
                </Icon>
              )}
            </Wrap>
            <Wrap>
              <FormFieldKit
                label={"Select Game settings"}
                description={"Select existing or create new settings"}
                fullWidth
                multiline
                select
                selectOptions={settingOptions}
                value={settingsId}
                onChange={val => {
                  if (val === "new") {
                    this.setState({
                      isAddSettingDialogOpen: true,
                      settingsId: "new"
                    });
                  } else {
                    this.setState({
                      settingsId: val,
                      settings: settingGroups[val].settings,
                      success: "",
                      updated: false
                    });
                  }
                }}
                placeholder={"Select game settings"}
              />
            </Wrap>
            <>
              {Object.entries(settings).map((item, index) => (
                <FormFieldKit
                  key={index}
                  fullWidth
                  placeholder={"Enter setting..."}
                  label={
                    <Wrap style={{ marginBottom: "-5px" }}>
                      <Label>{item[0]}</Label>
                      <img
                        onClick={() => {
                          const newSettings = {
                            ...this.state.settings
                          };
                          delete newSettings[item[0]];
                          this.setState({ settings: newSettings });
                        }}
                        style={{ cursor: "pointer" }}
                        src={iconX}
                        alt=""
                        width={22}
                        height={22}
                      />
                    </Wrap>
                  }
                  onChange={val => {
                    this.setState({
                      settings: { ...settings, [item[0]]: val },
                      success: ""
                    });
                  }}
                  value={item[1]}
                />
              ))}
              {settingsId !== null && (
                <Wrap>
                  <Icon
                    onClick={() =>
                      this.setState({ isAddSettingDialogOpen: true })
                    }
                  >
                    <IconCirclePlus width={25} />
                  </Icon>
                </Wrap>
              )}
            </>
            {this.state.success && (
              <TypographyKit color={COLOR.GREEN}>
                {this.state.success}
              </TypographyKit>
            )}
            {/* {settingsId !== null &&
              settingsId !== "new" &&
              !_.isEqual(
                propValueOr(settingGroups[settingsId], "settings", null),
                settings
              ) && (
                <WrapButtonsRow>
                  <ButtonKit
                    fullWidth
                    small
                    shape={"rounded"}
                    width={"200px"}
                    appearance={"secondary"}
                    color={"rival_red"}
                    onClick={this.revertSettingsChanges}
                  >
                    Discard
                  </ButtonKit>
                  <ButtonKit
                    fullWidth
                    small
                    shape={"rounded"}
                    width={"200px"}
                    disabled={false}
                    onClick={this.updateSettings}
                  >
                    Save Changes
                  </ButtonKit>
                </WrapButtonsRow>
              )} */}
            {/* {settingsId === "new" && (
              <WrapButtonsRow>
                <ButtonKit
                  fullWidth
                  small
                  shape={"rounded"}
                  width={"200px"}
                  appearance={"secondary"}
                  color={"rival_red"}
                  onClick={this.revertSettingsChanges}
                >
                  Discard
                </ButtonKit>
                <ButtonKit
                  fullWidth
                  small
                  shape={"rounded"}
                  width={"200px"}
                  disabled={false}
                  onClick={() => {
                    this.setState({ isSaveSettingDialogOpen: true });
                  }}
                >
                  Save Settings
                </ButtonKit>
              </WrapButtonsRow>
            )} */}
          </>
        )}
      </Container>
    );
  }
}

export default Stage;
