import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Container,
  Content,
  Bar,
  TitleContainer,
  RivalShield,
  TitleText,
  Settings,
  WrapNotifications,
  WrapAvatar,
  WrapToggle,
  Toggle,
  MobilePopup,
  MobilePopupContent,
  MobileNav,
  MobileNavList,
  MobileNavLink,
  MobileNavItem
} from "./styled";
import PopupKit from "../../../components/kit/Popup/PopupKit";
import OrgSwitcher from "../OrgSwitcher/OrgSwitcher";
import { withRouter } from "react-router-dom";
import HeaderNotificationsContainer from "./HeaderNotifications/HeaderNotificationsContainer";
import Pusher from "pusher-js";
import { refreshNotificationsAction } from "../../../store/notifications/actions";
import { connect } from "react-redux";
import withWidth from "../../../hoc/withWidth";
import { FixIOSBodyOverflow } from "../../../helpers/common";
import { NavItems } from "../SideNav/constants";

// Icons
import RivalShieldIcon from "../../../static/icons/rival-shield-red.svg";

class HeaderModerator extends Component {
  static propTypes = {
    user: PropTypes.shape({
      logged: PropTypes.bool,
      verified: PropTypes.bool,
      info: PropTypes.object
    }).isRequired
  };

  state = {
    isStickyMode: false,
    showModal: null,
    isMobileDropDownMenuOpen: false
  };

  fixIOSBodyOverflow = new FixIOSBodyOverflow();

  mobileToggleRef = React.createRef();

  topBarRef = React.createRef();

  subscribeToGlobalPusherEvents = () => {
    if (this.props.user && !this.pusher) {
      this.pusher = new Pusher(process.env.REACT_APP_PUSHER_ID || "", {
        cluster: "us3",
        forceTLS: true
      });

      this.pusher.subscribe(`user-${this.props.user.id}`);

      this.pusher.bind("new-notification", () => {
        //update notification

        this.props.refreshNotificationsAction();
      });
    }
  };

  componentDidMount() {
    window.addEventListener("scroll", this.handleDocumentScroll);
    this.subscribeToGlobalPusherEvents();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleDocumentScroll);
    document.body.classList.remove("global-modal-is-open");
  }

  handleDocumentScroll = () => {
    const isStickyMode =
      (window.scrollY || document.documentElement.scrollTop) > 0;

    this.setState({ isStickyMode });
  };

  openMobileMenu = () => {
    const height = this.topBarRef.current.getBoundingClientRect().bottom;
    document.body.classList.add("global-modal-is-open");
    this.fixIOSBodyOverflow.addOverflow();
    this.props.setMobileMenu(true);

    this.setState({ topBarHeight: height, isMobileDropDownMenuOpen: true });
  };

  closeMobileMenu = () => {
    document.body.classList.remove("global-modal-is-open");
    this.fixIOSBodyOverflow.removeOverflow();
    this.props.setMobileMenu(false);
    this.setState({ isMobileDropDownMenuOpen: false });
  };

  renderMobileNav = () => {
    const navButtons = NavItems.map((item, index) => {
      return (
        !item.disabled && (
          <MobileNavItem key={index}>
            {item.to ? (
              <MobileNavLink
                to={item.to}
                exact={item.exact}
                onClick={() => this.closeMobileMenu()}
              >
                {item.title}
              </MobileNavLink>
            ) : (
              <MobileNavLink
                as={"a"}
                href={item.href}
                target="_blank"
                onClick={() => this.closeMobileMenu()}
                to={""}
              >
                {item.title}
              </MobileNavLink>
            )}
          </MobileNavItem>
        )
      );
    });
    return (
      <MobilePopup>
        <MobilePopupContent>
          <PopupKit
            disabledPortal
            isOpen={this.state.isMobileDropDownMenuOpen}
            onClose={() => this.closeMobileMenu()}
          >
            <MobileNav>
              <MobileNavList>{navButtons}</MobileNavList>
            </MobileNav>
          </PopupKit>
        </MobilePopupContent>
      </MobilePopup>
    );
  };

  render() {
    const { isMobile } = this.props;
    const { isStickyMode } = this.state;

    const path = window.location.pathname.split("/");
    const title = path[1] || "";
    const currentNav =
      NavItems.find(item => `/${title}`.includes(item.to)) ||
      NavItems.find(item => item.alternates && item.alternates.includes(title));
    return (
      <Container ref={this.topBarRef}>
        <Content isStickyMode={isStickyMode}>
          <Bar>
            <WrapToggle>
              <Toggle
                ref={this.mobileToggleRef}
                onClick={() => {
                  this.openMobileMenu();
                }}
                isOpen={this.state.isMobileDropDownMenuOpen}
              />
            </WrapToggle>
            {!!currentNav && (
              <TitleContainer>
                <RivalShield src={RivalShieldIcon} />
                <TitleText>{currentNav.title}</TitleText>
              </TitleContainer>
            )}

            <Settings>
              <WrapNotifications>
                <HeaderNotificationsContainer />
              </WrapNotifications>

              <WrapAvatar>
                <OrgSwitcher isMobile={isMobile} />
              </WrapAvatar>
            </Settings>

            {this.renderMobileNav()}
          </Bar>
        </Content>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.info
});

const mapDispatchToProps = {
  refreshNotificationsAction
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withWidth(HeaderModerator)));
