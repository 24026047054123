import { propValueOr } from "../../helpers/common";
import { api } from "../../index";
import GAMES_ACTIONS from "./constants";

export const fetchGame = query => dispatch => {
  dispatch({
    type: GAMES_ACTIONS.fetch.request
  });
  api.games
    .fetch(query)
    .then(resp => {
      dispatch({
        type: GAMES_ACTIONS.fetch.success,
        payload: propValueOr(resp, "data", {})
      });
    })
    .catch(err => {
      dispatch({
        type: GAMES_ACTIONS.fetch.error,
        payload: err
      });
    });
};

export const fetchGames = () => dispatch => {
  dispatch({
    type: GAMES_ACTIONS.list.request
  });
  api.games
    .list()
    .then(resp => {
      dispatch({
        type: GAMES_ACTIONS.list.success,
        payload: propValueOr(resp, "data", [])
      });
    })
    .catch(err => {
      dispatch({
        type: GAMES_ACTIONS.list.error,
        payload: err
      });
    });
};

export const fetchTemplates = () => dispatch => {
  dispatch({
    type: GAMES_ACTIONS.templates.request
  });
  api.admin.matchTemplates
    .get()
    .then(resp => {
      dispatch({
        type: GAMES_ACTIONS.templates.success,
        payload: propValueOr(resp, "data", [])
      });
    })
    .catch(err => {
      dispatch({
        type: GAMES_ACTIONS.templates.error,
        payload: err
      });
    });
};

export const resetGames = () => dispatch => {
  dispatch({
    type: GAMES_ACTIONS.reset
  });
};
