import styled, { css } from "styled-components";
import {
  COLOR,
  FONT_FAMILY,
  FONT_WEIGHT
} from "../../../../../../constants/theme";
import breakpoints from "../../../../../../helpers/breakpoints";

export const DialogButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  > :not(:last-child) {
    margin-right: 0;
    margin-bottom: 10px;
  }
`;

export const Text = styled.div`
  font: ${FONT_WEIGHT.NORMAL} 14px / 20px ${FONT_FAMILY.THIN};
  color: ${COLOR.TEXT};
`;

export const Container = styled.div`
  width: 434px;
  margin: auto;

  border-radius: 2px;
  background-color: ${COLOR.BG_SECONDARY};
  position: relative;

  ${breakpoints.down("xs")} {
    width: 320px;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${COLOR.BORDER};
  padding: 22px 25px;
`;

export const BtnClose = styled.div`
  cursor: pointer;
  transition: all 0.3s;

  svg {
    transform: rotate(45deg);
    fill: ${COLOR.FG_PRIMARY};
    height: 19px;
  }

  &:hover {
    opacity: 0.8;
  }
`;

export const HeaderTitle = styled.div`
  color: ${COLOR.TEXT};
  font: ${FONT_WEIGHT.NORMAL} 28px / 32px ${FONT_FAMILY.THICK};
  text-transform: uppercase;
  text-align: center;
  display: flex;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FooterContent = styled.div`
  display: flex;

  width: 100%;
  padding: 15px;
`;

export const SectionTitle = styled.div`
  width: 100%;
  padding: 16px 24px;

  font: ${FONT_WEIGHT.BOLD} 14px ${FONT_FAMILY.THIN};
  color: ${COLOR.TEXT};

  border: 1px solid ${COLOR.BORDER};
`;

export const Profile = styled.div`
  padding: 8px 24px;
  margin: 0;
  width: 100%;
`;

export const GreyedProfile = styled.div`
  padding: 8px 24px;
  margin: 0;
  width: 100%;

  opacity: 0.5;
`;

export const ProfileList = styled.ul`
  padding: 0px 24px;
  margin: 0;
  width: 100%;
  overflow-y: auto;
  max-height: 245px;

  &::-webkit-scrollbar {
    width: 4px;
    padding-right: 20px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${COLOR.ROUGE};
    border-radius: 5.5px;
  }
  &::-webkit-scrollbar-track {
    background-color: ${COLOR.BG_PRIMARY};
    border-radius: 5.5px;
  }

  > li {
    padding: 8px 0px;
    cursor: pointer;
    &:hover {
      opacity: 0.4;
    }
    &:not(:last-child) {
      border-bottom: 1px solid ${COLOR.BG_PRIMARY};
    }
  }
`;

export const CheckmarkImg = styled.img`
  width: 32px;
  height: 32px;
  margin-left: auto;
  margin-right: 10px;
`;

export const Divider = styled.div`
  height: 1px;
  background-color: ${COLOR.BG_PRIMARY};
`;

export const Buttons = styled.div`
  display: flex;
  width: 100%;
  padding: 22px 25px;

  > :not(:last-child) {
    margin-right: 20px;
  }
`;

export const LabelGamertag = styled.div`
  text-align: left;
  color: ${COLOR.TEXT_3};
  font: ${FONT_WEIGHT.NORMAL} 16px / 1.2 ${FONT_FAMILY.THIN};
  margin-bottom: 5px;
  ${breakpoints.down("600")} {
    font-size: 14px;
  }
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin: 20px 0;
  border-bottom: 1px solid ${COLOR.BORDER};
  padding: 0 0 20px 0;
`;
export const ItemTitle = styled.div`
  color: ${COLOR.TEXT};
  font: ${FONT_WEIGHT.LIGHT} 14px / 16px ${FONT_FAMILY.THIN};
  margin: ${({ margin }) => (margin ? margin : "0")};
`;
export const ItemDescription = styled.div`
  color: ${COLOR.TEXT};
  font: ${FONT_WEIGHT.NORMAL} 12px / 14px ${FONT_FAMILY.THIN};
  margin: 5px 0 10px;
`;
export const ItemScore = styled.div`
  display: flex;
  margin: 15px 0 0 0;
  width: 100%;
  > :not(:last-child) {
    margin-right: 10px;
  }
`;
export const ItemScoreField = styled.div`
  display: flex;
  flex-direction: ${props => (props.border ? "row" : "column")};
  ${props => props.border && `border-bottom: 2px solid ${props.border}`};
  justify-content: space-between;
  flex: 1 1 50%;
`;
export const LabelScore = styled.div`
  text-align: right;
  color: ${COLOR.TEXT_3};
  font: ${FONT_WEIGHT.NORMAL} 16px / 1.2 ${FONT_FAMILY.THIN};
  margin-bottom: 5px;
  ${breakpoints.down("600")} {
    font-size: 14px;
  }
`;

export const WrapButtons = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 35px;

  > :not(:last-child) {
    margin-bottom: 15px;
  }
`;

export const Required = styled.span`
  color: red;
  font-weight: bold;
`;

export const TextContent = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  font: ${FONT_WEIGHT.NORMAL} 14px ${FONT_FAMILY.THIN};
  color: ${COLOR.TEXT};
`;

export const PlaceholderText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  width: 100%;
  padding: 10px;
  font: ${FONT_WEIGHT.MEDIUM} 16px ${FONT_FAMILY.THIN};
  color: ${COLOR.TEXT};
`;

export const ErrorText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  color: ${COLOR.ROUGE};
  font: ${FONT_WEIGHT.MEDIUM} 12px ${FONT_FAMILY.THIN};
`;

export const SearchBar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  padding: 15px 10px;
`;

export const ImgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
`;

export const CheckImg = styled.img`
  height: 40px;
  width: 40px;
  margin-left: auto;
`;

const BORDER_BOTTOM = () => css`
  border-bottom: 1px solid ${COLOR.BORDER};
`;

export const List = styled.div`
  padding: 10px 0px;
  color: ${COLOR.TEXT};

  width: 100%;
  max-height: 250px;
  overflow-y: auto;
  ${BORDER_BOTTOM};
  > :not(:last-child) {
    ${BORDER_BOTTOM};
  }

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
    border-radius: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${COLOR.ROUGE};
    border-radius: 5.5px;
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
  &::-webkit-scrollbar-track {
    background-color: ${COLOR.BG_PRIMARY};
    border-radius: 5.5px;
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

export const Success = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 25px;
  > :not(:last-child) {
    margin-bottom: 15px;
  }
`;
