import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import {
  Container,
  Navigation,
  Item,
  IFrame,
  WrapStream,
  Stream,
  Content,
  Icon,
  Title,
  Text
} from "./styled";
import { getUsername, propValueOr } from "../../../helpers/common";

function Streams({ streamTabIndex, onChangeStreamTab, players }) {
  const [notFound, setNotFound] = useState(false);
  const [hasFetched, setHasFetched] = useState(false);
  if (!players) {
    return null;
  }

  const twitchUsername = players[streamTabIndex].twitchUsername;
  const streamUrl =
    players[streamTabIndex].streamBaseUrl ||
    (twitchUsername &&
      `https://player.twitch.tv/?channel=${twitchUsername}&parent=${window.location.hostname}`);
  if (!streamUrl && !hasFetched) {
    setHasFetched(true);
    setNotFound(true);
  }

  if (streamUrl && !hasFetched) {
    setHasFetched(true);
    fetch(streamUrl).then(resp => {
      if (resp.status === 404) {
        setNotFound(true);
      }
    });
  }

  return (
    <Container>
      <Navigation>
        {players.map((p, i) => {
          return (
            <Item
              onClick={() => {
                onChangeStreamTab(i);
                setNotFound(false);
                setHasFetched(false);
              }}
              isActive={i === streamTabIndex}
              isHome={p.isHome}
              title={propValueOr(p, "team.name", getUsername(p))}
              key={i}
            >
              {propValueOr(p, "team.name", getUsername(p))}
            </Item>
          );
        })}
      </Navigation>
      <WrapStream>
        {!notFound ? (
          <IFrame src={streamUrl} />
        ) : (
          <Stream>
            <Content>
              <Icon />
              <Title>Stream not found</Title>
              {
                <Text>
                  Need Help?
                  <a href="https://support.rivalgames.com/80082-tech-support/how-do-i-stream-my-game-in-the-rival-games-app">
                    Click Here
                  </a>
                  for Troubleshooting tips
                </Text>
              }
            </Content>
          </Stream>
        )}
      </WrapStream>
    </Container>
  );
}

export default withRouter(Streams);
