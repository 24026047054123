import React, { Component, Fragment } from "react";
import {
  SnapshotSD,
  SnapshotRowSD,
  Body,
  Header,
  Content,
  Container,
  WrapLoader,
  HeaderTitle,
  SnapshotAscoreSD,
  SnapshotAstateSD,
  SnapshotGameClockSD,
  SnapshotHscoreSD,
  SnapshotHstateSD,
  SnapshotTimeSD,
  Divider,
  StatusBadge,
  Message,
  Text,
  Left,
  Icon,
  Label,
  GameLink,
  WrapGrid,
  SnapshotTitle,
  Subtitle,
  Description,
  WrapReasonField,
  Reason,
  VerificationEntry,
  Image,
  WrapTitle,
  ImageTitle,
  ImageSubtitle,
  WrapImageList,
  ImageList,
  WrapImageItem,
  ImageItem,
  WrapRadioButton,
  Avatar,
  Scores,
  Score,
  ScoreLabel,
  Side,
  ScoreSubtitle,
  Item,
  ItemScore,
  ItemScoreField,
  LabelGamertag,
  LabelScore,
  EnterNew,
  IncorrectText,
  WrapInputs,
  WrapPhoto,
  Photo,
  FullscreenImage,
  FullscreenBg,
  FullscreenText,
  ScoreTitle,
  ScoreTime,
  ScoreHeader,
  Gamertag,
  UserScore,
  ScoreTable,
  TableUser,
  CompletedScore,
  ReasonTitle,
  ReasonSubtitle,
  ReasonDescription,
  CompletedReason,
  Submit,
  WrapUser,
  WrapTimer,
  TimerContainer,
  WrapSubmitButton
} from "./styled";
import { COLOR } from "../../constants/theme";
import ButtonKit from "../../components/kit/Button/ButtonKit";
import { api } from "../../index";
import {
  getUsername,
  safePropValueOr,
  hasOnlyDigitsInString,
  propValueOr
} from "../../helpers/common";
import { withRouter } from "react-router-dom";
import Pusher from "pusher-js";
import backArrow from "../../static/icons/chevron-arrow.svg";
import FormFieldKit from "../../components/kit/Fields/FormField/FormField";
import RadioButtonKit from "../../components/kit/Fields/RadioButton/RadioButtonKit";
import { getScoreItemForUser } from "./constants";
import Loader from "../../components/presentation/Loader/Loader";
import placeholderUserImg from "../../static/images/default-user.jpg";
import Timer from "../../components/smart/Timer/Timer";
import moment from "moment";
import momentTz from "moment-timezone";
import { connect } from "react-redux";
import PermissionDenied from "../denied/PermissionDenied";

var zone_name = momentTz.tz.guess();
var timezone = momentTz.tz(zone_name).zoneAbbr();

class RouteChallenge extends Component {
  constructor(props) {
    super(props);

    this.state = {
      homeScore: null,
      awayScore: null,
      match: null,
      challenge: null,
      users: [],
      activeEntryIndexes: [],
      wasSent: false,
      reason: "",
      scoreSelect: "",
      showInputs: false,
      loading: false
    };

    this.pusher = new Pusher(process.env.REACT_APP_PUSHER_ID || "", {
      cluster: "us3",
      forceTLS: true
    });
  }

  subscribeToMatchChannel = token => {
    this.matchChannelName = `match-${token}`;

    const channel = this.pusher.subscribe(`match-${token}`);

    channel.bind("status", packet => {
      let data = packet.message;

      if (
        this.state.match &&
        this.state.match.status &&
        this.state.match.status !== data.status
      ) {
        this.getMatch();
      }
    });

    channel.bind("reload", () => {
      this.getMatch();
    });

    channel.bind("challenge", packet => {
      this.getMatch();
    });
  };

  componentDidMount() {
    this.getMatch();
    document.addEventListener("keydown", this.handleCloseImage);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.getMatch();
    }
  }

  handleCloseImage = () => {
    if (this.state.image) {
      this.setState({ image: null });
    }
  };

  handleActiveIndexChange(userIndex, activeIndex) {
    let { activeEntryIndexes } = this.state;
    activeEntryIndexes[userIndex] = activeIndex;
    this.setState({ activeEntryIndexes });
  }

  getMatch = () => {
    this.setState({ loading: true });
    api.challenge
      .get(this.props.match.params.token)
      .then(resp => {
        const { match, ...challenge } = resp.data;
        this.subscribeToMatchChannel(match.token);
        this.setState({
          challenge: challenge,
          match: match,
          users:
            match.users.length > 1
              ? match.users[0].isHome
                ? [match.users[0], match.users[1]]
                : [match.users[1], match.users[0]]
              : [],
          activeEntryIndexes: match.users.length > 1 ? [0, 0] : [],
          homeScore: null,
          awayScore: null,
          loading: false
        });
      })
      .catch(e => {
        console.log(e);
        this.setState({ match: null, loading: false });
      });
  };

  handleChangeScore = (propName, value) => {
    if (hasOnlyDigitsInString(value) && value.length <= 3) {
      this.setState({ [propName]: value });
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    api.challenge
      .update(this.props.match.params.token, {
        score: `${this.state.homeScore} - ${this.state.awayScore}`,
        description: this.state.reason
      })
      .then(() => {
        this.setState({
          wasSent: true
        });
      });
  };

  render() {
    const { history, permission } = this.props;
    const {
      homeScore,
      awayScore,
      wasSent,
      match,
      challenge,
      users,
      activeEntryIndexes,
      scoreSelect,
      showInputs,
      image,
      loading
    } = this.state;

    if (!permission) {
      return <PermissionDenied />;
    }
    const canEdit = permission !== "read";
    const id = safePropValueOr(match, "token", "");

    return (
      <Container>
        {image ? (
          <FullscreenBg onClick={this.handleCloseImage}>
            <FullscreenText>Click anywhere to exit</FullscreenText>
            <FullscreenImage src={image} />
          </FullscreenBg>
        ) : !match ? (
          loading ? (
            <WrapLoader>
              <Loader isBlock />
            </WrapLoader>
          ) : (
            <HeaderTitle>No Game Found</HeaderTitle>
          )
        ) : (
          <Content>
            <Header>
              <Left>
                <Icon src={backArrow} onClick={() => history.goBack()} />
                <Label>
                  {match.status === "complete" && "COMPLETED "}CHALLENGE FOR
                  GAME ID: <GameLink to={`/game/${id}`}>{`#${id}`}</GameLink>
                </Label>
              </Left>
              {match.status === "complete" ? (
                <StatusBadge status={match.status}>{match.status}</StatusBadge>
              ) : (
                <TimerContainer>
                  Challenge Clock
                  <WrapTimer margin={true}>
                    <Timer
                      endDate={moment(match.updatedAt)
                        .add(8, "minutes")
                        .toDate()}
                    />
                  </WrapTimer>
                </TimerContainer>
              )}
            </Header>
            {users.length === 2 && (
              <Fragment>
                <Scores>
                  {users.map((user, userIndex) => {
                    const opp = users.find(u => u.id !== user.id);
                    const item = getScoreItemForUser(user);
                    const radioValue = user.isHome ? "home" : "away";
                    const activeIndex = activeEntryIndexes[userIndex];
                    const verificationEntries = propValueOr(
                      user,
                      "verificationEntries",
                      []
                    );
                    let activeEntry = {};
                    if (activeIndex < verificationEntries.length)
                      activeEntry = verificationEntries[activeIndex];
                    return (
                      <WrapGrid key={userIndex}>
                        <Score>
                          <ScoreLabel>
                            <ScoreSubtitle>
                              <Avatar
                                src={propValueOr(
                                  user,
                                  "imageInfo.thumbnail",
                                  placeholderUserImg
                                )}
                                onError={e =>
                                  (e.target.src = placeholderUserImg)
                                }
                              />
                              {getUsername(user)} Submitted Score
                            </ScoreSubtitle>
                            <Side isHome={user.isHome}>
                              {user.isHome ? "Home" : "Away"}
                            </Side>
                          </ScoreLabel>
                          <Item>
                            <ItemScore>
                              <ItemScoreField
                                border={
                                  item.outcome === "W"
                                    ? COLOR.GREEN
                                    : item.outcome === "L"
                                    ? COLOR.ROUGE
                                    : item.outcome === "T"
                                    ? COLOR.YELLOW
                                    : COLOR.WHITE
                                }
                              >
                                <LabelGamertag>
                                  {getUsername(user.isHome ? user : opp)}
                                </LabelGamertag>
                                <LabelScore>{item.home}</LabelScore>
                              </ItemScoreField>

                              <ItemScoreField
                                border={
                                  item.outcome === "W"
                                    ? COLOR.ROUGE
                                    : item.outcome === "L"
                                    ? COLOR.GREEN
                                    : item.outcome === "T"
                                    ? COLOR.YELLOW
                                    : COLOR.WHITE
                                }
                              >
                                <LabelGamertag>
                                  {getUsername(user.isHome ? opp : user)}
                                </LabelGamertag>
                                <LabelScore>{item.away}</LabelScore>
                              </ItemScoreField>
                            </ItemScore>
                          </Item>
                        </Score>
                        <WrapTitle>
                          <ImageTitle>Uploaded Photos</ImageTitle>
                          <ImageSubtitle>
                            Uploaded photos of final score
                          </ImageSubtitle>
                        </WrapTitle>
                        <VerificationEntry>
                          <Image>
                            {!safePropValueOr(
                              activeEntry,
                              "image",
                              safePropValueOr(user, "imageUrl", "")
                            ) ? (
                              "NO PHOTO UPLOADED"
                            ) : (
                              <WrapPhoto>
                                <Photo
                                  src={safePropValueOr(
                                    activeEntry,
                                    "image",
                                    safePropValueOr(user, "imageUrl", "")
                                  )}
                                  onClick={() =>
                                    this.setState({
                                      image: safePropValueOr(
                                        activeEntry,
                                        "image"
                                      )
                                    })
                                  }
                                />
                              </WrapPhoto>
                            )}
                          </Image>
                          <Message>
                            <ImageTitle>Message For Moderator</ImageTitle>
                            <Text>
                              {safePropValueOr(
                                activeEntry,
                                "message",
                                safePropValueOr(
                                  user,
                                  "message",
                                  "No Message Submitted"
                                )
                              )}
                            </Text>
                          </Message>
                        </VerificationEntry>
                        <WrapImageList>
                          <ImageList>
                            {verificationEntries.map((entry, entryIndex) => {
                              return (
                                <WrapImageItem
                                  active={entryIndex === activeIndex}
                                >
                                  <ImageItem
                                    src={safePropValueOr(entry, "image")}
                                    onClick={() =>
                                      this.handleActiveIndexChange(
                                        userIndex,
                                        entryIndex
                                      )
                                    }
                                  />
                                </WrapImageItem>
                              );
                            })}
                          </ImageList>
                        </WrapImageList>
                        {match.status !== "complete" && (
                          <WrapRadioButton>
                            <RadioButtonKit
                              label={"Accept this score as the Final Score"}
                              value={radioValue}
                              checked={scoreSelect === radioValue}
                              disabled={!user.scores || showInputs || !canEdit}
                              color={"rival_red"}
                              onChange={() =>
                                this.setState({
                                  scoreSelect: radioValue,
                                  homeScore: item.home,
                                  awayScore: item.away
                                })
                              }
                            />
                          </WrapRadioButton>
                        )}
                      </WrapGrid>
                    );
                  })}
                </Scores>
              </Fragment>
            )}

            {match.status === "complete" && (
              <Body>
                <CompletedScore>
                  <ScoreHeader>
                    <ScoreTitle>Moderator Score</ScoreTitle>
                    <ScoreTime>
                      {moment(propValueOr(challenge, "updatedAt", null)).format(
                        "M/DD/YY @ h:mmA "
                      )}
                      {timezone}
                    </ScoreTime>
                  </ScoreHeader>
                  <ScoreTable>
                    {users.map((u, ind) => (
                      <TableUser result={u.outcome} key={ind}>
                        <WrapUser>
                          <Avatar
                            src={propValueOr(
                              u,
                              "imageInfo.thumbnail",
                              placeholderUserImg
                            )}
                            onError={e => (e.target.src = placeholderUserImg)}
                          />
                          <Gamertag>{getUsername(u)}</Gamertag>
                        </WrapUser>
                        <UserScore>{u.score}</UserScore>
                      </TableUser>
                    ))}
                  </ScoreTable>
                </CompletedScore>
                <Divider />
                <CompletedReason>
                  <ReasonTitle>REASON FOR DECISION</ReasonTitle>
                  <ReasonSubtitle>
                    Moderated By:{" "}
                    {propValueOr(
                      challenge,
                      "moderator.fullName",
                      propValueOr(
                        challenge,
                        "moderator.username",
                        propValueOr(challenge, "moderator.id", "SYSTEM")
                      )
                    )}
                  </ReasonSubtitle>
                  <ReasonDescription>
                    {propValueOr(
                      challenge,
                      "description",
                      "No reason provided by moderator."
                    )}
                  </ReasonDescription>
                </CompletedReason>
              </Body>
            )}

            {match.status !== "complete" && (
              <EnterNew>
                <IncorrectText>
                  {showInputs
                    ? "Enter the correct scores here"
                    : "Are both of these scores incorrect?"}
                </IncorrectText>
                {showInputs && (
                  <WrapInputs>
                    <FormFieldKit
                      value={homeScore || ""}
                      onChange={val => this.handleChangeScore("homeScore", val)}
                      placeholder={"Home score..."}
                      type={"number"}
                      disabled={!canEdit}
                    />
                    <FormFieldKit
                      value={awayScore || ""}
                      onChange={val => this.handleChangeScore("awayScore", val)}
                      placeholder={"Away score..."}
                      type={"number"}
                      disabled={!canEdit}
                    />
                  </WrapInputs>
                )}
                <ButtonKit
                  appearance={"secondary"}
                  color={"rival_red"}
                  shape={"rounded"}
                  width={"347px"}
                  disabled={!canEdit}
                  onClick={() =>
                    this.setState({
                      showInputs: !showInputs,
                      homeScore: null,
                      awayScore: null,
                      ...(!showInputs && { scoreSelect: "" })
                    })
                  }
                >
                  {showInputs ? "Cancel" : "Enter New Final Score"}
                </ButtonKit>
              </EnterNew>
            )}

            {match.status !== "complete" && (
              <Fragment>
                <Divider />
                <Reason>
                  <Subtitle>REASON FOR DECISION</Subtitle>
                  {match.status === "complete" ? (
                    <Description>
                      {propValueOr(match, "challenge.description", null) ||
                        "No Reason Submitted."}
                    </Description>
                  ) : (
                    <>
                      <Description>
                        This is for documentation purposes and will be submitted
                        to the tournament admin.
                      </Description>
                      <WrapReasonField>
                        <FormFieldKit
                          onChange={val => this.setState({ reason: val })}
                          inputProps={{ maxLength: 200 }}
                          placeholder={"Write a reason for your decision..."}
                          value={this.state.reason}
                          fullWidth
                          multiline
                          disabled={!canEdit}
                          gutterBottom={false}
                        />
                      </WrapReasonField>
                    </>
                  )}
                </Reason>
              </Fragment>
            )}

            {!match.manualScoring &&
              match.matchSnapshots &&
              match.matchSnapshots.length > 0 && (
                <SnapshotSD>
                  <SnapshotTitle>AUTODETECTED SCORE LOG</SnapshotTitle>
                  <SnapshotRowSD>
                    <SnapshotTimeSD>Time</SnapshotTimeSD>
                    <SnapshotHscoreSD>Home Score</SnapshotHscoreSD>
                    <SnapshotHstateSD>Home State</SnapshotHstateSD>
                    <SnapshotAscoreSD>Away Score</SnapshotAscoreSD>
                    <SnapshotAstateSD>Away State</SnapshotAstateSD>
                    <SnapshotGameClockSD>Game Clock</SnapshotGameClockSD>
                  </SnapshotRowSD>
                  {match.matchSnapshots.map(snapshot => (
                    <SnapshotRowSD>
                      <SnapshotTimeSD>
                        {moment(snapshot.created_at).format("H:mm:ss")}
                      </SnapshotTimeSD>
                      <SnapshotHscoreSD>
                        {snapshot.home_score || "0"}
                      </SnapshotHscoreSD>
                      <SnapshotHstateSD>{snapshot.home_state}</SnapshotHstateSD>
                      <SnapshotAscoreSD>
                        {snapshot.away_score || "0"}
                      </SnapshotAscoreSD>
                      <SnapshotAstateSD>{snapshot.away_state}</SnapshotAstateSD>
                      <SnapshotGameClockSD>
                        {snapshot.game_clock || "none"}
                      </SnapshotGameClockSD>
                    </SnapshotRowSD>
                  ))}
                </SnapshotSD>
              )}

            {!wasSent && match.status !== "complete" && (
              <Submit>
                <WrapSubmitButton>
                  <ButtonKit
                    fullWidth
                    onClick={this.handleSubmit}
                    shape={"rounded"}
                    disabled={!(homeScore && awayScore && match) || !canEdit}
                    color={"rival_red"}
                  >
                    Submit Decision
                  </ButtonKit>
                </WrapSubmitButton>
              </Submit>
            )}
          </Content>
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RouteChallenge));
