import { USER_ACTIONS } from "./constants";
import { parseJwt } from "../../helpers/common";
import { api } from "../../index";
import { fetchPermissionsAction } from "../permissions/actions";
import { fetchOrganizationsAction } from "../organizations/actions";

export const loginAction = body => dispatch => {
  return api
    .login(body)
    .then(resp => {
      dispatch(
        setLoggedInAction(resp.data["access_token"], resp.data["refresh_token"])
      );
      return resp;
    })
    .catch(err => {
      dispatch({ type: USER_ACTIONS.login.error });
      throw err;
    });
};

export const ipAddressAction = body => dispatch => {
  return api
    .ipAddress(body)
    .then(resp => {
      dispatch(
        setLoggedInAction(resp.data["access_token"], resp.data["refresh_token"])
      );
      return resp;
    })
    .catch(err => {
      dispatch({ type: USER_ACTIONS.login.error });
      throw err;
    });
};

export const setLoggedInAction = (accessToken, refreshToken) => dispatch => {
  api.setTokens(accessToken, refreshToken);
  dispatch({ type: USER_ACTIONS.login.success });
  dispatch(setAuthorizedUserInfoAction(parseJwt(accessToken)));
};

export const setAuthorizedUserInfoAction = (info = {}) => dispatch => {
  // check if user has current org for permissions
  dispatch(
    fetchPermissionsAction({ organizationId: info.organizationId })
  ).then(() => {
    dispatch({
      type: USER_ACTIONS.login.setAuthorizedUserInfo,
      payload: { info: info }
    });
    dispatch(fetchOrganizationsAction());
  });
};

export const updateUserInfoAction = body => dispatch => {
  return api.user
    .update(body)
    .then(resp => {
      const accessToken = resp.data["access_token"];

      dispatch({ type: USER_ACTIONS.update.success });

      dispatch(setAuthorizedUserInfoAction(parseJwt(accessToken)));

      return resp;
    })
    .catch(err => {
      dispatch({ type: USER_ACTIONS.update.error });
      throw err;
    });
};

export const logoutAction = () => {
  api.logout();

  return setUserAsNotAuthorizedAction();
};

export const setUserAsNotAuthorizedAction = () => ({
  type: USER_ACTIONS.login.setUserAsNotAuthorized
});

export const updateUserRequestStatus = status => ({
  type: USER_ACTIONS.request.status,
  payload: { status: status }
});
