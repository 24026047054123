import styled from "styled-components";
import { BOX_SHADOW, COLOR, FONT, FONT_SIZE } from "../../../constants/theme";
import breakpoints from "../../../helpers/breakpoints";
import { ButtonKitSD } from "../../kit/Button/styled";

export const Content = styled.div`
  padding-left: 18px;
  flex: 1;
  ${breakpoints.down("xs")} {
    padding-left: 6px;
  }
`;

export const Body = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  color: ${COLOR.GREY};
  font-family: ${FONT.THIN};
  font-size: ${FONT_SIZE.PARAGRAPH_SMALL}px;
  letter-spacing: 0;
  line-height: 1.5;

  & > * {
    line-height: inherit;
  }
`;

export const BodyRow = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
`;

export const BodySubject = styled.div`
  padding-right: 15px;
  overflow: hidden;
  white-space: nowrap;

  & > p {
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const BodyAuthor = styled.div`
  flex-basis: 32%;
  max-width: 32%;
  flex-shrink: 0;
  text-align: right;
  overflow: hidden;
  white-space: nowrap;

  & > p {
    text-overflow: ellipsis;
    overflow: hidden;
  }

  ${breakpoints.down("xs")} {
    flex-basis: 100%;
    max-width: 100%;
    text-align: left;
  }
`;

export const Row = styled.div`
  display: flex;
`;

export const ImageWrapper = styled.div`
  width: 32px;
  flex-shrink: 0;

  ${breakpoints.down("xs")} {
    width: 24px;
  }
`;

export const Image = styled.img`
  display: block;
  max-width: 100%;
`;

export const Header = styled.div`
  padding: 8px 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;

  ${breakpoints.down("xs")} {
    padding-top: 2px;
  }
`;

const Item = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  & > * {
    margin-bottom: 0;
  }
`;

export const From = styled(Item)`
  margin-left: 0;
  flex-basis: 20%;
  max-width: 20%;

  ${breakpoints.down("sm")} {
    flex-basis: 50%;
    max-width: 50%;
    margin-left: 0;
  }

  ${breakpoints.down("xs")} {
    flex-basis: 100%;
    max-width: 100%;
  }

  & > span {
    color: ${COLOR.GREY};
    margin-right: 16px;

    ${breakpoints.up("md")} {
      display: none;
    }

    ${breakpoints.down("sm")} {
      margin-right: 12px;
      min-width: 38px;
      display: inline-block;
    }
  }
`;

export const Name = styled(Item)`
  margin-left: 0;
  flex-basis: 38%;
  max-width: 38%;

  ${breakpoints.down("xs")} {
    flex-basis: 84%;
    max-width: 84%;
  }
`;

export const To = styled(Item)`
  margin-left: 26px;
  flex-basis: 34%;
  max-width: 34%;

  & > span {
    color: ${COLOR.GREY};
    margin-right: 16px;

    ${breakpoints.down("sm")} {
      margin-right: 12px;
      min-width: 38px;
      display: inline-block;
    }
  }

  ${breakpoints.down("sm")} {
    order: 6;
    margin-top: 8px;
    flex-basis: 50%;
    max-width: 50%;
    margin-left: 0;
  }

  ${breakpoints.down("xs")} {
    flex-basis: 100%;
    max-width: 100%;
  }
`;

export const Date = styled(Item)`
  margin-left: auto;
  display: inline-flex;
  align-items: center;
  flex-basis: 32%;
  max-width: 32%;
  justify-content: space-between;

  & > svg {
    fill: ${COLOR.TEAL};
    margin-right: 12px;
    margin-left: auto;
    flex-shrink: 0;
  }

  ${breakpoints.down("xs")} {
    flex-basis: 100%;
    max-width: 100%;
  }
`;

export const HeaderActions = styled(Item)`
  margin-left: 24px;
  overflow: initial;

  ${breakpoints.down("xs")} {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
  }
`;

export const HeaderAction = styled.div`
  position: relative;

  &:not(:first-child) {
    margin-left: 24px;
  }
`;

export const Actions = styled.div`
  margin-top: 16px;
  margin-left: -5px;
  margin-right: -5px;

  ${breakpoints.down("xs")} {
    margin-left: 0;
    margin-right: 0;
  }

  ${ButtonKitSD} {
    margin: 5px 5px;

    ${breakpoints.down("sm")} {
      width: calc(50% - 10px);
    }

    ${breakpoints.down("xs")} {
      width: 100%;
      margin: 5px 0;
    }
  }
`;

export const PopupWrapper = styled.div`
  position: absolute;
  top: 100%;
  transform: translate(-15px, 10px);
  left: auto;
  right: -20px;
  width: 130px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.5);
  border-radius: 2px;
  overflow: hidden;

  ${breakpoints.down("xs")} {
    left: auto;
    right: 0;
    transform: translate(0, 10px);
  }
`;

export const Tooltip = styled.div`
  white-space: initial;
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  background-color: ${COLOR.BG_SECONDARY};
  box-shadow: ${BOX_SHADOW.THIRD};
  border-radius: 2px;
  overflow: hidden;
  color: ${COLOR.WHITE};
  font-family: ${FONT.THIN};
  font-size: 11px;

  span {
  }
`;

export const TooltipInner = styled.div`
  max-height: 320px;
  overflow: auto;
`;

export const TooltipRecipient = styled.span`
  display: inline-block;
  padding: 3px 6px;
  margin: 1px;
  border-radius: 2px;
  background-color: ${COLOR.BG_INPUT};
  color: ${({ state }) =>
    state === "failed" ? COLOR.FG_SECONDARY : COLOR.WHITE};
`;

export const Container = styled.div`
  padding: ${({ template }) =>
    template ? "30px 26px 30px" : "22px 26px 30px"};
  border: ${({ template }) =>
    template ? `1px solid ${COLOR.BORDER_3}` : "none"};
  border-top: 1px solid ${COLOR.BORDER_3};
  border-radius: ${({ template }) => (template ? "4px" : "0")};
  
  ${breakpoints.down("sm")} {
    padding: ${({ template }) => (template ? "15px 15px" : "8px 15px 15px")};
  }
  
  &:not(:last-child) {
    margin-bottom: ${({ template }) => (template ? "24px" : "0")};
  }
  
  ${Content}{
    padding-left: ${({ template }) => (template ? "0" : "18px")};
    max-width:  ${({ template }) => (template ? "100%" : "calc(100% - 32px)")};
    flex-basis: ${({ template }) => (template ? "100%" : "calc(100% - 32px)")};
    
    ${breakpoints.down("xs")}{
      padding-left: ${({ template }) => (template ? "0" : "10px")};
      max-width: ${({ template }) => (template ? "100%" : "calc(100% - 24px)")};
      flex-basis: ${({ template }) =>
        template ? "100%" : "calc(100% - 24px)"};
    }
  }
  
  ${Body}{
    color: ${({ template }) => (template ? COLOR.WHITE : COLOR.GREY)}
  }
  
  ${Date} {
    flex-basis: ${({ template }) => (template ? "40%" : "32%")}
    max-width: ${({ template }) => (template ? "40%" : "32%")}
    
    ${breakpoints.down("sm")} {
      flex-basis: ${({ template }) => (template ? "54%" : "40%")}
      max-width: ${({ template }) => (template ? "54%" : "40%")}
    }
    
    ${breakpoints.down("xs")} {
      flex-basis: 100%;
      max-width: 100%;
      order: 8;
      margin-top: 8px;
    }
  }
`;
