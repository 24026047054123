import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  HeaderSD,
  HeaderWrapTopLineSD,
  HeaderLogoSD,
  HeaderNavLineSD,
  HeaderNavLineWrapActionSD,
  HeaderNavLineWrapLogoSD,
  HeaderNavLineLeftOfLogoSD,
  HeaderNavLinePlaceholderSD,
  HeaderBtnLoginSD,
  HeaderBtnLoginWrapSD,
  HeaderBtnLoginWrapOuterSD,
  // WrapNav,
  HeaderNavLineWrapToggleSD,
  HeaderToggleSD,
  HeaderMobileNavItemSD,
  HeaderMobileNavLinkSD,
  HeaderMobilePopupSD,
  HeaderMobilePopupContentSD,
  HeaderMobileNavSD,
  HeaderMobileNavListSD
} from "./styled";
import PopupKit from "../../kit/Popup/PopupKit";
// import HeaderLoggedNav from "../HeaderLogged/HeaderLoggedNav/HeaderLoggedNav";
import { FixIOSBodyOverflow, propValueOr } from "../../../helpers/common";
import { withRouter } from "react-router-dom";
import { config } from "../../../index";

const HEADER_NAV_ITEMS = [
  {
    title: "Log In",
    to: "/login"
  },
  {
    title: "dashboard",
    to: "/",
    exact: true
  }
];

class HeaderNotAuth extends Component {
  static propTypes = {
    user: PropTypes.shape({
      logged: PropTypes.bool,
      verified: PropTypes.bool,
      info: PropTypes.object
    }).isRequired,
    isTransparent: PropTypes.bool,
    // disabled button on registration page
    isDisabledJoinButton: PropTypes.bool
  };

  state = {
    isMobileDropDownMenuOpen: false,
    isStickyMode: false,
    topBarHeight: 0
  };

  mobileToggleRef = React.createRef();

  topBarRef = React.createRef();

  fixIOSBodyOverflow = new FixIOSBodyOverflow();

  componentDidMount() {
    window.addEventListener("scroll", this.handleDocumentScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleDocumentScroll);
    document.body.classList.remove("global-modal-is-open");
    this.fixIOSBodyOverflow.removeOverflow();
  }

  handleDocumentScroll = () => {
    const { isMobileDropDownMenuOpen } = this.state;
    const isStickyMode =
      (window.scrollY || document.documentElement.scrollTop) > 12;
    // in IOS doesn't work overflow hidden on body
    if (isMobileDropDownMenuOpen) {
      const height = this.topBarRef.current.getBoundingClientRect().bottom;
      this.setState({ topBarHeight: height });
    }

    this.setState({ isStickyMode });
    this.closeMobileMenu();
  };

  openMobileMenu = () => {
    const height = this.topBarRef.current.getBoundingClientRect().bottom;
    document.body.classList.add("global-modal-is-open");
    this.fixIOSBodyOverflow.addOverflow();

    this.setState({ topBarHeight: height, isMobileDropDownMenuOpen: true });
  };

  closeMobileMenu = () => {
    document.body.classList.remove("global-modal-is-open");
    this.fixIOSBodyOverflow.removeOverflow();
    this.setState({ isMobileDropDownMenuOpen: false });
  };

  renderTopLine = () => {
    return <HeaderWrapTopLineSD></HeaderWrapTopLineSD>;
  };

  renderNavLine = () => {
    const { isTransparent, location } = this.props;
    const { isStickyMode } = this.state;
    return (
      <HeaderNavLineSD
        isTransparent={isTransparent}
        isStickyMode={isStickyMode}
      >
        <HeaderNavLineLeftOfLogoSD>
          <HeaderNavLinePlaceholderSD />
        </HeaderNavLineLeftOfLogoSD>

        <HeaderNavLineWrapLogoSD>
          <HeaderLogoSD
            to={"/"}
            isTransparent={!isStickyMode && isTransparent}
            logo={propValueOr(config, "logoInfo")}
            path={location.pathname}
          />
        </HeaderNavLineWrapLogoSD>

        {/* {location.pathname !== "/" && (
          <WrapNav>{!config.hideNav && <HeaderLoggedNav />}</WrapNav>
        )} */}

        <HeaderNavLineWrapActionSD>
          <HeaderBtnLoginWrapOuterSD>
            <HeaderBtnLoginWrapSD>
              <HeaderBtnLoginSD />
            </HeaderBtnLoginWrapSD>
          </HeaderBtnLoginWrapOuterSD>
        </HeaderNavLineWrapActionSD>

        <HeaderNavLineWrapToggleSD>
          <HeaderToggleSD
            ref={this.mobileToggleRef}
            onClick={() => {
              this.openMobileMenu();
            }}
            isOpen={this.state.isMobileDropDownMenuOpen}
          />
        </HeaderNavLineWrapToggleSD>
        {this.renderMobileNav()}
      </HeaderNavLineSD>
    );
  };

  renderMobileNav = () => {
    const navItems = (config.hideNav
      ? [{ title: "Log In", to: "/login" }]
      : HEADER_NAV_ITEMS
    ).map((item, index) => {
      return (
        <HeaderMobileNavItemSD key={index}>
          {item.to ? (
            <HeaderMobileNavLinkSD
              to={item.to}
              exact={item.exact}
              onClick={() => this.closeMobileMenu()}
            >
              {item.title}
            </HeaderMobileNavLinkSD>
          ) : (
            <HeaderMobileNavLinkSD
              as={"a"}
              href={item.href}
              target="_blank"
              onClick={() => this.closeMobileMenu()}
              to={""}
            >
              {item.title}
            </HeaderMobileNavLinkSD>
          )}
        </HeaderMobileNavItemSD>
      );
    });
    return (
      <HeaderMobilePopupSD>
        <HeaderMobilePopupContentSD>
          <PopupKit
            disabledPortal
            isOpen={this.state.isMobileDropDownMenuOpen}
            onClose={() => this.closeMobileMenu()}
          >
            <HeaderMobileNavSD>
              <HeaderMobileNavListSD>{navItems}</HeaderMobileNavListSD>
            </HeaderMobileNavSD>
          </PopupKit>
        </HeaderMobilePopupContentSD>
      </HeaderMobilePopupSD>
    );
  };

  render() {
    const { isTransparent, noNavLine, location } = this.props;
    const { isStickyMode } = this.state;

    return (
      <HeaderSD
        isTransparent={
          !isStickyMode && isTransparent && location.pathname === "/"
        }
        ref={this.topBarRef}
      >
        {!noNavLine && this.renderNavLine()}
      </HeaderSD>
    );
  }
}

export default withRouter(HeaderNotAuth);
