import React, { Component } from "react";
import { connect } from "react-redux";
import RouteReports from "./RouteReports";
import { withRouter } from "react-router-dom";
import { getGlobalUser } from "../../store/user/selectors";
import PermissionDenied from "../denied/PermissionDenied";
import { api } from "../..";
import { propValueOr } from "../../helpers/common";
class RouteReportsContainer extends Component {
  constructor(props) {
    super();

    this.state = {
      link: "",
      loading: false,
      config: null,
      slug: null
    };
  }

  componentDidMount() {
    this.fetchOrganization();
  }

  fetchOrganization = () => {
    this.setState({ loading: true });
    api.admin.organizations
      .fetch(propValueOr(this.props, "user.info.organizationId"))
      .then(resp => {
        this.setState({
          slug: propValueOr(resp, "data.slug"),
          config: propValueOr(resp, "data.config"),
          link: propValueOr(resp, "data.config.report")
        });
      })
      .finally(() => this.setState({ loading: false }));
  };

  render() {
    const { permission, user } = this.props;
    const { config, link, slug } = this.state;
    if (!permission) {
      return <PermissionDenied />;
    }
    return (
      <RouteReports
        link={link}
        loading={this.state.loading}
        permission={permission}
        config={config}
        slug={slug}
        user={user}
        fetchOrganization={this.fetchOrganization}
      />
    );
  }
}

const mapStateProps = state => ({
  user: getGlobalUser(state)
});

export default connect(mapStateProps)(withRouter(RouteReportsContainer));
