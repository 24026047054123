import TransactionContainer from "./transaction/TransactionContainer";
import TransactionsContainer from "./transactions/TransactionsContainer";
import WalletContainer from "./wallet/WalletContainer";
import TransferContainer from "./transfer/TransferContainer";

export const routes = [
  {
    path: "/wallet",
    component: WalletContainer,
    exact: true
  },
  {
    path: "/wallet/transactions",
    component: TransactionsContainer,
    exact: true
  },
  {
    path: "/wallet/transactions/:id",
    component: TransactionContainer
  },
  {
    path: "/wallets/:id",
    component: WalletContainer,
    exact: true
  },
  {
    path: "/wallet/:id/transactions",
    component: TransactionsContainer
  },
  {
    path: "/wallet/transfer",
    component: TransferContainer,
    altPermission: "/wallet",
    exact: true
  }
];
