import { CHAT_ACTIONS } from "./constants";

export const pinChatChannel = chatObj => dispatch => {
  dispatch({
    type: CHAT_ACTIONS.add,
    payload: chatObj
  });
};

export const updatePinnedChat = chatObj => dispatch => {
  dispatch({
    type: CHAT_ACTIONS.update,
    payload: chatObj
  });
};

export const unpinChatChannel = channel => dispatch => {
  dispatch({
    type: CHAT_ACTIONS.delete,
    payload: channel
  });
};

export const resetChat = () => dispatch => {
  dispatch({
    type: CHAT_ACTIONS.reset
  });
};
