import { COLOR } from "../../../constants/theme";

export const chatTheme = {
  white: {
    main: {
      textColor: "#111"
    },
    header: {
      bgColor: "#fff",
      textColor: "#111"
    },
    body: {
      bgColor: "#fff"
    },
    footer: {
      bgColor: "#fff",
      placeholderColor: COLOR.GREY
    },
    message: {
      borderAvatarColor: "#111",
      bgColor: "#fff",
      textColor: "#111",
      modifiers: [
        {
          className: "",
          bgColor: "",
          textColor: ""
        },
        {
          className: "",
          bgColor: "",
          textColor: ""
        }
      ]
    },
    field: {
      borderColor: "#111",
      textColor: "#111",
      placeholderColor: "#0D0925"
    }
  },
  black: {
    main: {
      textColor: "#fff"
    },
    header: {
      bgColor: "#111",
      textColor: "#fff"
    },
    body: {
      bgColor: "#152431"
    },
    footer: {
      bgColor: COLOR.BG_SECONDARY,
      placeholderColor: "#0D0925"
    },
    message: {
      borderAvatarColor: "#fff",
      bgColor: COLOR.BG_ACCENT,
      bgColorModerator: COLOR.RIVAL_RED,
      textColor: COLOR.FG_INPUT,
      modifiers: [
        {
          className: "",
          bgColor: "",
          textColor: ""
        },
        {
          className: "",
          bgColor: "",
          textColor: ""
        }
      ]
    },
    field: {
      borderColor: "",
      textColor: COLOR.TEXT,
      placeholderColor: COLOR.FG_INPUT,
      bgColor: COLOR.BG_INPUT
    }
  }
};
