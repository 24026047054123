import styled, { css } from "styled-components";
import { FONT, COLOR } from "../../../../../../constants/theme";

export const ListItem = styled.li`
  display: flex;
  align-items: center;
  width: 100%;

  text-decoration: none;
  color: ${COLOR.TEXT};

  ${({ hover }) =>
    hover
      ? css`
          &:hover {
            opacity: 0.4;
          }
        `
      : null}
`;

export const ItemText = styled.div`
  font: ${FONT.PARAGRAPH};
`;

export const ProfileImage = styled.img`
  height: 50px;
`;

export const Children = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;
