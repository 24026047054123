import React from "react";
import PropTypes from "prop-types";
import { BlankSD, BlankTitleSD } from "./styled";

const Blank = ({ children, text }) => {
  return (
    <BlankSD>
      {children ? <>{children}</> : <BlankTitleSD>{text}</BlankTitleSD>}
    </BlankSD>
  );
};

Blank.propTypes = {
  children: PropTypes.node,
  text: PropTypes.node
};

export default Blank;
