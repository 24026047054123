import React, { Component } from "react";
import { connect } from "react-redux";
import {
  WrapPage,
  Content,
  Header,
  Title,
  WrapAsset,
  SubText,
  HeaderRow,
  WrapInfoIcon
} from "./styled";
import { withRouter } from "react-router-dom";
import Asset from "./Asset/Asset";
import PermissionDenied from "../denied/PermissionDenied";
import InfoDialog from "./InfoDialog/InfoDialog";
import GamePDF from "../../static/images/infoPDFs/game-info.pdf";
import InfoIcon from "../../static/icons/info-icon.svg";

class AssetsGame extends Component {
  constructor(props) {
    super();

    this.state = {
      isInfoDialogOpen: false
    };
  }

  renderInfoDialog() {
    return (
      <InfoDialog
        isOpen={this.state.isInfoDialogOpen}
        onClose={() => this.setState({ isInfoDialogOpen: false })}
        title={"Game Image Info"}
        images={[GamePDF]}
      />
    );
  }

  render() {
    if (!this.props.permission) {
      return <PermissionDenied />;
    }
    return (
      <WrapPage>
        <Content>
          <Header>
            <HeaderRow>
              <Title>Game</Title>
              <WrapInfoIcon
                src={InfoIcon}
                onClick={() => this.setState({ isInfoDialogOpen: true })}
              />
            </HeaderRow>
            <SubText>All images will be compressed down to 400KB</SubText>
          </Header>
          <WrapAsset>
            <Asset
              name="game-ad"
              image
              imageDimensions={[300, 250]}
              imgLabels={[
                "Recommended minimum width of 600px",
                "Recommended minimum height of 500px"
              ]}
              aspectRatioLimits={[{ value: 1.15 }, { value: 1.25 }]}
              link={{ notRequired: true }}
            />
          </WrapAsset>
          {this.renderInfoDialog()}
        </Content>
      </WrapPage>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user
});
const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AssetsGame));
