import { startCase, upperCase } from 'lodash';
import React, { Component } from 'react';
import FormFieldKit from '../../../../components/kit/Fields/FormField/FormField';
import RadioButtonKit from '../../../../components/kit/Fields/RadioButton/RadioButtonKit';
import { COLOR } from '../../../../constants/theme';
import { propValueOr, safePropValueOr } from '../../../../helpers/common';
import { Row } from '../styled';
import { Container, PlatformWrap, Platform, Content, Image } from './styled';
import CountryFieldKit from '../../../../components/kit/Fields/CountryField/CountryField';
import InputChips from '../../../../components/smart/ChipInput/InputChips';
class Info extends Component {
  render() {
    const {
      formState,
      onChangeField,
      addStage,
      updateStage,
      consoles,
      organization,
    } = this.props;

    const {
      readOnly,
      name,
      type,
      system,
      matchTemplateOptions,
      matchTemplateId,
      overrideMatchTemplateId,
      description,
      countries,
      ageRestrictionFrom,
      ageRestrictionTo,
      errors,
      clickedNext,
      maxPlayers,
      minPlayers,
      teamSize,
      maxTeam,
      isTeamTournament,
      gender,
      stageCreated,
      isTwitchRequired,
      isAccountRequired,
      verifiedRequired,
      featured,
      tournamentTemplate,
      autoCreate,
      affiliationWide,
      stages,
      matchMessage,
    } = formState;

    const matchTemplate = matchTemplateOptions.find(
      mt => mt.value === matchTemplateId
    );
    const consoleOb = consoles.find(c => c.id === system);
    const currentTemplate =
      matchTemplateOptions.find(opt => matchTemplateId === opt.value)?.label ||
      undefined;

    return (
      <Container>
        <FormFieldKit
          label={'Name of the Tournament (Limit 50 Characters)'}
          fullWidth
          placeholder={'Enter name...'}
          required
          value={name}
          inputProps={{ maxLength: 50 }}
          onChange={val => onChangeField('name', val)}
          disabled={readOnly}
          errorText={clickedNext && safePropValueOr(errors, 'Info.name')}
        />
        <Row>
          <FormFieldKit label={'Select a Console'} required>
            <PlatformWrap>
              {consoles.map(p => (
                <Platform
                  key={p.name}
                  onClick={() => {
                    if (!readOnly) {
                      onChangeField('system', p.id);
                      if (currentTemplate !== undefined) {
                        const newTemplate = matchTemplateOptions.find(
                          mt =>
                            mt.label === currentTemplate && mt.system === p.id
                        )?.value;
                        if (!!newTemplate) {
                          onChangeField('matchTemplateId', newTemplate);
                          return;
                        }
                      }
                      onChangeField('matchTemplateId', undefined);
                    }
                  }}
                  selected={p.id === system}
                >
                  <Content disabled={readOnly}>
                    <Image src={propValueOr(p, 'imageInfo.thumbnail')} />
                  </Content>
                </Platform>
              ))}
            </PlatformWrap>
          </FormFieldKit>
          <FormFieldKit
            label={'Select A Game'}
            fullWidth
            multiline
            required
            select
            disabled={system === undefined || readOnly}
            selectOptions={
              system === undefined
                ? []
                : matchTemplateOptions.filter(mt => mt.system === system)
            }
            value={
              overrideMatchTemplateId ||
              safePropValueOr(
                matchTemplateOptions.find(opt => matchTemplateId === opt.value),
                'label',
                undefined
              )
            }
            onChange={val => {
              onChangeField('matchTemplateId', val);
              const option = matchTemplateOptions.find(
                opt => val === opt.value
              );
              if (option) {
                onChangeField('imageInfo', { ...option.imageInfo });
                onChangeField('defaultSettings', { ...option.settings });
                if (!stageCreated) {
                  addStage({ ...option.settings });
                } else {
                  (stages || []).forEach(s => {
                    s.settings = option?.settings;
                    updateStage(s);
                  });
                }
              }
            }}
            placeholder={
              system === undefined ? 'Select a System First' : undefined
            }
            errorText={clickedNext && safePropValueOr(errors, 'Info.system')}
          />
        </Row>
        <Row>
          Required To Participate (Account must be connected in Passport)
        </Row>
        <Row style={{ justifyContent: 'flex-start', marginBottom: '15px' }}>
          <FormFieldKit
            label={'Twitch Required?'}
            checkbox
            name={'isTwitchRequired'}
            checked={isTwitchRequired}
            onChange={val => onChangeField('isTwitchRequired', val)}
            isBig
            width={'30%'}
          />
          {!!matchTemplateId &&
            !['username', safePropValueOr(consoleOb, 'username')].includes(
              matchTemplate?.username
            ) && (
              <FormFieldKit
                label={`${upperCase(
                  startCase(safePropValueOr(matchTemplate, 'username', ''))
                )} Required?`}
                checkbox
                name={'isAccountRequired'}
                checked={isAccountRequired}
                onChange={val => onChangeField('isAccountRequired', val)}
                isBig
                width={'30%'}
              />
            )}
        </Row>
        <Row
          style={{
            marginBottom: '5px',
            ...(clickedNext &&
              !!safePropValueOr(errors, 'Type.isTeamTournament') && {
                color: COLOR.CHERRY_RED,
              }),
          }}
        >
          Is there a gender restriction?
        </Row>
        <Row style={{ marginBottom: '10px' }}>
          <RadioButtonKit
            label={'Male'}
            horizontalLabel
            onChange={() => {
              onChangeField('gender', 'male');
            }}
            checked={gender === 'male'}
            fullWidth
          />
          <RadioButtonKit
            label={'Female'}
            horizontalLabel
            onChange={() => {
              onChangeField('gender', 'female');
            }}
            checked={gender === 'female'}
            fullWidth
          />
        </Row>
        <FormFieldKit
          label={'Tournament Description'}
          description={
            "The first 200 characters will show as the 'Short Description' on the Tournament Overview page. The rest will show on the 'Rules' tab."
          }
          fullWidth
          multiline
          disabled={readOnly}
          placeholder={'Write a description...'}
          value={description}
          onChange={val => onChangeField('description', val)}
        />
        {/* <FormFieldKit
          label={"Short Description (Limit 200 characters)"}
          fullWidth
          multiline
          disabled={readOnly}
          placeholder={"Write a short description..."}
          value={description}
          inputProps={{ maxLength: 200 }}
          onChange={val => onChangeField("description", val)}
        /> */}
        <Row
          style={{
            marginBottom: '5px',
            ...(clickedNext &&
              !!safePropValueOr(errors, 'Type.isTeamTournament') && {
                color: COLOR.CHERRY_RED,
              }),
          }}
        >
          Is this a TEAM Tournament?
          <span style={{ color: COLOR.CHERRY_RED, fontWeight: 'bold' }}>*</span>
        </Row>
        <Row style={{ marginBottom: '10px' }}>
          <RadioButtonKit
            label={'Yes'}
            horizontalLabel
            onChange={() => onChangeField('isTeamTournament', true)}
            checked={isTeamTournament}
            fullWidth
          />
          <RadioButtonKit
            label={'No'}
            horizontalLabel
            onChange={() => {
              onChangeField('isTeamTournament', false);
              onChangeField('teamSize', null);
            }}
            checked={isTeamTournament === false}
            fullWidth
          />
        </Row>
        {isTeamTournament && (
          <>
            <FormFieldKit
              label={'Team Size (minimum players per team)'}
              fullWidth
              required
              placeholder={'Enter team size'}
              type={'number'}
              min={(teamSize === 0 && minPlayers.toString()) || '2'}
              value={teamSize}
              onChange={val => {
                const newVal = parseInt(val);
                onChangeField('teamSize', isNaN(newVal) ? null : newVal);
              }}
              errorText={
                clickedNext && safePropValueOr(errors, 'Info.teamSize')
              }
            />
            <FormFieldKit
              label={'Maximum Players Per Team (optional)'}
              description={"If left blank this will be the 'Team Size'"}
              fullWidth
              required
              placeholder={'Enter maximum players per team'}
              type={'number'}
              min={teamSize || '2'}
              max={'15'}
              value={maxTeam}
              onChange={val => {
                let newVal = parseInt(val);
                if (newVal > 15) {
                  newVal = 15;
                }
                onChangeField('maxTeam', isNaN(newVal) ? null : newVal);
              }}
              errorText={clickedNext && safePropValueOr(errors, 'Info.maxTeam')}
            />
            {type === 'scheduled' && (
              <>
                <Row>
                  Rules Acceptance Required for All Team Members (Optional)
                </Row>
                <Row
                  style={{ justifyContent: 'flex-start', marginBottom: '15px' }}
                >
                  <FormFieldKit
                    label={
                      'If checked, every member of a team must accept rules or entire team will be removed at start time'
                    }
                    checkbox
                    name={'verifiedRequired'}
                    checked={verifiedRequired}
                    onChange={val => onChangeField('verifiedRequired', val)}
                    isBig
                    width={'30%'}
                  />
                </Row>
              </>
            )}
          </>
        )}
        <Row>
          <FormFieldKit
            label={isTeamTournament ? 'Minimum Teams' : 'Minimum Players'}
            description={`Minimum ${
              isTeamTournament ? 'teams' : 'players'
            } required for tournament to start`}
            fullWidth
            required
            placeholder={
              isTeamTournament ? 'Enter minimum teams' : 'Enter minimum players'
            }
            type={'number'}
            min={'2'}
            max={maxPlayers && maxPlayers.toString()}
            value={minPlayers}
            onChange={val => {
              const newVal = parseInt(val);
              onChangeField('minPlayers', newVal);
            }}
            errorText={
              clickedNext && safePropValueOr(errors, 'Info.minPlayers')
            }
          />
          <FormFieldKit
            label={
              isTeamTournament
                ? 'Maximum Teams (optional)'
                : 'Maximum Players (blank = no cap)'
            }
            description={`Maximum ${
              isTeamTournament ? 'teams' : 'players'
            } allowed to register for tournament`}
            fullWidth
            placeholder={'Leave blank for no limit on registrations'}
            type={'number'}
            min={
              ((minPlayers || minPlayers === 0) && minPlayers.toString()) || '2'
            }
            value={maxPlayers}
            onChange={val => {
              const newVal = parseInt(val);
              onChangeField('maxPlayers', isNaN(newVal) ? null : newVal);
            }}
            errorText={
              clickedNext && safePropValueOr(errors, 'Info.maxPlayers')
            }
          />
        </Row>
        <Row>
          <FormFieldKit
            label={'Minimum Age (Optional)'}
            fullWidth
            value={ageRestrictionFrom}
            type={'number'}
            inputProps={{ min: 1, max: 100 }}
            onChange={val => {
              const newVal = val === '' ? null : parseInt(val);
              if (!isNaN(newVal)) {
                onChangeField('ageRestrictionFrom', newVal);
              }
            }}
            placeholder={'Set a min age restriction'}
            disabled={readOnly}
            errorText={
              clickedNext && safePropValueOr(errors, 'Info.ageRestrictionFrom')
            }
          />
          <FormFieldKit
            label={'Maximum Age (Optional)'}
            fullWidth
            value={ageRestrictionTo}
            type={'number'}
            inputProps={{ min: 1, max: 100 }}
            onChange={val => {
              const newVal = val === '' ? null : parseInt(val);
              if (!isNaN(newVal)) {
                onChangeField('ageRestrictionTo', newVal);
              }
            }}
            placeholder={'Set a max age restriction'}
            disabled={readOnly}
            errorText={
              clickedNext && safePropValueOr(errors, 'Info.ageRestrictionTo')
            }
          />
        </Row>
        <Row>Allowed Countries "Whitelist" (Optional)</Row>
        <Row style={{ justifyContent: 'flex-start', marginBottom: '15px' }}>
          <CountryFieldKit
            searchable
            onChange={c => onChangeField('countries', [...countries, c])}
          />
          <InputChips
            onChange={c => onChangeField('countries', c)}
            readOnly
            hiddenInput
            small
            value={countries}
          />
        </Row>
        <FormFieldKit
          label={'Featured Tournament Order Number (Optional)'}
          description={
            'Enter a number to set the position of this tournament in the featured tournaments slider on the Game Center. Leave blank to make this not a featured tournament.'
          }
          fullWidth
          value={featured}
          type={'number'}
          inputProps={{ min: 1, max: 100 }}
          onChange={val => {
            const newVal = val === '' ? null : parseInt(val);
            if (!isNaN(newVal)) {
              onChangeField('featured', newVal);
            }
          }}
          placeholder={'Set featured order'}
        />
        {organization &&
          organization.type === 'affiliation' &&
          organization.isPrimary === false && (
            <FormFieldKit
              label={`Make affiliation-wide tournament?`}
              checkbox
              name={'affiliationWide'}
              checked={affiliationWide}
              onChange={val => onChangeField('affiliationWide', val)}
              isBig
              width={'30%'}
            />
          )}
        <FormFieldKit
          label={`Save tournament as template?`}
          checkbox
          name={'tournamentTemplate'}
          checked={tournamentTemplate}
          onChange={val => onChangeField('tournamentTemplate', val)}
          isBig
          width={'30%'}
        />
        <br />
        <FormFieldKit
          label={'Match Page Message'}
          fullWidth
          multiline
          description={
            'This will show up as the first message in the chat box on each match page in the tournament. If left blank will show the default based on elimination type.'
          }
          placeholder={'Enter custom match page message'}
          value={matchMessage}
          onChange={val => onChangeField('matchMessage', val)}
        />
        {tournamentTemplate && type === 'on-the-fly' && (
          <FormFieldKit
            label={'Number of Tournaments to Auto Create  (Optional)'}
            description={
              'Enter a number to set the number of tournaments that are spun up automatically. Leave blank to just use as template.'
            }
            fullWidth
            value={autoCreate}
            type={'number'}
            inputProps={{ min: 1, max: 10 }}
            onChange={val => {
              const newVal = val === '' ? null : parseInt(val);
              if (!isNaN(newVal)) {
                onChangeField('autoCreate', newVal);
              }
            }}
            placeholder={'Set auto create amount'}
          />
        )}
      </Container>
    );
  }
}

export default Info;
