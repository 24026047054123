import styled from "styled-components";
import { COLOR } from "../../../constants/theme";
import React from "react";
import breakpoints from "../../../helpers/breakpoints";

export const PaginationSD = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 21px;
  padding-bottom: 25px;
  background-color: ${COLOR.BG_ACCENT};
`;

const PaginationButtonSD = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  background-color: ${COLOR.BG_PRIMARY};
  cursor: pointer;
`;

export const PaginationButtonNextSD = styled(props => {
  return (
    <PaginationButtonSD className={props.className} {...props}>
      <div className="icon">
        <svg
          width="7px"
          height="11px"
          viewBox="0 0 7 11"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(-857.000000, -1514.000000)" fill="#FFFFFF">
              <g transform="translate(566.000000, 1501.000000)">
                <path
                  d="M296.898785,17.9456596 L292.15434,13.2012153 C292.027257,13.0741319 291.857812,13 291.677778,13 C291.307118,13 291,13.3071181 291,13.6777778 L291,23.1666665 C291,23.5373262 291.307118,23.8444443 291.677778,23.8444443 C291.857812,23.8444443 292.027257,23.7703123 292.15434,23.643229 L296.898785,18.8987846 C297.025868,18.7717013 297.1,18.6022569 297.1,18.4222221 C297.1,18.2421874 297.025868,18.072743 296.898785,17.9456596 Z"
                  id="RIGHT-ARROW"
                ></path>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </PaginationButtonSD>
  );
})`
  border-radius: 2px 0 0 2px;
`;

export const PaginationButtonPrevSD = styled(props => {
  return (
    <PaginationButtonSD className={props.className} {...props}>
      <div className="icon">
        <svg
          width="7px"
          height="12px"
          viewBox="0 0 7 12"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(-697.000000, -1514.000000)" fill="#FFFFFF">
              <g transform="translate(566.000000, 1501.000000)">
                <path
                  d="M137.1,17.4222221 C137.1,17.2421874 137.025868,17.072743 136.898785,16.9456596 L132.15434,12.2012153 C132.027257,12.0741319 131.857812,12 131.677778,12 C131.307118,12 131,12.3071181 131,12.6777778 L131,22.1666665 C131,22.5373262 131.307118,22.8444443 131.677778,22.8444443 C131.857812,22.8444443 132.027257,22.7703123 132.15434,22.643229 L136.898785,17.8987846 C137.025868,17.7717013 137.1,17.6022569 137.1,17.4222221 L137.1,17.4222221 Z"
                  id="LEFT-ARROW"
                  transform="translate(134.388885, 18.100000) rotate(-180.000000) translate(-134.388885, -18.100000) "
                ></path>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </PaginationButtonSD>
  );
})`
  border-radius: 0 2px 2px 0;
`;

export const PaginationInputSD = styled.input`
  height: 36px;
  width: 53px;
  text-align: center;
  border-radius: 0 2px 2px 0;
  border: 1px solid ${COLOR.BORDER};
  box-shadow: inset 0 1px 3px 0 rgba(153, 153, 153, 0.5),
    0 1px 1px 0 rgba(221, 221, 221, 0.5);
`;

export const PaginationTextSD = styled.p`
  margin: 0 6px;
  color: #fff;
  font-size: 14px;

  ${breakpoints.down("sm")} {
    font-size: 12px;
  }
`;

export const PaginationTextTotal = styled.span`
  display: inline-block;
  margin-left: 3px;
  text-decoration: underline;
  cursor: pointer;
`;
