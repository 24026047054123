const GAMES_ACTIONS = {
  list: {
    request: "GAMES/LIST_REQUEST",
    success: "GAMES/LIST_SUCCESS",
    error: "GAMES/LIST_ERROR"
  },
  fetch: {
    request: "GAMES/FETCH_REQUEST",
    success: "GAMES/FETCH_SUCCESS",
    error: "GAMES/FETCH_ERROR"
  },
  templates: {
    request: "GAMES/TEMPLATES_REQUEST",
    success: "GAMES/TEMPLATES_SUCCESS",
    error: "GAMES/TEMPLATES_ERROR"
  },
  reset: "GAMES/RESET_STATE"
};

export default GAMES_ACTIONS;
