import styled, { css } from "styled-components";
import { COLOR, FONT_FAMILY, FONT_WEIGHT } from "../../../../constants/theme";
import { Link } from "react-router-dom";
import breakpoints from "../../../../helpers/breakpoints";
import PropTypes from "prop-types";

export const Container = styled.div`
  min-height: 100%;
  width: 100%;
  margin-top: 10px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  > :not(:last-child) {
    border-bottom: 1px solid ${COLOR.BORDER};
  }
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;

  padding: 10px 0px;

  color: ${COLOR.TEXT};
  text-decoration: none;

  &:hover {
    opacity: 0.4;
  }
`;

export const WrapLink = styled(Link)`
  display: flex;
  flex: 1;
  color: ${COLOR.TEXT};
  text-decoration: none;
`;

export const Header = styled.div`
  display: flex;
`;

export const SearchField = styled.div`
  display: flex;
  width: 100%;
  margin: 20px 0;
`;

export const Info = styled.div`
  display: flex;
  align-items: center;
  flex: 2;
`;

export const PlayerProfile = styled.div`
  display: flex;
  align-items: center;

  padding: 10px 0px;
`;

export const ProfilePic = styled.img`
  width: 20px;
  height: 20px;

  margin-right: 10px;
`;

export const PlayerName = styled.div`
  font: ${FONT_WEIGHT.NORMAL} 14px ${FONT_FAMILY.THIN};
`;

export const Status = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
`;

export const Badge = styled.div`
  display: flex;
  justify-content: center;
  min-width: 85px;
  height: 20px;

  padding: 3px 10px;
  margin-right: 10px;
  border-radius: 12px 12px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  font: ${FONT_WEIGHT.NORMAL} 13px ${FONT_FAMILY.GOTHIC};
  text-transform: uppercase;

  background-color: ${({ status }) => {
    switch (status) {
      case "open":
        return COLOR.GREEN;
      case "pending":
        return COLOR.YELLOW;
      case "closed":
        return COLOR.GREY;
      default:
        return COLOR.BG_INPUT;
    }
  }};

  ${breakpoints.down("md")} {
    display: ${props => (!props.status ? "none" : "flex")};
  }
`;

export const TeamBadge = styled.div`
  display: flex;
  justify-content: center;
  min-width: 15px;
  height: 20px;

  padding: 3px 10px;
  margin-right: 10px;
  border-radius: 12px 12px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  font: ${FONT_WEIGHT.NORMAL} 13px ${FONT_FAMILY.GOTHIC};
  text-transform: uppercase;
  background-color: ${COLOR.BG_INPUT};

  ${breakpoints.down("md")} {
    display: ${props => (!props.status ? "none" : "flex")};
  }
`;

export const Message = styled.div`
  display: flex;
  width: 100%;

  padding: 5px 0px;

  font: ${FONT_WEIGHT.NORMAL} 12px ${FONT_FAMILY.THIN};
`;

export const FilterHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  width: 100%;
  background-color: ${COLOR.BG_SECONDARY};

  padding: 10px 0px;
`;

export const Filter = styled.div`
  display: flex;
  justify-content: flex-end;

  color: ${COLOR.TEXT};
  overflow: hidden;

  margin-right: 20px;

  border: 2px solid ${COLOR.RIVAL_RED};
  border-radius: 8px;
`;

const ACTIVE_SWITCHER_ITEM_STYLES = css`
  background-color: ${COLOR.RIVAL_RED};
  color: ${COLOR.WHITE};
`;

const NOT_ACTIVE_SWITCHER_ITEM_STYLES = css`
  background-color: ${COLOR.BG_SECONDARY};
  color: ${COLOR.WHITE};
`;

export const FilterItem = styled.div`
  width: 180px;
  padding: 12px 10px;

  font: ${FONT_WEIGHT.EXTRA_BOLD} 14px ${FONT_FAMILY.GOTHIC_BOLD};
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;

  cursor: pointer;
  transition: all 0.3s;

  ${_IS_ACTIVE_SWITCHER_ITEM_};

  &:hover {
    ${ACTIVE_SWITCHER_ITEM_STYLES};
  }

  &:not(:first-child) {
    margin-left: -1px;
  }

  ${breakpoints.down("xs")} {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 4px;
    padding-right: 4px;
  }

  ${breakpoints.down("md")} {
    width: 150px;
  }

  ${breakpoints.down("440")} {
    min-width: 0;
    font-size: 12px;
  }
`;

FilterItem.propTypes = {
  isActive: PropTypes.bool
};

function _IS_ACTIVE_SWITCHER_ITEM_({ isActive }) {
  return isActive
    ? ACTIVE_SWITCHER_ITEM_STYLES
    : NOT_ACTIVE_SWITCHER_ITEM_STYLES;
}

export const Name = styled.div`
  font: ${FONT_WEIGHT.NORMAL} 15px ${FONT_FAMILY.GOTHIC};
  margin-left: 5px;
`;

export const Avatar = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 2px;
  padding: 1px;
  background-color: ${COLOR.FG_PRIMARY};
  margin: 0 5px;
`;

export const Icon = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 2px;
  margin: 0 5px;

  ${breakpoints.down("md")} {
    display: none;
  }
`;

export const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-left: 20px;

  width: 170px;
`;

export const IconWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  &:hover {
    opacity: 0.4;
  }
`;

export const StatusBadge = styled(Badge)`
  width: 100px;
  background-color: ${({ status }) => {
    switch (status) {
      case "initialized":
        return COLOR.GREEN;
      case "in progress":
        return COLOR.YELLOW;
      case "complete":
        return COLOR.GREY;
      default:
        return COLOR.BG_INPUT;
    }
  }};

  ${breakpoints.down("sm")} {
    width: 75px;
  }
`;

export const DateBadge = styled(Badge)`
  background-color: ${COLOR.BG_INPUT};

  width: 140px;
`;

export const LinkBadge = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 85px;
  height: 20px;

  padding: 5px 10px;
  margin-right: 10px;
  border-radius: 12px 12px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  background-color: ${COLOR.RIVAL_RED};
  color: ${COLOR.TEXT};
  font: ${FONT_WEIGHT.NORMAL} 13px ${FONT_FAMILY.GOTHIC};
  text-transform: uppercase;
  text-decoration: none;

  cursor: pointer;
  &:hover {
    opacity: 0.4;
  }

  ${breakpoints.down("sm")} {
    min-width: 50px;
  }
`;

export const TeamsIcon = styled.img`
  width: 18px;
  margin-right: 3px;
`;

export const ArrowIcon = styled.img`
  width: 10px;

  margin-left: 5px;
`;
