import React, { Component, Fragment } from "react";
import styled, { css } from "styled-components";
import { COLOR, FONT_FAMILY, FONT_WEIGHT } from "../constants/theme";

const DELTA = 7;

/**
 * @name withTooltip
 * @description HOC to add tooltip to a component
 * @param {Component} WrappedComponent - Component to be wrapped
 * @prop {string} tooltip - Tooltip object with the following properties:
 * @required {string} tooltip.text - Text to be displayed in tooltip
 * @required {string} tooltip.position - Position of tooltip - must be one of: [top, bottom, left, right]
 * @optional {number} tooltip.delta - margin offset of tooltip
 */
function withTooltip(ReceivedComponent) {
  const getPosition = (tooltip, tooltipRef, targetRef) => {
    const targetEl = targetRef?.current || {};
    const tooltipEl = tooltipRef?.current || {};
    const position = {
      x: targetEl.offsetLeft,
      y: targetEl.offsetTop
    };
    const delta = tooltip?.delta || DELTA;
    if (tooltip?.position === "top") {
      position.y = position.y - targetEl.offsetHeight - delta;
      position.x = position.x - tooltipEl.offsetWidth / 2;
    } else if (tooltip?.position === "left") {
      position.x = position.x - tooltipEl.offsetWidth - delta;
    } else if (tooltip?.position === "right") {
      position.x = position.x + tooltipEl.offsetWidth + delta;
    } else {
      position.y = position.y + targetEl.offsetHeight + delta;
      position.x = position.x - tooltipEl.offsetWidth / 2;
    }
    return position;
  };

  return class withTooltip extends Component {
    constructor(props) {
      super(props);

      this.state = {
        show: false,
        ref: React.createRef(),
        tRef: React.createRef(),
        added: false
      };
    }

    componentDidUpdate(prevProps, prevState) {
      if (
        (!this.state.added && this.state.ref?.current) ||
        (!prevState.ref?.current && this.state.ref?.current)
      ) {
        this.state.ref.current.addEventListener(
          "mouseover",
          this.handleMouseOver
        );
        this.state.ref.current.addEventListener(
          "mouseout",
          this.handleMouseOut
        );
        this.setState({ added: true });
      }
    }

    componentWillUnmount() {
      this.state.ref?.current?.removeEventListener(
        "mouseover",
        this.handleMouseOver
      );
      this.state.ref?.current?.removeEventListener(
        "mouseover",
        this.handleMouseOver
      );
      this.setState({ added: false });
    }

    handleMouseOver = () => {
      this.setState({ show: true });
    };

    handleMouseOut = () => {
      this.setState({ show: false });
    };

    render() {
      const { show, ref, tRef } = this.state;
      const { tooltip, ...rest } = this.props;
      return (
        <Fragment>
          {tooltip?.text && (
            <Tooltip
              ref={tRef}
              show={show}
              position={getPosition(tooltip, tRef, ref)}
            >
              {tooltip?.text || ""}
            </Tooltip>
          )}
          <ReceivedComponent {...rest} ref={ref} />
        </Fragment>
      );
    }
  };
}

export default withTooltip;

const Tooltip = styled.div`
  transition: opacity 0.3s ease-in-out;
  display: block;
  visibility: hidden;
  position: absolute;
  background-color: ${COLOR.BG_PRIMARY};
  color: ${COLOR.TEXT};
  padding: 7px 10px;
  border-radius: 8px;
  font: ${FONT_WEIGHT.NORMAL} 14px ${FONT_FAMILY.THIN};
  white-space: nowrap;
  opacity: 0;

  ${({ position }) => {
    return (
      position &&
      css`
        top: ${position.y}px;
        left: ${position.x}px;
      `
    );
  }}

  ${({ show }) =>
    show &&
    css`
      visibility: visible;
      opacity: 1;
    `}
`;
