import AssetsDashboard from "./AssetsDashboard";
import AssetsLeaderboard from "./AssetsLeaderboard";
import AssetsProfile from "./AssetsProfile";
import AssetsConfig from "./AssetsConfig";
import AssetsGame from "./AssetsGame";
import AssetsEmail from "./AssetsEmail";

/*Note: for all routes property exact is true !!!*/
const routes = [
  {
    path: "/assets/config",
    contentComponent: AssetsConfig
  },
  {
    path: "/assets/dashboard",
    contentComponent: AssetsDashboard
  },
  {
    path: "/assets/leaderboard",
    contentComponent: AssetsLeaderboard
  },
  {
    path: "/assets/profile",
    contentComponent: AssetsProfile
  },
  {
    path: "/assets/game",
    contentComponent: AssetsGame
  },
  {
    path: "/assets/email",
    contentComponent: AssetsEmail
  }
];

export default routes;
