import styled from "styled-components";
import {
  FONT_WEIGHT,
  FONT_FAMILY,
  COLOR
} from "../../../../../../constants/theme";

export const WrapContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${COLOR.BG_PRIMARY};
  width: 100%;
`;

export const Container = styled.div`
  min-height: 100%;
  width: 100%;
  max-width: 1115px;

  color: ${COLOR.TEXT};

  > * {
    margin-bottom: 20px;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  border-bottom: 1px solid ${COLOR.BORDER_2};
  background-color: ${COLOR.BG_SECONDARY};
`;

export const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 20px 10px 20px;
`;

export const HeaderTitle = styled.div`
  display: flex;
  align-items: center;

  font: ${FONT_WEIGHT.MEDIUM} 14px / 26px ${FONT_FAMILY.THIN};
  color: ${COLOR.ROUGE};
  text-transform: uppercase;
`;

export const TournamentTitle = styled.div`
  display: flex;
  align-items: center;

  font: ${FONT_WEIGHT.MEDIUM} 24px / 28px ${FONT_FAMILY.THIN};
  text-transform: uppercase;
`;

export const UnregisterButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-left: auto;
  margin-right: 10px;
`;

export const IconLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 0;
`;

export const Icon = styled.img`
  cursor: pointer;
  width: 32px;
  height: 100%;
`;

export const IconSmall = styled.img`
  cursor: pointer;
  width: 32px;
  height: 32px;

  margin-right: 15px;
`;

export const WrapButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 5px;
  height: 100%;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 2px;

  padding: 20px 30px;

  background-color: ${COLOR.BG_SECONDARY};
`;

export const CardTitle = styled.div`
  display: flex;
  width: 100%;

  margin-bottom: 5px;

  font: ${FONT_WEIGHT.MEDIUM} 18px ${FONT_FAMILY.THIN};
  text-transform: uppercase;
`;

export const TeamCard = styled(Card)`
  flex-direction: row;
  background-color: ${COLOR.BG_ACCENT};
  padding: 20px 30px;
`;

export const TeamGame = styled.div`
  display: flex;
  align-items: center;

  ${({ isMobile }) =>
    isMobile
      ? `flex-direction: column;
  justify-content: center;
  > *{
    margin-bottom: 20px;
  }`
      : null}

  
  font: ${FONT_WEIGHT.MEDIUM} 22px ${FONT_FAMILY.THIN};
`;

export const TeamPlayerInfo = styled.div`
  display: flex;
  ${({ isMobile }) =>
    isMobile
      ? `flex-direction: column;
  align-items: flex-start;
  > * {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
  `
      : `flex-direction: row;
  align-items: center;
  `};
  justify-content: flex-end;
  justify-self: flex-end;
`;

const DefaultCounter = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: inherit;
  color: inherit;

  border-radius: 50%;
  font: ${FONT_WEIGHT.BOLD} 16px ${FONT_FAMILY.CONDENSED};

  height: 60px;
  width: 60px;
`;

export const Buffer = styled.div`
  flex: 1;
`;

export const CounterContainer = styled.div`
  display: flex;
  flex-wrap: no-wrap;
  align-items: center;
  justify-content: center;
`;

export const CounterTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 0px 10px 0px 10px;

  font: ${FONT_WEIGHT.BOLD} 14px ${FONT_FAMILY.CONDENSED};
  text-transform: uppercase;
`;

export const PlayerCount = styled(DefaultCounter)`
  border: 2px solid #169617;
`;

export const PendingInvites = styled(DefaultCounter)`
  border: 2px solid #ffb700;
`;

export const ProfileList = styled.ul`
  padding: 0;
  margin: 0;

  > li {
    padding: 10px 0px;

    &:not(:last-child) {
      border-bottom: 1px solid ${COLOR.BG_PRIMARY};
    }
  }
`;

export const ButtonWrapper = styled.div`
  margin: 0px 10px;
`;

export const ThumbnailImages = styled.div`
  display: flex;
  margin: 0px 20px;

  > * {
    margin: 0px 5px;
  }
`;

export const ImageThumbnail = styled.img`
  max-width: 32px;
  height: auto;
`;

export const Console = styled.img`
  width: 60px;
`;

export const PlaceHolderCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 80px;

  font: ${FONT_WEIGHT.MEDIUM} 14px ${FONT_FAMILY.THIN};
  text-transform: uppercase;
`;

export const RemoveTooltip = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  width: 60px;
  font: ${FONT_WEIGHT.LIGHT} 12px ${FONT_FAMILY.THIN};
  background-color: ${COLOR.BG_PRIMARY};
  padding: 5px;
  border-radius: 2px;

  &:after {
    content: " ";
    position: absolute;
    top: 50%;
    left: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent ${COLOR.BG_PRIMARY};
  }
`;

export const TooltipArrow = styled.div``;
