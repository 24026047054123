import styled from "styled-components";
import { COLOR, FONT_FAMILY, FONT_WEIGHT } from "../../constants/theme";
import breakpoints from "../../helpers/breakpoints";
import { Link } from "react-router-dom";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${COLOR.BG_PRIMARY};
  padding: 25px;
  height: 100vh;
  ${breakpoints.down("sm")} {
    padding: 0;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-radius: 20px;
  width: 100%;
  max-width: 1000px;
  height: 100%;
  background-color: ${COLOR.BG_SECONDARY};
`;

export const Header = styled.div`
  display: flex;
  align-items: center;

  background-color: ${COLOR.BG_INPUT};
  color: ${COLOR.TEXT};
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-top: 1px solid ${COLOR.BORDER};
  border-bottom: 1px solid ${COLOR.BORDER};
  border-right: 1px solid ${COLOR.BORDER};

  width: 100%;
  height: 50px;
`;

export const HeaderInfo = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
  height: 100%;

  padding: 10px;
`;

export const Info = styled.div`
  display: flex;
  align-items: center;
  flex: 2;
`;

export const Players = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: space-evenly;
`;

export const Player = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
  max-width: 120px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const Name = styled.div`
  font: ${FONT_WEIGHT.NORMAL} 13px ${FONT_FAMILY.GOTHIC};
`;

export const WrapIcons = styled(Link)``;

export const Avatar = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 2px;
  padding: 1px;
  background-color: ${COLOR.FG_PRIMARY};
  margin: 0 5px;
  user-select: none;
`;

export const Icon = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 2px;
  margin: 0 5px;
  user-select: none;
`;
