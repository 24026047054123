import styled from "styled-components";
import { COLOR, FONT_FAMILY, FONT_WEIGHT } from "../../../constants/theme";

export const Container = styled.div`
  display: flex;
  border-radius: 10px;
  align-items: center;
  background-color: ${COLOR.BG_SECONDARY};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  max-width: 800px;
`;

export const Title = styled.div`
  display: flex;
  font: ${FONT_WEIGHT.NORMAL} 20px ${FONT_FAMILY.GOTHIC};
  color: ${COLOR.FG_PRIMARY};
  margin: 20px 0px;
`;

export const Field = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 10px 0px;
  border-top: solid 1px ${COLOR.BG_PRIMARY};
`;

export const Wrapper = styled.div`
  display: flex;
  flex: 3;
`;

export const Label = styled.div`
  font: ${FONT_WEIGHT.NORMAL} 20px ${FONT_FAMILY.GOTHIC};
  color: ${COLOR.FG_PRIMARY};
  flex: 1;
  max-width: 100px;
`;

export const Balance = styled.div`
  font: ${FONT_WEIGHT.NORMAL} 20px ${FONT_FAMILY.GOTHIC};
  color: ${COLOR.FG_PRIMARY};
  flex: 1;
  text-align: right;
`;

export const Error = styled.div`
  display: flex;
  justify-content: center;
  font: ${FONT_WEIGHT.NORMAL} 16px ${FONT_FAMILY.GOTHIC};
  color: ${COLOR.CHERRY_RED};
  margin: 10px 0px;
`;

export const WrapIcon = styled.div``;
export const Icon = styled.img`
  width: 100%;
  max-width: 100px;
  height: auto;
`;
