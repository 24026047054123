import { ACTIONS_ACTIONS } from "./constants";
import { api } from "../../index";

export const listActionsAction = query => dispatch => {
  dispatch({
    type: ACTIONS_ACTIONS.list.request
  });
  api.action
    .list(query)
    .then(resp => {
      dispatch({
        type: ACTIONS_ACTIONS.list.success,
        payload: resp.data
      });
      return resp;
    })
    .catch(err => {
      dispatch({ type: ACTIONS_ACTIONS.list.error });
      throw err;
    });
};

export const resetActions = () => dispatch => {
  dispatch({
    type: ACTIONS_ACTIONS.reset
  });
};
