import React from "react";
import { connect } from "react-redux";
import DialogKit from "../../../../../../components/kit/Dialog/DialogKit";
import IconPlus from "../../../../../../components/kit/Icon/IconPlus";
import ButtonKit from "../../../../../../components/kit/Button/ButtonKit";
import {
  Container,
  Header,
  HeaderTitle,
  BtnClose,
  Content,
  SectionTitle,
  Profile,
  ProfileList,
  CheckmarkImg,
  Divider,
  Buttons,
  PlaceholderText
} from "./styled";
import PlayerItem from "../PlayerList/PlayerItem";

import withWidth from "../../../../../../hoc/withWidth";

import CheckIcon from "../../../../../../static/icons/circle-check-green.svg";
import PlayerListItem from "../PlayerList/PlayerItem";
import { api } from "../../../../../..";
import { propValueOr } from "../../../../../../helpers/common";
import { withRouter } from "react-router-dom";
import { fetchTournamentAction } from "../../../../../../store/tournament/actions";

class ReplaceDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: "",
      incomingPlayerIndex: null,
      sending: false,
      isConfirmationScreen: false
    };
  }

  handleReplacement() {
    const { team, match, replacePlayer, players } = this.props;
    const { incomingPlayerIndex } = this.state;

    if (!replacePlayer.id || !propValueOr(players[incomingPlayerIndex], "id")) {
      return;
    }

    const body = {
      replacee: replacePlayer.id,
      replacer: players[incomingPlayerIndex].id
    };

    const token = match.params.token;
    this.setState({ loading: true });
    api.tournament.teams
      .replace(token, team.id, body)
      .then(resp => {
        this.props.fetchTournamentAction(token);
        this.setState({ success: true });
        this.close();
      })
      .catch(err =>
        this.setState({
          error: propValueOr(err, "response.data.message", err.message)
        })
      )
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  close() {
    this.setState({
      isConfirmationScreen: false,
      incomingPlayerIndex: null,
      error: null
    });
    this.props.onClose();
  }

  renderSelection() {
    const { replacePlayer, players } = this.props;
    const { sending } = this.state;

    return (
      <Container>
        <Header>
          <HeaderTitle>Replace Player</HeaderTitle>
          <BtnClose onClick={() => this.close()}>
            <IconPlus width={19} />
          </BtnClose>
        </Header>
        <Content>
          <SectionTitle>Replacing:</SectionTitle>
          <Profile>
            <PlayerItem rowData={replacePlayer}></PlayerItem>
          </Profile>
          <SectionTitle>Team Players:</SectionTitle>
          <ProfileList>
            {!!players.length ? (
              players.map((player, index) => {
                return player.id !== this.props.replacePlayer.id ? (
                  <PlayerItem
                    key={player.id}
                    rowData={player}
                    onClick={() =>
                      this.setState({ incomingPlayerIndex: index })
                    }
                  >
                    {index === this.state.incomingPlayerIndex ? (
                      <CheckmarkImg src={CheckIcon} />
                    ) : null}
                  </PlayerItem>
                ) : null;
              })
            ) : (
              <PlaceholderText>No other players to select</PlaceholderText>
            )}
          </ProfileList>
        </Content>
        <Divider />
        <Buttons>
          <ButtonKit
            fullWidth
            appearance="secondary"
            onClick={() => this.close()}
          >
            Cancel
          </ButtonKit>
          <ButtonKit
            preloader={sending}
            onClick={() => this.setState({ isConfirmationScreen: true })}
            fullWidth
            color={"red"}
            disabled={!players.length}
          >
            Next
          </ButtonKit>
        </Buttons>
      </Container>
    );
  }

  renderConfirmation() {
    const { incomingPlayerIndex, error } = this.state;
    const { replacePlayer, players } = this.props;
    const incomingPlayer = players[incomingPlayerIndex];
    return (
      <Container>
        <Header>
          <HeaderTitle>Confirm Replace Player</HeaderTitle>
        </Header>
        <Content>
          <SectionTitle>Replace:</SectionTitle>
          <Profile>
            <PlayerListItem rowData={replacePlayer} />
          </Profile>
          <SectionTitle>With:</SectionTitle>
          <Profile>
            <PlayerListItem rowData={incomingPlayer} />
          </Profile>
        </Content>
        {error && (
          <div style={{ color: "red", textAlign: "center", marginTop: "7px" }}>
            {error}
          </div>
        )}
        <Buttons>
          <ButtonKit
            fullWidth
            appearance="primary"
            onClick={() => this.setState({ isConfirmationScreen: false })}
            disabled={error}
          >
            Back
          </ButtonKit>
          <ButtonKit
            fullWidth
            appearance="secondary"
            onClick={() => this.close()}
          >
            Cancel
          </ButtonKit>
          <ButtonKit
            fullWidth
            color={"red"}
            onClick={() => {
              this.handleReplacement();
            }}
            disabled={error}
          >
            Confirm
          </ButtonKit>
        </Buttons>
      </Container>
    );
  }

  renderDialog = () => {
    const { isConfirmationScreen } = this.state;

    return isConfirmationScreen
      ? this.renderConfirmation()
      : this.renderSelection();
  };

  render() {
    const { isOpen } = this.props;

    return (
      <DialogKit
        isOpen={isOpen}
        onClose={() => this.close()}
        renderCustom={this.renderDialog}
        displayButtonClose={false}
      />
    );
  }
}

const mapStateProps = state => ({
  team: state.teamState.team
});

const mapDispatchToProps = {
  fetchTournamentAction
};

export default connect(
  mapStateProps,
  mapDispatchToProps
)(withWidth(withRouter(ReplaceDialog)));
