import React, { Component } from "react";
import PropTypes from "prop-types";
import { stateToHTML } from "draft-js-export-html";
import {
  Form,
  FormField,
  FormFieldWithSearch,
  FormHeader,
  FormAttachments,
  FormAttachmentImages,
  FormAttachmentWrap,
  AttachmentIcon,
  Image,
  ImageIcon,
  ImageWrap,
  Recipients,
  RecipientsItem,
  RecipientsItemContent
} from "../styled";
import FormFieldKit from "../../../components/kit/Fields/FormField/FormField";
import RichTextEditor from "../../../components/kit/RichTextEditor/RichTextEditor";
import FileKit from "../../../components/kit/Fields/File/FileKit";
import { COLOR } from "../../../constants/theme";
import { addUnit, propValueOr, safePropValueOr } from "../../../helpers/common";
import { convertFromRaw, convertToRaw, EditorState, RichUtils } from "draft-js";
import { api } from "../../../index";
import IconClose from "../../../components/kit/Icon/IconClose";
import InputChips from "../../../components/smart/ChipInput/InputChips";
import FieldTypeAndSelect from "../../../components/smart/FieldTypeAndSelect/FieldTypeAndSelect";
import IconCirclePlus from "../../../components/kit/Icon/IconCirclePlus";
import createStyles from "draft-js-custom-styles";
import Loader from "../../../components/presentation/Loader/Loader";
import { withRouter } from "react-router-dom";
import withWidth from "../../../hoc/withWidth";
import { dateToSting } from "../../../helpers/date";
import EmailDialog from "../../../components/dialogs/EmailDialog/EmailDialog";
import IconPreloader from "../../../components/kit/Icon/IconPreloader";
import { isEmailValid, isValidUrl } from "../../../helpers/fields-validations";
import EmailFormPanel from "./EmailFormPanel";
import EmailFormActions from "./EmailFormActions";

const { styles, customStyleFn, exporter } = createStyles(
  ["font-size"],
  "CUSTOM_"
);

class EmailForm extends Component {
  constructor(props) {
    super(props);

    this.state = this.initialState(props);
    this.inputLinkRef = React.createRef();
  }

  initialState = props => {
    const { email, user, preview } = props;
    const from =
      {
        label: "noreply@rivalgames.com",
        value: "noreply@rivalgames.com",
        email: "noreply@rivalgames.com"
      } ||
      (propValueOr(email, "from", null) && {
        label: email.from,
        value: email.from,
        email: email.from
      }) ||
      (user && {
        label: propValueOr(user, "info.email", ""),
        value: propValueOr(user, "info.email", ""),
        email: propValueOr(user, "info.email", "")
      }) ||
      null;

    return {
      editorState:
        email && email.message
          ? EditorState.createWithContent(
              convertFromRaw(JSON.parse(email.message))
            )
          : EditorState.createEmpty(),
      linkUrlValue: "",
      header: propValueOr(email, "header", null),
      footer: propValueOr(email, "footer", null),
      isUploadingHeader: false,
      isUploadingFooter: false,
      id: propValueOr(email, "id", null),
      name: propValueOr(email, "name", ""),
      from,
      readOnly: preview || false,
      title: propValueOr(email, "title", ""),
      createdAt: propValueOr(email, "createdAt", null),
      sendAt: propValueOr(email, "sendAt", null),
      updatedAt: propValueOr(email, "updatedAt", null),
      attachments: propValueOr(email, "attachments", []),
      isLoadingAttachments: false,
      to: JSON.parse(propValueOr(email, "to", null)) || [],
      currentRecipient: null,
      currentRecipientEmail: "",
      currentRecipientList: null,
      fontSizePopupIsOpen: false,
      linkFormPopupIsOpen: false,
      isTournamentPlayersTypeDialogOpen: false,
      isCreatingDraft: false,
      isCreatingTemplate: false,
      isRecipientsListDialogOpen: false,
      isSendTestDialogOpen: false,
      errors: {}
    };
  };

  resetState = () => {
    this.setState({
      ...this.initialState(this.props)
    });
  };

  setFiledErrors = (propName, value = null) => {
    const { errors } = this.state;

    this.setState({
      errors: {
        ...errors,
        [propName]: value
      }
    });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      (!prevProps.email && this.props.email) ||
      (!prevProps.isEmpty && this.props.isEmpty)
    ) {
      this.resetState();
    }
  }

  componentWillUnmount() {
    const { history, location } = this.props;
    const saveEmail = safePropValueOr(
      history.location,
      "state.saveEmail",
      false
    );

    if (!saveEmail && history.location.pathname !== location.pathname) {
      this.props.deleteEmail();
    }
  }

  onLinkInputKeyDown = e => {
    if (e.which === 13) {
      this.confirmLink(e);
    }
  };

  setEditorRef = ref => (this.editorRef = ref);

  handleEditorChange = editorState => this.setState({ editorState });

  focusEditor = () => {
    setTimeout(this.editorRef.focus(), 5);
  };

  toggleFontSize = fontSize => {
    const newEditorState = styles.fontSize.toggle(
      this.state.editorState,
      addUnit(fontSize)
    );
    return this.handleEditorChange(newEditorState);
  };

  removeFontSize = () => {
    const newEditorState = styles.fontSize.remove(this.state.editorState);
    return this.handleEditorChange(newEditorState);
  };

  validateRecipients = () => {
    const { to } = this.state;
    let isValid;
    if (!!to && !!to.length) {
      isValid = true;
      this.setFiledErrors("to", null);
    } else {
      isValid = false;
      this.setFiledErrors("to", "Please choose recipient.");
    }
    return isValid;
  };

  validateTemplateName = () => {
    const { name } = this.state;
    let isValid;

    if (!!name.trim().length) {
      this.setFiledErrors("name", null);
      isValid = true;
    } else {
      this.setFiledErrors("name", "Please enter template name.");
      isValid = false;
    }

    return isValid;
  };

  addRecipient = (recipient, callback) => {
    let to = [...this.state.to];

    let index = to.findIndex(r => r.value === recipient.value);
    if (index === -1 && recipient.value !== undefined) {
      to.push(recipient);
    }

    this.setState({ to }, callback);
  };

  choosePlayersType = type => {
    const { currentRecipient } = this.state;
    if (currentRecipient) {
      this.addRecipient({
        ...currentRecipient,
        type: type === "remaining" ? "tournament_remaining" : "tournament"
      });
      this.setState({
        isTournamentPlayersTypeDialogOpen: false,
        currentRecipient: null
      });
    }
  };

  handleOnKeyDown = (e, callback) => {
    const isKeyValid =
      e.key === "Enter" ||
      e.key === "Tab" ||
      e.key === " " ||
      e.key === ";" ||
      e.key === ",";

    if (isKeyValid) {
      const value = e.target.value;
      if (!isEmailValid(value.toLowerCase())) {
        this.setFiledErrors("email", "Please enter valid email address.");
      } else {
        const recipient = {
          label: value,
          name: value,
          type: "email",
          value
        };
        this.addRecipient(recipient);
        callback();
        this.setFiledErrors("email", null);
      }
    } else {
      this.setState({
        errors: {
          ...this.state.errors,
          email: null,
          to: null
        }
      });
    }
  };

  changeRecipients = (recipient, notSelect) => {
    if (!recipient) {
      return false;
    }

    if (recipient.type === "tournament" && !notSelect) {
      this.setState({
        isTournamentPlayersTypeDialogOpen: true,
        currentRecipient: recipient
      });
      return false;
    }

    this.addRecipient(recipient);
  };

  removeRecipient = (newRecipients = []) => {
    this.setState({
      to: this.state.to.filter(r => {
        const value = `${r.name} ${r.id ? "ID: " + r.id : ""}`;
        return newRecipients.includes(value);
      })
    });
  };

  handleSearchRecipients = async searchWord => {
    try {
      const { data } = await api.searchRecipients(searchWord);
      const organizations = data.organizations.map(r => ({
        label: r.name,
        value: r.slug,
        type: "organization",
        ...r
      }));

      const tournaments = data.tournaments.map(t => ({
        label: t.name,
        value: t.token,
        type: "tournament",
        ...t
      }));
      return [...organizations, ...tournaments];
    } catch (err) {
      console.error(err);
    }
  };

  handleChangeField = (propName, val) => {
    this.setState({ [propName]: val });
  };

  handleDeleteFile = id => {
    this.setState({
      attachments: this.state.attachments.filter(x => x.id !== id)
    });
  };

  handleChangeFile = async e => {
    const files = e.target.files;

    if (!files) {
      return false;
    }

    const attachments = this.state.attachments || [];

    try {
      this.setState({ isLoadingAttachments: true });
      for (const file of files) {
        const policy = await api.getS3ImagePolicy("cc/attach", file.name);
        const { url, id } = policy;
        await api.uploadS3File(file, policy);
        attachments.push({
          id,
          url,
          type: file.type,
          name: file.name
        });
      }
      this.setState({ isLoadingAttachments: false });
    } catch (err) {
      this.setState({ isLoadingAttachments: false });
      console.log(err);
    }

    this.setState({
      attachments
    });
  };

  uploadFileToS3 = async (uploadingFlagName, fileType, filePath, e) => {
    const currentFile = e.target.files[0];

    if (!currentFile) {
      return false;
    }

    try {
      this.setState({ [uploadingFlagName]: true });

      const policy = await api.getS3ImagePolicy(filePath, currentFile.name);

      const { url, id } = policy;

      await api.uploadS3File(currentFile, policy);

      this.setState({
        [fileType]: { id, url },
        [uploadingFlagName]: false
      });
    } catch (err) {
      this.setState({ [uploadingFlagName]: false });
      console.log("error", err);
    }
  };

  handleChangeFooter = async e =>
    this.uploadFileToS3("isUploadingFooter", "footer", "cc/attach/footer", e);
  handleChangeHeader = async e =>
    this.uploadFileToS3("isUploadingHeader", "header", "cc/attach/header", e);

  closeFontSizePopup = () => this.setState({ fontSizePopupIsOpen: false });

  toggleFontSizePopup = () => {
    this.setState(prevState => ({
      fontSizePopupIsOpen: !prevState.fontSizePopupIsOpen
    }));
  };

  closeLinkFromPopup = () => this.setState({ linkFormPopupIsOpen: false });

  toggleLinkFromPopup = () => {
    const { linkFormPopupIsOpen } = this.state;

    if (linkFormPopupIsOpen) {
      this.setState({ linkFormPopupIsOpen: false });
      return;
    }

    const { editorState } = this.state;
    const selection = editorState.getSelection();

    if (selection.isCollapsed()) {
      return; // Nothing is selected
    }

    const contentState = editorState.getCurrentContent();
    const startKey = editorState.getSelection().getStartKey();
    const startOffset = editorState.getSelection().getStartOffset();
    const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
    const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);
    let url = "";
    if (linkKey) {
      const linkInstance = contentState.getEntity(linkKey);
      url = linkInstance.getData().url;
    }

    this.setState({
      linkFormPopupIsOpen: true,
      linkUrlValue: url
    });
  };

  confirmLink = e => {
    e.preventDefault();

    const { editorState, linkUrlValue } = this.state;
    const contentState = editorState.getCurrentContent();

    if (!isValidUrl(linkUrlValue)) {
      this.setFiledErrors("linkUrlValue", "Not valid link url");
    } else {
      const contentStateWithEntity = contentState.createEntity(
        "LINK",
        "MUTABLE",
        { url: linkUrlValue }
      );

      const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

      let nextEditorState = EditorState.set(editorState, {
        currentContent: contentStateWithEntity
      });

      nextEditorState = RichUtils.toggleLink(
        nextEditorState,
        nextEditorState.getSelection(),
        entityKey
      );
      this.setFiledErrors("linkUrlValue", null);
      this.setState(
        {
          editorState: nextEditorState,
          linkFormPopupIsOpen: false,
          linkUrlValue: ""
        },
        () => {
          setTimeout(() => this.editorRef.focus(), 0);
        }
      );
    }
  };

  handleShowRecipientsList = async item => {
    const id = item.type === "organization" ? item.slug : item.token;
    try {
      const { data } = await api.getRecipients(item.type, id);
      const objects = data.map(item => {
        return {
          value: item,
          selected: true
        };
      });
      this.setState({
        isRecipientsListDialogOpen: true,
        currentRecipientList: objects,
        currentRecipient: item
      });

      return item.type === "tournament";
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  handleSelectRecipientFromList = value => {
    let list = [...this.state.currentRecipientList];
    let index = list.findIndex(r => r.value === value);

    if (index > -1) {
      list[index] = {
        ...list[index],
        selected: !list[index].selected
      };
    }

    this.setState({
      currentRecipientList: list
    });
  };

  handleCloseRecipientsList = () => {
    this.setState({
      isRecipientsListDialogOpen: false,
      currentRecipientList: null,
      currentRecipient: null
    });
  };

  handleSetRecipientsList = () => {
    const { currentRecipient, currentRecipientList, to } = this.state;

    const recipients = [...to];
    let index = recipients.findIndex(r => {
      if (currentRecipient.type === "organization") {
        return r.slug === currentRecipient.slug;
      } else {
        return r.token === currentRecipient.token;
      }
    });

    if (index > -1) {
      const options = [
        ...safePropValueOr(recipients[index], "options", []),
        ...currentRecipientList
          .filter(r => r.selected === true)
          .map(el => el.value)
      ];

      const type =
        recipients[index].type === "organization"
          ? "organization_users"
          : "tournament_users";

      recipients[index] = {
        ...recipients[index],
        options,
        type
      };
    }

    this.setState({
      isRecipientsListDialogOpen: false,
      currentRecipient: null,
      currentRecipientList: null,
      to: recipients
    });
  };

  renderRecipientsListDialog = () => {
    const { isRecipientsListDialogOpen, currentRecipientList } = this.state;

    return (
      <EmailDialog
        title={"Please select recipients"}
        isOpen={isRecipientsListDialogOpen}
        onClose={this.handleCloseRecipientsList}
        onConfirm={() => null}
        actions={[
          {
            label: "Select",
            method: this.handleSetRecipientsList,
            disabled:
              currentRecipientList &&
              currentRecipientList.every(el => el.selected === false)
          },
          {
            label: "Cancel",
            method: this.handleCloseRecipientsList
          }
        ]}
      >
        <Recipients>
          {currentRecipientList &&
            currentRecipientList.map((recipient, index) => {
              return (
                <RecipientsItem key={index}>
                  <FormFieldKit
                    width={"auto"}
                    checkbox
                    onChange={() =>
                      this.handleSelectRecipientFromList(recipient.value)
                    }
                    disabled={false}
                    checked={recipient.selected}
                    isBig
                  />
                  <RecipientsItemContent>
                    {recipient.value}
                  </RecipientsItemContent>
                </RecipientsItem>
              );
            })}
        </Recipients>
      </EmailDialog>
    );
  };

  renderTournamentPlayersTypeDialog = () => {
    const { isTournamentPlayersTypeDialogOpen } = this.state;

    return (
      <EmailDialog
        title={"SEND EMAIL TO"}
        text={`Please select who will receive this email`}
        isOpen={isTournamentPlayersTypeDialogOpen}
        onClose={() =>
          this.setState({ isTournamentPlayersTypeDialogOpen: false })
        }
        onConfirm={() => null}
        width={480}
        actions={[
          {
            label: "All players",
            method: () => this.choosePlayersType("all")
          },
          {
            label: "Remaining players",
            method: () => this.choosePlayersType("remaining")
          }
        ]}
      />
    );
  };

  renderImageAttachments = () => {
    const { attachments, readOnly } = this.state;
    if (!attachments) {
      return null;
    }

    const attachmentImages = attachments.filter(a =>
      a.type.startsWith("image/")
    );
    return attachmentImages.map((image, index) => {
      const src = safePropValueOr(image, "url", null);

      return (
        <ImageWrap key={index}>
          {!readOnly && (
            <ImageIcon onClick={() => this.handleDeleteFile(image.id)}>
              <IconClose width={10} />
            </ImageIcon>
          )}
          <Image onClick={() => this.handleSetPreviewImage(src)} src={src} />
        </ImageWrap>
      );
    });
  };

  renderOtherAttachments = () => {
    const { attachments, readOnly } = this.state;

    if (!attachments) {
      return null;
    }

    const otherAttachments = attachments.filter(
      a => !a.type.startsWith("image/")
    );
    return otherAttachments.map((attachment, index) => {
      return (
        <FormAttachmentWrap>
          <span>{attachment.name}</span>
          {!readOnly && (
            <AttachmentIcon
              onClick={() => this.handleDeleteFile(attachment.id)}
            >
              <IconClose width={10} />
            </AttachmentIcon>
          )}
        </FormAttachmentWrap>
      );
    });
  };

  renderAttachments = () => {
    const { isLoadingAttachments } = this.state;

    if (isLoadingAttachments) {
      return <IconPreloader />;
    }

    return (
      <>
        {this.renderOtherAttachments()}
        <FormAttachmentImages>
          {this.renderImageAttachments()}
        </FormAttachmentImages>
      </>
    );
  };

  renderImage = imageType => {
    const image = this.state[imageType];
    const { readOnly } = this.state;
    const src = safePropValueOr(image, "url", null);

    return (
      <FormHeader>
        <ImageWrap>
          {!readOnly && (
            <ImageIcon onClick={() => this.setState({ [imageType]: null })}>
              <IconClose width={14} />
            </ImageIcon>
          )}
          <Image onClick={() => this.handleSetPreviewImage(src)} src={src} />
        </ImageWrap>
      </FormHeader>
    );
  };

  renderHeaderImage = () => this.renderImage("header");
  renderFooterImage = () => this.renderImage("footer");

  addSender = from => {
    if (!from) {
      return;
    }

    this.setState({
      from
    });
  };

  handleSearchSender = searchWord => {
    return api
      .search(searchWord, 10, 1, true)
      .then(resp =>
        resp.data.users.map(u => ({ label: u.email, value: u.email, ...u }))
      );
  };

  renderFiledSender = () => {
    const selectedOption = {
      label: "noreply@rivalgames.com",
      value: "noreply@rivalgames.com"
    };

    return (
      <FormField>
        <FormFieldKit
          fullWidth
          label={"From"}
          horizontalLabel
          horizontalLabelBox
          horizontalLabelWidth={63}
          gutterBottom={0}
        >
          <FormFieldWithSearch>
            <FieldTypeAndSelect
              placeholder={""}
              onChange={e => this.addSender(e)}
              onSearch={this.handleSearchSender}
              selectedOption={selectedOption}
              readOnly={true}
            />
          </FormFieldWithSearch>
        </FormFieldKit>
      </FormField>
    );
  };

  renderFiledRecipients = value => {
    const { errors } = this.state;
    return (
      <FormField>
        <FormFieldKit
          fullWidth
          label={"To"}
          horizontalLabel
          horizontalLabelBox
          horizontalLabelWidth={63}
          gutterBottom={0}
          errorText={
            safePropValueOr(errors, "email", null) ||
            safePropValueOr(errors, "to", null)
          }
        >
          <FormFieldWithSearch>
            <InputChips
              value={value}
              onChange={e => this.removeRecipient(e)}
              placeholder={""}
              readOnly
              hiddenInput
            />
            <FieldTypeAndSelect
              placeholder={""}
              onChange={(value, notSelect) =>
                this.changeRecipients(value, notSelect)
              }
              onKeyDown={this.handleOnKeyDown}
              onSearch={this.handleSearchRecipients}
              icon={<IconCirclePlus width={"1em"} />}
              showItemId
              onShowPopup={item => this.handleShowRecipientsList(item)}
              skipKeys={[" ", ";", ","]}
            />
          </FormFieldWithSearch>
        </FormFieldKit>
      </FormField>
    );
  };

  getEmail = () => {
    const {
      id,
      name,
      from,
      title,
      sendAt,
      attachments,
      to,
      header,
      footer
    } = this.state;

    const inlineStyles = exporter(this.state.editorState);
    const html = stateToHTML(this.state.editorState.getCurrentContent(), {
      inlineStyles
    });

    const message = JSON.stringify(
      convertToRaw(this.state.editorState.getCurrentContent())
    );

    return {
      id,
      name,
      from: safePropValueOr(from, "email", null),
      to: JSON.stringify(to),
      title,
      header,
      footer,
      message,
      messageHtml: html,
      sendAt: dateToSting(sendAt, "YYYY-MM-DD HH:mm:ssZZ"),
      attachments
    };
  };

  handleSetPreviewImage = src => {
    const email = this.getEmail();
    this.props.addEmail(email);
    this.props.handleSetImage(src);
  };

  onSchedule = email => {
    const valid = this.validateRecipients();
    if (valid) {
      this.props.handleSchedule(email);
    }
  };

  onCreateTemplate = email => {
    const valid = this.validateTemplateName();
    if (valid) {
      this.props.handleCreateTemplate(email);
    }
  };

  onCreateGlobalTemplate = email => {
    const valid = this.validateTemplateName();
    if (valid) {
      this.props.handleCreateGlobalTemplate(email);
    }
  };

  onUpdateTemplate = email => {
    const valid = this.validateTemplateName();
    if (valid) {
      this.props.handleUpdateTemplate(email);
    }
  };

  onConfirmSend = (email, to) => {
    const valid = this.validateRecipients();
    if (valid) {
      this.props.handleConfirmSend(email, to);
    }
  };

  render() {
    const {
      name,
      title,
      to,
      editorState,
      header,
      footer,
      errors,
      isUploadingHeader,
      isUploadingFooter,
      readOnly,
      fontSizePopupIsOpen,
      linkFormPopupIsOpen,
      sendAt,
      linkUrlValue
    } = this.state;

    const {
      type,
      loading,
      width,
      setSendAtDate,
      isScheduling,
      isCreatingDraft,
      isUpdating,
      isCreatingTemplate,
      handleSchedule,
      handleUpdate,
      handleCreateDraft,
      handleDiscard,
      handleRedirectToCreateTemplate,
      handleRedirectToCreateGlobalTemplate,
      handleCreateTemplate,
      handleCreateGlobalTemplate,
      handleUpdateTemplate,
      handleSendTestEmail
    } = this.props;

    const email = this.getEmail();

    return loading ? (
      <Loader isBlock />
    ) : (
      <>
        <Form>
          {type !== "template" && this.renderFiledSender()}
          {type !== "template" &&
            this.renderFiledRecipients(
              to.map(r => {
                return `${r.name} ${r.id ? "ID: " + r.id : ""}`;
              })
            )}
          {type === "template" && (
            <FormField variant={"primary"}>
              <FormFieldKit
                fullWidth
                label={"Name of Template"}
                placeholder={""}
                value={name}
                inputProps={{ maxLength: 200 }}
                onChange={val => this.handleChangeField("name", val)}
                gutterBottom={0}
                required
                disabled={readOnly}
                errorText={safePropValueOr(errors, "name", null)}
              />
            </FormField>
          )}
          <FormField>
            <FormFieldKit
              fullWidth
              label={"Subject"}
              placeholder={""}
              value={title}
              inputProps={{ maxLength: 200 }}
              onChange={val => this.handleChangeField("title", val)}
              gutterBottom={0}
              disabled={readOnly}
            />
          </FormField>
          <FormField>
            {header ? (
              this.renderHeaderImage()
            ) : (
              <FileKit
                onChange={e => this.handleChangeHeader(e)}
                accept={"image/*"}
                width={"100%"}
                height={"auto"}
                preloader={isUploadingHeader}
                variant={"primary"}
                color={COLOR.RIVAL_RED}
                disabled={readOnly}
              >
                Insert Header Graphic (Optional)
              </FileKit>
            )}
          </FormField>
          <FormField>
            <RichTextEditor
              minHeight={width > 600 ? 400 : 240}
              setEditorRef={this.setEditorRef.bind(this)}
              editorState={editorState}
              readOnly={readOnly}
              customStyleFn={customStyleFn}
              placeholder={"Begin Typing…"}
              focusEditor={this.focusEditor.bind(this)}
              onChange={this.handleEditorChange.bind(this)}
            />
          </FormField>
          <FormField>
            {footer ? (
              this.renderFooterImage()
            ) : (
              <FileKit
                onChange={e => this.handleChangeFooter(e)}
                accept={"image/*"}
                width={"100%"}
                height={"auto"}
                preloader={isUploadingFooter}
                variant={"primary"}
                color={COLOR.RIVAL_RED}
                disabled={readOnly}
              >
                Insert Footer Graphic (Optional)
              </FileKit>
            )}
          </FormField>

          <FormAttachments>{this.renderAttachments()}</FormAttachments>
          <EmailFormPanel
            inputLinkRef={this.inputLinkRef}
            fontSizePopupIsOpen={fontSizePopupIsOpen}
            linkFormPopupIsOpen={linkFormPopupIsOpen}
            editorState={editorState}
            sendAt={sendAt}
            readOnly={readOnly}
            linkUrlValue={linkUrlValue}
            setSendAtDate={setSendAtDate}
            type={type}
            handleChangeFile={this.handleChangeFile}
            toggleFontSizePopup={this.toggleFontSizePopup}
            closeFontSizePopup={this.closeFontSizePopup}
            toggleFontSize={this.toggleFontSize}
            currentFontSize={styles.fontSize.current(editorState)}
            toggleLinkFromPopup={this.toggleLinkFromPopup}
            closeLinkFromPopup={this.closeLinkFromPopup}
            onChangeDate={dt => {
              this.setState({ sendAt: dt }, () => {
                typeof setSendAtDate === "function" && setSendAtDate(dt);
              });
            }}
            changeLinkUrlValue={val => this.setState({ linkUrlValue: val })}
            confirmLink={this.confirmLink}
            errors={errors}
          />
          <EmailFormActions
            email={email}
            to={to}
            sendAt={sendAt}
            readOnly={readOnly}
            isScheduling={isScheduling}
            isCreatingDraft={isCreatingDraft}
            isUpdating={isUpdating}
            isCreatingTemplate={isCreatingTemplate}
            handleSchedule={handleSchedule}
            handleUpdate={handleUpdate}
            handleCreateDraft={handleCreateDraft}
            handleDiscard={handleDiscard}
            handleRedirectToCreateTemplate={handleRedirectToCreateTemplate}
            handleRedirectToCreateGlobalTemplate={
              handleRedirectToCreateGlobalTemplate
            }
            handleCreateTemplate={handleCreateTemplate}
            handleCreateGlobalTemplate={handleCreateGlobalTemplate}
            handleUpdateTemplate={handleUpdateTemplate}
            handleSendTestEmail={handleSendTestEmail}
            onSchedule={() => this.onSchedule(email)}
            onConfirmSend={() => this.onConfirmSend(email, to)}
            onUpdateTemplate={() => this.onUpdateTemplate(email)}
            onCreateTemplate={() => this.onCreateTemplate(email)}
            onCreateGlobalTemplate={() => this.onCreateGlobalTemplate(email)}
          />
        </Form>
        {this.renderTournamentPlayersTypeDialog()}
        {this.renderRecipientsListDialog()}
      </>
    );
  }
}

EmailForm.propTypes = {
  user: PropTypes.object,
  email: PropTypes.object,
  type: PropTypes.string,
  addEmail: PropTypes.func,
  deleteEmail: PropTypes.func,
  loading: PropTypes.bool,
  isEmpty: PropTypes.bool,
  isScheduling: PropTypes.bool,
  isCreatingDraft: PropTypes.bool,
  isUpdating: PropTypes.bool,
  isSendingTemplate: PropTypes.bool,
  handleSchedule: PropTypes.func,
  handleUpdate: PropTypes.func,
  handleCreateDraft: PropTypes.func,
  handleDiscard: PropTypes.func,
  handleRedirectToCreateTemplate: PropTypes.func,
  handleCreateTemplate: PropTypes.func,
  handleUpdateTemplate: PropTypes.func,
  handleSendTestEmail: PropTypes.func
};

export default withWidth(withRouter(EmailForm));
