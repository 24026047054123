import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import Route404 from "../../routes/404/Route404";
import Route500 from "../../routes/500/Route500";
import { layoutLoggedRoutes } from "./routes";
import LayoutLogged from "../../layouts/logged/LayoutLogged";
import RouteChat from "../../routes/chat/RouteChat";
import { verifyPermission } from "../../helpers/common";
import { pinChatChannel } from "../../store/chat/actions";

class AuthorizedLayoutRouting extends Component {
  componentDidMount() {
    const { permissions, pinChatChannel } = this.props;
    if (verifyPermission("/global-moderator-chat", permissions) === "admin") {
      pinChatChannel({ channel: "rcc", openWindow: false });
    }
  }

  render() {
    const { user, permissions } = this.props;
    return (
      <Switch>
        {/*if you need to add other layouts for authorized users. Please use Route component to detect which layout has to render. (See NotAuthorizedLayoutRouting component as an example)*/}

        <Route
          key={"chat"}
          path={"/chat/:channel"}
          render={() => {
            return <RouteChat {...this.props} />;
          }}
        />
        {/*Render LayoutLogged*/}
        <Route
          render={props => (
            <LayoutLogged {...props} user={user}>
              {user.requestStatus === 500 ? (
                <Route500 />
              ) : user.requestStatus === 404 ? (
                <Route404 />
              ) : (
                <Switch>
                  {layoutLoggedRoutes.map(route => {
                    return (
                      <Route
                        key={route.path}
                        path={route.path}
                        exact={route.exact}
                        render={props => (
                          <route.component
                            {...props}
                            user={user}
                            permission={verifyPermission(
                              route.path,
                              permissions
                            )}
                          />
                        )}
                      />
                    );
                  })}

                  <Route component={Route404} />
                </Switch>
              )}
            </LayoutLogged>
          )}
        />
      </Switch>
    );
  }
}

AuthorizedLayoutRouting.propTypes = {};

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  pinChatChannel
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthorizedLayoutRouting);
