import React, { Component } from "react";
import PropTypes from "prop-types";
import DialogKit from "../../../../../components/kit/Dialog/DialogKit";
import { connect } from "react-redux";
import IconPlus from "../../../../../components/kit/Icon/IconPlus";
import IconCheck from "../../../../../static/icons/circle-check-green.svg";
import IconWarning from "../../../../../static/icons/icon-warning.svg";
import {
  Container,
  Header,
  Body,
  ButtonClose,
  WrapButtons,
  Text,
  ScoreSubmitted,
  CheckMark,
  WarningCircle,
  WarningSymbol,
  WarningText
} from "./styled";
import ButtonKit from "../../../../../components/kit/Button/ButtonKit";
import { api } from "../../../../../index";

class ModeratorDialog extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      showModeratorSubmitted: false
    };
  }

  onSubmit = () => {
    const { tournament, onClose } = this.props;
    api.tournament.join(tournament.token, { moderatorAt: new Date() });
    if (!tournament.moderatorAt) {
      this.setState({ showModeratorSubmitted: true });
      setTimeout(() => {
        this.setState({ showModeratorSubmitted: false });
        onClose();
      }, 2000);
    } else {
      onClose();
    }
  };

  renderSubmitted = () => {
    const { onClose } = this.props;

    return (
      <Container>
        <Header>
          Moderator Request Submitted
          <ButtonClose onClick={onClose}>
            <IconPlus width={19} />
          </ButtonClose>
        </Header>
        <Body>
          <ScoreSubmitted>
            <CheckMark src={IconCheck} />
            <Text>Please wait for a moderator</Text>
          </ScoreSubmitted>
        </Body>
      </Container>
    );
  };

  renderRequest = () => {
    const { onClose } = this.props;

    return (
      <Container>
        <Header>
          Request a Moderator
          <ButtonClose onClick={onClose}>
            <IconPlus width={19} />
          </ButtonClose>
        </Header>
        <Body>
          <ScoreSubmitted>
            <WarningCircle>
              <WarningSymbol src={IconWarning} />
            </WarningCircle>
            <WarningText>Would you like to request a moderator?</WarningText>
            <ButtonKit
              fullWidth
              onClick={() => {
                this.onSubmit();
              }}
              color={"red"}
              style={{ marginBottom: "20px" }}
            >
              Request
            </ButtonKit>
            <ButtonKit
              fullWidth
              onClick={() => {
                onClose();
              }}
              appearance={"secondary"}
            >
              Cancel
            </ButtonKit>
          </ScoreSubmitted>
        </Body>
      </Container>
    );
  };

  renderCancel = () => {
    const { onClose } = this.props;

    return (
      <Container>
        <Header>
          End Moderator Request
          <ButtonClose onClick={onClose}>
            <IconPlus width={19} />
          </ButtonClose>
        </Header>
        <Body>
          <Text>Are you sure you want to end the moderator request?</Text>

          <WrapButtons>
            <ButtonKit fullWidth appearance={"secondary"} onClick={onClose}>
              Cancel
            </ButtonKit>
            <ButtonKit fullWidth color={"red"} onClick={this.onSubmit}>
              End
            </ButtonKit>
          </WrapButtons>
        </Body>
      </Container>
    );
  };

  renderDialog = () => {
    const { showModeratorSubmitted } = this.state;
    const { tournament } = this.props;
    if (showModeratorSubmitted) {
      return this.renderSubmitted();
    } else if (tournament.moderatorAt) {
      return this.renderCancel();
    } else {
      return this.renderRequest();
    }
  };

  render() {
    const { isOpen, onClose } = this.props;

    return (
      <DialogKit
        isOpen={isOpen}
        onClose={onClose}
        renderCustom={this.renderDialog}
        displayButtonClose={false}
      />
    );
  }
}

const mapStateProps = state => ({
  tournament: state.tournamentState.tournament
});

const mapDispatchToProps = {};

export default connect(mapStateProps, mapDispatchToProps)(ModeratorDialog);
