import Analytics from "./Analytics/Analytics";
import Membership from "./Membership/Membership";
import Tournament from "./Tournament/Tournament";

export const routes = [
  {
    path: "/reports/analytics",
    component: Analytics,
    exact: true
  },
  {
    path: "/reports/membership",
    component: Membership,
    exact: true
  },
  {
    path: "/reports/tournament",
    component: Tournament,
    exact: true
  }
];
