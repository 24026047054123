import styled from "styled-components";
import { COLOR } from "../../../../constants/theme";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${COLOR.BG_SECONDARY};
  border-radius: 4px;
  margin-bottom: 25px;
  padding: 0 10px;
  width: 100%;
  z-index: 10;
  > :first-child {
    margin-bottom: 25px;
  }
`;

export const WrapStages = styled.div`
  width: 100%;
  border-top: 1px solid ${COLOR.BORDER};
  border-bottom: 1px solid ${COLOR.BORDER};

  > :not(:last-child) {
    border-bottom: 1px solid ${COLOR.BORDER};
  }
`;
