import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Tournaments from "./TournamentsList/TournamentsList";
import {
  listTournamentAction,
  resetTournamentAction
} from "../../../store/tournament/actions";
import Pusher from "pusher-js";
import * as queryString from "query-string";
import PermissionDenied from "../../denied/PermissionDenied";
import { Container, ContentCard } from "./styled";
import { propValueOr } from "../../../helpers/common";
class TournamentsContainer extends Component {
  constructor(props) {
    super(props);

    this.pusher = new Pusher(process.env.REACT_APP_PUSHER_ID || "", {
      cluster: "us3",
      forceTLS: true
    });

    this.state = {
      searchValue: ""
    };
  }

  componentDidMount() {
    this.fetchTournaments();

    const channel = this.pusher.subscribe(`tournaments`);

    channel.unbind();

    channel.bind("reload", () => {
      this.fetchTournaments();
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      this.fetchTournaments();
    }
  }

  componentWillUnmount() {
    this.pusher.unsubscribe(`tournaments`);
    this.props.resetTournaments();
  }

  fetchTournaments = () => {
    this.props.fetchTournaments(this.getQueryParams());
  };

  getQueryParams = () => {
    const parsed = queryString.parse(this.props.location.search);

    return {
      status: parsed.status || "initialized",
      page: parsed.page || 1,
      limit: 50,
      q: parsed.q,
      organizationId: this.props.user.organizationId,
      affiliationId: this.props.affiliationId
    };
  };

  onChangeSearch = q => {
    this.setState({ searchValue: q });
  };

  onChangeFilter = filter => {
    const oldFilter = queryString.parse(this.props.location.search);

    const newFilter = {
      ...oldFilter,
      page: 1,
      ...filter
    };

    this.props.history.push({
      pathname: "/tournaments",
      search: `?${queryString.stringify(newFilter)}`
    });
  };

  render() {
    const { permission } = this.props;
    if (!permission) {
      return <PermissionDenied />;
    }
    return (
      <Container>
        <ContentCard>
          <Tournaments
            filter={this.getQueryParams()}
            onChangeFilter={this.onChangeFilter}
            onChangeSearch={this.onChangeSearch}
            getTournaments={this.fetchTournaments}
            searchValue={this.state.searchValue}
            {...this.props}
          />
        </ContentCard>
      </Container>
    );
  }
}

const mapStateProps = state => ({
  user: state.user.info,
  tournaments: state.tournamentState.tournaments,
  isFetching: state.tournamentState.isFetching,
  total: state.tournamentState.total,
  affiliationId: (
    state.organizationsState.organizations.find(
      o => o.id === propValueOr(state, "user.info.organizationId", null)
    ) || {}
  )?.affiliationId
});

const mapDispatchToProps = {
  fetchTournaments: listTournamentAction,
  resetTournaments: resetTournamentAction
};

export default connect(
  mapStateProps,
  mapDispatchToProps
)(withRouter(TournamentsContainer));
