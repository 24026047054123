import React, { Component } from "react";
import FormFieldKit from "../../../../components/kit/Fields/FormField/FormField";
import { Container } from "./styled";
import { propValueOr } from "../../../../helpers/common";

class GameConsoleSelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gameList: []
    };
  }

  componentDidMount() {
    this.getGamesAndConsoles();
  }

  componentDidUpdate(prevProps) {
    if (
      propValueOr(prevProps, "templates", []).length !==
      propValueOr(this.props, "templates", []).length
    ) {
      this.getGamesAndConsoles();
    }
  }

  getGamesAndConsoles() {
    const { templates, selectedConsoleId } = this.props;

    const gameList = [];
    const gameIds = [];

    templates.forEach(template => {
      const consoleId = propValueOr(template, "ConsoleId");
      const game = propValueOr(template, "Game");
      if (selectedConsoleId !== undefined && consoleId === selectedConsoleId) {
        gameIds.push(game.id);
        gameList.push(game);
      }
    });
    gameList.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
    this.setState({ gameList });
  }

  onConsoleChange(val) {
    this.props.onConsoleChange(val, () => this.getGamesAndConsoles());
  }

  onGameChange(val) {
    this.props.onGameChange(val);
  }

  render() {
    const {
      gameRequired,
      consoleRequired,
      selectedGameId,
      selectedConsoleId,
      consoles
    } = this.props;
    const { gameList } = this.state;

    return (
      <Container>
        <FormFieldKit
          label={"Console"}
          fullWidth
          required={!!consoleRequired}
          select
          selectOptions={consoles.map(console => ({
            label: console.name,
            value: console
          }))}
          value={consoles.find(c => c.id === selectedConsoleId)}
          onChange={val => {
            this.onConsoleChange(val);
          }}
          placeholder={"Select a Console..."}
        />
        <FormFieldKit
          label={"Game"}
          fullWidth
          required={!!gameRequired}
          select
          disabled={selectedConsoleId === undefined}
          selectOptions={gameList.map(game => ({
            label: game.name,
            value: game
          }))}
          value={gameList.find(g => g.id === selectedGameId)}
          onChange={val => {
            this.onGameChange(val);
          }}
          placeholder={
            selectedConsoleId === undefined
              ? "Select a Console First"
              : "Select A Game"
          }
        />
      </Container>
    );
  }
}

export default GameConsoleSelection;
