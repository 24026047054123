export const getImageSrcFromThumbnail = thumbnail => {
  if (!thumbnail || typeof thumbnail !== "string") {
    return null;
  }

  const pattern = /^\bhttp:\/\/|https:\/\/|data:/;

  if (pattern.test(thumbnail)) {
    return thumbnail;
  }

  let result;

  result = null;

  return result;
};

// export const fileToDataUri = file => URL.createObjectURL(file);
export const fileToDataUri = file =>
  new Promise(resolve => {
    const reader = new FileReader();
    const { type, name, size } = file;

    reader.onload = e => {
      resolve({
        base64: reader.result,
        name: name,
        type,
        size: size
      });
    };
    reader.readAsDataURL(file);
  });
