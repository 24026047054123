import moment from "moment";
import momentTz from "moment-timezone";

const zone_name = momentTz.tz.guess();
const timezone = momentTz.tz(zone_name).zoneAbbr();

export const hoursToDaysString = hours => {
  if (typeof hours !== "number") {
    return "";
  }

  if (hours === 0) {
    return "now";
  }

  if (hours < 24) {
    return `${hours} hr`;
  }

  const days = Math.floor(hours / 24);

  return `${days} day${days > 1 ? "s" : ""}`;
};

export const dateToSting = (date, format = "M/DD/YY @ h:mmA", withTimezone) => {
  if (!date) return null;
  if (withTimezone) return moment(date).format(format) + ` ${timezone}`;
  return moment(date).format(format);
};
