import React, { Component } from "react";
import {
  Container,
  SearchWrap,
  IconWrap,
  WrapTable,
  TableEmpty,
  User,
  Team,
  Avatar,
  Gamertag,
  Buttons,
  Text,
  Errors,
  Error,
  Seed,
  SeedInput,
  DialogButtons,
  RemoveButton,
  Count
} from "./styled";
import IconSearchGrey from "../../../../../static/icons/icon-search-grey.svg";
import { getUsername, propValueOr } from "../../../../../helpers/common";
import { connect } from "react-redux";
import FormFieldKit from "../../../../../components/kit/Fields/FormField/FormField";
import { COLOR } from "../../../../../constants/theme";
import Table from "../../../../../components/presentation/Table/Table";
import {
  SegmentHeader,
  SegmentTitle
} from "../../../../../components/presentation/Table/styled";
import ButtonKit from "../../../../../components/kit/Button/ButtonKit";
import placeholder from "../../../../../static/images/default-user.jpg";
import UnregisterDialog from "./Dialogs/UnregisterDialog";
import { api } from "../../../../../index";
import {
  fetchTournamentAction,
  fetchPlayersAction
} from "../../../../../store/tournament/actions";
import Loader from "../../../../../components/presentation/Loader/Loader";
import AddTeamDialog from "./Dialogs/AddTeamDialog";
import DialogKit from "../../../../../components/kit/Dialog/DialogKit";
import InputChips from "../../../../../components/smart/ChipInput/InputChips";
import FieldTypeAndSelect from "../../../../../components/smart/FieldTypeAndSelect/FieldTypeAndSelect";
import { Redirect, withRouter } from "react-router-dom";

class Players extends Component {
  constructor(props) {
    super();

    this.isTeamTournament =
      !!propValueOr(props, "tournament.teamSize") &&
      props.tournament.teamSize > 1;

    this.state = {
      rows: [],
      searchTerm: "",
      loading: false,
      isAddDialogOpen: false,
      isSeedDialogOpen: false,
      isRemoveDialogOpen: false,
      isConfirmDialogOpen: false,
      removeUser: {},
      addUsers: [],
      errors: [],
      isEditingSeeds: false,
      seeds: {},
      seedError: "",
      loadingUser: null,
      page: 1,
      searchList: []
    };
  }

  getData = () => {
    const { tournament } = this.props;

    this.props.fetchPlayersAction(tournament.id);
  };

  componentDidMount() {
    if (this.props.tournament.id) {
      this.getData();
      this.getRowsObject();
    }
  }

  checkinUser = id => {
    const { tournament } = this.props;
    this.setState({ loadingUser: id });
    api.admin.tournament.users
      .update(tournament.id, { checkin: id })
      .then(resp => {
        this.getData();
        this.setState({ loadingUser: null });
      });
  };

  addUsers = () => {
    const {
      tournament: { id }
    } = this.props;
    const { addUsers } = this.state;
    if (!id) {
      window.alert("No tournament id");
      return;
    }
    if (!addUsers.length) {
      window.alert("No users to add");
      return;
    }
    this.setState({ loading: true });
    api.admin.tournament.users
      .add(
        id,
        addUsers.map(u => u.id)
      )
      .then(resp => {
        this.props.fetchPlayersAction(id);
        if (resp.data && resp.data.errors && resp.data.errors.length) {
          this.setState({
            errors: resp.data.errors,
            loading: false,
            addUsers: []
          });
        } else {
          this.setState({
            loading: false,
            isAddDialogOpen: false,
            addUsers: []
          });
        }
      });
  };

  removeUsers = uid => {
    const {
      tournament: { id, token }
    } = this.props;
    if (!id) {
      window.alert("No tournament id");
      return;
    }
    if (!uid) {
      window.alert("Invalid user id");
      return;
    }
    this.setState({ loading: true });
    api.admin.tournament.users.remove(id, [uid]).then(resp => {
      this.props.fetchTournamentAction(token);
      this.setState({
        loading: false,
        removeUser: {},
        isRemoveDialogOpen: false
      });
    });
  };

  handleSearchUsers = (searchWord, newPage = false, resetPage = false) => {
    let data = [];

    if (this.isTeamTournament) {
      data = api.team
        .list({
          name: searchWord,
          q: searchWord,
          limit: 25,
          ...(!searchWord &&
            !resetPage && {
              page: newPage ? this.state.page + 1 : this.state.page
            })
        })
        .then(resp => {
          if (propValueOr(resp, "data.errors") && resp.data.errors.length) {
            this.setState({
              errors: resp.data.errors
            });
            return [];
          }
          const retList = propValueOr(resp, "data.docs", []).map(team => ({
            label: team.name,
            value: team.id,
            ...team
          }));

          const compList = [
            ...(!searchWord && !resetPage ? this.state.searchList : []),
            ...retList
          ];

          this.setState({
            searchList: compList,
            page: resetPage
              ? 1
              : newPage
              ? this.state.page + 1
              : this.state.page
          });
          return compList;
        })
        .catch(err => {
          console.log(err, "Team search failed");
        });
    } else {
      data = api
        .search(searchWord, 10, 1, true, this.props.tournament.ConsoleId)
        .then(resp =>
          resp.data.users.map(u => ({
            label: getUsername(u),
            value: u.id,
            ...u
          }))
        );
    }
    return data;
  };

  addUser = user => {
    // FieldTypeAndSelect sometimes can return null
    if (!!!user) {
      return;
    }
    let users = [...this.state.addUsers];

    let index = users.findIndex(c => c.id === user.id);
    if (
      index === -1 &&
      user.id !== undefined &&
      (this.isTeamTournament ? users.length === 0 : true)
    ) {
      users.push(user);
    }

    this.setState({ addUsers: users });
  };

  removeUser = (newUsers = []) => {
    this.setState({
      addUsers: this.state.addUsers.filter(u =>
        newUsers.includes(
          this.isTeamTournament ? propValueOr(u, "team.name") : getUsername(u)
        )
      )
    });
  };

  handleUpdateSeeds = () => {
    const { seeds } = this.state;
    const {
      tournament: { id, token }
    } = this.props;

    this.setState({ loading: true });
    api.admin.tournament.users
      .update(id, { seeds })
      .then(resp => {
        this.setState({ loading: false, isSeedDialogOpen: false });
        this.props.fetchTournamentAction(token);
      })
      .catch(err => this.setState({ loading: false, isSeedDialogOpen: false }));
  };

  validateSeeds = () => {
    const { seeds } = this.state;
    let valid = true;
    const seedVals = Object.values(seeds);
    if (!seedVals.length) {
      return "Must edit at least one seed";
    }
    seedVals.forEach((seed, i) => {
      if (seedVals.filter((s, j) => j !== i).includes(seed) && seed !== null) {
        valid = false;
      }
    });
    return valid ? "" : "One or more of the seeds entered were not unique.";
  };

  getRowObject(tournament, row, seeds, isTeam) {
    if (!isTeam) {
      return {
        ...row,
        tournament,
        id: row.id,
        state: this.state,

        removeUser: id => {
          this.setState({
            isRemoveDialogOpen: true,
            removeUser: { name: getUsername(row), id: id }
          });
        },
        checkinUser: this.checkinUser,
        changeSeed: (seed, uid, oldSeed) => {
          const newSeeds = { ...seeds };
          if (!seed && !oldSeed) delete newSeeds[uid];
          else newSeeds[uid] = seed || null;
          this.setState({ seeds: newSeeds });
        }
      };
    } else {
      return {
        ...row,
        tournament,
        id: row.id,
        state: this.state,
        link:
          propValueOr(row, "team.slug") &&
          `/tournaments/${tournament.token}/players/${row.team.slug}`,
        removeUser: (id, name) => {
          this.setState({
            isRemoveDialogOpen: true,
            removeUser: { name: name, id: id }
          });
        },
        checkinUser: this.checkinUser,
        changeSeed: (seed, uid, oldSeed) => {
          const newSeeds = { ...seeds };
          if (!seed && !oldSeed) delete newSeeds[uid];
          else newSeeds[uid] = seed || null;
          this.setState({ seeds: newSeeds });
        }
      };
    }
  }

  // Puts users into Map of teams, if a non-team tournament then all users are place in single map pair
  getRowsObject() {
    const { tournament, users } = this.props;
    const { searchTerm } = this.state;
    let playersArr = [];
    if (this.isTeamTournament) {
      playersArr = users
        .filter(
          u =>
            !searchTerm ||
            (u.team.name || "").toLowerCase().includes(searchTerm)
        )
        .map(user =>
          this.getRowObject(tournament, user, this.state.seeds, true)
        );
    } else {
      playersArr = (users || [])
        .filter(
          u =>
            !searchTerm ||
            (getUsername(u) || "").toLowerCase().includes(searchTerm)
        )
        .map(user =>
          this.getRowObject(tournament, user, this.state.seeds, false)
        );
    }
    playersArr.sort((a, b) => a.seed - b.seed);

    return playersArr;
  }

  renderSeedDialog = () => {
    const { loading, isSeedDialogOpen } = this.state;
    const error = this.validateSeeds();

    return (
      <DialogKit
        title={"Update Seeds"}
        renderBody={() => {
          return error ? (
            <Errors>
              Please correct the following errors in order to update the seeds:
              <Error>{error}</Error>
            </Errors>
          ) : (
            <Text>Are you sure you want to update these seeds?</Text>
          );
        }}
        displayButtonClose
        onClose={() => this.setState({ isSeedDialogOpen: false })}
        isOpen={isSeedDialogOpen}
        renderFooter={() => (
          <DialogButtons>
            <ButtonKit
              fullWidth
              preloader={loading}
              onClick={() =>
                error
                  ? this.setState({ isSeedDialogOpen: false })
                  : this.handleUpdateSeeds()
              }
            >
              {error ? "Okay" : "Update Seeds"}
            </ButtonKit>
            {!error && (
              <ButtonKit
                fullWidth
                appearance={"secondary"}
                color={"red"}
                onClick={() => this.setState({ isSeedDialogOpen: false })}
              >
                Cancel
              </ButtonKit>
            )}
          </DialogButtons>
        )}
      />
    );
  };

  renderAddDialog = () => {
    const { isAddDialogOpen, loading, errors, addUsers } = this.state;

    return (
      <DialogKit
        title={this.isTeamTournament ? "Add Team" : "Add Players"}
        renderBody={() => {
          const players = addUsers.map(u =>
            this.isTeamTournament ? propValueOr(u, "team.name") : getUsername(u)
          );
          return errors.length ? (
            <Errors>
              Failed to add users:
              {errors.map(err => (
                <Error>
                  {err.user} - {err.message}
                </Error>
              ))}
            </Errors>
          ) : (
            <>
              <FormFieldKit fullWidth>
                <FieldTypeAndSelect
                  placeholder={"Search by gamertag..."}
                  onChange={user => this.addUser(user)}
                  onSearch={this.handleSearchUsers}
                />
              </FormFieldKit>

              <InputChips
                value={players}
                onChange={this.removeUser}
                readOnly={true}
                placeholder={`${
                  this.isTeamTournament ? "Select a team" : "Add users"
                } in the field above`}
              />
            </>
          );
        }}
        displayButtonClose
        onClose={() => this.setState({ isAddDialogOpen: false })}
        isOpen={isAddDialogOpen}
        renderFooter={() => (
          <DialogButtons>
            <ButtonKit
              fullWidth
              preloader={loading}
              onClick={() => {
                if (errors.length) {
                  this.setState({ errors: [], isAddDialogOpen: false });
                } else {
                  this.addUsers();
                }
              }}
            >
              {errors.length
                ? "Okay"
                : this.isTeamTournament
                ? "Add Team"
                : "Add Players"}
            </ButtonKit>
            {!errors.length && (
              <ButtonKit
                fullWidth
                appearance={"secondary"}
                color={"red"}
                onClick={() => this.setState({ isAddDialogOpen: false })}
              >
                Cancel
              </ButtonKit>
            )}
          </DialogButtons>
        )}
      />
    );
  };

  renderAddTeamDialog() {
    const { isAddDialogOpen, loading } = this.state;
    return (
      <AddTeamDialog
        isDialogOpen={isAddDialogOpen}
        onClose={() => this.setState({ isAddDialogOpen: false })}
        loading={loading}
        onSearch={this.handleSearchUsers}
      />
    );
  }

  renderRemoveDialog = () => {
    const { removeUser, isRemoveDialogOpen, loading } = this.state;
    return (
      <UnregisterDialog
        isTeam={!!propValueOr(this.props, "tournament.teamSize")}
        unregisteredUser={removeUser}
        isDialogOpen={isRemoveDialogOpen}
        loading={loading}
        onUnregister={() => {
          this.removeUsers(removeUser.id);
        }}
        onClose={() => this.setState({ isRemoveDialogOpen: false })}
      />
    );
  };

  renderSegmentTitle = ({
    teamName,
    TeamId,
    seed,
    seeds,
    maxPlayers,
    isEditingSeeds,
    changeSeed,
    removeUser
  }) => {
    return (
      <SegmentHeader>
        <Seed>
          {isEditingSeeds ? (
            <SeedInput
              value={seeds[TeamId] || ""}
              type={"number"}
              onChange={e => {
                let val = parseInt(e.currentTarget.value);
                if (isNaN(val)) val = undefined;
                else if (val < 1) val = 1;
                else if (maxPlayers && val > maxPlayers) val = maxPlayers;
                changeSeed(val, TeamId, seed);
              }}
            />
          ) : (
            seed
          )}
        </Seed>
        <SegmentTitle>{teamName}</SegmentTitle>
        <RemoveButton>
          <ButtonKit
            small
            color={"red"}
            onClick={() => removeUser(TeamId, teamName)}
            // preloader={rowData.state.loading}
            disabled={isEditingSeeds}
          >
            Remove
          </ButtonKit>
        </RemoveButton>
      </SegmentHeader>
    );
  };

  getTeamColumns = () => {
    return [
      {
        title: "SEED",
        propName: "seed",
        renderItem(rowData) {
          const max = propValueOr(rowData, "tournament.maxPlayers");
          return (
            <Seed>
              {rowData.state.isEditingSeeds ? (
                <SeedInput
                  value={rowData.state.seeds[rowData.id] || ""}
                  type={"number"}
                  onChange={e => {
                    let val = parseInt(e.currentTarget.value);
                    if (isNaN(val)) val = undefined;
                    else if (val < 1) val = 1;
                    else if (max && val > max) val = max;
                    rowData.changeSeed(val, rowData.id, rowData.seed);
                  }}
                />
              ) : (
                rowData.seed
              )}
            </Seed>
          );
        },
        centered: true,
        innerWidth: "70px"
        // breakpointsWidth: [
        //   {
        //     breakpoint: 'xs',
        //     width: '40px',
        //   },
        // ],
      },
      {
        title: "Team",
        propName: "user",
        renderItem(rowData) {
          return (
            <Team to={rowData.link}>
              <Avatar
                src={propValueOr(rowData, "imageInfo.thumbnail", placeholder)}
                alt={""}
                onError={e => (e.target.src = placeholder)}
              />
              <Gamertag>{propValueOr(rowData, "team.name")}</Gamertag>
            </Team>
          );
        },
        innerWidth: "grow"
      },
      {
        title: "",
        propName: "buttons",
        renderItem(rowData) {
          return (
            <ButtonKit
              small
              fullWidth
              appearance={rowData.checkin ? "secondary" : "primary"}
              color={rowData.checkin ? "red" : "green"}
              onClick={() => rowData.checkinUser(rowData.id)}
              preloader={rowData.state.loadingUser === rowData.id}
              disabled={rowData.state.isEditingSeeds}
            >
              {rowData.checkin ? "Check Out" : "Check In"}
            </ButtonKit>
          );
        },
        innerWidth: "125px"
      }
    ];
  };

  getTableColumns = () => {
    return [
      {
        title: "SEED",
        propName: "seed",
        renderItem(rowData) {
          const max = propValueOr(rowData, "tournament.maxPlayers");
          return (
            <Seed>
              {rowData.state.isEditingSeeds ? (
                <SeedInput
                  value={rowData.state.seeds[rowData.id] || ""}
                  type={"number"}
                  onChange={e => {
                    let val = parseInt(e.currentTarget.value);
                    if (isNaN(val)) val = undefined;
                    else if (val < 1) val = 1;
                    else if (max && val > max) val = max;
                    rowData.changeSeed(val, rowData.id, rowData.seed);
                  }}
                />
              ) : (
                rowData.seed
              )}
            </Seed>
          );
        },
        centered: true,
        innerWidth: "70px"
        // breakpointsWidth: [
        //   {
        //     breakpoint: 'xs',
        //     width: '40px',
        //   },
        // ],
      },
      {
        title: "PLAYER",
        propName: "user",
        renderItem(rowData) {
          return (
            <User to={`/tournament/${rowData.token}/`}>
              <Avatar
                src={propValueOr(rowData, "imageInfo.thumbnail", placeholder)}
                alt={""}
                onError={e => (e.target.src = placeholder)}
              />
              <Gamertag>{getUsername(rowData)}</Gamertag>
            </User>
          );
        },
        innerWidth: "grow"
      },
      {
        title: "",
        propName: "buttons",
        renderItem(rowData) {
          return (
            <ButtonKit
              small
              fullWidth
              appearance={rowData.checkin ? "secondary" : "primary"}
              color={rowData.checkin ? "red" : "green"}
              onClick={() => rowData.checkinUser(rowData.id)}
              preloader={rowData.state.loadingUser === rowData.id}
              disabled={rowData.state.isEditingSeeds}
            >
              {rowData.checkin ? "Check Out" : "Check In"}
            </ButtonKit>
          );
        },
        innerWidth: "125px"
      },
      {
        title: "",
        propName: "buttons",
        renderItem(rowData) {
          return (
            <ButtonKit
              small
              color={"red"}
              onClick={() => rowData.removeUser(rowData.id)}
              // preloader={rowData.state.loading}
              disabled={rowData.state.isEditingSeeds}
            >
              Remove
            </ButtonKit>
          );
        },
        innerWidth: "105px"
        // hidden: 'sm',
      }
    ];
  };

  render() {
    const { searchTerm, loading, isEditingSeeds, seeds } = this.state;
    const { tournament, users } = this.props;
    const { token, status } = tournament;
    const filteredUsers = (users || [])
      .filter(u => (getUsername(u) || "").toLowerCase().includes(searchTerm))
      .map(u => ({
        ...u,
        tournament,
        removeUser: id => {
          this.setState({
            isRemoveDialogOpen: true,
            removeUser: { gamertag: getUsername(u), id: id }
          });
        },
        checkinUser: this.checkinUser,
        changeSeed: (seed, uid, oldSeed) => {
          const newSeeds = { ...seeds };
          if (!seed && !oldSeed) delete newSeeds[uid];
          else newSeeds[uid] = seed || null;
          this.setState({ seeds: newSeeds });
        },
        state: this.state
      }));
    filteredUsers.sort((a, b) => a.seed - b.seed);

    const rowsArr = this.getRowsObject();
    if (status && status !== "initialized") {
      return <Redirect to={`/tournaments/${token}`} />;
    }
    return (
      <Container>
        {this.renderRemoveDialog()}
        {this.isTeamTournament
          ? this.renderAddTeamDialog()
          : this.renderAddDialog()}
        {this.renderSeedDialog()}
        <SearchWrap>
          <FormFieldKit
            onChange={val =>
              this.setState({ searchTerm: (val || "").toLowerCase() })
            }
            value={searchTerm}
            placeholder={
              this.isTeamTournament
                ? "Search for a team..."
                : "Search for a user..."
            }
            icon={
              <IconWrap>
                <img src={IconSearchGrey} alt="search icon" />
              </IconWrap>
            }
            iconAlign="left"
            hasError={false}
            gutterBottom={false}
          />
          <Buttons>
            <ButtonKit
              fullWidth
              small
              appearance={isEditingSeeds ? "primary" : "secondary"}
              onClick={() => {
                const newSeeds = {};
                for (const u of rowsArr) {
                  newSeeds[u.id] = u.seed;
                }

                isEditingSeeds
                  ? this.setState({ isSeedDialogOpen: true })
                  : this.setState({
                      isEditingSeeds: !isEditingSeeds,
                      seeds: newSeeds
                    });
              }}
              disabled={!users || !users.length}
              preloader={loading}
            >
              {isEditingSeeds ? "Save Seed Changes?" : "Edit Seeds"}
            </ButtonKit>
            <ButtonKit
              fullWidth
              small
              appearance={"secondary"}
              color={isEditingSeeds ? "rival_red" : "primary"}
              onClick={() => {
                this.setState({
                  ...(isEditingSeeds
                    ? { isEditingSeeds: false }
                    : { isAddDialogOpen: true })
                });
              }}
            >
              {isEditingSeeds
                ? "Cancel"
                : this.isTeamTournament
                ? "Add Team"
                : "Add Players"}
            </ButtonKit>
          </Buttons>
        </SearchWrap>
        <Count>
          {`${users.length}${
            tournament.maxPlayers ? `/${tournament.maxPlayers}` : ""
          } ${this.isTeamTournament ? "TEAMS" : "PLAYERS"}`}
        </Count>
        <WrapTable>
          {loading ? (
            <Loader isBlock />
          ) : (
            <Table
              columns={
                !this.isTeamTournament
                  ? this.getTableColumns()
                  : this.getTeamColumns()
              }
              data={rowsArr}
              headerBgColor={COLOR.BG_PRIMARY}
              headerColor={COLOR.TEXT}
              renderContentForEmptyTable={() => (
                <TableEmpty>No Users to Display</TableEmpty>
              )}
            />
          )}
        </WrapTable>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  tournament: propValueOr(state, "tournamentState.tournament", {}),
  users: propValueOr(state, "tournamentState.players", []),
  tournamentId: propValueOr(state, "tournamentState.tournament.id"),
  tournamentToken: propValueOr(state, "tournamentState.tournament.token"),
  tournamentStatus: propValueOr(state, "tournamentState.tournament.status")
});

const mapDispatchToProps = {
  fetchTournamentAction,
  fetchPlayersAction
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Players));
