import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { api } from "../../../index";
import Panel from "../../../components/kit/Panel/Panel";
import Asset from "../Asset/Asset";
import { fetchAssetsAction } from "../../../store/assets/actions";
import { getGlobalUser } from "../../../store/user/selectors";
import ButtonKit from "../../../components/kit/Button/ButtonKit";
import SelectKit from "../../../components/kit/Fields/Select/SelectKit";
import { COLOR, FONT_WEIGHT, FONT_FAMILY } from "../../../constants/theme";

class AssetPanels extends Component {
  constructor(props) {
    super(props);

    const seriesAssets = this.props.assets.filter(a =>
      a.name.includes(this.props.asset.name)
    );
    const assetsOrder = seriesAssets.map((a, i) =>
      !!a?.order ? a?.order - 1 : i
    );
    this.state = {
      changeOrderMode: false,
      submitting: false,
      order: assetsOrder,
      assetsOrder
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.assets.length !== this.props.assets.length) {
      const seriesAssets = this.props.assets.filter(a =>
        a.name.includes(this.props.asset.name)
      );

      const assetsOrder = seriesAssets.map((a, i) => {
        return !!a?.order ? a?.order - 1 : i;
      });

      this.setState({
        order: assetsOrder,
        assetsOrder
      });
    }
  }

  handleOrderChange(listIndex, newIndex) {
    const { order } = this.state;

    let newOrder = [...order];
    let assetIndex = newOrder.findIndex(o => o === listIndex);

    let min = newIndex;
    let max = listIndex;
    let increment = 1;
    if (listIndex < newIndex) {
      min = listIndex;
      max = newIndex;
      increment = -1;
    }

    for (let i = 0; i < order.length; i++) {
      if (i === assetIndex) {
        newOrder[i] = newIndex;
      } else {
        if (newOrder[i] >= min && newOrder[i] <= max) {
          newOrder[i] = order[i] + increment;
        }
      }
    }

    this.setState({ order: newOrder });
  }

  handleSubmitOrder() {
    const { order } = this.state;
    const { asset } = this.props;
    this.setState({ submitting: true });
    const promises = order.map((o, i) => {
      return new Promise((resolve, reject) => {
        let data = new FormData();
        data.append("name", `${asset.name}-${i + 1}`);
        data.append("order", o + 1);
        api.assets.update(data).then(resp => {
          resp.data.success
            ? resolve(i)
            : reject(
                new Error(`Unable to update order of ${asset.name}-${i + 1}`)
              );
        });
      });
    });
    Promise.all(promises)
      .then(values => {
        this.setState({ submitting: false });
        window.location.reload();
      })
      .catch(error => {
        this.setState({ error: error.message });
      });
  }

  render() {
    const { title, subtitle, imageSpecs, asset, seriesNum } = this.props;
    const { order, assetsOrder, changeOrderMode, submitting } = this.state;

    return (
      <Container>
        <PanelContent>
          {!!title && (
            <SectionHeader>
              <SectionInfo>
                <SectionTitle>{title}</SectionTitle>
                {!!subtitle && <SectionSubtitle>{subtitle}</SectionSubtitle>}
                {!!imageSpecs && (
                  <WrapRequirements>
                    <ImageTitle>Image Requirements:</ImageTitle>
                    <ConstraintList>
                      {imageSpecs.map(spec => {
                        return (
                          <ConstraintItem isValid={null}>{spec}</ConstraintItem>
                        );
                      })}
                    </ConstraintList>
                  </WrapRequirements>
                )}
              </SectionInfo>
              {changeOrderMode ? (
                <WrapButtons>
                  {submitting && (
                    <SectionSubtitle>Submitting...</SectionSubtitle>
                  )}
                  <WrapButton>
                    <ButtonKit
                      shape={"rounded"}
                      color={"green"}
                      fullWidth
                      small
                      onClick={() => this.handleSubmitOrder()}
                      disabled={order.every(
                        (val, index) => val === assetsOrder[index]
                      )}
                    >
                      SUBMIT ORDER
                    </ButtonKit>
                  </WrapButton>
                  <WrapButton>
                    <ButtonKit
                      shape={"rounded"}
                      appearance={"secondary"}
                      color={"red"}
                      fullWidth
                      small
                      onClick={() =>
                        this.setState({
                          changeOrderMode: false,
                          order: assetsOrder
                        })
                      }
                    >
                      CANCEL
                    </ButtonKit>
                  </WrapButton>
                </WrapButtons>
              ) : (
                <WrapButtons>
                  <WrapButton>
                    <ButtonKit
                      shape={"rounded"}
                      appearance={"secondary"}
                      fullWidth
                      small
                      onClick={() => this.setState({ changeOrderMode: true })}
                    >
                      CHANGE ORDER
                    </ButtonKit>
                  </WrapButton>
                </WrapButtons>
              )}
            </SectionHeader>
          )}
        </PanelContent>
        <WrapPanelList>
          {[...Array(seriesNum)].map((_, i) => {
            const hasAsset = i < order.length;
            let assetNum = i;
            let sectionNum = i + 1;

            if (hasAsset) {
              assetNum = order.findIndex(o => o === i);
              sectionNum = assetsOrder[assetNum] + 1;
            }

            return (
              <WrapPanel disabled={i >= order.length + 1}>
                <Panel
                  trigger={`${asset.panelTitle} ${sectionNum}`}
                  customMargin={"0"}
                  triggerStyle={{ padding: "15px 25px" }}
                  overflowWhenOpen="auto"
                  open={false}
                  triggerDisabled={i >= order.length + 1}
                >
                  <PanelContent>
                    <Asset
                      name={`${asset.name}-${assetNum + 1}`}
                      label={`${asset.label} ${assetNum + 1}`}
                      title={asset.title}
                      description={asset.description}
                      organizationGroupId={asset?.organizationGroupId}
                      link={asset.link}
                      image={asset.image}
                      video={asset.video}
                      imageDimensions={asset.imageDimensions}
                      imgLabels={asset.imgLabels}
                      aspectRatioLimits={asset.aspectRatioLimits}
                      titleLimit={asset.titleLimit}
                      descriptionLimit={asset.descriptionLimit}
                    />
                  </PanelContent>
                  {changeOrderMode && hasAsset && (
                    <WrapOrderField>
                      <OrderLabel>CHANGE POSITION TO: </OrderLabel>
                      <WrapSelectField>
                        <SelectKit
                          select
                          fullWidth
                          value={i}
                          onChange={val => this.handleOrderChange(i, val)}
                          options={[...Array(order.length)].map((_, j) => {
                            return { value: j, label: j + 1 };
                          })}
                        />
                      </WrapSelectField>
                    </WrapOrderField>
                  )}
                </Panel>
              </WrapPanel>
            );
          })}
        </WrapPanelList>
      </Container>
    );
  }
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: ${COLOR.WHITE};
`;

export const WrapPanel = styled.div`
  width: 100%;
  position: relative;

  ${({ disabled }) => disabled && `opacity: 0.4;`}
`;

export const WrapPanelList = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  border-top: 1px solid ${COLOR.BORDER};
  border-bottom: 1px solid ${COLOR.BORDER};

  > :not(:last-child) {
    border-bottom: 1px solid ${COLOR.BORDER};
  }
`;

export const SectionHeader = styled.div`
  display: flex;
  margin-bottom: 15px;
  color: ${COLOR.WHITE};
`;

export const SectionInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const WrapButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 250px;
  > :not(:last-child) {
    margin-right: 10px;
  }
`;

export const WrapButton = styled.div`
  width: 100%;
  max-width: 125px;
`;

export const SectionTitle = styled.div`
  font: ${FONT_WEIGHT.NORMAL} 16px ${FONT_FAMILY.GOTHIC_BOLD};
`;

export const SectionSubtitle = styled.div`
  margin-top: 10px;
  font: ${FONT_WEIGHT.NORMAL} 14px ${FONT_FAMILY.GOTHIC};
`;

export const WrapRequirements = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0px 0px 0px;
`;

export const ConstraintList = styled.ul`
  font: ${FONT_WEIGHT.NORMAL} 12px ${FONT_FAMILY.GOTHIC};
  user-select: text;
  margin: 0px;
  padding-left: 20px;
`;

export const ConstraintItem = styled.li`
  display: flex;
  align-items: center;
  ${({ isValid }) => {
    return isValid === null
      ? `color: ${COLOR.TEXT};`
      : isValid
      ? `color: ${COLOR.LIGHT_GREEN};`
      : `color: ${COLOR.CHERRY_RED};`;
  }}
`;

export const ImageTitle = styled.div`
  font: ${FONT_WEIGHT.NORMAL} 14px ${FONT_FAMILY.GOTHIC_BOLD};
  margin: 5px 0px;
`;

export const PanelContent = styled.div`
  height: 100%;
  width: 100%;
  color: ${COLOR.WHITE};
  padding: 0px 30px;
`;

export const WrapOrderField = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  top: 5px;
  right: 60px;
`;

export const WrapSelectField = styled.div`
  width: 60px;
`;

export const OrderLabel = styled.div`
  font: ${FONT_WEIGHT.BOLD} 14px ${FONT_FAMILY.THIN};
  margin-right: 10px;
`;

const mapStateToProps = state => ({
  user: getGlobalUser(state),
  assets: state.assetsState.assets
});

const mapDispatchToProps = {
  fetchAssetsAction
};

export default connect(mapStateToProps, mapDispatchToProps)(AssetPanels);
