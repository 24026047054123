import { darken } from "polished";

import { COLOR } from "../../../constants/theme";
import { BUTTON_STATE } from "./constants";
import { propValueOr } from "../../../helpers/common";
import { config } from "../../../index";

export const getButtonAppearanceColor = (props, state) => {
  const { color, appearance } = props;
  const greenHover = darken(0.1, COLOR.GREEN);
  const lightGreenHover = darken(0.1, COLOR.GREEN);
  const redHover = darken(0.1, COLOR.ROUGE);
  const rivalRedHover = darken(0.1, COLOR.RIVAL_RED);
  const { primary } = propValueOr(config, "kit.button", {});

  if (state === BUTTON_STATE.DEFAULT) {
    if (color === "primary") {
      if (appearance === "secondary") {
        return COLOR.WHITE;
      }
      return primary || COLOR.BUTTON_DEFAULT_BG_PRIMARY;
    }

    if (color === "secondary") {
      return COLOR.YELLOW_2;
    }

    if (color === "inverted") {
      return COLOR.WHITE;
    }

    if (color === "danger") {
      return COLOR.CHERRY_RED;
    }

    if (color === "text-link") {
      return COLOR.TEXT_LINK;
    }

    if (color === "green") {
      return COLOR.GREEN;
    }

    if (color === "light-green") {
      return COLOR.LIGHT_GREEN;
    }

    if (color === "red") {
      if (appearance === "secondary") {
        return COLOR.WHITE;
      } else {
        return COLOR.ROUGE;
      }
    }

    if (color === "rival_red") {
      if (appearance === "secondary") {
        return COLOR.WHITE;
      } else {
        return COLOR.RIVAL_RED;
      }
    }

    if (color === "blue") {
      if (appearance === "secondary") {
        return COLOR.WHITE;
      } else {
        return COLOR.NAVY_BLUE;
      }
    }

    if (color === "mls") {
      return "#192756";
    }

    if (color === "discord") {
      return "#7289DA";
    }
  }

  if (state === BUTTON_STATE.HOVER) {
    if (color === "primary") {
      return !!primary ? darken(0.1, primary) : COLOR.BUTTON_HOVER_BG_PRIMARY;
    }

    if (color === "secondary") {
      return COLOR.YELLOW;
    }

    if (color === "inverted") {
      return COLOR.WHITE;
    }

    if (color === "danger") {
      return COLOR.ROUGE;
    }

    if (color === "text-link") {
      return COLOR.LIGHT_BLUE;
    }

    if (color === "green") {
      return greenHover;
    }

    if (color === "light-green") {
      return lightGreenHover;
    }

    if (color === "red") {
      return redHover;
    }

    if (color === "rival_red") {
      return rivalRedHover;
    }

    if (color === "blue") {
      return COLOR.DARK_BLUE;
    }

    if (color === "mls") {
      return "#192756";
    }
  }

  if (state === BUTTON_STATE.ACTIVE) {
    if (color === "primary") {
      return !!primary ? darken(0.15, primary) : COLOR.DARK_BLUE;
    }

    if (color === "secondary") {
      return COLOR.YELLOW;
    }

    if (color === "inverted") {
      return COLOR.WHITE;
    }

    if (color === "danger") {
      return COLOR.ROUGE;
    }

    if (color === "text-link") {
      return COLOR.LIGHT_BLUE;
    }

    if (color === "green") {
      return greenHover;
    }

    if (color === "light-green") {
      return lightGreenHover;
    }

    if (color === "red") {
      return COLOR.ROUGE;
    }

    if (color === "riva_red") {
      return COLOR.RIVAL_RED;
    }

    if (color === "blue") {
      return COLOR.DARK_BLUE;
    }

    if (color === "mls") {
      return "#192756";
    }
  }

  if (state === BUTTON_STATE.DISABLED) {
    if (appearance === "primary") {
      return COLOR.BUTTON_DISABLED_BG_PRIMARY;
    } else {
      return COLOR.BUTTON_DISABLED_BG_SECONDARY;
    }
  }
};

export const getButtonKitBackgroundColor = (props, state) => {
  const { appearance } = props;

  if (
    appearance === "secondary" &&
    (state === BUTTON_STATE.DEFAULT || state === BUTTON_STATE.DISABLED)
  ) {
    return "transparent";
  }

  if (
    appearance === "inverted" &&
    (state === BUTTON_STATE.DEFAULT || state === BUTTON_STATE.DISABLED)
  ) {
    return "transparent";
  }

  return getButtonAppearanceColor(props, state);
};

export const getButtonKitBorder = (props, state) => {
  const { appearance } = props;

  if (state === BUTTON_STATE.DISABLED) {
    return `1px solid ${
      appearance === "primary"
        ? COLOR.BUTTON_DISABLED_BORDER_PRIMARY
        : COLOR.BUTTON_DISABLED_BORDER_SECONDARY
    }`;
  }

  if (appearance === "primary") {
    return "1px solid transparent;";
  }

  return `1px solid ${getButtonAppearanceColor(props, state)}`;
};

export const getButtonKitFontColor = (props, state) => {
  const { appearance, color } = props;

  if (state === BUTTON_STATE.DISABLED) {
    return COLOR.BUTTON_DISABLED_FONT_COLOR;
  }

  if (color === "secondary") {
    return COLOR.BLACK;
  }

  if (color === "inverted") {
    if (state === BUTTON_STATE.HOVER) return COLOR.DARK_BLUE;
    else return COLOR.WHITE;
  }

  if (appearance === "secondary" && state === BUTTON_STATE.DEFAULT) {
    if (color === "red" || color === "rival_red" || color === "blue") {
      return COLOR.WHITE;
    }
    return getButtonAppearanceColor(props, state);
  }

  return COLOR.WHITE;
};
