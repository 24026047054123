import React, { Component } from "react";
import { connect } from "react-redux";
import { api } from "../../..";
import FormFieldKit from "../../../components/kit/Fields/FormField/FormField";
import GameConsoleSelection from "./GameConsoleSelection/GameConsoleSelection";
import Button from "../../../components/kit/Button/ButtonKit";
import { fetchTemplates } from "../../../store/games/actions";
import { fetchConsolesAction } from "../../../store/consoles/actions";
import { Container, Error } from "./styled";
import { propValueOr } from "../../../helpers/common";
import { fetchLeagues } from "../../../store/leagues/actions";
import Uploader from "../Uploader/Uploader";
import { compressionOptions, imageField } from "./constants";
import imageCompression from "browser-image-compression";

class LeagueCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: props?.league?.name || "",
      description: props?.league?.description || "",
      gameId: props?.league?.gameId || null,
      consoleId:
        props?.league?.consoleId !== undefined
          ? props?.league?.consoleId
          : undefined,
      loading: false,
      error: "",
      success: null,
      image: props?.league?.imageInfo?.image || null
    };
  }

  componentDidMount() {
    this.props.fetchConsolesAction();
    this.props.fetchTemplates();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.league !== this.props.league) {
      this.setState({
        name: this.props?.league?.name || "",
        description: this.props?.league?.description || "",
        gameId: this.props?.league?.gameId || null,
        consoleId:
          this.props?.league?.consoleId !== undefined
            ? this.props?.league?.consoleId
            : undefined,
        loading: false,
        error: "",
        success: null,
        image: this.props?.league?.imageInfo?.image || null
      });
    }
  }

  handleSubmit() {
    if (this.props.update) {
      this.updateLeague();
    } else {
      this.createLeague();
    }
  }

  createLeague = () => {
    const { name, description, gameId, consoleId } = this.state;
    this.setState({ loading: true });
    const body = {
      name: name,
      description: description,
      organizationId: this.props.user.organizationId,
      image: this.state.image,
      settings: JSON.stringify({}),
      gameId: gameId,
      consoleId: consoleId
    };
    let data = new FormData();
    for (let key in body) {
      if (body[key] != null) {
        data.append(key, body[key]);
      }
    }
    api.league
      .create(data)
      .then(() => {
        this.setState(
          {
            loading: false,
            success: "League created!",
            name: "",
            description: "",
            gameId: null,
            consoleId: undefined
          },
          () => {
            this.props.fetchLeagues();
            this.props.history.push("/leagues");
          }
        );
      })
      .catch(err => {
        const data = (err.response && err.response.data) || {};

        if (data.error) {
          this.setState({ error: data.error, loading: false });
        }
      });
    this.setState({ loading: false });
  };

  updateLeague = () => {
    const { league } = this.props;
    const { name, description, gameId, consoleId } = this.state;
    this.setState({ loading: true });
    const body = {
      ...(name !== league?.name && { name }),
      ...(description !== league?.description && { description }),
      ...(this.state.image && { image: this.state.image }),
      ...(gameId !== league?.gameId && { gameId }),
      ...(consoleId !== league?.consoleId && { consoleId })
    };
    let data = new FormData();
    for (let key in body) {
      if (body[key] != null) {
        data.append(key, body[key]);
      }
    }
    api.league
      .update(league.slug, data)
      .then(() => {
        this.setState(
          {
            loading: false,
            success: "League created!",
            name: "",
            description: "",
            gameId: null,
            consoleId: undefined
          },
          () => {
            if (!!this.props.onUpdate) this.props.onUpdate();
          }
        );
      })
      .catch(err => {
        const data = (err.response && err.response.data) || {};

        if (data.error) {
          this.setState({ error: data.error, loading: false });
        }
      });
    this.setState({ loading: false });
  };

  handleChangeField = (propName, val, callback) => {
    this.setState(
      { [propName]: val },
      callback && typeof callback === "function" ? callback : undefined
    );
  };

  handleChangeImage = async (propName, val) => {
    let compressedImage = await imageCompression(val, compressionOptions);
    compressedImage.lastModifiedDate = new Date();
    const newFile = new File([compressedImage], compressedImage.name);
    this.handleChangeField(propName, newFile);
  };

  render() {
    const { templates, consoles, update } = this.props;
    const {
      name,
      description,
      loading,
      consoleId,
      gameId,
      error,
      image
    } = this.state;

    return (
      <Container>
        <FormFieldKit
          label={"Name of the League (Limit 50 Characters)"}
          fullWidth
          placeholder={"Enter name..."}
          required
          value={name}
          inputProps={{ maxLength: 50 }}
          onChange={val => this.handleChangeField("name", val)}
        />
        <FormFieldKit
          label={"Description of the League (Limit 500 Characters)"}
          fullWidth
          placeholder={"Enter description..."}
          required
          multiline
          value={description}
          inputProps={{ maxLength: 500 }}
          onChange={val => this.handleChangeField("description", val)}
        />
        <GameConsoleSelection
          templates={templates}
          consoles={consoles}
          onConsoleChange={(val, callback) => {
            this.handleChangeField(
              "consoleId",
              propValueOr(val, "id"),
              callback
            );
            this.handleChangeField("gameId", null, callback);
          }}
          onGameChange={val =>
            this.handleChangeField("gameId", propValueOr(val, "id"))
          }
          selectedConsoleId={consoleId}
          selectedGameId={gameId}
        />
        <Uploader
          key={"league image"}
          label={"League Image"}
          required
          description={imageField.description}
          onChange={file => this.handleChangeImage("image", file)}
          constraints={imageField.constraints}
          imgLabels={imageField.imgLabels}
          imageDimensions={imageField.imageDimensions}
          defaultImage={propValueOr(this.state, `image`)}
          onReset={() => this.handleChangeField("image", undefined)}
        />
        {error && <Error>{error}</Error>}
        <Button
          style={{ marginTop: "20px" }}
          onClick={() => this.handleSubmit()}
          fullWidth
          shape={"rounded"}
          color={"rival_red"}
          width={"200px"}
          disabled={
            !name ||
            !description ||
            !this.props.user.organizationId ||
            consoleId === undefined ||
            !gameId ||
            !image
          }
          preloader={loading}
        >
          {update ? "UPDATE LEAGUE" : "CREATE LEAGUE"}
        </Button>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  consoles: state.consoles.list,
  templates: state.gamesState.templates,
  isFetchingTemplates: state.gamesState.isFetchingTemplates
});

const mapDispatchToProps = {
  fetchConsolesAction,
  fetchTemplates,
  fetchLeagues
};

export default connect(mapStateToProps, mapDispatchToProps)(LeagueCreate);
