import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Container,
  Versus,
  Column,
  Button,
  View,
  WrapFinalScore
} from "./styled";
import { Player } from "./Player/Player";
import { getPlayers } from "../../../store/game/selectors";
import { propValueOr } from "../../../helpers/common";

function Players({
  game,
  players,
  isStreamsOpen,
  handleShowStreams,
  handleVerifyScore,
  handleEditScore
}) {
  if (!players) {
    return null;
  }

  return (
    <Container>
      <Player
        game={game}
        player={players[0]}
        singlePlayer={players.length === 1}
        handleVerifyScore={handleVerifyScore}
        handleEditScore={handleEditScore}
      />
      {players.length < 2 && (
        <Column>
          <View onClick={handleShowStreams}>
            {isStreamsOpen ? "VIEW CHAT" : "VIEW STREAM"}
          </View>
          {game.status === "complete" && (
            <WrapFinalScore>
              {`FINAL SCORE: ${propValueOr(players[0], "score", "0")}`}
            </WrapFinalScore>
          )}
        </Column>
      )}
      {players.length > 1 && (
        <Column>
          <Versus>VS</Versus>
          <View onClick={handleShowStreams}>
            {isStreamsOpen ? "VIEW CHAT" : "VIEW STREAMS"}
          </View>

          {game.challenge && (
            <Button to={`/challenge/${game.challenge.token}`}>
              View Challenge
            </Button>
          )}
        </Column>
      )}
      {players[1] && (
        <Player
          game={game}
          player={players[1]}
          singlePlayer={false}
          handleVerifyScore={handleVerifyScore}
          handleEditScore={handleEditScore}
        />
      )}
    </Container>
  );
}

const mapStateProps = state => ({
  game: state.gameState.game,
  players: getPlayers(state)
});

export default connect(mapStateProps)(withRouter(Players));
