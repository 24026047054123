import { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { api } from "../../../../../..";
import FormFieldKit from "../../../../../../components/kit/Fields/FormField/FormField";
import { COLOR } from "../../../../../../constants/theme";
import { propValueOr } from "../../../../../../helpers/common";
import iconTrash from "../../../../../../static/icons/icon-trash.svg";
import FieldTypeAndSelect from "../../../../../../components/smart/FieldTypeAndSelect/FieldTypeAndSelect";
import { pick } from "lodash";

class Moderators extends Component {
  constructor(props) {
    super();

    this.state = {
      search: ""
    };
  }

  handleSelectMod = mod => {
    const { moderators = [], onChange } = this.props;
    if (!mod || typeof mod !== "object") {
      return;
    } else if (moderators?.find(m => m?.id === mod?.id)) {
      return;
    }
    const newMod = pick(mod, ["id", "label", "value"]);
    onChange(moderators?.concat([newMod]));
  };

  getInitialsFromName(name) {
    const nameArr = name?.split(" ");
    return nameArr?.map(n => n[0]).join("");
  }

  handleSearchUsers = s => {
    this.setState({ search: s });
    return api.admin.users
      .list({
        q: s,
        role: "admin"
      })
      .then(resp => {
        const data = propValueOr(resp, "data", []);
        return data?.docs?.length > 0
          ? data.docs.map(u => ({
              ...u,
              label: `${u.fullName} (${u.email})`,
              value: `${this.getInitialsFromName(u.fullName)}`
            }))
          : [];
      })
      .catch(err => {});
  };

  render() {
    const { moderators, onChange } = this.props;
    return (
      <>
        <Header></Header>
        <Description>
          Add or remove moderators for this tournament by using the fields
          below. To assign a mod, search by name, email, alias, etc., then
          select the user you want.
        </Description>
        <Container>
          <FieldTypeAndSelect
            label="Add Moderator"
            placeholder={"Search for mod by name, email, alias, etc..."}
            onNewPage
            onChange={this.handleSelectMod}
            onSearch={this.handleSearchUsers}
            fullWidth
          />
          <br />
          {moderators?.length > 0 ? (
            moderators.map((mod, index) => (
              <Moderator key={mod?.id || mod?.label}>
                <Label>{mod?.label}</Label>
                <WrapField>
                  <FormFieldKit
                    fullWidth
                    type="text"
                    value={mod?.value}
                    inputProps={{ maxLength: 12 }}
                    onChange={val => {
                      const newList = [...moderators];
                      newList[index] = { ...newList[index], value: val };
                      onChange(newList);
                    }}
                    errorText={!mod?.value && "Initials cannot be empty"}
                  />
                  <WrapIcon
                    onClick={() => {
                      onChange([
                        ...moderators?.slice(0, index),
                        ...moderators?.slice(index + 1)
                      ]);
                    }}
                  >
                    <img src={iconTrash} width={20} height={20} alt="" />
                  </WrapIcon>
                </WrapField>
              </Moderator>
            ))
          ) : (
            <Moderator center style={{ marginTop: "40px" }}>
              No moderators assigned yet. You can add them in the field below.
            </Moderator>
          )}
        </Container>
      </>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user?.info || {}
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Moderators);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 5px 20px 0;
  width: 100%;
  min-height: 220px;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
`;

const Description = styled.div`
  font-size: 14px;
  color: ${COLOR.GREY};
`;
const Moderator = styled.div`
  width: 100%;
  font-size: 16px;
  color: ${COLOR.TEXT};
  margin-top: 7px;
  display: flex;
  align-items: center;
  justify-content: ${({ center }) => (center ? "center" : "space-between")};
  text-align: center;
`;

const WrapIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 12px;
  margin-top: -10px;
  cursor: pointer;
`;

const Label = styled.span`
  white-space: pre;
  width: 100%;
  flex-basis: 50%;
  margin-right: 12px;
  margin-top: -10px;
  text-align: left;
`;

const WrapField = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
