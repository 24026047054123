import styled from "styled-components";
import { Link } from "react-router-dom";
import {
  COLOR,
  FONT_FAMILY,
  FONT_WEIGHT
} from "../../../../../constants/theme";
import breakpoints from "../../../../../helpers/breakpoints";

export const Container = styled.div`
  width: 100%;
  background-color: ${COLOR.BG_SECONDARY};
`;

export const SearchWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 0px;

  > :not(:last-child) {
    margin-right: 15px;
  }

  ${breakpoints.down(500)} {
    flex-direction: column-reverse;
    > :not(:last-child) {
      margin-right: 0;
    }
    > :last-child {
      margin-bottom: 15px;
    }
    > * {
      width: 100%;
    }
  }
`;
export const IconWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const WrapTable = styled.div`
  width: 100%;
`;

export const TableEmpty = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 60px 0;

  font: ${FONT_WEIGHT.NORMAL} 18px ${FONT_FAMILY.GOTHIC_BOLD};
`;

export const User = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const Team = styled(Link)`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  text-decoration: none;
  color: ${COLOR.TEXT};
`;
// export const WrapAvatar = styled.div``;
export const Avatar = styled.img`
  width: 44px;
  height: 44px;
`;
export const Gamertag = styled.div`
  margin-left: 20px;
  font: ${FONT_WEIGHT.NORMAL} 16px ${FONT_FAMILY.GOTHIC};
`;

export const DialogButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  width: 100%;
  > :not(:last-child) {
    margin-right: 0;
    margin-bottom: 10px;
  }
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 50%;

  > :not(:last-child) {
    margin-right: 7px;
    margin-bottom: 0;
  }

  ${breakpoints.down(1200)} {
    width: 70%;
  }

  ${breakpoints.down(600)} {
    flex-direction: column;
    width: 100%;

    > :not(:last-child) {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
`;

export const Text = styled.div`
  font: ${FONT_WEIGHT.NORMAL} 14px / 20px ${FONT_FAMILY.GOTHIC};
  color: ${COLOR.TEXT};
`;

export const Errors = styled.ul`
  font: ${FONT_WEIGHT.MEDIUM} 16px / 20px ${FONT_FAMILY.GOTHIC};
  color: ${COLOR.CHERRY_RED};
`;
export const Error = styled.li`
  font: ${FONT_WEIGHT.NORMAL} 14px / 20px ${FONT_FAMILY.GOTHIC};
  color: ${COLOR.TEXT};
  margin-top: 10px;
`;

export const Seed = styled.div`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input {
    border: none;
    text-align: center;
    height: 30px;
    border: 1px solid ${COLOR.BG_SECONDARY};

    &:focus {
      border: 1px solid ${COLOR.FG_PRIMARY};
      -webkit-transition: border-color 0.3s ease;
      -moz-transition: border-color 0.3s ease;
      -o-transition: border-color 0.3s ease;
      transition: border-color 0.3s ease;
    }
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
`;

export const SeedInput = styled.input`
  width: 100%;
  background-color: ${COLOR.BG_INPUT};
  color: ${COLOR.TEXT};
  font-size: 12px;
  font: ${FONT_WEIGHT.MEDIUM} 13px ${FONT_FAMILY.GOTHIC};
`;

export const RemoveButton = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
`;

export const EmptyCell = styled.div``;

export const Count = styled.div`
  font: ${FONT_WEIGHT.NORMAL} 14px / 20px ${FONT_FAMILY.GOTHIC};
  color: ${COLOR.TEXT};
  margin: 0 20px 20px;
`;
