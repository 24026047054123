import React, { Component } from "react";
import { connect } from "react-redux";
import { Content, Container } from "./styled";
import { withRouter } from "react-router-dom";
import organizationRoutes from "./routes";
import { Route, Switch, Redirect } from "react-router-dom";
import { verifyPermission } from "../../helpers/common";

class RouteOrganizations extends Component {
  render() {
    return (
      <Container>
        <Content>
          <Switch>
            {organizationRoutes.map(route => (
              <Route
                exact
                key={route.path}
                path={route.path}
                render={props => {
                  const Component = route.component;
                  return (
                    <Component
                      {...props}
                      user={this.props.user}
                      permission={verifyPermission(
                        route.path,
                        this.props.permissions
                      )}
                    />
                  );
                }}
              />
            ))}
            <Route render={() => <Redirect to={"/tournaments"} />} />
          </Switch>
        </Content>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  organizations: state.organizationsState.organizations,
  permissions: state.permissionsState.permissions
});
const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RouteOrganizations));
