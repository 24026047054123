export const buildFilter = data => {
  const filter = {
    page: data && data.page ? data.page : 1,
    limit: data && data.limit ? data.limit : 10
  };
  if (data) {
    if (data.age && data.age !== "all") {
      filter.fromAge = data.age;
      filter.toAge = data.age + 1;
    }
    if (data.game && data.game !== "all") {
      filter.gameId = data.game;
    }
    if (data.status && data.status !== "all") {
      filter.status = data.status;
    }
    if (data.state && data.state !== "all") {
      filter.state = data.state;
    }
    if (data.type && data.type !== "all") {
      filter.type = data.type;
    }
    if (data.role) {
      filter.role = data.role;
    }
    if (data.organizationId) {
      filter.organizationId = data.organizationId;
    }
    if (data.affiliationId) {
      filter.affiliationId = data.affiliationId;
    }
    if (data.moderator) {
      filter.moderator = data.moderator;
    }
    if (data.userId) {
      filter.userId = data.userId;
    }
    if (data.groupId) {
      filter.groupId = data.groupId;
    }
    if (data.userIds) {
      filter.userIds = data.userIds;
    }
    if (data.walletId) {
      filter.walletId = data.walletId;
    }
    if (
      data.permissionIds &&
      data.permissionIds.length > 0 &&
      data.permissionIds
    ) {
      filter.permissionIds = data.permissionIds;
    }
    if (
      data.organizationIds &&
      data.organizationIds.length > 0 &&
      data.organizationIds
    ) {
      filter.organizationIds = data.organizationIds;
    }
    if (data.q) {
      filter.q = data.q;
    }
    if (data.consoleId) {
      filter.consoleId = data.consoleId;
    }
    if (data.name) {
      filter.name = data.name;
    }
    if (data.moderator) {
      filter.moderator = data.moderator;
    }
    if (data.organizationGroupId) {
      filter.organizationGroupId = data.organizationGroupId;
    }
    if ("isPrimary" in data) {
      filter.isPrimary = data.isPrimary;
    }
  }
  return Object.entries(filter)
    .map(item => `${item[0]}=${item[1]}`)
    .join("&");
};
