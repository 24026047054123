import React, { Component } from "react";
import {
  DialogContainer,
  DialogHeader,
  DialogTitle,
  PlayerSelection,
  SelectionText,
  PlayerRow,
  PlayerProfile,
  Buttons,
  WrapInputs,
  Success,
  Text
} from "./styled";
import {
  propValueOr,
  getUsername,
  hasOnlyDigitsInString
} from "../../../helpers/common";
import DialogKit from "../../../components/kit/Dialog/DialogKit";
import ButtonKit from "../../../components/kit/Button/ButtonKit";
import placeholderUserImg from "../../../static/images/default-user.jpg";
import FormFieldKit from "../../../components/kit/Fields/FormField/FormField";
import { api } from "../../../index";
import iconCheck from "../../../static/icons/circle-check-green.svg";
import iconX from "../../../static/icons/circle-x-red.svg";

class ChangeWinnerDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      homeScore: "",
      awayScore: "",
      success: false,
      error: ""
    };
  }

  componentDidUpdate(prevProps) {
    if (
      propValueOr(prevProps, "players", []) !==
        propValueOr(this.props, "players", []) &&
      propValueOr(this.props, "players", []).length >= 1
    ) {
      const players = this.props.players[0].isHome
        ? [...this.props.players]
        : [...this.props.players].reverse();
      let stateObj = {};
      stateObj.homeScore = propValueOr(players[0], "score", "");
      if (players.length > 1)
        stateObj.awayScore = propValueOr(players[1], "score", "");
      this.setState(stateObj);
    }
  }

  handleSubmitScores = () => {
    const { homeScore, awayScore } = this.state;
    let score =
      this.props.players.length === 1
        ? `${homeScore}`
        : `${homeScore} - ${awayScore}`;
    api.admin.matches
      .update(this.props.game.token, {
        score
      })
      .then(resp => this.setState({ success: true }))
      .catch(err => {
        console.log(err);
        this.setState({
          error: propValueOr(
            err,
            "response.data.message",
            err.message || "Unknown error. Please Try again later."
          )
        });
      });
  };

  handleChangeScore = (propName, value) => {
    if (hasOnlyDigitsInString(value) && value.length <= 3) {
      this.setState({ [propName]: value });
    }
  };

  handleClose = () => {
    const { onClose } = this.props;

    this.setState({ homeScore: "", awayScore: "", success: false });
    onClose();
  };

  renderSuccess = () => {
    const { error } = this.state;
    return (
      <DialogContainer>
        <DialogHeader>
          <DialogTitle>
            {error ? "Score Change Failed" : "Success!"}
          </DialogTitle>
        </DialogHeader>
        <Success>
          <img
            src={error ? iconX : iconCheck}
            width={65}
            height={65}
            alt="check"
          />
          <Text>{error || "The scores have been updated."}</Text>
        </Success>
        <Buttons>
          <ButtonKit
            fullWidth
            onClick={() =>
              error
                ? this.setState({
                    error: null,
                    homeScore: "",
                    awayScore: "",
                    success: false
                  })
                : this.handleClose()
            }
            color={error ? "primary" : "green"}
          >
            {error ? "Try Again" : "Okay"}
          </ButtonKit>
        </Buttons>
      </DialogContainer>
    );
  };

  renderDialog = () => {
    const { players } = this.props;
    const { homeScore, awayScore } = this.state;
    let isTeam = !!players[0].team;
    const sortedPlayers = players[0].isHome
      ? [...players]
      : [...players].reverse();

    return (
      <DialogContainer>
        <DialogHeader>
          <DialogTitle>Change Score</DialogTitle>
        </DialogHeader>
        <PlayerSelection>
          <SelectionText>
            {players.length === 1
              ? "Enter new score"
              : `Enter the new scores for each ${isTeam ? "team" : "player"}`}
          </SelectionText>
          {players.length === 1 ? (
            <WrapInputs>
              <FormFieldKit
                label={
                  <PlayerRow>
                    <PlayerProfile
                      src={propValueOr(
                        players[0],
                        "imageInfo.thumbnail",
                        placeholderUserImg
                      )}
                      onError={e => (e.target.src = placeholderUserImg)}
                    />
                    {isTeam ? players[0].team.name : getUsername(players[0])}
                  </PlayerRow>
                }
                value={homeScore || ""}
                onChange={val => this.handleChangeScore("homeScore", val)}
                placeholder={"Score..."}
                type={"number"}
              />
            </WrapInputs>
          ) : (
            <WrapInputs>
              <FormFieldKit
                label={
                  <PlayerRow>
                    <PlayerProfile
                      src={propValueOr(
                        sortedPlayers[0],
                        "imageInfo.thumbnail",
                        placeholderUserImg
                      )}
                      onError={e => (e.target.src = placeholderUserImg)}
                    />
                    {isTeam
                      ? sortedPlayers[0].team.name
                      : getUsername(sortedPlayers[0])}
                  </PlayerRow>
                }
                value={homeScore || ""}
                onChange={val => this.handleChangeScore("homeScore", val)}
                placeholder={"Home score..."}
                type={"number"}
              />
              <FormFieldKit
                label={
                  <PlayerRow>
                    <PlayerProfile
                      src={propValueOr(
                        sortedPlayers[1],
                        "imageInfo.thumbnail",
                        placeholderUserImg
                      )}
                      onError={e => (e.target.src = placeholderUserImg)}
                    />
                    {isTeam
                      ? sortedPlayers[1].team.name
                      : getUsername(sortedPlayers[1])}
                  </PlayerRow>
                }
                value={awayScore || ""}
                onChange={val => this.handleChangeScore("awayScore", val)}
                placeholder={"Away score..."}
                type={"number"}
              />
            </WrapInputs>
          )}
        </PlayerSelection>
        <Buttons>
          <ButtonKit
            onClick={this.handleSubmitScores}
            disabled={
              players.length === 1 ? !homeScore : !homeScore || !awayScore
            }
            fullWidth
            color={"red"}
          >
            Submit
          </ButtonKit>
          <ButtonKit
            fullWidth
            appearance={"secondary"}
            onClick={this.handleClose}
          >
            Cancel
          </ButtonKit>
        </Buttons>
      </DialogContainer>
    );
  };

  render() {
    const { isOpen } = this.props;

    return (
      <DialogKit
        isOpen={isOpen}
        onClose={this.handleClose}
        renderCustom={
          this.state.success || this.state.error
            ? this.renderSuccess
            : this.renderDialog
        }
        displayButtonClose={true}
        width={370}
      />
    );
  }
}

export default ChangeWinnerDialog;
