import { getAffiliationFromHost, propValueOr } from "../helpers/common";
import { defaultConfig } from "./configurations";

/* Get configuration based solely on hostname/pathname (no user auth required) */
export const pathConfig = path => {
  return defaultConfig;
};

/* Get configuration based on user's Affiliation ID (first priority), else use hostname/pathname */
class Config {
  constructor(store) {
    // Get redux store to access state when called upon
    this.store = store;

    this.clientName = "";

    // Get initial configuration based on user state
    const config = this.getConfig();

    // Map each config property to a class variable for external access
    Object.keys(config).forEach(key => (this[key] = config[key]));
  }

  /* Call to update when a new configuration may need to be loaded (e.g. when user logs in) */
  updateConfig() {
    const config = this.getConfig();
    Object.keys(config).forEach(key => (this[key] = config[key]));
  }

  getConfig() {
    const state = this.store.getState();
    const host = window.location.hostname;

    // get client name based on user's affiliation ID
    const clientOb = Array.isArray(
      propValueOr(state, "affiliations.affiliations", null)
    )
      ? state.affiliations.affiliations.find(
          aff =>
            aff.id ===
            propValueOr(
              state,
              "user.info.affiliationId",
              propValueOr(state, "routeRegistrationStudent.affiliation.id", "")
            )
        )
      : {};
    let client = propValueOr(clientOb, "name", "").toLowerCase();

    // If no affiliation can be grabbed, default to check hostname
    if (client === "") {
      client = getAffiliationFromHost(host);
    }

    //set client name
    this.clientName = client;

    // Set config object based on determined client

    return defaultConfig;
  }
}

export default Config;
