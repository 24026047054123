import imageCompression from "browser-image-compression";
import { isEmpty, pick } from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { api } from "../../..";
import ButtonKit from "../../../components/kit/Button/ButtonKit";
import DialogKit from "../../../components/kit/Dialog/DialogKit";
import CheckboxKit from "../../../components/kit/Fields/Checkbox/CheckboxKit";
import FormFieldKit from "../../../components/kit/Fields/FormField/FormField";
import { COLOR } from "../../../constants/theme";
import { propValueOr } from "../../../helpers/common";
import withWidth from "../../../hoc/withWidth";
import {
  fetchAffiliationsAction,
  fetchOrganizationsAction
} from "../../../store/organizations/actions";
import Uploader from "../Uploader/Uploader";
import { Container, Content, Description, Text, Title } from "./styled";

class Organization extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      displayName: "",
      slug: "",
      icon: null,
      affiliationId: null,
      accessCode: null,
      discordChannel: null,
      discordChannelName: "",
      postToDiscord: null,
      confirm: false,
      creating: false,
      success: false,
      updated: false,
      errors: {},
      error: ""
    };
  }

  componentDidMount() {
    this.props.fetchAffiliationsAction();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (!prevProps.organizationId &&
        !!this.props.organizationId &&
        this.props.organizations?.length > 0) ||
      (!!this.props.organizationId &&
        prevProps.organizations?.length > 0 &&
        this.props.organizations?.length > 0 &&
        !this.state.updated)
    ) {
      const orgData = pick(this.props.organization || {}, [
        "name",
        "displayName",
        "slug",
        "imageInfo",
        "affiliationId",
        "accessCode"
      ]);
      if (orgData?.imageInfo?.icon) {
        orgData.icon = orgData.imageInfo.icon;
        delete orgData.imageInfo;
      }
      if (this.props.organization.discord) {
        const { channel, channelName, post } = this.props.organization.discord;
        orgData.discordChannel = channel;
        orgData.discordChannelName = channelName;
        orgData.postToDiscord = post;
      }
      this.setState({ updated: true, ...orgData });
    }
  }

  handleCreate = () => {
    const {
      name,
      displayName,
      slug,
      icon,
      accessCode,
      discordChannel,
      discordChannelName,
      postToDiscord
    } = this.state;

    const discord = {
      ...(discordChannel && {
        channel: discordChannel
      }),
      ...(discordChannelName && {
        channelName: discordChannelName
      }),
      ...(postToDiscord && {
        post: postToDiscord
      })
    };

    let body = {
      name,
      displayName,
      slug,
      ...(icon instanceof File && {
        icon
      }),
      affiliationId: 1,
      ...(accessCode && { accessCode }),
      ...(!isEmpty(discord) && { discord })
    };

    let data = new FormData();
    for (let key in body) {
      if (key === "discord") {
        for (let dkey in body[key]) {
          data.append(`discord[${dkey}]`, body[key][dkey]);
        }
      } else {
        data.append(key, body[key]);
      }
    }

    this.setState({ creating: true });
    api.admin.organizations
      .create(data)
      .then(resp => {
        this.setState({
          name: "",
          displayName: "",
          slug: "",
          icon: null,
          affiliationId: null,
          accessCode: "",
          discordChannel: null,
          discordChannelName: "",
          postToDiscord: null,
          confirm: false,
          success: true,
          error: ""
        });
      })
      .catch(err => {
        this.setState({
          error:
            err?.response?.data?.errors?.[0]?.message ||
            propValueOr(
              err,
              "response.data.message",
              typeof err === "string" ? err : JSON.stringify(err)
            )
        });
      })
      .finally(() => this.setState({ creating: false }));
  };

  handleUpdate = () => {
    const { organization, organizationId } = this.props;
    const {
      name,
      displayName,
      slug,
      icon,
      accessCode,
      discordChannel,
      discordChannelName,
      postToDiscord
    } = this.state;

    const orgDiscord = organization.discord || {
      channel: null,
      channelName: "",
      post: null
    };

    const discord = {
      ...(discordChannel !== orgDiscord.channel && {
        channel: discordChannel
      }),
      ...(discordChannelName !== orgDiscord.channelName && {
        channelName: discordChannelName
      }),
      ...(postToDiscord !== orgDiscord.post && {
        post: postToDiscord
      })
    };

    let body = {
      ...(name !== organization?.name && !!name && { name }),
      ...(displayName !== organization?.displayName &&
        !!displayName && { displayName }),
      ...(slug !== organization?.slug && !!slug && { slug }),
      ...(icon instanceof File && {
        icon
      }),
      ...(accessCode !== organization?.accessCode && { accessCode }),
      ...(!isEmpty(discord) && { discord })
    };

    let data = new FormData();
    for (let key in body) {
      if (key === "discord") {
        for (let dkey in body[key]) {
          data.append(`discord[${dkey}]`, body[key][dkey]);
        }
      } else {
        data.append(key, body[key]);
      }
    }

    this.setState({ creating: true });
    api.admin.organizations
      .update(organizationId, data)
      .then(resp => {
        this.props.fetchOrganizationsAction();
        const organization = resp.data;
        const orgData = pick(organization || {}, [
          "name",
          "displayName",
          "slug",
          "imageInfo",
          "affiliationId",
          "accessCode"
        ]);
        if (orgData?.imageInfo?.icon) {
          orgData.icon = orgData.imageInfo.icon;
          delete orgData.imageInfo;
        }
        if (organization.discord) {
          const { channel, channelName, post } = organization.discord;
          orgData.discordChannel = channel;
          orgData.discordChannelName = channelName;
          orgData.postToDiscord = post;
        }
        this.setState({
          confirm: false,
          success: true,
          error: "",
          ...orgData
        });
      })
      .catch(err => {
        console.log(err);
        this.setState({
          error:
            err?.response?.data?.errors?.[0]?.message ||
            propValueOr(
              err,
              "response.data.message",
              typeof err === "string" ? err : JSON.stringify(err)
            )
        });
      })
      .finally(() => this.setState({ creating: false }));
  };

  canSubmit = () => {
    const { organization, organizationId } = this.props;
    const {
      name,
      displayName,
      slug,
      icon,
      accessCode,
      discordChannel,
      discordChannelName,
      postToDiscord,
      errors
    } = this.state;

    const orgDiscord = organization?.discord || {
      channel: null,
      channelName: "",
      post: null
    };

    return !!organizationId
      ? icon instanceof File ||
          (name !== organization?.name && !!name) ||
          (displayName !== organization?.displayName && !!displayName) ||
          (slug !== organization?.slug && !!slug) ||
          accessCode !== organization?.accessCode ||
          (discordChannel !== orgDiscord.channel &&
            ((discordChannel && !!discordChannelName) || !discordChannel)) ||
          (!!discordChannelName &&
            discordChannelName !== orgDiscord.channelName) ||
          postToDiscord !== orgDiscord.post
      : name &&
          displayName &&
          slug &&
          icon &&
          ((discordChannel && !!discordChannelName) || !discordChannel) &&
          Object.values(errors).some(v => !v);
  };

  renderConfirmDialog = () => {
    const { organizationId } = this.props;
    const isUpdate = !!organizationId;
    return (
      <DialogKit
        isOpen={this.state.confirm}
        onClose={() => this.setState({ confirm: false })}
        title={"Are You Sure?"}
        renderBody={() => (
          <Text>
            Are you sure you want to {isUpdate ? "update" : "create"} this
            organization?
            <br />
            <br />
            Please verify the provided information is correct. This action
            cannot be undone.
          </Text>
        )}
        renderFooter={() => (
          <>
            {this.state.error && (
              <Text style={{ color: COLOR.CHERRY_RED }}>
                {this.state.error}
              </Text>
            )}
            <ButtonKit
              shape={"rounded"}
              color={"rival_red"}
              fullWidth
              onClick={isUpdate ? this.handleUpdate : this.handleCreate}
              preloader={this.state.creating}
            >
              {isUpdate ? "Update Organization" : "Create Organization"}
            </ButtonKit>
          </>
        )}
      />
    );
  };

  renderSuccessDialog = () => {
    return (
      <DialogKit
        isOpen={this.state.success}
        onClose={() => this.setState({ success: false })}
        title={"Success!"}
        renderBody={() => (
          <Text>
            The organization has been{" "}
            {!!this.props.organizationId ? "updated" : "created"}.
          </Text>
        )}
        renderFooter={() => (
          <ButtonKit
            shape={"rounded"}
            color={"rival_red"}
            fullWidth
            onClick={() => this.setState({ success: false })}
          >
            Okay
          </ButtonKit>
        )}
      />
    );
  };

  render() {
    const {
      name,
      displayName,
      slug,
      icon,
      accessCode,
      discordChannel,
      discordChannelName,
      postToDiscord,
      errors
    } = this.state;
    const { organizationId, organization } = this.props;

    const isUpdate = !!organizationId;

    return (
      <Container>
        <Title>{isUpdate ? "Update" : "Create"} Organization</Title>
        <Text>
          {isUpdate
            ? `Update information for ${organization?.name} below. To create a new organization, please change to "All Organizations" in the organization selector.`
            : `Enter information for the new organization below. To update an organization, please switch to the desired one in the organization selector.`}
        </Text>
        <Content>
          <FormFieldKit
            fullWidth
            label="Name"
            value={name}
            onChange={val =>
              this.setState({ name: val, errors: { ...errors, name: null } })
            }
            inputProps={{ maxLength: 50 }}
            placeholder={"Enter a name..."}
            required
            onBlur={() =>
              this.setState({
                errors: {
                  ...this.state.errors,
                  name:
                    !name || !(name?.length > 0)
                      ? "Please enter a valid name."
                      : null
                }
              })
            }
            errorText={errors.name}
          />
          <FormFieldKit
            fullWidth
            label="Display Name (used for SEO)"
            value={displayName}
            onChange={val =>
              this.setState({
                displayName: val,
                errors: { ...errors, displayName: null }
              })
            }
            inputProps={{ maxLength: 50 }}
            placeholder={"Enter a display name..."}
            required
            onBlur={() =>
              this.setState({
                errors: {
                  ...this.state.errors,
                  displayName:
                    !displayName || !(displayName?.length > 0)
                      ? "Please enter a valid name."
                      : null
                }
              })
            }
            errorText={errors.displayName}
          />
          <FormFieldKit
            fullWidth
            label="Slug"
            value={slug}
            onChange={val => {
              const newVal = (val?.toLowerCase() || "")
                .replace(/\s/g, "-")
                .replace(/[^a-z0-9-]/g, "");
              this.setState({ slug: newVal });
            }}
            inputProps={{ maxLength: 50 }}
            placeholder={"Enter a slug..."}
            description={
              isUpdate
                ? "This is the URL for the community. (i.e. https://rivalgames.com/<SLUG_GOES_HERE>)"
                : "This is the URL that the new community will live under. (i.e. https://rivalgames.com/<SLUG_GOES_HERE>)"
            }
            required
            onBlur={() =>
              this.setState({
                errors: {
                  ...this.state.errors,
                  name:
                    !name || !(name?.length > 0)
                      ? "Please enter a valid name."
                      : null
                }
              })
            }
            errorText={errors.slug}
          />
          <FormFieldKit
            fullWidth
            label="Hide Community With Access Code (optional)"
            value={accessCode || ""}
            onChange={val => this.setState({ accessCode: val })}
            inputProps={{ maxLength: 30 }}
            placeholder={"Enter an access code..."}
            description={
              "To hide this community and block public access you can set an access code. This can always be updated or removed at any time."
            }
          />
          Discord
          <CheckboxKit
            label="Community Channel"
            horizontalLabel
            onChange={() =>
              this.setState(prevState => {
                const newState = prevState.discordChannel
                  ? {
                      discordChannelName: this.props.organization?.discord
                        ?.channelName,
                      discordChannel: !discordChannel
                    }
                  : {
                      discordChannel: !discordChannel
                    };
                this.setState(newState);
              })
            }
            checked={discordChannel}
            isBig
            fullWidth
            style={{ margin: "10px 0 10px 0" }}
          />
          <Description style={{ marginBottom: 30 }}>
            Any existing channel will be archived if unchecked and can be
            restored by re-checking
          </Description>
          {discordChannel && (
            <FormFieldKit
              fullWidth
              label="Channel Name"
              value={discordChannelName}
              // onChange={(val) => this.setState({ discordChannelName: val })}
              onChange={val => {
                const newVal = (val?.toLowerCase() || "")
                  .replace(/\s/g, "-")
                  .replace(/[^a-z0-9-]/g, "");
                this.setState({ discordChannelName: newVal });
              }}
              inputProps={{ maxLength: 30 }}
              placeholder={"Enter a channel name..."}
              disabled={!discordChannel}
              required
            />
          )}
          <CheckboxKit
            label="Automatically post tournaments to discord 48 hours before start"
            horizontalLabel
            onChange={() =>
              this.setState({
                postToDiscord: !postToDiscord
              })
            }
            checked={!!postToDiscord}
            isBig
            fullWidth
            style={{ margin: "10px 0 30px 0" }}
          />
          <Uploader
            label="Community Logo"
            description={
              "This will show in the community selector or under the 'Find A Community' list on the landing page. **Note: looks best with an aspect ratio of 1:1"
            }
            onChange={async file => {
              let compressedImage = await imageCompression(file, {
                maxSizeMB: 0.2,
                useWebWorker: true
              });
              this.setState({
                icon: new File([compressedImage], "icon", {
                  type: file.type?.includes["image"]
                    ? file.type.replace("image/", ".")
                    : ".png"
                })
              });
            }}
            constraints={{ width: [25, 220], height: [25, 220] }}
            imgLabels={[]}
            imageDimensions={[100, 100]}
            defaultImage={icon}
            onReset={() => this.setState({ icon: null })}
            name={"icon"}
          />
          <ButtonKit
            style={{ marginTop: "15px" }}
            disabled={!this.canSubmit()}
            shape={"rounded"}
            color={"rival_red"}
            fullWidth
            appearance={"secondary"}
            onClick={() => this.setState({ confirm: true })}
          >
            {isUpdate ? "Update" : "Create"}
          </ButtonKit>
        </Content>
        {this.renderConfirmDialog()}
        {this.renderSuccessDialog()}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  organization: (state.organizationsState?.organizations || []).find(
    o => o.id === state.user?.info?.organizationId
  ),
  affiliations: state.organizationsState?.affiliations,
  organizationId: state.user?.info?.organizationId,
  organizations: state.organizationsState?.organizations,
  creating: state.organizationsState?.creating
});
const mapDispatchToProps = {
  fetchAffiliationsAction,
  fetchOrganizationsAction
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withWidth(Organization)));
