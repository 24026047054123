import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";
import userReducer from "./user/reducer";
import usersReducer from "./users/reducers";
import tournamentReducer from "./tournament/reducers";
import leaguesReducer from "./leagues/reducers";
import teamReducer from "./team/reducers";
import assetsReducer from "./assets/reducers";
import permissionsReducer from "./permissions/reducers";
import organizationsReducer from "./organizations/reducers";
import organizationGamesReducer from "./orgGames/reducers";
import gameReducer from "./game/reducers";
import gameSeriesReducer from "./gameSeries/reducers";
import actionsReducer from "./actions/reducers";
import notificationsReducer from "./notifications/reducers";
import communicationReducer from "./communication/reducer";
import walletReducer from "./wallet/reducers";
import matchesReducer from "./matches/reducers";
import gamesReducer from "./games/reducers";
import challengesReducer from "./challenges/reducers";
import chatReducer from "./chat/reducers";
import { consolesReducer } from "./consoles/reducer";

const store = function() {
  const reducers = {
    user: userReducer,
    tournamentState: tournamentReducer,
    teamState: teamReducer,
    assetsState: assetsReducer,
    permissionsState: permissionsReducer,
    organizationsState: organizationsReducer,
    leaguesState: leaguesReducer,
    gameState: gameReducer,
    gamesState: gamesReducer,
    gameSeriesState: gameSeriesReducer,
    orgGamesState: organizationGamesReducer,
    actionState: actionsReducer,
    notifications: notificationsReducer,
    communication: communicationReducer,
    usersState: usersReducer,
    walletState: walletReducer,
    matchesState: matchesReducer,
    challengesState: challengesReducer,
    chat: chatReducer,
    consoles: consolesReducer
  };

  const middleware = [thunk];

  // In development, use the browser's Redux dev tools extension if installed
  const isDevelopment = process.env.NODE_ENV === "development";

  // use the old version (2.15.5) of the redux dev tools extension, because version 2.16 has issue
  // https://github.com/zalmoxisus/redux-devtools-extension/issues/588

  // noinspection JSUnresolvedVariable
  const composeEnhancers =
    (isDevelopment && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

  const rootReducer = combineReducers(reducers);

  return createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(...middleware))
  );
};

export default store;
