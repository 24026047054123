import styled from "styled-components";
import {
  COLOR,
  FONT_FAMILY,
  FONT_WEIGHT
} from "../../../../../constants/theme";
export const Container = styled.div`
  padding: 20px 30px;
  overflow: auto;
`;

export const Row = styled.div`
  display: flex;
  > :not(:last-child) {
    margin-right: 20px;
  }
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Title = styled.div`
  margin: 20px 0 10px;
  font-weight: ${FONT_WEIGHT.BOLD};
  ${({ round }) =>
    round &&
    `
    margin: 0 5px 0 0;
    white-space: nowrap;
    flex-grow: 1;
  `}
`;

export const Label = styled.div`
  font: ${FONT_WEIGHT.NORMAL} 14px ${FONT_FAMILY.THIN};
`;

export const Round = styled.div`
  > :not(:first-child) {
    padding-left: 15px;
  }
`;

export const Description = styled.div`
  font: ${FONT_WEIGHT.NORMAL} 12px / 1 ${FONT_FAMILY.THIN};
  color: ${COLOR.GREY};
  width: 100%;
  text-align: left;
  margin-bottom: 8px;
  margin-top: 5px;
  min-height: 12px;
`;

export const WrapTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 20px 0 10px;
`;

export const Line = styled.div`
  flex-basis: 100%;
  margin: auto 0;
  height: 1px;
  background-color: ${COLOR.WHITE};
`;

export const WrapButtons = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  > :not(:last-child) {
    margin-bottom: 10px;
  }
`;

export const WrapButtonsRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;

  > :not(:last-child) {
    margin-right: 16px;
  }
  margin-bottom: 20px;
`;

export const Wrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

export const WrapEmpty = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  text-align: center;
  margin: 20px 0;
`;

export const Icon = styled.div`
  cursor: pointer;
`;

export const SettingFields = styled.div`
  display: flex;
  flex-direction: row;
`;

export const SettingField = styled.div`
  margin: 10px;
  width: 100%;
`;
