// https://fatfisz.com/blog/solving-z-index-with-styled-components

import React, { Component } from "react";
import PropTypes from "prop-types";
import { ThemeProvider } from "styled-components";
import { propValueOr } from "../../../helpers/common";

const types = ["loader", "popup", "stickyMatchInfo", "topBar", "modal"];

export const zIndex = props => {
  return propValueOr(props.theme, "levelUp.value", 0);
};

class LevelUp extends Component {
  static propTypes = {
    children: PropTypes.node,
    type: PropTypes.oneOf(types).isRequired
  };

  baseValue = 1000;

  getLevelIndex(level, value = 0) {
    return level * this.baseValue + value;
  }

  zIndexMap = types.reduce((acc, item, index) => {
    acc[item] = index;
    return acc;
  }, {});

  getNextLevel(level, type) {
    return {
      levelUp: {
        level,
        value: this.getLevelIndex(level, this.zIndexMap[type])
      }
    };
  }

  getTheme(type) {
    return prevTheme => ({
      ...prevTheme,
      ...this.getNextLevel(propValueOr(prevTheme, "levelUp.level", 0) + 1, type)
    });
  }

  render() {
    const { children, type } = this.props;
    const theme = this.getTheme(type);

    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
  }
}

export default LevelUp;
