import styled from "styled-components";
import {
  COLOR,
  FONT_WEIGHT,
  FONT_FAMILY
} from "../../../../../constants/theme";
import { Link } from "react-router-dom";
import breakpoints from "../../../../../helpers/breakpoints";

export const Container = styled.div`
  min-height: 100%;
  width: 100%;
  margin-top: 10px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

export const WrapTable = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  > :not(:last-child) {
    border: 1px solid ${COLOR.BORDER};
  }

  background-color: ${COLOR.BG_SECONDARY};
`;

export const TableCell = styled.div`
  display: flex;
  align-items: center;

  font: ${FONT_WEIGHT.NORMAL} 16px ${FONT_FAMILY.THIN};
  color: ${COLOR.FG_PRIMARY};
`;

export const TableEmpty = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 89px;
  padding-bottom: 67px;
  color: ${COLOR.FG_PRIMARY};
`;

export const WrapIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  width: 100%;

  > :not(:last-child) {
    border-bottom: 1px solid ${COLOR.BORDER};
  }
`;

export const Item = styled(Link)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding: 10px 0px;

  color: ${COLOR.TEXT};
  text-decoration: none;

  &:hover {
    opacity: 0.4;
  }
`;

export const Badge = styled.div`
  display: flex;
  justify-content: center;
  min-width: 85px;
  height: 20px;

  padding: 3px 10px;
  border-radius: 12px 12px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  font: ${FONT_WEIGHT.NORMAL} 13px ${FONT_FAMILY.GOTHIC};
  text-transform: uppercase;

  background-color: ${({ status }) => {
    switch (status) {
      case "succeeded":
        return COLOR.GREEN;
      case "pending":
        return COLOR.YELLOW;
      case "failed":
        return COLOR.ROUGE;
      default:
        return COLOR.BG_INPUT;
    }
  }};
`;

export const Status = styled.div`
  display: flex;
  justify-content: center;
  min-width: 85px;
  height: 20px;

  padding: 3px 10px;
  margin: 0px 10px;
  border-radius: 12px 12px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  font: ${FONT_WEIGHT.NORMAL} 13px ${FONT_FAMILY.GOTHIC};
  text-transform: uppercase;

  background-color: ${({ status }) => {
    switch (status) {
      case "succeeded":
        return COLOR.GREEN;
      case "pending":
        return COLOR.YELLOW;
      case "failed":
        return COLOR.ROUGE;
      default:
        return COLOR.BG_INPUT;
    }
  }};

  ${breakpoints.down("sm")} {
    display: none;
  }
`;

export const Id = styled.div`
  display: flex;
  justify-content: center;
  height: 20px;

  padding: 3px 10px;
  margin: 0px 10px;
  border-radius: 12px 12px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  font: ${FONT_WEIGHT.NORMAL} 13px ${FONT_FAMILY.GOTHIC};
  text-transform: uppercase;

  background-color: ${COLOR.BG_INPUT};

  ${breakpoints.down("sm")} {
    display: none;
  }
`;

export const Info = styled.div`
  display: flex;
  align-items: center;
`;

export const Text = styled.div`
  display: flex;
  font: ${FONT_WEIGHT.NORMAL} 15px ${FONT_FAMILY.THIN};
  margin-right: 15px;
  ${breakpoints.down("md")} {
    display: none;
  }
`;

export const WrapCoinsCount = styled.div`
  display: flex;
  align-items: center;
`;

export const CoinsIcon = styled.img`
  width: 15px;
  height: 15px;
  margin-left: 3px;
`;

export const CoinsCount = styled.div`
  font: ${FONT_WEIGHT.NORMAL} 15px ${FONT_FAMILY.GOTHIC_BOLD};
  margin-top: 5px;
`;
