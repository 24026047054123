import { GAME_ACTIONS } from "./constants";
import { api } from "../../index";
import { propValueOr } from "../../helpers/common";

export const fetchGameAction = token => dispatch => {
  dispatch({
    type: GAME_ACTIONS.fetch.request
  });

  return api.match
    .get(token)
    .then(resp => {
      dispatch({
        type: GAME_ACTIONS.fetch.success,
        payload: propValueOr(resp, "data", {})
      });
      return resp;
    })
    .catch(err => {
      dispatch({ type: GAME_ACTIONS.fetch.error, payload: err });
      throw err;
    });
};

export const pusherGameAction = query => dispatch => {
  dispatch({
    type: GAME_ACTIONS.pusher.update,
    payload: query
  });
};

export const resetGameAction = () => dispatch => {
  dispatch({
    type: GAME_ACTIONS.reset
  });
};
