import styled from "styled-components";
import { COLOR, FONT_FAMILY, FONT_WEIGHT } from "../../../constants/theme";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${COLOR.BG_PRIMARY};
  border-radius: 4px;
`;

export const ContainerWrap = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  padding: 0px 10px;
`;

export const Content = styled.div`
  margin-left: 25px;
  width: 100%;
  max-width: 1200px;

  padding: 60px 4% 30px 4%;
  border-radius: 16px;
  margin: 0 ${({ mobile }) => (mobile ? "0" : "9%")};
  margin-bottom: 30px;

  background-color: ${COLOR.BG_SECONDARY};
`;
export const WrapItem = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  width: 100%;
  > * {
    width: 100%;
    :not(:last-child) {
      margin-bottom: 60px;
    }
  }
`;
export const WrapButtons = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;

  > :not(:last-child) {
    margin-right: 16px;
  }
  margin-bottom: 20px;
`;

export const Title = styled.div`
  text-align: center;
  max-width: 560px;
  font: ${FONT_WEIGHT.MEDIUM} 32px ${FONT_FAMILY.GOTHIC_BOLD};
  text-transform: uppercase;
`;

export const Row = styled.div`
  display: flex;
  font-family: ${FONT_FAMILY.THIN};
  > :not(:last-child) {
    margin-right: 20px;
  }
`;

export const ErrorList = styled.ul``;

export const ErrorItem = styled.li`
  color: ${COLOR.CHERRY_RED};
  line-height: 36px;
`;

export const DialogText = styled.div`
  font: ${FONT_WEIGHT.NORMAL} 14px ${FONT_FAMILY.THIN};
  color: ${COLOR.TEXT};
`;

export const Timezone = styled.div`
  font: ${FONT_WEIGHT.LIGHT} 16px ${FONT_FAMILY.THIN};
  &&& {
    margin-bottom: 15px;
  }
  span {
    font-weight: ${FONT_WEIGHT.MEDIUM};
  }
`;

export const Required = styled.div`
  font: ${FONT_WEIGHT.NORMAL} 16px ${FONT_FAMILY.THIN};
  &&& {
    margin-bottom: 10px;
  }
  span {
    color: ${COLOR.CHERRY_RED};
    font-weight: ${FONT_WEIGHT.BOLD};
  }
`;
