import React from "react";
import styled from "styled-components";
import { COLOR } from "../../../constants/theme";
import { withRouter, Link } from "react-router-dom";

import PlusIcon from "../../../static/icons/minus-icon.svg";

function HubBarMenu({ slug, background, organization, loading }) {
  if (!organization?.groups || loading) return <></>;

  const hubs = organization?.groups || [];

  return hubs?.length ? (
    <Container>
      <Content>
        {hubs?.map(hub => {
          return (
            <Link key={hub.slug} to={`/organizations/groups/${hub.slug}`}>
              <Item>
                <WrapIcon active={slug === hub.slug}>
                  <Icon
                    alt={hub?.name}
                    layout="fill"
                    objectFit="contain"
                    src={hub?.imageInfo?.icon}
                  />
                </WrapIcon>
              </Item>
            </Link>
          );
        })}
        <Link key={"add-hub"} to={`/organizations/groups`}>
          <WrapIcon active={!slug}>
            <Icon layout="fill" objectFit="contain" src={PlusIcon} />
          </WrapIcon>
        </Link>
      </Content>
    </Container>
  ) : (
    <></>
  );
}

function HubBar({ organization, slug, loading, background, location }) {
  // Only show on /play routes
  if (location.pathname?.includes("/groups")) {
    return (
      <HubBarMenu
        organization={organization}
        loading={loading}
        background={background}
        slug={slug}
      />
    );
  }
  return <></>;
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0 10px;
  width: 100%;
  color: ${COLOR.TEXT};
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(8px);
  box-shadow: rgb(0 0 0 / 8%) 0px 1px 2px, rgb(0 0 0 / 8%) 0px 4px 12px;
  transition: all 0.2s ease 0s;
  border-bottom: 1px solid ${COLOR.BORDER};
  margin-bottom: 10px;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 100%;
`;

const WrapIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ active }) => (active ? "50px" : "45px")};
  height: ${({ active }) => (active ? "50px" : "45px")};
  margin: 0 10px;
  border-radius: 50%;
  background: ${({ active, color }) =>
    active ? (color ? color : COLOR.RIVAL_RED) : COLOR.WHITE};
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (hover: hover) {
    :hover ${WrapIcon} {
      background: ${({ color }) => (color ? color : COLOR.RIVAL_RED)};
    }
  }
  :active ${WrapIcon} {
    background: ${({ color }) => (color ? color : COLOR.RIVAL_RED)};
  }
`;

const Icon = styled.img`
  height: 40px;
  width: 40px;
  object-fit: contain;
  border-radius: 50%;
  overflow: hidden;
  background: ${COLOR.BLACK};
`;

export default withRouter(HubBar);
