import React, { Component } from "react";
import { connect } from "react-redux";
import RouteNotifications from "./RouteNotifications";
import { withRouter } from "react-router-dom";
import { getGlobalUser } from "../../store/user/selectors";
import PermissionDenied from "../denied/PermissionDenied";
class RouteNotificationsContainer extends Component {
  render() {
    const { permission } = this.props;
    if (!permission) {
      return <PermissionDenied />;
    }
    return <RouteNotifications />;
  }
}

const mapStateProps = state => ({
  user: getGlobalUser(state)
});

const mapDispatchToProps = {};

export default connect(
  mapStateProps,
  mapDispatchToProps
)(withRouter(RouteNotificationsContainer));
