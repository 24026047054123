import styled from "styled-components";
import PropTypes from "prop-types";
import { COLOR, FONT_FAMILY, FONT_WEIGHT } from "../../../constants/theme";

export const LargeNotificationSD = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  padding: 12px 22px;

  color: ${COLOR.TEXT_4};
  font: ${FONT_WEIGHT.MEDIUM} 12px / 1.2 ${FONT_FAMILY.THIN};
  letter-spacing: -0.22px;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
    background-color: ${({ wasNotRead }) =>
      wasNotRead ? COLOR.CHERRY_RED : "transparent"};
  }
`;
LargeNotificationSD.propTypes = {
  wasNotRead: PropTypes.bool
};

export const LargeNotificationWrapImgSD = styled.div`
  flex-shrink: 0;
  width: 34px;

  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  border-radius: 2px;
`;
export const LargeNotificationImgSD = styled.img`
  display: block;
  width: 100%;
  height: auto;
`;
export const LargeNotificationWrapContentSD = styled.div`
  flex: 1 0 0;
  padding: 2px 10px 0;

  b,
  strong {
    color: ${COLOR.TEXT_3};
    font-weight: ${FONT_WEIGHT.BOLD};
  }

  a {
    color: ${COLOR.TEXT_LINK};
    font-weight: ${FONT_WEIGHT.BOLD};
    text-decoration: none;
  }
`;
export const LargeNotificationTitleSD = styled.div`
  margin-bottom: 4px;
  font: ${FONT_WEIGHT.NORMAL} 14px ${FONT_FAMILY.THIN};
`;
export const LargeNotificationContentSD = styled.div`
  font: ${FONT_WEIGHT.LIGHT} 12px ${FONT_FAMILY.THIN};
`;
export const LargeNotificationWrapDateSD = styled.div`
  flex-shrink: 0;
  min-width: 40px;
  padding-top: 10px;
  text-align: right;
  font: ${FONT_WEIGHT.LIGHT} 10px ${FONT_FAMILY.THIN};
`;
export const LargeNotificationDateSD = styled.div``;
