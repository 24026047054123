import NFT from "../../../../artifacts/contracts/NFT.sol/NFT.json";
import Market from "../../../../artifacts/contracts/Market.sol/NFTMarket.json";
import React, { useState } from "react";
import { ethers } from "ethers";
import { create as ipfsHttpClient } from "ipfs-http-client";
import Web3Modal from "web3modal";
import { Container, WrapInput, Input, WrapPreview, Preview } from "./styled";
import FormFieldKit from "../../../../components/kit/Fields/FormField/FormField";
import ButtonKit from "../../../../components/kit/Button/ButtonKit";
import { useHistory } from "react-router-dom";

const client = ipfsHttpClient("https://ipfs.infura.io:5001/api/v0");

export default function CreateItem() {
  let history = useHistory();
  const [submitting, setSubmitting] = useState(false);
  const [fileUrl, setFileUrl] = useState(null);
  const [formInput, updateFormInput] = useState({
    price: "",
    name: "",
    description: ""
  });

  const isSubmitDisabled = () => {
    return (
      submitting ||
      !formInput.price ||
      !formInput.name ||
      !formInput.description ||
      !fileUrl
    );
  };

  async function onChange(e) {
    const file = e.target.files[0];
    try {
      const added = await client.add(file, {
        progress: prog => console.log(`received: ${prog}`)
      });
      const url = `https://ipfs.infura.io/ipfs/${added.path}`;
      setFileUrl(url);
    } catch (error) {
      console.log("Error uploading file: ", error);
    }
  }
  async function createMarket() {
    const { name, description, price } = formInput;
    if (!name || !description || !price || !fileUrl) return;
    /* first, upload to IPFS */
    setSubmitting(true);
    const data = JSON.stringify({
      name,
      description,
      image: fileUrl
    });
    try {
      const added = await client.add(data);
      const url = `https://ipfs.infura.io/ipfs/${added.path}`;
      /* after file is uploaded to IPFS, pass the URL to save it on Polygon */
      createSale(url);
    } catch (error) {
      console.log("Error uploading file: ", error);
    }
  }

  async function createSale(url) {
    const web3Modal = new Web3Modal();
    const connection = await web3Modal.connect();
    const provider = new ethers.providers.Web3Provider(connection);
    const signer = provider.getSigner();

    /* next, create the item */
    let contract = new ethers.Contract(
      process.env.REACT_APP_NFT_ADDRESS,
      NFT.abi,
      signer
    );
    let transaction = await contract.createToken(url);
    let tx = await transaction.wait();
    let event = tx.events[0];
    let value = event.args[2];
    let tokenId = value.toNumber();
    const price = ethers.utils.parseUnits(formInput.price, "ether");

    /* then list the item for sale on the marketplace */
    contract = new ethers.Contract(
      process.env.REACT_APP_NFT_MARKET_ADDRESS,
      Market.abi,
      signer
    );
    let listingPrice = await contract.getListingPrice();
    listingPrice = listingPrice.toString();

    transaction = await contract.createMarketItem(
      process.env.REACT_APP_NFT_ADDRESS,
      tokenId,
      price,
      {
        value: listingPrice
      }
    );
    await transaction.wait();
    setSubmitting(false);
    history.push("/marketplace");
  }

  return (
    <Container>
      <FormFieldKit
        placeholder="Asset Name"
        onChange={e => updateFormInput({ ...formInput, name: e })}
      />
      <FormFieldKit
        placeholder="Asset Description"
        onChange={e => updateFormInput({ ...formInput, description: e })}
      />
      <FormFieldKit
        placeholder="Asset Price in SGB"
        onChange={e => updateFormInput({ ...formInput, price: e })}
      />
      <WrapInput>
        <Input type="file" name="Asset" onChange={onChange} />
      </WrapInput>
      {fileUrl && (
        <WrapPreview>
          <Preview src={fileUrl} alt="preview" />
        </WrapPreview>
      )}
      <ButtonKit
        onClick={createMarket}
        fullWidth
        small
        disabled={isSubmitDisabled()}
        preloader={submitting}
        appearance={"secondary"}
      >
        Create Digital Asset
      </ButtonKit>
    </Container>
  );
}
