import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import DialogKit from "../../../../components/kit/Dialog/DialogKit";
import ButtonKit from "../../../../components/kit/Button/ButtonKit";
import {
  Container,
  Header,
  HeaderTitle,
  Content,
  FooterContent,
  SectionTitle,
  SearchBar,
  PlaceholderText,
  ErrorText,
  Profile,
  GreyedProfile,
  List,
  Buttons,
  Divider,
  CheckImg,
  Errors,
  DialogButtons
} from "./styled";

import {
  propValueOr,
  isObjectEmpty,
  getUsername
} from "../../../../helpers/common";
import { api } from "../../../../index";
import {
  fetchTeamAction,
  resetTeamAction
} from "../../../../store/team/actions";
import FormFieldKit from "../../../../components/kit/Fields/FormField/FormField";
import FieldTypeAndSelect from "../../../../components/smart/FieldTypeAndSelect/FieldTypeAndSelect";

import CheckIcon from "../../../../static/icons/circle-check-green.svg";
import PlayerListItem from "./PlayerItem";
import {
  // fetchPlayersAction,
  fetchSeason
} from "../../../../store/leagues/actions";

class AddTeamDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      teamList: [],
      selectedTeam: null,
      captain: null,
      roster: [],
      selectionPageIndex: 0,
      captainIndex: 0,
      rosterSelection: {},
      rosterCount: 0,
      loading: false,
      errors: null
    };
  }

  componentDidUpdate(prevProps) {
    const { isFetchingTeam, team } = this.props;
    if (prevProps.isFetchingTeam && !isFetchingTeam && !isObjectEmpty(team)) {
      const rosterSelection = this.getRosterSelection();
      this.setState({
        rosterSelection,
        rosterCount: 0
      });
    }
  }

  getRosterSelection() {
    const { team } = this.props;
    const { captain } = this.state;
    let rosterSelection = {};
    propValueOr(team, "users", []).forEach(player => {
      const selected =
        propValueOr(captain, "id", null) === player.id ? true : false;
      rosterSelection[player.id] = selected;
    });
    return rosterSelection;
  }

  validTeam() {
    const { season } = this.props;
    const { selectedTeam, captain, rosterCount } = this.state;

    if (!!!selectedTeam || !!!captain) return false;

    if (rosterCount + 1 < season.teamSize) return false;

    return true;
  }

  close() {
    this.props.resetTeamAction();
    this.setState({
      selectedTeam: null,
      captain: null,
      roster: this.getRosterSelection(),
      selectionPageIndex: 0,
      rosterSelection: {},
      rosterCount: 0,
      errors: null
    });
    this.props.onClose();
  }

  selectTeam(team) {
    if (!team) return;
    this.props.resetTeamAction();
    this.props.fetchTeamAction(team.slug, this.props.season?.id);
    this.setState({ selectedTeam: team });
  }

  selectCaptain(player) {
    const { rosterSelection, captain } = this.state;

    let newSelection = rosterSelection;
    if (!!captain) newSelection[captain.id] = false;
    newSelection[player.id] = true;
    this.setState({ captain: player, rosterSelection: newSelection });
  }

  AddTeamRoster(id) {
    const { season } = this.props;
    let { rosterSelection, rosterCount } = this.state;

    if (
      season.maxTeam && season.maxTeam > season.teamSize
        ? rosterCount < season.maxTeam - 1 || rosterSelection[id]
        : rosterCount < season.teamSize - 1 || rosterSelection[id]
    ) {
      rosterSelection[id] = !rosterSelection[id];
      rosterSelection[id] ? rosterCount++ : rosterCount--;
      this.setState({ rosterSelection, rosterCount });
    }
  }

  submitTeam() {
    const { team, season } = this.props;
    const { captain, rosterSelection } = this.state;
    if (this.validTeam()) {
      const body = {};

      const rosterIds = Object.entries(rosterSelection)
        .filter(u => !!u[1] && parseInt(u[0]) !== captain.id)
        .map(u => parseInt(u[0]));

      body.team = {
        ...team,
        users: team.users.filter(u => rosterIds.includes(u.id))
      };

      this.setState({ loading: true });
      api.season.users
        .addTeam(season.token, captain.id, body)
        .then(resp => {
          this.props.fetchSeasonAction(season.token);
          if (resp.data && resp.data.errors && resp.data.errors.length) {
            this.setState({
              errors: resp.data.errors
            });
          } else {
            this.close();
          }
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    }
  }

  handleSearchUsers = (searchWord, newPage = false, resetPage = false) => {
    let data = [];

    if (this.props.season?.teamSize > 1) {
      data = api.team
        .list({
          name: searchWord,
          q: searchWord,
          limit: 25,
          ...(!searchWord &&
            !resetPage && {
              page: newPage ? this.state.page + 1 : this.state.page
            })
        })
        .then(resp => {
          if (propValueOr(resp, "data.errors") && resp.data.errors.length) {
            this.setState({
              errors: resp.data.errors
            });
            return [];
          }
          const retList = propValueOr(resp, "data.docs", []).map(team => ({
            label: team.name,
            value: team.id,
            ...team
          }));

          const compList = [
            ...(!searchWord && !resetPage ? this.state.searchList || [] : []),
            ...retList
          ];

          this.setState({
            searchList: compList,
            page: resetPage
              ? 1
              : newPage
              ? this.state.page + 1
              : this.state.page
          });
          return compList;
        })
        .catch(err => {
          console.log(err, "Team search failed");
        });
    } else {
      data = api
        .search(searchWord, 10, 1, true, this.props.season.ConsoleId)
        .then(resp =>
          resp.data.users.map(u => ({
            label: getUsername(u),
            value: u.id,
            ...u
          }))
        );
    }
    return data;
  };

  // handleSearchUsers = searchWord => {
  //   let data = [];
  //   data = api.team.list().then(resp => {
  //     return resp.data.docs.map(team => ({
  //       label: team.name,
  //       value: team.id,
  //       ...team
  //     }));
  //   });
  //   return data;
  // };

  renderTeamSelection() {
    const { loading } = this.props;
    const { selectedTeam } = this.state;

    return (
      <Container>
        <Header>
          <HeaderTitle>Add Team</HeaderTitle>
        </Header>
        <Content>
          <SearchBar>
            <FormFieldKit fullWidth gutterBottom={false}>
              <FieldTypeAndSelect
                placeholder={"Search by team name..."}
                onNewPage
                onChange={team => this.selectTeam(team)}
                onSearch={this.handleSearchUsers}
                isTeam
              />
            </FormFieldKit>
          </SearchBar>
          <SectionTitle>Add:</SectionTitle>
          <Profile>
            {!!selectedTeam ? (
              <PlayerListItem rowData={selectedTeam} />
            ) : (
              <PlaceholderText>No Team Selected</PlaceholderText>
            )}
          </Profile>
        </Content>
        <Divider />
        <FooterContent>
          <DialogButtons>
            <ButtonKit
              fullWidth
              preloader={loading}
              disabled={!!!selectedTeam}
              onClick={() => this.setState({ selectionPageIndex: 1 })}
            >
              {"Select Team"}
            </ButtonKit>
            <ButtonKit
              fullWidth
              appearance={"secondary"}
              color={"red"}
              onClick={() => this.close()}
            >
              Cancel
            </ButtonKit>
          </DialogButtons>
        </FooterContent>
      </Container>
    );
  }

  renderCaptainSelection() {
    const { team, season } = this.props;
    const { captain } = this.state;
    const seasonPlayerIds = propValueOr(season, "users", []).map(
      player => player.id
    );

    return (
      <Container>
        <Header>
          <HeaderTitle>Select Team Captain</HeaderTitle>
        </Header>
        <Content>
          <SectionTitle>Select a Captain:</SectionTitle>
          <List>
            {propValueOr(team, "users", []).map(player => {
              return !seasonPlayerIds.includes(player.id) &&
                !player?.canJoin ? (
                <Profile key={player.id}>
                  <PlayerListItem
                    rowData={player}
                    onClick={() => this.selectCaptain(player)}
                    hover
                  >
                    {!!captain && player.id === captain.id ? (
                      <CheckImg src={CheckIcon} alt="check" />
                    ) : null}
                  </PlayerListItem>
                </Profile>
              ) : (
                <GreyedProfile key={player.id}>
                  <PlayerListItem rowData={player}>
                    <ErrorText>
                      {player?.canJoin
                        ? JSON.stringify(player.canJoin)
                        : "ALREADY IN SEASON"}
                    </ErrorText>
                  </PlayerListItem>
                </GreyedProfile>
              );
            })}
          </List>
        </Content>
        <Buttons>
          <ButtonKit
            fullWidth
            appearance="primary"
            onClick={() => this.setState({ selectionPageIndex: 0 })}
          >
            Back
          </ButtonKit>
          <ButtonKit
            fullWidth
            appearance="secondary"
            onClick={() => this.close()}
          >
            Cancel
          </ButtonKit>
          <ButtonKit
            fullWidth
            color={"red"}
            disabled={!!!captain}
            onClick={() => {
              this.setState({ selectionPageIndex: 2 });
            }}
          >
            Next
          </ButtonKit>
        </Buttons>
      </Container>
    );
  }

  renderRosterSelection() {
    const { team, season } = this.props;
    const { captain, rosterSelection, rosterCount } = this.state;
    let roster = [];

    const isMaxSeason = season.maxTeam && season.maxTeam > season.teamSize;

    const seasonPlayerIds = propValueOr(season, "users", []).map(
      player => player.id
    );
    propValueOr(team, "users", []).forEach(player => {
      if (player.id !== captain.id) {
        roster.push(player);
      }
    });
    return (
      <Container>
        <Header>
          <HeaderTitle>Select Roster</HeaderTitle>
        </Header>
        <Content>
          {isMaxSeason ? (
            <SectionTitle>
              Select at least {season.teamSize - 1} and up to{" "}
              {season.maxTeam - 1} players - {rosterCount}/{season.maxTeam - 1}
            </SectionTitle>
          ) : (
            <SectionTitle>
              Select Players for Roster: {rosterCount}/{season.teamSize - 1}
            </SectionTitle>
          )}
          <List>
            {roster.map((player, playerIndex) => {
              return !seasonPlayerIds.includes(player.id) && !player.canJoin ? (
                <Profile key={player.id}>
                  <PlayerListItem
                    rowData={player}
                    hover={!rosterSelection[player.id]}
                    onClick={() => this.AddTeamRoster(player.id)}
                  >
                    {rosterSelection[player.id] ? (
                      <CheckImg src={CheckIcon} alt="check" />
                    ) : null}
                  </PlayerListItem>
                </Profile>
              ) : (
                <GreyedProfile key={player.id}>
                  <PlayerListItem rowData={player}>
                    <ErrorText>
                      {player?.canJoin || "ALREADY IN SEASON"}
                    </ErrorText>
                  </PlayerListItem>
                </GreyedProfile>
              );
            })}
          </List>
        </Content>
        <Buttons>
          <ButtonKit
            fullWidth
            appearance="primary"
            onClick={() =>
              this.setState({
                selectionPageIndex: 1,
                rosterSelection: {},
                rosterCount: 0
              })
            }
          >
            Back
          </ButtonKit>
          <ButtonKit
            fullWidth
            appearance="secondary"
            onClick={() => this.close()}
          >
            Cancel
          </ButtonKit>
          <ButtonKit
            fullWidth
            color={"red"}
            disabled={
              isMaxSeason
                ? rosterCount < season.teamSize - 1 ||
                  rosterCount > season.maxTeam - 1
                : rosterCount !== season.teamSize - 1
            }
            onClick={() => {
              this.setState({ selectionPageIndex: 3 });
            }}
          >
            Next
          </ButtonKit>
        </Buttons>
      </Container>
    );
  }

  renderConfirmationPage() {
    const { season, team } = this.props;
    const { captain, rosterSelection, loading, errors } = this.state;
    const isMaxSeason = season.maxTeam && season.maxTeam > season.teamSize;

    let roster = [];
    let selectCount = 0;
    propValueOr(team, "users", []).forEach(player => {
      if (rosterSelection[player.id] && player.id !== captain.id) {
        roster.push(player);
        selectCount++;
      }
    });

    return (
      <Container>
        <Header>
          <HeaderTitle>{errors ? "Failed" : "Confirm Team"}</HeaderTitle>
        </Header>
        {errors ? (
          <Content style={{ padding: "10px 25px 0 0" }}>
            <Errors>
              {errors.map(err => (
                <li>
                  Failed to add {err.team}: <span>{err.message}</span>
                </li>
              ))}
            </Errors>
          </Content>
        ) : (
          <Content>
            <SectionTitle>Team:</SectionTitle>
            <Profile>
              {!!team ? (
                <PlayerListItem rowData={team} />
              ) : (
                <PlaceholderText>No Team Selected</PlaceholderText>
              )}
            </Profile>
            <SectionTitle>Team Captain:</SectionTitle>
            <Profile>
              {!!captain ? (
                <PlayerListItem rowData={captain} />
              ) : (
                <PlaceholderText>No Captain Selected</PlaceholderText>
              )}
            </Profile>
            <SectionTitle>
              Roster: {selectCount}/
              {isMaxSeason ? season.maxTeam - 1 : season.teamSize - 1}
            </SectionTitle>
            <List>
              {roster.map(player => {
                return (
                  <Profile key={player.id}>
                    <PlayerListItem key={player.id} rowData={player} />
                  </Profile>
                );
              })}
            </List>
          </Content>
        )}
        <Buttons>
          {!errors && (
            <ButtonKit
              fullWidth
              appearance="primary"
              onClick={() => this.setState({ selectionPageIndex: 2 })}
            >
              Back
            </ButtonKit>
          )}
          {!errors && (
            <ButtonKit
              fullWidth
              appearance="secondary"
              onClick={() => this.close()}
            >
              Cancel
            </ButtonKit>
          )}
          <ButtonKit
            fullWidth
            color={errors ? "red" : "green"}
            disabled={
              errors
                ? false
                : isMaxSeason
                ? selectCount > season.maxTeam - 1 ||
                  selectCount < season.teamSize - 1
                : selectCount !== season.teamSize - 1
            }
            onClick={() => {
              errors ? this.close() : this.submitTeam();
            }}
            preloader={loading}
          >
            {errors ? "Okay" : "Confirm"}
          </ButtonKit>
        </Buttons>
      </Container>
    );
  }

  render() {
    const { isDialogOpen } = this.props;
    const { selectionPageIndex } = this.state;

    return (
      <DialogKit
        isOpen={isDialogOpen}
        title={"Add Team"}
        displayButtonClose={false}
        onClose={() => this.close()}
        renderCustom={() => {
          return (
            <Fragment>
              {selectionPageIndex === 0 ? this.renderTeamSelection() : null}
              {selectionPageIndex === 1 ? this.renderCaptainSelection() : null}
              {selectionPageIndex === 2 ? this.renderRosterSelection() : null}
              {selectionPageIndex === 3 ? this.renderConfirmationPage() : null}
            </Fragment>
          );
        }}
      />
    );
  }
}

const mapStateToProps = state => ({
  team: state.teamState.team,
  isFetchingTeam: state.teamState.fetching,
  season: state.leaguesState.season
});

const mapDispatchToProps = {
  fetchTeamAction,
  resetTeamAction,
  fetchSeasonAction: fetchSeason
};

export default connect(mapStateToProps, mapDispatchToProps)(AddTeamDialog);
