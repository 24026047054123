import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Container,
  WelcomeCard,
  CardColumn,
  RCCGraphic,
  WelcomeTitle,
  WelcomeDivider,
  Sections,
  WelcomeSection,
  SubtitleContent,
  WelcomeSectionTitle,
  SectionContent
} from "./styled";
import { withRouter, Redirect } from "react-router-dom";
import withWidth from "../../hoc/withWidth";

// Icons
import RCCDashboardImage from "../../static/images/RCC_dashboard.png";

class RouteDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { width } = this.props;
    const isMobile = width < 650;

    if (this.props.location.pathname === "/") {
      return <Redirect to={"/dashboard"} />;
    }

    return (
      <Container>
        <WelcomeCard>
          {!isMobile && (
            <CardColumn>
              <RCCGraphic src={RCCDashboardImage} />
            </CardColumn>
          )}
          <CardColumn>
            <WelcomeTitle>
              Welcome to the Rival Control Center (RCC)
            </WelcomeTitle>
            <WelcomeDivider />
            <Sections>
              <WelcomeSection>
                <SubtitleContent>
                  RCC allows you to control all aspects of your community:
                </SubtitleContent>
              </WelcomeSection>
              <WelcomeSection>
                <WelcomeSectionTitle>Tournaments</WelcomeSectionTitle>
                <SectionContent>
                  create and operate all your esports events
                </SectionContent>
              </WelcomeSection>
              <WelcomeSection>
                <WelcomeSectionTitle>Reports</WelcomeSectionTitle>
                <SectionContent>real time community analytics</SectionContent>
              </WelcomeSection>
              <WelcomeSection>
                <WelcomeSectionTitle>Users</WelcomeSectionTitle>
                <SectionContent>
                  administrative control and management
                </SectionContent>
              </WelcomeSection>
              <WelcomeSection>
                <WelcomeSectionTitle>Assets</WelcomeSectionTitle>
                <SectionContent>
                  manage in real time your community's graphics, branding, and
                  messaging
                </SectionContent>
              </WelcomeSection>
            </Sections>
          </CardColumn>
        </WelcomeCard>
      </Container>
    );
  }
}

const mapStateToProps = state => ({});
const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withWidth(RouteDashboard)));
