import styled from "styled-components";
import { COLOR, FONT_WEIGHT, FONT_FAMILY } from "../../../constants/theme";

export const Container = styled.div``;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  color: ${COLOR.TEXT};
`;

export const Header = styled.div`
  display: flex;
  align-items: center;

  padding: 10px 0px;

  width: 100%;
`;

export const NavBar = styled.div`
  display: flex;
  align-items: center;

  > {
    margin-right: 10px;
  }
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
`;

export const NavItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  font: ${FONT_WEIGHT.NORMAL} 20px ${FONT_FAMILY.GOTHIC_BOLD};
  user-select: text;
`;

export const WrapArrow = styled.div`
  cursor: pointer;
`;

export const ArrowIcon = styled.img`
  width: 30px;
  height: 30px;
`;

export const WrapButton = styled.div`
  margin: 10px 0;
`;

export const Item = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding: 10px 0px;
  margin: 10px 0px;

  color: ${COLOR.TEXT};
  text-decoration: none;
`;

export const Badge = styled.div`
  display: flex;
  justify-content: center;
  min-width: 85px;
  height: 20px;

  padding: 3px 10px;
  border-radius: 12px 12px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  font: ${FONT_WEIGHT.NORMAL} 15px ${FONT_FAMILY.GOTHIC};
  text-transform: uppercase;

  background-color: ${COLOR.BG_INPUT};
`;

export const Info = styled.div`
  display: flex;
  align-items: center;
`;

export const Text = styled.div`
  display: flex;
  margin-bottom: 10px;
  font: ${FONT_WEIGHT.NORMAL} 15px ${FONT_FAMILY.THIN};
  width: 100%;
`;

export const WrapCoinsCount = styled.div`
  display: flex;
  align-items: center;
`;

export const CoinsIcon = styled.img`
  width: 15px;
  height: 15px;
  margin-left: 3px;
`;

export const CoinsCount = styled.div`
  font: ${FONT_WEIGHT.NORMAL} 17px ${FONT_FAMILY.GOTHIC_BOLD};
  margin-top: 5px;
`;
