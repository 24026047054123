import {
  differenceInMinutes,
  differenceInHours,
  differenceInDays
} from "date-fns";
import { propValueOr } from "../../../../helpers/common";
import { hoursToDaysString } from "../../../../helpers/date";

const getGroupData = (data, filterFn, formatFn, limitCount = null) => {
  const now = new Date();

  if (data) {
    const localData = limitCount
      ? data.filter((item, i) => i < limitCount)
      : data;
    return localData
      .filter(item => filterFn(now, new Date(item.createdAt)))
      .map(item => ({
        id: item.id,
        image: propValueOr(item, "thumbnail", null),
        title: item.title,
        content: item.message,
        dateString: formatFn(now, new Date(item.createdAt)),
        wasNotRead: !item.read
      }));
  }
};

export const getBriefNotifications = data => {
  return {
    new: getGroupData(
      data,
      (now, createDate) => differenceInMinutes(now, createDate) < 90,
      (now, createDate) => `${differenceInMinutes(now, createDate)}m`,
      25
    ),
    earlier: getGroupData(
      data,
      (now, createDate) => differenceInMinutes(now, createDate) >= 90,
      (now, createDate) =>
        hoursToDaysString(differenceInHours(now, createDate)),
      25
    )
  };
};

export const getNotifications = data => {
  return {
    new: getGroupData(
      data,
      (now, createDate) => differenceInMinutes(now, createDate) < 90,
      (now, createDate) => `${differenceInMinutes(now, createDate)}m`
    ),
    today: getGroupData(
      data,
      (now, createDate) =>
        differenceInMinutes(now, createDate) >= 90 &&
        differenceInHours(now, createDate) < 24,
      (now, createDate) => hoursToDaysString(differenceInHours(now, createDate))
    ),
    yesterday: getGroupData(
      data,
      (now, createDate) =>
        differenceInHours(now, createDate) >= 24 &&
        differenceInHours(now, createDate) < 48,
      (now, createDate) => hoursToDaysString(differenceInHours(now, createDate))
    ),
    lastWeek: getGroupData(
      data,
      (now, createDate) =>
        differenceInHours(now, createDate) >= 48 &&
        differenceInDays(now, createDate) < 7,
      (now, createDate) => `${differenceInDays(now, createDate)}d`
    ),
    allTime: getGroupData(
      data,
      (now, createDate) => differenceInDays(now, createDate) >= 7,
      (now, createDate) => `${differenceInDays(now, createDate)}d`
    )
  };
};
