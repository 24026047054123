import styled from "styled-components";
import { COLOR } from "../../../../constants/theme";

export const Container = styled.div``;

export const Content = styled.div`
  display: flex;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  color: ${COLOR.TEXT};
  font-size: 18px;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  color: ${COLOR.TEXT};
  font-size: 14px;
  cursor: pointer;

  width: 225px;
  border-radius: 16px;
  overflow: hidden;
  background-color: ${COLOR.BG_PRIMARY};
  margin-right: 20px;
  :hover {
    background-color: ${COLOR.RIVAL_RED};
    opacity: 0.8;
  }
  transition: all 0.25s ease 0s;
`;

export const WrapImage = styled.div`
  width: 100%;
  height: 230px;
`;

export const Image = styled.img`
  width: 100%;
  height: 220px;
`;

export const Info = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 80px;
  padding: 5px 10px;
`;

export const Heading = styled.div``;

export const Title = styled.div`
  font-weight: 600;
`;

export const Subtitle = styled.div`
  font-weight: 300;
`;

export const Price = styled.div`
  display: flex;
  align-items: center;
`;

export const PriceText = styled.div`
  font-weight: 600;
  margin-left: 5px;
  line-height: 15px;
  padding-top: 2px;
`;

export const PriceIcon = styled.img`
  width: 15px;
  height: 15px;
  background-color: #fff;
  border-radius: 50%;
  padding: 2px;
`;
