import { FONT_FAMILY, FONT_SIZE, FONT_WEIGHT } from "../../../constants/theme";

export const ELEMENT_BY_VARIANT = {
  h1: "h1",
  h2: "h2",
  h3: "h3",
  h4: "h4",
  h5: "h5",
  h6: "h6",
  caption1: "h6",
  caption2: "h6",
  caption3: "h6",
  p1: "p",
  p2: "p",
  label: "span"
};

export const FONTS_BY_VARIANTS = {
  h1: `${FONT_WEIGHT.MEDIUM} ${FONT_SIZE.H1}px / 1.2 ${FONT_FAMILY.THICK}`,
  h2: `${FONT_WEIGHT.MEDIUM} ${FONT_SIZE.H2}px / 1.2 ${FONT_FAMILY.THICK}`,
  h3: `${FONT_WEIGHT.MEDIUM} ${FONT_SIZE.H3}px / 1.2 ${FONT_FAMILY.THICK}`,
  h4: `${FONT_WEIGHT.MEDIUM} ${FONT_SIZE.H4}px / 1.2 ${FONT_FAMILY.THICK}`,
  h5: `${FONT_WEIGHT.BOLD} ${FONT_SIZE.H5}px / 1.2 ${FONT_FAMILY.THIN}`,
  h6: `${FONT_WEIGHT.NORMAL} ${FONT_SIZE.H6}px / 1.2 ${FONT_FAMILY.THIN}`,
  caption1: `${FONT_WEIGHT.BOLD} ${FONT_SIZE.CAPTION_1}px / 1.2 ${FONT_FAMILY.THIN}`,
  caption2: `${FONT_WEIGHT.BOLD} ${FONT_SIZE.CAPTION_2}px / 1.2 ${FONT_FAMILY.THIN}`,
  caption3: `${FONT_WEIGHT.BOLD} ${FONT_SIZE.CAPTION_3}px / 1.2 ${FONT_FAMILY.THIN}`,
  p1: `${FONT_WEIGHT.MEDIUM} ${FONT_SIZE.PARAGRAPH}px / 1.2 ${FONT_FAMILY.THIN}`,
  p2: `${FONT_WEIGHT.MEDIUM} ${FONT_SIZE.PARAGRAPH_SMALL}px / 1.2 ${FONT_FAMILY.THIN}`,
  p3: `${FONT_WEIGHT.NORMAL} ${FONT_SIZE.PARAGRAPH_SMALL}px / 1.2 ${FONT_FAMILY.THIN}`,
  label: `${FONT_WEIGHT.BOLD} ${FONT_SIZE.LABEL}px / 1.2 ${FONT_FAMILY.THIN}`
};

export const LETTER_SPACING_BY_VARIANTS = {
  h1: 0.3,
  h2: 0.3,
  h3: 0.3,
  h4: 0.3,
  h5: 0.3,
  h6: 0.3,
  caption1: null,
  caption2: null,
  caption3: null,
  p1: null,
  p2: null,
  p3: null,
  label: null
};

export const SHOULD_UPPERCASE_BY_VARIANT = {
  h1: true,
  h2: true,
  h3: true,
  h4: true,
  caption1: true,
  caption2: true
};
