import React, { Component } from "react";
import {
  Container,
  Content,
  Item,
  Info,
  List,
  Badge,
  Id,
  WrapIcon
} from "./styled";
import moment from "moment";
import Pagination from "../../../components/presentation/Pagination/Pagination";
import { propValueOr } from "../../../helpers/common";
import withLocationSearch from "../../../hoc/withLocationSearch";
import * as queryString from "query-string";
import { withRouter } from "react-router";
import FormFieldKit from "../../../components/kit/Fields/FormField/FormField";
import IconSearchGrey from "../../../static/icons/icon-search-grey.svg";

class Templates extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1
    };
  }
  componentDidMount() {
    this.fetchTemplates();
    const filter = queryString.parse(this.props.location.search);
    this.setState({ ...filter });
  }

  fetchTemplates = () => {
    const { page = 1 } = this.props.getLocationSearch();
    const organizationId = propValueOr(
      this.props.user,
      "organizationId",
      undefined
    );
    this.props.fetchTemplates({ page: page, organizationId: organizationId });
  };

  handleSearch = searchWord => {
    this.setState({ searchWord: searchWord });
    const organizationId = propValueOr(
      this.props.user,
      "organizationId",
      undefined
    );
    this.props.fetchTemplates({
      q: searchWord,
      organizationId: organizationId
    });
  };

  componentDidUpdate(prevProps) {
    const organizationId = propValueOr(
      this.props.user,
      "organizationId",
      undefined
    );
    const prevOrganizationId = propValueOr(
      prevProps.user,
      "organizationId",
      undefined
    );
    if (
      prevProps.location !== this.props.location ||
      organizationId !== prevOrganizationId
    ) {
      this.fetchTemplates();
    }
  }

  render() {
    const { templates, total } = this.props;
    return (
      <Container>
        <Content>
          <FormFieldKit
            fullWidth
            value={this.state.searchWord}
            placeholder="Search for template by name..."
            onChange={this.handleSearch}
            icon={
              <WrapIcon>
                <img src={IconSearchGrey} alt="search icon" />
              </WrapIcon>
            }
            iconAlign="left"
          />
          <List>
            {(templates || []).map(t => {
              return (
                <Item key={t.id} to={`/tournaments/templates/${t.id}`}>
                  <Info>
                    <Id>{t.id}</Id>
                    <Badge>{propValueOr(t, "name", "")}</Badge>
                  </Info>

                  <Info>
                    <Badge>
                      Auto-create: {propValueOr(t, "autoCreate", "0")}
                    </Badge>
                    <Badge>
                      {moment(t.createdAt).format("M/DD/YY @ h:mm A")}
                    </Badge>
                  </Info>
                </Item>
              );
            })}
            <Pagination withUrl total={total} />
          </List>
        </Content>
      </Container>
    );
  }
}

export default withRouter(withLocationSearch(Templates));
