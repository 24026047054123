import styled, { css } from "styled-components";
import { COLOR, FONT_FAMILY, FONT_WEIGHT } from "../../../constants/theme";
import breakpoints from "../../../helpers/breakpoints";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  color: ${COLOR.TEXT};
  background-color: ${COLOR.BG_PRIMARY};

  padding: 0 20px 20px 20px;
`;

export const Title = styled.div`
  font: ${FONT_WEIGHT.BOLD} 20px ${FONT_FAMILY.THIN};
  margin-bottom: 20px;
  height: 30px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1300px;
`;

export const Tabs = styled.div`
  display: flex;
  width: 100%;
  border-radius: 20px 20px 0 0;
  overflow: hidden;
`;

export const Tab = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  padding: 15px;

  background-color: ${({ active }) =>
    active ? COLOR.BG_SECONDARY : COLOR.BG_PRIMARY};

  cursor: pointer;
  &:hover {
    opacity: 0.4;
  }
`;

export const Text = styled.div`
  font: ${FONT_WEIGHT.NORMAL} 16px ${FONT_FAMILY.THIN};
  color: ${COLOR.TEXT};
  margin-bottom: 30px;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-radius: 0 0 20px 20px;
  padding: 20px;
  background-color: ${COLOR.BG_SECONDARY};
  overflow-y: auto;
`;

// AssetsHub

export const WrapPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;

  padding: 30px 0px;
`;

export const ContentCard = styled.div`
  display: flex;
  justify-content: center;

  width: 100%;

  background-color: ${COLOR.BG_SECONDARY};
  border-radius: 16px;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
`;

export const Config = styled.div`
  padding: 30px;
  color: ${COLOR.TEXT};
  background-color: ${COLOR.BG_SECONDARY};
  width: 100%;
  border-radius: 16px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;

  padding-bottom: 25px;
  margin-bottom: 15px;
  border-bottom: 1px solid ${COLOR.BORDER};

  > :not(:last-child) {
    margin-bottom: 5px;
  }
`;

export const HeaderRow = styled.div`
  display: flex;
`;

export const SubText = styled.div`
  display: flex;
  align-items: center;
  font: ${FONT_WEIGHT.MEDIUM} 12px ${FONT_FAMILY.GOTHIC};
`;

export const WrapInfoIcon = styled.img`
  height: 17px;
  margin-left: 10px;

  cursor: pointer;
  &:hover {
    opacity: 0.4;
  }
`;

export const Subtitle = styled.div`
  font: ${FONT_WEIGHT.MEDIUM} 16px ${FONT_FAMILY.GOTHIC_BOLD};
  padding: 20px 0px;
`;

export const Error = styled.div`
  color: ${COLOR.ROUGE};
  font: ${FONT_WEIGHT.MEDIUM} 14px ${FONT_FAMILY.THIN};
  text-align: center;
  margin: 10px 0;
`;

export const WrapField = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
  max-width: 900px;
`;
export const WrapCheckboxes = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  max-width: 900px;
`;

export const WrapAsset = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 25px 20px 25px 0px;
  padding: 15px;
  flex-direction: ${row => (row ? "column" : "row")};
  background-color: ${COLOR.BG_ACCENT};
  border-radius: 12px;
`;

export const ImageList = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  margin-bottom: 40px;

  background-color: ${COLOR.BG_ACCENT};
  border-radius: 8px;

  overflow-y: auto;
`;

export const ScrollContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  width: 500px;

  padding: 25px;
`;

export const SectionHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;

export const SectionTitle = styled.div`
  font: ${FONT_WEIGHT.NORMAL} 16px ${FONT_FAMILY.GOTHIC_BOLD};
`;

export const SectionSubtitle = styled.div`
  margin-top: 10px;
  font: ${FONT_WEIGHT.NORMAL} 14px ${FONT_FAMILY.GOTHIC};
`;

export const WrapPanel = styled.div`
  width: 100%;
`;

export const WrapPanelList = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  border-top: 1px solid ${COLOR.BORDER};
  border-bottom: 1px solid ${COLOR.BORDER};

  > :not(:last-child) {
    border-bottom: 1px solid ${COLOR.BORDER};
  }
`;

export const SocialMediaContainer = styled.div`
  display: flex;

  padding: 20px;
  background-color: ${COLOR.BG_ACCENT};
  border-radius: 8px;
`;

export const SocialMediaColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
`;

export const SelectOrg = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  padding: 15px;
  font: ${FONT_WEIGHT.NORMAL} 24px ${FONT_FAMILY.GOTHIC_BOLD};
`;

export const Separator = styled.div`
  height: 25px;
  width: 100%;
`;

export const WrapRequirements = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0px 0px 0px;
`;

export const Filter = styled.div`
  color: ${COLOR.TEXT};
  display: inline-flex;
  display: flex;
  align-items: center;
  overflow: hidden;

  border: 3px solid ${COLOR.RIVAL_RED};
  border-radius: 8px;
`;

const ACTIVE_SWITCHER_ITEM_STYLES = css`
  background-color: ${COLOR.RIVAL_RED};
  color: ${COLOR.WHITE};
`;

const NOT_ACTIVE_SWITCHER_ITEM_STYLES = css`
  background-color: ${COLOR.BG_SECONDARY};
  color: ${COLOR.WHITE};
`;

export const FilterItem = styled.div`
  width: 100%;
  min-width: 160px;
  padding: 10px;
  flex-grow: 1;

  font: ${FONT_WEIGHT.MEDIUM} 14px / 1.2 ${FONT_FAMILY.GOTHIC_BOLD};
  text-align: center;
  text-transform: uppercase;

  cursor: pointer;
  transition: all 0.3s;

  ${_IS_ACTIVE_SWITCHER_ITEM_};

  &:hover {
    ${ACTIVE_SWITCHER_ITEM_STYLES};
  }

  ${breakpoints.down("md")} {
    padding-top: 11px;
    padding-bottom: 11px;
    width: auto;
  }

  ${breakpoints.down("xs")} {
    display: flex;
    min-width: 100px;
    align-items: center;
    justify-content: center;
    padding-left: 4px;
    padding-right: 4px;
  }

  ${breakpoints.down("440")} {
    min-width: 0;
    font-size: 12px;
  }
`;

function _IS_ACTIVE_SWITCHER_ITEM_({ isActive }) {
  return isActive
    ? ACTIVE_SWITCHER_ITEM_STYLES
    : NOT_ACTIVE_SWITCHER_ITEM_STYLES;
}

export const FilterBar = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
  padding-bottom: 20px;

  background-color: ${COLOR.BG_SECONDARY};
  overflow: hidden;
`;
