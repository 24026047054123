import React, { Component } from "react";
import PropTypes from "prop-types";
import EmailForm from "../EmailForm/EmailForm";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { ContentBody, ContentHead } from "../styled";
import TypographyKit from "../../../components/kit/Typography/TypographyKit";
import Breadcrumbs from "../../../components/presentation/Breadcrumbs/Breadcrumbs";
import {
  addEmail,
  createScheduledEmail,
  deleteEmail,
  updateTemplatesEmail,
  updateGlobalTemplatesEmail
} from "../../../store/communication/actions";
import EmailDialogSuccess from "../../../components/dialogs/EmailDialog/EmailDialogSuccess";
import { safePropValueOr } from "../../../helpers/common";
import EmailDialog from "../../../components/dialogs/EmailDialog/EmailDialog";
import FormFieldKit from "../../../components/kit/Fields/FormField/FormField";
import { isEmailValid } from "../../../helpers/fields-validations";
import { dateToSting } from "../../../helpers/date";
import PermissionDenied from "../../denied/PermissionDenied";
import { verifyPermission } from "../../../helpers/common";

class CommunicationTemplatesEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      template: null,
      email: null,
      recipientEmail: "",
      isEmpty: false,
      isUpdatingTemplate: false,
      isUpdateTemplateDialogOpen: false,
      isScheduledDialogOpen: false,
      isSendTestDialogOpen: false,
      isScheduling: false,
      errors: null
    };
  }

  componentDidMount() {
    const { match, history } = this.props;
    const templates = this.props.isGlobalTemplates
      ? this.props.globalTemplates
      : this.props.templates;

    if (!templates || templates.length === 0) {
      if (this.props.isGlobalTemplates) {
        history.push("/communication/global_templates");
      } else {
        history.push("/communication/templates");
      }
    }

    if (match.params.id) {
      this.setState({
        template: templates.filter(e => "" + e.id === match.params.id)[0]
      });
    }
  }

  setFiledErrors = (propName, value = null) => {
    const { errors } = this.state;

    this.setState({
      errors: {
        ...errors,
        [propName]: value
      }
    });
  };

  renderUpdatedDialog = () => {
    const { isUpdateTemplateDialogOpen } = this.state;
    return (
      <EmailDialogSuccess
        title={"TEMPLATE UPDATED"}
        text={"Your template has been updated"}
        confirmText={"Ok"}
        isOpen={isUpdateTemplateDialogOpen}
        onClose={() => this.setState({ isUpdateTemplateDialogOpen: false })}
        onConfirm={() => this.setState({ isUpdateTemplateDialogOpen: false })}
      />
    );
  };

  handleUpdateTemplate = async template => {
    try {
      if (this.props.isGlobalTemplates) {
        await this.props.updateGlobalTemplatesEmail(template);
      } else {
        await this.props.updateTemplatesEmail(template);
      }
      this.setState({
        isUpdatingTemplate: false,
        isUpdateTemplateDialogOpen: true
      });
    } catch (err) {
      this.setState({ isUpdatingTemplate: false });
    }
  };

  handleSendTestEmail = email => {
    this.setState({
      isSendTestDialogOpen: true,
      email
    });
  };

  onConfirmSendTestDialog = async () => {
    const { email, recipientEmail } = this.state;

    if (!isEmailValid(recipientEmail)) {
      this.setFiledErrors(
        "recipientEmail",
        "Please enter valid email address."
      );
    } else {
      this.setFiledErrors("recipientEmail", null);
      this.setState({ isScheduling: true });

      try {
        await this.props.createScheduledEmail({
          ...email,
          sendAt: dateToSting(Date.now(), "YYYY-MM-DD HH:mm:ssZZ"),
          to: JSON.stringify([
            {
              label: recipientEmail,
              name: recipientEmail,
              type: "email",
              value: recipientEmail
            }
          ])
        });
        this.setState({
          isScheduling: false,
          isSendTestDialogOpen: false,
          isScheduledDialogOpen: true
        });
      } catch (err) {
        this.setState({ isScheduling: false });
      }
    }
  };

  renderSendTestDialog = () => {
    const {
      isSendTestDialogOpen,
      isScheduling,
      recipientEmail,
      errors
    } = this.state;

    return (
      <EmailDialog
        title={"SEND EMAIL"}
        text={`Please enter recipient email`}
        isOpen={isSendTestDialogOpen}
        onClose={() => this.setState({ isSendTestDialogOpen: false })}
        onConfirm={() => null}
        actions={[
          {
            label: "Send",
            method: this.onConfirmSendTestDialog
          },
          {
            label: "Cancel",
            color: "red",
            method: () => this.setState({ isSendTestDialogOpen: false })
          }
        ]}
        confirmPreloader={isScheduling}
      >
        <FormFieldKit
          fullWidth
          placeholder={"Enter recipient email"}
          value={recipientEmail}
          onChange={val => this.setState({ recipientEmail: val })}
          gutterBottom={0}
          required
          errorText={safePropValueOr(errors, "recipientEmail", null)}
        />
      </EmailDialog>
    );
  };

  renderScheduledDialog = () => {
    const { isScheduledDialogOpen } = this.state;
    const { history } = this.props;

    return (
      <EmailDialogSuccess
        title={"EMAIL SCHEDULED"}
        text={"This email will be sent:"}
        date={new Date().getTime()}
        isOpen={isScheduledDialogOpen}
        onClose={() => this.setState({ isScheduledDialogOpen: false })}
        onConfirm={() => history.push("/communication/scheduled")}
        confirmText={"SEE SCHEDULED"}
      />
    );
  };

  render() {
    const { template, isEmpty, isUpdatingTemplate } = this.state;
    const { handleSetImage, addEmail, deleteEmail, location } = this.props;
    const preview = safePropValueOr(location, "state.preview", false);

    const globalTemplateAccessible = verifyPermission(
      "/communication/global_templates",
      this.props.permissions
    );
    if (!globalTemplateAccessible && this.props.isGlobalTemplates) {
      return <PermissionDenied />;
    }

    return (
      <>
        <ContentHead>
          <TypographyKit variant={"caption1"}>Templates</TypographyKit>
        </ContentHead>
        <ContentBody>
          <Breadcrumbs lastItemValue={preview ? "Preview" : "Edit"} />
          <EmailForm
            type={"template"}
            preview={preview}
            email={template}
            handleSetImage={handleSetImage}
            isEmpty={isEmpty}
            handleUpdateTemplate={this.handleUpdateTemplate}
            handleSendTestEmail={this.handleSendTestEmail}
            isUpdating={isUpdatingTemplate}
            addEmail={addEmail}
            deleteEmail={deleteEmail}
          />
          {this.renderUpdatedDialog()}
          {this.renderSendTestDialog()}
          {this.renderScheduledDialog()}
        </ContentBody>
      </>
    );
  }
}

CommunicationTemplatesEdit.propTypes = {
  templates: PropTypes.arrayOf(PropTypes.object),
  updateTemplatesEmail: PropTypes.func.isRequired,
  addEmail: PropTypes.func.isRequired,
  deleteEmail: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  templates: state.communication.templates,
  globalTemplates: state.communication.globalTemplates
});

const mapDispatchToProps = {
  updateTemplatesEmail,
  updateGlobalTemplatesEmail,
  createScheduledEmail,
  addEmail,
  deleteEmail
};

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(CommunicationTemplatesEdit);
