export const USER_ACTIONS = {
  login: {
    success: "USER/LOGIN_SUCCESS",
    error: "USER/LOGIN_ERROR",
    setAuthorizedUserInfo: "USER/SET_AUTHORIZED_USER_INFO",
    setUserAsNotAuthorized: "USER/SET_USER_AS_NOT_AUTHORIZED"
  },
  update: {
    success: "USER/UPDATE_SUCCESS",
    error: "USER/UPDATE_ERROR",
    updateAuthorizedUserInfo: "USER/UPDATE_AUTHORIZED_USER_INFO"
  },
  request: {
    status: "USER/REQUEST_STATUS"
  }
};
