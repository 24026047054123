import styled from "styled-components";
import { COLOR, FONT_WEIGHT, FONT_FAMILY } from "../../../../constants/theme";
import breakpoints from "../../../../helpers/breakpoints";
import { Link } from "react-router-dom";

export const Item = styled(Link)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding: 10px 0px;
  height: 50px;

  color: ${COLOR.TEXT};
  text-decoration: none;

  &:hover {
    opacity: 0.4;
  }
`;

export const Badge = styled.div`
  display: flex;
  justify-content: center;
  min-width: ${({ width }) => (!!width ? `${width}px` : "100px")};

  padding: 3px 10px;
  margin-right: 10px;
  border-radius: 12px 12px;

  overflow: hidden;
  text-wrap: wrap;
  text-overflow: ellipsis;
  white-space: nowrap;

  font: ${FONT_WEIGHT.NORMAL} 13px ${FONT_FAMILY.GOTHIC};
  text-transform: uppercase;

  background-color: ${COLOR.BG_INPUT};
`;

export const Status = styled.div`
  display: flex;
  justify-content: center;
  min-width: 85px;
  height: 20px;

  padding: 3px 10px;
  margin: 0px 10px;
  border-radius: 12px 12px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  font: ${FONT_WEIGHT.NORMAL} 13px ${FONT_FAMILY.GOTHIC};
  text-transform: uppercase;

  background-color: ${({ status }) => {
    switch (status) {
      case "open":
        return COLOR.GREEN;
      case "pending":
        return COLOR.YELLOW;
      case "closed":
        return COLOR.GREY;
      default:
        return COLOR.BG_INPUT;
    }
  }};

  ${breakpoints.down("sm")} {
    display: none;
  }
`;

export const Info = styled.div`
  display: flex;
  align-items: center;
`;

export const WrapCoinsCount = styled.div`
  display: flex;
  align-items: center;
`;

export const CoinsIcon = styled.img`
  width: 15px;
  height: 15px;
  margin-left: 3px;
`;

export const CoinsCount = styled.div`
  font: ${FONT_WEIGHT.NORMAL} 15px ${FONT_FAMILY.GOTHIC_BOLD};
  margin-top: 5px;
`;
