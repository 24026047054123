import { CHAT_ACTIONS } from "./constants";

const initialState = {
  chats: []
};

const ChatReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHAT_ACTIONS.add:
      const chats = [...state.chats];
      if (
        chats.length < 4 &&
        !chats.some(chat => chat.channel === action.payload.channel)
      ) {
        chats.push({
          openWindow: true,
          ...action.payload
        });
      }

      return {
        ...state,
        chats: chats
      };
    case CHAT_ACTIONS.update:
      return {
        ...state,
        chats: state.chats.map(chat =>
          chat.channel === action.payload.channel ? action.payload : chat
        )
      };
    case CHAT_ACTIONS.delete:
      return {
        ...state,
        chats: state.chats.filter(chat => chat.channel !== action.payload)
      };
    case CHAT_ACTIONS.reset:
      return initialState;
    default:
      return { ...state };
  }
};

export default ChatReducer;
