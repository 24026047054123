import styled from "styled-components";
import { COLOR, FONT_FAMILY, FONT_WEIGHT } from "../../../constants/theme";
import breakpoints from "../../../helpers/breakpoints";

export const Container = styled.div`
  position: relative;
  max-width: 250px;
  ${breakpoints.down("sm")} {
    width: 200px;
  }
`;

export const MenuSelector = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 235px;
  padding: 4px;
  height: 36px;
  border-radius: 5px;

  background-color: ${COLOR.RIVAL_RED};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);

  cursor: pointer;
  &:hover {
    opacity: 0.4;
  }

  overflow-x: hidden;

  ${breakpoints.down("sm")} {
    width: 185px;
  }
`;

export const OrgPic = styled.img`
  max-height: 24px;
  max-width: 30px;
`;

export const OrgName = styled.div`
  font: ${FONT_WEIGHT.BOLD} 13px / 1.2 ${FONT_FAMILY.GOTHIC};
  padding-top: 1px;
  padding-left: 4px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 180px;

  ${breakpoints.down("sm")} {
    width: 130px;
  }
`;

export const WrapChevronIcon = styled.div`
  display: flex;
`;

export const ChevronIcon = styled.img`
  width: 15px;
  height: 15px;
`;

export const WrapOrgMenu = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;

  top: 100%;
  right: 0px;
  width: 225px;
  margin-top: 14px;
`;

export const WrapItem = styled.div`
  display: flex;
  align-items: center;
`;

export const OrgMenuItem = styled.div`
  display: flex;
  align-items: center;

  font: ${FONT_WEIGHT.BOLD} 13px / 1.2 ${FONT_FAMILY.GOTHIC};

  padding: 16px 16px 14px;

  ${({ isActive }) =>
    isActive
      ? `border-left: 4px solid ${COLOR.RIVAL_RED};`
      : `cursor: pointer;
        &:hover {
            opacity: 0.4;
        };`}
`;

export const OrgList = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  background-color: ${COLOR.BG_SECONDARY};
  border-top: 1px solid ${COLOR.BORDER};
  border-bottom: 1px solid ${COLOR.BORDER};

  overflow-y: auto;
  > :not(:last-child) {
    border-bottom: 1px solid ${COLOR.BORDER};
  }
`;

export const OrgMenu = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  max-height: 200px;
  background-color: ${COLOR.BG_ACCENT};

  padding: 10px 0px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;

  /* box-shadow: -2px 0px 5px black; */
`;
