import React from "react";
import PropTypes from "prop-types";
import DialogKit from "../../kit/Dialog/DialogKit";
import {
  EmailDialogSuccessActionsItemSD,
  EmailDialogSuccessActionsSD,
  EmailDialogSuccessBodySD,
  EmailDialogSuccessFooterSD,
  EmailDialogSuccessHeaderSD,
  EmailDialogSuccessTextSD,
  EmailDialogSuccessTitleSD
} from "./styled";

import ButtonKit from "../../kit/Button/ButtonKit";

const EmailDialog = ({
  isOpen,
  onClose,
  title,
  text,
  onConfirm,
  confirmPreloader,
  actions,
  ...props
}) => {
  const renderHeader = () => (
    <EmailDialogSuccessHeaderSD>
      {title && <EmailDialogSuccessTitleSD>{title}</EmailDialogSuccessTitleSD>}
    </EmailDialogSuccessHeaderSD>
  );

  const renderBody = () => (
    <EmailDialogSuccessBodySD>
      {text && <EmailDialogSuccessTextSD>{text}</EmailDialogSuccessTextSD>}
      {props.children}
    </EmailDialogSuccessBodySD>
  );

  const renderFooter = () => (
    <EmailDialogSuccessFooterSD>
      <EmailDialogSuccessActionsSD>
        {actions && actions.length > 0 ? (
          actions.map(action => {
            return (
              <EmailDialogSuccessActionsItemSD key={action.label}>
                <ButtonKit
                  color={action.color || "primary"}
                  appearance={"primary"}
                  fullWidth
                  onClick={action.method}
                  disabled={action.disabled}
                >
                  {action.label}
                </ButtonKit>
              </EmailDialogSuccessActionsItemSD>
            );
          })
        ) : (
          <>
            <EmailDialogSuccessActionsItemSD>
              <ButtonKit
                color={"red"}
                appearance={"primary"}
                fullWidth
                onClick={onConfirm}
                preloader={confirmPreloader}
              >
                YES
              </ButtonKit>
            </EmailDialogSuccessActionsItemSD>
            <EmailDialogSuccessActionsItemSD>
              <ButtonKit
                color={"red"}
                appearance={"secondary"}
                fullWidth
                onClick={onClose}
              >
                NO
              </ButtonKit>
            </EmailDialogSuccessActionsItemSD>
          </>
        )}
      </EmailDialogSuccessActionsSD>
    </EmailDialogSuccessFooterSD>
  );

  return (
    <DialogKit
      isOpen={isOpen}
      onClose={onClose}
      renderHeader={renderHeader}
      renderBody={renderBody}
      renderFooter={renderFooter}
      displayButtonClose={false}
      {...props}
    />
  );
};

EmailDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.node,
  text: PropTypes.node
};

EmailDialog.defaultProps = {
  title: "",
  text: "",
  confirmPreloader: false
};

export default EmailDialog;
