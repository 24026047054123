import React, { Component } from "react";
import { connect } from "react-redux";
import {
  WrapPage,
  Content,
  Header,
  HeaderRow,
  Title,
  WrapRequirements,
  WrapInfoIcon,
  SubText,
  SectionHeader,
  SectionTitle,
  SectionSubtitle,
  WrapAsset,
  ImageList,
  ScrollContainer,
  SocialMediaContainer,
  SocialMediaColumn
} from "./styled";
import {
  ImageTitle,
  ConstraintList,
  ConstraintItem
} from "../../assets/Asset/styled";
import InfoDialog from "../../assets/InfoDialog/InfoDialog";
import { withRouter } from "react-router-dom";
import Asset from "../../assets/Asset/Asset";
import AssetPanels from "../../assets/AssetPanels/AssetPanels";
import AssetScroll from "../../assets/AssetScroll/AssetScroll";
import { COLOR } from "../../../constants/theme";

// Images & Icons
import InfoIcon from "../../../static/icons/info-icon.svg";
import HubPDF1 from "../../../static/images/infoPDFs/dashboard-info.pdf";
import { fetchOrganizationsAction } from "../../../store/organizations/actions";

class AssetsHub extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isInfoDialogOpen: false,
      icon: null,
      defaultImage: props?.organization?.imageInfo?.icon
    };
  }

  componentDidUpdate(prevProps) {
    const icon = this.props.organization?.imageInfo?.icon;
    if (prevProps.organization?.imageInfo?.icon !== icon && !!icon) {
      this.setState({
        defaultImage: icon
      });
    }
  }

  renderInfoDialog() {
    return (
      <InfoDialog
        isOpen={this.state.isInfoDialogOpen}
        onClose={() => this.setState({ isInfoDialogOpen: false })}
        title={"Home Image Info"}
        images={[HubPDF1]}
      />
    );
  }

  render() {
    const { organizationGroupId, groupName } = this.props;

    return (
      <WrapPage>
        <Content>
          <Header>
            <HeaderRow>
              <Title>{`Hub Assets: ${groupName}`}</Title>
              <WrapInfoIcon
                src={InfoIcon}
                onClick={() => this.setState({ isInfoDialogOpen: true })}
              />
            </HeaderRow>
            <SubText>All images will be compressed down to 400KB</SubText>
          </Header>
        </Content>
        <Content>
          <WrapAsset>
            <Asset
              label={"Hub Banner"}
              organizationGroupId={organizationGroupId}
              name="hub-banner"
              image
              imageDimensions={[720, 110]}
              imgLabels={["Recommended minimum height of 220px"]}
              aspectRatioLimits={[{ value: 5.75 }, { value: 7.05 }]}
            />
          </WrapAsset>
        </Content>
        <br />
        <>
          <AssetPanels
            title={"Hub Slides"}
            seriesNum={4}
            asset={{
              name: "hub-slider",
              organizationGroupId: organizationGroupId,
              panelTitle: "Slide",
              label: "Home Slider",
              title: true,
              description: true,
              link: { notRequired: true },
              image: true,
              video: { notRequired: true },
              imageDimensions: [512, 288],
              imgLabels: [
                "Recommended minimum width of 1024px",
                "Recommended minimum height of 576px"
              ],
              aspectRatioLimits: [{ value: 1.7 }, { value: 1.85 }],
              titleLimit: 48,
              descriptionLimit: 140
            }}
          />
          <br />
          <br />
          <Content>
            <AssetScroll
              title={"Big Partner Logos"}
              seriesNum={4}
              asset={{
                label: "LOGO",
                name: "hub-big-presented",
                organizationGroupId: organizationGroupId,
                centerItems: true,
                link: { notRequired: true },
                image: true,
                heightLimits: [null, 260],
                displayConstraints: false
              }}
              imageSpecs={["Height must be less than 260px"]}
            />
            <AssetScroll
              title={"Partner Logos"}
              seriesNum={16}
              asset={{
                label: "LOGO",
                name: "hub-presented",
                organizationGroupId: organizationGroupId,
                centerItems: true,
                link: { notRequired: true },
                image: true,
                heightLimits: [null, 150],
                displayConstraints: false
              }}
              imageSpecs={["Height must be less than 150px"]}
            />
            <br />
            <br />
            <SectionHeader>
              <SectionTitle>Hub Ads</SectionTitle>
              <WrapRequirements>
                <ImageTitle>Image Requirements:</ImageTitle>
                <ConstraintList>
                  <ConstraintItem isValid={null}>
                    Recommended minimum width of 672px
                  </ConstraintItem>
                  <ConstraintItem isValid={null}>
                    Recommended minimum height of 560px
                  </ConstraintItem>
                  <ConstraintItem isValid={null}>
                    Required width : height ratio of 1.2
                  </ConstraintItem>
                </ConstraintList>
              </WrapRequirements>
            </SectionHeader>
            <ImageList>
              <ScrollContainer style={{ width: "100%" }}>
                <Asset
                  name="hub-ad-1"
                  label={"Ad 1"}
                  organizationGroupId={organizationGroupId}
                  link={{ notRequired: true }}
                  image
                  centerItems
                  imageDimensions={[336, 280]}
                  aspectRatioLimits={[{ value: 1.15 }, { value: 1.25 }]}
                  inputStyles={{ backgroundColor: `${COLOR.BG_SECONDARY}` }}
                  displayConstraints={false}
                />
                <Asset
                  name="hub-ad-2"
                  label={"Ad 2"}
                  organizationGroupId={organizationGroupId}
                  link={{ notRequired: true }}
                  image
                  centerItems
                  imageDimensions={[336, 280]}
                  aspectRatioLimits={[{ value: 1.15 }, { value: 1.25 }]}
                  inputStyles={{ backgroundColor: `${COLOR.BG_SECONDARY}` }}
                  displayConstraints={false}
                />
                <Asset
                  name="hub-asideAd-1"
                  label={"Ad 3"}
                  organizationGroupId={organizationGroupId}
                  link={{ notRequired: true }}
                  image
                  centerItems
                  imageDimensions={[336, 280]}
                  aspectRatioLimits={[{ value: 1.15 }, { value: 1.25 }]}
                  inputStyles={{ backgroundColor: `${COLOR.BG_SECONDARY}` }}
                  displayConstraints={false}
                />
              </ScrollContainer>
            </ImageList>
            <WrapAsset>
              <Asset
                label={"Hub Header Image"}
                name="hub-hero"
                organizationGroupId={organizationGroupId}
                title
                description
                image
                imageDimensions={[960, 400]}
                imgLabels={[
                  "Recommended minimum width of 1440px",
                  "Recommended minimum height of 600px"
                ]}
                aspectRatioLimits={[{ value: 2.2 }, { value: 2.6 }]}
                inputStyles={{ backgroundColor: `${COLOR.BG_SECONDARY}` }}
              />
            </WrapAsset>
            <WrapAsset>
              <Asset
                label={"Hub Header Image (Mobile)"}
                name="hub-banner-mobile"
                organizationGroupId={organizationGroupId}
                image
                imageDimensions={[330, 400]}
                imgLabels={[
                  "Recommended minimum width of 760px",
                  "Recommended minimum height of 920px"
                ]}
                aspectRatioLimits={[{ value: 0.75 }, { value: 0.9 }]}
                inputStyles={{ backgroundColor: `${COLOR.BG_SECONDARY}` }}
              />
            </WrapAsset>
            {/* {<WrapAsset>
              <Asset 
                name="store"
                organizationGroupId={organizationGroupId}
                link 
              />
            </WrapAsset>
            <WrapAsset>
              <Asset
                name="hub-store"
                organizationGroupId={organizationGroupId}
                image
                imageDimensions={[640, 360]}
                imgLabels={[
                  "Recommended minimum width of 640px",
                  "Recommended minimum height of 360px"
                ]}
                aspectRatioLimits={[{ value: 1.7 }, { value: 1.85 }]}
                inputStyles={{ backgroundColor: `${COLOR.BG_SECONDARY}` }}
              />
            </WrapAsset>} */}
            <SectionHeader>
              <SectionTitle>Social Media</SectionTitle>
              <SectionSubtitle>
                Enter links to your social media
              </SectionSubtitle>
            </SectionHeader>
            <SocialMediaContainer>
              <SocialMediaColumn>
                <Asset
                  label={"Twitter"}
                  name="hub-social-twitter"
                  organizationGroupId={organizationGroupId}
                  link
                  inputStyles={{ backgroundColor: `${COLOR.BG_SECONDARY}` }}
                />
                <Asset
                  label={"Youtube"}
                  name="hub-social-youtube"
                  organizationGroupId={organizationGroupId}
                  link
                  inputStyles={{ backgroundColor: `${COLOR.BG_SECONDARY}` }}
                />
              </SocialMediaColumn>
              <SocialMediaColumn>
                <Asset
                  label={"Facebook"}
                  name="hub-social-facebook"
                  organizationGroupId={organizationGroupId}
                  link
                  inputStyles={{ backgroundColor: `${COLOR.BG_SECONDARY}` }}
                />
                <Asset
                  label={"Twitch"}
                  name="hub-social-twitch"
                  organizationGroupId={organizationGroupId}
                  link
                  inputStyles={{ backgroundColor: `${COLOR.BG_SECONDARY}` }}
                />
              </SocialMediaColumn>
              <SocialMediaColumn>
                <Asset
                  label={"Instagram"}
                  name="hub-social-instagram"
                  organizationGroupId={organizationGroupId}
                  link
                  inputStyles={{ backgroundColor: `${COLOR.BG_SECONDARY}` }}
                />
                <Asset
                  label={"TikTok"}
                  name="hub-social-tiktok"
                  link
                  inputStyles={{ backgroundColor: `${COLOR.BG_SECONDARY}` }}
                />
              </SocialMediaColumn>
            </SocialMediaContainer>
            {/* {this.renderInfoDialog()} */}
          </Content>
        </>
      </WrapPage>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  organization: (state.organizationsState?.organizations || []).find(
    o => o.id === state.user?.info?.organizationId
  )
});
const mapDispatchToProps = {
  fetchOrganizationsAction
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AssetsHub));
