const ORG_GAMES_ACTIONS = {
  list: {
    request: "ORG_GAMES/LIST_REQUEST",
    success: "ORG_GAMES/LIST_SUCCESS",
    error: "ORG_GAMES/LIST_ERROR"
  },
  reset: "ORG_GAMES/RESET_STATE"
};

export default ORG_GAMES_ACTIONS;
