import styled from "styled-components";
import { COLOR, FONT_WEIGHT, FONT_FAMILY } from "../../../constants/theme";
import { Link } from "react-router-dom";
import { chatBarItemWidth } from "./constants";

export const Bar = styled.div`
  display: ${({ isActive }) => (isActive ? "flex" : "none")};
  align-items: center;
  justify-content: flex-end;
  flex-wrap: no-wrap;

  width: 100%;
  height: 50px;

  padding: 0px 10px;
`;

export const ChatBarItem = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 10px;

  width: ${`${chatBarItemWidth}px`};
  height: 100%;
`;

export const Container = styled.div`
  position: absolute;
  bottom: 0;

  display: flex;
  flex-direction: column;
  z-index: 100000;

  width: ${`${chatBarItemWidth}px`};

  color: ${COLOR.TEXT};
  border-radius: 8px 8px 0px 0px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;

  background-color: ${COLOR.BG_INPUT};
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-top: 1px solid ${COLOR.BORDER};
  border-bottom: 1px solid ${COLOR.BORDER};
  border-right: 1px solid ${COLOR.BORDER};

  width: ${`${chatBarItemWidth}px`};
  height: 50px;
`;

export const HeaderInfo = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
  height: 100%;

  padding: 10px;
`;

export const Profile = styled.div`
  display: flex;
  align-items: center;

  > :not(:last-child) {
    margin-right: 10px;
  }
`;

export const ProfilePic = styled.img`
  width: 32px;
  height: 32px;
`;

export const ProfileName = styled.div`
  font: ${FONT_WEIGHT.NORMAL} 16px ${FONT_FAMILY.GOTHIC};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const HeaderButtons = styled.div`
  display: flex;
  align-items: center;

  margin-left: auto;
`;

export const CollapseButton = styled.img`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 12px;

  margin-right: 10px;
  cursor: pointer;
  &:hover {
    opacity: 0.4;
  }
`;

export const BarIcon = styled.div`
  width: 100%;
  height: 2px;
  background-color: ${COLOR.WHITE};
`;

export const CloseButton = styled.img`
  width: 14px;
  cursor: pointer;
  &:hover {
    opacity: 0.4;
  }
`;

export const ChatContent = styled.div`
  display: flex;
  flex-direction: column;

  background-color: ${COLOR.BG_SECONDARY};
  border-left: 1px solid ${COLOR.BORDER};
  border-right: 1px solid ${COLOR.BORDER};
  border-bottom: 1px solid ${COLOR.BORDER};

  width: 100%;
  ${({ isOpen }) =>
    isOpen
      ? "height: 350px;"
      : `height: 0px;
    display:none;`};
`;

export const Info = styled.div`
  display: flex;
  align-items: center;
  flex: 2;
`;

export const Players = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: space-evenly;
`;

export const Player = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
  max-width: 120px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const Name = styled.div`
  font: ${FONT_WEIGHT.NORMAL} 13px ${FONT_FAMILY.GOTHIC};
`;

export const WrapIcons = styled(Link)``;

export const Avatar = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 2px;
  padding: 1px;
  background-color: ${COLOR.FG_PRIMARY};
  margin: 0 5px;
  user-select: none;
`;

export const Icon = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 2px;
  margin: 0 5px;
  user-select: none;
`;
