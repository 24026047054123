import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import * as queryString from "query-string";

const deleteEmptyProps = obj => {
  const clone = { ...obj };

  Object.keys(obj).forEach(propName => {
    if (obj[propName] === "") {
      delete clone[propName];
    }
  });

  return clone;
};

export default function withLocationSearch(EnhancementComponent) {
  class WithLocationSearch extends Component {
    static propTypes = {
      location: PropTypes.shape({
        search: PropTypes.string
      }).isRequired,
      history: PropTypes.shape({
        push: PropTypes.func
      }).isRequired
    };

    getLocationSearch = () => {
      const { location } = this.props;

      if (!location || location.search == null) {
        console.error("Location with search property doesn't provided");
        return {};
      }

      return queryString.parse(location.search);
    };

    setLocationSearch = params => {
      const { history } = this.props;

      const mergedParams = {
        ...this.getLocationSearch(),
        ...params
      };

      if (!history || typeof history.push !== "function") {
        console.error("History with push method doesn't provided");
        return;
      }

      if (typeof params !== "object") {
        console.error("the 'params' property should be an object");
        return;
      }

      history.push({
        search: queryString.stringify(deleteEmptyProps(mergedParams))
      });
    };

    render() {
      return (
        <EnhancementComponent
          {...this.props}
          getLocationSearch={this.getLocationSearch}
          setLocationSearch={this.setLocationSearch}
        />
      );
    }
  }

  return withRouter(WithLocationSearch);
}
