import styled from "styled-components";
import PropTypes from "prop-types";
import { addUnit } from "../../../helpers/common";
import breakpoints from "../../../helpers/breakpoints";

function getWidthInPercent(size) {
  return `${Math.round((size / 12) * 10e7) / 10e5}%`;
}

const Grid = styled.div(props => {
  const {
    container,
    item,
    justify,
    alignItems,
    spacing,
    offset,
    xl,
    gutterBottom
  } = props;

  const styles = {
    boxSizing: "border-box" // if grid uses without base styles
  };

  if (container) {
    styles.width = "100%";
    styles.display = "flex";
    styles.flexWrap = "wrap";

    if (justify) {
      styles.justifyContent = justify;
    }

    if (alignItems) {
      styles.alignItems = alignItems;
    }

    if (spacing) {
      styles.width = `calc(100% + ${spacing * 2}px)`;
      styles.marginTop = -spacing;
      styles.marginRight = -spacing;
      styles.marginBottom = -spacing;
      styles.marginLeft = -spacing;
      styles[`& > ${Grid}`] = {
        padding: spacing
      };
    }
  }

  if (gutterBottom) {
    styles.marginBottom = addUnit(gutterBottom);
  }

  if (item) {
    styles.flexGrow = 0;

    if (offset) {
      styles.marginLeft =
        offset === "full" ? "auto" : getWidthInPercent(offset);
    }

    if (xl) {
      if (xl === true) {
        styles.flexBasis = 0;
        styles.flexGrow = 1;
        styles.maxWidth = "100%";
      } else if (xl === "auto") {
        styles.flexBasis = "auto";
        styles.maxWidth = "none";
      } else {
        const width = getWidthInPercent(xl);
        styles.flexBasis = width;
        styles.maxWidth = width;
      }
    }

    const otherBreakpoints = ["lg", "md", "sm", "xs"];

    otherBreakpoints.forEach(breakpoint => {
      const curBreakpointValue = props[breakpoint];

      if (!curBreakpointValue) {
        return;
      }

      styles[breakpoints.down(breakpoint)] = {
        flexBasis:
          curBreakpointValue === true
            ? 0
            : curBreakpointValue === "auto"
            ? "auto"
            : getWidthInPercent(curBreakpointValue),
        flexGrow: curBreakpointValue === true ? 1 : 0,
        maxWidth:
          curBreakpointValue === true
            ? "100%"
            : curBreakpointValue === "auto"
            ? "none"
            : getWidthInPercent(curBreakpointValue)
      };
    });
  }

  return styles;
});

Grid.propTypes = {
  container: PropTypes.bool,
  item: PropTypes.bool,
  justify: PropTypes.oneOf([
    "flex-end",
    "center",
    "space-between",
    "space-around"
  ]),
  spacing: PropTypes.number,
  alignItems: PropTypes.oneOf(["center", "flex-start", "flex-end", "baseline"]),
  xl: PropTypes.oneOf([true, "auto", 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  lg: PropTypes.oneOf([true, "auto", 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  md: PropTypes.oneOf([true, "auto", 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  sm: PropTypes.oneOf([true, "auto", 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  xs: PropTypes.oneOf([true, "auto", 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  /** define using component or element */
  as: PropTypes.node,
  gutterBottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

/** @component */
export default Grid;
