import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Container,
  Title,
  Header,
  BackIcon,
  PlusIcon,
  Content,
  Description,
  List,
  Row,
  WrapIcons,
  EditIcon,
  Season,
  SeasonRow,
  SeasonTitle,
  SeasonDate,
  Players,
  LinkIcon,
  Game,
  Console,
  WrapFilter,
  Image,
  Info
} from "./styled";
import { Link } from "react-router-dom";
import {
  fetchLeagues,
  fetchLeague,
  resetLeague
} from "../../../store/leagues/actions";
import LeagueCreate from "../LeagueCreate/LeagueCreate";
import moment from "moment";
import { COLOR } from "../../../constants/theme";

// Icons
import backArrow from "../../../static/icons/chevron-arrow.svg";
import iconPencil from "../../../static/icons/icon-pencil.svg";
import IconCirclePlus from "../../../components/kit/Icon/IconCirclePlus";
import crossPlatform from "../../../static/images/cross-platform.svg";
import iconNewWindow from "../../../static/icons/icon-new-window.svg";
import Filter from "../../../components/Filter";
import { isObjectEmpty } from "../../../helpers/common";

class League extends Component {
  constructor(props) {
    super();

    this.state = {
      editMode: false,
      filter: { upcoming: true, inProgress: true }
    };
  }

  componentDidMount() {
    const {
      params: { slug }
    } = this.props.match;
    this.props.fetchLeague(slug);
  }

  onLeagueUpdate() {
    this.props.fetchLeague(this.props.match.params.slug);
    this.props.fetchLeagues();
    this.setState({ editMode: false });
  }

  toggleEditMode() {
    this.setState({ editMode: !this.state.editMode });
  }

  renderTitle() {
    const { league, match } = this.props;
    const env = process.env.NODE_ENV;
    const link = `leagues/${match?.params?.slug}`;
    return (
      <Title>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={
            env === "development"
              ? `http://localhost:3000/${link}`
              : `https://${
                  env === "production" &&
                  !window?.location?.hostname?.includes("luigi")
                    ? ""
                    : "luigi."
                }rivalgames.com/${link}`
          }
        >
          {league?.name}
          <LinkIcon src={iconNewWindow} width={12} height={12} />
        </a>{" "}
        -
        {league?.game !== undefined && (
          <Game src={league?.game?.imageInfo?.title} />
        )}
        {league?.console !== undefined && (
          <Console
            src={league?.console?.imageInfo?.thumbnail || crossPlatform}
          />
        )}
      </Title>
    );
  }

  renderEditMode() {
    const { league, user } = this.props;
    return (
      <Container>
        <Header>
          <BackIcon src={backArrow} onClick={() => this.toggleEditMode()} />
          {this.renderTitle()}
          <WrapIcons>
            <EditIcon src={iconPencil} onClick={() => this.toggleEditMode()} />
          </WrapIcons>
        </Header>
        <LeagueCreate
          league={league}
          user={user}
          onUpdate={() => this.onLeagueUpdate()}
          update
        />
      </Container>
    );
  }

  renderLeague() {
    const { filter } = this.state;
    const { league, history } = this.props;

    const filteredSeasons = (league?.seasons || []).filter(s => {
      return (
        Object.values(filter).every(v => !v) ||
        (filter.upcoming && s.status === "initialized") ||
        (filter.inProgress && s.status === "in progress") ||
        (filter.completed && s.status === "complete")
      );
    });

    return (
      <Container>
        <Header>
          <BackIcon
            src={backArrow}
            onClick={() => {
              history.goBack();
              this.props.resetLeague();
            }}
          />
          {this.renderTitle()}
          <WrapIcons>
            <EditIcon src={iconPencil} onClick={() => this.toggleEditMode()} />
          </WrapIcons>
        </Header>
        <Content>
          <Image src={league?.imageInfo?.image} width={400} height={225} />
          <Info>
            <Players>{league?.playerCount} Followers</Players>
            <Row color={COLOR.GREY}>Description</Row>
            <Description>{league?.description}</Description>
          </Info>
        </Content>
        <Row style={{ fontSize: "16px" }}>
          <b>SEASONS</b>
        </Row>
        {league?.seasons?.length > 0 && (
          <WrapFilter>
            <Filter
              items={["upcoming", "inProgress", "completed"]}
              filter={filter}
              onChangeFilter={val => {
                const newFilter = { ...filter, [val]: !filter[val] };
                if (!isObjectEmpty(newFilter)) {
                  this.setState({ filter: newFilter });
                }
              }}
            />
          </WrapFilter>
        )}
        <List>
          {filteredSeasons?.length > 0 &&
            filteredSeasons
              .sort((a, b) => a?.index - b?.index)
              .map(season => (
                <Season
                  key={season?.name + season?.id}
                  as={Link}
                  to={`${league?.slug}/seasons/${season?.token}`}
                  status={season?.status}
                >
                  <SeasonTitle>Season {season?.index}</SeasonTitle>
                  <SeasonDate>{`${moment(season?.startDate).format(
                    "MMM Do"
                  )} - ${moment(season?.endDate).format(
                    "MMM Do 'YY"
                  )}`}</SeasonDate>
                </Season>
              ))}
          <Season
            key={"create-season"}
            as={Link}
            to={`/leagues/${league.slug}/create`}
          >
            <SeasonRow>
              <SeasonTitle>Create New Season</SeasonTitle>
              <PlusIcon>
                <IconCirclePlus width={25} />
              </PlusIcon>
            </SeasonRow>
          </Season>
        </List>
      </Container>
    );
  }

  render() {
    return this.state.editMode ? this.renderEditMode() : this.renderLeague();
  }
}

const mapStateProps = state => ({
  league: state.leaguesState.league
});

const mapDispatchToProps = {
  fetchLeagues,
  fetchLeague,
  resetLeague
};

export default connect(mapStateProps, mapDispatchToProps)(League);
