import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import {
  Container,
  Header,
  TournamentInfo,
  WrapPause,
  Title,
  ConsoleIcon,
  TournamentId,
  Text,
  WrapSmallTimer,
  BackIcon,
  LinkIcon,
  Line
} from "./styled";
import { Route, Switch, withRouter } from "react-router-dom";
import withWidth from "../../../../hoc/withWidth";
import SmallTimer from "../../../../components/smart/Timer/Timer";
import ButtonKit from "../../../../components/kit/Button/ButtonKit";
import {
  fetchTournamentAction,
  resetTournamentAction
} from "../../../../store/tournament/actions";
import Loader from "../../../../components/presentation/Loader/Loader";
import { MANAGE_ROUTES } from "./constants";
import { isObjectEmpty, parseTournamentId } from "../../../../helpers/common";
import Pusher from "pusher-js";
import { debounce, propValueOr } from "../../../../helpers/common";
import { api } from "../../../..";

// Icons
import crossPlatform from "../../../../static/icons/cross-platform.svg";
import backArrow from "../../../../static/icons/chevron-arrow.svg";
import iconNewWindow from "../../../../static/icons/icon-new-window.svg";

class Tournament extends Component {
  constructor(props) {
    super();

    this.pusher = new Pusher(process.env.REACT_APP_PUSHER_ID || "", {
      cluster: "us3",
      forceTLS: true
    });
  }

  componentDidMount() {
    this.props.fetchTournamentAction(this.props.match.params.token);
  }

  componentDidUpdate(prevProps) {
    // Subscribe to pusher if
    if (prevProps.tournament.id !== this.props.tournament.id) {
      this.subscribeChannel(this.props.tournament.id);
    }
  }

  componentWillUnmount() {
    this.unsubscribeChannel(this.props.tournament.id);
    this.props.resetTournamentAction();
  }

  subscribeChannel = tournamentId => {
    this.pusher.subscribe(`tournament-${parseTournamentId(tournamentId)}`);

    this.pusher.bind("status", packet => {
      let data = packet.message;
      if (data.status === "started") {
        debounce(this.fetchTournament(), 500);
      } else if (data.status === "update") {
        debounce(this.fetchTournament(), 500);
      }
    });

    this.pusher.bind("new-matches", () => {
      debounce(this.fetchTournament(), 500);
    });

    this.pusher.bind("new-brackets", () => {
      debounce(this.fetchTournament(), 500);
    });
  };

  unsubscribeChannel = tournamentId => {
    this.pusher.unsubscribe(`tournament-${parseTournamentId(tournamentId)}`);
  };

  fetchTournament() {
    this.props.fetchTournamentAction(this.props.match.params.token);
  }

  pauseTournament = () => {
    const { tournament } = this.props;

    const body = {
      paused: tournament.paused ? null : new Date()
    };

    let data = new FormData();
    for (let key in body) {
      data.append(key, body[key]);
    }

    api.admin.tournament
      .update(tournament.id, data)
      .then(resp => {
        this.props.fetchTournamentAction(this.props.match.params.token);
      })
      .catch(err => console.log("Update failed", err));
  };

  postToDiscord = () => {
    const { tournament } = this.props;

    api.admin.tournament
      .discord(tournament.id)
      .then(resp => {
        this.props.fetchTournamentAction(this.props.match.params.token);
      })
      .catch(err => console.log("Update failed", err));
  };

  render() {
    const {
      tournament,
      isFetchingTournament,
      history,
      width,
      match
    } = this.props;
    const isMobile = width < 800;
    const env = process.env.NODE_ENV;
    const link = `tournaments/${match?.params?.token}`;
    return (
      <Container>
        <Header>
          <BackIcon src={backArrow} onClick={() => history.goBack()} />
          <TournamentInfo>
            <Title>MANAGE TOURNAMENT</Title>
            {!isMobile && (
              <Fragment>
                <TournamentId>{`ID ${tournament.id}`}</TournamentId>
                <ConsoleIcon
                  src={propValueOr(
                    tournament,
                    "console.imageInfo.icon",
                    crossPlatform
                  )}
                  onError={e => (e.target.src = null)}
                />
              </Fragment>
            )}
          </TournamentInfo>
          {tournament.status !== "complete" && (
            <WrapPause>
              <ButtonKit
                onClick={() => this.postToDiscord()}
                color={"discord"}
                shape={"rounded"}
                disabled={tournament?.metadata?.discord}
              >
                <Text>
                  {tournament?.metadata?.discord ? "Posted" : "Post to Discord"}
                </Text>
              </ButtonKit>
            </WrapPause>
          )}
          {tournament.status !== "complete" && (
            <WrapPause>
              <ButtonKit
                onClick={() => this.pauseTournament()}
                color={"green"}
                shape={"rounded"}
              >
                <Text>
                  {tournament.paused ? "Resume Tournament" : "Pause Tournament"}
                </Text>
                {tournament.paused && (
                  <WrapSmallTimer>
                    <SmallTimer
                      startDate={new Date(tournament.paused).getTime()}
                    />
                  </WrapSmallTimer>
                )}
              </ButtonKit>
            </WrapPause>
          )}
        </Header>
        <Line>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={
              env === "development"
                ? `http://localhost:3000/${link}`
                : `https://${
                    env === "production" &&
                    !window?.location?.hostname?.includes("luigi")
                      ? ""
                      : "luigi."
                  }rivalgames.com/${link}`
            }
          >
            {tournament?.name}
            <LinkIcon src={iconNewWindow} width={12} height={12} />
          </a>
        </Line>
        {isFetchingTournament || isObjectEmpty(tournament) ? (
          <Loader isBlock />
        ) : (
          <Switch>
            {MANAGE_ROUTES.map(route => (
              <Route
                exact
                key={route.path}
                path={route.path}
                component={route.component}
              />
            ))}
          </Switch>
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  tournament: state.tournamentState.tournament,
  team: state.teamState.team,
  isFetchingTournament: state.tournamentState.isFetching
});
const mapDispatchToProps = {
  fetchTournamentAction,
  resetTournamentAction
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withWidth(Tournament)));
