import React from "react";

import DialogKit from "../../../../components/kit/Dialog/DialogKit";
import ButtonKit from "../../../../components/kit/Button/ButtonKit";
import {
  Container,
  Header,
  HeaderTitle,
  TextContent,
  Buttons,
  WrapButton,
  Bold
} from "./styled";
import { api } from "../../../..";
import { propValueOr } from "../../../../helpers/common";
import { withRouter } from "react-router-dom";

class PlayerRemovalDialog extends React.Component {
  constructor(props) {
    super();

    this.state = {
      success: false,
      confirm: false,
      error: null,
      loading: false
    };
  }

  handleClose = () => {
    this.setState({
      confirm: false,
      success: false,
      error: null,
      loading: false
    });
    this.props.onClose();
  };

  deleteTournament = () => {
    const { tournament, history } = this.props;
    if (tournament && tournament.id) {
      this.setState({ loading: true });
      api.admin.tournament
        .delete(tournament.id)
        .then(resp => {
          this.setState({ success: true });
          window.setTimeout(() => {
            this.handleClose();
            history.push("/tournaments");
          }, 2000);
        })
        .catch(err =>
          this.setState({
            error:
              typeof err === "string"
                ? err
                : propValueOr(
                    err,
                    "response.message",
                    "Failed to delete tournament. Please try again later."
                  )
          })
        )
        .finally(() => this.setState({ loading: false }));
    }
  };

  renderDialog = () => {
    const { success, confirm, error, loading } = this.state;
    const { tournament } = this.props;

    return (
      <Container>
        <Header>
          <HeaderTitle>
            {error
              ? "FAILED"
              : success
              ? "SUCCESS"
              : confirm
              ? "ARE YOU SURE?"
              : "DELETE TOURNAMENT"}
          </HeaderTitle>
        </Header>
        <TextContent>
          {error ||
            (success ? (
              "Success. The tournament has been deleted."
            ) : confirm ? (
              "Are you sure? This action cannot be undone."
            ) : (
              <>
                Do you want to delete tournament #{tournament.id}:{" "}
                <Bold>{tournament.name}</Bold>?
              </>
            ))}
        </TextContent>
        <Buttons>
          {!error && !success && (
            <WrapButton>
              <ButtonKit
                fullWidth
                color="red"
                preloader={loading}
                onClick={
                  confirm
                    ? this.deleteTournament
                    : () => this.setState({ confirm: true })
                }
              >
                {confirm ? "Yes, Delete" : "Delete Tournament"}
              </ButtonKit>
            </WrapButton>
          )}
          <WrapButton>
            <ButtonKit
              fullWidth
              appearance="secondary"
              onClick={this.handleClose}
            >
              {error || success ? "Okay" : "Cancel"}
            </ButtonKit>
          </WrapButton>
        </Buttons>
      </Container>
    );
  };

  render() {
    const { isOpen } = this.props;

    return (
      <DialogKit
        isOpen={isOpen}
        onClose={this.handleClose}
        renderCustom={this.renderDialog}
        displayButtonClose={false}
      />
    );
  }
}

export default withRouter(PlayerRemovalDialog);
