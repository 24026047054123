import React, { Component } from "react";
import {
  Container,
  SectionTitle,
  Content,
  Row,
  Title,
  Description,
  Wrap,
  Icon,
  WrapButtons,
  Label,
  WrapEmpty
} from "./styled";
import FormFieldKit from "../../../../../components/kit/Fields/FormField/FormField";
import { propValueOr, safePropValueOr } from "../../../../../helpers/common";
import IconCirclePlus from "../../../../../components/kit/Icon/IconCirclePlus";
import DialogKit from "../../../../../components/kit/Dialog/DialogKit";
import ButtonKit from "../../../../../components/kit/Button/ButtonKit";
import iconX from "../../../../../static/icons/remove.svg";
import moment from "moment";
import momentTz from "moment-timezone";
import { connect } from "react-redux";
import { setSeasonProperty } from "../../../../../store/leagues/actions";
import {
  bestOfOptions,
  readyOptions,
  timeOptions,
  verificationOptions
} from "../../../../tournaments/TournamentCreate/constants";
import { cloneDeep, pick, set } from "lodash";
const zone_name = momentTz.tz.guess();
const timezone = momentTz.tz(zone_name).zoneAbbr();

class Match extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidUpdate(prevProps, prevState) {}

  handleChangeField = (field, value) => {
    // in progress - update season matches
    if (this.props.season?.status === "in progress") {
      const seasonMatches = cloneDeep(this.props.seasonMatches);

      try {
        const index = (seasonMatches || []).findIndex(
          sm => sm?.round === this.props.match?.round
        );
        let item = index === -1 ? undefined : seasonMatches[index];
        if (item) {
          item = pick(item, ["startDate", "round", "settings"]);
          item[field] = value;
          set(seasonMatches, `[${index}].${field}`, value);
        } else {
          seasonMatches.push({
            startDate:
              field === "startDate"
                ? value
                : this.props.match?.startDate || moment(new Date()),
            round: this.props.match?.round || this.props.currentIndex + 1,
            settings: this.props.match?.settings,
            [field]: value
          });
        }
        this.props.setSeasonProperty("seasonMatches", seasonMatches);
      } catch (e) {
        console.log(
          "failed to set season match field ",
          field,
          " with value ",
          value,
          "\nError: ",
          e
        );
      }
      return;
    }

    // Not in progress - update schedule
    const schedule = cloneDeep(this.props.schedule);

    try {
      let item = schedule[this.props.currentIndex];

      if (item) {
        item = pick(item, ["startDate", "round", "settings"]);
        item[field] = value;
        set(schedule, `[${this.props.currentIndex}].${field}`, value);
      } else {
        schedule.push({
          startDate:
            field === "startDate"
              ? value
              : this.props.match?.startDate || moment(new Date()),
          round: this.props.match?.round || this.props.currentIndex + 1,
          settings: this.props.match?.settings,
          [field]: value
        });
      }
      this.props.setSeasonProperty("schedule", schedule);
    } catch (e) {
      console.log(
        "failed to set season match field ",
        field,
        " with value ",
        value,
        "\nError: ",
        e
      );
    }
  };
  handleChangeSetting = (field, value) => {
    // in progress - update season matches
    if (this.props.season?.status === "in progress") {
      const schedule = cloneDeep(this.props.seasonMatches);
      try {
        const index = (schedule || []).findIndex(
          sm => sm?.round === this.props.match?.round
        );
        let item = index === -1 ? undefined : schedule[index];

        if (item) {
          item = pick(item, ["startDate", "round", "settings"]);
          item.settings[field] = value;
          set(schedule, `[${index}].settings.${field}`, value);
        } else {
          schedule.push({
            startDate: this.props.match?.startDate || moment(new Date()),
            round: this.props.match?.round || this.props.currentIndex + 1,
            settings: { ...this.props.match?.settings, [field]: value }
          });
        }
        this.props.setSeasonProperty("seasonMatches", schedule);
      } catch (e) {
        console.log(
          "failed to set season match field ",
          field,
          " with value ",
          value,
          "\nError: ",
          e
        );
      }
      return;
    }

    // Not in progress - update schedule
    const schedule = cloneDeep(this.props.schedule);
    try {
      let item = schedule[this.props.currentIndex];

      if (item) {
        item = pick(item, ["startDate", "round", "settings"]);
        item.settings[field] = value;
        set(schedule, `[${this.props.currentIndex}].settings.${field}`, value);
      } else {
        schedule.push({
          startDate: this.props.match?.startDate || moment(new Date()),
          round: this.props.match?.round || this.props.currentIndex + 1,
          settings: { ...this.props.match?.settings, [field]: value }
        });
      }
      this.props.setSeasonProperty("schedule", schedule);
    } catch (e) {
      console.log(
        "failed to set schedule field ",
        field,
        " with value ",
        value,
        "\nError: ",
        e
      );
    }
  };

  handleCloseSettingDialog = () => {
    this.setState({ isAddSettingDialogOpen: false, addSetting: "" });
  };

  handleAddSetting = () => {
    const { match } = this.props;
    const { addSetting } = this.state;
    if (Object.keys(match?.settings?.settings || {}).includes(addSetting)) {
      return;
    }

    this.handleChangeSetting("settings", {
      ...match?.settings?.settings,
      [addSetting]: ""
    });
    this.handleCloseSettingDialog();
  };

  renderAddSettingDialog = () => {
    const { match } = this.props;
    const { isAddSettingDialogOpen, addSetting } = this.state;
    return (
      <DialogKit
        isOpen={isAddSettingDialogOpen}
        onClose={this.handleCloseSettingDialog}
        title={"Add Setting"}
        renderBody={() => (
          <>
            <FormFieldKit
              fullWidth
              label={"Setting Name"}
              placeholder={"Enter new setting name"}
              onChange={val => this.setState({ addSetting: val })}
              value={addSetting}
              inputProps={{
                autoFocus: true,
                onKeyDown: e =>
                  e.keyCode === 13 && !!addSetting && this.handleAddSetting()
              }}
            />
          </>
        )}
        renderFooter={() => (
          <WrapButtons>
            <ButtonKit
              fullWidth
              small
              disabled={
                Object.keys(match?.settings?.settings || {}).includes(
                  addSetting
                ) || !addSetting
              }
              onClick={() => {
                this.handleAddSetting();
              }}
            >
              Add
            </ButtonKit>
            <ButtonKit
              fullWidth
              small
              appearance={"secondary"}
              color={"red"}
              onClick={this.handleCloseSettingDialog}
            >
              Cancel
            </ButtonKit>
          </WrapButtons>
        )}
      />
    );
  };

  render() {
    const { isCreate } = this.props;
    const seasonMatch = this.props.seasonMatches.find(
      m => m?.round === this.props.match?.round
    );
    const match = seasonMatch || this.props.match;
    const settings = match?.settings || {};

    if (
      this.props.season?.status === "in progress" &&
      this.props.currentIndex == null
    )
      return null;

    return !match ? null : (
      <Container>
        {this.renderAddSettingDialog()}
        <SectionTitle>ROUND {match.round} - DETAILS</SectionTitle>
        <Content>
          <FormFieldKit
            dateTimePicker
            fullWidth
            label={`Match Start Date/Time - ${zone_name} (${timezone})`}
            placeholder={"Enter a start date/time..."}
            onChange={dt => {
              let date = dt;
              if (dt) {
                date = moment(dt).format("YYYY-MM-DD HH:mm:ssZZ");
              }
              this.handleChangeField("startDate", date);
            }}
            value={match?.startDate}
          />
          {!isCreate && (
            <>
              <FormFieldKit
                label={"Ready Required?"}
                description={
                  "Set whether the players have to (1): Go to the game page to start the game (this is standard), or (2): Click a 'ready' button in order to start the game"
                }
                fullWidth
                multiline
                required
                select
                selectOptions={readyOptions}
                value={safePropValueOr(
                  readyOptions.find(opt =>
                    match
                      ? match.settings?.ready
                        ? true === opt.value
                        : false === opt.value
                      : false === opt.value
                  ),
                  "value",
                  undefined
                )}
                onChange={val => {
                  this.handleChangeSetting("ready", val);
                }}
                placeholder={"Select Ready Required"}
              />
              <Row>{settings?.ready ? "Play Window" : "Forfeit Clock"}</Row>
              <Description>
                {settings?.ready
                  ? "Players must ready-up and enter scores before this amount of time passes, otherwise the winner will be selected by the system"
                  : "Players must ready-up (go to the game page) before this amount of time passes, otherwise they will forfeit the game"}
              </Description>
              <Row>
                <FormFieldKit
                  type="number"
                  label={settings?.ready ? `Game Ends After` : "Forfeit After"}
                  placeholder={"15"}
                  fullWidth
                  onChange={f => {
                    let forfeitArr = settings?.forfeit
                      ? settings.forfeit.split(",")
                      : ["15", "minutes"];
                    const val = isNaN(parseInt(f)) || parseInt(f) <= 0 ? "" : f;
                    const newSettings = cloneDeep(settings);
                    forfeitArr[0] = val;
                    newSettings.forfeit = forfeitArr.join(",");
                    if (!val && forfeitArr[1] === "minutes") {
                      delete newSettings.forfeit;
                    }
                    this.handleChangeField("settings", newSettings);
                  }}
                  // onBlur={() => {
                  //   // If user enters null value, reset forfeit to default (15 minutes)
                  //   if (settings?.forfeit) {
                  //     let forfeitArr = settings.forfeit.split(",");
                  //     if (!forfeitArr[0] || !parseInt(forfeitArr[0])) {
                  //       delete info[round].forfeit;
                  //       this.handleChangeField({ roundInfo: info });
                  //     }
                  //   }
                  // }}
                  value={
                    propValueOr(settings, "forfeit", "").split(",")[0] || ""
                  }
                />
                <FormFieldKit
                  select
                  selectOptions={timeOptions}
                  label={"Time"}
                  placeholder={"min"}
                  fullWidth
                  onChange={f => {
                    let forfeitArr = settings?.forfeit
                      ? settings.forfeit.split(",")
                      : ["15", "minutes"];
                    forfeitArr[1] = f;

                    this.handleChangeSetting("forfeit", forfeitArr.join(","));
                  }}
                  value={
                    propValueOr(settings, "forfeit", "").split(",")[1] ||
                    "minutes"
                  }
                />
              </Row>
              <Row>
                <FormFieldKit
                  label={`Best Of Series`}
                  description={
                    "How many games per round? The winner of the round will be determined by who wins the majority of the games in the series."
                  }
                  select
                  selectOptions={bestOfOptions}
                  multiline
                  fullWidth
                  placeholder={"Single Game Series (standard)"}
                  onChange={val => {
                    const newSettings = cloneDeep(settings);
                    newSettings["Best Of"] = val;
                    if (!val) {
                      delete newSettings["Best Of"];
                    }
                    this.handleChangeField("settings", newSettings);
                  }}
                  value={match?.settings ? match?.settings["Best Of"] : null}
                />
              </Row>
              <Row>
                <FormFieldKit
                  label={`Result Verification Method for Matches`}
                  description={
                    "What kind of result verification? Matches will not complete until verified with the chosen method."
                  }
                  select
                  selectOptions={verificationOptions}
                  multiline
                  fullWidth
                  placeholder={
                    "Score Entry w/ No Pic Upload (Same winner entered = advance, standard challenge process)"
                  }
                  // placeholder={"User verification (standard)"}
                  onChange={val => {
                    const newSettings = cloneDeep(settings);
                    newSettings["Verification"] = val;
                    if (!val) {
                      delete newSettings["Verification"];
                    }
                    this.handleChangeField("settings", newSettings);
                  }}
                  value={
                    match?.settings ? match?.settings["Verification"] : null
                  }
                />
              </Row>
              <Wrap>
                <Title>GAME SETTINGS</Title>
                <Icon
                  onClick={() =>
                    this.setState({ isAddSettingDialogOpen: true })
                  }
                >
                  <IconCirclePlus width={25} />
                </Icon>
              </Wrap>
              {propValueOr(
                Object.entries(match?.settings?.settings || {}),
                "length"
              ) > 0 ? (
                Object.entries(match?.settings?.settings || {}).map(
                  (item, index) => (
                    <FormFieldKit
                      key={index}
                      fullWidth
                      placeholder={"Enter setting..."}
                      label={
                        <Wrap style={{ marginBottom: "-5px" }}>
                          <Label>{item[0]}</Label>
                          <img
                            onClick={() => {
                              const newSettings = {
                                ...match?.settings?.settings
                              };
                              delete newSettings[item[0]];
                              this.handleChangeSetting("settings", newSettings);
                            }}
                            style={{ cursor: "pointer" }}
                            src={iconX}
                            alt=""
                            width={22}
                            height={22}
                          />
                        </Wrap>
                      }
                      inputProps={{ maxLength: 50 }}
                      onChange={val => {
                        const newSettings = { ...match?.settings?.settings };
                        newSettings[item[0]] = val;
                        this.handleChangeSetting("settings", newSettings);
                      }}
                      value={item[1]}
                    />
                  )
                )
              ) : (
                <WrapEmpty>
                  No Game Settings. You can add settings by clicking the '+'
                  icon above.
                </WrapEmpty>
              )}
            </>
          )}
        </Content>
      </Container>
    );
  }
}

const mapStateProps = state => ({
  season: state.leaguesState.season,
  // schedule: state.leaguesState.season?.schedule,
  seasonMatches: state.leaguesState.season?.seasonMatches || []
});

const mapDispatchToProps = {
  setSeasonProperty
};

export default connect(mapStateProps, mapDispatchToProps)(Match);
