import React from "react";
import { SVGIconSD } from "./styled";
import PropTypes from "prop-types";

function IconLetterA(props) {
  return (
    <SVGIconSD width={props.width} viewBox="0 0 15 19">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-409.000000, -1084.000000)" fill="#52C3D0">
          <path
            d="M421.717391,1102.5 L419.966184,1097.34823 L413.033816,1097.34823 L411.282609,1102.5 L409,1102.5
            L415.425121,1084.5 L417.574879,1084.5 L424,1102.5 L421.717391,1102.5 Z M416.463768,1087.23181
            L413.673913,1095.43971 L419.326087,1095.43971 L416.536232,1087.23181 L416.463768,1087.23181 Z"
          />
        </g>
      </g>
    </SVGIconSD>
  );
}

IconLetterA.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default IconLetterA;
