import React from "react";
import PropTypes from "prop-types";

import {
  LayoutGeneralWrapFooterSD,
  LayoutGeneralWrapHeaderSD,
  LayoutGeneralWrapMainSD,
  LayoutGeneralSD,
  LayoutGeneralMainSD
} from "./styled";
// import Header from '../../components/smart/Header/Header';
import HeaderNotAuth from "../../components/smart/HeaderNotAuth/HeaderNotAuth";
import Footer from "../../components/presentation/Footer/Footer";
import { logoutAction } from "../../store/user/actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import LevelUp from "../../components/presentation/LevelUp/LevelUp";
import { propValueOr } from "../../helpers/common";
import { config } from "../../index";

function LayoutGeneral(props) {
  const { user, children } = props;

  return (
    <LayoutGeneralSD>
      <LevelUp type={"topBar"}>
        <LayoutGeneralWrapHeaderSD>
          <HeaderNotAuth
            user={user}
            isTransparent={propValueOr(config, "transparentHeader")}
            showOfficialRules
          />
        </LayoutGeneralWrapHeaderSD>
      </LevelUp>

      <LayoutGeneralWrapMainSD>
        <LayoutGeneralMainSD>{children}</LayoutGeneralMainSD>
      </LayoutGeneralWrapMainSD>

      <LayoutGeneralWrapFooterSD>
        <Footer />
      </LayoutGeneralWrapFooterSD>
    </LayoutGeneralSD>
  );
}

LayoutGeneral.propTypes = {
  user: PropTypes.shape({
    logged: PropTypes.bool
  }).isRequired
};

const mapDispatchToProps = {
  logoutAction
};

export default connect(null, mapDispatchToProps)(withRouter(LayoutGeneral));
