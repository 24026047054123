import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { addUnit } from "../../../helpers/common";

export const ButtonIconKitSD = styled.button`
  display: inline-block;
  width: ${({ width }) => addUnit(width)};
  height: ${({ height }) => addUnit(height)};
  padding: 0;
  margin: 0;

  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  border: none;

  cursor: pointer;
  transition: all 0.3s;

  ${_hoverStylesIfHasStroke};
  ${_hoverStylesIfHasFill};

  & > * {
    margin: auto;
  }

  &:hover {
    ${props =>
      props.hoverOpacity &&
      css`
        opacity: 0.6;
      `};
    ${_hoverStylesIfHasStrokeHover};
    ${_hoverStylesIfHasFillHover};
  }
`;
ButtonIconKitSD.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  stroke: PropTypes.string,
  strokeHover: PropTypes.string,
  fill: PropTypes.string,
  fillHover: PropTypes.string
};
ButtonIconKitSD.defaultProps = {
  width: 40,
  height: 40
};

export const ButtonIconInnerFlexKitSD = styled.span`
  display: flex;
  width: 100%;
  height: 100%;

  & > * {
    margin: auto;
  }
`;

function _hoverStylesIfHasFill(props) {
  return (
    props.fill &&
    css`
      fill: ${props.fill};
    `
  );
}
function _hoverStylesIfHasStroke(props) {
  return (
    props.stroke &&
    css`
      fill: ${props.stroke};
    `
  );
}
function _hoverStylesIfHasFillHover(props) {
  return (
    props.fillHover &&
    css`
      fill: ${props.fillHover};
    `
  );
}
function _hoverStylesIfHasStrokeHover(props) {
  return (
    props.strokeHover &&
    css`
      fill: ${props.strokeHover};
    `
  );
}
