import styled from "styled-components";
import { COLOR, FONT_WEIGHT, FONT_FAMILY } from "../../../constants/theme";
import breakpoints from "../../../helpers/breakpoints";

export const Container = styled.div`
  display: flex;
  flex: 1;
  background-color: ${COLOR.BG_PRIMARY};
  min-height: 67px;
  max-height: 67px;
  border-radius: 4px;
  margin-bottom: 30px;
`;

export const Left = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  height: 44px;
  padding-right: 20px;
`;

export const WrapOrganization = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 44px;
`;

export const Right = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
`;

export const Title = styled.div`
  font: ${FONT_WEIGHT.NORMAL} 14px / 14px ${FONT_FAMILY.THIN};
  color: ${COLOR.FG_PRIMARY};
  user-select: text;
`;

export const Image = styled.img`
  width: 29px;
  height: 44px;
  margin: 0 12px;
`;

export const Icon = styled.img``;

export const Divider = styled.div`
  display: flex;
  width: 1px;
  background-color: ${COLOR.BORDER};
`;

export const OrgPic = styled.img`
  max-height: 24px;
  max-width: 30px;
`;

export const Name = styled.div`
  font: ${FONT_WEIGHT.BOLD} 13px / 1.2 ${FONT_FAMILY.GOTHIC};
  padding-top: 1px;
  padding-left: 6px;
  user-select: text;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${breakpoints.down("md")} {
    max-width: 350px;
  }

  ${breakpoints.down("sm")} {
    max-width: 200px;
  }
`;

export const WrapTimer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  font: ${FONT_WEIGHT.BOLD} 13px / 1.2 ${FONT_FAMILY.GOTHIC};
  span {
    border-radius: 16px;
    padding: 3px 10px;
    min-width: 55px;
    text-align: center;
    background-color: ${COLOR.ROUGE};
  }
`;
