import React, { Component } from "react";
import { Container, Content, Report, Text, Edit } from "./styled";
import Loader from "../../../components/presentation/Loader/Loader";
import FormFieldKit from "../../../components/kit/Fields/FormField/FormField";
import ButtonKit from "../../../components/kit/Button/ButtonKit";
import { api } from "../../..";
import { propValueOr } from "../../../helpers/common";
import PermissionDenied from "../../denied/PermissionDenied";

class Analytics extends Component {
  constructor(props) {
    super();

    this.state = {
      editLink: props.link,
      error: ""
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.link !== this.props.link) {
      this.setState({ editLink: this.props.link });
    }
  }

  updateConfig = () => {
    api.admin.organizations
      .update(propValueOr(this.props, "user.info.organizationId"), {
        config: { ...this.props.config, report: this.state.editLink }
      })
      .then(() => this.props.fetchOrganization())
      .catch(e => this.setState({ error: propValueOr(e, "response.message") }));
  };

  render() {
    const { editLink, error } = this.state;
    const { link, loading, permission } = this.props;
    if (!permission) {
      return <PermissionDenied />;
    }
    return (
      <Container>
        {["admin", "write"].includes(permission) && (
          <Edit>
            <FormFieldKit
              fullWidth
              label={"Update report embed link"}
              onChange={val => this.setState({ editLink: val })}
              value={editLink}
              placeholder={"Enter embed link..."}
              errorText={error}
            />
            <ButtonKit
              fullWidth
              small
              appearance={"secondary"}
              onClick={this.updateConfig}
              disabled={link === editLink}
            >
              Update Link
            </ButtonKit>
          </Edit>
        )}
        <Content>
          {loading ? (
            <Loader isBlock />
          ) : link ? (
            <Report allowFullScreen height={1067} src={link} />
          ) : (
            <Text>No report for this organization</Text>
          )}
        </Content>
      </Container>
    );
  }
}

export default Analytics;
