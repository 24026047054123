import React, { useState } from "react";
import {
  Container,
  Header,
  Title,
  Subtitle,
  Content,
  ListContainer,
  List,
  WrapGameCard,
  GameCard,
  WrapButtons
} from "./styled";
import DialogKit from "../../../components/kit/Dialog/DialogKit";
import ButtonKit from "../../../components/kit/Button/ButtonKit";
import { propValueOr } from "../../../helpers/common";

const AddGameDialog = ({ games, orgGames, addGame, isOpen, onClose }) => {
  const [selectedGame, setSelectedGame] = useState(null);
  const orgGameIds = orgGames.map(g => g?.gameId);

  const close = () => {
    setSelectedGame(null);
    onClose();
  };

  const handleAddGame = id => {
    setSelectedGame(null);
    addGame(id);
  };

  const renderDialog = () => {
    return (
      <Container>
        <Header>
          <Title>Add Organization Game</Title>
        </Header>
        <Subtitle>Select Game to Add</Subtitle>
        <Content>
          <ListContainer>
            <List>
              {games
                .filter(g => !orgGameIds.includes(g.id))
                .map(g => {
                  return (
                    <WrapGameCard
                      key={g.id}
                      onClick={() => {
                        setSelectedGame(g);
                      }}
                      selected={selectedGame?.id === g.id}
                    >
                      <GameCard
                        src={propValueOr(
                          g,
                          "game.imageInfo.original",
                          propValueOr(g, "imageInfo.original")
                        )}
                        onError={e => (e.target.src = "")}
                        alt=""
                      />
                    </WrapGameCard>
                  );
                })}
            </List>
          </ListContainer>
          <WrapButtons>
            <ButtonKit
              appearance={"secondary"}
              fullWidth
              onClick={() => handleAddGame(selectedGame.id)}
              disabled={!selectedGame}
            >
              Add
            </ButtonKit>
            <ButtonKit
              appearance={"secondary"}
              color={"red"}
              fullWidth
              onClick={close}
            >
              Cancel
            </ButtonKit>
          </WrapButtons>
        </Content>
      </Container>
    );
  };

  return (
    <DialogKit
      isOpen={isOpen}
      onClose={close}
      renderCustom={renderDialog}
      isMobile
    />
  );
};

export default AddGameDialog;
