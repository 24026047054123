import styled from "styled-components";
import iconBellWhite from "../../../../static/icons/icon-bell-white.svg";
import { COLOR, FONT_FAMILY, FONT_WEIGHT } from "../../../../constants/theme";
import breakpoints from "../../../../helpers/breakpoints";

export const HeaderNotificationsSD = styled.div`
  position: relative;

  ${breakpoints.down(540)} {
    position: static;
  }
`;

export const HeaderNotificationsToggleSD = styled.div`
  position:relative;
  width: 25px;
  height: 25px;
  margin-top: 2px;
  background: url("${iconBellWhite}") 0 0 no-repeat;
  background-size: 100%;
  cursor:pointer;
`;
export const HeaderNotificationsToggleCounterSD = styled.div`
  position: absolute;
  top: -5px;
  left: -10px;
  padding: 2px 4px 1px;

  background-color: ${COLOR.RIVAL_RED};
  border-radius: 50px;

  color: ${COLOR.WHITE};
  font: ${FONT_WEIGHT.NORMAL} 12px / 1 ${FONT_FAMILY.THIN};
`;

export const HeaderNotificationsWrapPopupSD = styled.div`
  position: absolute;
  top: 100%;
  right: -40px;
  width: 487px;
  margin-top: 19px;

  max-height: 450px;
  overflow: auto;

  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);

  @media (max-height: 500px) {
    max-height: calc(100vh - 50px);
  }

  ${breakpoints.down(540)} {
    width: 90vw;
    right: 0;
  }
`;

export const HeaderNotificationsWrapGroupsSD = styled.div``;

export const HeaderNotificationsNoItemsMessageSD = styled.div`
  padding: 32px 0;
  margin: 0 22px;

  border-top: 1px solid ${COLOR.BORDER};

  color: ${COLOR.TEXT_4};
  font: ${FONT_WEIGHT.BOLD} 14px / 1.2 ${FONT_FAMILY.THIN};
  text-align: center;
  text-transform: uppercase;
`;
