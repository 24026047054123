export const ROUTES = [
  {
    path: "/communication/templates",
    breadcrumb: "Templates"
  },
  {
    path: "/communication/templates/create",
    breadcrumb: "Create"
  },
  {
    path: "/communication/templates/:id",
    breadcrumb: "Edit"
  },
  {
    path: "/communication/global_templates",
    breadcrumb: "Global templates"
  },
  {
    path: "/communication/global_templates/create",
    breadcrumb: "Create"
  },
  {
    path: "/communication/global_templates/:id",
    breadcrumb: "Edit"
  }
];
