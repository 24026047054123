import styled from "styled-components";
import { COLOR, FONT_FAMILY, FONT_WEIGHT } from "../../../../constants/theme";

export const Container = styled.div`
  display: flex;
`;

export const Form = styled.form`
  width: 100%;
`;

export const Icon = styled.img``;

export const WrapIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ moderatorAt }) =>
    moderatorAt ? COLOR.RIVAL_RED : COLOR.BG_INPUT};
  width: 36px;
  height: 36px;
  border-radius: 50px;
  margin-left: 10px;
  cursor: pointer;
  &:hover {
    background-color: ${COLOR.BG_PRIMARY};
  }
  animation: ${({ moderatorAt }) => moderatorAt && "pulse 2s infinite"};
  &&& {
    filter: brightness(85%);
  }

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgb(219, 51, 86, 0.9);
    }
    70% {
      box-shadow: 0 0 0 10px rgb(219, 51, 86, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgb(219, 51, 86, 0);
    }
  }
`;

export const Field = styled.input`
  padding: 9px 16px;
  border: 1px solid rgba(204, 204, 204, 0);
  border-radius: 17px;
  font: ${FONT_WEIGHT.NORMAL} 12px / 12px ${FONT_FAMILY.THIN};
  width: 100%;
  background-color: ${props => props.theme.field.bgColor || COLOR.BG_INPUT};
  color: ${props => props.theme.field.textColor};
  font-size: 14px;
  font-weight: ${FONT_WEIGHT.BOLD};

  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: ${props => props.theme.field.placeholderColor};
    opacity: 0.4;
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: ${props => props.theme.field.placeholderColor};
    opacity: 0.4;
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    color: ${props => props.theme.field.placeholderColor};
    opacity: 0.4;
  }
  :-moz-placeholder {
    /* Firefox 18- */
    color: ${props => props.theme.field.placeholderColor};
    opacity: 0.4;
  }
`;
