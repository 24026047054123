import styled from "styled-components";
import { COLOR } from "../../../constants/theme";

export const FormInFrameSD = styled.form`
  padding: 24px 34px 34px;

  background-color: ${COLOR.BG_SECONDARY};
  border: 1px solid ${COLOR.BG_SECONDARY};
  border-radius: 2px;
`;

export const FormInFrameHeaderSD = styled.div`
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid ${COLOR.BORDER};
`;

export const FormInFrameBodySD = styled.div``;

export const FormInFrameFooter = styled.div`
  margin-top: 32px;

  & > * + * {
    margin-top: 15px;
  }
`;
