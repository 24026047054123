import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { RouteLoginWrapFormSD, RouteLoginSD } from "./styled";
import { COLOR } from "../../constants/theme";
import TypographyKit from "../../components/kit/Typography/TypographyKit";
import FormFieldKit from "../../components/kit/Fields/FormField/FormField";
import ButtonKit from "../../components/kit/Button/ButtonKit";
import {
  loginAction,
  setLoggedInAction,
  ipAddressAction
} from "../../store/user/actions";
import FormInFrame from "../../components/presentation/FormInFrame/FormInFrame";
import { makeFingerprint } from "../../helpers/fingerprint";
import Dialog from "./Dialog/Dialog";
import * as queryString from "query-string";

class RouteLogin extends Component {
  static propTypes = {
    loginAction: PropTypes.func.isRequired,
    setLoggedInAction: PropTypes.func.isRequired,
    user: PropTypes.object
  };

  state = {
    email: "",
    password: "",
    errorEmail: "",
    errorPass: "",
    generalError: "",
    shouldRedirectToHomePage: false,
    fingerprint: null,
    loading: false,
    isDialogOpen: false
  };

  componentDidMount() {
    makeFingerprint().then(fingerprint => {
      const values = queryString.parse(this.props.location.search);
      const key = values.key;

      if (key) {
        this.props.ipAddressAction({ key, fingerprint });
      }
      this.setState({ fingerprint });
    });
  }

  isEmailValid = () => {
    const pattern = /\b[\w.-]+@[\w.-]+\.\w{2,6}\b/gi;
    return pattern.test(this.state.email);
  };
  isPassValid = () => {
    const { password } = this.state;

    return password.length >= 8;
  };

  isSubmitDisabled = () => {
    if (!this.isEmailValid()) {
      return true;
    }

    return !this.isPassValid() || !this.state.fingerprint;
  };

  handleChangeEmail = email =>
    this.setState({ email, errorEmail: "", generalError: "" });
  handleChangePass = password =>
    this.setState({ password, errorPass: "", generalError: "" });
  handleBlurEmail = () => {
    if (!this.isEmailValid()) {
      this.setState({ errorEmail: "Enter email address" });
    }
  };
  handleBlurPass = () => {
    if (!this.isPassValid()) {
      this.setState({
        errorPass: "Password length must be 8 character minimum"
      });
    }
  };

  handleSubmit = () => {
    // the form had already valid
    const { email, password, fingerprint } = this.state;
    this.setState({ loading: true });
    this.props
      .loginAction({
        username: email,
        password: password,
        fingerprint
      })
      .then(resp => {
        this.setState({ loading: false });
      })
      .catch(err => {
        const data = (err.response && err.response.data) || {};

        if (data.error) {
          this.setState({
            generalError: data.error,
            loading: false,
            ...(data.error.includes("Invalid IP") && {
              isDialogOpen: true
            })
          });
          return;
        }

        if (data.errors?.length > 0) {
          // this case never happens because the form doesn't send if validate is not passing.
          const mapFieldToErrors = {
            username: "errorEmail",
            password: "errorPass"
          };
          const newState = data.errors.reduce((acc, item) => {
            acc[mapFieldToErrors[item.path]] = item.message;
            return acc;
          }, {});
          this.setState(newState);
          return;
        }

        this.setState({
          generalError: `${err?.response?.status} - ${data?.message ||
            "Unknown"}`,
          errors: {},
          loading: false,
          ...(data?.message.includes("Invalid IP") && {
            isDialogOpen: true
          })
        });
      });
  };

  render() {
    const { user } = this.props;

    const {
      email,
      errorEmail,
      password,
      errorPass,
      loading,
      isDialogOpen
    } = this.state;

    if (user.logged || this.state.shouldRedirectToHomePage) {
      return <Redirect to={"/"} />;
    }

    const bodyJSX = (
      <Fragment>
        <FormFieldKit
          fullWidth
          label={"Email"}
          placeholder={"Enter email address"}
          type="email"
          value={email}
          onChange={this.handleChangeEmail}
          onBlur={this.handleBlurEmail}
          errorText={errorEmail}
        />
        <FormFieldKit
          fullWidth
          label={"Password"}
          placeholder={"Enter password"}
          type="password"
          value={password}
          onChange={this.handleChangePass}
          onBlur={this.handleBlurPass}
          errorText={errorPass}
        />

        {this.state.generalError && (
          <TypographyKit color={COLOR.CHERRY_RED}>
            {this.state.generalError}
          </TypographyKit>
        )}
      </Fragment>
    );
    const footerJSX = (
      <Fragment>
        <ButtonKit
          fullWidth
          type={"submit"}
          preloader={loading}
          disabled={this.isSubmitDisabled()}
        >
          Log In
        </ButtonKit>
      </Fragment>
    );

    return (
      <RouteLoginSD>
        <RouteLoginWrapFormSD>
          <Dialog
            loading={loading}
            isDialogOpen={isDialogOpen}
            handleEmail={() => this.handleEmail()}
            onClose={() =>
              this.setState({ isDialogOpen: false, generalError: "" })
            }
          />
          <FormInFrame
            onSubmit={this.handleSubmit}
            title={`Welcome to Rival Control Center`}
            body={bodyJSX}
            footer={footerJSX}
          />
        </RouteLoginWrapFormSD>
      </RouteLoginSD>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatchToProps = {
  loginAction,
  setLoggedInAction,
  ipAddressAction
};

export default connect(mapStateToProps, mapDispatchToProps)(RouteLogin);
