import React from "react";
import PropTypes from "prop-types";
import AuthorizedLayoutRouting from "./AuthorizedLayoutRouting";
import NotAuthorizedLayoutRouting from "./NotAuthorizedLayoutRouting";

function AppRouting({ user, permissions }) {
  return user.logged ? (
    <AuthorizedLayoutRouting user={user} permissions={permissions} />
  ) : (
    <NotAuthorizedLayoutRouting user={user} />
  );
}

AppRouting.propTypes = {
  user: PropTypes.shape({
    logged: PropTypes.bool,
    info: PropTypes.shape({
      stickyPath: PropTypes.string
    })
  })
};

export default AppRouting;
