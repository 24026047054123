import { safePropValueOr } from "../../helpers/common";

export const getScoreItemForUser = user => {
  const myScoreArr = safePropValueOr(user, "scores.manual", "").split(" - ");
  const myScore = {
    ...(myScoreArr.length > 0 && {
      home: Array.isArray(myScoreArr) && myScoreArr[0],
      away: Array.isArray(myScoreArr) && myScoreArr[1]
    })
  };

  const userDiff = parseInt(myScore.home) - parseInt(myScore.away);
  const outcome =
    userDiff > 0 ? "W" : userDiff < 0 ? "L" : userDiff === 0 ? "T" : "";
  return {
    outcome,
    home: myScore.home,
    away: myScore.away
  };
};
