import React, { Component } from "react";

import { safePropValueOr, snakeToText } from "../../../../helpers/common";
import { TYPE_OPTIONS } from "./constants";
import {
  Container,
  OrganizationGroups,
  InfoText,
  Options,
  Option,
  Icon,
  Title,
  ErrorText
} from "./styled";
import { Row } from "../styled";
import FormFieldKit from "../../../../components/kit/Fields/FormField/FormField";
import RadioButtonKit from "../../../../components/kit/Fields/RadioButton/RadioButtonKit";
import SelectKit from "../../../../components/kit/Fields/Select/SelectKit";
import { COLOR } from "../../../../constants/theme";
import { BRACKET_PREVIEW_OPTIONS } from "../../constants";

export const TOURNAMENT_MODES = [
  {
    value: "game-driven",
    label: "Match driven - default selection for elimination & swiss"
  },
  {
    value: "round-driven",
    label: "Round driven - caution - default selection for free for all"
  }
];
class Type extends Component {
  render() {
    const { formState, onChangeField, organization } = this.props;
    const {
      type,
      registrationStart,
      registrationEnd,
      checkinStart,
      checkinEnd,
      checkinRequired,
      preview,
      readOnly,
      startTime,
      clickedNext,
      errors,
      mode,
      organizationGroupId
    } = formState;

    return (
      <Container>
        {organization?.groups && organization?.groups?.length > 0 && (
          <OrganizationGroups>
            <InfoText>
              Choose the organization hub that will be able to participate in
              this tournament:
            </InfoText>
            <SelectKit
              placeholder={"Organization Wide"}
              value={organizationGroupId}
              onChange={val => {
                onChangeField("organizationGroupId", val);
              }}
              select
              options={[
                { value: null, label: "Organization Wide" },
                ...organization?.groups?.map(group => ({
                  value: group.id,
                  label: group.name
                }))
              ]}
            />
          </OrganizationGroups>
        )}
        <Options>
          {TYPE_OPTIONS.map(o => (
            <Option
              key={o.key}
              selected={type === o.key}
              onClick={() => {
                onChangeField("type", o.key);
              }}
            >
              <Icon src={o.icon} alt="" />
              <Title>{snakeToText(o.key)}</Title>
            </Option>
          ))}
        </Options>
        {type === "scheduled" && (
          <>
            <FormFieldKit
              dateTimePicker
              fullWidth
              required
              label={"Tournament Start Date & Time"}
              onChange={val => {
                onChangeField("startTime", val);
              }}
              value={startTime}
              disabled={type === "on-the-fly" || readOnly}
              errorText={
                clickedNext &&
                (safePropValueOr(errors, "Type.startTime") ||
                  safePropValueOr(errors, "Type.startTimePast"))
              }
            />
            <Row>
              <FormFieldKit
                dateTimePicker
                fullWidth
                label={"Registration Start Time (optional)"}
                description={"Defaults to opening registration immediately"}
                onChange={val => onChangeField("registrationStart", val)}
                value={registrationStart}
                disabled={readOnly}
                errorText={
                  clickedNext && safePropValueOr(errors, "Type.regStartNull")
                }
              />
              <FormFieldKit
                dateTimePicker
                fullWidth
                label={"Registration End Time (optional)"}
                description={"Defaults to Tournament Start Time"}
                onChange={val => onChangeField("registrationEnd", val)}
                value={registrationEnd}
                disabled={readOnly}
                errorText={
                  clickedNext &&
                  (safePropValueOr(errors, "Type.regEndNull") ||
                    safePropValueOr(errors, "Type.regEndBefore") ||
                    safePropValueOr(errors, "Type.startTimeReg"))
                }
              />
            </Row>
            <Row>
              <FormFieldKit
                label={"Bracket Preview"}
                description={
                  <>
                    A preview of the tournament bracket will be displayed on the
                    tournament page.
                  </>
                }
                fullWidth
                multiline
                select
                disabled={readOnly}
                selectOptions={BRACKET_PREVIEW_OPTIONS}
                value={safePropValueOr(
                  BRACKET_PREVIEW_OPTIONS.find(o => o.value === preview),
                  "label",
                  undefined
                )}
                onChange={val => {
                  onChangeField("preview", val);
                }}
                placeholder={
                  preview === undefined
                    ? "Select bracket preview type"
                    : undefined
                }
                errorText={
                  clickedNext && safePropValueOr(errors, "Info.system")
                }
              />
            </Row>
            <Row style={{ margin: "7px 0 3px" }}>Check-In</Row>
            <Row
              style={{
                fontSize: "14px",
                marginBottom: "5px",
                ...(clickedNext &&
                  !!safePropValueOr(errors, "Type.checkinRequired") && {
                    color: COLOR.CHERRY_RED
                  })
              }}
            >
              Check-In Enforced & Required To Participate In Tournament?
              <span style={{ color: COLOR.RIVAL_RED, fontWeight: "bold" }}>
                *
              </span>
            </Row>
            <Row style={{ marginBottom: "10px" }}>
              <RadioButtonKit
                label={"Yes"}
                horizontalLabel
                onChange={() => onChangeField("checkinRequired", true)}
                checked={checkinRequired}
                fullWidth
              />
              <RadioButtonKit
                label={"No"}
                horizontalLabel
                onChange={() => onChangeField("checkinRequired", false)}
                checked={checkinRequired === false}
                fullWidth
              />
            </Row>
            {clickedNext && safePropValueOr(errors, "Type.checkinRequired") && (
              <ErrorText>{errors.Type.checkinRequired}</ErrorText>
            )}
            <Row>
              <FormFieldKit
                dateTimePicker
                fullWidth
                required={checkinRequired}
                label={`Check-In Start Time ${
                  checkinRequired ? "" : "(optional)"
                }`}
                onChange={val => onChangeField("checkinStart", val)}
                value={checkinStart}
                disabled={readOnly}
                errorText={
                  clickedNext &&
                  (safePropValueOr(errors, "Type.checkStartNull") ||
                    safePropValueOr(errors, "Type.checkStartBefore"))
                }
              />
              <FormFieldKit
                dateTimePicker
                fullWidth
                required={checkinRequired}
                label={`Check-In End Time ${
                  checkinRequired ? "" : "(optional)"
                }`}
                onChange={val => onChangeField("checkinEnd", val)}
                value={checkinEnd}
                disabled={readOnly}
                errorText={
                  clickedNext &&
                  (safePropValueOr(errors, "Type.checkEndNull") ||
                    safePropValueOr(errors, "Type.checkEndBefore") ||
                    safePropValueOr(errors, "Type.checkEndBeforeReg") ||
                    safePropValueOr(errors, "Type.startTimeCheck"))
                }
              />
            </Row>

            <Row style={{ margin: "7px 0 3px" }}>Tournament Mode</Row>
            <FormFieldKit
              label={""}
              description={
                <>
                  The mode determines how players advance through the bracket
                  and when their next match(s) become available.
                  <br />
                  1. Match Driven - Elimination & Swiss - When a player's
                  opponent is available, the match starts regardless of round,
                  allows for playing ahead when possible.
                  <br />
                  2. Round Driven - Caution - Free For All (FFA) - Players must
                  wait to play their next round match until all matches in a
                  round are complete, even if their next opponent is available.
                </>
              }
              fullWidth
              multiline
              required
              select
              disabled={readOnly}
              selectOptions={TOURNAMENT_MODES}
              value={safePropValueOr(
                TOURNAMENT_MODES.find(tm => tm.value === mode),
                "label",
                undefined
              )}
              onChange={val => {
                onChangeField("mode", val);
              }}
              placeholder={
                mode === undefined ? "Select tourament mode" : undefined
              }
              errorText={clickedNext && safePropValueOr(errors, "Info.system")}
            />
          </>
        )}
      </Container>
    );
  }
}

export default Type;
