import React, { Component, useState } from "react";
import { connect } from "react-redux";
import {
  Content,
  Container,
  Item,
  LeaguesContainer,
  LeaguesHeader,
  WrapAddButton,
  AddLeaguesButton,
  WrapLink,
  Image,
  Title,
  Info,
  Players,
  Description,
  WrapFilter,
  WrapSearch,
  Seasons,
  Label,
  Labels,
  WrapImages
} from "./styled";
import { withRouter } from "react-router-dom";
import routes from "./routes";
import { Route, Switch } from "react-router-dom";
import { isObjectEmpty, verifyPermission } from "../../helpers/common";
import { fetchLeagues } from "../../store/leagues/actions";

// Icons
import IconCirclePlus from "../../components/kit/Icon/IconCirclePlus";
import Filter from "../../components/Filter";
import Search from "../../components/Search";
import crossPlatform from "../../static/images/cross-platform.svg";

const Leagues = ({ leagues }) => {
  const [filter, setFilter] = useState({
    upcoming: true,
    inProgress: true,
    completed: true
  });
  const [search, setSearch] = useState("");

  const filteredLeagues = leagues.filter(league => {
    return (
      (Object.values(filter).every(v => !v) ||
        (filter.upcoming && league.status === "upcoming") ||
        (filter.inProgress && league.status === "inProgress") ||
        (filter.completed && league.status === "completed")) &&
      (league?.name?.toLowerCase()?.includes(search) ||
        search?.includes(league?.name?.toLowerCase()))
    );
  });

  return (
    <LeaguesContainer>
      <LeaguesHeader>
        <WrapFilter>
          <Filter
            items={["upcoming", "inProgress", "completed"]}
            filter={filter}
            onChangeFilter={val => {
              const newFilter = { ...filter, [val]: !filter[val] };
              if (!isObjectEmpty(newFilter)) {
                setFilter(newFilter);
              }
            }}
          />
        </WrapFilter>
        <WrapAddButton>
          <AddLeaguesButton to={`leagues/create`}>
            <IconCirclePlus width={40} />
          </AddLeaguesButton>
        </WrapAddButton>
      </LeaguesHeader>
      <WrapSearch>
        <Search
          search={search}
          onChangeSearch={val => setSearch((val || "").toLowerCase())}
          placeholder={"Search for leagues..."}
          width={"100%"}
        />
      </WrapSearch>
      {filteredLeagues.map(league => {
        let upcoming = 0,
          inProgress = 0,
          completed = 0;
        (league?.seasons || []).forEach(s => {
          if (s?.status === "initialized") upcoming++;
          if (s?.status === "in progress") inProgress++;
          if (s?.status === "complete") completed++;
        });
        return (
          <WrapLink
            to={`/leagues/${league.slug}`}
            key={league?.name + league?.id}
          >
            <Item image={league?.game?.imageInfo?.background}>
              <Image
                src={league?.imageInfo?.image || league?.game?.imageInfo?.event}
              />
              <Info>
                <Title>
                  {league?.name}{" "}
                  <Players>{league?.playerCount} Followers</Players>
                </Title>
                <Description>{league?.description}</Description>
                <Seasons>
                  Seasons
                  <Labels>
                    <Label>{upcoming} Upcoming</Label>
                    {" | "}
                    <Label>{inProgress} In Progress</Label>
                    {" | "}
                    <Label>{completed} Completed</Label>
                  </Labels>
                </Seasons>
              </Info>
              <WrapImages>
                <img src={league?.game?.imageInfo?.title} alt="" />
                <img
                  src={league?.console?.imageInfo?.thumbnail || crossPlatform}
                  alt=""
                />
              </WrapImages>
            </Item>
          </WrapLink>
        );
      })}
    </LeaguesContainer>
  );
};

class RouteLeague extends Component {
  componentDidMount() {
    this.props.fetchLeagues();
  }
  renderContent = () => (
    <Switch>
      {routes.map(route => (
        <Route
          exact
          key={route.path}
          path={route.path}
          render={props => {
            const ContentComponent = route.contentComponent;
            return (
              <ContentComponent
                {...props}
                user={this.props.user}
                permission={verifyPermission(
                  route.path,
                  this.props.permissions
                )}
                {...(route.isUpdate && { isUpdate: true })}
              />
            );
          }}
        />
      ))}
      <Route render={() => <Leagues leagues={this.props.leagues} />} />
    </Switch>
  );

  render() {
    return (
      <Container>
        <Content>{this.renderContent()}</Content>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.info,
  permissions: state.permissionsState.permissions,
  leagues: state.leaguesState.leagues
});
const mapDispatchToProps = {
  fetchLeagues
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RouteLeague));
