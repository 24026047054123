import React, { Component } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import {
  addEmail,
  createDraftsEmail,
  createScheduledEmail,
  deleteEmail
} from "../../../store/communication/actions";
import EmailForm from "../EmailForm/EmailForm";
import { getGlobalUser } from "../../../store/user/selectors";
import EmailDialogSuccess from "../../../components/dialogs/EmailDialog/EmailDialogSuccess";
import EmailDialog from "../../../components/dialogs/EmailDialog/EmailDialog";
import { dateToSting } from "../../../helpers/date";
import { getAllRecipients, verifyPermission } from "../../../helpers/common";

class CommunicationCreate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: null,
      to: [],
      isEmpty: false,
      isScheduling: false,
      isScheduledDialogOpen: false,
      isSentDialogOpen: false,
      isCreatingDraftDialogOpen: false,
      isDiscardDialogOpen: false,
      isConfirmDialogOpen: false,
      isCreatingDraft: false,
      sendAt: null
    };
  }

  setSendAtDate = date => {
    this.setState({
      sendAt: date
    });
  };

  handleSchedule = async email => {
    this.setState({ isScheduling: true });
    try {
      await this.props.createScheduledEmail(email);
      this.setState({
        isScheduling: false,
        isScheduledDialogOpen: true
      });
    } catch (err) {
      this.setState({ isScheduling: false });
    }
  };

  handleCreateDraft = async email => {
    this.setState({ isCreatingDraft: true });
    try {
      await this.props.createDraftsEmail(email);
      this.setState({
        isCreatingDraft: false,
        isCreatingDraftDialogOpen: true
      });
    } catch (err) {
      this.setState({ isCreatingDraft: false });
    }
  };

  handleDiscard = () => {
    this.setState({
      isDiscardDialogOpen: true,
      isEmpty: false
    });
    this.props.deleteEmail();
  };

  onConfirm = async () => {
    this.setState({ isScheduling: true });
    try {
      await this.props.createScheduledEmail({
        ...this.state.email,
        sendAt: dateToSting(Date.now(), "YYYY-MM-DD HH:mm:ssZZ")
      });
      this.setState({
        isScheduling: false,
        isConfirmDialogOpen: false,
        isSentDialogOpen: true
      });
    } catch (err) {
      this.setState({ isScheduling: false });
    }
  };

  handleConfirmSend = async (email, to) => {
    this.setState({
      isConfirmDialogOpen: true,
      email,
      to
    });
  };

  handleRedirectToCreateGlobalTemplate = email => {
    const { history, addEmail } = this.props;
    addEmail(email);
    history.push({
      pathname: "/communication/global_templates/create",
      state: {
        saveEmail: true
      }
    });
  };

  handleRedirectToCreateTemplate = email => {
    const { history, addEmail } = this.props;
    addEmail(email);
    history.push({
      pathname: "/communication/templates/create",
      state: {
        saveEmail: true
      }
    });
  };

  onDiscard = () => {
    this.setState({
      isEmpty: true,
      isDiscardDialogOpen: false
    });
  };

  renderScheduledDialog = () => {
    const { isScheduledDialogOpen, sendAt } = this.state;
    const { history } = this.props;

    return (
      <EmailDialogSuccess
        title={"EMAIL SCHEDULED"}
        text={"This email will be sent:"}
        date={sendAt || new Date().getTime()}
        isOpen={isScheduledDialogOpen}
        onClose={() => this.setState({ isScheduledDialogOpen: false })}
        onConfirm={() => history.push("/communication/scheduled")}
        confirmText={"SEE SCHEDULED"}
      />
    );
  };

  renderSentDialog = () => {
    const { isSentDialogOpen, sendAt } = this.state;

    return (
      <EmailDialogSuccess
        title={"EMAIL SENT"}
        text={"This email will be sent:"}
        date={sendAt || new Date().getTime()}
        isOpen={isSentDialogOpen}
        onClose={() => this.setState({ isSentDialogOpen: false })}
        onConfirm={() => this.setState({ isSentDialogOpen: false })}
        confirmText={"Close"}
      />
    );
  };

  renderConfirmSentDialog = () => {
    const { isConfirmDialogOpen, isScheduling, to } = this.state;
    const recipients = getAllRecipients(to).join(", ") || `Not Selected`;

    return (
      <EmailDialog
        title={"SEND EMAIL"}
        text={`Are you sure ? An email will be immediately sent to: \n ${recipients}`}
        isOpen={isConfirmDialogOpen}
        onClose={() =>
          this.setState({
            isConfirmDialogOpen: false,
            email: null
          })
        }
        onConfirm={this.onConfirm}
        confirmPreloader={isScheduling}
      />
    );
  };

  renderCreatingDraftDialog = () => {
    const { isCreatingDraftDialogOpen } = this.state;
    const { history } = this.props;

    return (
      <EmailDialogSuccess
        title={"DRAFT CREATED"}
        text={"Your draft has been created for:"}
        date={new Date().getTime()}
        isOpen={isCreatingDraftDialogOpen}
        onClose={() => this.setState({ isCreatingDraftDialogOpen: false })}
        onConfirm={() => history.push("/communication/drafts")}
        confirmText={"SEE DRAFTS"}
      />
    );
  };

  renderDiscardDialog = () => {
    const { isDiscardDialogOpen } = this.state;

    return (
      <EmailDialog
        title={"DISCARD EMAIL"}
        text={"Are you sure you want to delete this email?"}
        isOpen={isDiscardDialogOpen}
        onClose={() => this.setState({ isDiscardDialogOpen: false })}
        onConfirm={this.onDiscard}
      />
    );
  };

  render() {
    const { isScheduling, isCreatingDraft, isEmpty } = this.state;

    const { user, handleSetImage, email, addEmail, deleteEmail } = this.props;
    const globalTemplateAccessible = verifyPermission(
      "/communication/global_templates",
      this.props.permissions
    );

    return (
      <>
        <EmailForm
          email={email}
          user={user}
          isEmpty={isEmpty}
          handleSetImage={handleSetImage}
          handleSchedule={this.handleSchedule}
          handleCreateDraft={this.handleCreateDraft}
          handleConfirmSend={this.handleConfirmSend}
          handleDiscard={this.handleDiscard}
          handleRedirectToCreateTemplate={this.handleRedirectToCreateTemplate}
          handleRedirectToCreateGlobalTemplate={
            globalTemplateAccessible
              ? this.handleRedirectToCreateGlobalTemplate
              : undefined
          }
          isScheduling={isScheduling}
          isCreatingDraft={isCreatingDraft}
          addEmail={addEmail}
          deleteEmail={deleteEmail}
          setSendAtDate={this.setSendAtDate}
        />
        {this.renderScheduledDialog()}
        {this.renderSentDialog()}
        {this.renderCreatingDraftDialog()}
        {this.renderDiscardDialog()}
        {this.renderConfirmSentDialog()}
      </>
    );
  }
}

CommunicationCreate.propTypes = {
  user: PropTypes.object,
  email: PropTypes.object,
  createScheduledEmail: PropTypes.func.isRequired,
  createDraftsEmail: PropTypes.func.isRequired,
  deleteEmail: PropTypes.func.isRequired,
  addEmail: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  user: getGlobalUser(state),
  email: state.communication.email
});

const mapDispatchToProps = {
  addEmail,
  deleteEmail,
  createScheduledEmail,
  createDraftsEmail
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(CommunicationCreate);
