import React from "react";
import {
  DialogContainer,
  DialogHeader,
  DialogTitle,
  PlayerSelection,
  SelectionText,
  PlayerRow,
  PlayerProfile,
  Buttons,
  Success,
  Text,
  EntryOptions,
  WrapPhoto,
  Photo,
  Image,
  WrapImageList,
  ImageList,
  WrapImageItem,
  ImageItem,
  Message
} from "./styled";
import {
  safePropValueOr,
  propValueOr,
  getUsername
} from "../../../helpers/common";
import DialogKit from "../../../components/kit/Dialog/DialogKit";
import ButtonKit from "../../../components/kit/Button/ButtonKit";
import placeholderUserImg from "../../../static/images/default-user.jpg";
import { api } from "../../../index";
import iconCheck from "../../../static/icons/circle-check-green.svg";
import iconX from "../../../static/icons/circle-x-red.svg";
import { fetchGameAction } from "../../../store/game/actions";
import { connect } from "react-redux";

class VerifyDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      error: "",
      scoreSelect: 0,
      manualScore: 0,
      newScore: null,
      activeEntryIndex: 0,
      message: ""
    };
  }

  handleNewScoreChange(val) {
    var n = Math.floor(Number(val));
    if (n !== Infinity && String(n) === val && n >= 0) {
      this.setState({
        newScore: val,
        manualScore: n
      });
    }
  }

  handleVerify = () => {
    if (this.props.player) {
      let updateObj = {
        userId: this.props.player.id,
        verified: !this.props.player.verifiedAt
      };
      api.admin.matches
        .update(this.props.game.token, updateObj)
        .then(resp => {
          this.setState({ success: true });
          this.props.fetchGameAction(this.props.game.token);
        })
        .catch(err =>
          this.setState({
            error: propValueOr(
              err,
              "response.data.message",
              err.message || "Unknown error. Please Try again later."
            )
          })
        );
    }
  };

  renderSuccess = () => {
    const { error } = this.state;
    return (
      <DialogContainer>
        <DialogHeader>
          <DialogTitle>
            {error ? "Verify Score Failed" : "Success!"}
          </DialogTitle>
        </DialogHeader>
        <Success>
          <img
            src={error ? iconX : iconCheck}
            width={65}
            height={65}
            alt="check"
          />
          <Text>{error || "The user's score has been verified."}</Text>
        </Success>
        <Buttons>
          <ButtonKit
            fullWidth
            onClick={() =>
              error
                ? this.setState({
                    error: null,
                    success: false
                  })
                : this.handleClose()
            }
            color={error ? "primary" : "green"}
          >
            {error ? "Try Again" : "Okay"}
          </ButtonKit>
        </Buttons>
      </DialogContainer>
    );
  };

  handleClose = () => {
    const { onClose } = this.props;

    this.setState(
      {
        success: false,
        error: "",
        scoreSelect: 0,
        manualScore: 0,
        newScore: null,
        activeEntryIndex: 0,
        message: ""
      },
      onClose
    );
  };

  renderDialog = () => {
    const { player } = this.props;
    const { activeEntryIndex } = this.state;
    if (!player) {
      return;
    }
    const verificationEntries = propValueOr(player, "verificationEntries", []);
    let activeEntry = {};
    if (activeEntryIndex < verificationEntries.length)
      activeEntry = verificationEntries[activeEntryIndex];
    return (
      <DialogContainer>
        <DialogHeader>
          <DialogTitle>Verify Player's Score</DialogTitle>
        </DialogHeader>
        <PlayerSelection>
          <SelectionText>
            Verify whether player's submitted score is correct
          </SelectionText>
        </PlayerSelection>
        <EntryOptions>
          <PlayerRow>
            <PlayerProfile
              src={
                player.team
                  ? propValueOr(
                      player,
                      "team.imageInfo.thumbnail",
                      placeholderUserImg
                    )
                  : propValueOr(
                      player,
                      "imageInfo.thumbnail",
                      placeholderUserImg
                    )
              }
              onError={e => (e.target.src = placeholderUserImg)}
            />
            {propValueOr(player, "team.name", getUsername(player))}
            {" - "}
            {propValueOr(player, "scores.manual", "Pending...")}
          </PlayerRow>
          <Image>
            {!safePropValueOr(activeEntry, "image", "") ? (
              "NO PHOTO UPLOADED"
            ) : (
              <WrapPhoto>
                <Photo
                  src={safePropValueOr(activeEntry, "image")}
                  onClick={() =>
                    this.setState({
                      image: safePropValueOr(activeEntry, "image")
                    })
                  }
                />
              </WrapPhoto>
            )}
          </Image>
          <Message>
            <Text>
              {safePropValueOr(activeEntry, "message", "No Message Submitted")}
            </Text>
          </Message>
          {verificationEntries.length > 1 && (
            <WrapImageList>
              <ImageList>
                {verificationEntries.map((entry, entryIndex) => {
                  return (
                    <WrapImageItem active={entryIndex === activeEntryIndex}>
                      <ImageItem
                        src={safePropValueOr(entry, "image")}
                        onClick={() =>
                          this.setState({ activeEntryIndex: entryIndex })
                        }
                      />
                    </WrapImageItem>
                  );
                })}
              </ImageList>
            </WrapImageList>
          )}
        </EntryOptions>
        <Buttons>
          <ButtonKit
            disabled={!player}
            onClick={this.handleVerify}
            fullWidth
            color={"blue"}
          >
            Verify
          </ButtonKit>
          <ButtonKit
            fullWidth
            appearance={"secondary"}
            color={"red"}
            onClick={this.handleClose}
          >
            Cancel
          </ButtonKit>
        </Buttons>
      </DialogContainer>
    );
  };

  render() {
    const { isOpen } = this.props;
    return (
      <DialogKit
        isOpen={isOpen}
        onClose={this.handleClose}
        renderCustom={
          this.state.success || this.state.error
            ? this.renderSuccess
            : this.renderDialog
        }
        displayButtonClose={false}
      />
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  fetchGameAction
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyDialog);
