import { ASSETS_ACTIONS } from "./constants";

const initialState = {
  assets: [],
  error: null
};

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case ASSETS_ACTIONS.fetch.request:
      return {
        ...state,
        error: null
      };
    case ASSETS_ACTIONS.fetch.success:
      return {
        ...state,
        assets: action.payload
      };
    case ASSETS_ACTIONS.fetch.error:
      return {
        ...state,
        assets: [],
        error: action.payload
      };

    case ASSETS_ACTIONS.reset:
      return initialState;
    default:
      return state;
  }
};

export default reducers;
