import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PermissionDenied from "../denied/PermissionDenied";
import { getGlobalUser } from "../../store/user/selectors";
import RouteCommunication from "./RouteCommunication";

class RouteCommunicationContainer extends Component {
  render() {
    const { permission } = this.props;
    if (!permission) {
      return <PermissionDenied />;
    }
    return <RouteCommunication />;
  }
}

const mapStateProps = state => ({
  user: getGlobalUser(state)
});

export default connect(mapStateProps)(withRouter(RouteCommunicationContainer));
