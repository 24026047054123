import React from "react";
import { ListItem, ItemText, Children } from "./styled";
import {
  TableItemsAvatarSD,
  TableItemsWrapAvatarSD
} from "../../../../components/presentation/Table/TableItems/styled";
import { getUsername, propValueOr } from "../../../../helpers/common";
import placeholderUserImg from "../../../../static/images/default-user.jpg";

const PlayerListItem = ({ rowData, onClick, hover, children }) => {
  return (
    <ListItem onClick={onClick} hover={!!hover && hover}>
      <TableItemsWrapAvatarSD>
        <TableItemsAvatarSD
          src={propValueOr(rowData, "imageInfo.thumbnail", placeholderUserImg)}
          alt="avatar"
          onError={e => {
            e.target.src = placeholderUserImg;
          }}
        />
      </TableItemsWrapAvatarSD>
      <ItemText>{rowData.name || getUsername(rowData)}</ItemText>
      <Children>{children}</Children>
    </ListItem>
  );
};

export default PlayerListItem;
