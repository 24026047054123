import React from "react";
import { InfoContainer, Status, Badge } from "./styled";

export const TournamentItem = ({ tournament }) => {
  return (
    <InfoContainer>
      <Status>
        {tournament && <Badge>{`TOKEN: ${tournament.token}`}</Badge>}
        {tournament && <Badge>{`ID: ${tournament.id}`}</Badge>}
      </Status>
    </InfoContainer>
  );
};

export default TournamentItem;
