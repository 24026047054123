import React from "react";
import {
  DialogContainer,
  DialogHeader,
  DialogTitle,
  PlayerSelection,
  SelectionText,
  PlayerRow,
  PlayerProfile,
  Buttons,
  Success,
  Text,
  EntryOptions,
  WrapPhoto,
  Photo,
  Image,
  WrapImageList,
  ImageList,
  WrapImageItem,
  ImageItem,
  Message,
  VsFields,
  WrapVsField,
  PlayerScore,
  Fields
} from "./styled";
import {
  safePropValueOr,
  propValueOr,
  getUsername
} from "../../../helpers/common";
import DialogKit from "../../../components/kit/Dialog/DialogKit";
import ButtonKit from "../../../components/kit/Button/ButtonKit";
import FormField from "../../../components/kit/Fields/FormField/FormField";
import placeholderUserImg from "../../../static/images/default-user.jpg";
import { api } from "../../../index";
import iconCheck from "../../../static/icons/circle-check-green.svg";
import iconX from "../../../static/icons/circle-x-red.svg";
import { fetchGameAction } from "../../../store/game/actions";
import { connect } from "react-redux";

class EditScoreDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      error: "",
      scoreSelect: 0,
      newScore: null,
      newAwayScore: null,
      activeEntryIndex: 0,
      message: ""
    };
  }

  handleNewScoreChange(val, prop) {
    var n = Math.floor(Number(val));
    if (!isNaN(n) && n >>> 0 === parseFloat(n) && n < 1000) {
      this.setState({
        [prop]: val
      });
    }
  }

  handleSubmit = () => {
    const { player, game } = this.props;
    const { newScore, newAwayScore, message } = this.state;
    if (player && newScore) {
      let submitScore =
        propValueOr(game, "users", []).length > 1
          ? `${newScore} - ${newAwayScore}`
          : newScore;
      let updateObj = {
        userId: player.id,
        score: submitScore,
        message: !!message ? message : null
      };
      if (propValueOr(game, "users", []).length === 1)
        updateObj.matchType = "ffa";
      api.admin.matches
        .update(this.props.game.token, updateObj)
        .then(resp => {
          this.setState({ success: true });
          this.props.fetchGameAction(this.props.game.token);
        })
        .catch(err =>
          this.setState({
            error: propValueOr(
              err,
              "response.data.message",
              err.message || "Unknown error. Please Try again later."
            )
          })
        );
    }
  };

  renderSuccess = () => {
    const { error } = this.state;
    return (
      <DialogContainer>
        <DialogHeader>
          <DialogTitle>
            {error ? "Verify Score Failed" : "Success!"}
          </DialogTitle>
        </DialogHeader>
        <Success>
          <img
            src={error ? iconX : iconCheck}
            width={65}
            height={65}
            alt="check"
          />
          <Text>{error || "The user's score has been verified."}</Text>
        </Success>
        <Buttons>
          <ButtonKit
            fullWidth
            onClick={() =>
              error
                ? this.setState({
                    error: null,
                    success: false
                  })
                : this.handleClose()
            }
            color={error ? "primary" : "green"}
          >
            {error ? "Try Again" : "Okay"}
          </ButtonKit>
        </Buttons>
      </DialogContainer>
    );
  };

  handleClose = () => {
    const { onClose } = this.props;

    this.setState(
      {
        success: false,
        error: "",
        newScore: "",
        activeEntryIndex: 0,
        message: ""
      },
      onClose
    );
  };

  renderDialog = () => {
    const { player, game } = this.props;
    const { activeEntryIndex, newScore, newAwayScore, message } = this.state;
    if (!player || !game) {
      return;
    }
    const users = propValueOr(game, "users");
    const verificationEntries = propValueOr(player, "verificationEntries", []);
    let activeEntry = {};
    if (activeEntryIndex < verificationEntries.length)
      activeEntry = verificationEntries[activeEntryIndex];
    return (
      <DialogContainer>
        <DialogHeader>
          <DialogTitle>Edit Player's Score</DialogTitle>
        </DialogHeader>
        <PlayerSelection>
          <SelectionText>Change player's submitted score</SelectionText>
        </PlayerSelection>
        <EntryOptions>
          <PlayerRow>
            <PlayerProfile
              src={
                player.team
                  ? propValueOr(
                      player,
                      "team.imageInfo.thumbnail",
                      placeholderUserImg
                    )
                  : propValueOr(
                      player,
                      "imageInfo.thumbnail",
                      placeholderUserImg
                    )
              }
              onError={e => (e.target.src = placeholderUserImg)}
            />
            {propValueOr(player, "team.name", getUsername(player))}
            {" - "}
            {propValueOr(player, "scores.manual", "Pending...")}
          </PlayerRow>
          <Image>
            {!safePropValueOr(activeEntry, "image", "") ? (
              "NO PHOTO UPLOADED"
            ) : (
              <WrapPhoto>
                <Photo
                  src={safePropValueOr(activeEntry, "image")}
                  onClick={() =>
                    this.setState({
                      image: safePropValueOr(activeEntry, "image")
                    })
                  }
                />
              </WrapPhoto>
            )}
          </Image>
          <Message>
            <Text>
              {safePropValueOr(activeEntry, "message", "No Message Submitted")}
            </Text>
          </Message>
          {verificationEntries.length > 1 && (
            <WrapImageList>
              <ImageList>
                {verificationEntries.map((entry, entryIndex) => {
                  return (
                    <WrapImageItem active={entryIndex === activeEntryIndex}>
                      <ImageItem
                        src={safePropValueOr(entry, "image")}
                        onClick={() =>
                          this.setState({ activeEntryIndex: entryIndex })
                        }
                      />
                    </WrapImageItem>
                  );
                })}
              </ImageList>
            </WrapImageList>
          )}
          <Fields>
            <>
              {users.length <= 1 ? (
                <FormField
                  placeholder={"Submit new score"}
                  value={newScore}
                  gutterBottom={false}
                  onChange={val => this.handleNewScoreChange(val, "newScore")}
                />
              ) : (
                <VsFields>
                  <PlayerScore>
                    <PlayerRow>
                      <PlayerProfile
                        src={
                          users[0].team
                            ? propValueOr(
                                users[0],
                                "team.imageInfo.thumbnail",
                                placeholderUserImg
                              )
                            : propValueOr(
                                users[0],
                                "imageInfo.thumbnail",
                                placeholderUserImg
                              )
                        }
                        onError={e => (e.target.src = placeholderUserImg)}
                      />
                      <Text>
                        {propValueOr(
                          users[0],
                          "team.name",
                          getUsername(users[0])
                        )}
                      </Text>
                    </PlayerRow>
                    <WrapVsField>
                      <FormField
                        placeholder={"Home Score"}
                        value={newScore}
                        gutterBottom={false}
                        onChange={val =>
                          this.handleNewScoreChange(val, "newScore")
                        }
                      />
                    </WrapVsField>
                  </PlayerScore>
                  <PlayerScore>
                    <PlayerRow away>
                      <PlayerProfile
                        src={
                          users[1].team
                            ? propValueOr(
                                users[1],
                                "team.imageInfo.thumbnail",
                                placeholderUserImg
                              )
                            : propValueOr(
                                users[1],
                                "imageInfo.thumbnail",
                                placeholderUserImg
                              )
                        }
                        onError={e => (e.target.src = placeholderUserImg)}
                      />
                      <Text>
                        {propValueOr(
                          users[1],
                          "team.name",
                          getUsername(users[1])
                        )}
                      </Text>
                    </PlayerRow>
                    <WrapVsField>
                      <FormField
                        placeholder={"Away score"}
                        value={newAwayScore}
                        gutterBottom={false}
                        onChange={val =>
                          this.handleNewScoreChange(val, "newAwayScore")
                        }
                      />
                    </WrapVsField>
                  </PlayerScore>
                </VsFields>
              )}
            </>
            <FormField
              placeholder={"Reason for new score"}
              multiline={2}
              gutterBottom={false}
              value={message}
              onChange={val => this.setState({ message: val })}
            />
          </Fields>
        </EntryOptions>
        <Buttons>
          <ButtonKit
            onClick={this.handleSubmit}
            disabled={!this.state.newScore}
            fullWidth
            color={"blue"}
          >
            Submit New Score
          </ButtonKit>
          <ButtonKit
            fullWidth
            appearance={"secondary"}
            color={"red"}
            onClick={this.handleClose}
          >
            Cancel
          </ButtonKit>
        </Buttons>
      </DialogContainer>
    );
  };

  render() {
    const { isOpen } = this.props;
    return (
      <DialogKit
        isOpen={isOpen}
        onClose={this.handleClose}
        renderCustom={
          this.state.success || this.state.error
            ? this.renderSuccess
            : this.renderDialog
        }
        displayButtonClose={false}
      />
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  fetchGameAction
};

export default connect(mapStateToProps, mapDispatchToProps)(EditScoreDialog);
