import React, { Component } from "react";
import { Container, Content, Button } from "./styled";
import Loader from "../../../components/presentation/Loader/Loader";
import { api } from "../../..";
import { propValueOr } from "../../../helpers/common";
import { CSVLink } from "react-csv";
import moment from "moment";
import PermissionDenied from "../../denied/PermissionDenied";

class Membership extends Component {
  constructor(props) {
    super();

    this.state = {
      members: [],
      loading: true,
      error: null
    };
  }
  componentDidMount() {
    const { permission } = this.props;
    if (["admin", "write"].includes(permission)) {
      api.admin.organizations
        .report(propValueOr(this.props, "user.info.organizationId"))
        .then(resp => {
          this.setState({
            members: propValueOr(resp, "data", []),
            loading: false
          });
        })
        .catch(e =>
          this.setState({
            error: propValueOr(e, "response.message"),
            loading: false
          })
        );
    }
  }

  render() {
    const { members, loading } = this.state;
    const { slug, permission } = this.props;
    if (!permission) {
      return <PermissionDenied />;
    }
    return (
      <Container>
        <Content>
          {loading ? (
            <Loader isBlock />
          ) : (
            <>
              <CSVLink
                filename={`membership-${slug}-${moment().format(
                  "MM-DD-YY"
                )}.csv`}
                data={members}
              >
                <Button fullWidth small appearance={"secondary"}>
                  Download Community Membership List
                </Button>
              </CSVLink>
            </>
          )}
        </Content>
      </Container>
    );
  }
}

export default Membership;
