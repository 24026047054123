import React, { Component } from "react";
import PropTypes from "prop-types";
import EmailForm from "../EmailForm/EmailForm";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  addEmail,
  createScheduledEmail,
  deleteDraftsEmail,
  deleteEmail,
  updateDraftsEmail
} from "../../../store/communication/actions";
import EmailDialog from "../../../components/dialogs/EmailDialog/EmailDialog";
import EmailDialogSuccess from "../../../components/dialogs/EmailDialog/EmailDialogSuccess";
import { dateToSting } from "../../../helpers/date";
import { getAllRecipients } from "../../../helpers/common";

class CommunicationDraftsEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: null,
      to: [],
      isEmpty: false,
      isUpdatingDraft: false,
      isUpdateDraftDialogOpen: false,
      isDiscarding: false,
      isDiscardDialogOpen: false,
      isConfirmDialogOpen: false,
      isScheduling: false,
      isScheduledDialogOpen: false,
      isSentDialogOpen: false,
      sendAt: null
    };
  }

  componentDidMount() {
    const { emails, match, history } = this.props;

    (!emails || emails.length === 0) && history.push("/communication/drafts");

    if (match.params.id) {
      this.setState({
        email: emails.filter(e => "" + e.id === match.params.id)[0]
      });
    }
  }

  setSendAtDate = date => {
    this.setState({
      sendAt: date
    });
  };

  handleSchedule = async email => {
    this.setState({ isScheduling: true });
    try {
      await this.props.updateDraftsEmail({
        ...email,
        state: "scheduled"
      });
      this.setState({
        isScheduling: false,
        isScheduledDialogOpen: true
      });
    } catch (err) {
      this.setState({ isScheduling: false });
    }
  };

  handleUpdateDraft = async email => {
    this.setState({ isUpdatingDraft: true });
    try {
      await this.props.updateDraftsEmail(email);
      this.setState({
        isUpdatingDraft: false,
        isUpdateDraftDialogOpen: true
      });
    } catch (err) {
      this.setState({ isUpdatingDraft: false });
    }
  };

  handleDiscard = () => {
    this.setState({
      isDiscardDialogOpen: true,
      isEmpty: false
    });
  };

  handleRedirectToCreateTemplate = email => {
    const { history, addEmail } = this.props;
    addEmail(email);
    history.push({
      pathname: "/communication/templates/create",
      state: {
        saveEmail: true
      }
    });
  };

  handleConfirmSend = async (email, to) => {
    this.setState({
      isConfirmDialogOpen: true,
      email,
      to
    });
  };

  onConfirm = async () => {
    this.setState({ isScheduling: true });
    try {
      await this.props.updateDraftsEmail({
        ...this.state.email,
        sendAt: dateToSting(Date.now(), "YYYY-MM-DD HH:mm:ssZZ")
      });
      this.setState({
        isScheduling: false,
        isConfirmDialogOpen: false,
        isSentDialogOpen: true
      });
    } catch (err) {
      this.setState({ isScheduling: false });
    }
  };

  onDiscard = async () => {
    const { email } = this.state;
    const { history } = this.props;

    this.setState({ isDiscarding: true });

    try {
      await this.props.deleteDraftsEmail(email.id);
      this.setState({
        isDiscarding: false,
        isEmpty: true,
        isDiscardDialogOpen: false
      });
      history.push("/communication/drafts");
    } catch (err) {
      this.setState({ isDiscarding: false });
    }
  };

  renderConfirmSentDialog = () => {
    const { isConfirmDialogOpen, isScheduling, to } = this.state;

    const recipients = getAllRecipients(to).join(", ") || `Not Selected`;

    return (
      <EmailDialog
        title={"SEND EMAIL"}
        text={`Are you sure ? An email will be immediately sent to: \n ${recipients}`}
        isOpen={isConfirmDialogOpen}
        onClose={() =>
          this.setState({
            isConfirmDialogOpen: false,
            email: null
          })
        }
        onConfirm={this.onConfirm}
        confirmPreloader={isScheduling}
      />
    );
  };

  renderScheduledDialog = () => {
    const { isScheduledDialogOpen, sendAt } = this.state;
    const { history } = this.props;

    return (
      <EmailDialogSuccess
        title={"EMAIL SCHEDULED"}
        text={"This email will be sent:"}
        date={sendAt || new Date().getTime()}
        isOpen={isScheduledDialogOpen}
        onClose={() => this.setState({ isScheduledDialogOpen: false })}
        onConfirm={() => history.push("/communication/scheduled")}
        confirmText={"SEE SCHEDULED"}
      />
    );
  };

  renderSentDialog = () => {
    const { isSentDialogOpen, sendAt } = this.state;
    const { history } = this.props;

    return (
      <EmailDialogSuccess
        title={"EMAIL SENT"}
        text={"This email will be sent:"}
        date={sendAt || new Date().getTime()}
        isOpen={isSentDialogOpen}
        onClose={() => this.setState({ isSentDialogOpen: false })}
        onConfirm={() => history.push("/communication/create")}
        confirmText={"Close"}
      />
    );
  };

  renderUpdatedDialog = () => {
    const { isUpdateDraftDialogOpen } = this.state;
    return (
      <EmailDialogSuccess
        title={"DRAFT UPDATED"}
        text={"Your draft has been updated"}
        confirmText={"Ok"}
        isOpen={isUpdateDraftDialogOpen}
        onClose={() => this.setState({ isUpdateDraftDialogOpen: false })}
        onConfirm={() => this.setState({ isUpdateDraftDialogOpen: false })}
      />
    );
  };

  renderDiscardDialog = () => {
    const { isDiscardDialogOpen, isDiscarding } = this.state;

    return (
      <EmailDialog
        title={"DISCARD DRAFT"}
        text={"Are you sure you want to delete this draft?"}
        isOpen={isDiscardDialogOpen}
        onClose={() => this.setState({ isDiscardDialogOpen: false })}
        confirmPreloader={isDiscarding}
        onConfirm={this.onDiscard}
      />
    );
  };

  render() {
    const { email, isEmpty, isUpdatingDraft, isScheduling } = this.state;
    const { handleSetImage, addEmail, deleteEmail, setSendAtDate } = this.props;
    return (
      <>
        <EmailForm
          email={email}
          isEmpty={isEmpty}
          handleSetImage={handleSetImage}
          handleUpdate={this.handleUpdateDraft}
          handleDiscard={this.handleDiscard}
          handleSchedule={this.handleSchedule}
          handleRedirectToCreateTemplate={this.handleRedirectToCreateTemplate}
          isUpdating={isUpdatingDraft}
          isScheduling={isScheduling}
          handleConfirmSend={this.handleConfirmSend}
          addEmail={addEmail}
          deleteEmail={deleteEmail}
          setSendAtDate={setSendAtDate}
        />
        {this.renderUpdatedDialog()}
        {this.renderDiscardDialog()}
        {this.renderConfirmSentDialog()}
        {this.renderSentDialog()}
        {this.renderScheduledDialog()}
      </>
    );
  }
}

CommunicationDraftsEdit.propTypes = {
  emails: PropTypes.arrayOf(PropTypes.object),
  addEmail: PropTypes.func.isRequired,
  deleteEmail: PropTypes.func.isRequired,
  createScheduledEmail: PropTypes.func.isRequired,
  deleteDraftsEmail: PropTypes.func.isRequired,
  updateDraftsEmail: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  emails: state.communication.drafts
});

const mapDispatchToProps = {
  addEmail,
  deleteEmail,
  updateDraftsEmail,
  createScheduledEmail,
  deleteDraftsEmail
};

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(CommunicationDraftsEdit);
