import React, { Component } from "react";
import { connect } from "react-redux";
import RouteUsers from "./RouteUsers";
import { withRouter } from "react-router-dom";
import { getGlobalUser } from "../../store/user/selectors";
import PermissionDenied from "../denied/PermissionDenied";

class RouteUsersContainer extends Component {
  render() {
    const { permission } = this.props;

    if (!permission) {
      return <PermissionDenied />;
    }
    return <RouteUsers />;
  }
}

const mapStateProps = state => ({
  user: getGlobalUser(state)
});

export default connect(mapStateProps)(withRouter(RouteUsersContainer));
