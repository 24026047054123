import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, ContentCard } from "./styled";
import { withRouter } from "react-router-dom";
import assetsRoutes from "./routes";
import { Route, Switch, Redirect } from "react-router-dom";
import { verifyPermission } from "../../helpers/common";

class RouteAssets extends Component {
  renderDesktopContent = () => {
    return (
      <Switch>
        {assetsRoutes.map(route => (
          <Route
            exact
            key={route.path}
            path={route.path}
            render={props => {
              const ContentComponent = route.contentComponent;
              if (!verifyPermission(route.path, this.props.permissions)) {
                return (
                  <ContentComponent
                    {...props}
                    user={this.props.user}
                    permission={verifyPermission(
                      route.path,
                      this.props.permissions
                    )}
                  />
                );
              }
              return (
                <Container>
                  <ContentCard>
                    <ContentComponent
                      {...props}
                      user={this.props.user}
                      permission={verifyPermission(
                        route.path,
                        this.props.permissions
                      )}
                    />
                  </ContentCard>
                </Container>
              );
            }}
          />
        ))}
        <Route render={() => <Redirect to={"/assets/config"} />} />
      </Switch>
    );
  };

  render() {
    return <>{this.renderDesktopContent()}</>;
  }
}

const mapStateToProps = state => ({
  user: state.user,
  organizations: state.organizationsState.organizations,
  permissions: state.permissionsState.permissions
});
const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RouteAssets));
