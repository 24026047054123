const GAMESERIES_ACTIONS = {
  list: {
    request: "GAMESERIES/LIST_REQUEST",
    success: "GAMESERIES/LIST_SUCCESS",
    error: "GAMESERIES/LIST_ERROR"
  },
  fetch: {
    request: "GAMESERIES/FETCH_REQUEST",
    success: "GAMESERIES/FETCH_SUCCESS",
    error: "GAMESERIES/FETCH_ERROR"
  },
  reset: "GAMESERIES/RESET_STATE"
};

export default GAMESERIES_ACTIONS;
