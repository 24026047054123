import React, { Component } from "react";
import {
  Container,
  Content,
  List,
  Item,
  Header,
  Info,
  Icon,
  Status,
  Badge,
  Filter,
  FilterItem,
  SearchField,
  Players,
  Player,
  Avatar,
  Name,
  Dot
} from "./styled";
import FormField from "../../components/kit/Fields/FormField/FormField";
import IconSearch from "../../components/kit/Icon/IconSearch";
import Pagination from "../../components/presentation/Pagination/Pagination";
import { getUsername, propValueOr } from "../../helpers/common";
import placeholderUserImg from "../../static/images/default-user.jpg";
import moment from "moment";
import { api } from "../..";
import InputChips from "./ChipInput/InputChips";
import FieldTypeAndSelect from "../../components/smart/FieldTypeAndSelect/FieldTypeAndSelect";
import * as queryString from "query-string";
import crossPlatform from "../../static/icons/cross-platform.svg";
import { getStatus } from "./constants";
class RouteMatches extends Component {
  constructor(props) {
    super(props);

    this.state = {
      users: []
    };
  }

  componentDidMount() {
    const { users } = this.state;
    const parsed = queryString.parse(this.props.location.search);
    if (parsed.userIds) {
      this.handleChangeUsersFilter(users);
    }
  }
  handleChangeStatusFilter = status => this.props.onChangeFilter({ status });

  handleChangeSearchFilter = q => this.props.onChangeFilter({ q });

  handleChangeUsersFilter = users =>
    this.props.onChangeFilter({ userIds: [...users.map(u => u.id)] });

  handleSearchUsers = searchWord => {
    return api
      .searchUsers({
        q: searchWord,
        limit: 10,
        page: 1,
        users: true,
        userIds: this.state.users.map(u => u.id)
      })
      .then(resp =>
        resp.data.users.map(u => ({ label: getUsername(u), value: u.id, ...u }))
      );
  };

  addUser = user => {
    // FieldTypeAndSelect sometimes can return null
    if (!!!user) {
      return;
    }
    let users = [...this.state.users];
    let index = users.findIndex(c => c.id === user.id);
    if (index === -1 && user.id !== undefined) {
      users.push(user);
    }

    this.setState({ users: users });

    this.handleChangeUsersFilter(users);
  };

  removeUser = (users = []) => {
    this.setState({
      users: this.state.users.filter(u => users.includes(getUsername(u)))
    });
    this.handleChangeUsersFilter(users);
  };

  render() {
    const { user, matches, filter, total, searchValue } = this.props;
    const { users } = this.state;
    return (
      <Container>
        <Content>
          <Header>
            <Filter>
              <FilterItem
                key={"all"}
                isActive={filter.status === "all"}
                onClick={() => this.handleChangeStatusFilter("all")}
              >
                ALL
              </FilterItem>
              <FilterItem
                key={"initialized"}
                isActive={filter.status === "initialized"}
                onClick={() => this.handleChangeStatusFilter("initialized")}
              >
                INITIALIZED
              </FilterItem>
              <FilterItem
                key={"playing"}
                isActive={filter.status === "playing"}
                onClick={() => this.handleChangeStatusFilter("playing")}
              >
                PLAYING
              </FilterItem>
              <FilterItem
                key={"complete"}
                isActive={filter.status === "complete"}
                onClick={() => this.handleChangeStatusFilter("complete")}
              >
                COMPLETE
              </FilterItem>
            </Filter>
            {users.length === 0 ? (
              <FormField
                iconAlign={"left"}
                fullWidth
                icon={<IconSearch />}
                value={searchValue}
                placeholder={"Search by Gamertag..."}
                gutterBottom={false}
              >
                <FieldTypeAndSelect
                  placeholder={"Search by gamertag..."}
                  onChange={user => this.addUser(user)}
                  onSearch={this.handleSearchUsers}
                />
              </FormField>
            ) : (
              <InputChips
                value={this.state.users.map(u => getUsername(u))}
                onChange={this.removeUser}
                readOnly={true}
                placeholder={`Add users in the field above`}
              />
            )}
          </Header>
          <SearchField>
            <FormField
              iconAlign={"left"}
              fullWidth
              icon={<IconSearch />}
              value={searchValue}
              placeholder={"Search by tournament id, token or name..."}
              gutterBottom={false}
              onChange={value => {
                this.props.onChangeSearch(value);
                this.handleChangeSearchFilter(value);
              }}
            />
          </SearchField>
          <List>
            {(matches || []).map(match => {
              const status = match.status;
              return (
                <Item key={match.id} to={`/game/${match.token}`}>
                  <Header>
                    <Info>
                      <Icon
                        src={propValueOr(match, "Game.imageInfo.icon", null)}
                      />
                      <Icon
                        src={propValueOr(
                          match,
                          "console.imageInfo.icon",
                          crossPlatform
                        )}
                      />
                      <Players>
                        {match.users.map(user => (
                          <Player>
                            <Avatar
                              src={propValueOr(
                                user,
                                "imageInfo.thumbnail",
                                placeholderUserImg
                              )}
                              onError={e => (e.target.src = placeholderUserImg)}
                            />
                            {match.status === "initialized" && (
                              <Dot status={getStatus(user, match)} />
                            )}
                            <Name>{getUsername(user)}</Name>
                          </Player>
                        ))}
                      </Players>
                    </Info>
                    <Status>
                      {match.tournament && (
                        <Badge>
                          {propValueOr(match, "tournament.name", "")}
                        </Badge>
                      )}
                      {match.tournament && (
                        <Badge>
                          ID {propValueOr(match, "tournament.id", "")}
                        </Badge>
                      )}
                      {!user.organizationId && match.organization && (
                        <Badge>
                          {propValueOr(match, "organization.name", "")}
                        </Badge>
                      )}
                      <Badge status={status}>{status}</Badge>
                      <Badge>
                        {moment(match.createdAt).format("MM/DD/YY @ hh:mm A")}
                      </Badge>
                    </Status>
                  </Header>
                </Item>
              );
            })}
          </List>
          <Pagination withUrl total={total} />
        </Content>
      </Container>
    );
  }
}

export default RouteMatches;
