/**
 * It is ButtonKit component which is used as Link
 * with filtered props to avoid console warnings
 * */
import React from "react";
import PropTypes from "prop-types";
import ButtonKit from "./ButtonKit";
import { Link } from "react-router-dom";

export const LinkWithFilteredProps = ({
  appearance,
  color,
  fixedSize,
  small,
  uppercase,
  fullWidth,
  active,
  preloader,
  width,
  disabled,
  isActive,
  ...rest
}) => <Link {...rest} />;

function ButtonLinkKit(props) {
  return <ButtonKit {...props} as={LinkWithFilteredProps} />;
}

ButtonLinkKit.propTypes = {
  /**
   * Response for style of button
   * 'primary' - button with background but without border (default)
   * 'secondary' - without background but with border
   */
  appearance: PropTypes.oneOf(["primary", "secondary", "inverted"]),
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "inverted",
    "danger",
    "text-link",
    "green",
    "light-green",
    "red",
    "blue",
    "mls",
    "rival_red"
  ]),
  fixedSize: PropTypes.bool,
  small: PropTypes.bool,
  uppercase: PropTypes.bool,
  fullWidth: PropTypes.bool,
  active: PropTypes.bool,
  preloader: PropTypes.bool,
  shape: PropTypes.oneOf(["rectangular", "rounded"]),

  // fixed width. fullWidth property overwrite this.
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  disabled: PropTypes.bool,

  to: PropTypes.string.isRequired
};

export default ButtonLinkKit;
