import React from "react";
import ButtonKit from "../../../components/kit/Button/ButtonKit";
import { FormActions } from "../styled";

const EmailFormActions = props => {
  const {
    email,
    isScheduling,
    isCreatingDraft,
    isUpdating,
    isCreatingTemplate,
    handleSchedule,
    handleUpdate,
    handleCreateDraft,
    handleDiscard,
    handleRedirectToCreateTemplate,
    handleRedirectToCreateGlobalTemplate,
    handleCreateTemplate,
    handleCreateGlobalTemplate,
    handleUpdateTemplate,
    handleSendTestEmail,
    onSchedule,
    onConfirmSend,
    onUpdateTemplate,
    onCreateTemplate,
    onCreateGlobalTemplate,
    sendAt,
    readOnly
  } = props;

  return (
    <FormActions>
      {typeof handleSchedule === "function" ? (
        sendAt ? (
          <ButtonKit
            fullWidth
            shape={"rounded"}
            color={"rival_red"}
            onClick={onSchedule}
            preloader={isScheduling}
          >
            SCHEDULE
          </ButtonKit>
        ) : (
          <ButtonKit
            fullWidth
            shape={"rounded"}
            color={"rival_red"}
            onClick={onConfirmSend}
          >
            SEND
          </ButtonKit>
        )
      ) : null}
      {typeof handleUpdate === "function" ? (
        <ButtonKit
          fullWidth
          shape={"rounded"}
          color={"rival_red"}
          onClick={() => handleUpdate(email)}
          preloader={isUpdating}
        >
          UPDATE
        </ButtonKit>
      ) : null}
      {typeof handleDiscard === "function" ? (
        <ButtonKit
          fullWidth
          appearance={"secondary"}
          shape={"rounded"}
          color={"rival_red"}
          onClick={handleDiscard}
        >
          DISCARD
        </ButtonKit>
      ) : null}
      {typeof handleCreateDraft === "function" ? (
        <ButtonKit
          fullWidth
          appearance={"secondary"}
          shape={"rounded"}
          color={"rival_red"}
          onClick={() => handleCreateDraft(email)}
          preloader={isCreatingDraft}
        >
          SAVE DRAFT
        </ButtonKit>
      ) : null}
      {typeof handleRedirectToCreateTemplate === "function" ? (
        <ButtonKit
          fullWidth
          appearance={"secondary"}
          shape={"rounded"}
          color={"rival_red"}
          onClick={() => handleRedirectToCreateTemplate(email)}
        >
          CREATE TEMPLATE
        </ButtonKit>
      ) : null}
      {typeof handleRedirectToCreateGlobalTemplate === "function" ? (
        <ButtonKit
          fullWidth
          appearance={"secondary"}
          shape={"rounded"}
          color={"rival_red"}
          onClick={() => handleRedirectToCreateGlobalTemplate(email)}
        >
          CREATE GLOBAL TEMPLATE
        </ButtonKit>
      ) : null}
      {typeof handleSendTestEmail === "function" && readOnly ? (
        <ButtonKit
          fullWidth
          shape={"rounded"}
          color={"rival_red"}
          onClick={() => handleSendTestEmail(email)}
        >
          SEND TEST EMAIL
        </ButtonKit>
      ) : null}
      {typeof handleUpdateTemplate === "function" && !readOnly ? (
        <ButtonKit
          fullWidth
          shape={"rounded"}
          color={"rival_red"}
          onClick={onUpdateTemplate}
          preloader={isUpdating}
        >
          UPDATE TEMPLATE
        </ButtonKit>
      ) : null}
      {typeof handleCreateTemplate === "function" ? (
        <ButtonKit
          fullWidth
          shape={"rounded"}
          color={"rival_red"}
          onClick={onCreateTemplate}
          preloader={isCreatingTemplate}
        >
          CREATE TEMPLATE
        </ButtonKit>
      ) : null}
      {typeof handleCreateGlobalTemplate === "function" ? (
        <ButtonKit
          fullWidth
          shape={"rounded"}
          color={"rival_red"}
          onClick={onCreateGlobalTemplate}
          preloader={isCreatingTemplate}
        >
          CREATE GLOBAL TEMPLATE
        </ButtonKit>
      ) : null}
    </FormActions>
  );
};

export default EmailFormActions;
