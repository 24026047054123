import styled from "styled-components";
import { COLOR, FONT_FAMILY, FONT_WEIGHT } from "../../constants/theme";
import { Link } from "react-router-dom";

export const Container = styled.div`
  display: flex;
  height: 100%;
  background-color: ${COLOR.BG_PRIMARY};
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  padding-bottom: 20px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;
  height: 100%;
  max-height: 1000px;
  padding: 0px 30px;
  border-radius: 15px;

  background-color: ${COLOR.BG_SECONDARY};
  color: ${COLOR.TEXT};
`;

export const Header = styled.div`
  display: flex;
  align-items: center;

  padding: 30px 0px;

  width: 100%;
`;

export const NavBar = styled.div`
  display: flex;
  align-items: center;

  > {
    margin-right: 10px;
  }
`;

export const LinkNavItem = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;

  font: ${FONT_WEIGHT.NORMAL} 16px ${FONT_FAMILY.GOTHIC_BOLD};
  color: ${COLOR.TEXT};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const NavItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  font: ${FONT_WEIGHT.NORMAL} 20px ${FONT_FAMILY.GOTHIC_BOLD};
  user-select: text;
`;

export const WrapArrow = styled.div`
  cursor: pointer;
`;

export const ArrowIcon = styled.img`
  width: 30px;
  height: 30px;
`;

export const WrapLoader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  min-height: 350px;
`;

export const Status = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  margin-left: auto;
`;

export const WrapMarkButton = styled.div`
  display: flex;
  align-items: center;

  margin-right: 10px;

  cursor: pointer;
  &:hover {
    opacity: 0.4;
  }
`;

export const ButtonText = styled.div`
  text-align: center;
  font: ${FONT_WEIGHT.NORMAL} 12px ${FONT_FAMILY.GOTHIC};
`;

export const StatusBadge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 70px;
  height: 100%;

  padding: 3px 5px;
  margin-right: 10px;
  border-radius: 12px 12px;

  font: ${FONT_WEIGHT.NORMAL} 12px ${FONT_FAMILY.GOTHIC};
  text-transform: uppercase;

  border: 1px solid
    ${({ status }) => {
      switch (status) {
        case "open":
          return COLOR.GREEN;
        case "pending":
          return COLOR.YELLOW;
        case "closed":
          return COLOR.GRAY;
        default:
          return COLOR.GRAY;
      }
    }};
`;

export const ModeratorMessage = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  border-radius: 12px;
  border: 1px solid ${COLOR.RIVAL_RED};
  background-color: ${COLOR.BORDER};
  margin-bottom: 15px;
`;

export const ModeratorScores = styled.div`
  display: flex;
  justify-content: ${({ multiUser }) =>
    !!multiUser ? "space-between" : "center"};
  width: 100%;
`;

export const PlayerScoreStatus = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PlayerTitle = styled.div`
  font: ${FONT_WEIGHT.BOLD} 16px ${FONT_FAMILY.THIN};
  color: ${({ home }) => (!!home ? COLOR.CHERRY_RED : COLOR.BLUE)};
  text-align: center;
`;

export const ModTitle = styled.div`
  font: ${FONT_WEIGHT.BOLD} 16px ${FONT_FAMILY.THIN};
  text-align: center;
  margin-bottom: 5px;
`;

export const ModText = styled.div`
  font: ${FONT_WEIGHT.MEDIUM} 16px ${FONT_FAMILY.THIN};
  text-align: center;
`;

export const WrapChat = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 50px;
  flex: 1;
  padding-bottom: 30px;
`;

export const CheckIcon = styled.img`
  width: 15px;
  height: 15px;

  margin-left: 15px;
`;

export const StatusItem = styled.div`
  font: ${FONT_WEIGHT.NORMAL} 14px ${FONT_FAMILY.GOTHIC};

  width: 100%;
  padding: 10px;

  cursor: pointer;
  &:hover {
    opacity: 0.4;
  }
`;

export const StatusList = styled.div`
  position: absolute;
  top: 120%;
  right: 0;
  display: flex;
  flex-direction: column;

  color: ${COLOR.TEXT};
  background-color: ${COLOR.BG_SECONDARY};
  box-shadow: 1px 1px 5px black;

  > :not(:last-child) {
    border-bottom: 1px solid ${COLOR.BORDER};
  }
`;

export const PopupMenu = styled.div`
  position: absolute;
  top: 120%;
  right: 0;
  display: flex;
  flex-direction: column;

  color: ${COLOR.TEXT};
  background-color: ${COLOR.BG_SECONDARY};
  box-shadow: 1px 1px 5px black;

  > :not(:last-child) {
    border-bottom: 1px solid ${COLOR.BORDER};
  }
`;

export const PopupMenuItem = styled.div`
  font: ${FONT_WEIGHT.NORMAL} 14px ${FONT_FAMILY.GOTHIC};

  text-transform: uppercase;
  width: 100%;
  padding: 10px 20px;

  ${({ disabled }) =>
    !!disabled
      ? `opacity: 0.4;`
      : `cursor: pointer;
    &:hover {
      opacity: 0.4;
    }`}
`;

export const OptionsMenu = styled.img`
  height: 25px;
  width: 25px;
  cursor: pointer;
`;
