export let TOURNAMENT_ASIDE_NAV_ITEMS = [
  {
    title: "Create Tournament",
    to: "/tournaments/create"
  },
  {
    title: "Manage Tournaments",
    to: "/tournaments/manage"
  }
];

export const BRACKET_PREVIEW_OPTIONS = [
  {
    value: "none",
    label: "No bracket preview"
  },
  {
    value: "registration-end",
    label:
      "Registration end - show bracket preview only after registration has ended"
  },
  {
    value: "rolling",
    label:
      "Rolling - continue to update bracket preview, starting from when registration starts until registration ends"
  }
];
