import styled from "styled-components";
import { COLOR, FONT_WEIGHT, FONT_FAMILY } from "../../../constants/theme";
import breakpoints from "../../../helpers/breakpoints";

export const Container = styled.div`
  display: flex;
  flex-direction: ${({ twoColumnLayout }) =>
    !!twoColumnLayout ? "row" : "column"};

  width: 100%;
  padding: 10px;

  > :not(:last-child) {
    margin-bottom: 10px;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: ${({ row }) => (row ? "row" : "column")};
  align-items: ${({ centerItems }) => (centerItems ? "center" : "flex-start")};
  width: ${({ twoColumnLayout, noWidth }) =>
    noWidth ? "13%" : !!twoColumnLayout ? "50%" : "100%"};

  ${breakpoints.down("sm")} {
    flex-direction: column;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  font: ${FONT_WEIGHT.NORMAL} 16px ${FONT_FAMILY.GOTHIC_BOLD};
  text-transform: capitalize;
  font-weight: 500;
`;

export const Delete = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;

  &:hover {
    opacity: 0.4;
  }
`;

export const Download = styled.a`
  position: absolute;
  top: 32px;
  right: 7px;
  background-color: ${COLOR.BLUE};

  border-radius: 50%;
  width: 20px;
  height: 20px;
  padding: 5px;
  border: 1px solid ${COLOR.WHITE};

  cursor: pointer;
  &:hover {
    opacity: 0.4;
  }
`;

export const IconWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Icon = styled.img`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  max-width: 100%;

  > fill {
    color: ${COLOR.WHITE};
  }
`;

export const ImageInput = styled.input`
  width: 0;
  height: 0;
  display: none;
`;

export const WrapImageInput = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  max-width: ${({ width }) => (!!width ? `${width}px` : "400px")};
  height: ${({ height }) => (!!height ? `${height}px` : "220px")};
  width: 100%;
  margin: 5px 0px 10px 0px;

  border: 1px solid ${COLOR.WHITE};
  background-color: ${COLOR.GREY_5};

  cursor: pointer;
  &:hover {
    opacity: 0.4;
  }
`;

export const ImageInputButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin: 5px 0px 10px 0px;

  width: 100%;
  height: 100%;

  background-color: ${COLOR.GREY_5};
  color: ${COLOR.GREY};
`;

export const ImageIcon = styled.img`
  width: 40px;
  height: 40px;
`;

export const Preview = styled.img`
  max-height: 100%;
  max-width: 100%;
  display: inline-block;
`;

export const SelectButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin: auto;
  position: absolute;

  color: ${COLOR.BG_SECONDARY};
  background-color: ${COLOR.WHITE};

  padding: 10px 15px;
  border-radius: 12px;

  cursor: pointer;
  &:hover {
    opacity: 0.4;
  }
`;

export const SelectText = styled.div`
  text-align: center;
  font: ${FONT_WEIGHT.NORMAL} 12px ${FONT_FAMILY.GOTHIC_BOLD};
  overflow: hidden;
  white-space: wrap;
  text-overflow: ellipsis;
`;

export const PreviewWrap = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  max-width: ${({ width }) => (!!width ? `${width}px` : "400px")};
  height: ${({ height }) => (!!height ? `${height}px` : "220px")};

  border: 1px solid ${COLOR.WHITE};
  padding: 5px;
  margin: 5px 0px 10px 0px;
`;

export const ImageError = styled.div`
  display: flex;
  align-items: center;

  color: ${COLOR.CHERRY_RED};
  font: ${FONT_WEIGHT.NORMAL} 14px ${FONT_FAMILY.GOTHIC};
  margin-top: 5px;
`;

export const WrapField = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
  max-width: 400px;

  margin-top: 10px;
  text-transform: capitalize;
`;

export const WrapButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ImageTitle = styled.div`
  font: ${FONT_WEIGHT.NORMAL} 14px ${FONT_FAMILY.GOTHIC_BOLD};
  margin: 5px 0px;
`;

export const ConstraintList = styled.ul`
  font: ${FONT_WEIGHT.NORMAL} 12px ${FONT_FAMILY.GOTHIC};
  user-select: text;
  margin: 0px;
  padding-left: 20px;
`;

export const ConstraintItem = styled.li`
  display: flex;
  align-items: center;
  ${({ isValid }) => {
    return isValid === null
      ? `color: ${COLOR.TEXT};`
      : isValid
      ? `color: ${COLOR.LIGHT_GREEN};`
      : `color: ${COLOR.CHERRY_RED};`;
  }}
`;

export const ConstraintIcon = styled.img`
  width: 10px;
  margin-left: 5px;

  & > svg {
    fill: green;
    stroke: green;
  }
`;

export const UpdatedDate = styled.div`
  margin-top: 10px;
  color: ${({ uploaded }) => (!!uploaded ? COLOR.LIGHT_GREEN : COLOR.TEXT)};
  font: ${FONT_WEIGHT.NORMAL} 12px ${FONT_FAMILY.GOTHIC};
  text-align: center;
`;

export const Swatch = styled.div`
  padding: 2px;
  border-radius: 1px;
  display: flex;
  cursor: pointer;
  background-color: ${COLOR.WHITE};
  margin: 0px 10px 0px 10px;

  ${breakpoints.down("sm")} {
    margin: 10px;
  }
`;

export const InsideColor = styled.div`
  width: 36px;
  height: 14px;
  background-color: ${({ color }) => (color ? color : COLOR.RIVAL_RED)};
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export const WrapButtons = styled.div`
  display: flex;
  flex-direction: ${({ column }) => (!!column ? "column-reverse" : "row")};
  align-items: flex-start;
`;
