import styled from "styled-components";
import { COLOR } from "../../constants/theme";

export const Content = styled.div`
  min-height: 100%;
  width: 100%;
  color: ${COLOR.WHITE};
  background-color: ${COLOR.BG_PRIMARY};
  z-index: 3;
  padding: 0 20px;
`;
export const Container = styled.div`
  min-height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
`;
export const Text = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 20px;
`;
