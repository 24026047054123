import React from "react";
import PropTypes from "prop-types";
import withBreadcrumbs from "react-router-breadcrumbs-hoc";
import { Container, Item, ItemLink, ItemText } from "./styled";
import { ROUTES } from "./constants";
import arrowIcon from "../../../static/icons/chevron-arrow-right.svg";

const Breadcrumbs = ({ breadcrumbs, lastItemValue, ...props }) => {
  return (
    <Container>
      {breadcrumbs.map(({ match, breadcrumb }, index) => {
        return index === breadcrumbs.length - 1 ? (
          <Item key={match.url}>
            <ItemText>{lastItemValue ? lastItemValue : breadcrumb}</ItemText>
          </Item>
        ) : (
          <Item key={match.url}>
            <ItemLink to={match.url}>{breadcrumb}</ItemLink>
            <img src={arrowIcon} width={24} height={21} alt="" />
          </Item>
        );
      })}
    </Container>
  );
};

Breadcrumbs.propTypes = {
  breadcrumbs: PropTypes.arrayOf(PropTypes.shape),
  lastItemValue: PropTypes.string
};

export default withBreadcrumbs(ROUTES, {
  disableDefaults: true
})(Breadcrumbs);
