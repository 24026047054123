import RouteTournamentAside from "./RouteTournamentAside";
import TournamentCreate from "./TournamentCreate/TournamentCreate";
import TournamentManage from "./TournamentManage/TournamentManage";
import TournamentCalendar from "./TournamentCalendar/TournamentCalendar";
import Tournament from "./TournamentManage/Tournament/Tournament";
import TemplatesContainer from "./Templates/TemplatesContainer";
import TemplateContainer from "./Template/TemplateContainer";

/*Note: for all routes property exact is true !!!*/
const routes = [
  {
    path: "/tournaments/calendar",
    contentComponent: TournamentCalendar
  },
  {
    path: "/tournaments/create",
    contentComponent: TournamentCreate,
    asideComponent: RouteTournamentAside
  },
  {
    path: "/tournaments/create/:step",
    contentComponent: TournamentCreate,
    asideComponent: RouteTournamentAside
  },
  {
    path: "/tournaments/templates",
    exact: true,
    contentComponent: TemplatesContainer
  },
  {
    path: "/tournaments/templates/:id",
    contentComponent: TemplateContainer
  },
  {
    path: "/tournaments",
    exact: true,
    contentComponent: TournamentManage,
    asideComponent: RouteTournamentAside
  },
  {
    path: "/tournaments/:token",
    contentComponent: Tournament
  },
  {
    path: "/tournaments/:token/players",
    contentComponent: Tournament
  },
  {
    path: "/tournaments/:token/matches",
    contentComponent: Tournament
  },
  {
    path: "/tournaments/:token/:step",
    contentComponent: Tournament
  },
  {
    path: "/tournaments/:token/:step/:slug",
    contentComponent: Tournament
  },
  {
    path: "/tournaments/:token/transactions",
    contentComponent: Tournament
  },
  {
    path: "/tournaments/calendar",
    contentComponent: TournamentCalendar
  }
];

export default routes;
