import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  PaginationButtonNextSD,
  PaginationButtonPrevSD,
  PaginationInputSD,
  PaginationSD,
  PaginationTextSD,
  PaginationTextTotal
} from "./styled";
import { withRouter } from "react-router-dom";
import * as queryString from "query-string";

class Pagination extends Component {
  static propTypes = {
    location: PropTypes.shape({
      search: PropTypes.string.isRequired
    }).isRequired,
    history: PropTypes.object.isRequired,
    total: PropTypes.number.isRequired,

    // need delete when pagination to url on all pages
    current: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    // need delete when pagination to url on all pages
    onChange: PropTypes.func,
    // this props in temporarily, when logic page to url will be implement need refactor this component
    withUrl: PropTypes.bool
  };

  getPageFromUrl = () => {
    return +queryString.parse(this.props.location.search).page || 1;
  };

  setPageToUrl = page => {
    const oldSearch = queryString.parse(this.props.location.search);

    const newSearch = {
      ...oldSearch,
      page
    };

    this.props.history.push({
      search: `?${queryString.stringify(newSearch)}`
    });
  };

  setPage = page => {
    const { total, withUrl } = this.props;

    if (page < 1 || page > total) {
      return;
    }

    // call change page function in parent component
    withUrl ? this.setPageToUrl(page) : this.props.onChange(page);
  };

  handleChange = e => {
    const { withUrl, onChange } = this.props;
    const page = parseInt(e.target.value.replace(/^[^1-9][^0-9]*/g, "")) || "";

    if (page <= this.props.total) {
      withUrl ? this.setPageToUrl(page) : onChange(page);
    }
  };

  render() {
    const { total, current, withUrl } = this.props;
    const page = withUrl ? this.getPageFromUrl() : +current;

    if (total <= 1) {
      // don't display pager if there is only 1 page
      return null;
    }

    return (
      <PaginationSD>
        <PaginationTextSD>Displaying Page</PaginationTextSD>
        <PaginationButtonPrevSD onClick={() => this.setPage(page - 1)} />
        <PaginationInputSD
          type="text"
          value={page}
          onChange={this.handleChange}
        />
        <PaginationTextSD>
          of
          <PaginationTextTotal onClick={() => this.setPage(total)}>
            {total}
          </PaginationTextTotal>
        </PaginationTextSD>
        <PaginationButtonNextSD onClick={() => this.setPage(page + 1)} />
      </PaginationSD>
    );
  }
}

Pagination.propTypes = {
  total: PropTypes.number.isRequired,
  // need delete when pagination to url on all pages
  current: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  // need delete when pagination to url on all pages
  onChange: PropTypes.func,
  // this props in temporarily, when logic page to url will be implement need refactor this component
  withUrl: PropTypes.bool
};

export default withRouter(Pagination);
