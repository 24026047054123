import React, { Component } from "react";
import {
  DialogContainer,
  DialogHeader,
  DialogTitle,
  PlayerSelection,
  SelectionText,
  PlayerRow,
  PlayerProfile,
  Buttons,
  WrapInputs,
  Success,
  Text
} from "./styled";
import { propValueOr, getUsername } from "../../../helpers/common";
import DialogKit from "../../../components/kit/Dialog/DialogKit";
import ButtonKit from "../../../components/kit/Button/ButtonKit";
import placeholderUserImg from "../../../static/images/default-user.jpg";
import FieldTypeAndSelect from "../../../components/smart/FieldTypeAndSelect/FieldTypeAndSelect";
import { api } from "../../../index";
import iconCheck from "../../../static/icons/circle-check-green.svg";
import iconX from "../../../static/icons/circle-x-red.svg";

class ReplacePlayersDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newPlayers: [],
      success: false,
      error: ""
    };
  }

  componentDidUpdate(prevProps) {
    if (
      propValueOr(prevProps, "players", []) !==
        propValueOr(this.props, "players", []) &&
      propValueOr(this.props, "players", []).length >= 1
    ) {
      let stateObj = {
        newPlayers: []
      };

      this.setState(stateObj);
    }
  }

  handleSubmit = () => {
    const canSubmit = this.haveChangedPlayers();
    if (!canSubmit) {
      return;
    }

    const { firstPlayer, secondPlayer } = this.getPlayers();
    const players = [firstPlayer.id, secondPlayer.id];
    api.admin.matches
      .update(this.props.game.token, { players })
      .then(resp => this.setState({ success: true }))
      .catch(err => {
        console.log(err);
        this.setState({
          error: propValueOr(
            err,
            "response.data.message",
            err.message || "Unknown error. Please Try again later."
          )
        });
      });
  };

  handleClose = () => {
    const { onClose } = this.props;

    this.setState({ newPlayers: [], success: false });
    onClose();
  };

  renderSuccess = () => {
    const { error } = this.state;
    return (
      <DialogContainer>
        <DialogHeader>
          <DialogTitle>
            {error ? "Player(s) Change Failed" : "Success!"}
          </DialogTitle>
        </DialogHeader>
        <Success>
          <img
            src={error ? iconX : iconCheck}
            width={65}
            height={65}
            alt="check"
          />
          <Text>{error || "The player(s) have been replaced."}</Text>
        </Success>
        <Buttons>
          <ButtonKit
            fullWidth
            onClick={() =>
              error
                ? this.setState({
                    error: null,
                    newPlayers: [],
                    success: false
                  })
                : this.handleClose()
            }
            color={error ? "primary" : "green"}
          >
            {error ? "Try Again" : "Okay"}
          </ButtonKit>
        </Buttons>
      </DialogContainer>
    );
  };

  handleSearchUsers = (searchWord, newPage = false, resetPage = false) => {
    let data = [];

    data = api
      .search(searchWord, 10, 1, true, this.props.game.consoleId)
      .then(resp =>
        resp.data.users
          .map(u => ({
            label: getUsername(u),
            value: u.id,
            ...u
          }))
          .filter(u => {
            // Do not allow to add player who is already in match
            for (const i in this.props.players) {
              const p = this.state.newPlayers[i] || this.props.players[i];
              if (p.id === u.id) {
                return false;
              }
            }

            return true;
          })
      );

    return data;
  };

  setPlayer(idx, player) {
    if (player == null) {
      return; // Do nothing when player is not selected
    }

    const newPlayers = [].concat(this.state.newPlayers);
    newPlayers[idx] = player;
    this.setState({
      newPlayers
    });
  }

  haveChangedPlayers = () => {
    return !!this.state.newPlayers.filter(np => !!np).length;
  };

  getPlayers = () => {
    const { players } = this.props;
    const { newPlayers } = this.state;

    const firstPlayerIdx = players[0].isHome ? 0 : 1;
    const secondPlayerIdx = players[0].isHome ? 1 : 0;

    const firstPlayer = newPlayers[firstPlayerIdx] || players[firstPlayerIdx];
    const secondPlayer =
      newPlayers[secondPlayerIdx] || players[secondPlayerIdx];

    return { firstPlayerIdx, firstPlayer, secondPlayerIdx, secondPlayer };
  };

  renderPlayer = (playerIndex, player) => {
    const playerThumbnail = propValueOr(
      player,
      "imageInfo.thumbnail",
      placeholderUserImg
    );
    return (
      <FieldTypeAndSelect
        label={
          <PlayerRow>
            <PlayerProfile
              src={playerThumbnail}
              onError={e => (e.target.src = placeholderUserImg)}
            />
            {getUsername(player)}
          </PlayerRow>
        }
        placeholder={"Search by gamertag..."}
        onChange={user => this.setPlayer(playerIndex, user)}
        onSearch={this.handleSearchUsers.bind(this)}
      />
    );
  };

  renderDialog = () => {
    const {
      firstPlayerIdx,
      firstPlayer,
      secondPlayerIdx,
      secondPlayer
    } = this.getPlayers();

    return (
      <DialogContainer>
        <DialogHeader>
          <DialogTitle>Replace players</DialogTitle>
        </DialogHeader>
        <PlayerSelection>
          <SelectionText>Select the new player(s)</SelectionText>
          <WrapInputs>
            {this.renderPlayer(firstPlayerIdx, firstPlayer)}
            {this.renderPlayer(secondPlayerIdx, secondPlayer)}
          </WrapInputs>
        </PlayerSelection>
        <Buttons>
          <ButtonKit
            onClick={this.handleSubmit}
            disabled={!this.haveChangedPlayers()}
            fullWidth
            color={"red"}
          >
            Submit
          </ButtonKit>
          <ButtonKit
            fullWidth
            appearance={"secondary"}
            onClick={this.handleClose}
          >
            Cancel
          </ButtonKit>
        </Buttons>
      </DialogContainer>
    );
  };

  render() {
    const { isOpen } = this.props;

    return (
      <DialogKit
        isOpen={isOpen}
        onClose={this.handleClose}
        renderCustom={
          this.state.success || this.state.error
            ? this.renderSuccess
            : this.renderDialog
        }
        displayButtonClose={true}
        width={370}
      />
    );
  }
}

export default ReplacePlayersDialog;
