import React, { Component } from "react";
import {
  Route500ContentSD,
  Route500DescriptionSD,
  Route500ImageSD,
  Route500SD,
  Route500TitleSD,
  Route500WrapImageSD
} from "./styled";

import image500 from "../../static/images/500.png";
import ButtonLinkKit from "../../components/kit/Button/ButtonLinkKit";

class Route500 extends Component {
  static propTypes = {};

  render() {
    return (
      <Route500SD>
        <Route500ContentSD>
          <Route500WrapImageSD>
            <Route500ImageSD src={image500} alt={"500 icon"} />
          </Route500WrapImageSD>
          <Route500TitleSD>Error: 500 Unexpected Error</Route500TitleSD>
          <Route500DescriptionSD>
            There has been an error. Please try again later.
          </Route500DescriptionSD>
          <ButtonLinkKit fullWidth appearance={"primary"} to={"/"}>
            Go to Homepage
          </ButtonLinkKit>
        </Route500ContentSD>
      </Route500SD>
    );
  }
}

export default Route500;
