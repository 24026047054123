import styled from "styled-components";
import { COLOR } from "../../../../../../constants/theme";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${COLOR.BG_SECONDARY};
  border-radius: 4px;
  width: 100%;
  z-index: 10;
  > :not(:last-child) {
    border-bottom: 1px solid ${COLOR.BORDER};
  }
`;

export const AddStageBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 15px 0px;
`;
