import { COLOR } from "../../../../../constants/theme";
import { propValueOr } from "../../../../../helpers/common";
import moment from "moment";
import initialized from "../../../../../static/images/tournament/initialized.png";
import inprogress from "../../../../../static/images/tournament/in-progress.png";
import complete from "../../../../../static/images/tournament/complete.png";

export const getCardValues = tournament => {
  if (!tournament) {
    return {};
  } else if (tournament.status === "initialized") {
    return {
      status: "Not Started",
      bg: initialized,
      game: propValueOr(tournament, "Game.imageInfo.thumbnail", ""),
      color: COLOR.CHERRY_RED,
      time:
        "Starts " +
        moment(propValueOr(tournament, "startDate", "")).format(
          "M/DD/YY @ h:mmA"
        ),
      button: "players"
    };
  } else if (tournament.status === "in progress") {
    return {
      status: "In Progress",
      bg: inprogress,
      game: propValueOr(tournament, "Game.imageInfo.thumbnail", ""),
      color: COLOR.GREEN,
      time:
        "Started " +
        moment(propValueOr(tournament, "startDate", "")).format(
          "M/DD/YY @ h:mmA"
        ),
      button: "games"
    };
  } else if (tournament.status === "complete") {
    return {
      status: "Complete",
      bg: complete,
      game: propValueOr(tournament, "Game.imageInfo.thumbnail", ""),
      color: COLOR.GREY_2,
      time:
        "Ended " +
        moment(propValueOr(tournament, "startDate", "")).format(
          "M/DD/YY @ h:mmA"
        )
    };
  }
  return {};
};

export const imageFields = [
  // {
  //   key: "thumbnail",
  //   description:
  //     "This will show on the Overview of the Tournament Details page.",
  //   constraints: {
  //     aspectRatio: [{ value: 0.66 }, { value: 0.68 }]
  //   },
  //   imageDimensions: [66.67, 100],
  //   imgLabels: ["Aspect ratio of 2:3"]
  // },
  {
    key: "background",
    description:
      "This will show at the top of the tournaments details page for desktop window widths.",
    constraints: {
      width: [1222, null],
      height: [185, null],
      aspectRatio: [{ value: 6.59 }, { value: 6.61 }]
    },
    imageDimensions: [500, 75.69],
    imgLabels: [
      "Aspect Ratio of ~6.6 (exact: 6.6054)",
      "Minimum Dimensions: 1222px by 185px"
    ]
  },
  // {
  //   key: "mobile",
  //   description:
  //     "This will show at the top of the tournaments details page for mobile window widths.",
  //   constraints: {
  //     aspectRatio: [{ value: 2 }, { value: 2.1 }]
  //   },
  //   imageDimensions: [300, 145],
  //   imgLabels: [
  //     "Aspect Ratio of 2.07",
  //     "Recommended width / height: 759px by 366.66px"
  //   ]
  // },
  {
    key: "event",
    description: `This will show on the tournaments list on the dashboard and game center pages. It will also show at the top of the tournaments details page for mobile window widths.`,
    constraints: {
      aspectRatio: [{ value: 1.7 }, { value: 1.8 }],
      width: [759, null],
      height: [439, null]
    },
    imageDimensions: [300, 173.6],
    imgLabels: [
      "Aspect Ratio of 1.728",
      "Recommended (and minimum) Dimensions: (759px by 439px)"
    ]
  }
];

export const adFields = [
  {
    key: "ad1",
    description: `This ad will show up on the bottom of the tournament details page`,
    constraints: {
      width: [336],
      height: [280]
    },
    imageDimensions: [210, 175],
    imgLabels: ["Aspect Ratio of 1.2"]
  },
  {
    key: "ad2",
    description: `This ad will show up on the bottom of the tournament details page`,
    constraints: {
      width: [336],
      height: [280]
    },
    imageDimensions: [210, 175],
    imgLabels: ["Aspect Ratio of 1.2"]
  },
  {
    key: "ad3",
    description: `This ad will show up on the bottom of the tournament details page`,
    constraints: {
      width: [336],
      height: [280]
    },
    imageDimensions: [210, 175],
    imgLabels: ["Aspect Ratio of 1.2"]
  }
];

export const pdfFields = [
  {
    key: "pdf1",
    description: `This PDF will show up on the top of the tournament details page`
  },
  {
    key: "pdf2",
    description: `This PDF will show up on the top of the tournament details page`
  },
  {
    key: "pdf3",
    description: `This PDF will show up on the top of the tournament details page`
  },
  {
    key: "pdf4",
    description: `This PDF will show up on the top of the tournament details page`
  }
];
