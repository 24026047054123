export const ORGANIZATIONS_ACTIONS = {
  fetch: {
    request: "ORGANIZATIONS/FETCH_REQUEST",
    success: "ORGANIZATIONS/FETCH_SUCCESS",
    error: "ORGANIZATIONS/FETCH_ERROR"
  },
  fetchAffiliations: {
    request: "ORGANIZATIONS/FETCH_AFFILIATIONS_REQUEST",
    success: "ORGANIZATIONS/FETCH_AFFILIATIONS_SUCCESS",
    error: "ORGANIZATIONS/FETCH_AFFILIATIONS_ERROR"
  },
  reset: "ORGANIZATIONS/RESET"
};
