import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Content,
  ContainerWrap,
  WrapButtons,
  WrapItem,
  Title,
  ErrorList,
  ErrorItem,
  DialogText,
  Timezone,
  Required,
  Row,
} from './styled';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import {
  TOURNAMENT_ASIDE_NAV_ITEMS,
  validateStage,
  compressionOptions,
} from './constants';
import AsideNav from '../../../components/presentation/AsideNav/AsideNav';
import withWidth from '../../../hoc/withWidth';
import Type from './Type/Type';
import Info from './Info/Info';
import Prizes from './Prizes/Prizes';
import Images from './Images/Images';
import Players from './Players/Players';
import Stages from './Stages/Stages';
import Review from './Review/Review';
import ButtonLinkKit from '../../../components/kit/Button/ButtonLinkKit';
import ButtonKit from '../../../components/kit/Button/ButtonKit';
import { api } from '../../../index';
import {
  getUsername,
  propValueOr,
  safePropValueOr,
} from '../../../helpers/common';
import moment from 'moment';
import { isEqual, set } from 'lodash';
import DialogKit from '../../../components/kit/Dialog/DialogKit';
import iconCheck from '../../../static/icons/circle-check-green.svg';
import iconX from '../../../static/icons/circle-x-red.svg';
import PermissionDenied from '../../denied/PermissionDenied';
import Communications from './Communications/Communications';
import imageCompression from 'browser-image-compression';
import * as queryString from 'query-string';
import { fetchTournamentAction } from '../../../store/tournament/actions';
import momentTz from 'moment-timezone';
import FormFieldKit from '../../../components/kit/Fields/FormField/FormField';
const zone_name = momentTz.tz.guess();
const timezone = momentTz.tz(zone_name).zoneAbbr();

class TournamentCreate extends Component {
  constructor(props) {
    super();

    this.state = this.initialState();
  }

  initialState = () => {
    return {
      id: null,
      name: null,
      date: '',
      description: '',
      minPlayers: 2,
      maxPlayers: null,
      isTeamTournament: false,
      teamSize: null,
      maxTeam: null,
      gender: null,
      organizationGroupId: null,
      firstPlace: 500, //prizes[0]
      secondPlace: 250, //prizes[1],
      firstPlaceCoins: null,
      secondPlaceCoins: null,
      firstPlaceGems: null,
      secondPlaceGems: null,
      inviteToken: null,
      entryFee: null,
      walletFee: null,
      survey: null,
      prize: null,
      organizationId: undefined,
      matchTemplateId: undefined,
      maxTournaments: 1,
      imageInfo: {
        thumbnail: '',
        background: '',
        icon: '',
        event: '',
        mobile: '',
      },
      thumbnail: null,
      background: null,
      icon: null,
      event: null,
      mobile: null,
      ad1: null,
      ad2: null,
      ad3: null,
      pdf1: null,
      pdf2: null,
      pdf3: null,
      pdf4: null,
      showIcon: false,
      creating: false,
      deleting: false,
      tournaments: [],
      users: [],
      status: 'draft',
      system: undefined,
      matchTemplateOptions: [],
      stages: [],
      ageRestrictionFrom: null,
      ageRestrictionTo: null,
      countries: [],
      startTime: moment()
        .add(15, 'm')
        .toDate(),
      type: 'scheduled',
      mode: 'game-driven',
      preview: 'none',
      visibility: 'anyone',
      error: {},
      errors: {},
      isCreatedDialogOpen: false,
      isDeleteDialogOpen: false,
      isStageDialogOpen: false,
      isCreated: false,
      overrideMatchTemplateId: undefined,
      readOnly: false,
      clickedNext: false,
      defaultSettings: {},
      isConfirmDialogOpen: false,
      errorMessage: '',
      templates: [],
      events: [],
      stageCreated: false,
      isTwitchRequired: false,
      isAccountRequired: false,
      featured: null,
      tournamentTemplate: false,
      autoCreate: 0,
      affiliationWide: false,
      hideStageInSchedule: false,
      matchMessage: '',
    };
  };

  getTournamentCopy = () => {
    const { matchTemplateOptions } = this.state;

    // Check if a tournament id was passed in the url
    const parsed = queryString.parse(this.props.location.search);
    if (parsed?.t && parsed?.t?.length > 0) {
      // Get tournament by token
      api.admin.tournament
        .get(parsed?.t)
        .then(resp => {
          const {
            name,
            description,
            startDate,
            registrationStart,
            registrationEnd,
            checkinStart,
            checkinEnd,
            checkinRequired,
            verifiedRequired,
            minPlayers,
            maxPlayers,
            restrictions,
            entryFee,
            walletFee,
            survey,
            inviteToken,
            prizes,
            imageInfo,
            stages,
            events,
            mode,
            preview,
            teamSize,
            maxTeam,
            coins,
            MatchTemplate,
            ConsoleId,
            type,
            announcements,
            metadata,
          } = resp?.data || {};

          this.setState({
            name: `Copy - ${name}`,
            description,
            minPlayers,
            maxPlayers,
            isTeamTournament: parseInt(teamSize) > 1,
            teamSize: teamSize || null,
            maxTeam: maxTeam || null,
            gender: restrictions.gender,
            firstPlace: prizes?.[0] || 500, // Rival Points
            secondPlace: prizes?.[1] || 250, // Rival Points,
            firstPlaceCoins: coins?.[0] || null, // Rivals (coins)
            secondPlaceCoins: coins?.[1] || null, // Rivals (coins)
            inviteToken: inviteToken || null,
            entryFee: entryFee / 100 || null,
            walletFee: walletFee || null,
            survey: survey || null,
            matchTemplateId: MatchTemplate?.id || null,
            system: ConsoleId,
            imageInfo: {
              thumbnail: imageInfo?.thumbnail,
              background: imageInfo?.background,
              icon: imageInfo?.icon,
              event: imageInfo?.event,
              mobile: imageInfo?.mobile,
              ...(imageInfo?.ad1 && { ad1: imageInfo.ad1 }),
              ...(imageInfo?.ad2 && { ad2: imageInfo.ad2 }),
              ...(imageInfo?.ad3 && { ad3: imageInfo.ad3 }),
              ...(imageInfo?.pdf1 && { pdf1: imageInfo.pdf1 }),
              ...(imageInfo?.pdf2 && { pdf2: imageInfo.pdf2 }),
              ...(imageInfo?.pdf3 && { pdf3: imageInfo.pdf3 }),
              ...(imageInfo?.pdf4 && { pdf3: imageInfo.pdf4 }),
            },
            stages: stages
              ? stages
                  .sort((a, b) => a.stage - b.stage)
                  .map((stage, i) => {
                    const {
                      id,
                      status,
                      startedAt,
                      endedAt,
                      createdAt,
                      updatedAt,
                      brackets,
                      ...rest
                    } = stage;
                    return { ...rest, index: i };
                  })
              : [],
            ageRestrictionFrom: restrictions?.age?.from || null,
            ageRestrictionTo: restrictions?.age?.to || null,
            countries: restrictions?.countries || [],
            startTime: startDate,
            type: type || 'scheduled',
            mode: mode || 'game-driven',
            preview: preview || 'none',
            defaultSettings:
              matchTemplateOptions?.find(mt => mt.value === MatchTemplate?.id)
                ?.settings || {},
            stageCreated: stages?.length > 0,
            isTwitchRequired: restrictions?.requireTwitch,
            isAccountRequired: !!restrictions?.username,
            registrationStart: registrationStart,
            registrationEnd: registrationEnd,
            checkinStart: checkinStart,
            checkinEnd: checkinEnd,
            checkinRequired,
            verifiedRequired,
            hideStageInSchedule: metadata?.hideStageInSchedule || false,
            matchMessage: metadata?.matchMessage || '',
            events: (events || []).map(e => ({
              name: e?.event || '',
              templateId: e?.communicationMessageId || null,
            })),
            ...(announcements?.length > 0 && {
              announcements: announcements.map(
                ({ message, order, deletedAt, translations }) => ({
                  message,
                  ...(order && { order }),
                  ...(translations && { translations }),
                  ...(deletedAt && { deletedAt }),
                })
              ),
            }),
            ...((imageInfo?.prize?.description || imageInfo?.prize?.title) && {
              prize: {
                ...(imageInfo?.prize?.title && {
                  title: imageInfo?.prize.title,
                }),
                ...(imageInfo?.prize?.description && {
                  description: imageInfo?.prize.description,
                }),
              },
            }),
            prizeImage: imageInfo?.prize?.image || undefined,
            ...(imageInfo?.prize?.image && { useCopiedPrizeImage: true }),
          });
        })
        .catch(err => {
          window.alert('Failed to load in tournament copy data.');
        });
    }
  };

  componentDidMount() {
    Promise.all([
      api.admin.matchTemplates.get(),
      api.getTemplatesEmails(),
      api.getGlobalTemplatesEmails(),
    ])
      .then(resp => {
        const [templateOptionsResp, templatesResp, globalTemplatesResp] = resp;

        if (templateOptionsResp) {
          if (Array.isArray(templateOptionsResp.data)) {
            const options = templateOptionsResp.data.map(mt => ({
              label: safePropValueOr(mt, 'displayName'),
              value: mt.id,
              system: safePropValueOr(mt, 'ConsoleId'),
              imageInfo: {
                mobile: safePropValueOr(mt, 'Game.imageInfo.event', ''),
                ...safePropValueOr(mt, 'Game.imageInfo', {}),
              },
              settings: safePropValueOr(mt, 'settings', []),
              metadata: safePropValueOr(mt, 'metadata', {}),
              username: safePropValueOr(mt, 'username', ''),
            }));
            this.setState({ matchTemplateOptions: options });
          }
        }

        let templates = [];
        if (templatesResp) {
          templates = templates.concat(
            templatesResp.data.docs.map(t => ({
              label: t.name,
              value: t.id,
              id: t.id,
              type: 'template',
            }))
          );
        }

        if (globalTemplatesResp) {
          templates = templates.concat(
            globalTemplatesResp.data.docs.map(t => ({
              label: `[GLOBAL] ${t.name}`,
              value: t.id,
              id: t.id,
              type: 'global_template',
            }))
          );
        }

        this.setState({ templates });
      })
      .catch(err => console.log(err))
      .finally(this.getTournamentCopy);

    if (window.location.pathname !== '/tournaments/create/type') {
      this.props.history.push({
        pathname: '/tournaments/create/type',
        search: this.props.location.search,
      });
    }
  }

  renderConfirmDialog = () => {
    return (
      <DialogKit
        isOpen={this.state.isConfirmDialogOpen}
        onClose={() => this.setState({ isConfirmDialogOpen: false })}
        displayButtonClose
        title={
          this.state.isCreated
            ? this.state.errorMessage
              ? 'Failed'
              : 'Success!'
            : 'Create Tournament'
        }
        renderBody={() => (
          <DialogText>
            {this.state.isCreated ? (
              this.state.errorMessage ? (
                <>
                  <img
                    src={iconX}
                    width={20}
                    height={20}
                    style={{ marginRight: '8px' }}
                    alt=""
                  />
                  Error creating tournament: {this.state.errorMessage}
                </>
              ) : (
                <>
                  <img
                    src={iconCheck}
                    width={20}
                    height={20}
                    style={{ marginRight: '8px' }}
                    alt=""
                  />
                  The Tournament has been created! You will now be redirected to
                  the Tournament Creator homepage.
                </>
              )
            ) : (
              'Are you sure you want to create the tournament?'
            )}
          </DialogText>
        )}
        renderFooter={() =>
          this.state.isCreated ? (
            <ButtonKit
              fullWidth
              shape={'rounded'}
              color={'green'}
              onClick={() => this.setState({ isConfirmDialogOpen: false })}
            >
              Okay
            </ButtonKit>
          ) : (
            <>
              <ButtonKit
                fullWidth
                shape={'rounded'}
                color={'rival_red'}
                onClick={() => this.createTournament()}
                preloader={this.state.creating}
                style={{ marginBottom: '15px' }}
              >
                Yes, Create!
              </ButtonKit>
              <ButtonKit
                fullWidth
                shape={'rounded'}
                appearance={'secondary'}
                onClick={() => this.setState({ isConfirmDialogOpen: false })}
              >
                Cancel
              </ButtonKit>
            </>
          )
        }
      />
    );
  };

  addUser = user => {
    // FieldTypeAndSelect sometimes can return null
    if (!user) {
      return;
    }

    let users = [...this.state.users];

    let index = users.findIndex(c => c.id === user.id);
    if (index === -1 && user.id !== undefined) {
      users.push(user);
    }

    this.setState({ users });
  };

  removeUser = (newUsers = []) => {
    this.setState({
      users: this.state.users.filter(u => newUsers.includes(getUsername(u))),
    });
  };

  addStage = (settings = null) => {
    let stages = this.state.stages;

    //add stage
    stages.push({
      index: stages.length,
      name: null,
      stage: stages.length + 1,
      type: null,
      startDate: null,
      minPlayers: 2,
      maxPlayers: null,
      maxRounds: null,
      maxBrackets: 1,
      prizes: null,
      settings: settings || this.state.defaultSettings,
      roundInfo: {},
      validated: false,
    });
    this.setState({ stages: stages, stageCreated: true });
  };

  handleStage = stage => {
    let stages = this.state.stages;

    //edit existing stage or add new one
    if (stage.index !== undefined && stage.index !== null) {
      stages[stage.index] = { ...stage };
      this.setState({ stages: stages });
    }
  };

  deleteStage = stage => {
    let stages = this.state.stages;

    //edit existing stage or add new one
    if (stage.index !== undefined && stage.index !== null) {
      if (stage.index > -1) {
        stages.splice(stage.index, 1);
      }
      this.setState({ stages: stages });
    }
  };

  handleChangeAd = async (propName, innerPropName, val) => {
    if (innerPropName === 'image') {
      val = await imageCompression(val, compressionOptions);
    }
    this.setState({
      [propName]: { ...this.state[propName], [innerPropName]: val },
    });
  };

  handleChangePDF = async (propName, innerPropName, val) => {
    this.setState({
      [propName]: { ...this.state[propName], [innerPropName]: val },
    });
  };

  handleChangePrize = async (propName, innerPropName, val) => {
    if (innerPropName === 'image' && val != null) {
      val = await imageCompression(val, compressionOptions);
    }
    this.setState({
      [propName]: { ...this.state[propName], [innerPropName]: val },
    });
  };

  handleChangeImage = async (propName, val) => {
    let compressedImage = await imageCompression(val, compressionOptions);
    this.setState({ [propName]: compressedImage });
  };

  handleChangeField = (propName, val) => {
    this.setState({ [propName]: val });
  };

  validateFields = () => {
    const {
      name,
      startTime,
      system,
      minPlayers,
      maxPlayers,
      matchTemplateId,
      type,
      stages,
      registrationStart,
      registrationEnd,
      checkinStart,
      checkinEnd,
      visibility,
      users,
      teamSize,
      maxTeam,
    } = this.state;

    const valid =
      !!name &&
      system !== undefined &&
      !!matchTemplateId &&
      stages.length > 0 &&
      !!minPlayers &&
      (maxPlayers === null || minPlayers <= maxPlayers) &&
      (teamSize === null || teamSize >= 2) &&
      (maxTeam === null ? true : maxTeam >= teamSize && maxTeam <= 15) &&
      (registrationStart ? !!registrationEnd : true) &&
      (checkinStart ? !!checkinEnd : true) &&
      !!type &&
      (type === 'scheduled' ? !!startTime : true) &&
      !!visibility &&
      (visibility === 'invitational' ? users.length >= minPlayers : true);
    return valid;
  };

  validateStepFields = step => {
    const {
      name,
      startTime,
      registrationStart,
      registrationEnd,
      checkinStart,
      checkinEnd,
      checkinRequired,
      system,
      minPlayers,
      maxPlayers,
      ageRestrictionFrom,
      ageRestrictionTo,
      matchTemplateId,
      type,
      stages,
      ad1,
      ad2,
      ad3,
      errors,
      teamSize,
      maxTeam,
      isTeamTournament,
      firstPlace,
      secondPlace,
      firstPlaceCoins,
      secondPlaceCoins,
    } = this.state;
    const { organization } = this.props;

    let newErrors = {};
    if (step === 'Type') {
      if (type === 'scheduled') {
        if (!startTime) {
          set(
            newErrors,
            'Type.startTime',
            'Must have a start time for a scheduled tournament.'
          );
        } else if (moment(startTime).isBefore(moment(), 'minute')) {
          set(
            newErrors,
            'Type.startTimePast',
            'Cannot schedule a tournament in the past.'
          );
        }
        if (registrationStart) {
          if (
            moment(registrationEnd).isBefore(
              moment(registrationStart),
              'minute'
            )
          ) {
            set(
              newErrors,
              'Type.regEndBefore',
              'Registration end cannot be before registration start.'
            );
          }
          if (!registrationEnd) {
            set(
              newErrors,
              'Type.regEndNull',
              'Please select a registration end date.'
            );
          }
        }
        if (registrationEnd) {
          if (!registrationStart) {
            set(
              newErrors,
              'Type.regStartNull',
              'Please select a registration start date.'
            );
          }
        }
        if (checkinRequired !== false && checkinRequired !== true) {
          set(
            newErrors,
            'Type.checkinRequired',
            'Please select whether check-in should be required or not'
          );
        }
        if (checkinRequired) {
          if (!checkinStart) {
            set(
              newErrors,
              'Type.checkStartNull',
              'Please select a check-in start date.'
            );
          }
          if (!checkinEnd) {
            set(
              newErrors,
              'Type.checkEndNull',
              'Please select a check-in end date.'
            );
          }
        }
        if (checkinStart) {
          if (moment(checkinEnd).isBefore(moment(checkinStart), 'minute')) {
            set(
              newErrors,
              'Type.checkEndBefore',
              'Check-in end cannot be before check-in start.'
            );
          }
          if (!checkinEnd) {
            set(
              newErrors,
              'Type.checkEndNull',
              'Please select a check-in end date.'
            );
          }
          if (
            registrationStart &&
            moment(checkinStart).isBefore(moment(registrationStart), 'minute')
          ) {
            set(
              newErrors,
              'Type.checkStartBefore',
              'Check-in start cannot be before registration start.'
            );
          }
          if (
            registrationEnd &&
            moment(checkinEnd).isBefore(moment(registrationEnd), 'minute')
          ) {
            set(
              newErrors,
              'Type.checkEndBeforeReg',
              'Check-in end cannot be before registration end.'
            );
          }
        }
        if (checkinEnd) {
          if (!checkinStart) {
            set(
              newErrors,
              'Type.checkStartNull',
              'Please select a check-in start date.'
            );
          }
        }
        if (startTime) {
          if (
            checkinEnd &&
            moment(checkinEnd).isAfter(moment(startTime), 'minute')
          ) {
            set(
              newErrors,
              'Type.startTimeCheck',
              'Check-In end time must be equal to or before the tournament start time'
            );
          }
          if (
            registrationEnd &&
            moment(registrationEnd).isAfter(moment(startTime), 'minute')
          ) {
            set(
              newErrors,
              'Type.startTimeReg',
              'Registration end time must be equal to or before the tournament start time'
            );
          }
        }
      }
    } else if (step === 'Info') {
      if (!name) {
        set(newErrors, 'Info.name', 'Must provide a tournament name.');
      }
      if (system === undefined || !matchTemplateId) {
        set(newErrors, 'Info.system', 'Must select a console and game.');
      }
      if (!minPlayers) {
        set(
          newErrors,
          'Info.minPlayers',
          'Must set a minimum number of players'
        );
      }
      if (maxPlayers !== null && minPlayers > maxPlayers) {
        set(
          newErrors,
          'Info.maxPlayers',
          'Must be greater than or equal to min players'
        );
      }
      if (isTeamTournament == null) {
        set(newErrors, 'Info.isTeamTournament', 'Must select an option');
      }
      if (isTeamTournament && (teamSize == null || teamSize < 2)) {
        set(
          newErrors,
          'Info.teamSize',
          'Team size is required and must be greater than or equal to 2'
        );
      }
      if (
        isTeamTournament &&
        (maxTeam == null
          ? false
          : maxTeam < teamSize || maxTeam > 15 || isNaN(parseInt(maxTeam)))
      ) {
        set(
          newErrors,
          'Info.teamSize',
          'Team size is required and must be greater than or equal to 2'
        );
      }
      if (ageRestrictionFrom != null) {
        if (!Number.isInteger(ageRestrictionFrom)) {
          set(
            newErrors,
            'Info.ageRestrictionFrom',
            'Must be an integer between 1 and 100'
          );
        }
        if (ageRestrictionFrom < 1 || ageRestrictionFrom > 100) {
          set(
            newErrors,
            'Info.ageRestrictionFrom',
            'Must be an integer between 1 and 100.'
          );
        }
      }
      if (ageRestrictionTo != null) {
        if (!Number.isInteger(ageRestrictionTo)) {
          set(
            newErrors,
            'Info.ageRestrictionTo',
            'Must be an integer between 1 and 100'
          );
        }
        if (ageRestrictionTo < 1 || ageRestrictionTo > 100) {
          set(
            newErrors,
            'Info.ageRestrictionTo',
            'Must be an integer between 1 and 100.'
          );
        }
      }
      if (
        ageRestrictionFrom &&
        ageRestrictionTo &&
        ageRestrictionFrom > ageRestrictionTo
      ) {
        set(
          newErrors,
          'Info.ageRestrictionFrom',
          'Must be less than or equal to the max age restriction'
        );
        set(
          newErrors,
          'Info.ageRestrictionTo',
          'Must be greater than or equal to the min age restriction'
        );
      }
    } else if (step === 'Prizes') {
      if (!firstPlace) {
        set(
          newErrors,
          'Prizes.firstPlace',
          'Must set number of points awarded for first place'
        );
      }
      if (!secondPlace) {
        set(
          newErrors,
          'Prizes.secondPlace',
          'Must set number of points awarded for second place'
        );
      }
      if (secondPlaceCoins && !firstPlaceCoins) {
        set(
          newErrors,
          'Prizes.firstPlaceCoins',
          'Must set 1st Place Rivals award'
        );
      } else if (firstPlaceCoins && firstPlaceCoins < 0) {
        set(newErrors, 'Prizes.firstPlaceCoins', 'Must be a positive number');
      } else if (secondPlaceCoins && secondPlaceCoins < 0) {
        set(newErrors, 'Prizes.secondPlaceCoins', 'Must be a positive number');
      } else if (firstPlaceCoins) {
        if (
          (firstPlaceCoins + secondPlaceCoins || 0) >
          propValueOr(organization, 'wallet')
        )
          set(
            newErrors,
            'Prizes.firstPlaceCoins',
            'Not enough Rivals to create tournament. Please add more Rivals to organization.'
          );
      }
    } else if (step === 'Images') {
      if (
        ad1 &&
        ad1.image &&
        ad1.link &&
        !ad1.link.toLowerCase().includes('http')
      ) {
        set(newErrors, 'Images.ad1.link', 'Must be http(s) link.');
      }
      if (
        ad2 &&
        ad2.image &&
        ad2.link &&
        !ad2.link.toLowerCase().includes('http')
      ) {
        set(newErrors, 'Images.ad2.link', 'Must be http(s) link.');
      }
      if (
        ad3 &&
        ad3.image &&
        ad3.link &&
        !ad3.link.toLowerCase().includes('http')
      ) {
        set(newErrors, 'Images.ad3.link', 'Must be http(s) link.');
      }
    } else if (step === 'Stages') {
      if (!(stages && stages.length > 0)) {
        set(newErrors, 'Stages.error', 'Need at least one stage.');
      } else {
        let stageErrors = { errors: [] };
        stages.forEach((s, i) => {
          let currStage = s.name ? s.name : 'Stage ' + s.stage;
          if (minPlayers < s.minPlayers) {
            set(
              newErrors,
              'Stage.min',
              'Cannot be more than tournament minimum.'
            );
          }
          if (!s.maxBrackets) {
            set(newErrors, 'Stage.maxBrackets', 'Must be 1 or greater');
          }
          if (type === 'scheduled') {
            if (
              s.startDate &&
              moment(startTime).isAfter(moment(s.startDate), 'minute')
            ) {
              let err =
                'Stage start date cannot be before tournament start date.';
              set(newErrors, 'Stage.startDate', err);
              stageErrors.errors.push(err);
            }
            if (stages[i - 1]) {
              let prevStage = stages[i - 1];
              let name = prevStage.name
                ? prevStage.name
                : 'Stage ' + prevStage.stage;
              if (
                prevStage.stage < s.stage &&
                prevStage.startDate &&
                s.startDate &&
                moment(prevStage.startDate).isAfter(
                  moment(s.startDate),
                  'minute'
                )
              ) {
                let err = currStage + ` cannot begin before ` + name;
                set(newErrors, 'Stage.stageStart', err);
                stageErrors.errors.push(err);
              }
            }
            if (stages[i].roundInfo) {
              let rounds = Object.values(stages[i].roundInfo);
              if (
                stages[i].type === 'single elimination' &&
                stages[i].maxPlayers === null
              ) {
                rounds.reverse();
              }
              rounds.forEach((r, j) => {
                if (
                  r.date &&
                  s.startDate &&
                  moment(r.date).isBefore(moment(s.startDate), 'minute')
                ) {
                  let err = `Round ${j +
                    1} cannot begin before ${currStage} start date`;
                  set(newErrors, `Stage.round${j + 1}Start`, err);
                  stageErrors.errors.push(err);
                }
                if (
                  r.date &&
                  startTime &&
                  moment(r.date).isBefore(moment(startTime), 'minute')
                ) {
                  let err = `Round ${j +
                    1} cannot begin before tournament start`;
                  set(newErrors, `Stage.round${j + 1}Tournament`, err);
                  stageErrors.errors.push(err);
                }
                if (rounds[j - 1]) {
                  let prevRound = rounds[j - 1];
                  if (
                    prevRound.date &&
                    r.date &&
                    moment(prevRound.date).isAfter(moment(r.date), 'minute')
                  ) {
                    let err = `Round ${j + 1} cannot begin before Round ${j}`;
                    set(newErrors, 'Stage.rounds', err);
                    stageErrors.errors.push(err);
                  }
                }
              });
            }
          }
          if (
            s.type !== 'single elimination' &&
            s.type !== 'swiss' &&
            s.type !== 'double elimination' &&
            s.type !== 'free for all'
          ) {
            set(newErrors, 'Stage.nullType', 'Must set elimination type');
          }
          if (
            s.type === 'single elimination' ||
            s.type === 'double elimination'
          ) {
            // for (const round in s.roundInfo) {
            //   if (s.roundInfo[round].ready === undefined) {
            //     set(
            //       newErrors,
            //       "Stage.readyRequired",
            //       "Must set ready required"
            //     );
            //   }
            // }
          }
          const err = validateStage(s, this.state);
          if (err) stageErrors.errors.push(err);
        });
        if (stageErrors.errors.length > 0) {
          set(newErrors, 'Stages', stageErrors);
        }
      }
    } else if (step === 'Review') {
      if (!this.validateFields()) {
        set(
          newErrors,
          'Review',
          'There are one or more errors with the info given. Please go back to each step and fix the issues.'
        );
      }
    }

    if (!isEqual(errors, newErrors)) {
      this.setState({ errors: newErrors });
    }
  };

  createTournament = () => {
    this.setState({ creating: true });

    const {
      name,
      startTime,
      description,
      minPlayers,
      maxPlayers,
      teamSize,
      maxTeam,
      gender,
      organizationId,
      organizationGroupId,
      matchTemplateId,
      imageInfo,
      thumbnail,
      background,
      icon,
      event,
      mobile,
      showIcon,
      type,
      mode,
      preview,
      countries,
      ageRestrictionFrom,
      ageRestrictionTo,
      stages,
      registrationStart,
      registrationEnd,
      checkinStart,
      checkinEnd,
      checkinRequired,
      ad1,
      ad2,
      ad3,
      pdf1,
      pdf2,
      pdf3,
      pdf4,
      firstPlace,
      secondPlace,
      firstPlaceCoins,
      secondPlaceCoins,
      inviteToken,
      entryFee,
      walletFee,
      survey,
      prize,
      prizeImage,
      useCopiedPrizeImage,
      events,
      isTwitchRequired,
      isAccountRequired,
      verifiedRequired,
      matchTemplateOptions,
      featured,
      tournamentTemplate,
      autoCreate,
      affiliationWide,
      announcements,
      hideStageInSchedule,
      matchMessage,
    } = this.state;

    const matchTemplateUsername = propValueOr(
      matchTemplateOptions.find(mt => mt.value === matchTemplateId),
      'username'
    );

    const newMode = stages?.some(s => s?.type === 'free for all')
      ? 'round-driven'
      : mode;

    const body = {
      name: name,
      startDate: moment(startTime).format('YYYY-MM-DD HH:mm:ssZZ'),
      description: description, //short description (<200 char)
      type: type,
      mode: newMode,
      preview: preview !== 'none' ? preview : null,
      minPlayers: minPlayers,
      maxPlayers: maxPlayers,
      teamSize: teamSize,
      ...(maxTeam && { maxTeam }),
      ...(tournamentTemplate && { tournamentTemplate: true }),
      ...(autoCreate && { autoCreate: autoCreate }),
      affiliationId: this.props.organization.affiliationId,
      organizationId: affiliationWide
        ? null
        : organizationId || this.props.user.info.organizationId,
      organizationGroupId: organizationGroupId,
      MatchTemplateId: matchTemplateId,
      imageInfo: JSON.stringify(imageInfo),
      ...(featured && { featured: featured }),
      ...(thumbnail && {
        thumbnail: thumbnail,
      }),
      ...(background && {
        background: background,
      }),
      ...(icon && {
        icon: icon,
      }),
      ...(event && {
        event: event,
      }),
      ...(mobile && {
        mobile: mobile,
      }),
      ...(ad1 && {
        ad1: ad1.image,
        ...(ad1.link && { ad1Link: ad1.link }),
      }),
      ...(ad2 && {
        ad2: ad2.image,
        ...(ad2.link && { ad2Link: ad2.link }),
      }),
      ...(ad3 && {
        ad3: ad3.image,
        ...(ad3.link && { ad3Link: ad3.link }),
      }),
      ...(pdf1 && {
        pdf1: pdf1.link,
        ...(pdf1.title && { pdf1Title: pdf1.title }),
      }),
      ...(pdf2 && {
        pdf2: pdf2.link,
        ...(pdf2.title && { pdf2Title: pdf2.title }),
      }),
      ...(pdf3 && {
        pdf3: pdf3.link,
        ...(pdf3.title && { pdf3Title: pdf3.title }),
      }),
      ...(pdf4 && {
        pdf4: pdf4.link,
        ...(pdf4.title && { pdf4Title: pdf4.title }),
      }),
      ...(useCopiedPrizeImage && prizeImage && !prize?.image && { prizeImage }),
      ...(prize && !!prize.image && { prize: prize.image }),
      ...(prize && !!prize.title && { prizeTitle: prize.title }),
      ...(prize &&
        !!prize.description && { prizeDescription: prize.description }),
      metadata: JSON.stringify({
        showIcon: showIcon && !!icon,
        ...(hideStageInSchedule && { hideStageInSchedule }),
        ...(matchMessage && { matchMessage }),
      }),
      restrictions: JSON.stringify({
        ...((ageRestrictionFrom || ageRestrictionTo) && {
          age: {
            ...(ageRestrictionFrom && { from: parseInt(ageRestrictionFrom) }),
            ...(ageRestrictionTo && { to: parseInt(ageRestrictionTo) }),
          },
        }),
        ...(countries.length > 0 && {
          countries: countries,
        }),
        ...(isTwitchRequired && {
          requireTwitch: true,
        }),
        ...(isAccountRequired && {
          username: matchTemplateUsername,
        }),
        ...(gender && { gender }),
      }),
      ...(type === 'scheduled' &&
        teamSize > 1 &&
        verifiedRequired && {
          verifiedRequired: true,
        }),
      prizes: JSON.stringify([firstPlace || 500, secondPlace || 250]),
      ...(firstPlaceCoins && {
        coins: JSON.stringify([
          ...(firstPlaceCoins > 0 ? [firstPlaceCoins] : []),
          ...(firstPlaceCoins > 0 && secondPlaceCoins > 0
            ? [secondPlaceCoins]
            : []),
        ]),
      }),
      ...(registrationStart && {
        registrationStart: moment(registrationStart).format(
          'YYYY-MM-DD HH:mm:ssZZ'
        ),
      }),
      ...(registrationEnd && {
        registrationEnd: moment(registrationEnd).format(
          'YYYY-MM-DD HH:mm:ssZZ'
        ),
      }),
      ...(checkinStart && {
        checkinStart: moment(checkinStart).format('YYYY-MM-DD HH:mm:ssZZ'),
      }),
      ...(checkinEnd && {
        checkinEnd: moment(checkinEnd).format('YYYY-MM-DD HH:mm:ssZZ'),
      }),
      ...(checkinStart &&
        checkinRequired && {
          checkinRequired: checkinRequired,
        }),
      ...(inviteToken && {
        inviteToken: inviteToken,
      }),
      ...(entryFee &&
        entryFee !== 0 && {
          entryFee: entryFee * 100,
        }),
      ...(walletFee &&
        walletFee !== 0 && {
          walletFee: walletFee,
        }),
      ...(survey && {
        survey: survey,
      }),
      ...(announcements?.length > 0 && {
        announcements: JSON.stringify(announcements),
      }),
      stages: JSON.stringify(
        stages.map(s => {
          const roundInfo = s.roundInfo;
          Object.keys(roundInfo).forEach(r => delete roundInfo[r].val);
          return {
            ...s,
            roundInfo,
            prizes: [500, 250],
            MatchTemplateId: matchTemplateId,
          };
        })
      ),
      events: JSON.stringify(events),
    };

    let data = new FormData();
    for (let key in body) {
      if (body[key] != null) {
        data.append(key, body[key]);
      }
    }

    api.admin.tournament
      .create(data)
      .then(() => {
        this.setState({
          isConfirmDialogOpen: true,
          isCreated: true,
          creating: false,
        });
        setTimeout(() => {
          this.setState({
            ...this.initialState(),
          });
          this.disableItems();
          this.props.history.push('/tournaments/create');
        }, 2000);
      })
      .catch(err => {
        this.setState({
          creating: false,
          isCreated: false,
          isConfirmDialogOpen: true,
          errorMessage: propValueOr(
            err,
            'response.data.message',
            err.message || 'Unknown error. Please Try again later.'
          ),
        });
      });
  };

  renderContent = () => (
    <Content>
      <Switch>
        {TOURNAMENT_ASIDE_NAV_ITEMS.map((route, i) => (
          <Route
            exact
            key={route.to}
            path={route.to}
            render={() => this.renderItem(route, i)}
          />
        ))}
        <Route render={() => <Redirect to={'/tournaments/create/type'} />} />
      </Switch>
    </Content>
  );

  enableItem = item => {
    //enables current item and previous items
    for (let i = 0; i < TOURNAMENT_ASIDE_NAV_ITEMS.length; i++) {
      if (TOURNAMENT_ASIDE_NAV_ITEMS[i].title === item.title) {
        item.disabled = false;
        break;
      } else {
        TOURNAMENT_ASIDE_NAV_ITEMS[i].disabled = false;
      }
    }
  };

  disableItems = () => {
    //disables all but first item
    for (let i = 1; i < TOURNAMENT_ASIDE_NAV_ITEMS.length; i++) {
      TOURNAMENT_ASIDE_NAV_ITEMS[i].disabled = true;
    }
  };

  renderActiveItem = item => {
    const {
      visibility,
      users,
      stages,
      errors,
      hideStageInSchedule,
    } = this.state;

    this.validateStepFields(item.title);
    if (!this.state.errors[item.title] && this.state.clickedNext) {
      this.setState({ clickedNext: false });
      this.enableItem(item);
    }

    switch (item.title) {
      case 'Type':
        return (
          <Type
            onChangeField={(propName, val) =>
              this.handleChangeField(propName, val)
            }
            formState={this.state}
            validateFields={this.validateStepFields}
            organization={this.props.organization}
          />
        );
      case 'Info':
        return (
          <Info
            onChangeField={(propName, val) =>
              this.handleChangeField(propName, val)
            }
            formState={this.state}
            addStage={this.addStage}
            consoles={this.props.consoles}
            organization={this.props.organization}
            updateStage={this.handleStage}
          />
        );
      case 'Prizes':
        return (
          <Prizes
            onChangeField={(propName, val) =>
              this.handleChangeField(propName, val)
            }
            onChangePrize={this.handleChangePrize}
            formState={this.state}
          />
        );
      case 'Images':
        return (
          <Images
            onChange={(propName, val) => this.handleChangeField(propName, val)}
            onChangeField={(propName, val) =>
              this.handleChangeImage(propName, val)
            }
            onChangeAd={this.handleChangeAd}
            onChangePDF={this.handleChangePDF}
            onResetField={propName => this.setState({ [propName]: null })}
            onResetAd={propName =>
              this.setState({
                [propName]: { ...this.state[propName], image: null },
              })
            }
            formState={this.state}
          />
        );
      case 'Players':
        return (
          <Players
            onChangeVisibility={val => this.setState({ visibility: val })}
            visibility={visibility}
            users={users}
            addUser={this.addUser}
            removeUser={this.removeUser}
          />
        );
      case 'Stages':
        return (
          <>
            <Row style={{ justifyContent: 'flex-start', marginBottom: '15px' }}>
              <FormFieldKit
                label={'Display Stage 1 Name in Schedule'}
                checkbox
                name={'hideStageInSchedule'}
                checked={!hideStageInSchedule}
                onChange={() =>
                  this.setState({ hideStageInSchedule: !hideStageInSchedule })
                }
                isBig
                width={'30%'}
              />
            </Row>
            <Stages
              stages={stages}
              handleStage={this.handleStage}
              addStage={this.addStage}
              deleteStage={this.deleteStage}
              errors={errors}
              formState={this.state}
            />
          </>
        );
      case 'Comms':
        return (
          <Communications
            onChangeField={(propName, val) =>
              this.handleChangeField(propName, val)
            }
            formState={this.state}
          />
        );
      case 'Review':
        return <Review {...this.state} />;
      default:
        return null;
    }
  };
  renderItem = (item, index) => {
    const { errors } = this.state;

    const stagesErrors = propValueOr(errors, 'Stages.errors', []);
    return (
      <WrapItem mobile={this.props.width < 900}>
        {item.subtitle && <Title>{item.subtitle}</Title>}
        {this.renderActiveItem(item)}
        <Required>
          <span>*</span> Required Field
        </Required>
        <Timezone>
          **<span>NOTE: </span>All time fields are displayed in{' '}
          <span>{zone_name} Time</span> (<span>{timezone}</span>)
        </Timezone>
        {item.title === 'Stages' && stagesErrors && stagesErrors.length > 0 && (
          <ErrorList>
            ERRORS
            {stagesErrors.map((err, i) => (
              <ErrorItem key={i}>{err}</ErrorItem>
            ))}
          </ErrorList>
        )}
        {item.title === 'Review' && errors.Review && (
          <ErrorList>
            <ErrorItem>{errors.Review}</ErrorItem>
          </ErrorList>
        )}
        <WrapButtons>
          {index !== 0 && (
            <ButtonLinkKit
              shape={'rounded'}
              width={'200px'}
              to={`${item.prev}${this.props.location.search || ''}`}
            >
              Back
            </ButtonLinkKit>
          )}
          {item.title === 'Review' ? (
            <ButtonKit
              shape={'rounded'}
              color={'rival_red'}
              width={'200px'}
              onClick={() => this.setState({ isConfirmDialogOpen: true })}
              preloader={this.state.creating}
              disabled={!this.validateFields()}
            >
              Create Tournament
            </ButtonKit>
          ) : (
            <ButtonLinkKit
              shape={'rounded'}
              color={'rival_red'}
              width={'200px'}
              to={
                !this.state.errors[item.title] &&
                `${item.next}${this.props.location.search || ''}`
              }
              disabled={!!this.state.errors[item.title]}
              onClick={() => this.setState({ clickedNext: true })}
            >
              Next Step
            </ButtonLinkKit>
          )}
        </WrapButtons>
      </WrapItem>
    );
  };

  renderDesktopMode = () => {
    const mobile = this.props.width < 900;
    return (
      <ContainerWrap mobile={mobile}>
        {!mobile && (
          <AsideNav items={TOURNAMENT_ASIDE_NAV_ITEMS} notClickable />
        )}
        {this.renderContent()}
        {this.renderConfirmDialog()}
      </ContainerWrap>
    );
  };

  render() {
    const { permission } = this.props;
    if (!permission) {
      return <PermissionDenied />;
    }
    return this.renderDesktopMode();
  }
}

const mapStateToProps = state => ({
  user: state.user,
  organization: state.organizationsState.organizations.find(
    o => o.id === propValueOr(state, 'user.info.organizationId', null)
  ),
  consoles: state.consoles.list,
  tournament: state.tournamentState.tournament,
});

const mapDispatchToProps = {
  fetchTournamentAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withWidth(TournamentCreate)));
