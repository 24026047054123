import styled, { css } from "styled-components";
import { COLOR, FONT_FAMILY, FONT_WEIGHT } from "../../constants/theme";
import breakpoints from "../../helpers/breakpoints";
import { Link } from "react-router-dom";

export const Container = styled.div`
  min-height: 100%;
  background-color: ${COLOR.BG_PRIMARY};
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  padding-bottom: 20px;
`;

export const ChallengesContainer = styled.div`
  min-height: 100%;
  background-color: ${COLOR.BG_PRIMARY};
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
`;

export const ContentCard = styled.div`
  display: flex;
  flex-direction: column;

  padding: 30px;
  border-radius: 16px;

  background-color: ${COLOR.BG_SECONDARY};
`;

export const Logo = styled.img`
  width: 126px;
  height: 15.33px;
  margin-right: 38px;
  ${breakpoints.down("700")} {
    margin-right: 0;
    margin-bottom: 12px;
  }
  ${breakpoints.down("500")} {
    width: 88.36px;
    height: 10.75px;
  }
`;

export const Subtitle = styled.div`
  font: ${FONT_WEIGHT.MEDIUM} 22px / 25px ${FONT_FAMILY.THIN};
  color: ${COLOR.TEXT};
  text-transform: uppercase;
  letter-spacing: 1.57px;
  text-align: center;
  margin-top: 12px;
  ${breakpoints.down("500")} {
    font-size: 18px;
  }
`;

export const Separator = styled.div`
  width: 32px;
  height: 4px;
  background-color: ${COLOR.ROUGE};
  margin-top: 12px;
`;

export const Content = styled.div`
  color: ${COLOR.TEXT};
  background-color: ${COLOR.BG_INPUT};
  padding: 30px 11.11%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* height: 80vh; */

  ${breakpoints.down("1400")} {
    padding: 20px;
  }
`;

export const Title = styled.div`
  color: ${COLOR.TEXT};
  font: ${FONT_WEIGHT.MEDIUM} 18px ${FONT_FAMILY.THIN};
  text-transform: uppercase;
  margin-bottom: 20px;
`;

export const FilterHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  width: 100%;
  background-color: ${COLOR.BG_SECONDARY};

  padding: 10px 0px;
`;

export const List = styled.div`
  color: ${COLOR.TEXT};
  border: 1px solid ${COLOR.BORDER};
`;
export const GameId = styled.div`
  color: ${COLOR.TEXT};
  font: ${FONT_WEIGHT.NORMAL} 14px / 16px ${FONT_FAMILY.THIN};
  text-transform: uppercase;
  text-decoration: none;
`;
export const Home = styled.div`
  color: ${COLOR.TEXT};
  font: ${FONT_WEIGHT.NORMAL} 14px / 16px ${FONT_FAMILY.THIN};
  text-decoration: none;
`;
export const Away = styled.div`
  color: ${COLOR.TEXT};
  font: ${FONT_WEIGHT.NORMAL} 14px / 16px ${FONT_FAMILY.THIN};
  text-decoration: none;
`;
export const TimeSubmitted = styled.div`
  color: ${COLOR.TEXT};
  font: ${FONT_WEIGHT.NORMAL} 14px / 16px ${FONT_FAMILY.THIN};
`;
export const Console = styled.img`
  width: 20px;
`;
export const Status = styled.div`
  color: ${COLOR.TEXT};
  display: flex;
  align-items: center;
  font: ${FONT_WEIGHT.NORMAL} 14px / 16px ${FONT_FAMILY.THIN};
`;
export const Dot = styled.div`
  background-color: ${({ active }) => (active ? COLOR.GREEN : "#B0B0B0")};
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 7px;

  ${({ active }) =>
    active &&
    css`
      animation: blinker 2s linear infinite;
    `};

  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }
`;
export const GameTitle = styled.div`
  color: ${COLOR.TEXT};
`;
export const Last = styled.div`
  color: ${COLOR.TEXT};
  font: ${FONT_WEIGHT.NORMAL} 14px / 16px ${FONT_FAMILY.THIN};
`;

export const TableEmpty = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 89px;
  padding-bottom: 67px;
`;

export const TitleEmpty = styled.div`
  margin-bottom: 20px;
  color: ${COLOR.TEXT_4};
  font-size: 14px;
  font-weight: ${FONT_WEIGHT.MEDIUM};
  line-height: 21px;
  text-align: center;
  text-transform: uppercase;
`;

export const Button = styled(Link)`
  color: ${COLOR.CHERRY_RED};
  text-decoration: none;
`;
