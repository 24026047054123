import styled from "styled-components";
import { COLOR, FONT_WEIGHT, FONT_FAMILY } from "../../constants/theme";
import breakpoints from "../../helpers/breakpoints";

export const Route500SD = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: ${COLOR.BG_PRIMARY};
  font-family: ${FONT_FAMILY.THIN};
`;

export const Route500ContentSD = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 495px;
  padding: 180px 15px;

  ${breakpoints.down("xs")} {
    padding-top: 115px;
    padding-bottom: 115px;
  }
`;

export const Route500WrapImageSD = styled.figure`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 40px;

  ${breakpoints.down("xs")} {
    width: 225px;
    margin-bottom: 52px;
  }
`;

export const Route500ImageSD = styled.img`
  max-width: 100%;
  height: auto;
  object-fit: contain;
`;

export const Route500TitleSD = styled.h1`
  margin: 0 0 14px;
  color: ${COLOR.TEXT_3};
  font-size: 28px;
  font-weight: ${FONT_WEIGHT.BOLD};

  ${breakpoints.down("xs")} {
    margin-bottom: 4px;
    font-size: 16px;
  }
`;

export const Route500DescriptionSD = styled.p`
  margin: 0 0 21px;
  color: ${COLOR.TEXT_4};
  font-size: 16px;
  font-weight: ${FONT_WEIGHT.LIGHT};

  ${breakpoints.down("xs")} {
    margin-bottom: 37px;
    font-size: 14px;
  }
`;
