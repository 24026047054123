import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import {
  COLOR,
  FONT_FAMILY,
  FONT_SIZE,
  FONT_WEIGHT
} from "../../../constants/theme";
import { addUnit } from "../../../helpers/common";

export const LabelKitSD = styled.label`
  color: ${COLOR.TEXT_3};
  font: ${FONT_WEIGHT.NORMAL} ${FONT_SIZE.LABEL}px / 1 ${FONT_FAMILY.GOTHIC};
`;

export const InputKitSD = styled.input`
  width: ${({ width, fullWidth }) => (fullWidth ? "100%" : addUnit(width))};
  height: ${({ multiline }) => (multiline ? 90 : 40)}px;
  padding: ${({ multiline }) => (multiline ? "12px 10px" : "5px 10px")};
  resize: none;

  background-color: ${({ bgColor }) => bgColor || COLOR.BG_INPUT};
  border: 1px solid
    ${({ hasError }) => (hasError ? COLOR.CHERRY_RED : COLOR.BG_PRIMARY)};
  border-radius: 2px;

  color: ${COLOR.TEXT_2};

  ${({ fontSize }) => {
    switch (fontSize) {
      case "large":
        return css`
          font: ${FONT_WEIGHT.BOLD} 30px / 1 ${FONT_FAMILY.GOTHIC};
        `;
      case "small":
        return css`
          font: ${FONT_WEIGHT.NORMAL} 14px / 1 ${FONT_FAMILY.GOTHIC};
        `;
      default:
        return css`
          font: ${FONT_WEIGHT.NORMAL} 16px / 1 ${FONT_FAMILY.GOTHIC}; // in Safari the browser will zoom if the font-size is less than 16px
        `;
    }
  }};

  letter-spacing: 0.4px;

  transition: all 0.3s;

  &::placeholder {
    color: ${COLOR.FG_INPUT};
    font-weight: ${FONT_WEIGHT.NORMAL};
  }

  &:focus {
    color: ${COLOR.FG_PRIMARY};
    border-color: ${COLOR.BG_INPUT};
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    background-color: ${COLOR.BG_INPUT};
  }
`;
InputKitSD.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fullWidth: PropTypes.bool,
  hasError: PropTypes.bool,
  fontSize: PropTypes.oneOf(["small", "normal", "large"])
};
InputKitSD.defaultProps = {
  width: 300,
  fullWidth: true,
  fontSize: "normal"
};
