import styled from "styled-components";
import {
  COLOR,
  FONT_WEIGHT,
  FONT_FAMILY
} from "../../../../../constants/theme";

export const Container = styled.div`
  width: 100%;
  background-color: ${COLOR.BG_PRIMARY};
  display: flex;
`;

export const Wrap = styled.div`
  width: 100%;
  background-color: ${COLOR.BG_PRIMARY};
  display: flex;
  flex-direction: column;

  padding: 20px;
  border-radius: 16px;
`;

export const GameList = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  height: fit-content;
  background-color: ${COLOR.BG_SECONDARY};
  padding-bottom: 20px;
`;

export const SearchBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${COLOR.BORDER};

  width: 100%;
  padding: 20px;
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StageListItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const StageHeader = styled.div`
  padding: 5px;
  display: flex;
  align-items: center;
  width: 100%;

  padding: 10px 5px;

  border-top: 1px solid ${COLOR.BORDER};
  border-bottom: 2px solid ${COLOR.BORDER};
  border-left: 3px solid
    ${({ status }) => {
      // default is 'initialized'
      let color = COLOR.GREEN;
      switch (status) {
        case "complete":
          color = COLOR.ROUGE;
          break;
        case "pending":
        case "in progress":
        case "playing":
          color = COLOR.GREEN;
          break;
        case "initialized":
          color = COLOR.BLUE;
          break;
        default:
          color = COLOR.GREEN;
      }
      return color;
    }};
`;

export const HeaderText = styled.div`
  font: ${FONT_WEIGHT.BOLD} 18px ${FONT_FAMILY.GOTHIC_BOLD};
`;

export const HeaderIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-left: auto;
  margin-right: 10px;

  &:hover {
    opacity: 0.4;
  }
`;

export const Icon = styled.img`
  display: inline-block;
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

export const WrapGameItem = styled.div`
  display: flex;
  background-color: ${COLOR.BG_PRIMARY};
  padding-left: 15px;
  width: 100%;
`;

export const GameListItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  padding: 10px;
  border-bottom: 1px solid ${COLOR.BORDER};
  ${({ active }) =>
    active
      ? `background-color: ${COLOR.BG_PRIMARY}`
      : `
      background-color: ${COLOR.BG_SECONDARY};
      &:hover {
      cursor: pointer;
      opacity: 0.5;
    }`}

  border-left: 3px solid ${({ status }) => {
    // default is 'initialized'
    let color = COLOR.GREEN;
    switch (status) {
      case "complete":
        color = COLOR.ROUGE;
        break;
      case "pending":
      case "challenged":
        color = COLOR.YELLOW;
        break;
      case "playing":
        color = COLOR.GREEN;
        break;
      default:
        color = COLOR.BLUE;
    }
    return color;
  }}
`;

export const ListTitle = styled.div`
  width: 100%;
  font: ${FONT_WEIGHT.MEDIUM} 14px ${FONT_FAMILY.GOTHIC_BOLD};
  margin-bottom: 10px;
`;

export const ListDescription = styled.div`
  width: 100%;
  font: ${FONT_WEIGHT.NORMAL} 12px ${FONT_FAMILY.GOTHIC};
  color: ${COLOR.GREY};
  margin-bottom: 2px;
`;

export const ListText = styled.div`
  width: 100%;
  font: ${FONT_WEIGHT.NORMAL} 15px ${FONT_FAMILY.GOTHIC};
  letter-spacing: -0.4px;
  text-overflow: ellipsis;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const Placeholder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;

  font: ${FONT_WEIGHT.MEDIUM} 18px ${FONT_FAMILY.GOTHIC};
  border-bottom: 1px solid ${COLOR.BORDER};
`;

export const InfoPlaceholder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0px 20px;
  font: ${FONT_WEIGHT.MEDIUM} 18px ${FONT_FAMILY.GOTHIC};
`;

export const PlaceholderCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: ${({ height }) => (!!height ? height : "auto")};

  font: ${FONT_WEIGHT.MEDIUM} 18px ${FONT_FAMILY.THIN};
  background-color: ${COLOR.BG_SECONDARY};
  border-radius: 2px;
  border-bottom: 1px solid ${COLOR.BORDER};
`;

export const GameDetails = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  padding: 18px;
`;

export const GameHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  padding-bottom: 15px;
  margin-bottom: 20px;
  border-bottom: 2px solid ${COLOR.BORDER_2};
`;

export const GameTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  font: ${FONT_WEIGHT.BOLD} 22px ${FONT_FAMILY.GOTHIC_BOLD};
  text-transform: uppercase;
`;

export const HeaderButtons = styled.div`
  display: flex;
  align-items: center;

  marign-left: auto;
`;

export const OptionsMenu = styled.img`
  display: flex;
  align-items: center;

  height: 25px;
  width: 25px;
  cursor: pointer;

  margin-top: 5px;
`;

export const PopupMenu = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PopupMenuItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  padding: 15px 20px;
  background-color: ${COLOR.BG_SECONDARY};
  border-bottom: 1px solid ${COLOR.BORDER};

  cursor: pointer;
  &:hover {
    opacity: 0.4;
  }

  ${({ disabled }) =>
    disabled &&
    `
    cursor: not-allowed;
    opacity: 0.2;
    pointer-events: none;
  `}
`;

export const Stream = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  min-height: 450px;
`;

export const StreamTabs = styled.div`
  display: flex;
  width: 100%;
`;

export const StreamTab = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;

  font: ${FONT_WEIGHT.BOLD} 12px ${FONT_FAMILY.THIN};
  letter-spacing: 0.86px;
  padding: 15px;

  ${({ active }) =>
    active
      ? `
  background-color: ${COLOR.NAVY_BLUE};`
      : `background-color: ${COLOR.BG_PRIMARY}
  cursor: pointer;
  &:hover {
    opacity: 0.4;
  }
  `};
  border: 1px solid ${COLOR.BORDER_2};
`;

export const TwitchStream = styled.div`
  height: 375px;
  width: 100%;
`;

export const GameInfo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  padding: 20px;
  margin-bottom: 20px;

  background-color: ${COLOR.BG_SECONDARY};
`;

export const PlayerInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const PlayerProfile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${({ player }) =>
    player === "home" ? "flex-end" : "flex-start"};
  justify-content: center;

  margin: 0px 10px;
`;

export const Status = styled.div`
  display: flex;
  align-items: center;
  margin: 5px 0px 0px 0px;
`;

export const StatusText = styled.div`
  font: ${FONT_WEIGHT.NORMAL} 12px ${FONT_FAMILY.THIN};
  margin: 0px 5px;
`;

export const PlayerName = styled.div`
  display: flex;
  align-items: center;
  font: ${FONT_WEIGHT.MEDIUM} 15px ${FONT_FAMILY.THIN};
  letter-spacing: -0.29px;
`;

export const PlayerProfilePic = styled.img`
  height: 32px;
  width: 32px;
  margin: 0;
`;

export const Dot = styled.div`
  width: 9px;
  height: 9px;
  margin: 0px 0px;
  border-radius: 50%;
  background-color: ${({ status }) =>
    status === "SUBMITTED" || status === "NOT READY" || status === "COMPLETE"
      ? COLOR.ROUGE
      : status === "PENDING" || status === "CHALLENGED"
      ? COLOR.YELLOW_2
      : COLOR.GREEN};
`;

export const PlayerScore = styled.div`
  font: ${FONT_WEIGHT.BOLD} 20px ${FONT_FAMILY.THIN};
  letter-spacing: -0.39px;
  margin: 0 10px;
  white-space: nowrap;
`;

export const defaultBadge = styled.div`
  padding: 8px;

  font: ${FONT_WEIGHT.MEDIUM} 12px ${FONT_FAMILY.THIN};
  text-transform: uppercase;
`;

export const HomeBadge = styled(defaultBadge)`
  background-color: ${COLOR.NAVY_BLUE};
  margin-right: auto;
`;

export const AwayBadge = styled(defaultBadge)`
  background-color: ${COLOR.ROUGE};
  margin-left: auto;
`;

export const WrapTimer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: -10px 0 10px;
  span {
    margin-left: 10px;
    border-radius: 16px;
    padding: 3px 10px;
    background-color: ${COLOR.ROUGE};
  }
`;

export const Refresh = styled.div`
  display: flex;
  align-items: center;

  margin-left: auto;
  margin-right: 10px;
  overflow-y: visible;
`;
