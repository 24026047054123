import styled, { css } from "styled-components";
import PropTypes from "prop-types";

import { COLOR, FONT_FAMILY } from "../../../constants/theme";

export const ChipInputSD = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  padding-left: 5px;

  ${({ bgColor }) =>
    bgColor &&
    css`
      background-color: ${bgColor};
    `};
  border: 1px solid
    ${({ hasError }) => (hasError ? COLOR.CHERRY_RED : COLOR.BORDER)};
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.02);

  & > * {
    margin: 2px;
  }
`;
ChipInputSD.propTypes = {
  bgColor: PropTypes.string,
  hasError: PropTypes.string
};

export const ChipInputForm = styled.form`
  flex: 1 0 50px;
`;

export const ChipInputField = styled.input`
  width: 100%;
  border: none;
  color: ${COLOR.TEXT_4};
  font-family: ${FONT_FAMILY.THIN};
  font-size: 14px;
  background-color: ${COLOR.BG_INPUT};

  ::-ms-clear {
    display: none; /* hidden the cross */
  }

  ${({ hiddenInput }) =>
    hiddenInput &&
    css`
      width: auto;
      visibility: hidden;
      z-index: -9999;
      opacity: 0;
      position: absolute;
      pointer-events: none;
    `};
`;
