import React, { Component } from "react";
import {
  Container,
  Content,
  Item,
  Info,
  List,
  Badge,
  Id,
  WrapCoinsCount,
  WrapIcon,
  CoinsCount,
  CoinsIcon,
  Text,
  Status
} from "./styled";
import moment from "moment";
import Pagination from "../../../../../components/presentation/Pagination/Pagination";
import {
  numberToThousandsString,
  propValueOr
} from "../../../../../helpers/common";
import withLocationSearch from "../../../../../hoc/withLocationSearch";
import * as queryString from "query-string";
import { withRouter } from "react-router";
import CoinIcon from "../../../../../static/icons/rival_coin.svg";
import FormFieldKit from "../../../../../components/kit/Fields/FormField/FormField";
import IconSearchGrey from "../../../../../static/icons/icon-search-grey.svg";

class Transactions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1
    };
  }
  componentDidMount() {
    this.fetchTransactions();
    const filter = queryString.parse(this.props.location.search);
    this.setState({ ...filter });
  }

  fetchTransactions = () => {
    const { page = 1 } = this.props.getLocationSearch();
    const walletId = propValueOr(this.props.tournament, "wallet.id", undefined);
    if (walletId) {
      this.props.fetchTransactions({ page: page, walletId: walletId });
    }
  };

  handleSearch = searchWord => {
    this.setState({ searchWord: searchWord });
    const walletId = propValueOr(this.props.tournament, "wallet.id", undefined);
    if (walletId) {
      return this.props.fetchTransactions({
        q: searchWord,
        walletId: walletId
      });
    }
  };

  componentDidUpdate(prevProps) {
    const walletId = propValueOr(this.props.game, "wallet.id", undefined);
    const prevWalletId = propValueOr(prevProps.game, "wallet.id", undefined);
    if (
      prevProps.location !== this.props.location ||
      walletId !== prevWalletId
    ) {
      this.fetchTransactions();
    }
  }

  render() {
    const { transactions, total } = this.props;
    return (
      <Container>
        <Content>
          <FormFieldKit
            fullWidth
            value={this.state.searchWord}
            placeholder="Search for transaction by ID or memo..."
            onChange={this.handleSearch}
            icon={
              <WrapIcon>
                <img src={IconSearchGrey} alt="search icon" />
              </WrapIcon>
            }
            iconAlign="left"
          />
          <List>
            {(transactions || []).map(t => {
              return (
                <Item key={t.id} to={`/wallet/transactions/${t.id}`}>
                  <Info>
                    <Id>{t.id}</Id>
                    <Badge>
                      {propValueOr(t, "sender.walletType", "")}:
                      {propValueOr(
                        t,
                        `sender.${propValueOr(t, "sender.walletType", "")}.id`,
                        ""
                      )}
                    </Badge>
                    {"->"}
                    <Badge>
                      {propValueOr(t, "recipient.walletType", "")}:
                      {propValueOr(
                        t,
                        `recipient.${propValueOr(
                          t,
                          "recipient.walletType",
                          ""
                        )}.id`,
                        ""
                      )}
                    </Badge>
                  </Info>

                  <Info>
                    <Text>{propValueOr(t, "metadata.memo", "")}</Text>
                    <WrapCoinsCount>
                      <CoinsCount>
                        {numberToThousandsString(t.amount)}
                      </CoinsCount>
                      <CoinsIcon src={CoinIcon} />
                    </WrapCoinsCount>
                    <Status status={t.status}>{t.status}</Status>
                    <Badge>
                      {moment(t.createdAt).format("M/DD/YY @ h:mm A")}
                    </Badge>
                  </Info>
                </Item>
              );
            })}
            <Pagination withUrl total={total} />
          </List>
        </Content>
      </Container>
    );
  }
}

export default withRouter(withLocationSearch(Transactions));
